import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { notifySuccess } from "../../components/ui/popup/toastPopup";
import { useForm } from "react-hook-form";
import {
  getUnitCounter,
  updateUnitCounter,
} from "../../service/unitCounterAPI";
import Loading from "../../components/ui/loading";
import BreadcrumbsTitle from "../../components/ui/breadcrumbsTitle";
import { t } from "i18next";
import { unitSchema } from "../../components/validation/unitSchema";
import { useSchema } from "../../components/hooks/use-schema";
import { LanguageForm } from "../../service/getLocalStorage";
import { GridContainerSize } from "../../components/utils/gridContainerSize";
import UnitForm from "./unitForm";

function UnitDetail() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm(useSchema(unitSchema));
  const [isLoading, setIsLoading] = useState(true);
  const [statusButton, setStatusButton] = useState(true);
  const [dataAll, setDataAll] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const paramsId =
    window.location.pathname.split("/")[
      window.location.pathname.split("/").length - 1
    ];
  const fetchData = async () => {
    let dataAPI = await getUnitCounter(paramsId);
    setDataAll(dataAPI);
    setStatusButton(dataAPI.status);
    setValue("unit", dataAPI.unit_counter_lang.name);
    setIsLoading(false);
  };
  const onConfirmForm = async (register) => {
    setIsSubmitting(true);
    let dataAPI = {
      id: dataAll.id,
      status: statusButton,
      unit_counter_lang: {
        name: register.unit,
        language_code: LanguageForm,
      },
    };
    let statusAPI = await updateUnitCounter(dataAPI);
    if (statusAPI === "OK") {
      notifySuccess({
        title: t("notification_successfully_edited_information"),
      });
      navigate("/unit");
    }
    await new Promise((resolve) => setTimeout(resolve, 2000));
    setIsSubmitting(false);
  };

  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <Box>
      <BreadcrumbsTitle
        props={{
          title: t("unit_info"),
          dataLink: [
            { text: t("unit"), link: "/unit" },
            { text: t("unit_info"), link: null },
          ],
        }}
      />
      {isLoading ? (
        <>
          <Loading />
        </>
      ) : (
        <GridContainerSize>
          <Box p={2}>
            <form onSubmit={handleSubmit(onConfirmForm)}>
              <UnitForm
                props={{
                  register,
                  errors,
                  isSubmitting,
                  statusButton,
                  setStatusButton,
                }}
              />
            </form>
          </Box>
        </GridContainerSize>
      )}
    </Box>
  );
}

export default UnitDetail;
