import * as yup from "yup";
import { validationSchemas } from "./data";
export const staffPosSchema = yup.object().shape({
  firstName: validationSchemas.name,
  lastName: validationSchemas.name,
  tel: validationSchemas.tel,
  email: validationSchemas.email,
  positionUser: validationSchemas.name,
  idCardUser: validationSchemas.number,
  pin: validationSchemas.pin,
});
