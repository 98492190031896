import { Box, Switch } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getAllPosUser } from "../../../../service/posUserAPI";
import { MomentConvert } from "../../../../components/ui/moment/momentConvert";
import {
  configGetAllPosUser,
  configUpdatePosUser,
} from "../../../../service/branchConfigAPI/branchConfigPosUser";
import {
  notifyDefault,
  notifySuccess,
} from "../../../../components/ui/popup/toastPopup";
import Loading from "../../../../components/ui/loading";
import { BoxImage } from "../../../../components/ui/boxImage/boxImage";
import DataMaterialTable from "../../../../components/ui/dataTable/dataMaterialTable";
import { TextTable } from "../../../../components/ui/textfield/textfieldCustom";
import { BoxImageBlank } from "../../../../components/ui/boxImage/boxImageBlank";
import { ActionDetail } from "../../../../components/ui/dataTable/components/actionDetail";
import { getImage } from "../../../../service/upload/getImage";
import { UseConvertImageToBase64 } from "../../../../components/hooks/use-convert-image-to-base-64";
import dayjs from "dayjs";

function BranchUserPOS(props) {
  let { templateId } = props;
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [columns, setColumns] = useState([]);
  const { t } = useTranslation();
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [exportColumnName, setExportColumnName] = useState(null);

  const fetchData = async () => {
    let dataColumns = [
      {
        accessorKey: "image",
        size: 100,
        enableSorting: false,
        header: t("global_image"),
        Cell: ({ row }) => (
          <>
            {row.original.image ? (
              <BoxImage
                image={row.original.image}
                width={"50px"}
                height={"50px"}
                type="table"
                borderRadius={true}
              />
            ) : (
              <BoxImageBlank width={50} height={50} />
            )}
          </>
        ),
      },
      {
        accessorKey: "firstName",
        size: 100,
        header: t("global_first_name"),
      },
      {
        accessorKey: "lastName",
        size: 100,
        header: t("global_last_name"),
      },
      {
        accessorKey: "email",
        header: t("global_email"),
        size: 100,
      },
      {
        accessorKey: "tel",
        header: t("global_tel"),
        size: 100,
      },
      {
        accessorKey: "position",
        header: t("staff_pos_position"),
        size: 100,
      },
      {
        accessorKey: "update",
        header: t("global_updated_at"),
        size: 100,
        Cell: ({ cell }) => <TextTable props={{ text: cell.getValue() }} />,
      },
      {
        accessorKey: "status",
        enableSorting: false,
        header: t("global_status"),
        size: 100,
        Cell: ({ row }) => (
          <>
            <Switch
              onChange={(e) =>
                onUpdateStatus(row.original, e.target.checked, row.index)
              }
              checked={row.original.status}
            />
          </>
        ),
      },
      {
        accessorKey: "action",
        enableSorting: false,
        header: "Action",
        size: 100,
        sortable: false,
        Cell: ({ row }) => (
          <Box sx={{ display: "flex", gap: 2 }}>
            <ActionDetail params={row} detailPath={"/posUser/detail/"} />
          </Box>
        ),
      },
    ];

    setColumns(dataColumns);

    setIsLoadingData(false);

    let dataBox = await configGetAllPosUser(templateId);

    let dataAllUser = await getAllPosUser(templateId);
    let dataRows = [];
    dataBox = dataBox.sort(
      (a, b) => new Date(b.updated_at) - new Date(a.updated_at)
    );
    dataBox.forEach((item, index) => {
      let dataFind = dataAllUser.find(
        (itemFind) => itemFind.id === item.pos_user_id
      );
      if (dataFind.status) {
        if (dataFind.pos_user_lang) {
          dataRows.push({
            id: dataFind.pos_user_lang.pos_user_id,
            update: MomentConvert(item.updated_at),
            firstName: dataFind.pos_user_lang.firstname,
            lastName: dataFind.pos_user_lang.lastname,
            position: dataFind.position,
            tel: dataFind.tel,
            email: dataFind.email,
            image: dataFind.profile_img,
            status: item.status,
          });
        }
      }
    });
    const promises = dataRows.map(async (item) => {
      let imageUrl = await getImage(item.image);
      item.image = UseConvertImageToBase64(imageUrl);
    });
    await Promise.all(promises);

    //===== Start set header name export csv
    let headers = [];
    dataColumns.forEach((item) => {
      if (item.header !== "Image" && item.header !== "Action") {
        headers.push(item.header);
      }
    });
    const csvStringArray = [
      headers,
      ...dataRows.map((item) => [
        item.firstName,
        item.lastName,
        item.email,
        item.tel,
        item.position,
        item.update,
        item.status,
      ]),
    ]
      .map((row) => row.join(","))
      .join("\n");

    setExportColumnName(csvStringArray);
    //===== End set header name export csv
    setRows(dataRows);
    setIsLoading(false);
  };
  const onUpdateStatus = async (item, checked, index) => {
    setRows((prevRows) => {
      const dataNews = [...prevRows];
      dataNews[index].status = checked;
      return dataNews;
    });
    let dataAPI = {
      template_id: String(templateId),
      pos_user_id: Number(item.id),
      status: checked,
    };
    let dataStatus = await configUpdatePosUser(dataAPI);
    if (dataStatus === "OK") {
      if (checked) {
        notifySuccess({ title: t("toast_active") });
      } else {
        notifyDefault({ title: t("toast_in_active") });
      }
    }
  };
  // const onDirectToDetail = async (itemId) => {
  //   navigate("/posUser/detail/" + itemId);
  // };

  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <Box>
      {isLoading ? (
        <Loading />
      ) : (
        <Box>
          <DataMaterialTable
            statusViewMode={true}
            exportColumnName={exportColumnName}
            fileName={t("staff_pos") + ` ${dayjs().format("DD/MM/YYYY")}`}
            rows={rows}
            isLoadingData={isLoadingData}
            columns={columns}
            detailPath={"/posUser/detail/"}
          />
        </Box>
      )}
    </Box>
  );
}

export default BranchUserPOS;
