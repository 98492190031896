import axios from "axios";
import {
  AccessTokenAPI,
  BusinessId,
  CompanyId,
  ServiceId,
} from "../getLocalStorage";
import { expiredToken } from "../expiredToken";

export const getOverviewDashboard = (dataAPI, type) => {
  let dataAPINew = {
    type: type,
    data: dataAPI,
  };
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_API_URL}/api/insight/getInsight?company_uid=${CompanyId}&business_uid=${BusinessId}&service_code=${ServiceId}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${AccessTokenAPI}`,
    },
    data: JSON.stringify(dataAPINew),
  };

  return axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      console.log(error);
      if (error.response.status !== 500) {
        expiredToken(error);
      }
    });
};
