import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { notifySuccess } from "../../../components/ui/popup/toastPopup";
import { useForm } from "react-hook-form";
import BreadcrumbsTitle from "../../../components/ui/breadcrumbsTitle";
import { useNavigate } from "react-router-dom";
import { useSchema } from "../../../components/hooks/use-schema";
import OnlineAdminForm from "./selfPickupAdminForm";
import { storeSelfPickupAdmin } from "../../../service/selfPickUp/selfPickupAdmin/storeSelfPickupAdmin";
import { uploadImage } from "../../../service/upload/uploadImage";
import { selfPickupAdminSchema } from "../../../components/validation/selfPickupAdminSchema";
import { LanguageForm } from "../../../service/getLocalStorage";

function SelfPickupAdminCreate() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm(useSchema(selfPickupAdminSchema));
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [statusButton, setStatusButton] = useState(true);
  const [image, setImage] = useState(false);
  const navigate = useNavigate();
  const onConfirmForm = async (register) => {
    setIsSubmitting(true);
    let newImage = "";
    if (image) {
      newImage = await uploadImage(image);
    }
    let dataAPI = {
      position: register.positionUser,
      pin: register.pin,
      profile_img: newImage,
      nat_id: register.idCardUser,
      tel: register.tel,
      email: register.email,
      status: statusButton,
      self_pickup_admin_lang: {
        firstname: register.firstName,
        lastname: register.lastName,
        language_code: LanguageForm,
      },
    };
    let statusAPI = await storeSelfPickupAdmin(dataAPI);
    if (statusAPI === "OK") {
      notifySuccess({
        title: t("toast_successfully_added_information"),
      });
      navigate("/self-pickup-admin");
    }
    await new Promise((resolve) => setTimeout(resolve, 2000));
    setIsSubmitting(false);
  };
  return (
    <div align="left">
      <BreadcrumbsTitle
        props={{
          title: t("self_pickup_admin"),
          dataLink: [
            {
              text: t("self_pickup_admin"),
              link: "/self-pickup-admin",
            },
            { text: t("self_pickup_admin_create"), link: null },
          ],
        }}
      />
      <OnlineAdminForm
        props={{
          handleSubmit,
          onConfirmForm,
          register,
          errors,
          statusButton,
          setStatusButton,
          isSubmitting,
          image,
          setImage,
        }}
      />
    </div>
  );
}

export default SelfPickupAdminCreate;
