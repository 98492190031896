import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
  OutlinedInput,
  ListItemText,
  Radio,
  FormHelperText,
} from "@mui/material";
import StatusForm from "../../../components/ui/formComponent/statusForm";
import "react-awesome-slider/dist/styles.css";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { TitleTextfield } from "../../../components/ui/textfield/textfieldCustom";
import { notifySuccess } from "../../../components/ui/popup/toastPopup";
import { useNavigate } from "react-router-dom";
import ConfirmButton from "../../../components/ui/button/confirmButton";
import { getProduct, updateProduct } from "../../../service/productAPI";
import { getAllProductCategory } from "../../../service/productCategoryAPI";
import { getAllUnitCounter } from "../../../service/unitCounterAPI";
import { getAllPreDefineCategory } from "../../../service/preDefineCategory";
import Loading from "../../../components/ui/loading";
import { uploadImage } from "../../../service/upload/uploadImage";
import ImageCrop from "../../../components/ui/upload/imageCrop";
import BreadcrumbsTitle from "../../../components/ui/breadcrumbsTitle";
import TitleForm from "../../../components/ui/formComponent/titleForm";
import { WarningPopup } from "../../../components/ui/popup/successPopup";
import { useSchema } from "../../../components/hooks/use-schema";
import { productSchema } from "../../../components/validation/productSchema";
import SelectComponent from "../../../components/ui/textfield/selectComponent";
import TextfieldComponent from "../../../components/ui/textfield/textfieldComponent";
import { BoxWrapper } from "../../../components/ui/box/boxWrapper";
import PaperWrapper from "../../../components/ui/paper/paperWrapper";
import { GridContainerSize } from "../../../components/utils/gridContainerSize";
import { getImage } from "../../../service/upload/getImage";
import { UseConvertImageToBase64 } from "../../../components/hooks/use-convert-image-to-base-64";
import { BoxGapInput } from "../../../components/ui/box/boxGapInput";
import { MyContext } from "../../../context/MyContext";
import { BoxFormTitleAction } from "../../../components/ui/box/boxFormTitleAction";
import TitleHeaderForm from "../../../components/ui/formComponent/titleHeaderForm";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
function ProductDetail() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    clearErrors,
    control,
  } = useForm(useSchema(productSchema));

  const { fetchProduct } = useContext(MyContext);
  const [dataAll, setDataAll] = useState(null);
  const [image, setImage] = useState("");
  const [categoryProductList, setCategoryProductList] = useState([]);
  const [unitCounterAll, setUnitCounterAll] = useState([]);
  const [preDefinedCategoryAll, setPreDefinedCategoryAll] = useState([]);
  const [selectCategoryAll, setSelectCategoryAll] = useState([]);
  const { t } = useTranslation();
  const [statusButton, setStatusButton] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [amountCount, setAmountCount] = useState(false);
  const paramsId =
    window.location.pathname.split("/")[
      window.location.pathname.split("/").length - 1
    ];
  const navigate = useNavigate();
  const onConfirmForm = async (register) => {
    setIsSubmitting(true);
    if (localStorage.getItem("uploadImage")) {
      WarningPopup({ title: t("notification_please_upload_image") });
    } else {
      let preDefinedBox = [];
      selectCategoryAll.forEach((selectCate) => {
        preDefinedBox.push(selectCate.id);
      });
      let newImage = "";
      if (image) {
        if (typeof image === "object") {
          newImage = await uploadImage(image);
        } else {
          newImage = dataAll.image;
        }
      }
      let dataAPI = {
        id: String(paramsId),
        product_category_id: register.productCategory,
        unit_counter_id: register.unit,
        image: newImage,
        status: statusButton,
        product_lang: {
          name: register.productName,
          description: register.description,
          language_code: String(localStorage.getItem("language_form")),
        },
        pre_define_categories: preDefinedBox,
      };
      let statusAPI = await updateProduct(dataAPI);
      if (statusAPI === "OK") {
        fetchProduct()
          .then(() => {
            notifySuccess({
              title: t("notification_successfully_edited_information"),
            });
            navigate("/products");
          })
          .catch((error) => {
            console.error("Error fetching product:", error);
          });
      }
    }
    await new Promise((resolve) => setTimeout(resolve, 2000));
    setIsSubmitting(false);
  };

  const fetchData = async () => {
    localStorage.removeItem("uploadImage");
    await fetchDataCategory();
    await fetchDataUnit();
    let dataAPI = await getProduct(paramsId);
    let {
      product_lang,
      status,
      pre_define_categories,
      stock_count,
      unit_counter,
      unit_counter_id,
      product_category_id,
      image,
    } = dataAPI;
    setDataAll(dataAPI);

    let imageUrl = await getImage(image);
    image = UseConvertImageToBase64(imageUrl);
    await Promise.all(imageUrl);

    if (unit_counter.status === false) {
      setValue("unit", "");
    } else {
      setValue("unit", unit_counter_id);
    }
    await setValue("productCategory", product_category_id);
    await setValue("imageRef", image);
    await setImage(image);
    await setValue("productName", product_lang.name);
    await setValue("description", product_lang.description);
    await setStatusButton(status);
    await setAmountCount(stock_count);
    fetchAllPreDefineCategory(pre_define_categories, stock_count);
  };
  const fetchDataCategory = async () => {
    let dataAPI = await getAllProductCategory();
    dataAPI = dataAPI.filter((item) => item.status === true);
    setCategoryProductList(dataAPI);
  };
  const fetchDataUnit = async () => {
    let dataAPI = await getAllUnitCounter();
    dataAPI = dataAPI.filter((item) => item.status === true);
    setUnitCounterAll(dataAPI);
  };
  const fetchAllPreDefineCategory = async (
    pre_define_categories,
    ItemAmountCount
  ) => {
    let dataBox = [];
    let boxMatchCateAll = [];

    let dataAPI = await getAllPreDefineCategory();
    dataAPI = dataAPI.filter((item) => item.pre_defines.length > 0);
    dataAPI.forEach((item) => {
      if (item.status) {
        let itemBox = item.pre_defines.filter(
          (itemPre) => itemPre.stock_count === ItemAmountCount
        );
        if (itemBox.length !== 0) {
          dataBox.push({ id: item.id, name: item.name });
        }
      }
    });
    dataBox.forEach((allCates) => {
      pre_define_categories.forEach((preCate) => {
        if (allCates.id === preCate.id) {
          boxMatchCateAll.push(allCates);
        }
      });
    });

    setPreDefinedCategoryAll(dataBox);
    setSelectCategoryAll(boxMatchCateAll);
    await setIsLoading(false);
  };
  const onPreDefinedCategoryAll = (e) => {
    const {
      target: { value },
    } = e;
    setSelectCategoryAll(typeof value === "string" ? value.split(",") : value);
  };
  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <Box>
      <BreadcrumbsTitle
        props={{
          title: t("product_info"),
          dataLink: [
            { text: t("product"), link: "/products" },
            { text: t("product_info"), link: null },
          ],
        }}
      />
      {isLoading ? (
        <>
          <Loading />
        </>
      ) : (
        <GridContainerSize>
          <Box p={2}>
            <form onSubmit={handleSubmit(onConfirmForm)}>
              <BoxWrapper>
                <PaperWrapper props={{ growFull: 1 }}>
                  <BoxFormTitleAction>
                    <TitleHeaderForm props={{ text: t("unit_info") }} />
                    <StatusForm
                      statusButton={statusButton}
                      setStatusButton={setStatusButton}
                    />
                  </BoxFormTitleAction>
                  <BoxGapInput>
                    <Box className="boxWrapper-container">
                      <BoxWrapper>
                        <PaperWrapper props={{ growFull: null }}>
                          <TitleForm props={{ text: t("product_image") }} />
                          <Box align="center">
                            <ImageCrop
                              props={{
                                setImage,
                                image,
                                heightCrop: 256,
                                widthCrop: 256,
                                label: "staffPOS",
                              }}
                            />
                          </Box>
                        </PaperWrapper>
                        <PaperWrapper props={{ growFull: 1 }}>
                          <TitleForm props={{ text: t("product_info") }} />
                          <BoxGapInput>
                            <SelectComponent
                              watch={watch}
                              title={t("product_category")}
                              register={register}
                              setValue={setValue}
                              name="productCategory"
                              errors={errors}
                              dataEmpty={t("product_category_select")}
                              optionsData={categoryProductList}
                              clearErrors={clearErrors}
                              control={control}
                            />
                            <SelectComponent
                              watch={watch}
                              title={t("unit")}
                              register={register}
                              setValue={setValue}
                              name="unit"
                              errors={errors}
                              dataEmpty={t("unit_select")}
                              optionsData={unitCounterAll}
                              clearErrors={clearErrors}
                              control={control}
                            />
                            <TextfieldComponent
                              title={t("product_name")}
                              name="productName"
                              register={register}
                              placeholder={t("product_example")}
                              errors={errors}
                              fullGrid={true}
                            />
                            <TextfieldComponent
                              title={t("product_description")}
                              name="description"
                              register={register}
                              placeholder={t("global_optional")}
                              errors={errors}
                              multiline={true}
                              rows={4}
                              fullGrid={true}
                            />
                            <Box>
                              <TitleTextfield text={t("product_amount")} />
                              <FormControlLabel
                                control={
                                  <Radio
                                    name="limit"
                                    checked={amountCount === true}
                                    onChange={(e) => setAmountCount(true)}
                                    disabled
                                  />
                                }
                                label={t("product_amount_limit")}
                              />
                              <FormControlLabel
                                control={
                                  <Radio
                                    name="unlimited"
                                    checked={amountCount === false}
                                    onChange={(e) => setAmountCount(false)}
                                    disabled
                                  />
                                }
                                label={t("product_amount_un_limit")}
                              />
                            </Box>
                            <TitleTextfield
                              text={t("product_option_category")}
                            />
                            <Select
                              labelId="demo-multiple-checkbox-label"
                              id="demo-multiple-checkbox"
                              multiple
                              displayEmpty
                              value={selectCategoryAll}
                              onChange={onPreDefinedCategoryAll}
                              input={<OutlinedInput label="Tag" />}
                              renderValue={(selected) => {
                                let dataReturn = [];
                                if (selected.length === 0) {
                                  return (
                                    <em>
                                      {t("product_option_category_select") +
                                        t("global_optional")}
                                    </em>
                                  );
                                } else {
                                  selected.forEach((item, index) => {
                                    if (index > 0) {
                                      dataReturn.push(",");
                                    }
                                    dataReturn.push(item.name);
                                  });
                                }

                                return dataReturn;
                              }}
                              MenuProps={MenuProps}
                              fullWidth
                              size="small"
                            >
                              {preDefinedCategoryAll.length === 0 && (
                                <MenuItem value={""} disabled>
                                  <em>
                                    {t("global_data_not_found") +
                                      (amountCount === true
                                        ? t("product_amount_limit")
                                        : t("product_amount_un_limit"))}
                                  </em>
                                </MenuItem>
                              )}

                              {preDefinedCategoryAll.map((item) => (
                                <MenuItem key={item} value={item}>
                                  <Checkbox
                                    checked={
                                      selectCategoryAll.indexOf(item) > -1
                                    }
                                  />
                                  <ListItemText primary={item.name} />
                                </MenuItem>
                              ))}
                            </Select>
                            <FormHelperText>
                              {t("product_category_helper")}
                            </FormHelperText>
                          </BoxGapInput>
                        </PaperWrapper>
                      </BoxWrapper>
                    </Box>
                    <ConfirmButton disabled={isSubmitting} />
                  </BoxGapInput>
                </PaperWrapper>
              </BoxWrapper>
            </form>
          </Box>
        </GridContainerSize>
      )}
    </Box>
  );
}

export default ProductDetail;
