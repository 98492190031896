// Icon
import StoreIcon from "@mui/icons-material/Store";
import { useTranslation } from "react-i18next";

import OnlineBranch from "../../../pages/selfPickup/selfPickupBranch/selfPickupBranch";
import SelfPickupAdmin from "../../../pages/selfPickup/selfPickupAdmin/selfPickupAdmin";
import SelfPickupSetupTab from "../../../pages/selfPickup/selfPickupSetup/selfPickupSetupTab";
import SelfPickupBankAccount from "../../../pages/selfPickup/selfPickupBankAccount/selfPickupBankAccount";
import SelfPickupSaleProduct from "../../../pages/selfPickup/selfPickupSaleProduct/selfPickupSaleProduct";

export const ListOnline = () => {
  const { t } = useTranslation();
  const listItem = [
    // ==== จัดการข้อมูล ====

    {
      name: t("Self pickup setup"),
      exact: true,
      component: <SelfPickupSetupTab />,
      to: `/self-pickup-setup`,
      iconClassName: <StoreIcon  sx={{ fontSize: 20 }} />,
    },
    {
      name: t("Self pickup branch"),
      exact: true,
      component: <OnlineBranch />,
      to: `/self-pickup-branch`,
      iconClassName: <StoreIcon  sx={{ fontSize: 20 }} />,
    },
    {
      name: t("Self pickup Admin"),
      exact: true,
      component: <SelfPickupAdmin />,
      to: `/self-pickup-admin`,
      iconClassName: <StoreIcon  sx={{ fontSize: 20 }} />,
    },
    {
      name: t("Self pickup Bank Account"),
      exact: true,
      component: <SelfPickupBankAccount />,
      to: `/self-pickup-admin-bank-account`,
      iconClassName: <StoreIcon  sx={{ fontSize: 20 }} />,
    },
    {
      name: t("Self pickup sale product"),
      exact: true,
      component: <SelfPickupSaleProduct />,
      to: `/self-pickup-sale-product`,
      iconClassName: <StoreIcon  sx={{ fontSize: 20 }} />,
    },
  ];
  return listItem;
};
