import axios from "axios";
import {
  AccessTokenAPI,
  BusinessId,
  CompanyId,
  LanguageForm,
  ServiceId,
} from "../getLocalStorage";

export const getAllBranchConfigZone = async (paramsId) => {
  let config = {
    method: "get",
    url: `${process.env.REACT_APP_API_URL}/api/setting/branch/getAllZone?template_id=${paramsId}&language_code=${LanguageForm}&company_uid=${CompanyId}&business_uid=${BusinessId}&service_code=${ServiceId}`,
    headers: {
      Authorization: `Bearer ${AccessTokenAPI}`,
    },
  };

  return axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      console.log(error);
    });
};
export const storeBranchConfigZone = async (dataAPI) => {
  let config = {
    method: "post",
    url: `${process.env.REACT_APP_API_URL}/api/setting/branch/storeZone?company_uid=${CompanyId}&business_uid=${BusinessId}&service_code=${ServiceId}`,
    headers: {
      Authorization: `Bearer ${AccessTokenAPI}`,
      "Content-Type": "application/json",
    },
    data: JSON.stringify(dataAPI),
  };

  return axios(config)
    .then(function (response) {
      return response.data.message;
    })
    .catch(function (error) {
      console.log(error);
    });
};
export const updateBranchConfigZone = async (dataAPI) => {
  let config = {
    method: "put",
    url: `${process.env.REACT_APP_API_URL}/api/setting/branch/updateZone?company_uid=${CompanyId}&business_uid=${BusinessId}&service_code=${ServiceId}`,
    headers: {
      Authorization: `Bearer ${AccessTokenAPI}`,
      "Content-Type": "application/json",
    },
    data: JSON.stringify(dataAPI),
  };

  return axios(config)
    .then(function (response) {
      return response.data.message;
    })
    .catch(function (error) {
      console.log(error);
    });
};
export const getBranchConfigZone = async (paramsId) => {
  let data = "";
  let config = {
    method: "get",
    url: `${process.env.REACT_APP_API_URL}/api/setting/branch/getZone?template_id=${paramsId}&language_code=${LanguageForm}&company_uid=${CompanyId}&business_uid=${BusinessId}&service_code=${ServiceId}`,
    headers: {
      Authorization: `Bearer ${AccessTokenAPI}`,
    },
    data: data,
  };

  return axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      console.log(error);
    });
};
export const deleteBranchConfigZone = async (dataAPI) => {
  let config = {
    method: "delete",
    url: `${process.env.REACT_APP_API_URL}/api/setting/branch/deleteZone?company_uid=${CompanyId}&business_uid=${BusinessId}&service_code=${ServiceId}`,
    headers: {
      Authorization: `Bearer ${AccessTokenAPI}`,
      "Content-Type": "application/json",
    },
    data: JSON.stringify(dataAPI),
  };

  return axios(config)
    .then(function (response) {
      return response.data.message;
    })
    .catch(function (error) {
      console.log(error);
    });
};
