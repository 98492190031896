import React, { useState } from "react";
import { Box, Typography, Switch, FormControlLabel } from "@mui/material";
import { t } from "i18next";
function StatusForm(props) {
  const { statusButton, setStatusButton, disabled } = props;
  const [disableBtn, setDisableBtn] = useState(false);
  const handleChange = (e) => {
    setDisableBtn(true);
    setStatusButton(e.target.checked);
    setTimeout(() => {
      setDisableBtn(false);
    }, 1000);
  };
  return (
    <Box sx={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
      <FormControlLabel
        label={
          <Typography
            sx={(theme) => ({
              fontWeight: "bold",
              color: theme.palette.primaryText.main,
            })}
          >
            {statusButton ? t("global_active") : t("global_in_active")}
          </Typography>
        }
        control={
          <Switch
            label={statusButton ? t("global_active") : t("global_in_active")}
            onChange={(e) => handleChange(e)}
            checked={statusButton}
            disabled={disabled ? disabled : disableBtn}
          />
        }
      ></FormControlLabel>
    </Box>
  );
}
export default StatusForm;
