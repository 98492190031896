import React, { useState } from "react";
import { Box } from "@mui/material";
import TitleForm from "../../../components/ui/formComponent/titleForm";
import { t } from "i18next";
import { postProductCategory } from "../../../service/productCategoryAPI";
import { notifySuccess } from "../../../components/ui/popup/toastPopup";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import BreadcrumbsTitle from "../../../components/ui/breadcrumbsTitle";
import StatusForm from "../../../components/ui/formComponent/statusForm";
import ConfirmButton from "../../../components/ui/button/confirmButton";
import TextfieldComponent from "../../../components/ui/textfield/textfieldComponent";
import { useSchema } from "../../../components/hooks/use-schema";
import { productCategorySchema } from "../../../components/validation/productCategorySchema";
import PaperWrapper from "../../../components/ui/paper/paperWrapper";
import { GridContainerSize } from "../../../components/utils/gridContainerSize";
import { BoxGapInput } from "../../../components/ui/box/boxGapInput";
import { BoxWrapper } from "../../../components/ui/box/boxWrapper";
import { BoxFormTitleAction } from "../../../components/ui/box/boxFormTitleAction";
import TitleHeaderForm from "../../../components/ui/formComponent/titleHeaderForm";

function ProductCategoryCreate() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm(useSchema(productCategorySchema));
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [statusButton, setStatusButton] = useState(true);
  const navigate = useNavigate();
  const onConfirmForm = async (register) => {
    setIsSubmitting(true);
    let dataAPI = {
      status: statusButton,
      product_category_lang: {
        name: register.productCategory,
        language_code: "ALL",
      },
    };
    let statusAPI = await postProductCategory(dataAPI);
    if (statusAPI === "OK") {
      setValue("productCategory", "");
      notifySuccess({
        title: t("toast_successfully_added_information"),
      });
      navigate("/product-category");
    }
    await new Promise((resolve) => setTimeout(resolve, 2000));
    setIsSubmitting(false);
  };
  return (
    <div align="left">
      <BreadcrumbsTitle
        props={{
          title: t("product_category_create"),
          dataLink: [
            {
              text: t("product_category"),
              link: "/product-category",
            },
            { text: t("product_category_create"), link: null },
          ],
        }}
      />
      <GridContainerSize>
        <Box p={2}>
          <form onSubmit={handleSubmit(onConfirmForm)}>
            <BoxWrapper>
              <PaperWrapper props={{ growFull: 1 }}>
                <BoxFormTitleAction>
                  <TitleHeaderForm props={{ text: t("product_category") }} />
                  <StatusForm
                    statusButton={statusButton}
                    setStatusButton={setStatusButton}
                  />
                </BoxFormTitleAction>
                <BoxGapInput>
                  <PaperWrapper props={{ growFull: 1 }}>
                    <TitleForm props={{ text: t("product_category_info") }} />
                    <BoxGapInput>
                      <TextfieldComponent
                        title={t("product_category_name")}
                        name="productCategory"
                        register={register}
                        placeholder={t("product_category_example")}
                        errors={errors}
                        fullGrid={true}
                      />
                    </BoxGapInput>
                  </PaperWrapper>

                  <ConfirmButton disabled={isSubmitting} />
                </BoxGapInput>
              </PaperWrapper>
            </BoxWrapper>
          </form>
        </Box>
      </GridContainerSize>
    </div>
  );
}

export default ProductCategoryCreate;
