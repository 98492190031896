import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { useTheme } from "@mui/material";
import dayjs from "dayjs";
import { t } from "i18next";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function LineChartComponent({ props }) {
  let {
    dataValues,
    nameValue,
    symbol,
    title,
    startDate,
    endDate,
    isConfirmData,
  } = props;
  const theme = useTheme();
  const [data, setData] = useState({
    labels: [],
    datasets: [
      {
        label: nameValue,
        data: [],
        borderColor: null,
        tension: 0,
      },
    ],
  });

  const fetchData = () => {
    let currentDate = startDate;
    let dateArray = [];
    while (currentDate.isBefore(endDate) || currentDate.isSame(endDate)) {
      dateArray.push(currentDate.format("YYYY-MM-DD"));
      currentDate = currentDate.add(1, "day");
    }
    let labelDate = dateArray.map((item) => dayjs(item).format("DD MMM"));
    const colorBox = ["#005c3a", theme.palette.primary.main];

    let dataBox = [];
    dateArray.forEach((itemDate) => {
      let dataFind = dataValues.find(
        (itemFind) =>
          dayjs(itemFind.date, "DD-MM-YYYY HH:mm:ss").format("YYYY-MM-DD") ===
          itemDate
      );
      if (dataFind) {
        dataBox.push({
          date: dayjs(itemDate).format("YYYY-MM-DD"),
          data: {
            total_sale: dataFind.data.total_sale,
            total_receipt: dataFind.data.total_receipt,
            avg_price_per_receipt: dataFind.data.avg_price_per_receipt,
          },
        });
      } else {
        dataBox.push({
          date: dayjs(itemDate).format("YYYY-MM-DD"),
          data: {
            total_sale: 0,
            total_receipt: 0,
            avg_price_per_receipt: 0,
          },
        });
      }
    });

    let datasetsList = dataBox.map((item) => Number(item.data[nameValue]));
    setData({
      labels: labelDate,
      datasets: [
        {
          label: nameValue,
          data: datasetsList,
          borderColor: colorBox[0],
          tension: 0.1,
        },
      ],
    });
  };
  const options = {
    maintainAspectRatio: false,
    scales: {
      x: {
        display: true,
        grid: {
          display: false,
        },
        title: {
          display: window.innerWidth < 600 ? false : true, // Enable the axis title
          text: "Date", // Set the title text
          font: {
            // Optional: Customize title font
            size: window.innerWidth < 600 ? 12 : 16,
            weight: "bold",
          },
        },
      },
      y: {
        display: true,
        grid: {
          display: true,
        },
        ticks: {
          display: window.innerWidth < 600 ? false : true,
          callback: (value) => {
            // Customize the text labels as needed
            return `${symbol ? "฿" : ""} ${value.toLocaleString()}`;
          },
        },
        title: {
          display: true, // Enable the axis title
          text: title ? title : "Total Sales", // Set the title text
          font: {
            // Optional: Customize title font
            size: window.innerWidth < 600 ? 12 : 16,
            weight: "bold",
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false,
        position: "bottom",
      },
      title: {
        display: true,
        text: t(nameValue),
        position: "top",
      },
      // datalabels: {
      //   color: theme.palette.primary.main,
      //   backgroundColor: "#ffffff",
      //   formatter: (value) => `${value.toFixed(2)}`,
      // },
      tooltip: {
        callbacks: {
          title: function (context) {
            return `${dayjs(context[0].label, "DD MMM").format("DD/MMM/YYYY")}`;
          },
          label: function (context) {
            let sumPercent = "";
            if (nameValue === "total_sale") {
              sumPercent = `Total Sales`;
            } else if (nameValue === "total_receipt") {
              sumPercent = `Total Receipt`;
            } else {
              sumPercent = `Average price per receipt`;
            }
            return sumPercent;
          },
          afterLabel: function (context) {
            let sum = Number(context.raw).toLocaleString();
            // let sumPercent = `${((context.raw / total) * 100).toFixed(2)}%`;
            return sum;
          },
        },
      },
    },
  };
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConfirmData]);

  return (
    <div style={{ height: "300px", width: "100%" }}>
      <Line data={data} options={options} />
    </div>
  );
}

export default LineChartComponent;
