import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import "chartjs-plugin-datalabels";
import ChartDataLabels from "chartjs-plugin-datalabels";
import colorDashboard from "../insight/colorDashboard.json";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function StackedBarChart({ props }) {
  let { dataBranch } = props;
  let colorBg = colorDashboard;
  const total = dataBranch.branchBar.reduce(
    (acc, item) => acc + item.amount,
    0
  );
  const datasets = dataBranch?.branchBar.map((item, index) => ({
    label: item.type,
    data: [(item.amount / total) * 100], // Calculate percentage
    backgroundColor: colorBg[index],
  }));
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    indexAxis: "y",
    scales: {
      x: {
        stacked: true,
        display: true,
        grid: {
          display: false,
        },
        ticks: {
          callback: (value) => {
            // Customize the text labels as needed
            return value === "branch all" ? value : `${value}%`;
          },
        },
      },

      y: {
        stacked: true,
        height: 100,
        display: false,
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: true,
      },
      datalabels: {
        display: true,
        color: "white",
        formatter: (value) => (value ? `${value.toFixed(2)}%` : null), // Format percentage to two decimal places
      },
      tooltip: {
        callbacks: {
          title: function (context) {
            return context[0].dataset.label;
          },
          label: function (context) {
            let dataSum = dataBranch?.branchBar[context.datasetIndex];
            let sumPercent = `${((dataSum.amount / total) * 100).toFixed(2)}%`;
            return Number(dataSum.amount).toLocaleString() + `(${sumPercent})`;
          },
        },
      },
    },
  };

  const labels = [""];

  const data = {
    labels,
    datasets: datasets,
  };
  return (
    <div style={{ height: "128px", margin: "20px 0" }}>
      <Bar options={options} plugins={[ChartDataLabels]} data={data} />
    </div>
  );
}

export default StackedBarChart;
