import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { t } from "i18next";
import { NumberDecimal } from "../../../components/utils/numberDecimal";
import dayjs from "dayjs";
import { BoxImageTable } from "../../../components/ui/boxImage/boxImageTable";

function DetailBillReceipt({ props }) {
  let { dialogData, handleClose } = props;
  let {
    receipt_config_receipt,
    receipt_sale_products,
    vat,
    total_round_up,
    grand_total,
    receipt_payment_types,
    invoice_number,
    total_discount,
    receipt_status,
    total_vat,
    type,
    datetime,
    created_by_name,
    receipt_promotions,
    sub_total,
    total_before_vat_after_service_charge,
  } = dialogData.data;
  const [statusButtonPrint, setStatusButtonPrint] = useState(true);
  const onPrintReceipt = async () => {
    setStatusButtonPrint(false);
    setTimeout(() => {
      window.print();
      setTimeout(() => {
        setStatusButtonPrint(true);
      }, 1000);
    }, 1000);
  };
  return (
    <Dialog open={dialogData.status} maxWidth="sm" fullWidth>
      {statusButtonPrint ? (
        <>
          <DialogTitle>{t("report_receipt_bill_detail")}</DialogTitle>
          <IconButton
            aria-label="close"
            onClick={() => handleClose()}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </>
      ) : null}
      <DialogContent align="center">
        <Paper sx={{ maxWidth: "256px", py: 2, px: 2 }} align="center">
          {receipt_status === "void" ? (
            <Typography
              sx={(theme) => ({
                color: "red",
                pb: 1,
                borderBottom: "1px dashed red",
              })}
            >
              Receipt Voided
            </Typography>
          ) : null}
          <Box align="center" sx={{ width: "50%", mb: 2 }}>
            <BoxImageTable
              image={
                receipt_config_receipt
                  ? receipt_config_receipt.config_receipt_lang.logo
                  : null
              }
              width={"100%"}
              height={"100px"}
              type={"table"}
            />
          </Box>
          <Box>
            <Typography
              sx={{
                wordBreak: "break-word",
                fontSize: 14,
                fontWeight: "bold",
              }}
            >
              {receipt_config_receipt
                ? receipt_config_receipt.config_receipt_lang.shop_name
                : null}
            </Typography>
            <Typography
              sx={{
                fontSize: 12,
                wordBreak: "break-word",
              }}
            >
              {receipt_config_receipt
                ? receipt_config_receipt.config_receipt_lang.branch_name
                : null}
            </Typography>
            <Typography
              sx={{
                fontSize: 12,
                wordBreak: "break-word",
              }}
            >
              {receipt_config_receipt
                ? receipt_config_receipt.config_receipt_lang.address
                : null}
            </Typography>
            <Typography
              sx={{
                fontSize: 12,
                wordBreak: "break-word",
              }}
            >
              {receipt_config_receipt ? (
                <>
                  {receipt_config_receipt.config_receipt_lang.tel ? (
                    <>
                      Tel:
                      {receipt_config_receipt
                        ? receipt_config_receipt.config_receipt_lang.tel
                        : null}
                    </>
                  ) : null}
                </>
              ) : null}
            </Typography>
            <Typography
              sx={{
                wordBreak: "break-word",
                fontSize: 14,
                fontWeight: "bold",
              }}
            >
              Receipt
            </Typography>

            {receipt_config_receipt ? (
              <>
                {receipt_config_receipt.config_receipt_lang.text_header ? (
                  <Typography
                    sx={{
                      wordBreak: "break-word",
                      fontSize: 12,
                    }}
                  >
                    {"textHeader"}
                  </Typography>
                ) : null}
              </>
            ) : null}
          </Box>
          <Divider sx={{ my: 1 }} variant="horizontal" />
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              gap: 0.5,
            }}
          >
            <Typography sx={(theme) => ({ fontSize: 12 })}>
              Order Type :
            </Typography>
            <Typography sx={(theme) => ({ fontSize: 12 })}>{type}</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              gap: 0.5,
            }}
          >
            <Typography sx={(theme) => ({ fontSize: 12 })}>Table :</Typography>
            <Typography sx={(theme) => ({ fontSize: 12 })}>0</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              gap: 1,
            }}
          >
            <Typography sx={(theme) => ({ fontSize: 12 })}>Staff :</Typography>
            <Typography sx={(theme) => ({ fontSize: 12 })}>
              {created_by_name}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              gap: 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                gap: 0.5,
              }}
            >
              <Typography sx={(theme) => ({ fontSize: 12 })}>
                Guest :
              </Typography>
              <Typography sx={(theme) => ({ fontSize: 12 })}>
                {type ? 1 : 2}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                gap: 0.5,
              }}
            >
              <Typography sx={(theme) => ({ fontSize: 12 })}>
                Receipt NO :
              </Typography>
              <Typography sx={(theme) => ({ fontSize: 12 })}>
                {invoice_number}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              gap: 0.5,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                gap: 0.5,
              }}
            >
              <Typography sx={(theme) => ({ fontSize: 12 })}>Date :</Typography>
              <Typography sx={(theme) => ({ fontSize: 12 })}>
                {dayjs(datetime).format("DD/MM/YYYY")}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                gap: 0.5,
              }}
            >
              <Typography sx={(theme) => ({ fontSize: 12 })}>Time :</Typography>
              <Typography sx={(theme) => ({ fontSize: 12 })}>
                {dayjs(datetime).format("HH:mm")}
              </Typography>
            </Box>
          </Box>
          <Divider sx={{ my: 1 }} variant="horizontal" />
          <Box align="left">
            {receipt_sale_products.map((itemProduct, index) => (
              <>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      gap: 1,
                    }}
                  >
                    <Typography sx={(theme) => ({ fontSize: 12 })}>
                      {itemProduct.amount}
                    </Typography>
                    <Typography sx={(theme) => ({ fontSize: 12 })}>
                      {itemProduct.name}
                    </Typography>
                  </Box>
                  <Typography sx={(theme) => ({ fontSize: 12 })}>
                    <NumberDecimal
                      number={itemProduct.price * itemProduct.amount}
                      decimal={2}
                    />
                  </Typography>
                </Box>
                {itemProduct.receipt_sale_product_promotions.length > 0 ? (
                  <>
                    {itemProduct.receipt_sale_product_promotions.map(
                      (itemSaleProductPromotion) => (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              gap: 1,
                            }}
                          >
                            <Typography
                              sx={(theme) => ({ fontSize: 12, ml: 1 })}
                            >
                              #{itemSaleProductPromotion.name}
                            </Typography>
                          </Box>
                          <Typography sx={(theme) => ({ fontSize: 12 })}>
                            <NumberDecimal
                              number={
                                itemSaleProductPromotion.price *
                                itemSaleProductPromotion.amount
                              }
                              decimal={2}
                            />
                          </Typography>
                        </Box>
                      )
                    )}
                  </>
                ) : null}
              </>
            ))}
            {/* &&receipt_promotions[0].discount_type === 1 */}
            {receipt_promotions.length > 0 ? (
              <>
                {receipt_promotions
                  .filter((promotion) => promotion.discount_type === 3)
                  .sort((a, b) => a.updated_at.localeCompare(b.updated_at))
                  .map((itemPromotion, index) => (
                    <>
                      {index === 0 ? (
                        <>
                          <Typography
                            sx={(theme) => ({ fontSize: 12, mt: 1 })}
                            key={index}
                          >
                            # {itemPromotion.name}
                          </Typography>
                        </>
                      ) : (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              gap: 1,
                            }}
                          >
                            <Typography sx={(theme) => ({ fontSize: 12 })}>
                              {itemPromotion.amount}
                            </Typography>
                            <Typography sx={(theme) => ({ fontSize: 12 })}>
                              {itemPromotion.name}
                            </Typography>
                          </Box>
                          <Typography sx={(theme) => ({ fontSize: 12 })}>
                            <NumberDecimal
                              number={
                                itemPromotion.price * itemPromotion.amount
                              }
                              decimal={2}
                            />
                          </Typography>
                        </Box>
                      )}
                    </>
                  ))}

                {/* {receipt_promotions.map((itemPromotion, index) => (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: 1,
                      }}
                    >
                      <Typography sx={(theme) => ({ fontSize: 12 })}>
                        {itemPromotion.amount}
                      </Typography>
                      <Typography sx={(theme) => ({ fontSize: 12 })}>
                        {itemPromotion.name}
                      </Typography>
                    </Box>
                    <Typography sx={(theme) => ({ fontSize: 12 })}>
                      <NumberDecimal
                        number={itemPromotion.price * itemPromotion.amount}
                        decimal={2}
                      />
                    </Typography>
                  </Box>
                ))} */}
              </>
            ) : null}
          </Box>

          <Divider sx={{ my: 1 }} variant="horizontal" />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              gap: 1,
            }}
          >
            <Typography sx={(theme) => ({ fontSize: 12 })}>Item</Typography>
            <Typography sx={(theme) => ({ fontSize: 12 })}>
              {receipt_sale_products.reduce(
                (total, product) => total + product.amount,
                0
              )}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              gap: 1,
            }}
          >
            <Typography sx={(theme) => ({ fontSize: 12 })}>Subtotal</Typography>
            <Typography sx={(theme) => ({ fontSize: 12 })}>
              <NumberDecimal number={sub_total} decimal={2} />
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              gap: 1,
            }}
          >
            <Typography sx={(theme) => ({ fontSize: 12 })}>Discount</Typography>
            <Typography sx={(theme) => ({ fontSize: 12 })}>
              <NumberDecimal
                number={
                  total_discount > 0 ? "-" + total_discount : total_discount
                }
                decimal={2}
              />
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              gap: 1,
            }}
          >
            <Typography sx={(theme) => ({ fontSize: 12 })}>Roundup</Typography>
            <Typography sx={(theme) => ({ fontSize: 12 })}>
              <NumberDecimal number={total_round_up} decimal={2} />
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              gap: 1,
            }}
          >
            <Typography sx={(theme) => ({ fontSize: 12 })}>
              Grand Total
            </Typography>
            <Typography sx={(theme) => ({ fontSize: 12, fontWeight: "bold" })}>
              <NumberDecimal number={grand_total} decimal={2} />
            </Typography>
          </Box>
          <Divider sx={{ my: 1 }} variant="horizontal" />
          {receipt_payment_types.map((itemPayment) => (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                gap: 1,
              }}
            >
              <Typography
                sx={(theme) => ({ fontSize: 12, textTransform: "capitalize" })}
              >
                {itemPayment.receipt_payment_channels.length > 0
                  ? itemPayment.receipt_payment_channels[0].name
                  : itemPayment.name}
              </Typography>
              <Typography sx={(theme) => ({ fontSize: 12 })}>
                <NumberDecimal number={itemPayment.received} decimal={2} />
              </Typography>
            </Box>
          ))}
          {receipt_payment_types.some((item) => item.type === "cash") ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                gap: 1,
              }}
            >
              <Typography sx={(theme) => ({ fontSize: 12 })}>Change</Typography>

              <Typography sx={(theme) => ({ fontSize: 12 })}>
                <NumberDecimal
                  number={receipt_payment_types.reduce(
                    (total, itemChange) => total + itemChange.change,
                    0
                  )}
                  decimal={2}
                />
              </Typography>
            </Box>
          ) : null}
          <Divider sx={{ my: 1 }} variant="horizontal" />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              gap: 1,
            }}
          >
            <Typography sx={(theme) => ({ fontSize: 12 })}>
              Total Before VAT
            </Typography>

            <Typography sx={(theme) => ({ fontSize: 12 })}>
              <NumberDecimal
                number={total_before_vat_after_service_charge}
                decimal={2}
              />
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              gap: 1,
            }}
          >
            <Typography sx={(theme) => ({ fontSize: 12 })}>
              VAT {vat}%
            </Typography>
            <Typography sx={(theme) => ({ fontSize: 12 })}>
              <NumberDecimal number={total_vat} decimal={2} />
            </Typography>
          </Box>
          <Divider sx={{ my: 1 }} variant="horizontal" />
          <Box align="center">
            {receipt_config_receipt ? (
              <Typography
                sx={{
                  wordBreak: "break-word",
                  fontSize: 12,
                }}
              >
                {receipt_config_receipt
                  ? receipt_config_receipt.config_receipt_lang.text_footer_1
                  : null}
              </Typography>
            ) : null}
            {receipt_config_receipt ? (
              <>
                {receipt_config_receipt.config_receipt_lang.img_footer ? (
                  <Box align="center" sx={{ width: "50%", mb: 2 }}>
                    <BoxImageTable
                      image={
                        receipt_config_receipt
                          ? receipt_config_receipt.config_receipt_lang
                              .img_footer
                          : null
                      }
                      width={"100%"}
                      height={"100px"}
                      type={"table"}
                    />
                  </Box>
                ) : null}
              </>
            ) : (
              <></>
            )}

            {receipt_config_receipt ? (
              <Typography
                sx={{
                  wordBreak: "break-word",
                  fontSize: 12,
                }}
              >
                {receipt_config_receipt
                  ? receipt_config_receipt.config_receipt_lang.text_footer_2
                  : null}
              </Typography>
            ) : null}
          </Box>
        </Paper>
      </DialogContent>
      <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
        {statusButtonPrint ? (
          <Button
            variant="outlined"
            onClick={() => onPrintReceipt()}
            sx={{ borderRadius: 2 }}
          >
            Print receipt again
          </Button>
        ) : null}
      </DialogActions>
    </Dialog>
  );
}

export default DetailBillReceipt;
