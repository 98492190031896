import axios from "axios";
import {
  AccessTokenAPI,
  BusinessId,
  CompanyId,
  ServiceId,
} from "../../getLocalStorage";

export const updateSelfPickupLineProvider = async (dataAPI) => {
  var config = {
    method: "post",
    url: `https://spos-business-api.anribakery.me/api/setting/selfpickup/updateSelfPickupLineProvider?company_uid=${CompanyId}&business_uid=${BusinessId}&service_code=${ServiceId}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${AccessTokenAPI}`,
    },
    data: JSON.stringify(dataAPI),
  };

  axios(config)
    .then(function (response) {
      console.log(JSON.stringify(response.data));
    })
    .catch(function (error) {
      console.log(error);
    });
};
