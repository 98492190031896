import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Loading from "../loading";
import SearchIcon from "@mui/icons-material/Search";
import { getAllMainCategory } from "../../../service/mainCategory";
import { Clear } from "@mui/icons-material";

function DialogAddCategory({ props }) {
  let {
    categoryAll,
    setCategoryAll,
    dataAllCategory,
    setDataAllCategory,
    dialogCategory,
    setDialogCategory,
  } = props;
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation();
  const [search, setSearch] = useState("");

  const fetchCategory = async () => {
    let categoryAllAPI = await getAllMainCategory();
    let newDataAPI = categoryAllAPI.filter((item) => item.status === true);
    newDataAPI.forEach((itemCate) => {
      itemCate.show = true;
      itemCate.select = false;
    });
    setDataAllCategory(newDataAPI);
    setIsLoading(false);
  };
  const handleSearchProduct = (e) => {
    const dataSearch = e.target.value.toUpperCase();
    setSearch(e.target.value);

    setDataAllCategory((prevData) => {
      const newData = prevData.map((itemCate) => {
        const categoryName = itemCate.main_category_lang.name.toUpperCase();
        itemCate.show = !dataSearch || categoryName.includes(dataSearch);
        return itemCate;
      });
      return newData;
    });
  };
  const onClose = () => {
    setTimeout(() => {
      let dataNews = [...dataAllCategory];
      if (dataNews.length > 0) {
        dataNews.forEach((cate) => {
          cate.show = true;
          if (
            categoryAll.find(
              (cateData) => cateData.name === cate.main_category_lang.name
            )
          ) {
            cate.select = true;
          } else {
            cate.select = false;
          }
        });
      }
      setDataAllCategory(dataNews);
    }, 200);
    setDialogCategory(false);
    setDialogCategory(false);
  };
  const onConfirmCategory = () => {
    setSearch("");
    let dataConfirm = [];
    dataAllCategory.forEach((cate) => {
      cate.show = true;
      if (cate.select) {
        dataConfirm.push({
          id: cate.main_category_lang.main_category_id,
          name: cate.main_category_lang.name,
        });
      }
    });
    setCategoryAll(dataConfirm);
    setDialogCategory(false);
  };
  const onSelectCategory = (item, index) => {
    const newData = [...dataAllCategory];
    newData[index].select = !newData[index].select;
    setDataAllCategory(newData);
  };
  const handleClear = () => {
    setSearch("");
    let dataNews = [...dataAllCategory];
    dataNews.forEach((itemCate) => {
      itemCate.show = true;
    });
    setDataAllCategory(dataNews);
  };
  useEffect(() => {
    fetchCategory();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <Dialog open={dialogCategory} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Typography variant="h5">{t("category_add")}</Typography>
      </DialogTitle>
      <DialogContent>
        {isLoading ? (
          <Loading />
        ) : (
          <Grid container>
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                endAdornment: search && (
                  <InputAdornment position="end">
                    <Clear
                      onClick={handleClear}
                      style={{ cursor: "pointer" }}
                    />
                  </InputAdornment>
                ),
              }}
              value={search}
              onChange={handleSearchProduct}
              placeholder={t("global_search_product")}
              size="small"
              fullWidth
            />
            {dataAllCategory.map((itemCate, indexCate) => (
              <>
                {itemCate?.show ? (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    sx={{ p: 2, boxSizing: "border-box" }}
                  >
                    <Box
                      sx={(theme) => ({
                        boxShadow: 2,
                        boxSizing: "border-box",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        p: 2,
                        borderRadius: 1,
                        backgroundColor: itemCate.select
                          ? theme.palette.primary.main
                          : null,
                        color: itemCate.select
                          ? theme.palette.white.main
                          : null,
                        ":hover": { cursor: "pointer" },
                      })}
                      onClick={() => onSelectCategory(itemCate, indexCate)}
                    >
                      <Checkbox
                        checked={itemCate.select === true}
                        color="white"
                      />
                      <Typography>
                        {itemCate.main_category_lang.name}
                      </Typography>
                    </Box>
                  </Grid>
                ) : null}
              </>
            ))}

            <Grid item xs={12} sm={12}>
              <Box
                sx={(theme) => ({
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  gap: 2,
                })}
              >
                <Button
                  size="large"
                  variant="outlined"
                  onClick={() => onClose()}
                  sx={{ borderRadius: 2 }}
                >
                  {t("cancel_button")}
                </Button>
                <Button
                  size="large"
                  sx={{ borderRadius: 2 }}
                  variant="contained"
                  onClick={() => onConfirmCategory()}
                >
                  {t("confirm_button")}
                </Button>
              </Box>
            </Grid>
          </Grid>
        )}
      </DialogContent>
    </Dialog>
  );
}

export default DialogAddCategory;
