import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { useForm } from "react-hook-form";
import { getPreDefine, updatePreDefine } from "../../../service/preDefineAPI";
import { getAllUnitCounter } from "../../../service/unitCounterAPI";
import { useNavigate } from "react-router-dom";
import { notifySuccess } from "../../../components/ui/popup/toastPopup";
import Loading from "../../../components/ui/loading";
import { getAllPreDefineCategory } from "../../../service/preDefineCategory";
import { useTranslation } from "react-i18next";
import BreadcrumbsTitle from "../../../components/ui/breadcrumbsTitle";
import { useSchema } from "../../../components/hooks/use-schema";
import { productOptionSchema } from "../../../components/validation/productOptionSchema";
import { GridContainerSize } from "../../../components/utils/gridContainerSize";
import ProductOptionsForm from "./productOptionsForm";
function ProductOptionsDetail() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    clearErrors,
    control,
  } = useForm(useSchema(productOptionSchema));
  const { t } = useTranslation();
  const [statusButton, setStatusButton] = useState(true);
  const [categoryProductList, setCategoryProductList] = useState([]);
  const [unitCounterAll, setUnitCounterAll] = useState([]);
  const [statusPrice, setStatusPrice] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [vatStatus, setVatStatus] = useState(false);
  const paramsId =
    window.location.pathname.split("/")[
      window.location.pathname.split("/").length - 1
    ];
  const [amountCount, setAmountCount] = useState(false);
  const onStatusPrice = (status) => {
    setStatusPrice(status);
    if (status === false) {
      setValue("price", "0");
    }
  };
  const fetchDataCategory = async () => {
    let dataAPI = await getAllPreDefineCategory();
    dataAPI = dataAPI.filter((item) => item.status === true);
    setCategoryProductList(dataAPI);
  };
  const fetchDataUnit = async () => {
    let dataAPI = await getAllUnitCounter();
    dataAPI = dataAPI.filter((item) => item.status === true);
    setUnitCounterAll(dataAPI);
  };
  const onConfirmForm = async (register) => {
    setIsSubmitting(true);
    let dataAPI = {
      id: String(paramsId),
      pre_define_category_id: register.productCategory,
      unit_counter_id: register.unit,
      price_status: statusPrice,
      // vat: Number(register.vat),
      vat_status: vatStatus,
      // vat_type: vatType === true ? "include" : "exclude",
      price: statusPrice ? Number(register.price) : 0,
      status: statusButton,
      pre_define_lang: {
        name: register.productOptionName,
        language_code: String(localStorage.getItem("language_form")),
      },
    };
    let statusAPI = await updatePreDefine(dataAPI);
    if (statusAPI === "OK") {
      notifySuccess({
        title: t("notification_successfully_edited_information"),
      });
      navigate("/pre-define-product");
    }
    await new Promise((resolve) => setTimeout(resolve, 2000));
    setIsSubmitting(false);
  };
  const fetchData = async () => {
    await fetchDataCategory();
    await fetchDataUnit();
    let dataAPI = await getPreDefine(paramsId);
    let {
      status,
      stock_count,
      pre_define_lang,
      pre_define_categories,
      vat,
      vat_status,
      unit_counter_id,
      unit_counter,
      price,
      price_status,
    } = dataAPI;
    setStatusButton(status);
    setAmountCount(stock_count);
    setValue("productOptionName", pre_define_lang.name);

    setValue("vat", String(vat));
    setVatStatus(vat_status);
    setValue("unit", unit_counter_id);
    setValue(
      "productCategory",
      pre_define_categories.length > 0 ? pre_define_categories[0].id : ""
    );

    if (unit_counter.status === false) {
      setValue("unit", "");
    } else {
      setValue("unit", unit_counter_id);
    }

    setValue("price", price);
    setStatusPrice(price_status);
    setIsLoading(false);
  };
  const onVatStatus = (status) => {
    setVatStatus(status);
  };
  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <Box>
      <BreadcrumbsTitle
        props={{
          title: t("product_option_info"),
          dataLink: [
            {
              text: t("product_option"),
              link: "/pre-define-product",
            },
            { text: t("product_option_info"), link: null },
          ],
        }}
      />

      {isLoading ? (
        <>
          <Loading />
        </>
      ) : (
        <GridContainerSize>
          <Box p={2}>
            <form onSubmit={handleSubmit(onConfirmForm)}>
              <ProductOptionsForm
                props={{
                  register,
                  errors,
                  watch,
                  setValue,
                  control,
                  clearErrors,
                  categoryProductList,
                  unitCounterAll,
                  vatStatus,
                  onVatStatus,
                  statusPrice,
                  onStatusPrice,
                  amountCount,
                  setAmountCount,
                  statusButton,
                  setStatusButton,
                  isSubmitting,
                }}
              />
            </form>
          </Box>
        </GridContainerSize>
      )}
    </Box>
  );
}

export default ProductOptionsDetail;
