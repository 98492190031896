import React, { useContext, useRef, useState } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  FormControlLabel,
  Grid,
  IconButton,
  Switch,
  Typography,
} from "@mui/material";
import Loading from "../../components/ui/loading";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { useTranslation } from "react-i18next";
import {
  addSliderToTemplate,
  getAllSliderTemplate,
  postSlider,
} from "../../service/sliderAPI";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import { uploadImage } from "../../service/upload/uploadImage";
import { getAllBranch } from "../../service/branchAPI";
import { notifySuccess } from "../../components/ui/popup/toastPopup";
import DeleteIcon from "@mui/icons-material/Delete";
import { useForm } from "react-hook-form";
import BreadcrumbsTitle from "../../components/ui/breadcrumbsTitle";
import TitleForm from "../../components/ui/formComponent/titleForm";
import { useNavigate } from "react-router-dom";
import StatusForm from "../../components/ui/formComponent/statusForm";
import ConfirmButton from "../../components/ui/button/confirmButton";
import SettingTypeBranch from "../../components/ui/addTypeTemplate/settingTypeBranch";
import { WarningPopup } from "../../components/ui/popup/successPopup";
import TextfieldComponent from "../../components/ui/textfield/textfieldComponent";
import SelectComponent from "../../components/ui/textfield/selectComponent";
import screenSize from "../../components/utils/screenSize.json";
import { useSchema } from "../../components/hooks/use-schema";
import { backScreenSchema } from "../../components/validation/backScreenSchema";
import { PaddingContainer } from "../../components/utils/paddingContainer";
import Swal from "sweetalert2";
import { getImage } from "../../service/upload/getImage";
import { UseConvertImageToBase64 } from "../../components/hooks/use-convert-image-to-base-64";
import { BoxImage } from "../../components/ui/boxImage/boxImage";
import PaperWrapper from "../../components/ui/paper/paperWrapper";
import SettingPlatformScreen from "../../assets/images/settingPlatformScreen.png";
import { GridContainerSize } from "../../components/utils/gridContainerSize";
import AwesomeSlider from "react-awesome-slider";
import withAutoplay from "react-awesome-slider/dist/autoplay";
import SunmiDevice from "../../assets/images/Sunmi-T2s-device.png";
import Sunmi3ProDevice from "../../assets/images/sunmi-d3-pro.png";
import { convertToPNG } from "../../components/ui/upload/convertHEIC";
import ImageUploadDialog from "./imageUploadDialog";
import { BoxGapInput } from "../../components/ui/box/boxGapInput";
import { MyContext } from "../../context/MyContext";
import { BoxWrapper } from "../../components/ui/box/boxWrapper";
import { BoxFormTitleAction } from "../../components/ui/box/boxFormTitleAction";
import TitleHeaderForm from "../../components/ui/formComponent/titleHeaderForm";
function BackScreenCreate() {
  const fileInputRef = useRef(null);
  const AutoplaySlider = withAutoplay(AwesomeSlider);
  const {
    register,
    handleSubmit,
    control,
    clearErrors,
    setValue,
    watch,
    formState: { errors },
  } = useForm(useSchema(backScreenSchema));
  let todoItemDrag = useRef();
  let todoItemDragOver = useRef();
  const { t } = useTranslation();
  const [sizeSlider, setSizeSlider] = useState("");
  const [sizeShow, setSizeShow] = useState("");
  const [sizeWHSlider, setSizeWHSlider] = useState({
    width: 1024,
    height: 600,
  });
  const [showTemplate, setShowTemplate] = useState(false);
  const [imageAll, setImageAll] = useState([
    { url: null, priority: 0, status: true, draggable: false },
  ]);
  const [statusButton, setStatusButton] = useState(true);
  const [numImage, setNumImage] = useState(null);
  const [idTemplate, setIdTemplate] = useState(null);
  const [allDataTemplate, setAllDataTemplate] = useState([]);
  const [loadingImage, setLoadingImage] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [dialogCropImage, setDialogCropImage] = useState(false);
  const previewCanvasRef = useRef();
  const [imageUpload, setImageUpload] = useState(null);
  const [crop, setCrop] = useState(null);
  const [completedCrop, setCompletedCrop] = useState();
  const imgRef = useRef();
  const { fetchBackScreen } = useContext(MyContext);

  const aspect =
    sizeWHSlider.width || sizeWHSlider.height
      ? sizeWHSlider.width / sizeWHSlider.height
      : 1 / 1;

  const navigate = useNavigate();
  const onConfirmForm = async (register) => {
    setIsSubmitting(true);
    if (localStorage.getItem("uploadImage")) {
      WarningPopup({ title: t("notification_please_upload_image") });
    } else {
      let imageAllConvert = [];
      let count = 0;
      let imageAllNew = imageAll.filter((itemImage) => itemImage.url !== null);
      imageAllNew.forEach(async (itemImage, index) => {
        delete itemImage.draggable;
        itemImage.priority = index + 1;
        let newImage = "";
        if (typeof itemImage.url === "object") {
          newImage = await uploadImage(itemImage.url);
          imageAllConvert.push({
            priority: itemImage.priority,
            status: itemImage.status,
            url: newImage,
          });
          const img = new Image();
          img.onload = () => {};
          let itemDataImage = await getImage(newImage);
          let itemNewImage = UseConvertImageToBase64(itemDataImage);
          img.src = itemNewImage;
          count = count + 1;
          if (count === imageAllNew.length) {
            let dataAPI = {
              name: register.nameSlider,
              status: statusButton,
              slider_images: imageAllConvert,
              type: sizeSlider === 1 ? "full" : "half",
            };
            let statusAPI = await postSlider(dataAPI);
            if (statusAPI.message === "OK") {
              fetchBackScreen()
                .then(() => {
                  notifySuccess({
                    title: t("toast_successfully_added_information"),
                  });
                  if (statusButton) {
                    setIdTemplate(statusAPI.data.id);
                    fetchAllBranch(statusAPI.data.id);
                    setShowTemplate(true);
                  } else {
                    navigate("/slider");
                  }
                })
                .catch((error) => {
                  console.error("Error fetching product:", error);
                });
            }
          }
        }
      });
    }
    await new Promise((resolve) => setTimeout(resolve, 2000));
    setIsSubmitting(false);
  };
  const fetchAllBranch = async (id) => {
    let dataAPI = await getAllBranch();
    dataAPI = dataAPI.filter((item) => item.branch_lang !== null);
    await fetchSettingSelectTemplate(dataAPI, id);
  };
  const fetchSettingSelectTemplate = async (dataBranch, id) => {
    let dataAPI = await getAllSliderTemplate(id);
    dataAPI = dataAPI[0].template_sliders;
    let dataBox = [];
    dataBranch.forEach((item) => {
      let dataNews = dataAPI.filter(
        (itemTemplate) => itemTemplate.template_id === item.template_id
      );
      if (dataNews.length > 0) {
        dataBox.push({
          branch: item,
          data: dataNews[0],
          select: false,
        });
      } else {
        dataBox.push({
          branch: item,
          data: null,
          select: false,
        });
      }
    });
    await setAllDataTemplate(dataBox);
  };
  const onAddSliderToTemplate = async () => {
    let branchArray = allDataTemplate.filter((item) => item.select === true);
    let dataSelect = [];
    branchArray.forEach((item) => {
      dataSelect.push(item.branch.id);
    });
    let typeOfSlider = sizeSlider === 1 ? "full" : "half";
    let dataAPI = {
      slider_id: idTemplate,
      branches: dataSelect,
      type: typeOfSlider,
    };
    let dataStatus = await addSliderToTemplate(dataAPI);
    if (dataStatus === "OK") {
      notifySuccess({
        title: t("toast_successfully_added_information"),
      });
      navigate("/slider");
    }
  };
  // const processImage = async (file, numImage) => {
  //   return new Promise((resolve, reject) => {
  //     let img = new Image();
  //     img.onload = () => {
  //       let canvas = document.createElement("canvas");
  //       let context = canvas.getContext("2d");
  //       canvas.width = sizeWHSlider.width;
  //       canvas.height = sizeWHSlider.height;
  //       context.drawImage(img, 0, 0, sizeWHSlider.width, sizeWHSlider.height);
  //       let resizedImageUrl = canvas.toDataURL(file.type);
  //       let fileConvert = base64StringToFile(
  //         resizedImageUrl,
  //         "productImage.png"
  //       );
  //       resolve(fileConvert);
  //     };
  //     img.onerror = (error) => reject(error);
  //     img.src = URL.createObjectURL(file);
  //   });
  // };

  function D_Start(e, index) {
    todoItemDrag.current = index;
  }
  function D_Enter(e, index) {
    todoItemDragOver.current = index;
    const newItems = [...imageAll];
    newItems.forEach((item) => {
      item.draggable = false;
    });
    newItems[index].draggable = true;
    setImageAll(newItems);
  }
  function D_End(e, index) {
    const newItems = [...imageAll];
    let todo_item_main = newItems[todoItemDrag.current];
    newItems.splice(todoItemDrag.current, 1);
    newItems.splice(todoItemDragOver.current, 0, todo_item_main);
    todoItemDrag.current = null;
    todoItemDragOver.current = null;
    newItems.forEach((item) => {
      item.draggable = false;
    });
    setImageAll(newItems);
  }
  const removeUploadDataImage = async (item) => {
    if (imageAll.length > 0) {
      let array = [...imageAll];
      let index = array.indexOf(item);
      if (index !== -1) {
        array.splice(index, 1);
        setImageAll(array);
      }
    }
    if (imageAll.length === 1) {
      setImageAll([{ url: null, priority: 0, status: true }]);
    }
  };
  const onUploadFile = async (e) => {
    localStorage.setItem("uploadImage", true);
    const file = e.target.files[0];
    let newImage = null;
    if (file.type === "image/heic") {
      const pngBlob = await convertToPNG(file);
      newImage = URL.createObjectURL(pngBlob);
    } else {
      newImage = URL.createObjectURL(file);
    }
    setCrop(null);
    setCompletedCrop(null);

    setImageUpload(newImage);
    setDialogCropImage(true);
  };

  const onChangeSizeSlider = (itemSlider) => {
    let dataFindScreenSize = screenSize.find(
      (screen) => screen.id === itemSlider
    );
    let dataSizeSlider = dataFindScreenSize.name.includes("Full") ? 1 : 2;
    setValue("selectSize", itemSlider);
    setSizeShow(dataFindScreenSize.name);
    if (sizeSlider) {
      Swal.fire({
        title: t("notification_confirm_change_size_screen"),
        text: t("notification_if_you_change_screen_size_image_will_reset"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Confirm",
      }).then((result) => {
        if (result.isConfirmed) {
          setSizeSlider(dataSizeSlider);
          let dataWH = screenSize.find((screen) => screen.id === itemSlider);
          setSizeWHSlider({
            width: dataWH.value.width,
            height: dataWH.value.height,
          });
          setImageAll([
            { url: null, priority: 0, status: true, draggable: false },
          ]);
        }
      });
    } else {
      setSizeSlider(dataSizeSlider);
      let dataWH = screenSize.find((screen) => screen.id === itemSlider);
      setSizeWHSlider({
        width: dataWH.value.width,
        height: dataWH.value.height,
      });
      setImageAll([{ url: null, priority: 0, status: true, draggable: false }]);
    }
  };
  const onAddImage = async () => {
    let dataAdd = { url: null, priority: 0, status: true, draggable: false };
    let dataImageAll = [...imageAll];
    dataImageAll.push(dataAdd);
    setImageAll(dataImageAll);
  };
  const onChangeStatus = (index) => async (e) => {
    let dataNews = [...imageAll];
    dataNews[index].status = e.target.checked;
    setImageAll(dataNews);
  };

  return (
    <Box>
      <BreadcrumbsTitle
        props={{
          title: t("back_screen_create"),
          dataLink: [
            {
              text: t("back_screen"),
              link: "/slider",
            },
            {
              text: t("back_screen_create"),
              link: null,
            },
          ],
        }}
      />
      <GridContainerSize>
        <form onSubmit={handleSubmit(onConfirmForm)}>
          <BoxWrapper>
            <PaperWrapper props={{ growFull: 1 }}>
              <BoxFormTitleAction>
                <TitleHeaderForm props={{ text: t("back_screen") }} />
                <StatusForm
                  statusButton={statusButton}
                  setStatusButton={setStatusButton}
                />
              </BoxFormTitleAction>
              <BoxGapInput>
                <Box p={PaddingContainer.size}>
                  <Box className="boxWrapper-container">
                    <PaperWrapper props={{ growFull: 1 }}>
                      <TitleForm props={{ text: t("back_screen_info") }} />
                      <BoxGapInput>
                        <TextfieldComponent
                          title={t("back_screen_name")}
                          name="nameSlider"
                          register={register}
                          placeholder={t("back_screen_name_example")}
                          errors={errors}
                        />
                        <SelectComponent
                          title={t("back_screen_size_screen")}
                          register={register}
                          name="selectSize"
                          errors={errors}
                          dataEmpty={t("back_screen_size_screen_select")}
                          optionsData={screenSize}
                          dataValue={sizeSlider}
                          onFunctionName={onChangeSizeSlider}
                          clearErrors={clearErrors}
                          control={control}
                          watch={watch}
                        />
                      </BoxGapInput>
                    </PaperWrapper>
                    {sizeSlider ? (
                      <>
                        <PaperWrapper props={{ growFull: 1 }}>
                          <TitleForm
                            props={{ text: t("back_screen_sequence_image") }}
                          />
                          <Grid container p={PaddingContainer.size}>
                            {sizeSlider ? (
                              <>
                                {loadingImage ? (
                                  <>
                                    <Loading />
                                  </>
                                ) : (
                                  <>
                                    {imageAll.map((itemImage, index) => (
                                      <Grid
                                        item
                                        xs={12}
                                        sm={sizeSlider === 1 ? 6 : 4}
                                        lg={sizeSlider === 1 ? 4 : 6}
                                        // xl={sizeSlider === 1 ? 3 : 3}
                                        key={index}
                                        draggable
                                        droppable
                                        onDragStart={(e) =>
                                          itemImage.url
                                            ? D_Start(e, index)
                                            : null
                                        }
                                        onDragEnter={(e) =>
                                          itemImage.url
                                            ? D_Enter(e, index)
                                            : null
                                        }
                                        onDragEnd={(e) =>
                                          itemImage.url ? D_End(e, index) : null
                                        }
                                        sx={(theme) => ({
                                          transition: "all 0.2s ease-in-out",
                                          border: itemImage.draggable
                                            ? `10px solid ${theme.palette.primary.main}`
                                            : null,
                                          opacity: itemImage.draggable
                                            ? 0.5
                                            : 1,
                                          boxSizing: "border-box",
                                          p: 1,
                                        })}
                                      >
                                        <Box
                                          sx={(theme) => ({
                                            boxSizing: "border-box",
                                            boxShadow: 2,
                                            p: 1,
                                            borderRadius: 1,
                                          })}
                                        >
                                          {itemImage.url ? (
                                            <Box
                                              sx={(theme) => ({
                                                display: "flex",
                                                justifyContent: "center",
                                                transform: "rotate(-90deg)",
                                                ":hover": {
                                                  cursor: "grab",
                                                },
                                              })}
                                            >
                                              <DragIndicatorIcon />
                                            </Box>
                                          ) : null}
                                          <label htmlFor="icon-button-image-slider-update">
                                            {itemImage.url ? (
                                              <BoxImage
                                                image={itemImage.url}
                                                height={sizeWHSlider.height}
                                                width={sizeWHSlider.width}
                                                type="table"
                                                borderRadius={null}
                                              />
                                            ) : (
                                              <Box
                                                onClick={() =>
                                                  setNumImage(index)
                                                }
                                              >
                                                <Box
                                                  sx={(theme) => ({
                                                    border:
                                                      "2px solid #cccccc80",
                                                    borderRadius: 1,
                                                    gap: 1,
                                                    p: 2,
                                                    boxSizing: "border-box",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    ":hover": {
                                                      opacity: 0.8,
                                                      cursor: "pointer",
                                                    },
                                                    backgroundColor:
                                                      "#cccccc50",
                                                    maxHeight:
                                                      sizeWHSlider.height,
                                                    maxWidth:
                                                      sizeWHSlider.width,
                                                    height: "100%",
                                                    width: "100%",
                                                    display: "flex",
                                                  })}
                                                >
                                                  <Box sx={{ width: "100%" }}>
                                                    <Box
                                                      sx={{
                                                        display: "flex",
                                                        gap: 1,
                                                        backgroundColor:
                                                          "#00000090",
                                                        color: "#fff",
                                                        borderRadius: 1,
                                                        boxSizing: "border-box",
                                                        p: 1,
                                                        justifyContent:
                                                          "center",
                                                        algnItems: "center",
                                                      }}
                                                    >
                                                      <AddAPhotoIcon />
                                                      <Typography>
                                                        Add Photo
                                                      </Typography>
                                                    </Box>
                                                    <Box my={1} align="center">
                                                      <Typography
                                                        sx={{ opacity: 0.5 }}
                                                      >
                                                        Recommended size <br />
                                                        {
                                                          sizeWHSlider.width
                                                        } x{" "}
                                                        {sizeWHSlider.height} PX{" "}
                                                        <br /> Format suggest
                                                        JPG,PNG
                                                      </Typography>
                                                    </Box>
                                                  </Box>
                                                </Box>
                                              </Box>
                                            )}
                                            <input
                                              ref={fileInputRef}
                                              accept="image/*"
                                              id="icon-button-image-slider-update"
                                              type="file"
                                              key={index}
                                              style={{
                                                display: "none",
                                                width: "100%",
                                                height: "160px",
                                              }}
                                              onChange={onUploadFile}
                                              name="imageDataUpdate"
                                              // multiple
                                            />
                                          </label>
                                          <Box
                                            sx={{
                                              display: "flex",
                                              justifyContent: "space-between",
                                              alignItems: "center",
                                            }}
                                          >
                                            <FormControlLabel
                                              control={
                                                <Switch
                                                  onChange={onChangeStatus(
                                                    index
                                                  )}
                                                  checked={itemImage.status}
                                                />
                                              }
                                              label={t("global_status")}
                                            />
                                            <IconButton
                                              onClick={() =>
                                                removeUploadDataImage(itemImage)
                                              }
                                              disabled={!itemImage.url}
                                            >
                                              <DeleteIcon />
                                            </IconButton>
                                          </Box>
                                        </Box>
                                      </Grid>
                                    ))}
                                  </>
                                )}
                              </>
                            ) : null}
                          </Grid>
                        </PaperWrapper>
                        <PaperWrapper props={{ growFull: 1 }}>
                          <TitleForm
                            props={{ text: t("back_screen_example_slider") }}
                          />
                          <Box
                            sx={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "center",
                              p: 2,
                              boxSizing: "border-box",
                              position: "relative",
                            }}
                          >
                            <Box sx={{ margin: "auto", width: "80%" }}>
                              {sizeShow.includes("T2s") ? (
                                <img
                                  src={SunmiDevice}
                                  alt="SunmiDevice"
                                  width="100%"
                                />
                              ) : (
                                <img
                                  src={Sunmi3ProDevice}
                                  alt="Sunmi3ProDevice"
                                  width="100%"
                                />
                              )}
                            </Box>
                            <Box
                              sx={(theme) => ({
                                width: sizeShow.includes("T2s") ? "62%" : "32%",
                                position: "absolute",
                                top: sizeShow.includes("T2s") ? "10%" : "35%",
                              })}
                            >
                              {sizeSlider === 1 ? (
                                <>
                                  <AutoplaySlider
                                    play={true}
                                    organicArrows={false}
                                    infinite={true}
                                    bullets={false}
                                    mobileTouch={true}
                                    cancelOnInteraction={false}
                                    interval={400}
                                  >
                                    {imageAll
                                      .filter((item) => item.url !== null)
                                      .map((itemImage, index) => (
                                        <>
                                          <div>
                                            <>
                                              <BoxImage
                                                image={itemImage.url}
                                                height={sizeWHSlider.height}
                                                width={sizeWHSlider.width}
                                                type="table"
                                              />
                                            </>
                                          </div>
                                        </>
                                      ))}
                                  </AutoplaySlider>
                                </>
                              ) : (
                                <>
                                  <Grid container>
                                    <Grid
                                      item
                                      xs={6}
                                      sm={6}
                                      sx={{ backgroundColor: "#ffffff" }}
                                    >
                                      <AutoplaySlider
                                        play={true}
                                        organicArrows={false}
                                        infinite={true}
                                        mobileTouch={true}
                                        bullets={false}
                                        cancelOnInteraction={false} // should stop playing on user interaction
                                        interval={400}
                                        style={{ height: "100%" }}
                                      >
                                        {imageAll
                                          .filter((item) => item.url !== null)
                                          .map((itemImage, index) => (
                                            <>
                                              <BoxImage
                                                image={itemImage.url}
                                                height={sizeWHSlider.height}
                                                width={sizeWHSlider.width}
                                                type="table"
                                              />
                                            </>
                                          ))}
                                      </AutoplaySlider>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={6}
                                      sm={6}
                                      sx={{ backgroundColor: "#ffffff" }}
                                    >
                                      <img
                                        src={SettingPlatformScreen}
                                        alt="SettingPlatformScreen"
                                        width="100%"
                                      />
                                    </Grid>
                                  </Grid>
                                </>
                              )}
                            </Box>
                          </Box>
                        </PaperWrapper>
                      </>
                    ) : null}

                    <ConfirmButton disabled={isSubmitting} />
                  </Box>
                </Box>
              </BoxGapInput>
            </PaperWrapper>
          </BoxWrapper>

          <Dialog open={showTemplate} maxWidth="md" fullWidth>
            <DialogContent>
              <SettingTypeBranch
                title={t("back_screen_branch")}
                cancelPath={"/slider"}
                onAddTypeToTemplate={onAddSliderToTemplate}
                allDataTemplate={allDataTemplate}
                setAllDataTemplate={setAllDataTemplate}
              />
            </DialogContent>
          </Dialog>
          <ImageUploadDialog
            props={{
              onAddImage,
              setDialogCropImage,
              setImageUpload,
              setImageAll,
              sizeWHSlider,
              setLoadingImage,
              imageAll,
              previewCanvasRef,
              numImage,
              imageUpload,
              setCompletedCrop,
              setCrop,
              completedCrop,
              aspect,
              dialogCropImage,
              imgRef,
              crop,
            }}
          />
        </form>
      </GridContainerSize>
    </Box>
  );
}

export default BackScreenCreate;
