import { Avatar, Box, Button, Typography } from "@mui/material";
import React from "react";
import InfoIcon from "@mui/icons-material/Info";
import { NumberDecimal } from "../../../../components/utils/numberDecimal";
import basicTime from "../../../../assets/svg/dashboard/basicTime.svg";
import { RadioButtonChecked, RadioButtonUnchecked } from "@mui/icons-material";

const ContributionsDashboard = ({ props }) => {
  let {
    dataPeakHour,
    dataHeatMap,
    statusMap,
    setStatusMap,
    isHovered,
    handleMouseEnter,
    handleMouseLeave,
  } = props;
  const hoursOfDay = Array.from({ length: 24 }, (_, index) => index); // 0 to 23
  return (
    <Box
      sx={{ p: 2, boxSizing: "border-box", borderRadius: 2, height: "100%" }}
      className="grid-paper-white"
    >
      <Box
        sx={(theme) => ({
          [theme.breakpoints.down("sm")]: {
            display: "inline",
          },
          display: "flex",
          justifyContent: "space-between",
          color: theme.palette.primary.main,
        })}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            gap: 1,
          }}
        >
          <Avatar
            src={basicTime}
            sx={{ borderRadius: 0, width: 32, height: 32 }}
          />
          <Box sx={{ display: "flex", gap: 0.5 }}>
            <Box>
              <Typography
                sx={(theme) => ({
                  color: theme.palette.primaryText.main,
                })}
                variant="h3"
              >
                Peak hours :{" "}
                {statusMap === "grand_total" ? "Total Sales" : "Total Bill"}
              </Typography>

              <Typography
                sx={(theme) => ({
                  color: theme.palette.secondaryText.main,
                })}
                variant="h5"
              >
                Shows how busy your store typically is, based on when orders are
                placed or scheduled.
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: 2,
            justifyContent: {
              xs: "flex-end",
            },
            my: {
              xs: 2,
            },
          }}
        >
          <Button
            disableFocusRipple={true}
            disableRipple={true}
            disableElevation={true}
            // variant="contained"
            variant={statusMap === "grand_total" ? "contained" : "outlined"}
            color={statusMap === "grand_total" ? "success" : "inherit"}
            startIcon={
              statusMap === "grand_total" ? (
                <RadioButtonChecked />
              ) : (
                <RadioButtonUnchecked />
              )
            }
            size="small"
            sx={(theme) => ({
              // color: "#005c3a",
              // borderRadius: 2,
              // backgroundColor: statusMap === "grand_total" ? "#dbf9df" : "#fff",
              // boxShadow: 0,
              // border: "1px solid #005c3a80",
              // [theme.breakpoints.down("sm")]: {
              //   width: "100%",
              // },
              // ":hover": { backgroundColor: "#dbf9df" },
            })}
            onClick={() => setStatusMap("grand_total")}
            // disabled={statusMap === "grand_total"}
          >
            Total Sales
          </Button>
          <Button
            disableFocusRipple={true}
            disableRipple={true}
            disableElevation={true}
            // disabled={statusMap === "amount"}
            startIcon={
              statusMap === "amount" ? (
                <RadioButtonChecked />
              ) : (
                <RadioButtonUnchecked />
              )
            }
            variant={statusMap === "amount" ? "contained" : "outlined"}
            color={statusMap === "amount" ? "success" : "inherit"}
            size="small"
            sx={(theme) => ({
              // borderRadius: 2,
              // color: "#005c3a",
              // backgroundColor: statusMap === "amount" ? "#dbf9df" : "#fff",
              // boxShadow: 0,
              // [theme.breakpoints.down("sm")]: {
              //   width: "100%",
              // },
              // border: "1px solid #005c3a80",
              // ":hover": { backgroundColor: "#dbf9df" },
            })}
            onClick={() => setStatusMap("amount")}
          >
            Total Bill
          </Button>
        </Box>
      </Box>

      <Box sx={{ display: "flex", gap: 2, mt: 1 }}>
        <Typography
          sx={(theme) => ({ color: theme.palette.secondaryText.main })}
        >
          Peak hours
        </Typography>
        <Box sx={{ display: "flex" }}>
          {["#dbf9df", "#c5f6c7", "#75d387", "#00b752", "#005c3a"]
            .reverse()
            .map((item) => (
              <Box
                sx={{
                  backgroundColor: item,
                  width: "25px",
                  height: "25px",
                }}
              ></Box>
            ))}
        </Box>
        <Typography
          sx={(theme) => ({ color: theme.palette.secondaryText.main })}
        >
          Non peak hours
        </Typography>
      </Box>
      <Box
        sx={(theme) => ({
          my: 2,
          color: theme.palette.secondaryText.main,
          overflow: "auto",
          width: "100%",
        })}
      >
        <table>
          <tfoot>
            <tr>
              <th></th> {/* Empty cell for the corner */}
              {hoursOfDay.map((hour) => (
                <th
                  style={{ fontSize: 10, width: "4vw" }}
                  key={hour}
                >{`${String(hour).padStart(2, "0")}:00`}</th>
              ))}
            </tr>
          </tfoot>
          <tbody>
            {dataHeatMap[statusMap].map((row, indexRow) => (
              <tr key={row.day}>
                <td>{row.day.substring(0, 3).toUpperCase()}</td>
                {row.data.map((cell, indexCell) => (
                  <td
                    key={`${cell.time}`}
                    style={{
                      backgroundColor: cell.backgroundColor,
                      borderRadius: "5px",
                      // position: "relative",
                    }}
                    className="heatMapItem"
                    onMouseEnter={() =>
                      handleMouseEnter({
                        rowNo: indexRow,
                        cellNo: indexCell,
                        data: {
                          day: row.day,
                          color: cell.backgroundColor,
                          time: cell.time,
                          grand_total: cell.grand_total,
                          amount: cell.amount,
                        },
                      })
                    }
                    onMouseLeave={handleMouseLeave}
                  >
                    {isHovered.rowNo === indexRow &&
                    isHovered.cellNo === indexCell ? (
                      <Box
                        sx={(theme) => ({
                          position: "absolute",
                          zIndex: 99,
                          backgroundColor: "#fff",
                          p: 1,
                          mt: 2,
                          borderRadius: "5px",
                          boxShadow: 1,
                          boxSizing: "border-box",
                        })}
                      >
                        <Typography
                          sx={{ fontSize: 14, textTransform: "capitalize" }}
                        >
                          {isHovered.data.day.substring(0, 3)}
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 2,
                          }}
                        >
                          <Box
                            sx={(theme) => ({
                              width: "10px",
                              height: "10px",
                              borderRadius: "50%",
                              backgroundColor: isHovered.data.color,
                            })}
                          ></Box>
                          <Typography sx={{ fontSize: 14 }}>
                            {isHovered.data.time.substring(0, 2)}:
                            {isHovered.data.time.substring(2, 4)}:
                          </Typography>
                          <Typography sx={{ fontSize: 14 }}>
                            <NumberDecimal
                              number={isHovered.data[statusMap]}
                              decimal={0}
                            />
                          </Typography>
                        </Box>
                      </Box>
                    ) : null}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </Box>
      <Box
        sx={{
          width: "100%",
          backgroundColor: "#dbf9df",
          display: "flex",
          gap: 2,
          p: 1,
          borderRadius: "25px",
          boxSizing: "border-box",
          color: "#005c3a",
        }}
      >
        <InfoIcon />
        <Typography>Peak hour of today is {dataPeakHour}</Typography>
      </Box>
    </Box>
  );
};

export default ContributionsDashboard;

// import React, { useEffect } from "react";
// import Chart from "react-apexcharts";

// const ContributionDashboard = ({ props }) => {
//   let { dataHeatMap } = props;
//   const chartData = {
//     options: {
//       chart: {
//         type: "heatmap",
//       },
//       dataLabels: {
//         enabled: false,
//       },
//       colors: ["#dbf9df", "#c5f6c7", "#75d387", "#00b752", "#005c3a"],
//       xaxis: {
//         type: "category",
//       },
//     },
//     series: dataHeatMap,
//   };

//   useEffect(() => {
//     // You can fetch or update data here
//   }, []); // Empty dependency array means it will run only once after the initial render

//   return (
//     <div>
//       <Chart
//         options={chartData.options}
//         series={chartData.series}
//         type="heatmap"
//         width="500"
//       />
//     </div>
//   );
// };

// export default ContributionDashboard;
