import React, { useState } from "react";
import SelfPickupBankAccountForm from "./selfPickupBankAccountForm";
import { useForm } from "react-hook-form";
import BreadcrumbsTitle from "../../../components/ui/breadcrumbsTitle";
import { t } from "i18next";
import { Box } from "@mui/material";
import StatusForm from "../../../components/ui/formComponent/statusForm";
import ConfirmButton from "../../../components/ui/button/confirmButton";
import { storeBankAccount } from "../../../service/selfPickUp/bankaccount/storeBankAccount";
import { notifySuccess } from "../../../components/ui/popup/toastPopup";
import { Navigate } from "react-router-dom";

function SelfPickupBankAccountCreate() {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const [statusButton, setStatusButton] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [promptPay, setPromptPay] = useState(false);
  const onConfirmForm = async (register) => {
    let { accountName, bankName, branchName, accountNo } = register;
    setIsSubmitting(true);
    let dataAPI = {
      account_name: accountName,
      bank_name: bankName,
      branch_name: branchName,
      account_no: accountNo,
      promptpay: promptPay,
      image: "",
      status: statusButton,
    };
    let statusAPI = await storeBankAccount(dataAPI);
    if (statusAPI === "OK") {
      notifySuccess({
        title: t("toast_successfully_added_information"),
      });
      Navigate("/self-pickup-admin-bank-account");
    }
    await new Promise((resolve) => setTimeout(resolve, 2000));
    setIsSubmitting(false);
  };
  return (
    <Box>
      <BreadcrumbsTitle
        props={{
          title: t("self_pickup_admin_bank_account"),
          dataLink: [
            {
              text: t("self_pickup_admin_bank_account"),
              link: "/self-pickup-admin-bank-account",
            },
            { text: t("self_pickup_admin_bank_account_create"), link: null },
          ],
        }}
      />
      <Box p={2}>
        <form onConfirm={handleSubmit(onConfirmForm)}>
          <SelfPickupBankAccountForm
            props={{ register, errors, promptPay, setPromptPay }}
          />
        </form>
        <StatusForm
          statusButton={statusButton}
          setStatusButton={setStatusButton}
        />
        <ConfirmButton disabled={isSubmitting} />
      </Box>
    </Box>
  );
}

export default SelfPickupBankAccountCreate;
