import { Box } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";

import { t } from "i18next";
// import CloseIcon from "@mui/icons-material/Close";
import dayjs from "dayjs";
import BreadcrumbsTitle from "../../../components/ui/breadcrumbsTitle";
import SelectCheckMarkBranch from "../../../components/ui/select/selectCheckMarkBranch";
import { TextTable } from "../../../components/ui/textfield/textfieldCustom";
import { MyContext } from "../../../context/MyContext";
import DataTableReport from "../../../components/ui/dataTable/dataTableReport";
import i18n from "../../../locals/i18n";
import ReceiptSummaryDetail from "../reportByDateDrawer/receiptSummaryDetail";

function ReportByBillDetail() {
  const [rows, setRows] = useState([]);

  const [columns, setColumns] = useState([]);
  const [exportColumnName, setExportColumnName] = useState(null);
  const [dialogSummary, setDialogSummary] = useState({
    status: false,
    data: null,
  });
  const {
    setBranchSelectReport,
    branchSelectReportDate,
    reportAll,
    fetchReport,
  } = useContext(MyContext);

  const [isLoadingData, setIsLoadingData] = useState(
    reportAll.length > 0 ? false : true
  );
  const onDialogSummary = async (dataAPI) => {
    setDialogSummary({
      status: true,
      data: dataAPI,
    });
  };
  const onCloseDialogSummary = () => {
    setDialogSummary({
      status: false,
      data: null,
    });
  };
  const fetchData = async (dataReportNew) => {
    let headers = [];
    let dataBox = [];
    if (reportAll) {
      reportAll.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));
      // eslint-disable-next-line array-callback-return
      reportAll.map((item) => {
        item.receipt_sale_products.forEach((itemProduct) => {
          let sumDiscountByItems =
            itemProduct.receipt_sale_product_promotions?.reduce(
              (acc, product) => (acc += product.price),
              0
            );
          let subTotal = itemProduct.price * itemProduct.amount;
          let saleProductPromotionDiscount =
            itemProduct.receipt_sale_product_promotions?.reduce(
              (acc, product) => (acc += product.price),
              0
            );
          dataBox.push({
            data: item,
            paymentDate: dayjs(item.datetime).format("DD/MM/YYYY"),
            paymentTime: dayjs(item.datetime).format("HH:mm"),
            receiptNumber: item.invoice_number,
            menuName: itemProduct.name,
            orderType: item.type,
            quantity: itemProduct.amount,
            pricePerUnit: itemProduct.price,
            grossSale: subTotal,
            discountByItems: sumDiscountByItems,
            summaryPrice: subTotal - saleProductPromotionDiscount,
            paymentType: [
              ...new Set(item.receipt_payment_types.map((item) => item.name)),
            ],
            paymentTypeChannel:
              item.receipt_payment_types[0]?.receipt_payment_channels.length > 0
                ? item.receipt_payment_types.map(
                    (item) => item.receipt_payment_channels[0].name
                  )
                : null,
            drawerId: item.open_close_uid
              .substring(
                item.open_close_uid.length - 5,
                item.open_close_uid.length
              )
              .toUpperCase(),
            billOpenBy: item.created_by_name,
            billCloseBy: item.created_by_name,
            branchName: item.receipt_config_receipt
              ? item.receipt_config_receipt.config_receipt_lang.branch_name
              : null,
          });
        });
      });
      dataBox.sort(
        (a, b) =>
          dayjs(b.paymentDate, "DD/MM/YYYY") -
          dayjs(a.paymentDate, "DD/MM/YYYY")
      );

      // dataBox.push({
      //   paymentDate: "Total",
      //   paymentTime: null,
      //   receiptNumber: null,
      //   menuName: null,
      //   orderType: null,
      //   quantity: dataBox.reduce((acc, item) => acc + item.quantity, 0),
      //   pricePerUnit: dataBox.reduce((acc, item) => acc + item.pricePerUnit, 0),
      //   grossSale: dataBox.reduce((acc, item) => acc + item.grossSale, 0),
      //   discountByItems: dataBox.reduce(
      //     (acc, item) => acc + item.discountByItems,
      //     0
      //   ),
      //   summaryPrice: dataBox.reduce((acc, item) => acc + item.summaryPrice, 0),
      //   paymentType: null,
      //   paymentTypeChannel: null,
      //   drawerId: null,
      //   billOpenBy: null,
      //   billCloseBy: null,
      //   branchName: null,
      // });

      setRows(dataBox);
    }

    let dataColumns = [
      {
        header: t("report_payment_date"),
        accessorKey: "paymentDate",
        isSorting: true,
        minWidth: i18n.language === "th" ? 100 : 100,
        Cell: ({ cell }) => <TextTable props={{ text: cell.getValue() }} />,
      },
      {
        header: t("report_payment_time"),
        accessorKey: "paymentTime",
        isSorting: true,
        minWidth: i18n.language === "th" ? 110 : 110,
        Cell: ({ cell }) => <TextTable props={{ text: cell.getValue() }} />,
      },
      {
        header: t("report_receipt_number"),
        accessorKey: "receiptNumber",
        minWidth: i18n.language === "th" ? 150 : 100,
        Cell: ({ cell }) => (
          <Box
            onClick={() => onDialogSummary(cell.row.original)}
            sx={(theme) => ({
              color: theme.palette.secondary.main,
              ":hover": {
                opacity: 0.7,
                textDecoration: "underline",
                cursor: "pointer",
                color: theme.palette.primary.main,
              },
            })}
          >
            <TextTable
              props={{
                text: cell.getValue(),
              }}
            />
          </Box>
        ),
      },
      {
        header: t("report_menu_name"),
        accessorKey: "menuName",
        Cell: ({ cell }) => <TextTable props={{ text: cell.getValue() }} />,
      },
      {
        header: t("report_order_type"),
        accessorKey: "orderType",
        minWidth: i18n.language === "th" ? 110 : 100,
        Cell: ({ cell }) => <TextTable props={{ text: cell.getValue() }} />,
      },
      {
        header: t("report_quantity"),
        accessorKey: "quantity",
        minWidth: i18n.language === "th" ? 80 : 80,
        isSorting: true,
        Cell: ({ cell }) => (
          <TextTable
            props={{
              text: Number(cell.getValue()).toLocaleString(undefined, {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              }),
              alignText: "right",
            }}
          />
        ),
      },

      {
        header: t("report_price_per_unit"),
        accessorKey: "pricePerUnit",
        isSorting: true,
        minWidth: i18n.language === "th" ? 110 : 100,
        Cell: ({ cell }) => (
          <TextTable
            props={{
              text: Number(cell.getValue()).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }),
              alignText: "right",
            }}
          />
        ),
      },

      {
        header: t("report_gross_sale"),
        accessorKey: "grossSale",
        isSorting: true,
        minWidth: i18n.language === "th" ? 100 : 100,
        Cell: ({ cell }) => (
          <TextTable
            props={{
              text: Number(cell.getValue()).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }),
              alignText: "right",
            }}
          />
        ),
      },
      {
        header: t("report_discount_by_item"),
        accessorKey: "discountByItems",
        isSorting: true,
        minWidth: i18n.language === "th" ? 110 : 120,
        Cell: ({ cell }) => (
          <TextTable
            props={{
              text: Number(cell.getValue()).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }),
              alignText: "right",
            }}
          />
        ),
      },
      {
        header: t("report_summary_price"),
        accessorKey: "summaryPrice",
        isSorting: true,
        minWidth: i18n.language === "th" ? 110 : 120,
        Cell: ({ cell }) => (
          <TextTable
            props={{
              text: Number(cell.getValue()).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }),
              alignText: "right",
            }}
          />
        ),
      },
      {
        header: t("report_payment_type"),
        accessorKey: "paymentType",
        minWidth: i18n.language === "th" ? 140 : 120,
        Cell: ({ cell }) => (
          <Box>
            <TextTable
              props={{
                text: cell.getValue()
                  ? cell
                      .getValue()
                      .sort((a, b) => {
                        if (a.paymentType < b.paymentType) return -1;
                        if (a.paymentType > b.paymentType) return 1;
                        return 0;
                      })
                      .join(", ")
                  : null,
              }}
            />
          </Box>
        ),
      },
      {
        header: t("report_payment_channel"),
        accessorKey: "paymentTypeChannel",
        minWidth: i18n.language === "th" ? 140 : 120,
        Cell: ({ cell }) => (
          <Box>
            <TextTable
              props={{
                text: cell.getValue()
                  ? cell
                      .getValue()
                      .sort((a, b) => {
                        if (a.paymentTypeChannel < b.paymentTypeChannel)
                          return -1;
                        if (a.paymentTypeChannel > b.paymentTypeChannel)
                          return 1;
                        return 0;
                      })
                      .join(", ")
                  : "-",
              }}
            />
          </Box>
        ),
      },
      {
        header: t("report_drawer_id"),
        accessorKey: "drawerId",
        minWidth: 100,
        Cell: ({ cell }) => <TextTable props={{ text: cell.getValue() }} />,
      },
      {
        header: t("report_bill_open_by"),
        accessorKey: "billOpenBy",
        minWidth: 150,
        Cell: ({ cell }) => <TextTable props={{ text: cell.getValue() }} />,
      },
      {
        header: t("report_bill_close_by"),
        accessorKey: "billCloseBy",
        minWidth: 150,
        Cell: ({ cell }) => <TextTable props={{ text: cell.getValue() }} />,
      },
      {
        header: t("report_branch"),
        accessorKey: "branchName",
        Cell: ({ cell }) => <TextTable props={{ text: cell.getValue() }} />,
      },
    ];
    dataColumns.forEach((item) => {
      if (item.header !== "Image" && item.header !== "Action") {
        headers.push(item.header);
      }
    });
    setColumns(dataColumns);
    //===== Start set header name export csv
    const csvStringArray = [
      headers,
      ...dataBox.map((item) => [
        item.paymentDate,
        item.paymentTime,
        item.receiptNumber,
        item.menuName,
        item.orderType,
        item.quantity,
        item.pricePerUnit,
        item.grossSale,
        item.discountByItems,
        item.summaryPrice,
        item.paymentType,
        item.paymentTypeChannel,
        item.drawerId,
        item.billOpenBy,
        item.billCloseBy,
        item.branchName,
      ]),
    ]
      .map((row) => row.join(","))
      .join("\n");

    setExportColumnName(csvStringArray);
    //===== End set header name export csv
    setIsLoadingData(false);
  };
  const onConfirm = async () => {
    await setIsLoadingData(true);
    await fetchReport();
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportAll]);
  return (
    <Box>
      <BreadcrumbsTitle
        props={{
          title: t("report_by_bill_detail"),
        }}
      />
      <Box>
        <SelectCheckMarkBranch
          props={{
            onConfirm,
            setBranchSelectReport,
          }}
        />
        <DataTableReport
          statusViewMode={true}
          rows={rows}
          isLoadingData={isLoadingData}
          columns={columns}
          exportColumnName={exportColumnName}
          height={0.85}
          fileName={
            t("report_by_bill_detail") +
            `${dayjs(branchSelectReportDate.startDate).format(
              "DD/MM/YYYY"
            )} - ${dayjs(branchSelectReportDate.endDate).format("DD/MM/YYYY")}`
          }
        />{" "}
        {dialogSummary.status ? (
          <ReceiptSummaryDetail
            props={{ dialogSummary, onCloseDialogSummary }}
          />
        ) : null}
      </Box>
    </Box>
  );
}

export default ReportByBillDetail;
