import React, { useEffect, useState } from "react";
import {
  Box,
  Chip,
  Grid,
  Paper,
  Skeleton,
  Typography,
  TextField,
  InputAdornment,
} from "@mui/material";
import StatusTable from "../../chip/statusTable";
import { BoxImage } from "../boxImage/boxImage";
import { useNavigate } from "react-router-dom";
import { Edit } from "@mui/icons-material";
import { BoxImageOutline } from "../boxImage/boxImageOutline";
import DataNotFound from "../dataNotFound";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";

import { t } from "i18next";
function CardMaterialTable({ props }) {
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  let { rows, columns, detailPath } = props;
  const onSearch = (valueSearch) => {
    setSearch(valueSearch);
  };
  const handleClear = () => {
    setSearch("");
  };
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1500); // Simulating 2 seconds delay for demonstration
  }, []);
  const navigate = useNavigate();
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          width: "100%",
          mt: 1,
        }}
      >
        <TextField
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: search && (
              <InputAdornment position="end">
                <ClearIcon
                  onClick={handleClear}
                  style={{ cursor: "pointer" }}
                />
              </InputAdornment>
            ),
          }}
          value={search}
          fullWidth={window.innerWidth < 600 ? true : false}
          size="small"
          onChange={(e) => onSearch(e.target.value)}
          placeholder="Search"
          sx={{ mb: 2 }}
        />
      </Box>
      <Grid container spacing={2}>
        {rows && !loading ? (
          <>
            {rows.length !== 0 ? (
              <>
                {rows
                  .filter((obj) =>
                    Object.values(obj).some(
                      (value) =>
                        typeof value === "string" &&
                        value.toLowerCase().includes(search.toLowerCase())
                    )
                  )
                  .map((itemRows, index) => (
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <Paper
                        sx={{
                          position: "relative",
                          p: 1.5,
                          borderRadius: "12px",
                          boxSizing: "border-box",
                        }}
                      >
                        <Box sx={{}}>
                          {columns.map((ItemColumn, indexColumn) => (
                            <Box mb={1}>
                              {columns[indexColumn].header !== "Action" ? (
                                <>
                                  {columns[indexColumn].header !==
                                    t("global_status") &&
                                  columns[indexColumn].header !==
                                    t("global_image") ? (
                                    <Typography
                                      sx={{ fontSize: 12 }}
                                      color="text.secondary"
                                    >
                                      {columns[indexColumn].header}
                                    </Typography>
                                  ) : null}
                                  {columns[indexColumn].header ===
                                  t("global_image") ? (
                                    <>
                                      {typeof itemRows[
                                        columns[indexColumn]["accessorKey"]
                                      ] === "string" ? (
                                        <>
                                          <BoxImage
                                            image={
                                              itemRows[
                                                columns[indexColumn][
                                                  "accessorKey"
                                                ]
                                              ]
                                            }
                                            type="table"
                                            width="100%"
                                            borderRadius="25px"
                                          />
                                        </>
                                      ) : (
                                        <>
                                          {itemRows[
                                            columns[indexColumn]["accessorKey"]
                                          ].length > 0 ? (
                                            <>
                                              <Box
                                                sx={{
                                                  display: "flex",
                                                  gap: 1,
                                                  overflow: "auto",
                                                }}
                                              >
                                                {itemRows[
                                                  columns[indexColumn][
                                                    "accessorKey"
                                                  ]
                                                ].length > 0 ? (
                                                  <>
                                                    {itemRows[
                                                      columns[indexColumn][
                                                        "accessorKey"
                                                      ]
                                                    ].map((item) => (
                                                      <>
                                                        <BoxImage
                                                          image={item.url}
                                                          width={"50px"}
                                                          height={"50px"}
                                                          type="table"
                                                        />
                                                      </>
                                                    ))}
                                                  </>
                                                ) : (
                                                  <BoxImageOutline
                                                    width={50}
                                                    height={50}
                                                  />
                                                )}
                                              </Box>
                                            </>
                                          ) : (
                                            <>
                                              <BoxImage
                                                image={
                                                  itemRows[
                                                    columns[indexColumn][
                                                      "accessorKey"
                                                    ]
                                                  ]
                                                }
                                                type="table"
                                                width="100%"
                                                height="128px"
                                                borderRadius="25px"
                                              />
                                            </>
                                          )}
                                        </>
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      {columns[indexColumn].header !==
                                      t("global_status") ? (
                                        <>
                                          {columns[indexColumn].header ===
                                          t("promotion_expirations_status") ? (
                                            <>
                                              <Typography
                                                sx={(theme) => ({
                                                  fontWeight: "bold",
                                                  fontSize: 16,
                                                  color:
                                                    itemRows[
                                                      columns[indexColumn][
                                                        "accessorKey"
                                                      ]
                                                    ] === "Expired"
                                                      ? theme.palette.error.main
                                                      : itemRows[
                                                          columns[indexColumn][
                                                            "accessorKey"
                                                          ]
                                                        ] === "Pending"
                                                      ? "default"
                                                      : theme.palette.success
                                                          .main,
                                                })}
                                              >
                                                {
                                                  itemRows[
                                                    columns[indexColumn][
                                                      "accessorKey"
                                                    ]
                                                  ]
                                                }
                                              </Typography>
                                            </>
                                          ) : (
                                            <>
                                              {typeof itemRows[
                                                columns[indexColumn][
                                                  "accessorKey"
                                                ]
                                              ] === "object" ? (
                                                <StatusTable
                                                  props={{
                                                    status:
                                                      itemRows[
                                                        columns[indexColumn][
                                                          "accessorKey"
                                                        ]
                                                      ],
                                                  }}
                                                />
                                              ) : (
                                                <Typography
                                                  sx={(theme) => ({
                                                    fontSize: 16,
                                                    fontWeight: "bold",
                                                    color:
                                                      theme.palette
                                                        .secondaryText.main,
                                                  })}
                                                >
                                                  {
                                                    itemRows[
                                                      columns[indexColumn][
                                                        "accessorKey"
                                                      ]
                                                    ]
                                                  }
                                                </Typography>
                                              )}
                                            </>
                                          )}
                                        </>
                                      ) : (
                                        <Box
                                          sx={{
                                            display: "flex",
                                            gap: 1,
                                            justifyContent: "flex-end",
                                          }}
                                        >
                                          <StatusTable
                                            props={{
                                              status:
                                                itemRows[
                                                  columns[indexColumn][
                                                    "accessorKey"
                                                  ]
                                                ],
                                            }}
                                          />
                                          <Chip
                                            icon={
                                              <Edit sx={{ fontSize: 16 }} />
                                            }
                                            sx={{ p: 1, borderRadius: 2 }}
                                            label={t("global_edit")}
                                            onClick={
                                              () =>
                                                navigate(
                                                  detailPath + itemRows.id
                                                )
                                              // alert(detailPath + itemRows.id)
                                            }
                                          />
                                        </Box>
                                      )}
                                    </>
                                  )}
                                </>
                              ) : null}
                            </Box>
                          ))}
                        </Box>
                      </Paper>
                    </Grid>
                  ))}
              </>
            ) : (
              <DataNotFound />
            )}
          </>
        ) : (
          <>
            {Array.from({ length: 6 }).map((_, index) => (
              <Grid item xs={12} sm={6} md={4} mb={3}>
                <Skeleton
                  variant="rounded"
                  animation="wave"
                  width={"100%"}
                  height={256}
                  sx={{ mb: 2 }}
                />
                <Skeleton
                  variant="rounded"
                  animation="wave"
                  width={"100%"}
                  height={32}
                />
              </Grid>
            ))}
          </>
        )}
      </Grid>
    </Box>
  );
}

export default CardMaterialTable;
