import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { useForm } from "react-hook-form";
import StatusForm from "../../../components/ui/formComponent/statusForm";
import ConfirmButton from "../../../components/ui/button/confirmButton";
import { useNavigate } from "react-router-dom";
import { notifySuccess } from "../../../components/ui/popup/toastPopup";
import {
  getProductCategory,
  updateProductCategory,
} from "../../../service/productCategoryAPI";
import Loading from "../../../components/ui/loading";
import { useTranslation } from "react-i18next";
import BreadcrumbsTitle from "../../../components/ui/breadcrumbsTitle";
import TitleForm from "../../../components/ui/formComponent/titleForm";
import { useSchema } from "../../../components/hooks/use-schema";
import { productCategorySchema } from "../../../components/validation/productCategorySchema";
import TextfieldComponent from "../../../components/ui/textfield/textfieldComponent";
import PaperWrapper from "../../../components/ui/paper/paperWrapper";
import { GridContainerSize } from "../../../components/utils/gridContainerSize";
import { BoxGapInput } from "../../../components/ui/box/boxGapInput";
import { BoxWrapper } from "../../../components/ui/box/boxWrapper";
import { BoxFormTitleAction } from "../../../components/ui/box/boxFormTitleAction";
import TitleHeaderForm from "../../../components/ui/formComponent/titleHeaderForm";

function ProductCategoryDetail() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm(useSchema(productCategorySchema));
  const { t } = useTranslation();
  const [statusButton, setStatusButton] = useState(true);
  const [dataAll, setDataAll] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const paramsId =
    window.location.pathname.split("/")[
      window.location.pathname.split("/").length - 1
    ];
  const onConfirmForm = async (register) => {
    setIsSubmitting(true);
    let dataAPI = {
      id: dataAll.id,
      status: statusButton,
      product_category_lang: {
        name: register.productCategory,
        language_code: String(localStorage.getItem("language_form")),
      },
    };
    let statusAPI = await updateProductCategory(dataAPI);
    if (statusAPI === "OK") {
      notifySuccess({
        title: t("notification_successfully_edited_information"),
      });
      navigate("/product-category");
    }
    await new Promise((resolve) => setTimeout(resolve, 2000));
    setIsSubmitting(false);
  };
  const fetchData = async () => {
    let dataAPI = await getProductCategory(paramsId);
    setDataAll(dataAPI);
    setStatusButton(dataAPI.status);
    setValue("productCategory", dataAPI.product_category_lang.name);
    setIsLoading(false);
  };
  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <Box>
      <BreadcrumbsTitle
        props={{
          title: t("product_category_info"),
          dataLink: [
            {
              text: t("product_category"),
              link: "/product-category",
            },
            { text: t("product_category_info"), link: null },
          ],
        }}
      />
      <GridContainerSize>
        <Box p={2}>
          <form onSubmit={handleSubmit(onConfirmForm)}>
            <BoxWrapper>
              <PaperWrapper props={{ growFull: 1 }}>
                <BoxFormTitleAction>
                  <TitleHeaderForm props={{ text: t("product_category") }} />
                  <StatusForm
                    statusButton={statusButton}
                    setStatusButton={setStatusButton}
                  />
                </BoxFormTitleAction>
                <BoxGapInput>
                  {isLoading ? (
                    <>
                      <Loading />
                    </>
                  ) : (
                    <PaperWrapper props={{ growFull: 1 }}>
                      <TitleForm props={{ text: t("product_category_info") }} />
                      <BoxGapInput>
                        <TextfieldComponent
                          title={t("product_category_name")}
                          name="productCategory"
                          register={register}
                          placeholder={t("product_category_example")}
                          errors={errors}
                          fullGrid={true}
                        />
                        <ConfirmButton disabled={isSubmitting} />
                      </BoxGapInput>
                    </PaperWrapper>
                  )}
                </BoxGapInput>
              </PaperWrapper>
            </BoxWrapper>
          </form>
        </Box>
      </GridContainerSize>
    </Box>
  );
}

export default ProductCategoryDetail;
