import React, { useState, useEffect } from "react";
import { Box, Tab } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import BranchInfo from "./branchInfo/branchInfo";
import BranchPOSTab from "./branchPOS/branchPOSTab";
import { useLocation, useNavigate } from "react-router-dom";
import { getBranch } from "../../../service/branchAPI";
import { useForm } from "react-hook-form";
import BranchSaleProduct from "./branchSale/branchSaleProduct";
// import BranchCategory from "./branchCategory/branchCategory";
import { useTranslation } from "react-i18next";
import { searchAddressByDistrict } from "thai-address-database";
import Loading from "../../../components/ui/loading";
import BreadcrumbsTitle from "../../../components/ui/breadcrumbsTitle";
import BranchPromotion from "./branchPromotion/branchPromotion";
import { useSchema } from "../../../components/hooks/use-schema";
import { branchSchema } from "../../../components/validation/branchSchema";
import { getAllCurrency } from "../../../service/currencyAPI";
import { configGetAllCurrency } from "../../../service/branchConfigAPI/branchConfigCurrency";
import BranchManageTable from "./branchManageTable/branchManageZoneTable";
function BranchTab() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    reset,
    control,
    clearErrors,
  } = useForm(useSchema(branchSchema));
  const [valueStep, setValueStep] = useState("1");
  const [valueStepPOS, setValueStepPOS] = useState("1");
  const [currencyProduct, setCurrentProduct] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const paramsId =
    window.location.pathname.split("/")[
      window.location.pathname.split("/").length - 1
    ];
  const handleChange = (event, newValue) => {
    setValueStep(newValue);
  };
  const { t } = useTranslation();
  const [dataBranch, setDataBranch] = useState(null);
  const [dataAddress, setDataAddress] = useState([]);
  const [statusButton, setStatusButton] = useState(true);
  const [anchorMap, setAnchorMap] = useState([null, null]);
  const [templateId, setTemplateId] = useState(null);
  const navigate = useNavigate();
  const [address, setAddress] = useState({
    district: "",
    subDistrict: "",
    province: "",
    zipCode: "",
  });
  const fetchData = async () => {
    let dataAPI = await getBranch(paramsId);
    if (dataAPI === null) {
      navigate("/branch");
    }
    let {
      template_id,
      branch_lang,
      b_branch_code,
      r_branch_code,
      postcode_no,
      lat,
      long,
      floor_no,
      room_no,
      house_no,
      village_no,
      status,
    } = dataAPI;
    const {
      district,
      subdistrict,
      building,
      province,
      road,
      alley,
      village,
      tax_id,
      country,
      name,
    } = branch_lang;
    setTemplateId(template_id);
    await fetchUnitPrice(template_id);
    setStatusButton(status);
    setValue("branchName", name);
    setValue("branchCode", b_branch_code);
    setValue("branchRevenueCode", r_branch_code);
    setValue("country", country);
    setValue("subDistrict", subdistrict);
    setValue("district", district);
    setValue("province", province);
    setValue("zipCode", postcode_no);
    setValue("floor", floor_no);
    setValue("villageNo", village_no);
    setValue("houseNo", house_no);
    setValue("roomNo", room_no);
    setValue("road", road);
    setValue("villageName", village);
    setValue("alley", alley);
    setValue("buildingName", building);
    setValue("supplierName", name);
    setValue("taxId", tax_id);

    let dataFetch = searchAddressByDistrict(subdistrict, 1000);
    setDataAddress(dataFetch);
    setAddress({
      subDistrict: subdistrict,
      district: district,
      province: province,
      zipCode: postcode_no,
    });
    setAnchorMap([Number(lat), Number(long)]);
    setDataBranch(dataAPI);

    setIsLoading(false);
  };
  const fetchUnitPrice = async (idTemplate) => {
    let currencyPack = await getAllCurrency();
    let dataAPICurrency = await configGetAllCurrency(idTemplate);
    if (dataAPICurrency) {
      let currencyBranch = currencyPack.find(
        (item) => item.currency_lang.currency_id === dataAPICurrency.currency_id
      );
      if (currencyBranch) {
        setCurrentProduct(currencyBranch.currency_lang.name);
      }
    }
  };
  useEffect(() => {
    let valueStep = "1";
    let valueStepPOS = "1";
    let isLoading = false;
    switch (location.state) {
      case "userPos":
        valueStepPOS = "1";
        break;
      case "paymentType":
        valueStepPOS = "3";
        break;
      case "sliderScreen":
        valueStepPOS = "6";
        break;
      case "saleProduct":
        valueStep = "2";
        break;
      case "promotion":
        valueStep = "3";
        break;
      default:
        fetchData();
        return;
    }

    setValueStep(valueStep);
    setValueStepPOS(valueStepPOS);
    setIsLoading(isLoading);
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <div align="left">
      <BreadcrumbsTitle
        props={{
          title: t("branch_title", {
            data: dataBranch ? dataBranch?.branch_lang.name : "",
          }),
          dataLink: [
            { text: t("branch"), link: "/branch" },
            { text: t("branch_info"), link: null },
          ],
        }}
      />
      <Box>
        <TabContext value={valueStep}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
            }}
          >
            <TabList
              onChange={handleChange}
              aria-label="lab API tabs"
              variant="scrollable"
            >
              {[
                t("branch_pos_config"),
                t("sale_product"),
                t("promotion"),
                t("branch_info"),
                t("table_manage_zone"),
              ].map((item, index) => (
                <Tab label={item} key={index} value={String(index + 1)} />
              ))}
            </TabList>
          </Box>
          {isLoading ? (
            <Loading />
          ) : (
            <>
              <TabPanel value="1" sx={{ p: 0 }}>
                <BranchPOSTab
                  templateId={templateId}
                  valueStepPOS={valueStepPOS}
                  dataBranch={dataBranch}
                  setCurrentProduct={setCurrentProduct}
                />
              </TabPanel>
              <TabPanel value="2">
                <BranchSaleProduct
                  templateId={templateId}
                  currencyProduct={currencyProduct}
                />
              </TabPanel>
              <TabPanel value="3" sx={{ p: 0 }}>
                <BranchPromotion props={{ templateId }} />
              </TabPanel>
              {/* <TabPanel value="5" sx={{ p: 0 }}>
                <BranchOpenClose props={{ paramsId }} />
              </TabPanel> */}
              <TabPanel value="4" sx={{ p: 0 }}>
                <BranchInfo
                  register={register}
                  handleSubmit={handleSubmit}
                  errors={errors}
                  setValue={setValue}
                  paramsId={paramsId}
                  dataAddress={dataAddress}
                  statusButton={statusButton}
                  address={address}
                  setAddress={setAddress}
                  setDataAddress={setDataAddress}
                  fetchData={fetchData}
                  dataBranch={dataBranch}
                  setStatusButton={setStatusButton}
                  getValues={getValues}
                  anchorMap={anchorMap}
                  setAnchorMap={setAnchorMap}
                  reset={reset}
                  control={control}
                  clearErrors={clearErrors}
                />
              </TabPanel>
              <TabPanel value="5">
                <BranchManageTable props={{ paramsId,templateId }} />
              </TabPanel>
            </>
          )}
        </TabContext>
      </Box>
    </div>
  );
}

export default BranchTab;
