import {
  Avatar,
  Box,
  Button,
  Grid,
  MenuItem,
  Select,
  Skeleton,
  Tab,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import BreadcrumbsTitle from "../../../components/ui/breadcrumbsTitle";
import { t } from "i18next";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useForm } from "react-hook-form";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import DashboardTotalSale from "./dashboardTotalSale";
import { DatePicker, Space } from "antd";
import dayjs from "dayjs";
import { getAllReceiptType } from "../../../service/orderType";
import PieChartComponent from "./chartComponent/pieDashboard";
import StackedBarChart from "../chart/barDashboard";
import TableDashboard from "./chartComponent/tableDashboard";
import ContributionsDashboard from "./chartComponent/contributionDashboard";
import SelectCheckMarkComponent from "../../../components/ui/select/selectCheckMarkComponent";
import moment from "moment";
import DashboardTotalSaleOneDay from "./dashboardTotalSaleOneDay";
import Swal from "sweetalert2";
import { getOverviewDashboard } from "../../../service/dashboard/getDashboardOverview";
import { MyContext } from "../../../context/MyContext";
import basicChart from "../../../assets/svg/dashboard/basicChart.svg";
import basicBranch from "../../../assets/svg/dashboard/basicBranch.svg";

function Dashboard() {
  const { RangePicker } = DatePicker;
  const {
    setValue,
    clearErrors,
    control,
    formState: { errors },
  } = useForm();
  const dateFormat = "DD/MM/YYYY";
  const [tabSelect, setTabSelect] = useState("1");

  const [isLoadingLineBar, setIsLoadingLineBar] = useState(true);
  const [isLoadingPie, setIsLoadingPie] = useState(true);
  const [isLoadingTable, setIsLoadingTable] = useState(true);
  const [isLoadingHeat, setIsLoadingHeat] = useState(true);
  const { branchAll } = useContext(MyContext);
  const [branchSelect, setBranchSelect] = useState([]);
  const [branchSelectName, setBranchSelectName] = useState([]);
  const [orderAll, setOrderAll] = useState([]);
  const [orderSelect, setOrderSelect] = useState([]);
  const [listOfSaleProduct, setListOfSaleProduct] = useState([]);
  const [dataHeatMap, setDataHeatMap] = useState({
    grand_total: [],
    amount: [],
  });
  const [dataPeakHour, setDataPeakHour] = useState(null);
  const [dataTotalSale, setDataTotalSale] = useState([]);
  const [summary, setSummary] = useState({
    total_sale: {
      total: 0,
    },
    total_receipt: {
      total: 0,
    },
    avg_price_per_receipt: {
      total: 0,
    },
  });
  const [selectChart, setSelectChart] = useState("1");
  const [dataBranch, setDataBranch] = useState([]);
  const [startDate, setStartDate] = useState(dayjs().startOf("day"));
  const [endDate, setEndDate] = useState(dayjs().endOf("day"));
  const [isLoadingUpdate, setIsLoadingUpdate] = useState(true);
  const [statusMap, setStatusMap] = useState("grand_total");
  const [isConfirmData, setIsConfirmData] = useState(false);
  const [showLineChartOneDay, setShowLineChartOneDay] = useState(true);
  const [isHovered, setHovered] = useState({
    rowNo: null,
    cellNo: null,
    data: null,
  });

  const handleMouseEnter = (item) => {
    setHovered(item);
  };
  const handleMouseLeave = () => {
    setHovered({ rowNo: null, cellNo: null, data: null });
  };
  const handleChange = (event, newValue) => {
    setTabSelect(newValue);
  };

  const fetchData = async () => {
    const orderAllAPI = await getOrderAPI();
    if (branchAll.length === 0) {
      Swal.fire({
        title: t("notification_please_create_branch"),
        icon: "warning",
      }).then((isConfirmed) => {
        if (isConfirmed) {
          window.location.replace("/branch");
        }
      });
      return;
    }

    const formattedStartDate = dayjs(startDate)
      .startOf("day")
      .format("YYYYMMDDHHmmss");
    const formattedEndDate = dayjs(endDate)
      .endOf("day")
      .format("YYYYMMDDHHmmss");

    setShowLineChartOneDay(dayjs(startDate).isSame(dayjs(endDate), "day"));

    const types = orderAllAPI.map((order) => order.name);
    const branches = branchAll.map((item) => item.id);
    const branchNames = branchAll.map((item) => item.name);
    const dataAPIStat = {
      start: formattedStartDate,
      end: formattedEndDate,
      types,
      branches,
    };
    await Promise.all([
      getStatAPI(dataAPIStat),
      getListOfSaleProductAPI(dataAPIStat),
      getBranchStatAPI(orderAllAPI, branchAll, dataAPIStat),
      getListOfHeatMapAPI(dataAPIStat),
    ]);

    setBranchSelectName(branchNames);
    setBranchSelect(branches);
    setValue("branchSelect", branchNames);

    const orderNames = orderAllAPI.map((order) => order.name);
    setOrderSelect(orderNames);
    setValue("orderSelect", orderNames);

    await setIsConfirmData(false);
    setIsLoadingUpdate(false);
  };

  const rangePresets = [
    {
      label: "Last 7 Days",
      value: [dayjs().add(-7, "d").startOf("day"), dayjs().endOf("day")],
    },
    {
      label: "Last 14 Days",
      value: [dayjs().add(-14, "d").startOf("day"), dayjs().endOf("day")],
    },
    {
      label: "Last 30 Days",
      value: [dayjs().add(-30, "d").startOf("day"), dayjs().endOf("day")],
    },
    {
      label: "Last 90 Days",
      value: [dayjs().add(-90, "d").startOf("day"), dayjs().endOf("day")],
    },
  ];
  const handleChangeDate = (newValue) => {
    if (newValue) {
      setStartDate(dayjs(newValue[0], dateFormat));
      setEndDate(dayjs(newValue[1], dateFormat));
    }
  };
  const getListOfSaleProductAPI = async (dataAPIStat) => {
    let dataAPI = await getOverviewDashboard(dataAPIStat, "sale_N05");
    if (dataAPI) {
      if (dataAPI.data?.length > 0) {
        let dataBox = dataAPI.data.map((item) => {
          let itemFind = branchAll.find(
            (branch) => branch.id === item.leading_branch
          );
          item.leading_branch = itemFind.name;
          return item;
        });
        setListOfSaleProduct(dataBox);
      }
    } else {
      setListOfSaleProduct([]);
    }
    await setIsLoadingTable(false);
  };
  const getStatAPI = async (dataAPIStat) => {
    let dataAPI = await getOverviewDashboard(dataAPIStat, "sale_N01");
    if (dataAPI.data) {
      setSummary(dataAPI.data.data);
    } else {
      setSummary({
        total_sale: {
          total: 0,
        },
        total_receipt: {
          total: 0,
        },
        avg_price_per_receipt: {
          total: 0,
        },
      });
    }
    let dataTotalSaleAPI = await getOverviewDashboard(dataAPIStat, "sale_N02");
    if (dataTotalSaleAPI) {
      setDataTotalSale(dataTotalSaleAPI.data);
    } else {
      setDataTotalSale([
        {
          hour: "0.0",
          data: {
            total_sale: 0,
            total_receipt: 0,
            avg_price_per_receipt: 0,
            pct_total_sale: 0,
            pct_total_receipt: 0,
          },
        },
        {
          hour: "1.0",
          data: {
            total_sale: 0,
            total_receipt: 0,
            avg_price_per_receipt: 0,
            pct_total_sale: 0,
            pct_total_receipt: 0,
          },
        },
        {
          hour: "2.0",
          data: {
            total_sale: 0,
            total_receipt: 0,
            avg_price_per_receipt: 0,
            pct_total_sale: 0,
            pct_total_receipt: 0,
          },
        },
        {
          hour: "3.0",
          data: {
            total_sale: 0,
            total_receipt: 0,
            avg_price_per_receipt: 0,
            pct_total_sale: 0,
            pct_total_receipt: 0,
          },
        },
        {
          hour: "4.0",
          data: {
            total_sale: 0,
            total_receipt: 0,
            avg_price_per_receipt: 0,
            pct_total_sale: 0,
            pct_total_receipt: 0,
          },
        },
        {
          hour: "5.0",
          data: {
            total_sale: 0,
            total_receipt: 0,
            avg_price_per_receipt: 0,
            pct_total_sale: 0,
            pct_total_receipt: 0,
          },
        },
        {
          hour: "6.0",
          data: {
            total_sale: 0,
            total_receipt: 0,
            avg_price_per_receipt: 0,
            pct_total_sale: 0,
            pct_total_receipt: 0,
          },
        },
        {
          hour: "7.0",
          data: {
            total_sale: 0,
            total_receipt: 0,
            avg_price_per_receipt: 0,
            pct_total_sale: 0,
            pct_total_receipt: 0,
          },
        },
        {
          hour: "8.0",
          data: {
            total_sale: 0,
            total_receipt: 0,
            avg_price_per_receipt: 0,
            pct_total_sale: 0,
            pct_total_receipt: 0,
          },
        },
        {
          hour: "9.0",
          data: {
            total_sale: 0,
            total_receipt: 0,
            avg_price_per_receipt: 0,
            pct_total_sale: 0,
            pct_total_receipt: 0,
          },
        },
        {
          hour: "10.0",
          data: {
            total_sale: 0,
            total_receipt: 0,
            avg_price_per_receipt: 0,
            pct_total_sale: 0,
            pct_total_receipt: 0,
          },
        },
        {
          hour: "11.0",
          data: {
            total_sale: 0,
            total_receipt: 0,
            avg_price_per_receipt: 0,
            pct_total_sale: 0,
            pct_total_receipt: 0,
          },
        },
        {
          hour: "12.0",
          data: {
            total_sale: 0,
            total_receipt: 0,
            avg_price_per_receipt: 0,
            pct_total_sale: 0,
            pct_total_receipt: 0,
          },
        },
        {
          hour: "13.0",
          data: {
            total_sale: 0,
            total_receipt: 0,
            avg_price_per_receipt: 0,
            pct_total_sale: 0,
            pct_total_receipt: 0,
          },
        },
        {
          hour: "14.0",
          data: {
            total_sale: 0,
            total_receipt: 0,
            avg_price_per_receipt: 0,
            pct_total_sale: 0,
            pct_total_receipt: 0,
          },
        },
        {
          hour: "15.0",
          data: {
            total_sale: 0,
            total_receipt: 0,
            avg_price_per_receipt: 0,
            pct_total_sale: 0,
            pct_total_receipt: 0,
          },
        },
        {
          hour: "16.0",
          data: {
            total_sale: 0,
            total_receipt: 0,
            avg_price_per_receipt: 0,
            pct_total_sale: 0,
            pct_total_receipt: 0,
          },
        },
        {
          hour: "17.0",
          data: {
            total_sale: 0,
            total_receipt: 0,
            avg_price_per_receipt: 0,
            pct_total_sale: 0,
            pct_total_receipt: 0,
          },
        },
        {
          hour: "18.0",
          data: {
            total_sale: 0,
            total_receipt: 0,
            avg_price_per_receipt: 0,
            pct_total_sale: 0,
            pct_total_receipt: 0,
          },
        },
        {
          hour: "19.0",
          data: {
            total_sale: 0,
            total_receipt: 0,
            avg_price_per_receipt: 0,
            pct_total_sale: 0,
            pct_total_receipt: 0,
          },
        },
        {
          hour: "20.0",
          data: {
            total_sale: 0,
            total_receipt: 0,
            avg_price_per_receipt: 0,
            pct_total_sale: 0,
            pct_total_receipt: 0,
          },
        },
        {
          hour: "21.0",
          data: {
            total_sale: 0,
            total_receipt: 0,
            avg_price_per_receipt: 0,
            pct_total_sale: 0,
            pct_total_receipt: 0,
          },
        },
        {
          hour: "22.0",
          data: {
            total_sale: 0,
            total_receipt: 0,
            avg_price_per_receipt: 0,
            pct_total_sale: 0,
            pct_total_receipt: 0,
          },
        },
        {
          hour: "23.0",
          data: {
            total_sale: 0,
            total_receipt: 0,
            avg_price_per_receipt: 0,
            pct_total_sale: 0,
            pct_total_receipt: 0,
          },
        },
      ]);
    }

    await setIsLoadingLineBar(false);
  };
  const getBranchStatAPI = async (
    orderAllGroup,
    branchAllGroup,
    dataAPIStat
  ) => {
    let statusListBranchStat = await getOverviewDashboard(
      dataAPIStat,
      "sale_N03"
    );

    const validJsonArrayString = `[${statusListBranchStat}]`;
    const parsedData = JSON.parse(validJsonArrayString);
    let dataBranchOrder = [];
    let dataBranchBox = [];
    orderAllGroup.forEach((itemOrder) => {
      let dataFind = parsedData[0].data.find(
        (item) => item.channel === itemOrder.name
      );
      let amount = 0;
      if (dataFind) {
        amount = dataFind.data.total_sale;
      }
      dataBranchOrder.push({
        type: itemOrder.name,
        amount: amount,
      });
    });
    let filteredData = branchAll.filter((item) =>
      branchAllGroup.includes(item.id)
    );
    (filteredData.length > 0 ? filteredData : branchAllGroup).forEach(
      (itemBranch) => {
        let dataFind = parsedData[1].data.find(
          (item) => item.branch === itemBranch.id
        );
        let dataTotal = 0;
        if (dataFind) {
          dataTotal = dataFind.data.total_sale;
        }
        dataBranchBox.push({
          name: itemBranch.name,
          grand_total: dataTotal,
        });
      }
    );
    let dataBox = { branchPie: dataBranchBox, branchBar: dataBranchOrder };
    setDataBranch(dataBox);
    await setIsLoadingPie(false);
  };
  const getListOfHeatMapAPI = async (dataAPIStat) => {
    let itemGrandTotal = [];
    let itemAmount = [];
    let dataBoxInsert = [];
    let dataSale04 = await await getOverviewDashboard(dataAPIStat, "sale_N04");
    // let statusHeatMap = await getHeatMap(dataAPIStat);
    let dayBox = [
      { day: "Sunday", data: [] },
      { day: "Monday", data: [] },
      { day: "Tuesday", data: [] },
      { day: "Wednesday", data: [] },
      { day: "Thursday", data: [] },
      { day: "Friday", data: [] },
      { day: "Saturday", data: [] },
    ];
    // if (dataSale04) {
    dayBox.forEach((itemDay) => {
      let dataFind = [];
      if (dataSale04) {
        dataFind = dataSale04.data.filter(
          (item) => item.weekday === itemDay.day
        );
      }
      let boxDay = [];
      if (dataFind.length > 0) {
        dataFind.forEach((itemTime) => {
          boxDay.push({
            time: dayjs(itemTime.data.hour, "HH").format("HHmm"),
            grand_total: itemTime.data.data.total_sale,
            amount: itemTime.data.data.total_receipt,
            backgroundColor: "",
          });
        });
        dataBoxInsert.push({ day: itemDay.day, data: boxDay });
      } else {
        boxDay = Array.from({ length: 24 }, (_, index) => ({
          time: dayjs(String(index), "HH").format("HHmm"),
          grand_total: 0,
          amount: 0,
          backgroundColor: "",
          // You can add more properties as needed
        }));
        dataBoxInsert.push({ day: itemDay.day, data: boxDay });
      }
    });
    // }
    // if (dataSale04) {
    dataBoxInsert.forEach((itemMain, index) => {
      let percentileBox = [
        { value: 20, data: 0 },
        { value: 40, data: 0 },
        { value: 60, data: 0 },
        { value: 80, data: 0 },
      ];
      let boxSort = itemMain.data
        .filter((item) => item.grand_total > 0)
        .sort((a, b) => a.grand_total - b.grand_total);
      if (boxSort.length >= 4) {
        if (boxSort.length > 0) {
          percentileBox.forEach((itemPercentile) => {
            let percentileCal =
              (itemPercentile.value * (boxSort.length + 1)) / 100;

            if (Number.isInteger(percentileCal)) {
              itemPercentile.data = boxSort[percentileCal - 1].grand_total;
            } else {
              itemPercentile.data =
                (boxSort[Math.floor(percentileCal)].grand_total +
                  boxSort[Math.floor(percentileCal - 1)].grand_total) /
                2;
            }
          });
        }
      } else {
        boxSort
          .sort((a, b) => b.grand_total - a.grand_total)
          .forEach((item, index) => {
            percentileBox[4 - (index + 1)].data = item.grand_total;
          });
      }

      let boxDay = [];
      itemMain.data.forEach((item) => {
        let backgroundBox = "";
        if (item.grand_total === 0) {
          backgroundBox = "#f1f1f1";
        } else if (item.grand_total >= percentileBox[3].data) {
          backgroundBox = "#005c3a";
        } else if (item.grand_total >= percentileBox[2].data) {
          backgroundBox = "#00b752";
        } else if (item.grand_total >= percentileBox[1].data) {
          backgroundBox = "#75d387";
        } else if (item.grand_total >= percentileBox[0].data) {
          backgroundBox = "#c5f6c7";
        } else {
          backgroundBox = "#dbf9df";
        }
        boxDay.push({
          time: item.time,
          grand_total: item.grand_total,
          amount: item.amount,
          backgroundColor: backgroundBox,
        });
      });
      boxDay.sort((a, b) => Number(a.time) - Number(b.time));
      itemGrandTotal.push({ day: itemMain.day, data: boxDay });
    });
    // amount
    dataBoxInsert.forEach((itemMain, index) => {
      let percentileBox = [
        { value: 20, data: 0 },
        { value: 40, data: 0 },
        { value: 60, data: 0 },
        { value: 80, data: 0 },
      ];
      let boxSort = itemMain.data
        .filter((item) => item.amount > 0)
        .sort((a, b) => a.amount - b.amount);
      if (boxSort.length >= 4) {
        if (boxSort.length > 0) {
          percentileBox.forEach((itemPercentile) => {
            let percentileCal =
              (itemPercentile.value * (boxSort.length + 1)) / 100;
            if (Number.isInteger(percentileCal)) {
              itemPercentile.data = boxSort[percentileCal - 1].amount;
            } else {
              itemPercentile.data =
                (boxSort[Math.floor(percentileCal)].amount +
                  boxSort[Math.floor(percentileCal - 1)].amount) /
                2;
            }
          });
        }
      } else {
        boxSort
          .sort((a, b) => b.amount - a.amount)
          .forEach((item, index) => {
            percentileBox[4 - (index + 1)].data = item.amount;
          });
      }

      let boxDay = [];
      itemMain.data.forEach((item) => {
        let backgroundBox = "";
        if (item.amount === 0) {
          backgroundBox = "#f1f1f1";
        } else if (item.amount >= percentileBox[3].data) {
          backgroundBox = "#005c3a";
        } else if (item.amount >= percentileBox[2].data) {
          backgroundBox = "#00b752";
        } else if (item.amount >= percentileBox[1].data) {
          backgroundBox = "#75d387";
        } else if (item.amount >= percentileBox[0].data) {
          backgroundBox = "#c5f6c7";
        } else {
          backgroundBox = "#dbf9df";
        }
        boxDay.push({
          time: item.time,
          grand_total: item.grand_total,
          amount: item.amount,
          backgroundColor: backgroundBox,
        });
      });
      boxDay.sort((a, b) => Number(a.time) - Number(b.time));
      itemAmount.push({ day: itemMain.day, data: boxDay });
    });
    setDataHeatMap({ grand_total: itemGrandTotal, amount: itemAmount });

    //Find PeakHour
    let dataFindPeakHour = findPeakHour(
      itemGrandTotal.find(
        (item) =>
          item.day.toLowerCase() === moment().format("dddd").toLowerCase()
      ).data
    );
    setDataPeakHour(moment(dataFindPeakHour, "HHmm").format("HH:mm"));
    // }
    await setIsLoadingHeat(false);
  };
  function findPeakHour(data) {
    let maxTotal = 0;
    let maxTime = "";

    for (const element of data) {
      const currentTotal = element.grand_total;
      if (currentTotal > maxTotal) {
        maxTotal = currentTotal;
        maxTime = element.time;
      }
    }

    return maxTime;
  }
  const getOrderAPI = async () => {
    //Order
    let statusOrder = await getAllReceiptType();
    let statusOrderBox = [];
    statusOrder.forEach((item) => {
      statusOrderBox.push({ id: item.id, name: item.name });
    });
    setOrderAll(statusOrderBox);
    return statusOrderBox;
  };

  const maxDate = new Date();
  const disabledDate = (current) => {
    return current && current > maxDate;
  };

  const onConfirm = async () => {
    await setIsLoadingLineBar(true);
    await setIsLoadingPie(true);
    await setIsLoadingTable(true);
    await setIsLoadingUpdate(true);
    await setIsLoadingHeat(true);

    const formattedStartDate = dayjs(startDate)
      .startOf("day")
      .format("YYYYMMDDHHmmss");
    const formattedEndDate = dayjs(endDate)
      .endOf("day")
      .format("YYYYMMDDHHmmss");
    setShowLineChartOneDay(dayjs(startDate).isSame(dayjs(endDate), "day"));

    const dataAPIStat = {
      start: formattedStartDate,
      end: formattedEndDate,
      types: orderSelect,
      branches: branchSelect,
    };

    await Promise.all([
      getStatAPI(dataAPIStat),
      getListOfSaleProductAPI(dataAPIStat),
      getBranchStatAPI(
        orderAll,
        branchSelect.length > 0 ? branchSelect : branchAll,
        dataAPIStat
      ),
      getListOfHeatMapAPI(dataAPIStat),
    ]);
    await setIsLoadingUpdate(false);
    await setIsConfirmData(true);
  };
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Box>
      <BreadcrumbsTitle
        props={{
          title: t("side_bar_dashboard"),
        }}
      />

      <Grid
        container
        justifyContent={"space-between"}
        sx={(theme) => ({
          boxSizing: "border-box",
          p: 2,
        })}
        spacing={2}
      >
        <Grid item xs={12}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Box
              sx={(theme) => ({
                display: "flex",
                alignItems: "center",
                width: "100%",
                gap: 2,
              })}
            >
              <Grid container justifyContent={"space-between"} spacing={2}>
                <Grid item xs={12} md={3}>
                  <Box
                    sx={(theme) => ({
                      boxSizing: "border-box",
                      // p: 1,
                      // borderRadius: 2,
                      // border: `3px solid ${theme.palette.primary.main}`,
                    })}
                  >
                    <SelectCheckMarkComponent
                      dataEmpty={t("branch_select")}
                      errors={errors}
                      name="branchSelect"
                      dataOptions={branchAll}
                      clearErrors={clearErrors}
                      control={control}
                      setValue={setValue}
                      valueData={branchSelectName}
                      setValueData={setBranchSelectName}
                      setValueDataId={setBranchSelect}
                      disabled={isLoadingUpdate}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Box
                    sx={(theme) => ({
                      boxSizing: "border-box",
                      // p: 1,
                      // borderRadius: 2,
                      // border: `3px solid ${theme.palette.primary.main}`,
                    })}
                  >
                    <SelectCheckMarkComponent
                      dataEmpty={t("order_select")}
                      errors={errors}
                      name="orderSelect"
                      dataOptions={orderAll}
                      clearErrors={clearErrors}
                      control={control}
                      setValue={setValue}
                      valueData={orderSelect}
                      setValueData={setOrderSelect}
                      disabled={isLoadingUpdate}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Box
                    sx={(theme) => ({
                      boxSizing: "border-box",
                      // p: 1,
                      // borderRadius: 2,
                      // border: `3px solid ${theme.palette.primary.main}`,
                    })}
                  >
                    <Space direction="vertical">
                      <RangePicker
                        defaultValue={[startDate, endDate]}
                        presets={[
                          {
                            label: (
                              <span aria-label="Current Time to End of Day">
                                Now ~ EOD
                              </span>
                            ),
                            value: () => [dayjs(), dayjs().endOf("day")], // 5.8.0+ support function
                          },
                          ...rangePresets,
                        ]}
                        // showTime
                        onChange={handleChangeDate}
                        disabledDate={disabledDate}
                        format={dateFormat}
                        style={{
                          height: "40px",
                          // color: theme.palette.primary.main,
                          // backgroundColor: theme.palette.primary.main, // Background color
                          // border: "none",
                          width: "100%",
                        }}
                        disabled={isLoadingUpdate}
                      />
                    </Space>
                  </Box>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Button
                    variant="contained"
                    fullWidth
                    onClick={() => onConfirm()}
                    sx={{ borderRadius: 2 }}
                    disabled={
                      isLoadingUpdate ||
                      branchSelectName.length === 0 ||
                      orderSelect.length === 0
                    }
                  >
                    Confirm
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </LocalizationProvider>
        </Grid>
        {isLoadingLineBar ? (
          <Grid item xs={12} sm={12} md={8} lg={8}>
            <Skeleton
              sx={{
                borderRadius: "10px",
              }}
              variant="rectangular"
              width="100%"
              height="40vh"
            />
          </Grid>
        ) : (
          <Grid
            item
            xs={12}
            sm={12}
            md={8}
            lg={8}
            sx={(theme) => ({
              color: theme.palette.primaryText.main,
            })}
          >
            <Box className="grid-paper-white" sx={{ height: "100%" }}>
              {window.innerWidth < 600 ? (
                <Box>
                  <Select
                    value={selectChart}
                    onChange={(e) => setSelectChart(e.target.value)}
                    fullWidth
                    size="small"
                    sx={{ mb: 1 }}
                  >
                    <MenuItem value="1">
                      <Box sx={{ display: "flex", gap: 1 }}>
                        <Typography>Total Sales</Typography>
                        <Typography
                          sx={(theme) => ({
                            color: theme.palette.secondaryText.main,
                          })}
                        >
                          ฿
                          {Number(summary.total_sale?.total).toLocaleString(
                            undefined,
                            {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }
                          )}
                        </Typography>
                      </Box>
                    </MenuItem>
                    <MenuItem value="2">
                      <Box sx={{ display: "flex", gap: 1 }}>
                        <Typography> Total receipt</Typography>
                        <Typography
                          sx={(theme) => ({
                            color: theme.palette.secondaryText.main,
                          })}
                        >
                          {Number(
                            summary.total_receipt?.total
                          ).toLocaleString()}
                        </Typography>
                      </Box>
                    </MenuItem>
                    <MenuItem value="3">
                      <Box sx={{ display: "flex", gap: 1 }}>
                        <Typography>Value / receipt</Typography>
                        <Typography
                          sx={(theme) => ({
                            color: theme.palette.secondaryText.main,
                          })}
                        >
                          ฿
                          {Number(
                            summary.avg_price_per_receipt?.total
                          ).toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </Typography>
                      </Box>
                    </MenuItem>
                  </Select>
                  {selectChart === "1" ? (
                    <>
                      {showLineChartOneDay ? (
                        <DashboardTotalSaleOneDay
                          props={{
                            data: dataTotalSale,
                            summary: summary,
                            nameValue: "pct_total_sale",
                            symbol: true,
                            isConfirmData,
                          }}
                        />
                      ) : (
                        <DashboardTotalSale
                          props={{
                            data: dataTotalSale,
                            startDate: startDate,
                            endDate: endDate,
                            summary: summary,
                            nameValue: "total_sale",
                            symbol: true,
                            isConfirmData,
                          }}
                        />
                      )}
                    </>
                  ) : null}
                  {selectChart === "2" ? (
                    <>
                      {showLineChartOneDay ? (
                        <DashboardTotalSaleOneDay
                          props={{
                            data: dataTotalSale,
                            summary: summary,
                            nameValue: "pct_total_receipt",
                            symbol: false,
                            isConfirmData,
                            title: "Total Receipt",
                          }}
                        />
                      ) : (
                        <DashboardTotalSale
                          props={{
                            data: dataTotalSale,
                            startDate: startDate,
                            endDate: endDate,
                            summary: summary,
                            nameValue: "total_receipt",
                            symbol: false,
                            isConfirmData,
                            title: "Total Receipt",
                          }}
                        />
                      )}
                    </>
                  ) : null}
                  {selectChart === "3" ? (
                    <>
                      {showLineChartOneDay ? (
                        <DashboardTotalSaleOneDay
                          props={{
                            data: dataTotalSale,
                            summary: summary,
                            nameValue: "avg_price_per_receipt",
                            symbol: false,
                            title: "Average price per receipt",
                          }}
                        />
                      ) : (
                        <DashboardTotalSale
                          props={{
                            data: dataTotalSale,
                            startDate: startDate,
                            endDate: endDate,
                            summary: summary,
                            nameValue: "avg_price_per_receipt",
                            symbol: true,
                            isConfirmData,
                            title: "Average price per receipt",
                          }}
                        />
                      )}
                    </>
                  ) : null}
                </Box>
              ) : (
                <Box
                  className="grid-paper-white"
                  sx={{ p: 0, boxSizing: "border-box", height: "100%" }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      gap: 1,
                      p: 2,
                      boxSizing: "border-box",
                    }}
                  >
                    <Avatar
                      src={basicChart}
                      sx={{ borderRadius: 0, width: 24, height: 24 }}
                    />
                    <Typography
                      variant="h3"
                      sx={(theme) => ({
                        fontWeight: "500",
                      })}
                    >
                      Performance
                    </Typography>
                  </Box>
                  <TabContext value={tabSelect}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                      <TabList
                        onChange={handleChange}
                        aria-label="lab API tabs"
                        variant="scrollable"
                        scrollButtons="auto"
                      >
                        <Tab
                          label={
                            <Box align="left">
                              <Typography sx={{ opacity: 0.5 }}>
                                Total Sales
                              </Typography>
                              <Typography
                                sx={{ fontWeight: 600, fontSize: 24 }}
                              >
                                {Number(
                                  summary.total_sale?.total
                                ).toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </Typography>
                            </Box>
                          }
                          sx={{ flexGrow: 1, textAlign: "left" }}
                          value={"1"}
                        />
                        <Tab
                          label={
                            <Box align="left">
                              <Typography sx={{ opacity: 0.5 }}>
                                Total receipt
                              </Typography>
                              <Typography
                                sx={{ fontWeight: 600, fontSize: 24 }}
                              >
                                {Number(
                                  summary.total_receipt?.total
                                ).toLocaleString()}
                              </Typography>
                            </Box>
                          }
                          sx={{ flexGrow: 1, textAlign: "left" }}
                          value={"2"}
                        />
                        <Tab
                          label={
                            <Box align="left">
                              <Typography sx={{ opacity: 0.5 }}>
                                Value / receipt
                              </Typography>
                              <Typography
                                sx={{ fontWeight: 600, fontSize: 24 }}
                              >
                                {Number(
                                  summary.avg_price_per_receipt?.total
                                ).toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </Typography>
                            </Box>
                          }
                          sx={{ flexGrow: 1, textAlign: "left" }}
                          value={"3"}
                        />
                      </TabList>
                    </Box>
                    <TabPanel
                      value="1"
                      sx={(theme) => ({
                        p: 0,
                      })}
                    >
                      {showLineChartOneDay ? (
                        <DashboardTotalSaleOneDay
                          props={{
                            data: dataTotalSale,
                            summary: summary,
                            nameValue: "pct_total_sale",
                            symbol: true,
                            isConfirmData,
                          }}
                        />
                      ) : (
                        <DashboardTotalSale
                          props={{
                            data: dataTotalSale,
                            startDate: startDate,
                            endDate: endDate,
                            summary: summary,
                            nameValue: "total_sale",
                            symbol: true,
                            isConfirmData,
                          }}
                        />
                      )}
                    </TabPanel>
                    <TabPanel
                      value="2"
                      sx={(theme) => ({
                        p: 0,
                      })}
                    >
                      {showLineChartOneDay ? (
                        <DashboardTotalSaleOneDay
                          props={{
                            data: dataTotalSale,
                            summary: summary,
                            nameValue: "pct_total_receipt",
                            symbol: true,
                            isConfirmData,
                            title: "Total Receipt",
                          }}
                        />
                      ) : (
                        <DashboardTotalSale
                          props={{
                            data: dataTotalSale,
                            startDate: startDate,
                            endDate: endDate,
                            summary: summary,
                            nameValue: "total_receipt",
                            symbol: false,
                            isConfirmData,
                            title: "Total Receipt",
                          }}
                        />
                      )}
                    </TabPanel>
                    <TabPanel
                      value="3"
                      sx={(theme) => ({
                        p: 0,
                      })}
                    >
                      {showLineChartOneDay ? (
                        <DashboardTotalSaleOneDay
                          props={{
                            data: dataTotalSale,
                            summary: summary,
                            nameValue: "avg_price_per_receipt",
                            symbol: false,
                            title: "Average price per receipt",
                          }}
                        />
                      ) : (
                        <DashboardTotalSale
                          props={{
                            data: dataTotalSale,
                            startDate: startDate,
                            endDate: endDate,
                            summary: summary,
                            nameValue: "avg_price_per_receipt",
                            symbol: true,
                            isConfirmData,
                            title: "Average price per receipt",
                          }}
                        />
                      )}
                    </TabPanel>
                  </TabContext>
                </Box>
              )}
            </Box>
          </Grid>
        )}

        {isLoadingPie ? (
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <Skeleton
              sx={{
                borderRadius: "10px",
              }}
              variant="rectangular"
              width="100%"
              height="40vh"
            />
          </Grid>
        ) : (
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            lg={4}
            sx={(theme) => ({
              color: theme.palette.primaryText.main,
            })}
          >
            <Box
              className="grid-paper-white"
              sx={{ p: 2, boxSizing: "border-box", height: "100%" }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <Avatar
                  src={basicBranch}
                  sx={{ borderRadius: 0, width: 24, height: 24 }}
                />
                <Typography
                  variant="h3"
                  sx={{
                    fontWeight: "500",
                  }}
                >
                  Sales (%) by Channel & Branch
                </Typography>
              </Box>

              <StackedBarChart props={{ dataBranch }} />
              <PieChartComponent props={{ dataBranch }} />
            </Box>
          </Grid>
        )}

        {isLoadingHeat ? (
          <Grid
            item
            xs={12}
            sm={12}
            md={8}
            lg={8}
            sx={{
              boxSizing: "border-box",
              borderRadius: 2,
            }}
          >
            <Skeleton
              sx={{
                borderRadius: "10px",
              }}
              variant="rectangular"
              width="100%"
              height="40vh"
            />
          </Grid>
        ) : (
          <Grid
            item
            xs={12}
            sm={12}
            md={8}
            lg={8}
            sx={{
              boxSizing: "border-box",
              borderRadius: 2,
            }}
          >
            <ContributionsDashboard
              props={{
                dataPeakHour,
                dataHeatMap,
                statusMap,
                setStatusMap,
                isHovered,
                handleMouseEnter,
                handleMouseLeave,
              }}
            />
          </Grid>
        )}

        {isLoadingTable ? (
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            lg={4}
            sx={{
              boxSizing: "border-box",
              borderRadius: 2,
            }}
          >
            <Skeleton
              sx={{
                borderRadius: "10px",
              }}
              variant="rectangular"
              width="100%"
              height="40vh"
            />
          </Grid>
        ) : (
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <TableDashboard
              props={{ listOfSaleProduct, setListOfSaleProduct }}
            />
          </Grid>
        )}
      </Grid>
    </Box>
  );
}

export default Dashboard;
