import { Box } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";

import { t } from "i18next";
import dayjs from "dayjs";
import BreadcrumbsTitle from "../../../components/ui/breadcrumbsTitle";
import SelectCheckMarkBranch from "../../../components/ui/select/selectCheckMarkBranch";
import { TextTable } from "../../../components/ui/textfield/textfieldCustom";
import { MyContext } from "../../../context/MyContext";
import DataTableReport from "../../../components/ui/dataTable/dataTableReport";
import { getAllPromotion } from "../../../service/promotionAPI";
import { UseConvertImageToBase64 } from "../../../components/hooks/use-convert-image-to-base-64";
import { getImage } from "../../../service/upload/getImage";
import DetailBillReceipt from "../reportByBill/detailBillReceipt";
import i18n from "../../../locals/i18n";

function ReportByPromotionDetail() {
  const [rows, setRows] = useState([]);

  const [dialogData, setDialogData] = useState({ status: false, data: null });
  const [columns, setColumns] = useState([]);
  const [exportColumnName, setExportColumnName] = useState(null);
  const {
    branchAll,
    setBranchSelectReport,
    branchSelectReportDate,
    reportAll,
    fetchReport,
    salePromotionSetAll,
  } = useContext(MyContext);
  const [isLoadingData, setIsLoadingData] = useState(
    reportAll.length > 0 ? false : true
  );
  const onDialogBill = async (item) => {
    let { receipt_config_receipt } = item.data;
    if (receipt_config_receipt) {
      let { config_receipt_lang } = receipt_config_receipt;
      let { logo, img_footer } = config_receipt_lang;
      setDialogData({ status: true, data: item.data });
      if (logo.includes("data:image") === false) {
        if (item.data.receipt_config_receipt.config_receipt_lang.logo) {
          item.data.receipt_config_receipt.config_receipt_lang.logo =
            UseConvertImageToBase64(await getImage(logo));
        }
        if (item.data.receipt_config_receipt.config_receipt_lang.img_footer) {
          item.data.receipt_config_receipt.config_receipt_lang.img_footer =
            UseConvertImageToBase64(await getImage(img_footer));
        }
        await setDialogData({ status: true, data: item.data });
      } else {
        await setDialogData({ status: true, data: item.data });
      }
    } else {
      setDialogData({ status: true, data: item.data });
    }
  };
  const handleClose = () => {
    setDialogData({ status: false, data: null });
  };
  const fetchData = async () => {
    let headers = [];
    let dataPromotionRows = [];

    if (reportAll) {
      let dataAPIPromotion =
        salePromotionSetAll.length > 0
          ? salePromotionSetAll
          : await getAllPromotion();
      let dataBranch = [];
      let reportAllNew = reportAll.filter(
        (itemPromotion) => itemPromotion.receipt_promotions.length > 0
      );
      branchAll.forEach((itemBranch) => {
        let dataAllReceipt = reportAllNew.filter(
          (item) => item.branch_id === itemBranch.id
        );
        if (dataAllReceipt.length > 0) {
          dataBranch.push({
            branch_id: itemBranch.id,
            receiptAll: dataAllReceipt,
          });
        }
      });
      let dataAllPromotion = [];
      dataBranch.forEach((itemBranch) => {
        let dataCollectPromotion = [];
        itemBranch.receiptAll
          .filter((itemNoVoid) => itemNoVoid.receipt_status === "success")
          .forEach((itemReceipt) => {
            let dataBoxPromotion = [];
            itemReceipt.receipt_promotions.forEach((itemPromotion) => {
              let existPromotion = dataBoxPromotion.find(
                (item) => item.promotion_id === itemPromotion.promotion_id
              );
              if (existPromotion === undefined) {
                dataBoxPromotion.push(itemPromotion);
              }
            });
            dataBoxPromotion.forEach((itemPromotion) => {
              dataCollectPromotion.push({
                data: itemReceipt,
                invNo: itemReceipt.invoice_number,
                paymentDate: dayjs(itemReceipt.datetime).format("DD/MM/YYYY"),
                paymentTime: dayjs(itemReceipt.datetime).format("HH:mm"),
                branch_id: itemBranch.branch_id,
                nameMenu: itemPromotion.name,
                discount: 9999,
                namePromotion: dataAPIPromotion.find(
                  (itemPromotionAll) =>
                    itemPromotionAll.id === itemPromotion.promotion_id
                ).promotion_lang.name,
                amount: 1,
                branchName: itemReceipt.receipt_config_receipt
                  ? itemReceipt.receipt_config_receipt.config_receipt_lang
                      .branch_name
                  : null,
              });
            });
          });
        dataAllPromotion.push(dataCollectPromotion);
      });

      dataAllPromotion.forEach((itemPromotion) => {
        itemPromotion.sort((a, b) => {
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        });
      });

      dataAllPromotion.forEach((item) => {
        item.forEach((itemPromotion) => {
          dataPromotionRows.push(itemPromotion);
        });
      });
      dataPromotionRows.sort(
        (a, b) =>
          dayjs(b.paymentDate, "DD/MM/YYYY") -
          dayjs(a.paymentDate, "DD/MM/YYYY")
      );
      dataPromotionRows.sort(
        (a, b) => dayjs(b.paymentTime, "HHmm") - dayjs(a.paymentTime, "HHmm")
      );
      // dataPromotionRows.push({
      //   invNo: "Total",
      //   paymentDate: null,
      //   paymentTime: null,
      //   namePromotion: null,
      //   amount: dataPromotiondataPromotionRows.reduce((acc, item) => acc + item.amount, 0),
      //   branchName: null,
      // });
    }
    let dataColumns = [
      {
        header: t("report_receipt_number"),
        accessorKey: "invNo",
        minWidth: i18n.language === "th" ? 60 : 30,
        onClick: (row) => onDialogBill(row),
        Cell: ({ cell }) => <TextTable props={{ text: cell.getValue() }} />,
      },
      {
        header: t("report_payment_date"),
        accessorKey: "paymentDate",
        minWidth: 50,
        isSorting: true,
        Cell: ({ cell }) => <TextTable props={{ text: cell.getValue() }} />,
      },
      {
        header: t("report_payment_time"),
        accessorKey: "paymentTime",
        minWidth: 50,
        isSorting: true,
        Cell: ({ cell }) => <TextTable props={{ text: cell.getValue() }} />,
      },
      {
        header: t("promotion_name"),
        accessorKey: "namePromotion",
        minWidth: 200,
        Cell: ({ cell }) => <TextTable props={{ text: cell.getValue() }} />,
      },
      {
        header: t("Amount"),
        accessorKey: "amount",
        isSorting: true,
        minWidth: 10,
        decimal: 0,
        Cell: ({ cell }) => (
          <TextTable
            props={{
              text: Number(cell.getValue()).toLocaleString(undefined, {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              }),
              alignText: "right",
            }}
          />
        ),
      },
      {
        header: t("report_discounted_price"),
        accessorKey: "discount",
        minWidth: i18n.language === "th" ? 40 : 40,
        isSorting: true,
        Cell: ({ cell }) => (
          <TextTable
            props={{
              text: Number(cell.getValue()).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }),
              alignText: "right",
            }}
          />
        ),
      },
      {
        header: t("report_branch"),
        accessorKey: "branchName",
        Cell: ({ cell }) => <TextTable props={{ text: cell.getValue() }} />,
      },
    ];

    dataColumns.forEach((item) => {
      if (item.header !== "Image" && item.header !== "Action") {
        headers.push(item.header);
      }
    });
    setColumns(dataColumns);
    //===== Start set header name export csv
    const csvStringArray = [
      headers,
      ...dataPromotionRows.map((item) => [
        item.invNo,
        item.paymentDate,
        item.paymentTime,
        item.namePromotion,
        item.amount,
        item.branchName,
      ]),
    ]
      .map((row) => row.join(","))
      .join("\n");

    setExportColumnName(csvStringArray);
    //===== End set header name export csv
    setRows(dataPromotionRows);
    setIsLoadingData(false);
  };
  const onConfirm = async () => {
    await setIsLoadingData(true);
    await fetchReport();
  };
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportAll]);
  return (
    <Box>
      <BreadcrumbsTitle
        props={{
          title: t("report_by_promotion_detail"),
        }}
      />
      <Box>
        <SelectCheckMarkBranch
          props={{
            onConfirm,
            setBranchSelectReport,
          }}
        />

        <DataTableReport
          statusViewMode={true}
          rows={rows}
          exportColumnName={exportColumnName}
          isLoadingData={isLoadingData}
          columns={columns}
          nameTotal="invNo"
          height={0.85}
          fileName={
            t("report_by_promotion_detail") +
            `${dayjs(branchSelectReportDate.startDate).format(
              "DD/MM/YYYY"
            )} - ${dayjs(branchSelectReportDate.endDate).format("DD/MM/YYYY")}`
          }
        />
        {dialogData.status ? (
          <DetailBillReceipt props={{ dialogData, handleClose }} />
        ) : null}
      </Box>
    </Box>
  );
}

export default ReportByPromotionDetail;
