import axios from "axios";
import { AccessTokenAPI, BusinessId, CompanyId, LanguageForm, ServiceId } from "../getLocalStorage";



export const configGetAllCategory = (templateId) => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_API_URL}/api/setting/branch/getAllCategory?template_id=${templateId}&language_code=${LanguageForm}&company_uid=${CompanyId}&business_uid=${BusinessId}&service_code=${ServiceId}`,
    headers: {
      Authorization: `Bearer ${AccessTokenAPI}`,
    },
  };
  return axios
    .request(config)
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      console.log(error);
    });
};
export const configUpdateMainCategory = (dataAPI) => {
  let config = {
    method: "put",
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_API_URL}/api/setting/branch/updateMainCategory?company_uid=${CompanyId}&business_uid=${BusinessId}&service_code=${ServiceId}`,
    headers: {
      Authorization: `Bearer ${AccessTokenAPI}`,
      "Content-Type": "application/json",
    },
    data: JSON.stringify(dataAPI),
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data.message;
    })
    .catch((error) => {
      console.log(error);
    });
};

export const configUpdateMinorCategory = (dataAPI) => {
  let config = {
    method: "put",
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_API_URL}/api/setting/branch/updateMinorCategory?company_uid=${CompanyId}&business_uid=${BusinessId}&service_code=${ServiceId}`,
    headers: {
      Authorization: `Bearer ${AccessTokenAPI}`,
      "Content-Type": "application/json",
    },
    data: JSON.stringify(dataAPI),
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data.message;
    })
    .catch((error) => {
      console.log(error);
    });
};

export const configSortMainCategory = (dataAPI) => {
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_API_URL}/api/setting/branch/sortMainCategory?company_uid=${CompanyId}&business_uid=${BusinessId}&service_code=${ServiceId}`,
    headers: {
      Authorization: `Bearer ${AccessTokenAPI}`,
      "Content-Type": "application/json",
    },
    data: JSON.stringify(dataAPI),
  };
  return axios
    .request(config)
    .then((response) => {
      return response.data.message;
    })
    .catch((error) => {
      console.log(error);
    });
};

export const configSortMinorCategory = (dataAPI) => {
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_API_URL}/api/setting/branch/sortMinorCategory?company_uid=${CompanyId}&business_uid=${BusinessId}&service_code=${ServiceId}`,
    headers: {
      Authorization: `Bearer ${AccessTokenAPI}`,
      "Content-Type": "application/json",
    },
    data: JSON.stringify(dataAPI),
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data.message;
    })
    .catch((error) => {
      console.log(error);
    });
};

export const configSyncMinorCategory = (dataAPI) => {
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_API_URL}/api/setting/branch/addMinorCategoryToTemplate?company_uid=${CompanyId}&business_uid=${BusinessId}&service_code=${ServiceId}`,
    headers: {
      Authorization: `Bearer ${AccessTokenAPI}`,
      "Content-Type": "application/json",
    },
    data: JSON.stringify(dataAPI),
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data.message;
    })
    .catch((error) => {
      console.log(error);
    });
};
