import { Box, IconButton, Typography } from "@mui/material";
import React, { useState } from "react";
import DraggableResizableImage from "./draggableResizableImage";
import { getImage } from "../../../../service/upload/getImage";
import Loading from "../../../../components/ui/loading";
function DropAreaTable({ props }) {
  let {
    zoneSelect,
    tableZoneBox,
    setTableZoneBox,
    setDialogAddTable,
    onEditTable,
    onClickCopyTableToZone,
    onUpdateTable,
    onRemoveTable,
    isLoadingUpdate,
  } = props;
  const [dragging, setDragging] = useState(false);
  const [activeBall, setActiveBall] = useState(null);
  const [offset, setOffset] = useState({ position_x: 0, position_y: 0 });
  const [editTable, setEditTable] = useState(null);
  const [enableResize, setEnableResize] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageSize, setImageSize] = useState({ width: null, height: null });

  const handleMouseDown = (event, ball) => {
    setDragging(true);
    const offsetX = event.clientX - ball.position_x;
    const offsetY = event.clientY - ball.position_y;
    setOffset({ position_x: offsetX, position_y: offsetY });
    setActiveBall(ball);
  };
  const handleMouseMove = (event) => {
    if (dragging && activeBall) {
      const updatedBalls = tableZoneBox.map((ball) => {
        if (ball.id === activeBall.id) {
          const newX = event.clientX - offset.position_x;
          const newY = event.clientY - offset.position_y;
          const boundedX = Math.min(Math.max(newX, 0), 1300 - 100);
          const boundedY = Math.min(Math.max(newY, 0), 700 - 100);
          return { ...ball, position_x: boundedX, position_y: boundedY };
        }
        return ball;
      });

      // Check for collisions and move balls if needed
      const movedBalls = checkCollisions(updatedBalls);
      setTableZoneBox(movedBalls);
    }
  };

  const checkCollisions = (updatedBalls) => {
    return updatedBalls.map((ball) => {
      if (ball.id !== activeBall.id) {
        // Check if the active ball collides with other balls
        const collisionDetected = updatedBalls.some((otherBall) => {
          if (otherBall.id !== ball.id) {
            const distance = Math.sqrt(
              Math.pow(ball.position_x - otherBall.position_x, 2) +
                Math.pow(ball.position_y - otherBall.position_y, 2)
            );
            return distance < 100; // Adjust this value as per the ball size
          }
          return false;
        });

        if (collisionDetected) {
          //   // Move the ball to a new position
          //   const newX = 0;
          //   const newY = 0;
          //   return { ...ball, x: newX, y: newY };
        }
      }
      return ball;
    });
  };
  const onUpdateTableInZone = (item) => {
    if (enableResize) {
      onUpdateTable(item);
    }
    setEditTable(null);
  };
  function getBase64ImageWidth(base64String) {
    return new Promise((resolve, reject) => {
      let img = new Image();
      img.onload = () => {
        resolve({ width: img.width, height: img.height });
      };
      img.onerror = (err) => {
        reject(err);
      };
      img.src = base64String;
    });
  }
  const onAspectRadio = async (ball) => {
    setEnableResize(ball.id);
    setEditTable(null);
    setActiveBall(null);
    const imageUrl = await getImage(ball.image);
    getBase64ImageWidth("data:image/png;base64," + imageUrl)
      .then((dimensions) => {
        setImageSize({ width: dimensions.width, height: dimensions.height });
      })
      .catch((error) => {
        console.error("Error loading image:", error);
      });
  };
  const handleMouseUp = (e) => {
    if (activeBall && !enableResize) {
      let dataFind = tableZoneBox.find((item) => item.id === activeBall.id);
      let statusShow = false;
      onUpdateTable(dataFind, statusShow);
      setDragging(false);
      setActiveBall(null);
    } else {
      setDragging(false);
      setActiveBall(null);
    }
  };
  const onSelectTable = (ball) => {
    setEditTable(ball);
    setEnableResize(null);
  };
  const handleSelect = (id) => {
    setSelectedImage(id);
  };
  const onConfirmResize = (ball) => {
    setEnableResize(null);
    setActiveBall(null);
    setEditTable(null);
    setSelectedImage(null);

    onUpdateTable(ball);
  };
  return (
    <Box
      sx={{
        overflow: "auto",
        width: "100%",
        height: "100%",
      }}
    >
      <Box
        className="draggable-area"
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        sx={{ position: "relative", zIndex: 98 }}
        align="left"
      >
        <Typography
          sx={{
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%,-50%)",
            color: "#cccccc90",
            fontSize: 32,
          }}
          className="unselectable"
        >
          Drag and Drop item zone
        </Typography>
        <IconButton
          sx={{
            position: "absolute",
            bottom: 10,
            right: 10,
            backgroundColor: "#cccccc",
            width: "50px",
            height: "50px",
          }}
          disabled={!zoneSelect}
          onClick={() => setDialogAddTable(true)}
        >
          +
        </IconButton>
        {isLoadingUpdate ? (
          <Loading />
        ) : (
          <>
            {tableZoneBox
              .filter((item) => item.template_zone_id === zoneSelect)
              .map((ball, index) => (
                <Box
                  key={ball.id}
                  className="ball"
                  sx={{
                    left: ball.position_x,
                    top: ball.position_y,
                    ":hover": {
                      cursor: editTable === ball.id ? "grab" : null,
                      opacity: 0.8,
                    },
                    position: "relative",
                    filter: `saturate(${ball.status === true ? 1 : 0})`,
                  }}
                  onMouseDown={(event) =>
                    editTable === ball.id ? handleMouseDown(event, ball) : null
                  }
                >
                  <Box>
                    <DraggableResizableImage
                      key={index}
                      onRemoveTable={onRemoveTable}
                      id={index}
                      imageSrc={ball.image}
                      onSelect={handleSelect}
                      isSelected={selectedImage === index}
                      onConfirmResize={onConfirmResize}
                      enableResize={enableResize}
                      TableName={ball.name}
                      editTable={editTable}
                      index={index}
                      onUpdateTableInZone={onUpdateTableInZone}
                      onAspectRadio={onAspectRadio}
                      onClickCopyTableToZone={onClickCopyTableToZone}
                      onEditTable={onEditTable}
                      ball={ball}
                      onSelectTable={onSelectTable}
                      imageSize={imageSize}
                    />
                  </Box>
                </Box>
              ))}
          </>
        )}
      </Box>
    </Box>
  );
}

export default DropAreaTable;
