import { Typography, Box } from "@mui/material";

export const TitleFormPaper = (props) => {
  const { text } = props;
  return (
    <Typography
      align="left"
      sx={(theme) => ({
        color: theme.palette.primaryText.main,
        fontFamily: "Kanit",
        // width: "100%",
        fontSize: "24px",
        p: 2,
        boxSizing: "border-box",
        borderBottom: "1px solid #00000020",
      })}
    >
      {text}
    </Typography>
  );
};
export const TitleTextfield = (props) => {
  const { text } = props;
  return (
    <Typography
      align="left"
      sx={(theme) => ({
        color: theme.palette.primaryText.main,
        fontFamily: "Kanit",
        // width: "100%",
        fontSize: "14px",
      })}
    >
      {text}
    </Typography>
  );
};
export const TextTable = ({ props }) => {
  const { text, colorSetting, alignText, fontWeight } = props;
  return (
    <Typography
      align="left"
      sx={(theme) => ({
        fontFamily: "Kanit",
        fontSize: "14px",
        fontWeight: fontWeight ? "bold" : "",
        // width: "250px",
        whiteSpace: "nowrap",
        // textTransform: "capitalize",
        overflow: "hidden",
        textAlign: alignText ? alignText : "left",
        textOverflow: "ellipsis",
        color: colorSetting ? colorSetting : null,
      })}
    >
      {text}
    </Typography>
  );
};
export const TextFormWithError = (props) => {
  const { text, textError } = props;

  return (
    <Box>
      {textError ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItem: "center",
            // gap: 1,
          }}
        >
          {/* <ErrorIcon sx={(theme) => ({ color: theme.palette.primary.main })} /> */}
          <Typography
            sx={(theme) => ({
              color: theme.palette.error.main,
              fontFamily: "Kanit",
              fontSize: 14,
            })}
          >
            {textError}*
          </Typography>
        </Box>
      ) : (
        <Typography
          align="left"
          sx={(theme) => ({
            fontFamily: "Kanit",
            fontSize: 14,
          })}
        >
          {text}
        </Typography>
      )}
    </Box>
  );
};
