import {
  Box,
  Dialog,
  DialogContent,
  FormControlLabel,
  FormHelperText,
  Switch,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import BreadcrumbsTitle from "../../../components/ui/breadcrumbsTitle";
import { t } from "i18next";
import { GridContainerSize } from "../../../components/utils/gridContainerSize";
import PaperWrapper from "../../../components/ui/paper/paperWrapper";
import TitleForm from "../../../components/ui/formComponent/titleForm";
import { useForm } from "react-hook-form";
import ConfirmButton from "../../../components/ui/button/confirmButton";
import { notifySuccess } from "../../../components/ui/popup/toastPopup";
// import TextfieldComponent from "../../../components/ui/textfield/textfieldComponent";
import StatusForm from "../../../components/ui/formComponent/statusForm";
import {
  addPaymentChannelToTemplate,
  getAllPaymentChannel,
} from "../../../service/paymentChannelAPI";
import { postPaymentChannel } from "../../../service/paymentChannelAPI";
import { getAllPaymentChannelTemplate } from "../../../service/paymentChannelAPI";
import SettingTypeBranch from "../../../components/ui/addTypeTemplate/settingTypeBranch";
import { BoxGapInput } from "../../../components/ui/box/boxGapInput";
import { useNavigate } from "react-router-dom";
import { getAllBranch } from "../../../service/branchAPI";
// import { useSchema } from "../../../components/hooks/use-schema";
// import { paymentChannel } from "../../../components/validation/paymentChannel";
import { TitleTextfield } from "../../../components/ui/textfield/textfieldCustom";
import DeliveryOptions from "./channelFormOptions/deliveryOptions";
import dataDelivery from "./channelFormOptions/delivery.json";
import eWallet from "./channelFormOptions/e-wallet.json";
import dataCard from "./channelFormOptions/card.json";
import dataNETS from "./channelFormOptions/nets.json";
import dataCashCard from "./channelFormOptions/cashCard.json";
import { getPaymentType } from "../../../service/paymentTypeAPI";
import Loading from "../../../components/ui/loading";
import EWalletOptions from "./channelFormOptions/eWalletOptions";
import CardOptions from "./channelFormOptions/cardOptions";
import NetsOptions from "./channelFormOptions/netsOptions";
import CashCardOptions from "./channelFormOptions/cashCardOptions";
import { BoxWrapper } from "../../../components/ui/box/boxWrapper";
import { BoxFormTitleAction } from "../../../components/ui/box/boxFormTitleAction";
import TitleHeaderForm from "../../../components/ui/formComponent/titleHeaderForm";
import BankTransferOptions from "./channelFormOptions/bankTransferOptions";

function PaymentChannelCreate() {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    clearErrors,
    control,
    formState: { errors },
  } = useForm();
  const paramsId =
    window.location.pathname.split("/")[
      window.location.pathname.split("/").length - 2
    ];
  const [showTemplate, setShowTemplate] = useState(false);
  const [freeTextStatus, setFreeTextStatus] = useState(false);
  const [allDataTemplate, setAllDataTemplate] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [statusButton, setStatusButton] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [idTemplate, setIdTemplate] = useState(null);

  const [data, setData] = useState(null);
  const [dataChannel, setDataChannel] = useState(null);
  const navigate = useNavigate();

  const fetchAllBranch = async (id) => {
    let dataAPI = await getAllBranch();
    dataAPI = dataAPI.filter((item) => item.branch_lang !== null);
    await fetchSettingSelectTemplate(dataAPI, id);
  };

  const fetchSettingSelectTemplate = async (dataBranch, id) => {
    let dataAPI = await getAllPaymentChannelTemplate(id);
    dataAPI = dataAPI[0].template_payment_channels;
    let dataBox = [];
    dataBranch.forEach((item) => {
      let dataNews = dataAPI.filter(
        (itemTemplate) => itemTemplate.template_id === item.template_id
      );
      if (dataNews.length > 0) {
        dataBox.push({
          branch: item,
          data: dataNews[0],
          select: false,
        });
      } else {
        dataBox.push({
          branch: item,
          data: null,
          select: false,
        });
      }
    });
    await setAllDataTemplate(dataBox);
  };
  const onConfirmForm = async (register) => {
    let { selectDelivery } = register;
    let dataSelect = [];
    if (data.type === "delivery") {
      dataSelect = dataDelivery;
    } else if (data.type === "e_wallet") {
      dataSelect = eWallet;
    } else if (data.type === "card") {
      dataSelect = dataCard;
    } else if (data.type === "nets") {
      dataSelect = dataNETS;
    } else if (data.type === "cash_card") {
      dataSelect = dataCashCard;
    } else {
      dataSelect = [];
    }
    let dataFindName = "";
    if (data.type !== "bank_transfer") {
      dataFindName = dataSelect.find((item) => item.id === selectDelivery).name;
      dataFindName = {
        name: dataFindName,
        alt_name: dataFindName,
      };
    } else {
      dataFindName = {
        name: register.bankTransferName,
        alt_name: register.bankTransferNameAlt,
      };
    }

    setIsSubmitting(true);
    let dataAPI = {
      status: statusButton,
      payment_type_id: String(paramsId),
      freetext_status: freeTextStatus,
      payment_channel_lang: {
        name: dataFindName.name,
        alt_name: dataFindName.alt_name,
        language_code: "ALL",
      },
    };
    let statusAPI = await postPaymentChannel(dataAPI);
    if (statusAPI.message === "OK") {
      notifySuccess({
        title: t("toast_successfully_added_information"),
      });
      if (statusButton) {
        setIdTemplate(statusAPI.data.id);
        fetchAllBranch(statusAPI.data.id);
        setShowTemplate(true);
      } else {
        navigate("/payment-type/sub/detail/" + paramsId);
      }
    }
    await new Promise((resolve) => setTimeout(resolve, 2000));
  };

  const onAddPaymentChannelPaymentChannelToTemplate = async () => {
    let branchArray = allDataTemplate.filter((item) => item.select === true);
    let dataSelect = [];
    branchArray.forEach((item) => {
      dataSelect.push(item.branch.id);
    });
    let dataAPI = {
      payment_type_id: paramsId,
      payment_channel_id: idTemplate,
      branches: dataSelect,
      priority: 1,
    };
    let dataStatus = await addPaymentChannelToTemplate(dataAPI);
    if (dataStatus === "OK") {
      notifySuccess({
        title: t("toast_successfully_added_information"),
      });
      navigate("/payment-type/sub/detail/" + paramsId);
    }
  };
  const fetchData = async () => {
    let dataAPI = await getPaymentType(paramsId);
    let dataAPIChannel = await getAllPaymentChannel(paramsId);
    dataAPIChannel = dataAPIChannel.filter(
      (item) => item.payment_type_id === paramsId
    );

    setData(dataAPI);
    setDataChannel(dataAPIChannel);
    setIsLoading(false);
  };
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Box>
      <BreadcrumbsTitle
        props={{
          title: t("payment_channel_add"),
          dataLink: [
            { text: t("payment_type"), link: "/payment-type" },
            {
              text: t("payment_type_channel"),
              link: "/payment-type/sub/detail/" + paramsId,
            },
            { text: t("payment_channel_add"), link: null },
          ],
        }}
      />

      {isLoading ? (
        <Loading />
      ) : (
        <>
          <GridContainerSize>
            <Box p={2}>
              <form onSubmit={handleSubmit(onConfirmForm)}>
                <BoxWrapper>
                  <PaperWrapper props={{ growFull: 1 }}>
                    <BoxFormTitleAction>
                      <TitleHeaderForm props={{ text: t("payment_channel") }} />
                      <StatusForm
                        statusButton={statusButton}
                        setStatusButton={setStatusButton}
                      />
                    </BoxFormTitleAction>
                    <BoxGapInput>
                      <Box className="boxWrapper-container">
                        <PaperWrapper props={{ growFull: 1 }}>
                          <TitleForm
                            props={{ text: t("payment_channel_info") }}
                          />
                          <BoxGapInput p={2}>
                            {data.type === "delivery" && (
                              <DeliveryOptions
                                props={{
                                  register,
                                  watch,
                                  setValue,
                                  errors,
                                  clearErrors,
                                  control,
                                  setFreeTextStatus,
                                  dataChannel,
                                }}
                              />
                            )}
                            {data.type === "e_wallet" && (
                              <EWalletOptions
                                props={{
                                  register,
                                  watch,
                                  setValue,
                                  errors,
                                  clearErrors,
                                  control,
                                  setFreeTextStatus,
                                  dataChannel,
                                }}
                              />
                            )}
                            {data.type === "card" && (
                              <CardOptions
                                props={{
                                  register,
                                  watch,
                                  setValue,
                                  errors,
                                  clearErrors,
                                  control,
                                  setFreeTextStatus,
                                  dataChannel,
                                }}
                              />
                            )}
                            {data.type === "nets" && (
                              <NetsOptions
                                props={{
                                  register,
                                  watch,
                                  setValue,
                                  errors,
                                  clearErrors,
                                  control,
                                  setFreeTextStatus,
                                  dataChannel,
                                }}
                              />
                            )}
                            {data.type === "cash_card" && (
                              <CashCardOptions
                                props={{
                                  register,
                                  watch,
                                  setValue,
                                  errors,
                                  clearErrors,
                                  control,
                                  setFreeTextStatus,
                                  dataChannel,
                                }}
                              />
                            )}

                            {data.type === "bank_transfer" && (
                              <BankTransferOptions
                                props={{
                                  register,
                                  watch,
                                  setValue,
                                  errors,
                                  clearErrors,
                                  control,
                                  setFreeTextStatus,
                                  dataChannel,
                                }}
                              />
                            )}

                            {/* <TextfieldComponent
                    title={t("payment_channel_name")}
                    name="name"
                    register={register}
                    placeholder={t("payment_channel_example")}
                    errors={errors}
                  />
                  <TextfieldComponent
                    title={t("payment_channel_alt_name")}
                    name="altName"
                    register={register}
                    placeholder={t("payment_channel_example")}
                    errors={errors}
                  /> */}
                            <Box align="start">
                              <TitleTextfield text={t("global_free_text")} />
                              <FormHelperText>
                                {t(
                                  "global_payment_channel_text_free_suggestion"
                                )}
                              </FormHelperText>
                              <FormControlLabel
                                onChange={(e) =>
                                  setFreeTextStatus(e.target.checked)
                                }
                                control={<Switch checked={freeTextStatus} />}
                                label={
                                  freeTextStatus
                                    ? t("global_active")
                                    : t("global_in_active")
                                }
                              />
                            </Box>
                          </BoxGapInput>
                        </PaperWrapper>

                        <ConfirmButton disabled={isSubmitting} />
                      </Box>
                    </BoxGapInput>
                  </PaperWrapper>
                </BoxWrapper>
              </form>
            </Box>
          </GridContainerSize>
          <Dialog open={showTemplate} maxWidth="md" fullWidth>
            <DialogContent>
              <SettingTypeBranch
                title={t("payment_channel_branch")}
                cancelPath={"/payment-type/sub/detail/" + paramsId}
                onAddTypeToTemplate={
                  onAddPaymentChannelPaymentChannelToTemplate
                }
                allDataTemplate={allDataTemplate}
                setAllDataTemplate={setAllDataTemplate}
              />
            </DialogContent>
          </Dialog>
        </>
      )}
    </Box>
  );
}

export default PaymentChannelCreate;
