import React from "react";
import { BoxWrapper } from "../../../../components/ui/box/boxWrapper";
import PaperWrapper from "../../../../components/ui/paper/paperWrapper";
import { t } from "i18next";
import TitleForm from "../../../../components/ui/formComponent/titleForm";
import { Box, FormControlLabel, Switch } from "@mui/material";
import SelectComponent from "../../../../components/ui/textfield/selectComponent";
import verificationData from "../verificationData.json";
import TextfieldComponent from "../../../../components/ui/textfield/textfieldComponent";
import { TitleTextfield } from "../../../../components/ui/textfield/textfieldCustom";

function SelfPickupConfigForm({ props }) {
  let {
    register,
    errors,
    setValue,
    control,
    clearErrors,
    watch,
    confirmOrderAuto,
    setConfirmOrderAuto,
    mergeOrderReceipt,
    setMergeOrderReceipt,
    orderOverLimit,
    setOrderOverLimit,
    orderFitStock,
    setOrderFitStock,
    stockLimit,
    setStockLimit,
    stockLimitDisabled,
    verificationTypeDisabled,
  } = props;
  return (
    <div>
      <BoxWrapper>
        <PaperWrapper props={{ growFull: 1 }}>
          <TitleForm props={{ text: t("self_pickup_setup_status") }} />
          <Box p={2}>
            <SelectComponent
              title={t("self_pickup_setup_verification_type")}
              watch={watch}
              register={register}
              name="verificationType"
              errors={errors}
              setValue={setValue}
              control={control}
              clearErrors={clearErrors}
              dataEmpty={t("self_pickup_setup_select_verification_type")}
              optionsData={verificationData}
              fullGrid={true}
              disabled={verificationTypeDisabled}
            />
            {watch("paymentType") === 3 ? (
              <TextfieldComponent
                title={t("self_pickup_setup_payment_api")}
                name="paymentAPI"
                register={register}
                placeholder={t("Url")}
                errors={errors}
                fullGrid={true}
              />
            ) : null}

            <Box mt={1}>
              <TitleTextfield
                text={t("self_pickup_setup_confirm_order_auto")}
              />
              <FormControlLabel
                label={
                  confirmOrderAuto ? t("global_active") : t("global_in_active")
                }
                control={
                  <Switch
                    onChange={(e) => setConfirmOrderAuto(!confirmOrderAuto)}
                    checked={confirmOrderAuto}
                  />
                }
              />
              <TitleTextfield text={t("self_pickup_setup_merge_order_auto")} />
              <FormControlLabel
                label={
                  mergeOrderReceipt ? t("global_active") : t("global_in_active")
                }
                control={
                  <Switch
                    onChange={(e) => setMergeOrderReceipt(!mergeOrderReceipt)}
                    checked={mergeOrderReceipt}
                  />
                }
              />
              <TitleTextfield
                text={t("self_pickup_setup_order_over_receipt")}
              />
              <FormControlLabel
                label={
                  orderOverLimit ? t("global_active") : t("global_in_active")
                }
                control={
                  <Switch
                    onChange={(e) => setOrderOverLimit(!orderOverLimit)}
                    checked={orderOverLimit}
                  />
                }
              />
              <TitleTextfield text={t("self_pickup_setup_order_fit_stock")} />
              <FormControlLabel
                label={
                  orderFitStock ? t("global_active") : t("global_in_active")
                }
                control={
                  <Switch
                    onChange={(e) => setOrderFitStock(!orderFitStock)}
                    checked={orderFitStock}
                  />
                }
              />

              <TitleTextfield text={t("self_pickup_setup_stock_limit")} />
              <FormControlLabel
                label={stockLimit ? t("global_active") : t("global_in_active")}
                control={
                  <Switch
                    onChange={(e) => setStockLimit(!stockLimit)}
                    checked={stockLimit}
                    disabled={stockLimitDisabled}
                  />
                }
              />
            </Box>
          </Box>
        </PaperWrapper>
      </BoxWrapper>
    </div>
  );
}

export default SelfPickupConfigForm;
