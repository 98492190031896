import { Box, useTheme } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";

import { t } from "i18next";
import dayjs from "dayjs";
import BreadcrumbsTitle from "../../../components/ui/breadcrumbsTitle";
import { TextTable } from "../../../components/ui/textfield/textfieldCustom";
import { getAllSaleProduct } from "../../../service/saleProductAPI";
import { MyContext } from "../../../context/MyContext";
import DataTableReport from "../../../components/ui/dataTable/dataTableReport";
import SelectCheckMarkBranch from "../../../components/ui/select/selectCheckMarkBranch";
import i18n from "../../../locals/i18n";

function ReportByMonthlyProduct() {
  const [rows, setRows] = useState([]);

  const [columns, setColumns] = useState([]);
  const [exportColumnName, setExportColumnName] = useState(null);
  const {
    branchAll,
    setBranchSelectReport,
    branchSelectReportDate,
    reportAll,
    fetchReport,
    saleProductSetAll,
  } = useContext(MyContext);

  const [isLoadingData, setIsLoadingData] = useState(
    reportAll.length > 0 ? false : true
  );
  const theme = useTheme();
  const getDaysInMonth = (year, month) => {
    return new Date(year, month, 0).getDate();
  };

  const fetchData = async () => {
    let headers = [];
    const year = Number(dayjs(branchSelectReportDate.startDate).format("YYYY"));
    const month = Number(dayjs(branchSelectReportDate.startDate).format("MM"));

    let CountDate = await getDaysInMonth(year, month);
    const daysInMonth = getDaysInMonth(year, month);

    let dataAPISaleProduct =
      saleProductSetAll.length > 0
        ? saleProductSetAll
        : await getAllSaleProduct();
    let dataBranch = [];
    branchAll.forEach((itemBranch) => {
      let dataAllReceipt = reportAll.filter(
        (item) => item.branch_id === itemBranch.id
      );
      if (dataAllReceipt.length > 0) {
        dataBranch.push({
          branch_id: itemBranch.id,
          receiptAll: dataAllReceipt,
        });
      }
    });

    const combineData = (data1, data2) => {
      const combinedData = {};
      for (let key in data1) {
        combinedData[key] = data1[key] + data2[key];
      }
      return combinedData;
    };
    let dataAllSaleProduct = [];

    dataBranch.forEach((itemBranch) => {
      let dataCollectSaleProduct = [];
      itemBranch.receiptAll
        .filter((itemNoVoid) => itemNoVoid.receipt_status === "success")
        .forEach((itemReceipt) => {
          itemReceipt.receipt_sale_products.forEach((itemSaleProduct) => {
            const saleProduct = dataAPISaleProduct.find(
              (itemSaleProductAll) =>
                itemSaleProductAll.id === itemSaleProduct.sale_product_id
            );

            if (saleProduct) {
              dataCollectSaleProduct.push({
                branch_id: itemBranch.branch_id,
                id: itemSaleProduct.sale_product_id,
                name: itemSaleProduct.name,
                amount: itemSaleProduct.amount,
                price: itemSaleProduct.price,
                priceAfterDiscount: itemSaleProduct.price_after_discount,
                mainCategory:
                  saleProduct.main_categories[0].main_category_lang.name,
                minorCategory:
                  saleProduct.minor_categories.length > 0
                    ? saleProduct.minor_categories[0].minor_category_lang.name
                    : "None",
                branchName: itemReceipt.receipt_config_receipt
                  ? itemReceipt.receipt_config_receipt.config_receipt_lang
                      .branch_name
                  : null,
                time: {
                  "01":
                    dayjs(itemSaleProduct.datetime, "YYYYMMDDHHmmss").format(
                      "DD"
                    ) === "01"
                      ? itemSaleProduct.amount
                      : 0,
                  "02":
                    dayjs(itemSaleProduct.datetime, "YYYYMMDDHHmmss").format(
                      "DD"
                    ) === "02"
                      ? itemSaleProduct.amount
                      : 0,
                  "03":
                    dayjs(itemSaleProduct.datetime, "YYYYMMDDHHmmss").format(
                      "DD"
                    ) === "03"
                      ? itemSaleProduct.amount
                      : 0,
                  "04":
                    dayjs(itemSaleProduct.datetime, "YYYYMMDDHHmmss").format(
                      "DD"
                    ) === "04"
                      ? itemSaleProduct.amount
                      : 0,
                  "05":
                    dayjs(itemSaleProduct.datetime, "YYYYMMDDHHmmss").format(
                      "DD"
                    ) === "05"
                      ? itemSaleProduct.amount
                      : 0,
                  "06":
                    dayjs(itemSaleProduct.datetime, "YYYYMMDDHHmmss").format(
                      "DD"
                    ) === "06"
                      ? itemSaleProduct.amount
                      : 0,
                  "07":
                    dayjs(itemSaleProduct.datetime, "YYYYMMDDHHmmss").format(
                      "DD"
                    ) === "07"
                      ? itemSaleProduct.amount
                      : 0,
                  "08":
                    dayjs(itemSaleProduct.datetime, "YYYYMMDDHHmmss").format(
                      "DD"
                    ) === "08"
                      ? itemSaleProduct.amount
                      : 0,
                  "09":
                    dayjs(itemSaleProduct.datetime, "YYYYMMDDHHmmss").format(
                      "DD"
                    ) === "09"
                      ? itemSaleProduct.amount
                      : 0,
                  10:
                    dayjs(itemSaleProduct.datetime, "YYYYMMDDHHmmss").format(
                      "DD"
                    ) === "10"
                      ? itemSaleProduct.amount
                      : 0,
                  11:
                    dayjs(itemSaleProduct.datetime, "YYYYMMDDHHmmss").format(
                      "DD"
                    ) === "11"
                      ? itemSaleProduct.amount
                      : 0,
                  12:
                    dayjs(itemSaleProduct.datetime, "YYYYMMDDHHmmss").format(
                      "DD"
                    ) === "12"
                      ? itemSaleProduct.amount
                      : 0,
                  13:
                    dayjs(itemSaleProduct.datetime, "YYYYMMDDHHmmss").format(
                      "DD"
                    ) === "13"
                      ? itemSaleProduct.amount
                      : 0,
                  14:
                    dayjs(itemSaleProduct.datetime, "YYYYMMDDHHmmss").format(
                      "DD"
                    ) === "14"
                      ? itemSaleProduct.amount
                      : 0,
                  15:
                    dayjs(itemSaleProduct.datetime, "YYYYMMDDHHmmss").format(
                      "DD"
                    ) === "15"
                      ? itemSaleProduct.amount
                      : 0,
                  16:
                    dayjs(itemSaleProduct.datetime, "YYYYMMDDHHmmss").format(
                      "DD"
                    ) === "16"
                      ? itemSaleProduct.amount
                      : 0,
                  17:
                    dayjs(itemSaleProduct.datetime, "YYYYMMDDHHmmss").format(
                      "DD"
                    ) === "17"
                      ? itemSaleProduct.amount
                      : 0,
                  18:
                    dayjs(itemSaleProduct.datetime, "YYYYMMDDHHmmss").format(
                      "DD"
                    ) === "18"
                      ? itemSaleProduct.amount
                      : 0,
                  19:
                    dayjs(itemSaleProduct.datetime, "YYYYMMDDHHmmss").format(
                      "DD"
                    ) === "19"
                      ? itemSaleProduct.amount
                      : 0,
                  20:
                    dayjs(itemSaleProduct.datetime, "YYYYMMDDHHmmss").format(
                      "DD"
                    ) === "20"
                      ? itemSaleProduct.amount
                      : 0,
                  21:
                    dayjs(itemSaleProduct.datetime, "YYYYMMDDHHmmss").format(
                      "DD"
                    ) === "21"
                      ? itemSaleProduct.amount
                      : 0,
                  22:
                    dayjs(itemSaleProduct.datetime, "YYYYMMDDHHmmss").format(
                      "DD"
                    ) === "22"
                      ? itemSaleProduct.amount
                      : 0,
                  23:
                    dayjs(itemSaleProduct.datetime, "YYYYMMDDHHmmss").format(
                      "DD"
                    ) === "23"
                      ? itemSaleProduct.amount
                      : 0,
                  24:
                    dayjs(itemSaleProduct.datetime, "YYYYMMDDHHmmss").format(
                      "DD"
                    ) === "24"
                      ? itemSaleProduct.amount
                      : 0,
                  25:
                    dayjs(itemSaleProduct.datetime, "YYYYMMDDHHmmss").format(
                      "DD"
                    ) === "25"
                      ? itemSaleProduct.amount
                      : 0,
                  26:
                    dayjs(itemSaleProduct.datetime, "YYYYMMDDHHmmss").format(
                      "DD"
                    ) === "26"
                      ? itemSaleProduct.amount
                      : 0,
                  27:
                    dayjs(itemSaleProduct.datetime, "YYYYMMDDHHmmss").format(
                      "DD"
                    ) === "27"
                      ? itemSaleProduct.amount
                      : 0,
                  28:
                    dayjs(itemSaleProduct.datetime, "YYYYMMDDHHmmss").format(
                      "DD"
                    ) === "28"
                      ? itemSaleProduct.amount
                      : 0,
                  29:
                    dayjs(itemSaleProduct.datetime, "YYYYMMDDHHmmss").format(
                      "DD"
                    ) === "29"
                      ? itemSaleProduct.amount
                      : 0,
                  30:
                    dayjs(itemSaleProduct.datetime, "YYYYMMDDHHmmss").format(
                      "DD"
                    ) === "30"
                      ? itemSaleProduct.amount
                      : 0,
                  31:
                    dayjs(itemSaleProduct.datetime, "YYYYMMDDHHmmss").format(
                      "DD"
                    ) === "31"
                      ? itemSaleProduct.amount
                      : 0,
                },
                discountByItems:
                  (itemSaleProduct.price -
                    itemSaleProduct.price_after_discount) *
                  itemSaleProduct.amount,
              });
            }
          });
        });
      dataAllSaleProduct.push(dataCollectSaleProduct);
    });

    let dataBox = [];

    dataAllSaleProduct.forEach((itemBranch) => {
      let dataItemBrachBoxWithSaleProduct = [];
      itemBranch.forEach((itemAllProduct) => {
        let existingItem = dataItemBrachBoxWithSaleProduct.find(
          (itemFind) => itemFind.id === itemAllProduct.id
        );
        if (existingItem === undefined) {
          dataItemBrachBoxWithSaleProduct.push(itemAllProduct);
        } else {
          let combinedData = combineData(
            existingItem.time,
            itemAllProduct.time
          );
          existingItem.time = combinedData;
          existingItem.amount = existingItem.amount + itemAllProduct.amount;
          existingItem.discountByItems += itemAllProduct.discountByItems;
        }
      });
      dataBox.push(dataItemBrachBoxWithSaleProduct);
    });

    dataBox.forEach((itemReceipt) => {
      itemReceipt.sort((a, b) =>
        a.name < b.name ? -1 : a.name > b.name ? 1 : 0
      );
      itemReceipt.forEach((itemSaleProduct) => {
        itemSaleProduct.grossSale =
          itemSaleProduct.amount * itemSaleProduct.price;
        itemSaleProduct.discountedPrice =
          itemSaleProduct.grossSale - itemSaleProduct.discountByItems;
      });
    });

    let dataSaleProduct = [];
    dataBox.forEach((item) => {
      item.forEach((itemSaleProduct) => {
        dataSaleProduct.push(itemSaleProduct);
      });
    });

    // dataSaleProduct.push({
    //   name: "Total",
    //   mainCategory: null,
    //   minorCategory: null,
    //   amount: dataSaleProduct.reduce((acc, item) => acc + item.amount, 0),
    //   price: dataSaleProduct.reduce((acc, item) => acc + item.price, 0),
    //   grossSale: dataSaleProduct.reduce((acc, item) => acc + item.grossSale, 0),
    //   discountByItems: dataSaleProduct.reduce(
    //     (acc, item) => acc + item.discountByItems,
    //     0
    //   ),
    //   discountedPrice: dataSaleProduct.reduce(
    //     (acc, item) => acc + item.discountedPrice,
    //     0
    //   ),
    //   time: timeSlots.reduce((acc, slot) => {
    //     acc[slot] = dataSaleProduct.reduce(
    //       (sum, item) => sum + item.time[slot],
    //       0
    //     );
    //     return acc;
    //   }, {}),
    //   branchName: null,
    // });
    let dataNewBoxSaleProduct = [];
    dataSaleProduct.forEach((item) => {
      let newItem = { ...item, ...item.time };
      dataNewBoxSaleProduct.push(newItem);
    });
    setRows(dataNewBoxSaleProduct);

    let dataColumns = [
      {
        header: t("global_name"),
        accessorKey: "name",
        minWidth: 150,
        Cell: ({ cell }) => <TextTable props={{ text: cell.getValue() }} />,
      },
      {
        header: t("report_main_category"),
        accessorKey: "mainCategory",
        minWidth: i18n.language === "th" ? 150 : 110,
        Cell: ({ cell }) => <TextTable props={{ text: cell.getValue() }} />,
      },
      {
        header: t("report_subcategory"),
        accessorKey: "minorCategory",
        minWidth: i18n.language === "th" ? 150 : 100,
        Cell: ({ cell }) => <TextTable props={{ text: cell.getValue() }} />,
      },
      {
        header: t("report_amount"),
        accessorKey: "amount",
        isSorting: true,
        minWidth: i18n.language === "th" ? 70 : 80,
        Cell: ({ cell }) => (
          <TextTable
            props={{
              text: Number(cell.getValue()).toLocaleString(undefined, {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              }),
              alignText: "right",
            }}
          />
        ),
      },
      {
        header: t("report_price_per_unit"),
        accessorKey: "price",
        isSorting: true,
        minWidth: i18n.language === "th" ? 110 : 100,
        Cell: ({ cell }) => (
          <TextTable
            props={{
              text: Number(cell.getValue()).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }),
              alignText: "right",
            }}
          />
        ),
      },
      {
        header: t("report_gross_sale"),
        accessorKey: "grossSale",
        isSorting: true,
        minWidth: 100,
        Cell: ({ cell }) => (
          <TextTable
            props={{
              text: Number(cell.getValue()).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }),
              alignText: "right",
            }}
          />
        ),
      },
      {
        header: t("report_discount_by_item"),
        accessorKey: "discountByItems",
        isSorting: true,
        minWidth: i18n.language === "th" ? 100 : 120,
        Cell: ({ cell }) => (
          <TextTable
            props={{
              text: Number(cell.getValue()).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }),
              alignText: "right",
            }}
          />
        ),
      },
      {
        header: t("report_discounted_price"),
        accessorKey: "discountedPrice",
        isSorting: true,
        minWidth: i18n.language === "th" ? 100 : 130,
        Cell: ({ cell }) => (
          <TextTable
            props={{
              text: Number(cell.getValue()).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }),
              alignText: "right",
            }}
          />
        ),
      },
    ];

    Array.from({ length: daysInMonth }, (_, i) =>
      dataColumns.push({
        header: (i + 1).toString(),
        accessorKey: `${(i + 1).toString().padStart(2, "0")}`,
        isSorting: true,
        decimal: 0,
        center: true,
        minWidth: i18n.language === "th" ? 30 : 30,
        Cell: ({ cell }) => (
          <TextTable
            props={{
              text: cell.getValue() > 0 ? cell.getValue() : 0,
              alignText: "center",
              fontWeight: cell.getValue() > 0 ? true : false,
              colorSetting:
                cell.getValue() > 0 ? theme.palette.secondary.main : false,
            }}
          />
        ),
      })
    );

    dataColumns.push({
      header: t("report_branch"),
      accessorKey: "branchName",
      Cell: ({ cell }) => (
        <TextTable
          props={{
            text: cell.getValue(),
          }}
        />
      ),
    });
    dataColumns.forEach((item) => {
      if (item.header !== "Image" && item.header !== "Action") {
        headers.push(item.header);
      }
    });

    setColumns(dataColumns);
    //===== Start set header name export csv

    if (dataSaleProduct.length > 0) {
      let csvStringArray = [
        headers,
        ...dataSaleProduct.map((item) =>
          [
            item.name,
            item.mainCategory,
            item.minorCategory,
            item.amount,
            item.price,
            item.grossSale,
            item.discountByItems,
            item.discountedPrice,
          ].concat(
            ...Array.from(
              { length: CountDate },
              (_, i) => item.time[i.toString().padStart(2, "0")]
            )
          )
        ),
      ]
        .map((row) => row.join(","))
        .join("\n");
      setExportColumnName(csvStringArray);
    }
    //===== End set header name export csv
    setIsLoadingData(false);
  };
  const onConfirm = async () => {
    await setIsLoadingData(true);
    await fetchReport();
  };
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportAll]);
  return (
    <Box>
      <BreadcrumbsTitle
        props={{
          title: t("report_by_monthly_product"),
        }}
      />
      <Box>
        <SelectCheckMarkBranch
          props={{
            onConfirm,
            setBranchSelectReport,
            mode: "month",
          }}
        />

        <DataTableReport
          statusViewMode={true}
          rows={rows}
          isLoadingData={isLoadingData}
          exportColumnName={exportColumnName}
          columns={columns}
          height={0.85}
          fileName={
            t("report_by_monthly_product") +
            `${dayjs(branchSelectReportDate.startDate).format(
              "DD/MM/YYYY"
            )} - ${dayjs(branchSelectReportDate.endDate).format("DD/MM/YYYY")}`
          }
        />
      </Box>
    </Box>
  );
}

export default ReportByMonthlyProduct;
