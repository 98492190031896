import { Box } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";

import { t } from "i18next";
import dayjs from "dayjs";
import BreadcrumbsTitle from "../../../components/ui/breadcrumbsTitle";
import SelectCheckMarkBranch from "../../../components/ui/select/selectCheckMarkBranch";
import { TextTable } from "../../../components/ui/textfield/textfieldCustom";
import { getAllSaleProduct } from "../../../service/saleProductAPI";
import { MyContext } from "../../../context/MyContext";
import DataTableReport from "../../../components/ui/dataTable/dataTableReport";
import i18n from "../../../locals/i18n";

function ReportByMinorCategory() {
  const [rows, setRows] = useState([]);

  const [columns, setColumns] = useState([]);
  const [exportColumnName, setExportColumnName] = useState(null);
  const {
    branchAll,
    setBranchSelectReport,
    branchSelectReportDate,
    reportAll,
    fetchReport,
    saleProductSetAll,
  } = useContext(MyContext);

  const [isLoadingData, setIsLoadingData] = useState(
    reportAll.length > 0 ? false : true
  );
  const fetchData = async () => {
    let headers = [];
    let dataAPISaleProduct =
      saleProductSetAll.length > 0
        ? saleProductSetAll
        : await getAllSaleProduct();
    let dataBranch = [];
    branchAll.forEach((itemBranch) => {
      let dataAllReceipt = reportAll.filter(
        (item) => item.branch_id === itemBranch.id
      );
      if (dataAllReceipt.length > 0) {
        dataBranch.push({
          branch_id: itemBranch.id,
          receiptAll: dataAllReceipt,
        });
      }
    });

    let dataAllSaleProduct = [];
    dataBranch.forEach((itemBranch) => {
      let dataCollectSaleProduct = [];
      itemBranch.receiptAll
        .filter((itemNoVoid) => itemNoVoid.receipt_status === "success")
        .forEach((itemReceipt) => {
          itemReceipt.receipt_sale_products.forEach((itemSaleProduct) => {
            dataCollectSaleProduct.push({
              branch_id: itemBranch.branch_id,
              id: itemSaleProduct.sale_product_id,
              name: itemSaleProduct.name,
              amount: itemSaleProduct.amount,
              price: itemSaleProduct.price,
              priceAfterDiscount: itemSaleProduct.price_after_discount,
              mainCategory: dataAPISaleProduct.find(
                (itemSaleProductAll) =>
                  itemSaleProductAll.id === itemSaleProduct.sale_product_id
              ).main_categories[0].main_category_lang.name,
              minorCategory:
                dataAPISaleProduct.find(
                  (itemSaleProductAll) =>
                    itemSaleProductAll.id === itemSaleProduct.sale_product_id
                ).minor_categories.length > 0
                  ? dataAPISaleProduct.find(
                      (itemSaleProductAll) =>
                        itemSaleProductAll.id ===
                        itemSaleProduct.sale_product_id
                    ).minor_categories[0].minor_category_lang.name
                  : "None",
              branchName: itemReceipt.receipt_config_receipt
                ? itemReceipt.receipt_config_receipt.config_receipt_lang
                    .branch_name
                : null,
              discountByItems:
                (itemSaleProduct.price - itemSaleProduct.price_after_discount) *
                itemSaleProduct.amount,
            });
          });
        });
      dataAllSaleProduct.push(dataCollectSaleProduct);
    });
    let dataBox = [];
    dataAllSaleProduct.forEach((itemBranch) => {
      let dataItemBrachBoxWithSaleProduct = [];
      itemBranch.forEach((itemAllProduct) => {
        let existingItem = dataItemBrachBoxWithSaleProduct.find(
          (itemFind) => itemFind.id === itemAllProduct.id
        );
        if (existingItem === undefined) {
          dataItemBrachBoxWithSaleProduct.push(itemAllProduct);
        } else {
          existingItem.amount = existingItem.amount + itemAllProduct.amount;
          existingItem.discountByItems += itemAllProduct.discountByItems;
        }
      });
      dataBox.push(dataItemBrachBoxWithSaleProduct);
    });
    dataBox.forEach((itemReceipt) => {
      itemReceipt.sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      });
      itemReceipt.forEach((itemSaleProduct) => {
        itemSaleProduct.grossSale =
          itemSaleProduct.amount * itemSaleProduct.price;
        itemSaleProduct.discountedPrice =
          itemSaleProduct.grossSale - itemSaleProduct.discountByItems;
      });
    });
    let dataBoxMinor = [];
    dataBox.forEach((branch) => {
      branch.sort((a, b) => a.minorCategory.localeCompare(b.minorCategory));
      let dataBranch = [];
      branch.forEach((itemMinor) => {
        let itemMinorExist = dataBranch.find(
          (minor) => minor.minorCategory === itemMinor.minorCategory
        );
        if (itemMinorExist === undefined) {
          dataBranch.push(itemMinor);
        } else {
          itemMinorExist.amount += itemMinor.amount;
          itemMinorExist.grossSale += itemMinor.grossSale;
          itemMinorExist.discountByItems += itemMinor.discountByItems;
          itemMinorExist.discountedPrice += itemMinor.discountedPrice;
        }
      });
      dataBoxMinor.push(dataBranch);
    });
    let dataAll = [];
    dataBoxMinor.forEach((item) => {
      item.forEach((itemSaleProduct) => {
        dataAll.push(itemSaleProduct);
      });
    });
    // dataAll.push({
    //   minorCategory: "Total",
    //   mainCategory: null,
    //   amount: dataAll.reduce((acc, item) => acc + item.amount, 0),
    //   grossSale: dataAll.reduce((acc, item) => acc + item.grossSale, 0),
    //   discountByItems: dataAll.reduce(
    //     (acc, item) => acc + item.discountByItems,
    //     0
    //   ),
    //   discountedPrice: dataAll.reduce(
    //     (acc, item) => acc + item.discountedPrice,
    //     0
    //   ),
    //   branchName: null,
    // });

    setRows(dataAll);

    let dataColumns = [
      {
        header: t("report_subcategory"),
        accessorKey: "minorCategory",
        size: i18n.language === "th" ? 160 : 150,
        Cell: ({ cell }) => <TextTable props={{ text: cell.getValue() }} />,
      },
      {
        header: t("report_main_category"),
        accessorKey: "mainCategory",
        size: 150,
        Cell: ({ cell }) => <TextTable props={{ text: cell.getValue() }} />,
      },

      {
        header: t("report_amount"),
        accessorKey: "amount",
        isSorting: true,
        size: 80,
        Cell: ({ cell }) => (
          <TextTable
            props={{
              text: Number(cell.getValue()).toLocaleString(undefined, {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              }),
              alignText: "right",
            }}
          />
        ),
      },

      {
        header: t("report_gross_sale"),
        accessorKey: "grossSale",
        isSorting: true,
        flexGrow: 1,
        Cell: ({ cell }) => (
          <TextTable
            props={{
              text: Number(cell.getValue()).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }),
              alignText: "right",
            }}
          />
        ),
      },
      {
        header: t("report_discount_by_item"),
        accessorKey: "discountByItems",
        isSorting: true,
        size: 150,
        Cell: ({ cell }) => (
          <TextTable
            props={{
              text: Number(cell.getValue()).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }),
              alignText: "right",
            }}
          />
        ),
      },
      {
        header: t("report_discounted_price"),
        accessorKey: "discountedPrice",
        isSorting: true,
        size: 150,
        Cell: ({ cell }) => (
          <TextTable
            props={{
              text: Number(cell.getValue()).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }),
              alignText: "right",
            }}
          />
        ),
      },
      {
        header: t("report_branch"),
        accessorKey: "branchName",
        size: 100,
        Cell: ({ cell }) => <TextTable props={{ text: cell.getValue() }} />,
      },
    ];
    dataColumns.forEach((item) => {
      if (item.header !== "Image" && item.header !== "Action") {
        headers.push(item.header);
      }
    });
    setColumns(dataColumns);
    //===== Start set header name export csv
    const csvStringArray = [
      headers,
      ...dataAll.map((item) => [
        item.minorCategory,
        item.mainCategory,
        item.amount,
        item.grossSale,
        item.discountByItems,
        item.discountedPrice,
        item.branchName,
      ]),
    ]
      .map((row) => row.join(","))
      .join("\n");

    setExportColumnName(csvStringArray);
    //===== End set header name export csv
    setIsLoadingData(false);
  };
  const onConfirm = async () => {
    await setIsLoadingData(true);
    await fetchReport();
  };
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportAll]);
  return (
    <Box>
      <BreadcrumbsTitle
        props={{
          title: t("report_by_minor_category"),
        }}
      />
      <Box>
        <SelectCheckMarkBranch
          props={{
            onConfirm,
            setBranchSelectReport,
          }}
        />

        <DataTableReport
          statusViewMode={true}
          rows={rows}
          isLoadingData={isLoadingData}
          exportColumnName={exportColumnName}
          columns={columns}
          height={0.85}
          fileName={
            t("report_by_minor_category") +
            `${dayjs(branchSelectReportDate.startDate).format(
              "DD/MM/YYYY"
            )} - ${dayjs(branchSelectReportDate.endDate).format("DD/MM/YYYY")}`
          }
        />
      </Box>
    </Box>
  );
}

export default ReportByMinorCategory;
