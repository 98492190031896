import backScreen from "./backScreen.json";
import branch from "./branch.json";
import voidReceipt from "./voidReceipt.json";
import category from "./category.json";
import currency from "./currency.json";
import device from "./device.json";
import moneyIn from "./moneyIn.json";
import moneyOut from "./moneyOut.json";
import paymentType from "./paymentType.json";
import productOption from "./productOption.json";
import productOptionCate from "./productOptionCate.json";
import product from "./product.json";
import productCate from "./productCate.json";
import saleProduct from "./saleProduct.json";
import sidebar from "./sidebar.json";
import staffPOS from "./staffPOS.json";
import unit from "./unit.json";
import notification from "./notification.json";
import toast from "./toast.json";
import global from "./global.json";
import address from "./address.json";
import button from "./button.json";
import promotion from "./promotion.json";
import orderType from "./orderType.json";
import coupon from "./coupon.json";
import receipt from "./receipt.json";
import validate from "./validate.json";
import taxInvoice from "./taxInvoice.json";
import closeShop from "./closeShop.json";
import selfPickup from "./selfPickup.json";
import lineProvider from "./lineProvider.json";
import report from "./report.json";
import table from "./table.json";
import dashboard from "./dashboard.json";

export const en = {
  ...backScreen,
  ...branch,
  ...voidReceipt,
  ...category,
  ...currency,
  ...device,
  ...moneyIn,
  ...moneyOut,
  ...paymentType,
  ...productOption,
  ...productOptionCate,
  ...product,
  ...productCate,
  ...saleProduct,
  ...sidebar,
  ...staffPOS,
  ...unit,
  ...notification,
  ...toast,
  ...global,
  ...address,
  ...button,
  ...promotion,
  ...orderType,
  ...coupon,
  ...receipt,
  ...validate,
  ...taxInvoice,
  ...closeShop,
  ...selfPickup,
  ...lineProvider,
  ...report,
  ...table,
  ...dashboard,
};
