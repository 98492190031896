import { useTranslation } from "react-i18next";
import SettingsIcon from "@mui/icons-material/Settings";
import SettingDevice from "../../../pages/settingPOS/settingDevice";

export const ListSettingPos = () => {
  const { t } = useTranslation();
  const listItem = [
    {
      name: t("device_pos"),
      exact: true,
      component: <SettingDevice />,
      to: "/setting-device",
      iconClassName: <SettingsIcon sx={{ fontSize: 20 }} />,
    },
  ];
  return listItem;
};
