import { List } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";
import GridViewIcon from "@mui/icons-material/GridView";
import React from "react";

function ViewMode({ props }) {
  let { setViewMode, viewMode, name } = props;
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        justifyContent: "flex-end",
        boxSizing: "border-box",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "flex-end",
          gap: 1,
        }}
      >
        <IconButton
          sx={(theme) => ({
            border: 1,
            borderColor: "#00000020",
            borderRadius: 1,
            p: 0,
            width: "32px",
            height: "32px",
            backgroundColor:
              viewMode[name] === "list"
                ? theme.palette.primary.main + "80"
                : null,
            color: viewMode[name] === "list" ? theme.palette.white.main : null,
          })}
          onClick={() =>
            setViewMode({
              ...viewMode,
              [name]: "list",
            })
          }
        >
          <List />
        </IconButton>

        <IconButton
          sx={(theme) => ({
            border: 1,
            borderColor: "#00000020",
            borderRadius: 1,
            width: "32px",
            height: "32px",
            p: 0,
            backgroundColor:
              viewMode[name] === "grid"
                ? theme.palette.primary.main + "80"
                : null,
            color: viewMode[name] === "grid" ? theme.palette.white.main : null,
          })}
          onClick={() =>
            setViewMode({
              ...viewMode,
              [name]: "grid",
            })
          }
        >
          <GridViewIcon />
        </IconButton>
      </Box>
    </Box>
  );
}

export default ViewMode;
