import {
  Box,
  Button,
  TextField,
  Switch,
  Typography,
  Grid,
  Fab,
  FormHelperText,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { BoxWrapper } from "../../../components/ui/box/boxWrapper";
import PaperWrapper from "../../../components/ui/paper/paperWrapper";
import TitleForm from "../../../components/ui/formComponent/titleForm";
import { t } from "i18next";
import TextfieldComponent from "../../../components/ui/textfield/textfieldComponent";
import StatusForm from "../../../components/ui/formComponent/statusForm";
import ConfirmButton from "../../../components/ui/button/confirmButton";
import verificationData from "./verificationData.json";

import { isBrowser } from "react-device-detect";
import { DesktopTimePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import moment from "moment";
import { getAllBranch } from "../../../service/branchAPI";
import StorefrontIcon from "@mui/icons-material/Storefront";
import Loading from "../../../components/ui/loading";
import { setupSelfPickup } from "../../../service/selfPickUp/config/setupSelfPickup";
import { notifySuccess } from "../../../components/ui/popup/toastPopup";
import SelfPickupConfigForm from "./selfPickupConfig/selfPickupConfigForm";
import SelfPickupThemeForm from "./selfPickupTheme/selfPickupThemeForm";
function SelfPickupSetup({ props }) {
  let { fetchDataConfig } = props;
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    control,
    clearErrors,
  } = useForm();
  const [statusButton, setStatusButton] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [confirmOrderAuto, setConfirmOrderAuto] = useState(false);
  const [mergeOrderReceipt, setMergeOrderReceipt] = useState(false);
  const [stockLimit, setStockLimit] = useState(false);
  const [orderOverLimit, setOrderOverLimit] = useState(false);
  const [orderFitStock, setOrderFitStock] = useState(false);
  // const [imageBackground, setImageBackground] = useState(null);
  const [primaryColor, setPrimaryColor] = useState("#ffffff");
  const [secondaryColor, setSecondaryColor] = useState("#ffffff");
  const [branchSetup, setBranchSetup] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const [promotionSchedulesAllError, setPromotionSchedulesAllError] =
    useState(false);
  const [promotionSchedules, setPromotionSchedules] = useState([
    {
      day: "sunday",
      open: new Date().setHours(8, 0, 0),
      close: new Date().setHours(18, 0, 0),
      select: true,
    },
    {
      day: "monday",
      open: new Date().setHours(8, 0, 0),
      close: new Date().setHours(18, 0, 0),
      select: false,
    },
    {
      day: "tuesday",
      open: new Date().setHours(8, 0, 0),
      close: new Date().setHours(18, 0, 0),
      select: false,
    },
    {
      day: "wednesday",
      open: new Date().setHours(8, 0, 0),
      close: new Date().setHours(18, 0, 0),
      select: false,
    },
    {
      day: "thursday",
      open: new Date().setHours(8, 0, 0),
      close: new Date().setHours(18, 0, 0),
      select: false,
    },
    {
      day: "friday",
      open: new Date().setHours(8, 0, 0),
      close: new Date().setHours(18, 0, 0),
      select: false,
    },
    {
      day: "saturday",
      open: new Date().setHours(8, 0, 0),
      close: new Date().setHours(18, 0, 0),
      select: false,
    },
  ]);

  const onConfirmForm = async (register) => {
    setIsSubmitting(true);
    setTimeout(() => {
      setIsSubmitting(false);
    }, 1000);
    let promotionSchedulesBox = [];
    let statusDate = promotionSchedulesAll.select;
    let dataNew = promotionSchedules.filter(
      (itemPromotion) => itemPromotion.select === true
    );
    dataNew.forEach((itemPromotionDate) => {
      promotionSchedulesBox.push({
        day: itemPromotionDate.day,
        open: moment(
          statusDate ? promotionSchedulesAll.open : itemPromotionDate.open
        ).format("HHmmss"),
        close: moment(
          statusDate ? promotionSchedulesAll.close : itemPromotionDate.close
        ).format("HHmmss"),
        open_day: itemPromotionDate.select,
      });
    });
    let verificationTypeNew = verificationData.find(
      (item) => item.id === register.verificationType
    );
    let dataAPI = {
      verification_type: verificationTypeNew.name,
      confirm_order_auto: confirmOrderAuto,
      merge_order_receipt: mergeOrderReceipt,
      order_over_limit: orderOverLimit,
      order_fit_stock: orderFitStock,
      stock_limit: stockLimit,
      status: statusButton,
      payment_api: null,
      bank_accounts: [
        {
          account_name: register.accountName,
          account_no: register.accountNo,
          bank_name: register.bankName,
          branch_name: register.branchName,
          image: "",
        },
      ],
      theme: {
        background_image: "",
        primary_color: primaryColor,
        secondary_color: secondaryColor,
      },
      branch_configs: [
        {
          branch_id: 1,
          schedules: promotionSchedulesBox,
        },
      ],
    };
    let statusAPI = await setupSelfPickup(dataAPI);
    if (statusAPI === "OK") {
      notifySuccess({
        title: t("toast_successfully_added_information"),
      });
      fetchDataConfig();
    }
  };
  const fetchData = async () => {
    let dataBranch = await getAllBranch();
    let dataBranchFind = dataBranch.find((item) => item.id === 1);
    if (dataBranchFind) {
      setBranchSetup(dataBranchFind);
    } else {
      setBranchSetup(dataBranch[0]);
    }
    setIsLoading(false);
  };
  // const onAddBankAccount = async () => {
  //   let dataNews = [...bankAccount];
  //   dataNews.push({
  //     image: null,
  //     accountName: "",
  //     accountNo: "",
  //     bankName: "",
  //     branchName: "",
  //   });

  //   setBankAccount(dataNews);
  // };
  // const onRemoveBankAccount = async (index) => {
  //   if (bankAccount.length > 1) {
  //     if (index) {
  //       if (index > -1) {
  //         bankAccount.splice(index);
  //       }
  //       setBankAccount([...bankAccount]);
  //     }
  //   }
  // };
  const [promotionSchedulesAll, setPromotionSchedulesAll] = useState({
    open: new Date().setHours(8, 0, 0),
    close: new Date().setHours(18, 0, 0),
    select: false,
  });
  const handleErrorTime = (newValue, index) => {
    let dataNews = [...promotionSchedules];
    if (newValue) {
      dataNews[index].errorFormat = "wrong";
    } else {
      delete dataNews[index].errorFormat;
    }
    setPromotionSchedules(dataNews);
  };

  const handleErrorTimeAll = (newValue) => {
    if (newValue) {
      setPromotionSchedulesAllError(true);
    } else {
      setPromotionSchedulesAllError(false);
    }
  };
  const onSchedule = (index) => {
    setPromotionSchedules((prevSchedules) => {
      const updatedSchedules = [...prevSchedules];
      updatedSchedules[index].select = !updatedSchedules[index].select;
      return updatedSchedules;
    });
  };
  const onChangeStatusTimeAll = (e) => {
    setPromotionSchedulesAll((prevSchedules) => {
      const newItem = { ...prevSchedules };
      newItem.select = e.target.checked;
      return newItem;
    });

    let dataNews = [...promotionSchedules];
    dataNews.forEach((item) => {
      delete item.errorFormat;
    });
  };
  const handleTimeScheduleAllStart = (newValue) => {
    setPromotionSchedulesAll({
      ...promotionSchedulesAll,
      open: newValue,
    });
  };
  const handleTimeScheduleAllEnd = (newValue) => {
    setPromotionSchedulesAll({
      ...promotionSchedulesAll,
      close: newValue,
    });
  };
  const handleTimeScheduleEachStart = (newValue, index) => {
    let dateNews = [...promotionSchedules];
    dateNews[index].open = new Date(newValue);
    setPromotionSchedules(dateNews);
  };
  const handleTimeScheduleEachEnd = (newValue, index) => {
    let dateNews = [...promotionSchedules];
    dateNews[index].close = new Date(newValue);
    setPromotionSchedules(dateNews);
  };
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : (
        <Box p={2}>
          <form onSubmit={handleSubmit(onConfirmForm)}>
            <Box className="boxWrapper-container">
              <BoxWrapper>
                <PaperWrapper props={{ growFull: 1 }}>
                  <TitleForm props={{ text: t("self_pickup_setup_branch") }} />
                  <Box p={2}>
                    <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                      <StorefrontIcon />
                      <Typography sx={{ fontWeight: "bold" }}>
                        {t("branch_name")} : {branchSetup.branch_lang.name}
                      </Typography>
                    </Box>
                    <FormHelperText>
                      {t("self_pickup_setup_suggest_first")}
                    </FormHelperText>
                  </Box>
                </PaperWrapper>
              </BoxWrapper>
              <SelfPickupConfigForm
                props={{
                  register,
                  errors,
                  setValue,
                  control,
                  clearErrors,
                  watch,
                  confirmOrderAuto,
                  setConfirmOrderAuto,
                  mergeOrderReceipt,
                  setMergeOrderReceipt,
                  orderOverLimit,
                  setOrderOverLimit,
                  orderFitStock,
                  setOrderFitStock,
                  stockLimit,
                  setStockLimit,
                  stockLimitDisabled: false,
                  verificationTypeDisabled: false,
                }}
              />
              <BoxWrapper>
                <PaperWrapper props={{ growFull: 1 }}>
                  <TitleForm
                    props={{ text: t("self_pickup_setup_bank_account") }}
                  />
                  {/* {bankAccount.map((item, index) => (
                    <> */}
                  <Box sx={{ display: "flex" }}>
                    {/* <Box align="center">
                        <ImageCrop
                          props={{
                            setImage,
                            image,
                            heightCrop: 256,
                            widthCrop: 256,
                            label: "staffPOS",
                            type: "table",
                          }}
                        />
                      </Box> */}
                    <Box sx={{ width: "100%", p: 2 }}>
                      <BoxWrapper>
                        <TextfieldComponent
                          title={t("self_pickup_setup_account_name")}
                          name={`accountName`}
                          register={register}
                          placeholder={t("self_pickup_setup_account_name")}
                          errors={errors}
                        />
                        <TextfieldComponent
                          title={t("self_pickup_setup_account_no")}
                          name={`accountNo`}
                          register={register}
                          placeholder={t("self_pickup_setup_account_no")}
                          errors={errors}
                        />
                      </BoxWrapper>
                      <BoxWrapper>
                        <TextfieldComponent
                          title={t("self_pickup_setup_bank_name")}
                          name={`bankName`}
                          register={register}
                          placeholder={t("self_pickup_setup_bank_name")}
                          errors={errors}
                        />
                        <TextfieldComponent
                          title={t("self_pickup_setup_branch_name")}
                          name={`branchName`}
                          register={register}
                          placeholder={t("self_pickup_setup_branch_name")}
                          errors={errors}
                        />
                      </BoxWrapper>
                    </Box>

                    {/* <IconButton onClick={() => onRemoveBankAccount(index)}>
                      <RemoveCircleOutlineIcon />
                    </IconButton> */}
                  </Box>
                  {/* </>
                  ))} */}

                  {/* <Box sx={{ p: 2 }}>
                <Button
                  onClick={() => onAddBankAccount()}
                  sx={{ borderRadius: 2 }}
                  variant="outlined"
                  color="secondary"
                  fullWidth
                >
                  {t("Add Bank Account")}
                </Button>
              </Box> */}
                </PaperWrapper>
              </BoxWrapper>
              <BoxWrapper>
                {/* <PaperWrapper props={{ growFull: null }}>
                <TitleForm
                  props={{
                    text: t("self_pickup_setup_image_theme_background"),
                  }}
                />
                <Box align="center">
                  <ImageCrop
                    props={{
                      setImageBackground,
                      imageBackground,
                      heightCrop: 256,
                      widthCrop: 256,
                      label: "staffPOS",
                    }}
                  />
                </Box>
              </PaperWrapper> */}
                <SelfPickupThemeForm
                  props={{
                    primaryColor,
                    setPrimaryColor,
                    secondaryColor,
                    setSecondaryColor,
                  }}
                />
              </BoxWrapper>
              {/* time schedule */}

              <Box
                sx={(theme) => ({
                  display: "flex",
                  alignItems: "flex-start",
                  width: "100%",
                  boxSizing: "border-box",
                })}
              >
                <PaperWrapper props={{ flexGrow: 1, setting: { p: 2 } }}>
                  <Box
                    sx={{
                      width: "100%",
                    }}
                  >
                    <Box mb={2}>
                      <Typography sx={(theme) => ({ fontWeight: "bold" })}>
                        {t("self_pickup_setup_date_for_activating_the_system")}
                      </Typography>
                      <Typography sx={(theme) => ({ fontSize: 13 })}>
                        {t("self_pickup_setup_select_the_desire_date")}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        width: "100%",
                        boxSizing: "border-box",
                        border: "1px solid #cccccc",
                        borderRadius: 1,
                      }}
                    >
                      <Box
                        sx={(theme) => ({
                          display: "flex",
                          justifyContent: "space-evenly",
                          p: 1,
                          my: 2,
                        })}
                      >
                        {promotionSchedules.map((itemDate, indexDate) => (
                          <Fab
                            size="small"
                            sx={(theme) => ({ boxShadow: 0 })}
                            color={itemDate?.select ? "primary" : "default"}
                            aria-label="add"
                            onClick={() => onSchedule(indexDate)}
                          >
                            {itemDate.day.substring(0, 2)}
                          </Fab>
                        ))}
                      </Box>
                      <Box
                        sx={(theme) => ({
                          px: 2,
                          mb: 2,
                          boxSizing: "border-box",
                        })}
                      >
                        <Grid container alignItems={"center"}>
                          <Grid
                            item
                            xs={12}
                            sm={3}
                            sx={(theme) => ({
                              [theme.breakpoints.down("sm")]: {
                                justifyContent: "flex-start",
                              },
                              display: "flex",
                              justifyContent: "center",
                            })}
                          >
                            <Box>
                              <Switch
                                onChange={onChangeStatusTimeAll}
                                checked={promotionSchedulesAll.select}
                              />
                              <Typography>
                                {t("promotion_same_time")}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={9}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <Box
                                sx={(theme) => ({
                                  justifyContent: "center",
                                  display: "flex",
                                  alignItems: "center",
                                  gap: 2,
                                })}
                              >
                                <DesktopTimePicker
                                  label={t("global_open")}
                                  name="startDate"
                                  ampm={false}
                                  onChange={handleTimeScheduleAllStart}
                                  value={
                                    !promotionSchedulesAll.select
                                      ? null
                                      : promotionSchedulesAll.open
                                  }
                                  sx={{
                                    width: "100%",
                                  }}
                                  renderInput={(params) => (
                                    <TextField {...params} size="small" />
                                  )}
                                  disabled={!promotionSchedulesAll.select}
                                />

                                <Typography>-</Typography>
                                <DesktopTimePicker
                                  label={
                                    promotionSchedulesAllError
                                      ? t(
                                          "validate_please_fill_out_correct_format"
                                        )
                                      : t("global_close")
                                  }
                                  value={
                                    !promotionSchedulesAll.select
                                      ? null
                                      : promotionSchedulesAll.close
                                  }
                                  name="endDate"
                                  ampm={false}
                                  minTime={promotionSchedulesAll.open}
                                  onChange={handleTimeScheduleAllEnd}
                                  onError={(e) => handleErrorTimeAll(e)}
                                  sx={{
                                    width: "100%",
                                  }}
                                  renderInput={(params) => (
                                    <TextField {...params} size="small" />
                                  )}
                                  disabled={!promotionSchedulesAll.select}
                                />
                              </Box>
                            </LocalizationProvider>
                          </Grid>
                        </Grid>
                      </Box>
                      <Box
                        sx={(theme) => ({
                          px: 2,
                        })}
                      >
                        <Grid container alignItems={"center"}>
                          {promotionSchedules.map((itemDate, indexDate) => (
                            <>
                              {itemDate.select && (
                                <>
                                  <Grid
                                    item
                                    xs={12}
                                    sm={3}
                                    sx={{
                                      boxSizing: "border-box",
                                      px: 2,
                                      mb: 2,
                                    }}
                                  >
                                    <Button
                                      variant="contained"
                                      fullWidth
                                      sx={{ borderRadius: 2 }}
                                      disabled={promotionSchedulesAll.select}
                                    >
                                      {isBrowser
                                        ? itemDate.day
                                        : itemDate.day.substring(0, 2)}
                                    </Button>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    sm={9}
                                    sx={(theme) => ({ mb: 2 })}
                                  >
                                    <LocalizationProvider
                                      dateAdapter={AdapterDateFns}
                                    >
                                      <Box
                                        sx={(theme) => ({
                                          display: "flex",
                                          alignItems: "center",
                                          gap: 2,
                                        })}
                                      >
                                        <DesktopTimePicker
                                          label={t("global_open")}
                                          name={"start" + itemDate.day}
                                          value={itemDate.open}
                                          onChange={(e) =>
                                            handleTimeScheduleEachStart(
                                              e,
                                              indexDate
                                            )
                                          }
                                          ampm={false}
                                          sx={{
                                            width: "100%",
                                          }}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              error
                                              helperText="Your error message"
                                              size="small"
                                            />
                                          )}
                                          disabled={
                                            promotionSchedulesAll.select
                                          }
                                        />
                                        <Typography>-</Typography>
                                        <DesktopTimePicker
                                          label={
                                            itemDate.errorFormat === "wrong"
                                              ? t(
                                                  "validate_please_fill_out_correct_format"
                                                )
                                              : t("global_close")
                                          }
                                          value={itemDate.close}
                                          ampm={false}
                                          name={"end" + itemDate.day}
                                          minTime={itemDate.open}
                                          onChange={(e) =>
                                            handleTimeScheduleEachEnd(
                                              e,
                                              indexDate
                                            )
                                          }
                                          sx={{
                                            width: "100%",
                                          }}
                                          onError={(e) =>
                                            handleErrorTime(e, indexDate)
                                          }
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              size="small"
                                            />
                                          )}
                                          disabled={
                                            promotionSchedulesAll.select
                                          }
                                        />
                                      </Box>
                                    </LocalizationProvider>
                                  </Grid>
                                </>
                              )}
                            </>
                          ))}
                        </Grid>
                      </Box>
                    </Box>
                  </Box>
                </PaperWrapper>
              </Box>
            </Box>
            <StatusForm
              statusButton={statusButton}
              setStatusButton={setStatusButton}
            />
            <ConfirmButton disabled={isSubmitting} />
          </form>
        </Box>
      )}
    </div>
  );
}

export default SelfPickupSetup;
