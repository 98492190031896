import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from "@mui/material";
import React from "react";
import { Table } from "./table";
import { Close } from "@mui/icons-material";

function DialogSelectTable({ props }) {
  const tableTemplate = Table();
  let { dialogAddTable, setDialogAddTable, onClickAddTableToZone } = props;
  return (
    <Dialog open={dialogAddTable} fullWidth maxWidth="md">
      <DialogTitle>Add Table To Zone</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => setDialogAddTable(false)}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Close />
      </IconButton>
      <DialogContent>
        <Grid container spacing={2} alignItems={"center"}>
          {tableTemplate.map((table) => (
            <Grid item xs={3} onClick={() => onClickAddTableToZone(table)}>
              <Box
                sx={{
                  p: 2,
                  boxSizing: "border-box",
                  boxShadow: 2,
                }}
              >
                <img src={table.image} alt="table" width="100%" />
              </Box>
            </Grid>
          ))}
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default DialogSelectTable;
