import { Button } from "@mui/material";
import { t } from "i18next";
import React from "react";
import DirectionsIcon from "@mui/icons-material/Directions";

function EmptyDataDirect({ props }) {
  const { text, url } = props;
  return (
    <Button
      startIcon={<DirectionsIcon />}
      onClick={() => window.location.replace(url)}
      fullWidth
      variant="outlined"
      size="small"
      sx={{ borderRadius: 1 }}
    >
      {t("global_direct_to_page_data", { title: text })}
    </Button>
  );
}

export default EmptyDataDirect;
