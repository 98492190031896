import { Box } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";

import { t } from "i18next";
import moment from "moment";
import { MyContext } from "../../../context/MyContext";
import dayjs from "dayjs";
import BreadcrumbsTitle from "../../../components/ui/breadcrumbsTitle";
import SelectCheckMarkBranch from "../../../components/ui/select/selectCheckMarkBranch";
import { getCloseShopSummary } from "../../../service/report/getCloseShopSummary";
import { TextTable } from "../../../components/ui/textfield/textfieldCustom";
import ReceiptSummaryDetail from "./receiptSummaryDetail";
import DataTableReport from "../../../components/ui/dataTable/dataTableReport";
import i18n from "../../../locals/i18n";

function ReportByDateDrawer() {
  const {
    setBranchSelectReport,
    branchSelectReportDate,
    branchSelectReport,
    fetchReport,
  } = useContext(MyContext);
  const [rows, setRows] = useState([]);

  const [isLoadingData, setIsLoadingData] = useState(true);
  const [columns, setColumns] = useState([]);
  const [exportColumnName, setExportColumnName] = useState(null);

  const [dialogSummary, setDialogSummary] = useState({
    status: false,
    data: null,
  });
  const onCloseDialogSummary = () => {
    setDialogSummary({
      status: false,
      data: null,
    });
  };
  const onDialogSummary = async (dataAPI) => {
    setDialogSummary({
      status: true,
      data: dataAPI,
    });
  };
  const fetchData = async () => {
    let headers = [];
    let dataSentAPI = {
      start: dayjs(branchSelectReportDate.startDate).format("YYYYMMDDHHmmss"),
      end: dayjs(branchSelectReportDate.endDate).format("YYYYMMDDHHmmss"),
      branches: branchSelectReport,
    };
    let dataAPI = await getCloseShopSummary(dataSentAPI);
    let dataBox = [];
    if (dataAPI) {
      dataAPI.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));
      // eslint-disable-next-line array-callback-return
      dataAPI.map((item) => {
        let { info, open_close, summary, receipt_promotion, receipt_discount } =
          item.result_summary;
        let receiptDiscount = receipt_discount
          ? receipt_discount.result_total_amount_discount +
            receipt_discount.result_total_percent_discount
          : 0;

        let promotionDiscountTypeOne = receipt_promotion
          ? receipt_promotion.reduce(
              (acc, promotion) =>
                (acc +=
                  promotion.discount_type === 1 ? promotion.sum_received : 0),
              0
            )
          : 0;
        let promotionDiscountTypeTwo = receipt_promotion
          ? receipt_promotion.reduce(
              (acc, promotion) =>
                (acc +=
                  promotion.discount_type === 2 ? promotion.sum_received : 0),
              0
            )
          : 0;
        dataBox.push({
          dataAll: item,
          drawerCode: info.drawer_code,
          datetime: moment(info.datetime, "YYYYMMDDHHmmss").format(
            "DD/MM/YYYY"
          ),
          total: summary.result_total,
          discountByItems: Number(promotionDiscountTypeTwo),
          subTotalBillDiscount: promotionDiscountTypeOne + receiptDiscount,
          subTotal: summary.result_total_grand_total,
          serviceChange: summary.result_total_service_charge,
          beforeVat: Number(summary.result_total_grand_total_before_vat),
          vat: Number(summary.result_total_vat),
          roundingAmount: summary.total_round_off,
          refund: open_close.total_result_total_refund_receipt,
          amountBill: open_close.result_total_receipt,
          openBy: info.opened_by,
          openDate: moment(info.open_datetime, "YYYYMMDDHHmmss").format(
            "DD/MM/YYYY HH:mm"
          ),
          closeBy: info.closed_by,
          closeDate: moment(info.close_datetime, "YYYYMMDDHHmmss").format(
            "DD/MM/YYYY HH:mm"
          ),
          cashOpen: open_close.total_money_open,
          cashClose: open_close.total_money_close,
          grandTotalCash: open_close.total_grand_total_cash,
          cashIn: open_close.total_result_total_money_in,
          cashOut: open_close.total_result_total_money_out,
          totalExpectInDrawer: open_close.total_expected_cash_in_drawer,
          actualInDrawer: open_close.total_actual_in_drawer,
          difference: open_close.total_differrence,
          branchName: item.branch_name,
        });
      });
      // dataBox.push({
      //   drawerCode: "Total",
      //   datetime: null,
      //   total: dataBox.reduce((acc, item) => acc + item.total, 0),
      //   discountByItems: dataBox.reduce(
      //     (acc, item) => acc + item.discountByItems,
      //     0
      //   ),
      //   subTotalBillDiscount: dataBox.reduce(
      //     (acc, item) => acc + item.subTotalBillDiscount,
      //     0
      //   ),
      //   subTotal: dataBox.reduce((acc, item) => acc + item.subTotal, 0),
      //   serviceChange: dataBox.reduce(
      //     (acc, item) => acc + item.serviceChange,
      //     0
      //   ),
      //   beforeVat: dataBox.reduce((acc, item) => acc + item.beforeVat, 0),
      //   vat: dataBox.reduce((acc, item) => acc + item.vat, 0),
      //   roundingAmount: dataBox.reduce(
      //     (acc, item) => acc + item.roundingAmount,
      //     0
      //   ),
      //   refund: dataBox.reduce((acc, item) => acc + item.refund, 0),
      //   amountBill: dataBox.reduce((acc, item) => acc + item.amountBill, 0),
      //   openBy: null,
      //   openDate: null,
      //   closeBy: null,
      //   closeDate: null,
      //   cashOpen: dataBox.reduce((acc, item) => acc + item.cashOpen, 0),
      //   grandTotalCash: dataBox.reduce(
      //     (acc, item) => acc + item.grandTotalCash,
      //     0
      //   ),
      //   cashIn: dataBox.reduce((acc, item) => acc + item.cashIn, 0),
      //   cashOut: dataBox.reduce((acc, item) => acc + item.cashOut, 0),
      //   totalExpectInDrawer: dataBox.reduce(
      //     (acc, item) => acc + item.totalExpectInDrawer,
      //     0
      //   ),
      //   actualInDrawer: dataBox.reduce(
      //     (acc, item) => acc + item.actualInDrawer,
      //     0
      //   ),
      //   difference: dataBox.reduce((acc, item) => acc + item.difference, 0),
      //   branchName: null,
      // });
      setRows(dataBox);
    }
    let dataColumns = [
      {
        header: t("report_drawer_code"),
        accessorKey: "drawerCode",
        fixed: true,
        minWidth: i18n.language === "th" ? 120 : 100,
        Cell: ({ cell }) => (
          <Box
            onClick={() => onDialogSummary(cell.row.original)}
            sx={(theme) => ({
              color: theme.palette.secondary.main,
              ":hover": {
                opacity: 0.7,
                textDecoration: "underline",
                cursor: "pointer",
                color: theme.palette.primary.main,
              },
            })}
          >
            <TextTable
              props={{
                text: cell.getValue(),
              }}
            />
          </Box>
        ),
      },
      {
        header: t("report_date"),
        accessorKey: "datetime",
        minWidth: 80,
        Cell: ({ cell }) => <TextTable props={{ text: cell.getValue() }} />,
      },
      {
        header: t("report_summary_price"),
        accessorKey: "total",
        isSorting: true,
        minWidth: i18n.language === "th" ? 100 : 130,
        Cell: ({ cell }) => (
          <TextTable
            props={{
              text: Number(cell.getValue()).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }),
              alignText: "right",
            }}
          />
        ),
      },
      {
        header: t("report_discount_by_item"),
        accessorKey: "discountByItems",
        isSorting: true,
        minWidth: i18n.language === "th" ? 100 : 120,
        Cell: ({ cell }) => (
          <TextTable
            props={{
              text: Number(cell.getValue()).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }),
              alignText: "right",
            }}
          />
        ),
      },
      {
        header: t("report_discount_bill_end"),
        accessorKey: "subTotalBillDiscount",
        isSorting: true,
        minWidth: i18n.language === "th" ? 120 : 120,
        Cell: ({ cell }) => (
          <TextTable
            props={{
              text: Number(cell.getValue()).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }),
              alignText: "right",
            }}
          />
        ),
      },
      {
        header: t("report_sub_total"),
        accessorKey: "subTotal",
        isSorting: true,
        minWidth: 80,
        Cell: ({ cell }) => (
          <TextTable
            props={{
              text: Number(cell.getValue()).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }),
              alignText: "right",
            }}
          />
        ),
      },
      {
        header: t("report_service_charge"),
        accessorKey: "serviceChange",
        isSorting: true,
        minWidth: i18n.language === "th" ? 80 : 120,
        Cell: ({ cell }) => (
          <TextTable
            props={{
              text: Number(cell.getValue()).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }),
              alignText: "right",
            }}
          />
        ),
      },
      {
        header: t("report_before_vat"),
        accessorKey: "beforeVat",
        isSorting: true,
        minWidth: i18n.language === "th" ? 110 : 100,
        Cell: ({ cell }) => (
          <TextTable
            props={{
              text: Number(cell.getValue()).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }),
              alignText: "right",
            }}
          />
        ),
      },
      {
        header: t("report_vat"),
        accessorKey: "vat",
        isSorting: true,
        minWidth: i18n.language === "th" ? 60 : 50,
        Cell: ({ cell }) => (
          <TextTable
            props={{
              text: Number(cell.getValue()).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }),
              alignText: "right",
            }}
          />
        ),
      },
      {
        header: t("report_rounding_amount"),
        accessorKey: "roundingAmount",
        isSorting: true,
        minWidth: i18n.language === "th" ? 100 : 130,
        Cell: ({ cell }) => (
          <TextTable
            props={{
              text: Number(cell.getValue()).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }),
              alignText: "right",
            }}
          />
        ),
      },
      {
        header: t("report_refund"),
        accessorKey: "refund",
        isSorting: true,
        minWidth: i18n.language === "th" ? 80 : 60,

        Cell: ({ cell }) => (
          <TextTable
            props={{
              text: Number(cell.getValue()).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }),
              alignText: "right",
            }}
          />
        ),
      },
      {
        header: t("report_amount_bill"),
        accessorKey: "amountBill",
        isSorting: true,
        minWidth: i18n.language === "th" ? 80 : 80,

        Cell: ({ cell }) => (
          <TextTable
            props={{
              text: Number(cell.getValue()).toLocaleString(undefined, {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              }),
              alignText: "right",
            }}
          />
        ),
      },
      {
        minWidth: 150,
        header: t("report_open_by"),
        accessorKey: "openBy",
        Cell: ({ cell }) => <TextTable props={{ text: cell.getValue() }} />,
      },
      {
        minWidth: 150,
        header: t("report_open_date"),
        accessorKey: "openDate",
        Cell: ({ cell }) => <TextTable props={{ text: cell.getValue() }} />,
      },
      {
        minWidth: 150,
        header: t("report_close_by"),
        accessorKey: "closeBy",
        Cell: ({ cell }) => <TextTable props={{ text: cell.getValue() }} />,
      },
      {
        minWidth: 150,
        header: t("report_close_date"),
        accessorKey: "closeDate",
        Cell: ({ cell }) => <TextTable props={{ text: cell.getValue() }} />,
      },
      {
        minWidth: 100,
        header: t("report_cash_open"),
        accessorKey: "cashOpen",
        Cell: ({ cell }) => (
          <TextTable
            props={{
              text: Number(cell.getValue()).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }),
              alignText: "right",
            }}
          />
        ),
      },
      {
        minWidth: 100,
        header: t("report_cash_sale"),
        accessorKey: "grandTotalCash",
        Cell: ({ cell }) => (
          <TextTable
            props={{
              text: Number(cell.getValue()).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }),
              alignText: "right",
            }}
          />
        ),
      },
      {
        minWidth: 100,
        header: t("report_cash_in"),
        accessorKey: "cashIn",
        Cell: ({ cell }) => (
          <TextTable
            props={{
              text: Number(cell.getValue()).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }),
              alignText: "right",
            }}
          />
        ),
      },
      {
        minWidth: 100,
        header: t("report_cash_out"),
        accessorKey: "cashOut",
        Cell: ({ cell }) => (
          <TextTable
            props={{
              text: Number(cell.getValue()).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }),
              alignText: "right",
            }}
          />
        ),
      },
      {
        header: t("report_expected_cash_in_drawer"),
        accessorKey: "totalExpectInDrawer",
        minWidth: i18n.language === "th" ? 140 : 160,
        Cell: ({ cell }) => (
          <TextTable
            props={{
              text: Number(cell.getValue()).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }),
              alignText: "right",
            }}
          />
        ),
      },
      {
        header: t("actual_cash_in_drawer"),
        accessorKey: "actualInDrawer",
        minWidth: i18n.language === "th" ? 110 : 140,
        Cell: ({ cell }) => (
          <TextTable
            props={{
              text: Number(cell.getValue()).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }),
              alignText: "right",
            }}
          />
        ),
      },
      {
        minWidth: i18n.language === "th" ? 100 : 80,

        header: t("report_difference"),
        accessorKey: "difference",
        Cell: ({ cell }) => (
          <TextTable
            props={{
              text: Number(cell.getValue()).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }),
              alignText: "right",
            }}
          />
        ),
      },
      {
        minWidth: 150,
        header: t("branch"),
        accessorKey: "branchName",
        Cell: ({ cell }) => <TextTable props={{ text: cell.getValue() }} />,
      },
    ];
    dataColumns.forEach((item) => {
      if (item.header !== "Image" && item.header !== "Action") {
        headers.push(item.header);
      }
    });
    setColumns(dataColumns);
    //===== Start set header name export csv

    const csvStringArray = [
      headers,
      ...dataBox.map((item) => [
        item.drawerCode,
        item.datetime,
        item.total,
        item.discountByItems,
        item.subTotalBillDiscount,
        item.subTotal,
        item.serviceChange,
        item.beforeVat,
        item.vat,
        item.roundingAmount,
        item.refund,
        item.amountBill,
        item.openBy,
        item.openDate,
        item.closeBy,
        item.closeDate,
        item.cashOpen,
        item.grandTotalCash,
        item.cashIn,
        item.cashOut,
        item.totalExpectInDrawer,
        item.actualInDrawer,
        item.difference,
        item.branchName,
      ]),
    ]
      .map((row) => row.join(","))
      .join("\n");

    setExportColumnName(csvStringArray);
    //===== End set header name export csv
    setIsLoadingData(false);
  };
  const onConfirm = async () => {
    await setIsLoadingData(true);
    await fetchReport();
    await fetchData();
  };
  // const dateFormat = "DD/MM/YYYY";
  // const handleChangeDate = (newValue) => {
  //   setStartDate(dayjs(newValue[0], dateFormat));
  //   setEndDate(dayjs(newValue[1], dateFormat));
  // };
  // const maxDate = new Date();
  // const disabledDate = (current) => {
  //   return current && current > maxDate;
  // };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Box>
      <BreadcrumbsTitle
        props={{
          title: t("report_by_date_drawer"),
        }}
      />
      <Box>
        <SelectCheckMarkBranch
          props={{
            onConfirm,
            setBranchSelectReport,
          }}
        />

        <DataTableReport
          statusViewMode={true}
          rows={rows}
          isLoadingData={isLoadingData}
          columns={columns}
          exportColumnName={exportColumnName}
          height={0.85}
          fileName={
            t("report_by_date_drawer") +
            `${dayjs(branchSelectReportDate.startDate).format(
              "DD/MM/YYYY"
            )} - ${dayjs(branchSelectReportDate.endDate).format("DD/MM/YYYY")}`
          }
        />

        {dialogSummary.status ? (
          <ReceiptSummaryDetail
            props={{ dialogSummary, onCloseDialogSummary }}
          />
        ) : null}
      </Box>
    </Box>
  );
}

export default ReportByDateDrawer;
