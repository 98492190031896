import * as yup from "yup";
import { validationSchemas } from "./data";
import { ServiceStatus } from "../../service/getLocalStorage";
export const promotionSchema = (
  resultDiscountType,
  menuConditionMinimum,
  statusCoupon,
  statusLimit,
  discountType
) =>
  yup.object().shape({
    serviceSelect: ServiceStatus
      ? validationSchemas.numberSelect
      : validationSchemas.free,
    menuConditionMinimum: validationSchemas.numberSelect,
    conditionMinimum:
      menuConditionMinimum === 2
        ? validationSchemas.number
        : validationSchemas.numberInteger,
    resultDiscountType:
      discountType === 3
        ? validationSchemas.free
        : validationSchemas.numberSelect,
    resultDiscount:
      discountType === 3
        ? validationSchemas.free
        : resultDiscountType === 2
        ? validationSchemas.percent
        : validationSchemas.number,
    promotionName: validationSchemas.name,
    promotionDescription: validationSchemas.free,
    amountOfCoupon:
      statusCoupon.status === true && statusCoupon.type === "limit"
        ? validationSchemas.numberInteger
        : validationSchemas.free,
    couponCodeTicket:
      statusCoupon.status === true && statusCoupon.type === "unlimit"
        ? validationSchemas.name
        : validationSchemas.free,
    couponName: statusCoupon.status
      ? validationSchemas.name
      : validationSchemas.free,
    subCouponName: validationSchemas.free,
    couponDescription: validationSchemas.free,
    limitAmountUse: statusLimit ? validationSchemas.number : validationSchemas.free,
  });
