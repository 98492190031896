import React, { useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import "chartjs-plugin-datalabels";
import MaterialReactTable from "material-react-table";
import { TextTable } from "../../../../components/ui/textfield/textfieldCustom";
import { t } from "i18next";
import { Avatar, Box, Typography } from "@mui/material";
import comboProductIcon from "../../../../assets/svg/dashboard/comboProductIcon.svg";
import productPerformanceIcon from "../../../../assets/svg/dashboard/productPerformance.svg";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function ProductComboSuggestions({ props }) {
  let { dataProductCombo } = props;
  const [productCombo, setProductCombo] = useState([
    {
      accessorKey: "name",
      flexGrow: 1,
      header: t("sale_product"),
      Cell: ({ cell }) => <TextTable props={{ text: cell.getValue() }} />,
    },
    {
      accessorKey: "symbol",
      size: 10,
      header: t(""),
      Cell: ({ cell }) => <TextTable props={{ text: cell.getValue() }} />,
    },
    {
      accessorKey: "name2",
      flexGrow: 1,
      header: t("sale_product"),
      Cell: ({ cell }) => <TextTable props={{ text: cell.getValue() }} />,
    },
  ]);
  const [productCount, setProductCount] = useState(2);
  const [rows, setRows] = useState(
    dataProductCombo.data1.data.map((item) => ({
      name: item.item_1,
      symbol: "+",
      name2: item.item_2,
    }))
  );

  const onSelectProductCombo = (amount) => {
    setProductCount(amount);
    if (amount === 2) {
      let rowsData = dataProductCombo.data1.data.map((item) => ({
        name: item.item_1,
        symbol: "+",
        name2: item.item_2,
      }));
      let productListTable = [
        {
          accessorKey: "name",
          flexGrow: 1,
          header: t("sale_product"),
          Cell: ({ cell }) => <TextTable props={{ text: cell.getValue() }} />,
        },
        {
          accessorKey: "symbol",
          size: 10,
          header: t(""),
          Cell: ({ cell }) => <TextTable props={{ text: cell.getValue() }} />,
        },
        {
          accessorKey: "name2",
          flexGrow: 1,
          header: t("sale_product"),
          Cell: ({ cell }) => <TextTable props={{ text: cell.getValue() }} />,
        },
      ];
      setProductCombo(productListTable);
      setRows(rowsData);
    } else if (amount === 3) {
      let rowsData = dataProductCombo.data2.data.map((item) => ({
        name: item.item_1,
        symbol: "+",
        name2: item.item_2,
        symbol1: "+",
        name3: item.item_3,
      }));
      let productListTable = [
        {
          accessorKey: "name",
          flexGrow: 1,
          header: t("sale_product"),
          Cell: ({ cell }) => <TextTable props={{ text: cell.getValue() }} />,
        },
        {
          accessorKey: "symbol",
          size: 10,
          header: t(""),
          Cell: ({ cell }) => <TextTable props={{ text: cell.getValue() }} />,
        },
        {
          accessorKey: "name2",
          flexGrow: 1,
          header: t("sale_product"),
          Cell: ({ cell }) => <TextTable props={{ text: cell.getValue() }} />,
        },
        {
          accessorKey: "symbol1",
          size: 10,
          header: t(""),
          Cell: ({ cell }) => <TextTable props={{ text: cell.getValue() }} />,
        },
        {
          accessorKey: "name3",
          flexGrow: 1,
          header: t("sale_product"),
          Cell: ({ cell }) => <TextTable props={{ text: cell.getValue() }} />,
        },
      ];
      setProductCombo(productListTable);
      setRows(rowsData);
    }
  };
  return (
    <div>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 2,
          mb: 2,
        }}
      >
        <Avatar
          src={comboProductIcon}
          sx={{ borderRadius: 0, width: 24, height: 24 }}
        />
        <Typography
          variant="h3"
          sx={{
            fontWeight: "500",
          }}
        >
          Product Combo Suggestions
        </Typography>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            // width: "50%",
            gap: 4,
            // flexWrap: "wrap",
          }}
        >
          <Box
            sx={(theme) => ({
              p: 2,
              backgroundColor: "#fff",
              borderRadius: 5,
              border: `2px solid ${
                productCount === 2 ? theme.palette.primary.main : "#ccc"
              }`,
              boxShadow: 2,
              width: "100px",
              ":hover": { cursor: "pointer", opacity: 0.7 },
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            })}
            onClick={() => onSelectProductCombo(2)}
            align="center"
          >
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
                gap: 1,
              }}
            >
              <Avatar
                src={productPerformanceIcon}
                sx={{ borderRadius: 0, width: 24, height: 24 }}
              />

              <Avatar
                src={productPerformanceIcon}
                sx={{ borderRadius: 0, width: 24, height: 24 }}
              />
            </Box>
            <Typography sx={{ fontWeight: "bold", mt: 1 }}>2 Items</Typography>
          </Box>
          <Box
            sx={(theme) => ({
              p: 2,
              backgroundColor: "#fff",
              borderRadius: 5,
              border: `2px solid ${
                productCount === 3 ? theme.palette.primary.main : "#ccc"
              }`,
              boxShadow: 2,
              width: "100px",
              ":hover": { cursor: "pointer", opacity: 0.7 },
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            })}
            onClick={() => onSelectProductCombo(3)}
            align="center"
          >
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
                gap: 1,
              }}
            >
              <Avatar
                src={productPerformanceIcon}
                sx={{ borderRadius: 0, width: 24, height: 24 }}
              />

              <Avatar
                src={productPerformanceIcon}
                sx={{ borderRadius: 0, width: 24, height: 24 }}
              />

              <Avatar
                src={productPerformanceIcon}
                sx={{ borderRadius: 0, width: 24, height: 24 }}
              />
            </Box>
            <Typography sx={{ fontWeight: "bold", mt: 1 }}>3 Items</Typography>
          </Box>
        </Box>
      </Box>
      <MaterialReactTable
        columns={productCombo}
        data={rows}
        enableStickyHeader
        // defaultColumn={{
        //   maxSize: 400,
        //   minSize: 80,
        //   size: 100,
        // }}
        options={{
          search: false,
        }}
        // enableColumnResizing={true}

        enableTopToolbar={false}
        enableColumnActions={false}
        enableColumnFilters={false}
        enableSorting={false}
        muiTableBodyRowProps={{ hover: false }}
        enableToolbarInternalActions={false}
        muiTableProps={{
          sx: {
            tableLayout: "fixed",
          },
        }}
        muiTablePaginationProps={{
          rowsPerPageOptions: [25, 50, 100],
          showFirstButton: true,
          showLastButton: true,
        }}
      />
    </div>
  );
}

export default ProductComboSuggestions;
