import React, { useRef, useEffect, useState } from "react";
import { BoxImageZone } from "./boxImageZone";
import { Box, IconButton, Typography } from "@mui/material";
import { AspectRatio, Check, CopyAllOutlined, Edit } from "@mui/icons-material";

const ResizableDiv = ({
  imageSrc,
  id,
  onSelect,
  isSelected,
  enableResize,
  onConfirmResize,
  TableName,
  index,
  editTable,
  ball,
  onUpdateTableInZone,
  onAspectRadio,
  onClickCopyTableToZone,
  onEditTable,
  onSelectTable,
  imageSize,
}) => {
  const resizableRef = useRef(null);
  const [rotation, setRotation] = useState(0);
  let maxWidth = imageSize.width ? imageSize.width : 300;
  let minWidth = 120;

  useEffect(() => {
    if (isSelected) {
      const makeResizableDiv = (div) => {
        const element = div;
        const resizers = element.querySelectorAll(".resizer");
        const rotator = element.querySelector(".rotator");
        const minimum_size = 20;
        let original_width = 0;
        let original_height = 0;
        let original_x = 0;
        let original_y = 0;
        let original_mouse_x = 0;
        let original_mouse_y = 0;
        let original_rotation = 0;
        let aspectRatio = original_width / original_height;

        for (let i = 0; i < resizers.length; i++) {
          const currentResizer = resizers[i];
          // eslint-disable-next-line no-loop-func
          currentResizer.addEventListener("mousedown", function (e) {
            e.preventDefault();
            original_width = parseFloat(
              getComputedStyle(element, null)
                .getPropertyValue("width")
                .replace("px", "")
            );
            original_height = parseFloat(
              getComputedStyle(element, null)
                .getPropertyValue("height")
                .replace("px", "")
            );
            aspectRatio = original_width / original_height;
            original_x = element.getBoundingClientRect().left;
            original_y = element.getBoundingClientRect().top;
            original_mouse_x = e.pageX;
            original_mouse_y = e.pageY;
            window.addEventListener("mousemove", resize);
            window.addEventListener("mouseup", stopResize);
          });

          // eslint-disable-next-line no-loop-func
          const resize = (e) => {
            let width, height;

            if (currentResizer.classList.contains("bottom-right")) {
              width = original_width + (e.pageX - original_mouse_x);
              height = width / aspectRatio;
            } else if (currentResizer.classList.contains("bottom-left")) {
              width = original_width - (e.pageX - original_mouse_x);
              height = width / aspectRatio;
            } else if (currentResizer.classList.contains("top-right")) {
              width = original_width + (e.pageX - original_mouse_x);
              height = width / aspectRatio;
            } else {
              width = original_width - (e.pageX - original_mouse_x);
              height = width / aspectRatio;
            }

            if (
              width > minimum_size &&
              (!maxWidth || width <= maxWidth) &&
              (!minWidth || width >= minWidth)
            ) {
              element.style.width = width + "px";
              element.style.height = height + "px";
            }

            if (
              currentResizer.classList.contains("bottom-left") ||
              currentResizer.classList.contains("top-left")
            ) {
              element.style.left =
                original_x + (e.pageX - original_mouse_x) + "px";
            }
            if (
              currentResizer.classList.contains("top-right") ||
              currentResizer.classList.contains("top-left")
            ) {
              element.style.top =
                original_y + (e.pageY - original_mouse_y) + "px";
            }
          };

          const stopResize = () => {
            window.removeEventListener("mousemove", resize);
          };
        }

        if (rotator) {
          rotator.addEventListener("mousedown", function (e) {
            e.preventDefault();
            original_rotation = rotation;
            original_mouse_x = e.pageX;
            original_mouse_y = e.pageY;
            window.addEventListener("mousemove", rotate);
            window.addEventListener("mouseup", stopRotate);
          });

          const rotate = (e) => {
            const deltaX = e.pageX - original_mouse_x;
            const deltaY = e.pageY - original_mouse_y;
            const newRotation =
              original_rotation + Math.atan2(deltaY, deltaX) * (180 / Math.PI);
            setRotation(newRotation);
            element.style.transform = `rotate(${newRotation}deg)`;
          };

          const stopRotate = () => {
            window.removeEventListener("mousemove", rotate);
          };
        }
      };
      makeResizableDiv(resizableRef.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rotation, enableResize, imageSize]);

  return (
    <Box sx={{ position: "relative" }}>
      <div
        className={`resizable ${isSelected ? "selected" : ""}`}
        ref={resizableRef}
        onClick={() => onSelect(id)}
        style={{ transform: `rotate(${rotation}deg)` }}
      >
        <Box onClick={() => onSelectTable(ball.id)}>
          <BoxImageZone
            image={imageSrc}
            width="100%"
            height="100%"
            type="table"
          />
          <Typography
            sx={(theme) => ({
              fontSize: 14,
              fontWeight: 500,
              position: "absolute",
              left: "50%",
              top: "50%",
              zIndex: 99,
              transform: "translate(-50%, -50%)",
              color: theme.palette.primary.main,
            })}
            className="unselectable"
          >
            {TableName}
          </Typography>
        </Box>

        {editTable === ball.id ? (
          <Box className="boxTable">
            <IconButton
              onClick={() => onUpdateTableInZone(ball)}
              className="top-left"
              sx={{
                backgroundColor: "#ccc",
                color: "#000",
              }}
            >
              <Check sx={{ fontSize: 20 }} />
            </IconButton>

            <IconButton
              onClick={() => onAspectRadio(ball)}
              className="top-right"
              sx={{
                backgroundColor: "#ccc",
                color: "#000",
              }}
            >
              <AspectRatio sx={{ fontSize: 20 }} />
            </IconButton>

            <IconButton
              onClick={() => onClickCopyTableToZone(ball)}
              className="bottom-left"
              sx={{
                backgroundColor: "#ccc",
                color: "#000",
              }}
            >
              <CopyAllOutlined sx={{ fontSize: 20 }} />
            </IconButton>

            <IconButton
              onClick={(e) => onEditTable(ball, index, e)}
              className="bottom-right onEditTableForm"
              sx={{
                backgroundColor: "#ccc",
                color: "#000",
              }}
            >
              <Edit sx={{ fontSize: 20 }} />
            </IconButton>
          </Box>
        ) : null}
        {enableResize === ball.id ? (
          <div className="resizers">
            <div className="resizer top-left"></div>
            <div className="resizer top-right"></div>
            <div className="resizer bottom-left"></div>
            <div className="resizer bottom-right"></div>
            <div className="rotator"></div>
            <IconButton
              onClick={() => onConfirmResize(ball)}
              className="isChecked"
            >
              <Check />
            </IconButton>
          </div>
        ) : null}
      </div>
    </Box>
  );
};

export default ResizableDiv;
