import React from "react";
import PaperWrapper from "../../components/ui/paper/paperWrapper";
import TitleForm from "../../components/ui/formComponent/titleForm";
import {
  Avatar,
  Box,
  FormControlLabel,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Switch,
  Typography,
} from "@mui/material";
import { t } from "i18next";
import LoginIcon from "@mui/icons-material/Login";

function StaffPOSPermission({ props }) {
  let {
    checkPermissionAll,
    onCheckAllPermissions,
    permissionAll,
    convertNamePermission,
    onChangePermission,
  } = props;
  return (
    <div>
      <PaperWrapper props={{ growFull: 0 }}>
        <TitleForm props={{ text: t("staff_pos_permission") }} />
        <Box p={2}>
          <FormControlLabel
            control={
              <Switch
                checked={checkPermissionAll}
                onChange={(e) => onCheckAllPermissions(e)}
              />
            }
            sx={{ mb: 2 }}
            label={t("global_active_all")}
          />
          {permissionAll.slice(0, undefined).map((item, index) => (
            <>
              {index > 1 ? (
                <Box
                  htmlFor={index}
                  sx={(theme) => ({
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                    boxShadow: 1,
                    boxSizing: "border-box",
                    pr: 1,
                  })}
                >
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar
                        sx={(theme) => ({
                          backgroundColor: theme.palette.primary.main,
                        })}
                      >
                        <LoginIcon sx={{ fontSize: 16 }} />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <Typography sx={(theme) => ({})}>
                          {convertNamePermission(item.name)}
                        </Typography>
                      }
                      secondary={
                        item.value ? t("toast_active") : t("toast_in_active")
                      }
                    />
                  </ListItem>
                  <Switch
                    checked={item.value}
                    onChange={onChangePermission(index)}
                  />
                </Box>
              ) : null}
            </>
          ))}
        </Box>
      </PaperWrapper>
    </div>
  );
}

export default StaffPOSPermission;
