import React from "react";
import SelectComponent from "../../../../components/ui/textfield/selectComponent";
import { t } from "i18next";
import eWallet from "./e-wallet.json";
function EWalletOptions({ props }) {
  let {
    watch,
    setValue,
    errors,
    clearErrors,
    control,
    setFreeTextStatus,
    dataChannel,
    dataAllEditSelections,
  } = props;
  const onSelectDelivery = (value) => {
    setValue("selectDelivery", value);
    clearErrors("selectDelivery");
    if (value === 17) {
      setFreeTextStatus(true);
    } else {
      setFreeTextStatus(false);
    }
  };
  const compareArray = eWallet.filter(
    (item) =>
      !dataChannel.some(
        (fItem) => fItem.payment_channel_lang.name === item.name
      )
  );

  return (
    <div>
      <SelectComponent
        watch={watch}
        title={t("payment_channel")}
        name="selectDelivery"
        errors={errors}
        setValue={setValue}
        dataEmpty={t("payment_channel_select")}
        optionsData={
          dataAllEditSelections ? dataAllEditSelections : compareArray
        }
        onFunctionName={onSelectDelivery}
        disabled={
          dataAllEditSelections
            ? dataAllEditSelections.length === 0
            : compareArray.length === 0
        }
        clearErrors={clearErrors}
        control={control}
      />
    </div>
  );
}

export default EWalletOptions;
