// import Swal from "sweetalert2";

export const expiredToken = (data) => {
  if (
    data.response.data === "accessToken expired" ||
    data.response.data === "Unauthorized"
  ) {
    // window.location.replace(process.env.REACT_APP_SMART_PLATFORM_DOMAIN_URL + "/login");
    // Swal.fire({
    //   title: "Your session has expired.",
    //   icon: "warning",
    //   confirmButtonText: "BACK TO LOGIN",
    // }).then((result) => {
    //   if (result.isConfirmed) {
    //     window.location.replace(process.env.REACT_APP_SMART_PLATFORM_DOMAIN_URL + "/login");
    //   }
    // });
  }
};
