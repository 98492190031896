import { expiredToken } from "./expiredToken";
import {
  AccessTokenAPI,
  BusinessId,
  CompanyId,
  LanguageForm,
  ServiceId,
} from "./getLocalStorage";

import axios from "axios";

export const getAllProductCategory = () => {
  let config = {
    method: "get",
    url: `${process.env.REACT_APP_API_URL}/api/setting/getAllProductCategory?language_code=${LanguageForm}&company_uid=${CompanyId}&business_uid=${BusinessId}&service_code=${ServiceId}`,
    headers: {
      Authorization: `Bearer ${AccessTokenAPI}`,
    },
  };

  return axios(config)
    .then(function (response) {
      let dataBox = response.data.data;
      let dataModel = [];
      dataBox.forEach((item) => {
        dataModel.push({
          id: item.product_category_lang.product_category_id,
          name: item.product_category_lang.name,
          status: item.status,
          product_category_lang:item.product_category_lang
        });
      });
      return dataModel;
    })
    .catch(function (error) {
      console.log(error);
      expiredToken(error);
    });
};
export const getProductCategory = (paramsId) => {
  let dataAPI = "";
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_API_URL}/api/setting/getProductCategory?id=${paramsId}&language_code=${LanguageForm}&company_uid=${CompanyId}&business_uid=${BusinessId}&service_code=${ServiceId}`,
    headers: {
      Authorization: `Bearer ${AccessTokenAPI}`,
    },
    data: dataAPI,
  };

  return axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      console.log(error);
      expiredToken(error);
    });
};
export const updateProductCategory = (dataAPI) => {
  let config = {
    method: "put",
    url: `${process.env.REACT_APP_API_URL}/api/setting/updateProductCategory?company_uid=${CompanyId}&business_uid=${BusinessId}&service_code=${ServiceId}`,
    headers: {
      Authorization: `Bearer ${AccessTokenAPI}`,
      "Content-Type": "application/json",
    },
    data: JSON.stringify(dataAPI),
  };

  return axios(config)
    .then(function (response) {
      return response.data.message;
    })
    .catch(function (error) {
      console.log(error);
      expiredToken(error);
    });
};
export const postProductCategory = (dataAPI) => {
  let config = {
    method: "post",
    url: `${process.env.REACT_APP_API_URL}/api/setting/storeProductCategory?company_uid=${CompanyId}&business_uid=${BusinessId}&service_code=${ServiceId}`,
    headers: {
      Authorization: `Bearer ${AccessTokenAPI}`,
      "Content-Type": "application/json",
    },
    data: JSON.stringify(dataAPI),
  };

  return axios(config)
    .then(function (response) {
      return response.data.message;
    })
    .catch(function (error) {
      console.log(error);
      expiredToken(error);
    });
};
