import React, { useContext, useState } from "react";
import { Box } from "@mui/material";
import TitleForm from "../../../../components/ui/formComponent/titleForm";
import { updateBranch } from "../../../../service/branchAPI";
import { useTranslation } from "react-i18next";
import AddressForm from "../../../../components/ui/addressForm/addressForm";
import ConfirmButton from "../../../../components/ui/button/confirmButton";
import { notifySuccess } from "../../../../components/ui/popup/toastPopup";
import StatusForm from "../../../../components/ui/formComponent/statusForm";
// import { MapAddress } from "../../../../components/ui/mapAddress";
import AddressFormOptional from "../../../../components/ui/addressForm/addressFormOptional";
import TextfieldComponent from "../../../../components/ui/textfield/textfieldComponent";
import { BoxWrapper } from "../../../../components/ui/box/boxWrapper";
import PaperWrapper from "../../../../components/ui/paper/paperWrapper";
import { GridContainerSize } from "../../../../components/utils/gridContainerSize";
import { BoxGapInput } from "../../../../components/ui/box/boxGapInput";
import { MyContext } from "../../../../context/MyContext";
import { BoxFormTitleAction } from "../../../../components/ui/box/boxFormTitleAction";
import TitleHeaderForm from "../../../../components/ui/formComponent/titleHeaderForm";

function BranchInfo(props) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  let {
    setValue,
    statusButton,
    address,
    setAddress,
    register,
    errors,
    handleSubmit,
    fetchData,
    dataBranch,
    setStatusButton,
    reset,
    anchorMap,
    // setAnchorMap,
    control,
    clearErrors,
  } = props;
  const { fetchBranchData } = useContext(MyContext);

  const { t } = useTranslation();
  const [country, setCountry] = useState(null);
  const onConfirmForm = async (register) => {
    setIsSubmitting(true);
    let dataAPI = {
      id: dataBranch.id,
      b_branch_code: register.branchCode,
      r_branch_code: register.branchRevenueCode,
      floor_no: register.floor,
      room_no: register.roomNo,
      house_no: register.houseNo,
      village_no: register.villageNo,
      postcode_no: register.zipCode,
      lat: String(anchorMap[0]),
      long: String(anchorMap[1]),
      status: statusButton,
      branch_lang: {
        name: register.branchName,
        building: register.buildingName,
        village: register.villageName,
        alley: register.alley,
        road: register.road,
        subdistrict: register.subDistrict,
        district: register.district,
        province: register.province,
        country: register.country,
        language_code: String(localStorage.getItem("language_form")),
      },
    };
    let dataStatus = await updateBranch(dataAPI);
    if (dataStatus === "OK") {
      fetchBranchData()
        .then(() => {
          notifySuccess({ title: t("toast_successfully_added_information") });
          fetchData();
        })
        .catch((error) => {
          console.error("Error fetching product:", error);
        });
    }
    await new Promise((resolve) => setTimeout(resolve, 2000));
    setIsSubmitting(false);
  };

  return (
    <Box>
      <GridContainerSize>
        <Box p={2}>
          <form onSubmit={handleSubmit(onConfirmForm)}>
            <BoxWrapper>
              <PaperWrapper props={{ growFull: 1 }}>
                <BoxFormTitleAction>
                  <TitleHeaderForm props={{ text: t("branch_info") }} />
                  <StatusForm
                    statusButton={statusButton}
                    setStatusButton={setStatusButton}
                  />
                </BoxFormTitleAction>
                <BoxGapInput>
                  <Box className="boxWrapper-container">
                    <BoxWrapper>
                      <PaperWrapper props={{ growFull: null }}>
                        <TitleForm props={{ text: t("branch_info") }} />
                        <BoxGapInput>
                          <TextfieldComponent
                            title={t("branch_name")}
                            name="branchName"
                            register={register}
                            placeholder={t("branch_name_example")}
                            errors={errors}
                            fullGrid={true}
                          />
                          <TextfieldComponent
                            title={t("branch_code")}
                            name="branchCode"
                            register={register}
                            placeholder={t("branch_code_example")}
                            errors={errors}
                            fullGrid={true}
                          />
                          <TextfieldComponent
                            title={t("branch_revenue_code")}
                            name="branchRevenueCode"
                            register={register}
                            placeholder={t("branch_revenue_code_example")}
                            errors={errors}
                            fullGrid={true}
                          />
                        </BoxGapInput>
                      </PaperWrapper>
                      <PaperWrapper props={{ growFull: 1 }}>
                        <AddressForm
                          props={{
                            register,
                            setValue,
                            errors,
                            reset,
                            address,
                            setAddress,
                            country,
                            setCountry,
                            control,
                            clearErrors,
                          }}
                        />
                      </PaperWrapper>
                    </BoxWrapper>

                    <BoxWrapper>
                      <PaperWrapper props={{ growFull: 0 }}>
                        <AddressFormOptional
                          register={register}
                          setValue={setValue}
                          errors={errors}
                        />
                      </PaperWrapper>
                    </BoxWrapper>
                    {/* <BoxWrapper>
                <PaperWrapper props={{ growFull: 1 }}>
                  <MapAddress props={{ anchorMap, setAnchorMap }} />
                </PaperWrapper>
              </BoxWrapper> */}
                   
                    <ConfirmButton disabled={isSubmitting} />
                  </Box>
                </BoxGapInput>
              </PaperWrapper>
            </BoxWrapper>
          </form>
        </Box>
      </GridContainerSize>
    </Box>
  );
}

export default BranchInfo;
