import React, { useEffect, useState } from "react";
import {
  deleteBranchConfigZone,
  getAllBranchConfigZone,
  storeBranchConfigZone,
  updateBranchConfigZone,
} from "../../../../service/branchConfigAPI/branchConfigZone";
import {
  deleteBranchConfigTable,
  getAllBranchConfigTable,
  storeBranchConfigTable,
  updateBranchConfigTable,
} from "../../../../service/branchConfigAPI/branchConfigTable";
import { uploadImage } from "../../../../service/upload/uploadImage";
import { notifySuccess } from "../../../../components/ui/popup/toastPopup";
import { t } from "i18next";
import { Box, Button, IconButton } from "@mui/material";
import Loading from "../../../../components/ui/loading";
import DropAreaTable from "./dropAreaTable";
import { useSchema } from "../../../../components/hooks/use-schema";
import { useForm } from "react-hook-form";
import { branchZoneSchema } from "../../../../components/validation/branchZoneSchema";
import BranchCreateManageZoneForm from "./branchCreateManageZoneForm";
import Add from "@mui/icons-material/Add";
import { Edit, TableBar } from "@mui/icons-material";
import DialogSelectTable from "./dialogSelectTable";
import DialogTableForm from "./dialogTableForm";
function BranchManageTable({ props }) {
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm(useSchema(branchZoneSchema));
  let { templateId } = props;
  const [zoneAll, setZoneAll] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [zoneSelect, setZoneSelect] = useState(null);
  const [dialogAddTable, setDialogAddTable] = useState(false);
  const [tableZoneBox, setTableZoneBox] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [dialogAddZone, setDialogAddZone] = useState({
    status: false,
    data: null,
    mode: null,
  });
  const [statusButton, setStatusButton] = useState(true);
  const [isLoadingUpdate, setIsLoadingUpdate] = useState(false);
  const [dialogFormTable, setDialogFormTable] = useState({
    status: false,
    data: null,
    mode: null,
  });
  const fetchData = async () => {
    try {
      const promises = [fetchDataZone(), fetchDataTableInZone()];
      await Promise.all(promises);
      setIsLoading(false);
      setIsLoadingUpdate(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchDataZone = async () => {
    let dataAPI = await getAllBranchConfigZone(templateId);
    let dataBox = [];
    if (dataAPI.length > 0) {
      dataAPI.forEach((item) => {
        dataBox.push({ id: item.id, name: item.name });
      });
      if (!zoneSelect) {
        setZoneSelect(dataBox[0].id);
        setValue("zoneSelectName", dataBox[0].name);
      }
    }
    setZoneAll(dataBox);
  };
  const fetchDataTableInZone = async () => {
    let dataAPI = await getAllBranchConfigTable(templateId);
    setTableZoneBox(dataAPI);
  };
  const onZoneSelect = async (itemZone) => {
    setZoneSelect(itemZone.id);
    setValue("zoneSelectName", itemZone.name);
  };
  const onClickAddTableToZone = async (item) => {
    setDialogAddTable(false);
    setDialogFormTable({ status: true, data: item, mode: "create" });
  };

  const onClickCopyTableToZone = async (item) => {
    let number = "";
    while (number.length < 4) {
      number += Math.floor(Math.random() * 9 + 1); // Generates digits from 1 to 9
      number = number.substring(0, 4); // Ensures no more than 18 digits
    }
    let dataBox = [...tableZoneBox];
    let itemNews = {
      id: number,
      name: "",
      template_zone_id: item.template_zone_id,
      image: item.image,
      amount: item.amount,
      position_x: item.position_x + 100,
      position_y: item.position_y,
      rotate: item.rotate,
      status: item.status,
    };
    dataBox.push(itemNews);
    setTableZoneBox(dataBox);
    setDialogAddTable(false);
  };
  const onRemoveTable = async (item, index) => {
    let dataAPI = {
      id: item.id,
      template_id: String(templateId),
    };
    let statusAPI = await deleteBranchConfigTable(dataAPI);
    if (statusAPI === "OK") {
      notifySuccess({
        title: t("toast_successfully_updated_information"),
      });
      setDialogFormTable({ status: false, data: null, mode: null });
      fetchData();
    }
  };
  const onEditTable = async (item, index, e) => {
    setDialogFormTable({ status: true, data: item, mode: "edit" });
  };
  const onCreateTable = async (item) => {
    setIsLoadingUpdate(true);
    let newImage = "";
    if (item.image.includes("/") === true) {
      await fetch(item.image)
        .then((response) => response.blob())
        .then(async (blob) => {
          newImage = await uploadImage(blob);
        });
      let dataAPI = {
        template_id: String(templateId),
        template_zone_id: String(zoneSelect),
        name: item.name,
        image: newImage,
        amount: Number(item.amount),
        position_x: item.position_x,
        position_y: item.position_y,
        rotate: item.rotate,
        status: true,
      };
      let statusAPI = await storeBranchConfigTable(dataAPI);
      if (statusAPI === "OK") {
        notifySuccess({
          title: t("toast_successfully_updated_information"),
        });
        fetchData();
      }
    }
  };
  const onUpdateTable = async (item, statusShow) => {
    let dataAPI = {
      id: String(item.id),
      template_id: String(templateId),
      template_zone_id: String(zoneSelect),
      name: item.name,
      image: item.image,
      amount: Number(item.amount),
      position_x: item.position_x,
      position_y: item.position_y,
      rotate: item.rotate,
      status: true,
    };
    if (!statusShow) {
      await updateBranchConfigTable(dataAPI);
    } else {
      let statusAPI = await updateBranchConfigTable(dataAPI);
      if (statusAPI === "OK") {
        notifySuccess({
          title: t("toast_successfully_updated_information"),
        });
      }
    }
  };

  const onCreateZone = async (register) => {
    setIsSubmitting(true);
    let dataAPI = {
      template_id: String(templateId),
      name: register.zoneName,
      status: statusButton,
    };
    let statusAPI = await storeBranchConfigZone(dataAPI);
    if (statusAPI === "OK") {
      notifySuccess({
        title: t("toast_successfully_added_information"),
      });
      setDialogAddZone({
        status: false,
        data: null,
        mode: null,
      });
      setIsSubmitting(false);
      fetchData();
    }
  };
  const onEditZone = async (register) => {
    setIsSubmitting(true);
    let dataAPI = {
      template_id: String(templateId),
      id: dialogAddZone.data.id,
      name: register.zoneName,
      status: statusButton,
    };
    let statusAPI = await updateBranchConfigZone(dataAPI);
    if (statusAPI === "OK") {
      notifySuccess({
        title: t("toast_successfully_edit_information"),
      });
      setDialogAddZone({
        status: false,
        data: null,
        mode: null,
      });
      setIsSubmitting(false);
      fetchData();
    }
  };
  const onCloseZoneForm = async () => {
    setDialogAddZone({
      status: false,
      data: null,
      mode: null,
    });
    setValue("zoneName", "");
  };
  const onRemoveZone = async (item) => {
    setIsSubmitting(true);
    let dataAPI = {
      id: item.id,
      template_id: String(templateId),
    };
    let statusAPI = await deleteBranchConfigZone(dataAPI);
    if (statusAPI === "OK") {
      notifySuccess({
        title: t("toast_successfully_edit_information"),
      });
      setDialogAddZone({
        status: false,
        data: null,
        mode: null,
      });
      setIsSubmitting(false);
      fetchData();
    }
  };
  const onOpenDialogEditZone = async (item) => {
    setValue("zoneName", item.name);
    setDialogAddZone({
      status: true,
      data: item,
      mode: "edit",
    });
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Box>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <Box
            sx={{
              margin: "auto",
              width: "100%",
              display: "flex",
              gap: 2,
            }}
          >
            {/* {images.map((src, index) => (
                <DraggableResizableImage
                  key={index}
                  id={index}
                  imageSrc={src}
                  onSelect={handleSelect}
                  isSelected={selectedImage === index}
                />
              ))} */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
                width: "100%",
              }}
            >
              {zoneAll.map((item) => (
                <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                  <Button
                    variant={zoneSelect === item.id ? "contained" : "outlined"}
                    color="primary"
                    sx={{ borderRadius: 2 }}
                    onClick={() => onZoneSelect(item)}
                    startIcon={<TableBar />}
                    fullWidth
                  >
                    {item.name}
                  </Button>
                  <IconButton onClick={() => onOpenDialogEditZone(item)}>
                    <Edit color="primary" />
                  </IconButton>
                </Box>
              ))}

              <Box
                sx={{
                  py: 2,
                  px: 4,
                  boxSizing: "border-box",
                  display: "grid",
                  placeItems: "center",
                  border: "2px dashed #cccccc",
                  borderRadius: 2,
                  ":hover": {
                    cursor: "pointer",
                    opacity: 0.8,
                  },
                }}
                onClick={() =>
                  setDialogAddZone({
                    status: true,
                    data: null,
                    mode: "create",
                  })
                }
              >
                <Add sx={{ color: "#cccccc" }} />
              </Box>
            </Box>
            <Box>
              <DropAreaTable
                props={{
                  zoneSelect,
                  tableZoneBox,
                  setTableZoneBox,
                  setDialogAddTable,
                  onEditTable,
                  onClickCopyTableToZone,
                  onUpdateTable,
                  onEditZone,
                  isLoadingUpdate,
                }}
              />

              {dialogAddTable ? (
                <DialogSelectTable
                  props={{
                    dialogAddTable,
                    setDialogAddTable,
                    onClickAddTableToZone,
                  }}
                />
              ) : null}
              {dialogFormTable.status ? (
                <DialogTableForm
                  props={{
                    dialogFormTable,
                    zoneSelect,
                    onRemoveTable,
                    setTableZoneBox,
                    setDialogFormTable,
                    tableZoneBox,
                    onCreateTable,
                  }}
                />
              ) : null}
              {dialogAddZone ? (
                <BranchCreateManageZoneForm
                  props={{
                    setDialogAddZone,
                    onRemoveZone,
                    dialogAddZone,
                    handleSubmit,
                    onCreateZone,
                    onEditZone,
                    register,
                    errors,
                    statusButton,
                    setStatusButton,
                    isSubmitting,
                    onCloseZoneForm,
                  }}
                />
              ) : null}
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
}

export default BranchManageTable;
