import axios from "axios";
import {
  AccessTokenAPI,
  BusinessId,
  CompanyId,
  ServiceId,
} from "../../getLocalStorage";

export const checkSelfPickup = async () => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_API_URL}/api/setting/selfpickup/checkSelfPickup?company_uid=${CompanyId}&business_uid=${BusinessId}&service_code=${ServiceId}`,
    headers: {
      Authorization: `Bearer ${AccessTokenAPI}`,
    },
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      console.log(error);
    });
};
