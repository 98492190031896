import React, { useState, useEffect, useContext } from "react";
import { Box, Dialog, DialogContent } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { getAllBranch } from "../../service/branchAPI";
import {
  addPosUserToTemplate,
  getAllPosUserTemplate,
  storePosUser,
} from "../../service/posUserAPI";
import { notifySuccess } from "../../components/ui/popup/toastPopup";
import { uploadImage } from "../../service/upload/uploadImage";
import ConfirmButton from "../../components/ui/button/confirmButton";
import ImageCrop from "../../components/ui/upload/imageCrop";
import TitleForm from "../../components/ui/formComponent/titleForm";
import BreadcrumbsTitle from "../../components/ui/breadcrumbsTitle";
import SettingTypeBranch from "../../components/ui/addTypeTemplate/settingTypeBranch";
import StatusForm from "../../components/ui/formComponent/statusForm";
import { useNavigate } from "react-router-dom";
import { WarningPopup } from "../../components/ui/popup/successPopup";
import TextfieldComponent from "../../components/ui/textfield/textfieldComponent";
import { useSchema } from "../../components/hooks/use-schema";
import { staffPosSchema } from "../../components/validation/staffPosSchema";
import { BoxWrapper } from "../../components/ui/box/boxWrapper";
import PaperWrapper from "../../components/ui/paper/paperWrapper";
import StaffPOSPermission from "./staffPOSPermission";
import { GridContainerSize } from "../../components/utils/gridContainerSize";
import { BoxGapInput } from "../../components/ui/box/boxGapInput";
import { MyContext } from "../../context/MyContext";
import { BoxFormTitleAction } from "../../components/ui/box/boxFormTitleAction";
import TitleHeaderForm from "../../components/ui/formComponent/titleHeaderForm";
function StaffPOSCreate() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm(useSchema(staffPosSchema));
  const { t } = useTranslation();
  const [statusButton, setStatusButton] = useState(true);
  const [image, setImage] = useState(null);
  const [permissionAll, setPermissionAll] = useState([]);
  const [checkPermissionAll, setCheckPermissionAll] = useState(false);
  const [idTemplate, setIdTemplate] = useState(null);
  const [showTemplate, setShowTemplate] = useState(false);
  const [allDataTemplate, setAllDataTemplate] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { fetchStaff } = useContext(MyContext);

  const navigate = useNavigate();
  const fetchAllBranch = async (id) => {
    let dataAPI = await getAllBranch();
    dataAPI = dataAPI.filter((item) => item.branch_lang !== null);
    await fetchSettingSelectTemplate(dataAPI, id);
  };
  const fetchSettingSelectTemplate = async (dataBranch, id) => {
    let dataAPI = await getAllPosUserTemplate(id);
    dataAPI = dataAPI[0].template_pos_users;
    let dataBox = [];
    dataBranch.forEach((item) => {
      let dataNews = dataAPI.filter(
        (itemTemplate) => itemTemplate.template_id === item.template_id
      );
      if (dataNews.length > 0) {
        dataBox.push({
          branch: item,
          data: dataNews[0],
          select: false,
        });
      } else {
        dataBox.push({
          branch: item,
          data: null,
          select: false,
        });
      }
    });
    await setAllDataTemplate(dataBox);
  };
  const onAddStaffPOSToTemplate = async () => {
    let branchArray = allDataTemplate.filter((item) => item.select === true);
    let dataSelect = [];
    branchArray.forEach((item) => {
      dataSelect.push(item.branch.id);
    });
    let dataAPI = {
      pos_user_id: idTemplate,
      branches: dataSelect,
    };
    let dataStatus = await addPosUserToTemplate(dataAPI);
    if (dataStatus === "OK") {
      notifySuccess({
        title: t("toast_successfully_added_information"),
      });
      navigate("/posUser");
    }
  };
  const onConfirmForm = async (register) => {
    setIsSubmitting(true);
    if (localStorage.getItem("uploadImage")) {
      WarningPopup({ title: t("notification_please_upload_image") });
    } else {
      let newImage = "";
      if (image) {
        newImage = await uploadImage(image);
      }
      let dataAPI = {
        position: register.positionUser,
        pin: register.pin,
        profile_img: newImage,
        nat_id: register.idCardUser,
        tel: register.tel,
        email: register.email,
        status: statusButton,
        pos_user_lang: {
          firstname: register.firstName,
          lastname: register.lastName,
          language_code: "ALL",
        },
        pos_user_permissions: permissionAll,
      };
      let statusAPI = await storePosUser(dataAPI);
      if (statusAPI.message === "OK") {
        fetchStaff()
          .then(() => {
            notifySuccess({
              title: t("toast_successfully_added_information"),
            });
            if (statusButton) {
              setIdTemplate(statusAPI.data.id);
              fetchAllBranch(statusAPI.data.id);
              setShowTemplate(true);
            } else {
              navigate("/posUser");
            }
          })
          .catch((error) => {
            console.error("Error fetching product:", error);
          });
      }
    }
    await new Promise((resolve) => setTimeout(resolve, 2000));
    setIsSubmitting(false);
  };
  const fetchDataPermission = async () => {
    setPermissionAll([
      {
        name: "home_page",
        permission: "home_page",
        value: true,
      },
      {
        name: "manage_receipt_page",
        permission: "manage_receipt_page",
        value: true,
      },
      {
        name: "manage_money_in_drawer",
        permission: "manage_money_in_drawer",
        value: false,
      },
      {
        name: "daily_balance_summary_page",
        permission: "daily_balance_summary_page",
        value: false,
      },
      {
        name: "pin_close",
        permission: "pin_close",
        value: false,
      },
      {
        name: "void_bill",
        permission: "void_bill",
        value: false,
      },
      {
        name: "drawer_summary",
        permission: "drawer_summary",
        value: false,
      },
    ]);
  };
  const convertNamePermission = (name) => {
    switch (name) {
      case "home_page":
        return t("staff_pos_home_page");
      case "manage_receipt_page":
        return t("staff_pos_manage_receipt_page");
      case "manage_money_in_drawer":
        return t("staff_pos_manage_money_in_drawer");
      case "daily_balance_summary_page":
        return t("staff_pos_daily_balance_summary_page");
      case "pin_close":
        return t("staff_pos_pin_close");
      case "void_bill":
        return t("staff_pos_receipt_cancel");
      case "setting":
        return t("staff_pos_setting");
      case "drawer_summary":
        return t("staff_pos_drawer_summary");
      default:
        t("global_data_not_found");
    }
  };
  const onChangePermission = (index) => (e) => {
    let checked = e.target.checked;
    let dataNews = [...permissionAll];
    dataNews[index].value = checked;
    setPermissionAll(dataNews);
    if (
      permissionAll.every((itemPermission) => itemPermission.value === true)
    ) {
      setCheckPermissionAll(true);
    } else {
      setCheckPermissionAll(false);
    }
  };
  const onCheckAllPermissions = (e) => {
    let checked = e.target.checked;
    permissionAll.forEach((itemPermission) => {
      itemPermission.value = checked;
    });
    setCheckPermissionAll(checked);
  };
  useEffect(() => {
    localStorage.removeItem("uploadImage");
    fetchDataPermission();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div align="left">
      <BreadcrumbsTitle
        props={{
          title: t("staff_pos_create"),
          dataLink: [
            { text: t("staff_pos"), link: "/posUser" },
            { text: t("staff_pos_create"), link: null },
          ],
        }}
      />
      <GridContainerSize>
        <Box p={2}>
          <form onSubmit={handleSubmit(onConfirmForm)}>
            <BoxWrapper>
              <PaperWrapper props={{ growFull: 1 }}>
                <BoxFormTitleAction>
                  <TitleHeaderForm props={{ text: t("staff_pos") }} />
                  <StatusForm
                    statusButton={statusButton}
                    setStatusButton={setStatusButton}
                  />
                </BoxFormTitleAction>
                <BoxGapInput>
                  <Box className="boxWrapper-container">
                    <BoxWrapper>
                      <PaperWrapper props={{ growFull: null }}>
                        <TitleForm props={{ text: t("staff_pos_image") }} />
                        <Box align="center">
                          <ImageCrop
                            props={{
                              setImage,
                              image,
                              heightCrop: 256,
                              widthCrop: 256,
                              label: "staffPOS",
                            }}
                          />
                        </Box>
                      </PaperWrapper>
                      <PaperWrapper props={{ growFull: 1 }}>
                        <TitleForm props={{ text: t("staff_pos_info") }} />
                        <BoxGapInput p={2}>
                          <BoxWrapper>
                            <TextfieldComponent
                              title={t("global_first_name")}
                              name="firstName"
                              register={register}
                              placeholder={t("global_first_name_example")}
                              errors={errors}
                            />
                            <TextfieldComponent
                              title={t("global_last_name")}
                              name="lastName"
                              register={register}
                              placeholder={t("global_last_name_example")}
                              errors={errors}
                            />
                          </BoxWrapper>

                          <BoxWrapper>
                            <TextfieldComponent
                              title={t("tel")}
                              name="tel"
                              register={register}
                              placeholder={t("global_tel_example")}
                              errors={errors}
                            />
                            <TextfieldComponent
                              title={t("global_email")}
                              name="email"
                              register={register}
                              placeholder={t("global_email_example")}
                              errors={errors}
                            />
                          </BoxWrapper>
                          <BoxWrapper>
                            <TextfieldComponent
                              title={t("staff_pos_position")}
                              name="positionUser"
                              register={register}
                              placeholder={t("staff_pos_position_example")}
                              errors={errors}
                            />
                            <TextfieldComponent
                              title={t("global_national_id")}
                              name="idCardUser"
                              register={register}
                              placeholder={t("global_national_id_example")}
                              errors={errors}
                            />
                          </BoxWrapper>
                          <TextfieldComponent
                            title={t("global_pin")}
                            name="pin"
                            register={register}
                            placeholder={t("global_pin_example")}
                            errors={errors}
                          />
                        </BoxGapInput>
                      </PaperWrapper>
                    </BoxWrapper>
                    <StaffPOSPermission
                      props={{
                        checkPermissionAll,
                        onCheckAllPermissions,
                        permissionAll,
                        convertNamePermission,
                        onChangePermission,
                      }}
                    />
                    <ConfirmButton disabled={isSubmitting} />
                  </Box>
                </BoxGapInput>
              </PaperWrapper>
            </BoxWrapper>
          </form>
        </Box>
      </GridContainerSize>
      <Dialog open={showTemplate} maxWidth="md" fullWidth>
        <DialogContent>
          <SettingTypeBranch
            title={t("staff_pos_branch")}
            cancelPath={"/posUser"}
            onAddTypeToTemplate={onAddStaffPOSToTemplate}
            allDataTemplate={allDataTemplate}
            setAllDataTemplate={setAllDataTemplate}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default StaffPOSCreate;
