import React, { useContext, useEffect, useState } from "react";
import { Box } from "@mui/material";
import TitleForm from "../../components/ui/formComponent/titleForm";
import AddressForm from "../../components/ui/addressForm/addressForm";
import { useForm } from "react-hook-form";
import { postBranch } from "../../service/branchAPI";
import { notifySuccess } from "../../components/ui/popup/toastPopup";
import BreadcrumbsTitle from "../../components/ui/breadcrumbsTitle";
import ConfirmButton from "../../components/ui/button/confirmButton";
import StatusForm from "../../components/ui/formComponent/statusForm";
import { useNavigate } from "react-router-dom";
import AddressFormOptional from "../../components/ui/addressForm/addressFormOptional";
import { useSchema } from "../../components/hooks/use-schema";
import { branchSchema } from "../../components/validation/branchSchema";
import TextfieldComponent from "../../components/ui/textfield/textfieldComponent";
import { t } from "i18next";
import { BoxWrapper } from "../../components/ui/box/boxWrapper";
import PaperWrapper from "../../components/ui/paper/paperWrapper";
import { GridContainerSize } from "../../components/utils/gridContainerSize";
import { BoxGapInput } from "../../components/ui/box/boxGapInput";
import { MyContext } from "../../context/MyContext";
import { BoxFormTitleAction } from "../../components/ui/box/boxFormTitleAction";
import TitleHeaderForm from "../../components/ui/formComponent/titleHeaderForm";

function BranchCreate() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    control,
    clearErrors,
  } = useForm(useSchema(branchSchema));
  const { fetchBranchData } = useContext(MyContext);
  const [anchorMap, setAnchorMap] = useState([
    18.788852690765257, 98.98393206985963,
  ]);
  const [statusButton, setStatusButton] = useState(true);
  const [address, setAddress] = useState({
    province: "",
    district: "",
    subDistrict: "",
    zipCode: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [country, setCountry] = useState(null);
  const navigate = useNavigate();
  const onConfirmForm = async (register) => {
    setIsSubmitting(true);
    let dataAPI = {
      b_branch_code: register.branchCode,
      r_branch_code: register.branchRevenueCode,
      floor_no: register.floor,
      room_no: register.roomNo,
      house_no: register.houseNo,
      village_no: register.villageNo,
      postcode_no: register.zipCode,
      lat: String(anchorMap[0]),
      long: String(anchorMap[1]),
      status: statusButton,
      branch_lang: {
        name: register.branchName,
        building: register.buildingName,
        village: register.villageName,
        alley: register.alley,
        road: register.road,
        subdistrict: register.subDistrict,
        district: register.district,
        province: register.province,
        country: register.country,
        language_code: "ALL",
      },
    };
    let statusAPI = await postBranch(dataAPI);
    if (statusAPI === "OK") {
      fetchBranchData()
        .then(() => {
          notifySuccess({
            title: t("toast_successfully_added_information"),
          });
          navigate("/branch");
        })
        .catch((error) => {
          console.error("Error fetching product:", error);
        });
    }
    await new Promise((resolve) => setTimeout(resolve, 2000));
    setIsSubmitting(false);
  };
  useEffect(() => {
    setAnchorMap([18.788852690765257, 98.98393206985963]);
  }, []);
  return (
    <div align="left">
      <BreadcrumbsTitle
        props={{
          title: t("branch_create"),
          dataLink: [
            { text: t("branch"), link: "/branch" },
            { text: t("branch_create"), link: null },
          ],
        }}
      />
      <GridContainerSize>
        <Box p={2}>
          <form onSubmit={handleSubmit(onConfirmForm)}>
            <BoxWrapper>
              <PaperWrapper props={{ growFull: 1 }}>
                <BoxFormTitleAction>
                  <TitleHeaderForm props={{ text: t("branch") }} />
                  <StatusForm
                    statusButton={statusButton}
                    setStatusButton={setStatusButton}
                  />
                </BoxFormTitleAction>
                <BoxGapInput>
                  <Box className="boxWrapper-container">
                    <BoxWrapper>
                      <PaperWrapper props={{ growFull: null }}>
                        <TitleForm props={{ text: t("branch_info") }} />
                        <BoxGapInput>
                          <TextfieldComponent
                            title={t("branch_name")}
                            name="branchName"
                            register={register}
                            placeholder={t("branch_name_example")}
                            errors={errors}
                            fullGrid={true}
                          />
                          <TextfieldComponent
                            title={t("branch_code")}
                            name="branchCode"
                            register={register}
                            placeholder={t("branch_code_example")}
                            errors={errors}
                            fullGrid={true}
                          />
                          <TextfieldComponent
                            title={t("branch_revenue_code")}
                            name="branchRevenueCode"
                            register={register}
                            placeholder={t("branch_revenue_code_example")}
                            errors={errors}
                            fullGrid={true}
                          />
                        </BoxGapInput>
                      </PaperWrapper>
                      <PaperWrapper props={{ growFull: 1 }}>
                        <AddressForm
                          props={{
                            register,
                            setValue,
                            errors,
                            reset,
                            address,
                            setAddress,
                            country,
                            setCountry,
                            control,
                            clearErrors,
                          }}
                        />
                      </PaperWrapper>
                    </BoxWrapper>
                    <BoxWrapper>
                      <PaperWrapper props={{ growFull: 0 }}>
                        <AddressFormOptional
                          register={register}
                          setValue={setValue}
                          errors={errors}
                        />
                      </PaperWrapper>
                    </BoxWrapper>

                    <ConfirmButton disabled={isSubmitting} />
                  </Box>
                </BoxGapInput>
              </PaperWrapper>
            </BoxWrapper>
          </form>
        </Box>
      </GridContainerSize>
    </div>
  );
}

export default BranchCreate;
