import React from "react";
import TitleForm from "../../../components/ui/formComponent/titleForm";
import ConfirmButton from "../../../components/ui/button/confirmButton";
import StatusForm from "../../../components/ui/formComponent/statusForm";
import TextfieldComponent from "../../../components/ui/textfield/textfieldComponent";
import SelectComponent from "../../../components/ui/textfield/selectComponent";
import EmptyDataDirect from "../../../components/ui/emptyDataDirect";
import { BoxWrapper } from "../../../components/ui/box/boxWrapper";
import PaperWrapper from "../../../components/ui/paper/paperWrapper";
import { BoxGapInput } from "../../../components/ui/box/boxGapInput";
import { BoxFormTitleAction } from "../../../components/ui/box/boxFormTitleAction";
import i18next, { t } from "i18next";
import TitleHeaderForm from "../../../components/ui/formComponent/titleHeaderForm";

function ProductOptionsCategoryForm({ props }) {
  let {
    register,
    statusButton,
    setStatusButton,
    errors,
    watch,
    setValue,
    unitCounterAll,
    clearErrors,
    control,
    productOptionsSelectType,
    isSubmitting,
  } = props;
  return (
    <>
      <BoxWrapper>
        <PaperWrapper props={{ growFull: 1 }}>
          <BoxFormTitleAction>
            <TitleHeaderForm props={{ text: t("unit_info") }} />
            <StatusForm
              statusButton={statusButton}
              setStatusButton={setStatusButton}
            />
          </BoxFormTitleAction>
          <BoxGapInput>
            <BoxWrapper>
              <PaperWrapper props={{ growFull: 1 }}>
                <TitleForm
                  props={{
                    text: t("product_option_category_info"),
                  }}
                />
                <BoxGapInput>
                  <TextfieldComponent
                    title={t("product_option_category_name")}
                    name="productCategory"
                    register={register}
                    placeholder={t("product_option_category_example")}
                    errors={errors}
                    fullGrid={true}
                  />
                  <SelectComponent
                    title={t("unit")}
                    watch={watch}
                    register={register}
                    setValue={setValue}
                    name="unit"
                    errors={errors}
                    dataEmpty={t("unit_select")}
                    optionsData={unitCounterAll}
                    disabled={unitCounterAll.length === 0}
                    dataAlert={
                      <>
                        {unitCounterAll.length === 0 ? (
                          <EmptyDataDirect
                            props={{ url: "/unit", text: t("unit") }}
                          />
                        ) : null}
                      </>
                    }
                    clearErrors={clearErrors}
                    control={control}
                  />

                  <SelectComponent
                    title={t("product_option_category_option")}
                    watch={watch}
                    register={register}
                    setValue={setValue}
                    name="selectType"
                    errors={errors}
                    dataEmpty={t("product_option_category_option_select")}
                    optionsData={productOptionsSelectType[i18next.language]}
                    clearErrors={clearErrors}
                    control={control}
                  />
                </BoxGapInput>
              </PaperWrapper>
            </BoxWrapper>
            <ConfirmButton disabled={isSubmitting} />
          </BoxGapInput>
        </PaperWrapper>
      </BoxWrapper>
    </>
  );
}

export default ProductOptionsCategoryForm;
