import {
  Box,
  FormControlLabel,
  Switch,
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Divider,
  FormHelperText,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  configGetAllPaymentType,
  // configSortPaymentType,
  configUpdatePaymentType,
} from "../../../../service/branchConfigAPI/branchConfigPaymentType";
import Loading from "../../../../components/ui/loading";
import { getPaymentType } from "../../../../service/paymentTypeAPI";
import { notifySuccess } from "../../../../components/ui/popup/toastPopup";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useSchema } from "../../../../components/hooks/use-schema";
import { branchPaymentSchema } from "../../../../components/validation/branch/branchPayment";
import TextfieldComponent from "../../../../components/ui/textfield/textfieldComponent";
import PaperWrapper from "../../../../components/ui/paper/paperWrapper";
import TitleForm from "../../../../components/ui/formComponent/titleForm";
import Edit from "@mui/icons-material/Edit";
import {
  configGetAllPaymentChannel,
  // configSortPaymentChannel,
  configUpdatePaymentChannel,
} from "../../../../service/branchConfigAPI/branchConfigPaymentChannel";
import { Add } from "@mui/icons-material";
import ImageUploadPaymentDialog from "./imageUploadPaymentDialog";
import EmptyDataDirect from "../../../../components/ui/emptyDataDirect";

function BranchPayment(props) {
  let { templateId } = props;
  const {
    register,
    formState: { errors },
  } = useForm(useSchema(branchPaymentSchema));
  const { t } = useTranslation();
  const [dataPayment, setDataPayment] = useState(null);
  // const [dataSelectPayment, setDataSelectPayment] = useState(null);
  const [dataSelectIndexPayment, setDataSelectIndexPayment] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [dialogPaymentChannel, setDialogPaymentChannel] = useState({
    status: false,
    data: null,
  });
  // const [checkAllPaymentChannel, setCheckAllPaymentChannel] = useState(false);

  // const onConfirmStatusChangePaymentTypeChannel = async (e) => {
  //   let checked = e.target.checked;
  //   Swal.fire({
  //     title: t("notification_confirm_change_status"),
  //     text: t(
  //       "notification_changing_the_status_will_affect_the_branch_settings"
  //     ),
  //     icon: "warning",
  //     showCancelButton: true,
  //   }).then(async (result) => {
  //     if (result.isConfirmed) {
  //       setCheckAllPaymentChannel(checked);
  //       setTitleProgress(t("notification_loading_update_sale_product"));
  //       let countRound = 0;
  //       let dataNews = [...dataPayment];
  //       dataNews.forEach(async (itemPaymentType, indexProduct) => {
  //         itemPaymentType.payment_channel.forEach(
  //           (itemPaymentChannel, index) => {
  //             setTimeout(async () => {
  //               countRound++;
  //               if (
  //                 itemPaymentType.payment_type.type === "Cash" ||
  //                 itemPaymentType.payment_type.type === "Voucher"
  //               ) {
  //                 itemPaymentChannel.status = true;
  //               } else {
  //                 itemPaymentChannel.status = checked;
  //               }

  //               let dataAPI = {
  //                 template_id: String(templateId),
  //                 payment_channel_id: String(
  //                   itemPaymentChannel.payment_channel_id
  //                 ),
  //                 priority: itemPaymentType.priority,
  //                 status:
  //                   itemPaymentType.payment_type.type === "Cash" ||
  //                   itemPaymentType.payment_type.type === "Voucher"
  //                     ? true
  //                     : checked,
  //               };

  //               let dataStatus = await configUpdatePaymentChannel(dataAPI);
  //               if (dataStatus === "OK") {
  //                 setProgress((prevProgress) =>
  //                   prevProgress >= 100
  //                     ? 0
  //                     : prevProgress + 100 / (dataNews.length * itemPaymentType.payment_channel)
  //                 );
  //               }

  //               if (countRound === dataNews.length) {
  //                 setProgress(0);
  //                 setTitleProgress("");
  //                 if (checked) {
  //                   notifySuccess({ title: t("global_active") });
  //                 } else {
  //                   notifyDefault({ title: t("global_in_active") });
  //                 }
  //               }
  //             }, 300 * indexProduct);
  //           }
  //         );
  //       });
  //       setDataPayment(dataNews);
  //     }
  //   });
  // };

  const fetchData = async () => {
    // payment type
    await fetchPaymentType();
    // Loading
    await setIsLoading(false);
  };
  // PaymentType
  const fetchPaymentType = async () => {
    let dataPaymentAPI = await configGetAllPaymentType(templateId);
    let dataPaymentChannelAPI = await configGetAllPaymentChannel(templateId);
    dataPaymentAPI.forEach(async (item) => {
      item.draggable = false;
      item.data = await getPaymentType(item.payment_type_id);
    });
    dataPaymentAPI
      .sort((a, b) => a.priority - b.priority)
      .forEach((itemPaymentType) => {
        let dataFind = dataPaymentChannelAPI.filter(
          (item) => item.payment_type_id === itemPaymentType.payment_type_id
        );
        if (dataFind.length > 0) {
          itemPaymentType.payment_channel = dataFind;
        } else {
          itemPaymentType.payment_channel = [];
        }
      });
    if (dataPaymentAPI.length > 0 && !dataSelectIndexPayment) {
      setDataSelectIndexPayment(0);
    }
    // await setCheckAllPayment(
    //   dataPaymentAPI.every((item) => item.status === true)
    // );
    await setDataPayment(dataPaymentAPI);
  };
  const updateConfigPaymentType = async (item, e) => {
    let dataNews = [...dataPayment];
    let dataFind = dataNews.find((itemFind) => itemFind.id === item.id);
    dataFind.status = e.target.checked;
    setDataPayment(dataNews.sort((a, b) => a.priority - b.priority));

    let dataAPI = {
      template_id: String(templateId),
      payment_type_id: String(item.payment_type_id),
      priority: item.priority ? item.priority : 1,
      status: e.target.checked,
    };
    let dataStatus = await configUpdatePaymentType(dataAPI);
    if (dataStatus === "OK") {
      notifySuccess({
        title: t("notification_successfully_edited_information"),
      });
    }
  };

  const onConfirmUpdatePaymentChannel = async () => {
    let dataAPI = {
      template_id: templateId,
      payment_channel_id: dialogPaymentChannel.data.payment_channel_id,
      priority: dialogPaymentChannel.data.priority,
      image: dialogPaymentChannel.data.image,
      status: dialogPaymentChannel.data.status,
    };
    let statusAPI = await configUpdatePaymentChannel(dataAPI);
    if (statusAPI === "OK") {
      notifySuccess({
        title: t("notification_successfully_edited_information"),
      });
      setDialogPaymentChannel({
        status: false,
        data: null,
      });
    }
  };
  const onClosePaymentChannel = () => {
    setDialogPaymentChannel({
      status: false,
      data: null,
    });
  };
  const onChangeSelectPaymentChannel = async (itemPayment, index, event) => {
    let dataNews = [...dataPayment];
    dataNews[dataSelectIndexPayment].payment_channel[index].status =
      event.target.checked;
    setDataPayment(dataNews);
    let dataAPI = {
      template_id: templateId,
      payment_channel_id: itemPayment.payment_channel_id,
      priority: itemPayment.priority,
      status: event.target.checked,
    };
    let statusAPI = await configUpdatePaymentChannel(dataAPI);
    if (statusAPI === "OK") {
      notifySuccess({
        title: t("notification_successfully_edited_information"),
      });
      setDialogPaymentChannel({
        status: false,
        data: null,
      });
    }
  };
  const [dialogCropImage, setDialogCropImage] = useState({
    status: false,
    data: null,
  });
  // Start payment sequence
  // let todoItemMainDrag = useRef();
  // let todoItemMainDragOver = useRef();
  // const onDragStartPaymentType = async (e, index) => {
  //   todoItemMainDrag.current = index;
  // };
  // const onEnterStartPaymentType = async (e, index) => {
  //   todoItemMainDragOver.current = index;
  //   let cpArr = [...dataPayment];
  //   cpArr.forEach((item) => (item.draggable = false));
  //   cpArr[index].draggable = true;
  //   setDataPayment(cpArr);
  // };
  // const onDragEndStartPaymentType = async (e, index) => {
  //   let arr1 = [...dataPayment];
  //   let todo_item_main = arr1[todoItemMainDrag.current];
  //   arr1.splice(todoItemMainDrag.current, 1);
  //   arr1.splice(todoItemMainDragOver.current, 0, todo_item_main);

  //   todoItemMainDrag.current = null;
  //   todoItemMainDragOver.current = null;

  //   arr1.forEach((item) => (item.draggable = false));
  //   setDataPayment(arr1);
  //   let boxSortMainCategory = [];
  //   arr1.forEach(async (itemCate, index) => {
  //     boxSortMainCategory.push(itemCate.payment_type_id);
  //   });
  //   let uniqueArr = Array.from(new Set(boxSortMainCategory));

  //   let dataAPI = {
  //     template_id: String(templateId),
  //     payment_types: uniqueArr,
  //   };
  //   let dataSortStatus = await configSortPaymentType(dataAPI);
  //   if (dataSortStatus.message === "OK") {
  //     notifySuccess({ title: t("notification_successfully_sequence") });
  //     fetchData();
  //   }
  // };
  // End payment sequence

  // Start payment channel sequence
  // let todoItemChannel = useRef();
  // let todoItemChannelOver = useRef();
  // const onDragStartPaymentTypeChannel = async (e, index) => {
  //   todoItemChannel.current = index;
  // };
  // const onEnterStartPaymentTypeChannel = async (e, index) => {
  //   todoItemChannelOver.current = index;
  //   let cpArr = [...dataPayment];
  //   cpArr[dataSelectIndexPayment].payment_channel.forEach(
  //     (item) => (item.draggable = false)
  //   );
  //   cpArr[dataSelectIndexPayment].payment_channel[index].draggable = true;
  //   setDataPayment(cpArr);
  // };
  // const onDragEndStartPaymentTypeChannel = async (e, index) => {
  //   let arr1 = [...dataPayment];
  //   let todo_item_main =
  //     arr1[dataSelectIndexPayment].payment_channel[todoItemChannel.current];
  //   arr1[dataSelectIndexPayment].payment_channel.splice(
  //     todoItemChannel.current,
  //     1
  //   );
  //   arr1[dataSelectIndexPayment].payment_channel.splice(
  //     todoItemChannelOver.current,
  //     0,
  //     todo_item_main
  //   );

  //   todoItemChannel.current = null;
  //   todoItemChannelOver.current = null;

  //   arr1[dataSelectIndexPayment].payment_channel.forEach(
  //     (item) => (item.draggable = false)
  //   );

  //   //Collect sort all payment channel to box
  //   let boxSortPaymentChannel = [];
  //   arr1[dataSelectIndexPayment].payment_channel.forEach(
  //     async (itemCate, index) => {
  //       boxSortPaymentChannel.push(itemCate.payment_channel_id);
  //     }
  //   );
  //   setDataPayment(arr1);

  //   let uniqueArr = Array.from(new Set(boxSortPaymentChannel));
  //   let dataPaymentChannelBox = [];
  //   dataPayment.forEach((itemPayment) => {
  //     if (itemPayment.payment_type_id === arr1.payment_type_id) {
  //       uniqueArr.forEach((itemChannel) => {
  //         dataPaymentChannelBox.push(itemChannel);
  //       });
  //     } else {
  //       itemPayment.payment_channel.forEach((itemChannel) => {
  //         dataPaymentChannelBox.push(itemChannel.payment_channel_id);
  //       });
  //     }
  //   });
  //   let dataAPI = {
  //     template_id: String(templateId),
  //     payment_channels: dataPaymentChannelBox,
  //   };
  //   let dataSortStatus = await configSortPaymentChannel(dataAPI);
  //   if (dataSortStatus === "OK") {
  //     notifySuccess({ title: t("notification_successfully_sequence") });
  //     fetchData();
  //   }
  // };
  // End payment channel sequence

  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <div>
      {isLoading ? (
        <>
          <Loading />
        </>
      ) : (
        <Box
          className="boxWrapper-container"
          sx={(theme) => ({
            maxWidth: "800px",
            margin: "auto",
            pt: 2,
            [theme.breakpoints.down("md")]: { px: 2 },
            pb: 4,
          })}
        >
          <PaperWrapper props={{ growFull: 1 }}>
            {/* ประเภทการชำระเงิน */}
            <TitleForm props={{ text: t("payment_type") }} />
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                backgroundColor: "#fff",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: 2,
                  p: 2,
                  boxSizing: "border-box",
                }}
              >
                <Grid container spacing={1}>
                  {dataPayment
                    .sort((a, b) => a.priority - b.priority)
                    .map((itemTab, index) => (
                      <Grid item xs={3}>
                        <Box
                          sx={(theme) => ({
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column",
                            ":hover": {
                              cursor: "grab",
                              opacity: 0.5,
                            },
                            width: "100%",
                            boxSizing: "border-box",
                            borderLeft: itemTab.draggable
                              ? `5px dashed ${theme.palette.primary.main}`
                              : null,
                            pl: itemTab.draggable ? 2 : 0,
                          })}
                          draggable={true}
                          droppable={true}
                          // onDragStart={(e) => onDragStartPaymentType(e, index)}
                          // onDragEnter={(e) => onEnterStartPaymentType(e, index)}
                          // onDragEnd={(e) => onDragEndStartPaymentType(e, index)}
                        >
                          {/* <DragIndicator sx={{ transform: "rotate(90deg)" }} /> */}
                          <Button
                            variant={
                              dataSelectIndexPayment === index
                                ? "contained"
                                : "outlined"
                            }
                            fullWidth
                            onClick={() => setDataSelectIndexPayment(index)}
                          >
                            {itemTab.payment_type.payment_type_lang.name}
                          </Button>
                        </Box>
                      </Grid>
                    ))}
                </Grid>
              </Box>
              <Box>
                {dataPayment[dataSelectIndexPayment] ? (
                  <Box
                    sx={(theme) => ({
                      p: 2,
                    })}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        borderBottom: "1px solid #ccc",
                        alignItems: "center",
                        mb: 2,
                      }}
                    >
                      <Typography>
                        {
                          dataPayment[dataSelectIndexPayment].payment_type
                            .payment_type_lang.name
                        }
                      </Typography>
                      <FormControlLabel
                        onChange={(e) =>
                          updateConfigPaymentType(
                            dataPayment[dataSelectIndexPayment],
                            e
                          )
                        }
                        control={
                          <Switch
                            checked={dataPayment[dataSelectIndexPayment].status}
                            disabled={
                              dataPayment[dataSelectIndexPayment].payment_type
                                .type === "cash" ||
                              dataPayment[dataSelectIndexPayment].payment_type
                                .type === "voucher"
                                ? true
                                : false
                            }
                          />
                        }
                        label={
                          dataPayment[dataSelectIndexPayment].status
                            ? t("global_active")
                            : t("global_in_active")
                        }
                      />
                    </Box>

                    {dataPayment[dataSelectIndexPayment].payment_channel
                      .length > 0 ? (
                      <>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography sx={{ mb: 1 }}>
                            {t("payment_type_channel")}
                          </Typography>
                          {/* <FormControlLabel
                        control={<Switch checked={checkAllPaymentChannel} />}
                        label={t("button_check_all")}
                        onChange={onConfirmStatusChangePaymentTypeChannel}
                      /> */}
                        </Box>
                        <Grid container justifyContent="flex-start" spacing={2}>
                          {dataPayment[dataSelectIndexPayment].payment_channel
                            .sort((a, b) => a.priority - b.priority)
                            .map((itemChannel, index) => (
                              <>
                                <Grid
                                  item
                                  xs={6}
                                  sm={4}
                                  sx={(theme) => ({
                                    // ":hover": {
                                    //   cursor: "grab",
                                    //   opacity: 0.5,
                                    // },
                                    boxSizing: "border-box",
                                    borderLeft: itemChannel.draggable
                                      ? `5px dashed ${theme.palette.primary.main}`
                                      : null,
                                    pl: itemChannel.draggable ? 2 : 0,
                                  })}
                                  draggable={true}
                                  droppable={true}
                                  // onDragStart={(e) =>
                                  //   onDragStartPaymentTypeChannel(e, index)
                                  // }
                                  // onDragEnter={(e) =>
                                  //   onEnterStartPaymentTypeChannel(e, index)
                                  // }
                                  // onDragEnd={(e) =>
                                  //   onDragEndStartPaymentTypeChannel(e, index)
                                  // }
                                >
                                  <Box
                                    sx={{ p: 2, boxShadow: 1, borderRadius: 2 }}
                                  >
                                    {/* <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <DragIndicator
                                    sx={{
                                      transform: "rotate(90deg)",
                                    }}
                                  />
                                </Box> */}

                                    <Box
                                      sx={{
                                        display: "flex",
                                        gap: 1,
                                        alignItems: "center",
                                        justifyContent: "center",
                                        my: 1,
                                      }}
                                    >
                                      <Typography sx={{ textAlign: "center" }}>
                                        {
                                          itemChannel.payment_channel
                                            .payment_channel_lang.name
                                        }
                                      </Typography>
                                    </Box>
                                    {dataPayment[dataSelectIndexPayment]
                                      .payment_type.type === "bank_transfer" ||
                                    dataPayment[dataSelectIndexPayment]
                                      .payment_type.type === "e_wallet" ? (
                                      <>
                                        <Button
                                          startIcon={
                                            itemChannel.image ? (
                                              <Edit />
                                            ) : (
                                              <Add />
                                            )
                                          }
                                          variant="outlined"
                                          disabled={
                                            dataPayment[dataSelectIndexPayment]
                                              .payment_type.type === "cash" ||
                                            dataPayment[dataSelectIndexPayment]
                                              .payment_type.type === "voucher"
                                              ? true
                                              : false
                                          }
                                          onClick={() =>
                                            setDialogCropImage({
                                              status: true,
                                              data: itemChannel,
                                            })
                                          }
                                          fullWidth
                                        >
                                          {t("payment_image_qr_code")}
                                        </Button>
                                      </>
                                    ) : null}
                                    <Divider sx={{ my: 1 }} />
                                    <Box align="center">
                                      <FormControlLabel
                                        onChange={(e) =>
                                          onChangeSelectPaymentChannel(
                                            itemChannel,
                                            index,
                                            e
                                          )
                                        }
                                        control={
                                          <Switch
                                            checked={itemChannel.status}
                                          />
                                        }
                                        disabled={
                                          itemChannel.payment_channel.status ===
                                            false ||
                                          dataPayment[dataSelectIndexPayment]
                                            .payment_type.type === "cash" ||
                                          dataPayment[dataSelectIndexPayment]
                                            .payment_type.type === "voucher"
                                            ? true
                                            : false
                                        }
                                        label={
                                          itemChannel.status
                                            ? t("global_active")
                                            : t("global_in_active")
                                        }
                                      />
                                    </Box>
                                    {itemChannel.payment_channel.status ===
                                    false ? (
                                      <FormHelperText
                                        sx={{ textAlign: "center" }}
                                      >
                                        {t(
                                          "notification_status_setting_is_closed"
                                        )}
                                      </FormHelperText>
                                    ) : null}
                                  </Box>
                                </Grid>
                              </>
                            ))}
                        </Grid>
                      </>
                    ) : (
                      <Grid item xs={12}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography sx={{ mb: 1 }}>
                            {t("global_no_data_record")}
                          </Typography>
                          {/* <FormControlLabel
                        control={<Switch checked={checkAllPaymentChannel} />}
                        label={t("button_check_all")}
                        onChange={onConfirmStatusChangePaymentTypeChannel}
                      /> */}
                        </Box>
                        <EmptyDataDirect
                          props={{
                            url: "/payment-type",
                            text: t("payment_channel"),
                          }}
                        />
                      </Grid>
                    )}

                    {/* <BranchUserPOS templateId={templateId} /> */}
                  </Box>
                ) : null}
              </Box>
            </Box>
          </PaperWrapper>
          {dialogCropImage.data ? (
            <ImageUploadPaymentDialog
              props={{ dialogCropImage, setDialogCropImage, templateId }}
            />
          ) : null}
          {dialogPaymentChannel.data ? (
            <Dialog open={dialogPaymentChannel.status} fullWidth>
              <DialogTitle
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  borderBottom: "1px solid #cccccc",
                }}
              >
                <Typography sx={(theme) => ({ fontSize: 20, fontWeight: 600 })}>
                  {t("payment_channel_update")}
                </Typography>
              </DialogTitle>
              <DialogContent>
                <Box mt={2}>
                  <TextfieldComponent
                    title={t("payment_channel_name")}
                    name="namePaymentChannel"
                    register={register}
                    placeholder={t("")}
                    errors={errors}
                    fullGrid={true}
                    disabled={true}
                    required={true}
                  />
                  {/* <StatusForm
                  statusButton={statusButton}
                  setStatusButton={setStatusButton}
                /> */}
                </Box>
              </DialogContent>
              <DialogActions
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  borderTop: "1px solid #cccccc",
                  gap: 1,
                  p: 2,
                  boxSizing: "border-box",
                }}
              >
                <Button
                  color="info"
                  onClick={() => onClosePaymentChannel()}
                  variant="outlined"
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  type="button"
                  onClick={() => onConfirmUpdatePaymentChannel()}
                >
                  Confirm
                </Button>
              </DialogActions>
            </Dialog>
          ) : null}
        </Box>
      )}
    </div>
  );
}

export default BranchPayment;
