import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { getAllUnitCounter } from "../../../service/unitCounterAPI";
import { postPreDefineCategory } from "../../../service/preDefineCategory";
import { notifySuccess } from "../../../components/ui/popup/toastPopup";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import BreadcrumbsTitle from "../../../components/ui/breadcrumbsTitle";
import { useSchema } from "../../../components/hooks/use-schema";
import { productOptionCategorySchema } from "../../../components/validation/productOptionCategorySchema";
import productOptionsSelectType from "../../../components/utils/productOptionsSelectType.json";
import i18next from "i18next";
import Loading from "../../../components/ui/loading";
import { GridContainerSize } from "../../../components/utils/gridContainerSize";
import ProductOptionsCategoryForm from "./productOptionsCategoryForm";

function ProductOptionsCategoryCreate() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    control,
    watch,
    setValue,
  } = useForm(useSchema(productOptionCategorySchema));
  const [statusButton, setStatusButton] = useState(true);
  const [unitCounterAll, setUnitCounterAll] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const onConfirmForm = async (register) => {
    setIsSubmitting(true);
    let selectTypeName = productOptionsSelectType[i18next.language].find(
      (item) => item.id === register.selectType
    );
    let dataAPI = {
      unit_counter_id: register.unit,
      select_type: selectTypeName.name,
      status: statusButton,
      pre_define_category_lang: {
        name: register.productCategory,
        language_code: "ALL",
      },
    };
    let statusAPI = await postPreDefineCategory(dataAPI);
    if (statusAPI === "OK") {
      notifySuccess({
        title: t("toast_successfully_added_information"),
      });
      navigate("/pre-define-category");
    }
    await new Promise((resolve) => setTimeout(resolve, 2000));
    setIsSubmitting(false);
  };

  const fetchDataUnit = async () => {
    let dataAPI = await getAllUnitCounter();
    dataAPI = dataAPI.filter((item) => item.status === true);
    setUnitCounterAll(dataAPI);
    setIsLoading(false);
  };
  useEffect(() => {
    fetchDataUnit();
  }, []);
  return (
    <div align="left">
      <BreadcrumbsTitle
        props={{
          title: t("product_option_category_create"),
          dataLink: [
            {
              text: t("product_option_category"),
              link: "/pre-define-category",
            },
            {
              text: t("product_option_category_create"),
              link: null,
            },
          ],
        }}
      />
      {isLoading ? (
        <Loading />
      ) : (
        <GridContainerSize>
          <Box p={2}>
            <form onSubmit={handleSubmit(onConfirmForm)}>
              <ProductOptionsCategoryForm
                props={{
                  register,
                  statusButton,
                  setStatusButton,
                  errors,
                  watch,
                  setValue,
                  unitCounterAll,
                  clearErrors,
                  control,
                  productOptionsSelectType,
                  isSubmitting,
                }}
              />
            </form>
          </Box>
        </GridContainerSize>
      )}
    </div>
  );
}

export default ProductOptionsCategoryCreate;
