import React, { useState, useEffect, useContext } from "react";
import { ActionDetail } from "../../../components/ui/dataTable/components/actionDetail";
import { Box, Button } from "@mui/material";
import { MomentConvert } from "../../../components/ui/moment/momentConvert";
import DataMaterialTable from "../../../components/ui/dataTable/dataMaterialTable";
import { useTranslation } from "react-i18next";
import BreadcrumbsTitle from "../../../components/ui/breadcrumbsTitle";
import StatusTable from "../../../components/chip/statusTable";
import { TextTable } from "../../../components/ui/textfield/textfieldCustom";
import LibraryAddIcon from "@mui/icons-material/LibraryAdd";
import { useNavigate } from "react-router-dom";
import {
  addMainCategoryToTemplate,
  getAllMainCategory,
} from "../../../service/mainCategory";
import { notifySuccess } from "../../../components/ui/popup/toastPopup";
import { MyContext } from "../../../context/MyContext";
import dayjs from "dayjs";
function Category() {
  const [rows, setRows] = useState([]);
  const { t } = useTranslation();
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [exportColumnName, setExportColumnName] = useState(null);
  const [columns, setColumns] = useState([]);
  const navigate = useNavigate();
  const { setProgress } = useContext(MyContext);

  const fetchData = async () => {
    let dataColumns = [
      {
        accessorKey: "name",
        flexGrow: 1,
        header: t("global_name"),
        Cell: ({ cell }) => <TextTable props={{ text: cell.getValue() }} />,
      },
      {
        accessorKey: "update",
        size: 100,
        header: t("global_updated_at"),
        Cell: ({ cell }) => <TextTable props={{ text: cell.getValue() }} />,
      },
      {
        accessorKey: "status",
        enableSorting: false,
        size: 20,
        header: t("global_status"),
        Cell: ({ row }) => (
          <StatusTable props={{ status: row.original.status }} />
        ),
      },
      {
        accessorKey: "action",
        enableSorting: false,
        size: 100,
        header: "Action",
        sortable: false,
        Cell: ({ row }) => (
          <Box sx={{ display: "flex", gap: 2 }}>
            <ActionDetail params={row} detailPath={"/category/detail/"} />
            {/* <IconButton>
              <LibraryAddIcon />
            </IconButton> */}
            <Button
              startIcon={<LibraryAddIcon />}
              onClick={() =>
                navigate("/category/sub/detail/" + row.original.id)
              }
              sx={{ borderRadius: 2 }}
            >
              {t("category_sub_add")}
            </Button>
          </Box>
        ),
      },
    ];

    let dataAPI = await getAllMainCategory();
    if (dataAPI) {
      let dataBox = dataAPI
        .sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at))
        .map((item) => ({
          id: item.id,
          name: item.main_category_lang.name,
          update: MomentConvert(item.updated_at),
          status: item.status,
        }));
      setRows(dataBox);
      //===== Start set header name export csv
      let headers = [];
      dataColumns.forEach((item) => {
        if (item.header !== "Image" && item.header !== "Action") {
          headers.push(item.header);
        }
      });
      const csvStringArray = [
        headers,
        ...dataBox.map((item) => [item.name, item.update, item.status]),
      ]
        .map((row) => row.join(","))
        .join("\n");

      setExportColumnName(csvStringArray);
      //===== End set header name export csv
    }
    setColumns(dataColumns);
    setIsLoadingData(false);
  };
  const onInsertToBranches = async (rowsData, branchSelect) => {
    let countRound = 0;
    let timeBoost = 0;
    const promises = rowsData.map(async (row, index) => {
      await new Promise((resolve) => {
        let startTime = performance.now();
        setTimeout(async () => {
          let dataAPI = {
            main_category_id: String(row.original.id),
            branches: branchSelect,
            priority: 1,
          };
          let dataStatus = await addMainCategoryToTemplate(dataAPI);
          if (dataStatus === "OK") {
            countRound++;
            setProgress((prevProgress) =>
              prevProgress >= 100 ? 0 : prevProgress + 100 / rowsData.length
            );
          } else {
            countRound++;
            setProgress((prevProgress) =>
              prevProgress >= 100 ? 0 : prevProgress + 100 / rowsData.length
            );
          }
          if (countRound === rowsData.length) {
            setProgress(0);
            notifySuccess({
              title: t("notification_successfully_insert_information"),
            });
            resolve(true);
          } else {
            resolve(false);
          }
          if (index === 0) {
            let endTime = performance.now();
            let processTime = endTime - startTime;
            timeBoost = processTime;
          }
        }, (timeBoost ? timeBoost + 200 : 1800) * index);
      });
    });
    await Promise.all(promises);
  };

  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box>
      <BreadcrumbsTitle
        props={{
          title: t("category") + " " + t("category_example"),
          suggestText: "",
        }}
      />
      <DataMaterialTable
        statusViewMode={true}
        onInsertToBranch={onInsertToBranches}
        enableSelection={true}
        rows={rows}
        exportColumnName={exportColumnName}
        fileName={t("category") + ` ${dayjs().format("DD/MM/YYYY")}`}
        isLoadingData={isLoadingData}
        columns={columns}
        detailPath={"/category/detail/"}
        createButton={t("category")}
      />
    </Box>
  );
}

export default Category;
