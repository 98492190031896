import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { useForm } from "react-hook-form";
import { notifySuccess } from "../../../components/ui/popup/toastPopup";
import { useNavigate } from "react-router-dom";
import {
  getPreDefineCategory,
  updatePreDefineCategory,
} from "../../../service/preDefineCategory";
import { getAllUnitCounter } from "../../../service/unitCounterAPI";
import { useTranslation } from "react-i18next";
import BreadcrumbsTitle from "../../../components/ui/breadcrumbsTitle";
import Loading from "../../../components/ui/loading";
import { useSchema } from "../../../components/hooks/use-schema";
import { productOptionCategorySchema } from "../../../components/validation/productOptionCategorySchema";
import productOptionsSelectType from "../../../components/utils/productOptionsSelectType.json";
import i18next from "i18next";
import { GridContainerSize } from "../../../components/utils/gridContainerSize";
import ProductOptionsCategoryForm from "./productOptionsCategoryForm";

function ProductOptionsCategoryDetail() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    clearErrors,
    control,
  } = useForm(useSchema(productOptionCategorySchema));
  const { t } = useTranslation();
  const [statusButton, setStatusButton] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [unitCounterAll, setUnitCounterAll] = useState([]);
  const navigate = useNavigate();
  const paramsId =
    window.location.pathname.split("/")[
      window.location.pathname.split("/").length - 1
    ];
  const [isLoading, setIsLoading] = useState(true);

  const onConfirmForm = async (register) => {
    setIsSubmitting(true);
    let selectTypeName = productOptionsSelectType[i18next.language].find(
      (item) => item.id === register.selectType
    );
    let dataAPI = {
      id: String(paramsId),
      unit_counter_id: register.unit,
      select_type: selectTypeName.name,
      status: statusButton,
      pre_define_category_lang: {
        name: register.productCategory,
        language_code: String(localStorage.getItem("language_form")),
      },
    };
    let statusAPI = await updatePreDefineCategory(dataAPI);
    if (statusAPI === "OK") {
      notifySuccess({
        title: t("notification_successfully_edited_information"),
      });
      navigate("/pre-define-category");
    }
    await new Promise((resolve) => setTimeout(resolve, 2000));
    setIsSubmitting(false);
  };

  const fetchData = async () => {
    let dataAPI = await getPreDefineCategory(paramsId);
    setValue("productCategory", dataAPI.pre_define_category_lang.name);
    setValue("selectType", dataAPI.select_type === "single" ? 1 : 2);

    if (dataAPI.unit_counter.status === false) {
      setValue("unit", "");
    } else {
      setValue("unit", dataAPI.unit_counter_id);
    }

    setStatusButton(dataAPI.status);
    setIsLoading(false);
  };
  const fetchDataUnit = async () => {
    let dataAPI = await getAllUnitCounter();
    dataAPI = dataAPI.filter((item) => item.status === true);
    setUnitCounterAll(dataAPI);
  };
  useEffect(() => {
    fetchDataUnit();
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <Box>
      <BreadcrumbsTitle
        props={{
          title: t("product_option_category_info"),
          dataLink: [
            {
              text: t("product_option_category"),
              link: "/pre-define-category",
            },
            {
              text: t("product_option_category_info"),
              link: null,
            },
          ],
        }}
      />
      {isLoading ? (
        <Loading />
      ) : (
        <GridContainerSize>
          <Box p={2}>
            <form onSubmit={handleSubmit(onConfirmForm)}>
              <ProductOptionsCategoryForm
                props={{
                  register,
                  statusButton,
                  setStatusButton,
                  errors,
                  watch,
                  setValue,
                  unitCounterAll,
                  clearErrors,
                  control,
                  productOptionsSelectType,
                  isSubmitting,
                }}
              />
            </form>
          </Box>
        </GridContainerSize>
      )}
    </Box>
  );
}

export default ProductOptionsCategoryDetail;
