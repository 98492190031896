import { Box, useTheme } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";

import { t } from "i18next";
import dayjs from "dayjs";
import BreadcrumbsTitle from "../../../components/ui/breadcrumbsTitle";
import SelectCheckMarkBranch from "../../../components/ui/select/selectCheckMarkBranch";
import { TextTable } from "../../../components/ui/textfield/textfieldCustom";
import { getAllSaleProduct } from "../../../service/saleProductAPI";
import { MyContext } from "../../../context/MyContext";
import DataTableReport from "../../../components/ui/dataTable/dataTableReport";
import i18n from "../../../locals/i18n";

function ReportByHourlyProduct() {
  const [rows, setRows] = useState([]);
  const theme = useTheme();

  const [columns, setColumns] = useState([]);
  const [exportColumnName, setExportColumnName] = useState(null);
  const {
    branchAll,
    setBranchSelectReport,
    branchSelectReportDate,
    reportAll,
    fetchReport,
    saleProductSetAll,
  } = useContext(MyContext);

  const [isLoadingData, setIsLoadingData] = useState(
    reportAll.length > 0 ? false : true
  );
  const fetchData = async () => {
    let headers = [];
    let dataAPISaleProduct =
      saleProductSetAll.length > 0
        ? saleProductSetAll
        : await getAllSaleProduct();
    let dataBranch = [];
    branchAll.forEach((itemBranch) => {
      let dataAllReceipt = reportAll.filter(
        (item) => item.branch_id === itemBranch.id
      );
      if (dataAllReceipt.length > 0) {
        dataBranch.push({
          branch_id: itemBranch.id,
          receiptAll: dataAllReceipt,
        });
      }
    });

    const combineData = (data1, data2) => {
      const combinedData = {};
      for (let key in data1) {
        combinedData[key] = data1[key] + data2[key];
      }
      return combinedData;
    };
    let dataAllSaleProduct = [];

    dataBranch.forEach((itemBranch) => {
      let dataCollectSaleProduct = [];
      itemBranch.receiptAll
        .filter((itemNoVoid) => itemNoVoid.receipt_status === "success")
        .forEach((itemReceipt) => {
          itemReceipt.receipt_sale_products.forEach((itemSaleProduct) => {
            const saleProduct = dataAPISaleProduct.find(
              (itemSaleProductAll) =>
                itemSaleProductAll.id === itemSaleProduct.sale_product_id
            );

            if (saleProduct) {
              dataCollectSaleProduct.push({
                name: itemSaleProduct.name,
                mainCategory:
                  saleProduct.main_categories[0].main_category_lang.name,
                minorCategory:
                  saleProduct.minor_categories.length > 0
                    ? saleProduct.minor_categories[0].minor_category_lang.name
                    : "None",
                price: itemSaleProduct.price,
                branch_id: itemBranch.branch_id,
                id: itemSaleProduct.sale_product_id,
                amount: itemSaleProduct.amount,
                branchName: itemReceipt.receipt_config_receipt
                  ? itemReceipt.receipt_config_receipt.config_receipt_lang
                      .branch_name
                  : null,

                time: {
                  "00:00":
                    dayjs(itemSaleProduct.datetime, "YYYYMMDDHHmmss").format(
                      "HH"
                    ) === "00"
                      ? itemSaleProduct.amount
                      : 0,
                  "01:00":
                    dayjs(itemSaleProduct.datetime, "YYYYMMDDHHmmss").format(
                      "HH"
                    ) === "01"
                      ? itemSaleProduct.amount
                      : 0,
                  "02:00":
                    dayjs(itemSaleProduct.datetime, "YYYYMMDDHHmmss").format(
                      "HH"
                    ) === "02"
                      ? itemSaleProduct.amount
                      : 0,
                  "03:00":
                    dayjs(itemSaleProduct.datetime, "YYYYMMDDHHmmss").format(
                      "HH"
                    ) === "03"
                      ? itemSaleProduct.amount
                      : 0,
                  "04:00":
                    dayjs(itemSaleProduct.datetime, "YYYYMMDDHHmmss").format(
                      "HH"
                    ) === "04"
                      ? itemSaleProduct.amount
                      : 0,
                  "05:00":
                    dayjs(itemSaleProduct.datetime, "YYYYMMDDHHmmss").format(
                      "HH"
                    ) === "05"
                      ? itemSaleProduct.amount
                      : 0,
                  "06:00":
                    dayjs(itemSaleProduct.datetime, "YYYYMMDDHHmmss").format(
                      "HH"
                    ) === "06"
                      ? itemSaleProduct.amount
                      : 0,
                  "07:00":
                    dayjs(itemSaleProduct.datetime, "YYYYMMDDHHmmss").format(
                      "HH"
                    ) === "07"
                      ? itemSaleProduct.amount
                      : 0,
                  "08:00":
                    dayjs(itemSaleProduct.datetime, "YYYYMMDDHHmmss").format(
                      "HH"
                    ) === "08"
                      ? itemSaleProduct.amount
                      : 0,
                  "09:00":
                    dayjs(itemSaleProduct.datetime, "YYYYMMDDHHmmss").format(
                      "HH"
                    ) === "09"
                      ? itemSaleProduct.amount
                      : 0,
                  "10:00":
                    dayjs(itemSaleProduct.datetime, "YYYYMMDDHHmmss").format(
                      "HH"
                    ) === "10"
                      ? itemSaleProduct.amount
                      : 0,
                  "11:00":
                    dayjs(itemSaleProduct.datetime, "YYYYMMDDHHmmss").format(
                      "HH"
                    ) === "11"
                      ? itemSaleProduct.amount
                      : 0,
                  "12:00":
                    dayjs(itemSaleProduct.datetime, "YYYYMMDDHHmmss").format(
                      "HH"
                    ) === "12"
                      ? itemSaleProduct.amount
                      : 0,
                  "13:00":
                    dayjs(itemSaleProduct.datetime, "YYYYMMDDHHmmss").format(
                      "HH"
                    ) === "13"
                      ? itemSaleProduct.amount
                      : 0,
                  "14:00":
                    dayjs(itemSaleProduct.datetime, "YYYYMMDDHHmmss").format(
                      "HH"
                    ) === "14"
                      ? itemSaleProduct.amount
                      : 0,
                  "15:00":
                    dayjs(itemSaleProduct.datetime, "YYYYMMDDHHmmss").format(
                      "HH"
                    ) === "15"
                      ? itemSaleProduct.amount
                      : 0,
                  "16:00":
                    dayjs(itemSaleProduct.datetime, "YYYYMMDDHHmmss").format(
                      "HH"
                    ) === "16"
                      ? itemSaleProduct.amount
                      : 0,
                  "17:00":
                    dayjs(itemSaleProduct.datetime, "YYYYMMDDHHmmss").format(
                      "HH"
                    ) === "17"
                      ? itemSaleProduct.amount
                      : 0,
                  "18:00":
                    dayjs(itemSaleProduct.datetime, "YYYYMMDDHHmmss").format(
                      "HH"
                    ) === "18"
                      ? itemSaleProduct.amount
                      : 0,
                  "19:00":
                    dayjs(itemSaleProduct.datetime, "YYYYMMDDHHmmss").format(
                      "HH"
                    ) === "19"
                      ? itemSaleProduct.amount
                      : 0,
                  "20:00":
                    dayjs(itemSaleProduct.datetime, "YYYYMMDDHHmmss").format(
                      "HH"
                    ) === "20"
                      ? itemSaleProduct.amount
                      : 0,
                  "21:00":
                    dayjs(itemSaleProduct.datetime, "YYYYMMDDHHmmss").format(
                      "HH"
                    ) === "21"
                      ? itemSaleProduct.amount
                      : 0,
                  "22:00":
                    dayjs(itemSaleProduct.datetime, "YYYYMMDDHHmmss").format(
                      "HH"
                    ) === "22"
                      ? itemSaleProduct.amount
                      : 0,
                  "23:00":
                    dayjs(itemSaleProduct.datetime, "YYYYMMDDHHmmss").format(
                      "HH"
                    ) === "23"
                      ? itemSaleProduct.amount
                      : 0,
                },
                discountByItems:
                  (itemSaleProduct.price -
                    itemSaleProduct.price_after_discount) *
                  itemSaleProduct.amount,
              });
            }
          });
        });
      dataAllSaleProduct.push(dataCollectSaleProduct);
    });

    let dataBox = [];

    dataAllSaleProduct.forEach((itemBranch) => {
      let dataItemBrachBoxWithSaleProduct = [];
      itemBranch.forEach((itemAllProduct) => {
        let existingItem = dataItemBrachBoxWithSaleProduct.find(
          (itemFind) => itemFind.id === itemAllProduct.id
        );
        if (existingItem === undefined) {
          dataItemBrachBoxWithSaleProduct.push(itemAllProduct);
        } else {
          let combinedData = combineData(
            existingItem.time,
            itemAllProduct.time
          );
          existingItem.time = combinedData;
          existingItem.amount = existingItem.amount + itemAllProduct.amount;
          existingItem.discountByItems += itemAllProduct.discountByItems;
        }
      });
      dataBox.push(dataItemBrachBoxWithSaleProduct);
    });

    dataBox.forEach((itemReceipt) => {
      itemReceipt.sort((a, b) =>
        a.name < b.name ? -1 : a.name > b.name ? 1 : 0
      );
      itemReceipt.forEach((itemSaleProduct) => {
        itemSaleProduct.grossSale =
          itemSaleProduct.amount * itemSaleProduct.price;
        itemSaleProduct.discountedPrice =
          itemSaleProduct.grossSale - itemSaleProduct.discountByItems;
      });
    });

    let dataSaleProduct = [];
    dataBox.forEach((item) => {
      item.forEach((itemSaleProduct) => {
        delete itemSaleProduct.branch_id;
        delete itemSaleProduct.id;
        for (let hour = 0; hour < 24; hour++) {
          let time = hour.toString().padStart(2, "0") + ":00";
          itemSaleProduct[time] = itemSaleProduct.time[time];
        }
        dataSaleProduct.push(itemSaleProduct);
      });
    });

    // dataSaleProduct.push({
    //   name: "Total",
    //   mainCategory: null,
    //   minorCategory: null,
    //   amount: dataSaleProduct.reduce((acc, item) => acc + item.amount, 0),
    //   price: dataSaleProduct.reduce((acc, item) => acc + item.price, 0),
    //   grossSale: dataSaleProduct.reduce((acc, item) => acc + item.grossSale, 0),
    //   discountByItems: dataSaleProduct.reduce(
    //     (acc, item) => acc + item.discountByItems,
    //     0
    //   ),
    //   discountedPrice: dataSaleProduct.reduce(
    //     (acc, item) => acc + item.discountedPrice,
    //     0
    //   ),
    // time: timeSlots.reduce((acc, slot) => {
    //   acc[slot] = dataSaleProduct.reduce(
    //     (sum, item) => sum + item.time[slot],
    //     0
    //   );
    //   return acc;
    // }, {}),
    //   branchName: null,
    // });

    setRows(dataSaleProduct);

    let dataColumns = [
      {
        header: t("global_name"),
        accessorKey: "name",
        minWidth: 150,
        Cell: ({ cell }) => <TextTable props={{ text: cell.getValue() }} />,
        Footer: () => (
          <TextTable
            props={{
              text: "Total",
              alignText: "left",
              fontWeight: "bold",
            }}
          />
        ),
      },
      {
        header: t("report_main_category"),
        accessorKey: "mainCategory",
        minWidth: i18n.language === "th" ? 150 : 110,
        Cell: ({ cell }) => <TextTable props={{ text: cell.getValue() }} />,
      },
      {
        header: t("report_subcategory"),
        accessorKey: "minorCategory",
        minWidth: i18n.language === "th" ? 150 : 100,
        Cell: ({ cell }) => <TextTable props={{ text: cell.getValue() }} />,
      },
      {
        header: t("report_amount"),
        accessorKey: "amount",
        minWidth: i18n.language === "th" ? 70 : 80,
        isSorting: true,
        Cell: ({ cell }) => (
          <TextTable
            props={{
              text: Number(cell.getValue()).toLocaleString(undefined, {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              }),
              alignText: "right",
            }}
          />
        ),
      },
      {
        header: t("report_price_per_unit"),
        accessorKey: "price",
        isSorting: true,
        minWidth: i18n.language === "th" ? 110 : 100,
        Cell: ({ cell }) => (
          <TextTable
            props={{
              text: Number(cell.getValue()).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }),
              alignText: "right",
            }}
          />
        ),
      },
      {
        header: t("report_gross_sale"),
        accessorKey: "grossSale",
        isSorting: true,
        minWidth: 100,
        Cell: ({ cell }) => (
          <TextTable
            props={{
              text: Number(cell.getValue()).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }),
              alignText: "right",
            }}
          />
        ),
      },
      {
        header: t("report_discount_by_item"),
        accessorKey: "discountByItems",
        isSorting: true,
        minWidth: i18n.language === "th" ? 100 : 120,
        Cell: ({ cell }) => (
          <TextTable
            props={{
              text: Number(cell.getValue()).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }),
              alignText: "right",
            }}
          />
        ),
      },
      {
        header: t("report_discounted_price"),
        accessorKey: "discountedPrice",
        isSorting: true,
        minWidth: i18n.language === "th" ? 100 : 130,
        Cell: ({ cell }) => (
          <TextTable
            props={{
              text: Number(cell.getValue()).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }),
              alignText: "right",
            }}
          />
        ),
      },
    ];
    Array.from({ length: 24 }, (_, i) =>
      dataColumns.push({
        header: i.toString() + ":00",
        accessorKey: `${i.toString().padStart(2, "0")}:00`,
        isSorting: true,
        center: true,
        decimal: 0,
        minWidth: i18n.language === "th" ? 50 : 50,
        Cell: ({ cell }) => (
          <TextTable
            props={{
              text: cell.getValue() > 0 ? cell.getValue() : 0,
              fontWeight: cell.getValue() > 0 ? true : false,
              colorSetting:
                cell.getValue() > 0 ? theme.palette.secondary.main : false,
              alignText: "center",
            }}
          />
        ),
      })
    );
    dataColumns.push({
      header: t("report_branch"),
      accessorKey: "branchName",
      Cell: ({ cell }) => (
        <TextTable
          props={{
            text: cell.getValue(),
          }}
        />
      ),
    });
    dataColumns.forEach((item) => {
      if (item.header !== "Image" && item.header !== "Action") {
        headers.push(item.header);
      }
    });
    //===== Start set header name export csv
    const csvStringArray = [
      headers,
      ...dataSaleProduct.map((item) => [
        item.name,
        item.mainCategory,
        item.minorCategory,
        item.amount,
        item.price,
        item.grossSale,
        item.discountByItems,
        item.discountedPrice,
        ...Array.from(
          { length: 24 },
          (_, i) => item.time[`${i.toString().padStart(2, "0")}:00`]
        ),
        item.branchName,
      ]),
    ]
      .map((row) => row.join(","))
      .join("\n");

    setExportColumnName(csvStringArray);
    //===== End set header name export csv
    setColumns(dataColumns);
    setIsLoadingData(false);
  };
  const onConfirm = async () => {
    await setIsLoadingData(true);
    await fetchReport();
  };
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportAll]);
  return (
    <Box>
      <BreadcrumbsTitle
        props={{
          title: t("report_by_hourly_product"),
        }}
      />
      <Box>
        <SelectCheckMarkBranch
          props={{
            onConfirm,
            setBranchSelectReport,
          }}
        />

        <DataTableReport
          statusViewMode={true}
          rows={rows}
          isLoadingData={isLoadingData}
          columns={columns}
          exportColumnName={exportColumnName}
          height={0.85}
          fileName={
            t("report_by_hourly_product") +
            `${dayjs(branchSelectReportDate.startDate).format(
              "DD/MM/YYYY"
            )} - ${dayjs(branchSelectReportDate.endDate).format("DD/MM/YYYY")}`
          }
        />
      </Box>
    </Box>
  );
}

export default ReportByHourlyProduct;
