import React, { useState, useEffect, useContext } from "react";
import { Box, Button } from "@mui/material";
import DataMaterialTable from "../../components/ui/dataTable/dataMaterialTable";
import { useTranslation } from "react-i18next";
import { ActionDetail } from "../../components/ui/dataTable/components/actionDetail";
import {
  addPaymentTypeToTemplate,
  getAllPaymentType,
} from "../../service/paymentTypeAPI";
import { MomentConvert } from "../../components/ui/moment/momentConvert";
import BreadcrumbsTitle from "../../components/ui/breadcrumbsTitle";
import StatusTable from "../../components/chip/statusTable";
import { TextTable } from "../../components/ui/textfield/textfieldCustom";
import { notifySuccess } from "../../components/ui/popup/toastPopup";
import { MyContext } from "../../context/MyContext";
import dayjs from "dayjs";
import { LibraryAdd } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

function PaymentType() {
  const [rows, setRows] = useState([]);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [exportColumnName, setExportColumnName] = useState(null);
  const [columns, setColumns] = useState([]);
  const { setProgress } = useContext(MyContext);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const convertType = (type) => {
    switch (type) {
      case "e_wallet":
        return "E-Wallet";
      case "cash_card":
        return "Cash Card";
      case "bank_transfer":
        return "Bank Transfer";
      case "cash":
        return "Cash";
      case "card":
        return "Card";
      case "nets":
        return "NETS";
      case "voucher":
        return "Voucher";
        case "delivery":
          return "Delivery";
      default:
        return type;
    }
  };
  const fetchData = async () => {
    let dataColumns = [
      {
        accessorKey: "type",
        size: 40,
        header: t("payment_type"),
      },
      // {
      //   accessorKey: "name",
      //   size: 80,
      //   header: t("payment_type_name"),
      //   Cell: ({ cell }) => <TextTable props={{ text: cell.getValue() }} />,
      // },
      // {
      //   accessorKey: "altName",
      //   size: 80,
      //   header: t("payment_type_alt_name"),
      //   Cell: ({ cell }) => <TextTable props={{ text: cell.getValue() }} />,
      // },
      {
        accessorKey: "update",
        size: 80,
        header: t("global_updated_at"),
        Cell: ({ cell }) => <TextTable props={{ text: cell.getValue() }} />,
      },
      {
        accessorKey: "status",
        enableSorting: false,
        size: 20,
        header: t("global_status"),
        Cell: ({ row }) => (
          <StatusTable props={{ status: row.original.status }} />
        ),
      },
      {
        accessorKey: "action",
        enableSorting: false,
        size: 100,
        header: "Action",
        sortable: false,
        Cell: ({ row }) => (
          <Box sx={{ display: "flex", gap: 2 }}>
            <ActionDetail params={row} detailPath={"/payment-type/detail/"} />

            <Button
              startIcon={<LibraryAdd />}
              onClick={() =>
                navigate("/payment-type/sub/detail/" + row.original.id)
              }
              sx={{ borderRadius: 2 }}
            >
              {t("payment_channel")}
            </Button>
          </Box>
        ),
      },
    ];

    let dataAPI = await getAllPaymentType();
    if (dataAPI) {
      let dataBox = dataAPI
        .sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at))
        .map((item) => ({
          id: item.id,
          // name: item.payment_type_lang.name,
          // altName: item.payment_type_lang.alt_name,
          type: convertType(item.type),
          update: MomentConvert(item.updated_at),
          status: item.status,
        }));
      setRows(dataBox);
      //===== Start set header name export csv
      let headers = [];
      dataColumns.forEach((item) => {
        if (item.header !== "Image" && item.header !== "Action") {
          headers.push(item.header);
        }
      });
      const csvStringArray = [
        headers,
        ...dataBox.map((item) => [
          item.type,
          // item.name,
          // item.altName,
          item.update,
          item.status,
        ]),
      ]
        .map((row) => row.join(","))
        .join("\n");

      setExportColumnName(csvStringArray);
      //===== End set header name export csv
    }
    setColumns(dataColumns);

    setIsLoadingData(false);
  };

  const onInsertToBranches = async (rowsData, branchSelect) => {
    let countRound = 0;
    let timeBoost = 0;
    const promises = rowsData.map(async (row, index) => {
      await new Promise((resolve) => {
        let startTime = performance.now();
        setTimeout(async () => {
          let dataAPI = {
            payment_type_id: String(row.original.id),
            branches: branchSelect,
            priority: 1,
          };
          let dataStatus = await addPaymentTypeToTemplate(dataAPI);
          if (dataStatus === "OK") {
            countRound++;
            setProgress((prevProgress) =>
              prevProgress >= 100 ? 0 : prevProgress + 100 / rowsData.length
            );
          } else {
            countRound++;
            setProgress((prevProgress) =>
              prevProgress >= 100 ? 0 : prevProgress + 100 / rowsData.length
            );
          }
          if (countRound === rowsData.length) {
            setProgress(0);
            notifySuccess({
              title: t("notification_successfully_insert_information"),
            });
            resolve(true);
          } else {
            resolve(false);
          }
          if (index === 0) {
            let endTime = performance.now();
            let processTime = endTime - startTime;
            timeBoost = processTime;
          }
        }, (timeBoost ? timeBoost + 200 : 1800) * index);
      });
    });
    await Promise.all(promises);
  };
  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <Box>
      <BreadcrumbsTitle
        props={{
          title: t("payment_type"),
        }}
      />
      <DataMaterialTable
        statusViewMode={true}
        onInsertToBranch={onInsertToBranches}
        exportColumnName={exportColumnName}
        fileName={t("payment_type") + ` ${dayjs().format("DD/MM/YYYY")}`}
        enableSelection={true}
        rows={rows}
        isLoadingData={isLoadingData}
        columns={columns}
        detailPath={"/payment-type/detail/"}
        createButton={t("payment_type")}
        createPath={null}
      />
    </Box>
  );
}

export default PaymentType;
