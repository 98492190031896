import { Box, styled } from "@mui/material";

export const BoxWrapper = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
    gap: "16px",
  },
  display: "grid",
  rowGap: "16px",
}));
export const BoxPadding = styled(Box)(({ theme, padding, width }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "16px",
  // margin: "auto",
  boxSizing: "border-box",

  // Apply dynamic padding and width based on props or use defaults
  padding: padding || "32px",
  width: width || "100%",

  [theme.breakpoints.up("sm")]: {
    width: width || "100%",
  },
  [theme.breakpoints.up("md")]: {
    width: width || "75%",
  },
  [theme.breakpoints.up("lg")]: {
    padding: padding || "0 48px",
    width: width || "50%",
  },
}));
