import { Box, Divider, Paper, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import {
  configGetReceipt,
  configUpdateReceipt,
} from "../../../../service/branchConfigAPI/branchConfigReceipt";
import Loading from "../../../../components/ui/loading";
import { notifySuccess } from "../../../../components/ui/popup/toastPopup";
import { useTranslation } from "react-i18next";
import { uploadImage } from "../../../../service/upload/uploadImage";
import { useForm } from "react-hook-form";
import DialogReceipt from "./dialogReceipt";
import { useSchema } from "../../../../components/hooks/use-schema";
import { branchReceipt } from "../../../../components/validation/branch/branchReceipt";
import { BoxImage } from "../../../../components/ui/boxImage/boxImage";
import { BoxWrapper } from "../../../../components/ui/box/boxWrapper";
import { NumberDecimal } from "../../../../components/utils/numberDecimal";
import { BusinessName } from "../../../../service/getLocalStorage";
import { configGetAllServiceCharge } from "../../../../service/branchConfigAPI/branchConfigServiceCharge";
import { getImage } from "../../../../service/upload/getImage";
import { UseConvertImageToBase64 } from "../../../../components/hooks/use-convert-image-to-base-64";

function BranchReceipt(props) {
  let { dataBranch, templateId } = props;
  const fileInputRef = useRef(null);
  const {
    setValue,
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm(useSchema(branchReceipt));
  const [data, setData] = useState(null);
  const [statusButton, setStatusButton] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [imageLogo, setImageLogo] = useState(null);
  const [imageQRCode, setImageQRCode] = useState(null);
  const [duplicateDetail, setDuplicateDetail] = useState(false);
  const [configBranch, setConfigBranch] = useState(null);
  const [configShow, setConfigShow] = useState({
    menuOption: true,
    menuAtPriceZero: true,
    tableOrder: true,
    vat: true,
    serviceCharge: true,
  });

  const { t } = useTranslation();
  const onConfigShow = async (status, name) => {
    setConfigShow({ ...configShow, [name]: status });
  };
  let exampleReceipt = {
    detailReceipt: [
      { name: "Order Type :", value: "Dine In" },
      { name: "Table: ", value: "11" },
      { name: "Staff: ", value: "John" },
    ],
    detailBetween: [
      { name: "Guest: 1", name2: "Receipt No: B114113" },
      { name: "Date: 22/11/2023", name2: "Time: 17:50" },
    ],
    productReceipt: [
      {
        name: "Mini Pie (10pcs.)",
        amount: 10,
        price: 990.0,
        discount: { name: "discount 10%", value: "-99.00" },
        option: [],
      },
      {
        name: "Coffee",
        amount: 1,
        price: 100.0,
        discount: null,
        option: [
          { name: "low sugar", value: 0 },
          { name: "cream", value: 10 },
        ],
      },
      {
        name: "Water",
        amount: 1,
        price: 0.0,
        discount: null,
        option: [],
      },
    ],
    calculateReceipt: [
      { name: "Items", value: configShow.menuAtPriceZero ? 11 : 12 },
      { name: "Subtotal", value: 1100.0 },
      { name: "Discount", value: -99.0 },
    ],
  };
  const onDuplicateDetail = async (status) => {
    setDuplicateDetail(status);
    let {
      branch_lang,
      b_branch_code,
      postcode_no,
      house_no,
      floor_no,
      village_no,
    } = dataBranch;
    let {
      province,
      district,
      subdistrict,
      name,
      alley,
      road,
      building,
      village,
    } = branch_lang;
    let addressNew =
      (village ? village + ", " : "") +
      (village_no ? village_no + ", " : "") +
      (floor_no ? floor_no + " Floor, " : "") +
      (house_no ? house_no + ", " : "") +
      (alley ? alley + ", " : "") +
      (building ? building + ", " : "") +
      (road ? road + ", " : "") +
      subdistrict +
      ", " +
      district +
      ", " +
      province +
      ", " +
      postcode_no;
    await setValue("branchCode", b_branch_code);
    await setValue("address", addressNew);
    await setValue("branchName", name);
    await setValue("businessName", BusinessName);
  };
  const fetchData = async () => {
    let dataAPI = await configGetReceipt(templateId);
    let dataConfig = await await configGetAllServiceCharge(templateId);
    let {
      template_config_receipt_lang,
      status,
      display_menu_option,
      display_price_zero,
      display_table_name_order_no,
      display_vat,
      display_service_charge,
      business_name,
      branch_code,
    } = dataAPI;

    let {
      logo,
      name,
      branch_name,
      tax_id,
      tel,
      text_header,
      text_footer_1,
      img_footer,
      text_footer_2,
      address,
      pos_id_number,
      invoice_name,
      invoice_last_number,
    } = template_config_receipt_lang;
    if (logo) {
      let promises = await getImage(logo);
      logo = UseConvertImageToBase64(promises);
      await Promise.all(promises);
    }
    if (img_footer) {
      let promisesFooter = await getImage(img_footer);
      img_footer = UseConvertImageToBase64(promisesFooter);
      await Promise.all(promisesFooter);
    }

    setValue("branchName", branch_name);
    setValue("businessName", business_name);
    setValue("branchCode", branch_code);
    setValue("address", address);
    setValue("storeName", name);
    setValue("shop_name", name);
    setValue("tel", tel);
    setValue("posId", pos_id_number);
    setValue("taxId", tax_id);
    setValue("invoiceName", invoice_name);
    setValue("invoiceLastNumber", invoice_last_number);
    setValue("textHeader", text_header);
    setValue("textFooter1", text_footer_1);
    setValue("textFooter2", text_footer_2);
    setValue("imageFooter", img_footer);

    setConfigShow({
      menuOption: display_menu_option,
      menuAtPriceZero: display_price_zero,
      tableOrder: display_table_name_order_no,
      vat: display_vat,
      serviceCharge: display_service_charge,
    });
    await setData(dataAPI);
    await setImageLogo(logo);
    await setImageQRCode(img_footer);
    await setConfigBranch(dataConfig);
    await setStatusButton(status);
    await setIsLoading(false);
  };

  const onConfirmForm = async (register) => {
    setIsSubmitting(true);
    let {
      branchName,
      businessName,
      branchCode,
      address,
      storeName,
      tel,
      taxId,
      posId,
      invoiceName,
      invoiceLastNumber,
      textHeader,
      textFooter1,
      textFooter2,
    } = register;
    let newImageLogo = "";
    if (imageLogo) {
      if (typeof imageLogo === "object" && imageLogo) {
        newImageLogo = await uploadImage(imageLogo);
      } else {
        newImageLogo = data.logo;
      }
    }
    let newImageQRCode = "";
    if (imageQRCode) {
      if (typeof imageQRCode === "object" && imageQRCode) {
        newImageQRCode = await uploadImage(imageQRCode);
      } else {
        newImageQRCode = data.img_footer;
      }
    }
    let dataAPI = {
      template_id: String(templateId),
      business_name: businessName,
      branch_code: branchCode,
      display_menu_option: configShow.menuOption,
      display_price_zero: configShow.menuAtPriceZero,
      display_table_name_order_no: configShow.tableOrder,
      display_vat: configShow.vat,
      display_service_charge: configShow.serviceCharge,
      status: statusButton,
      config_receipt_lang: {
        name: storeName,
        logo: newImageLogo,
        shop_name: storeName,
        branch_name: branchName,
        pos_id_number: posId,
        tax_id: taxId,
        invoice_name: invoiceName,
        invoice_last_number: invoiceLastNumber,
        address: address,
        tel: tel,
        text_header: textHeader,
        text_footer_1: textFooter1,
        img_footer: newImageQRCode,
        text_footer_2: textFooter2,
        language_code: String(localStorage.getItem("language_form")),
      },
    };
    let dataStatus = await configUpdateReceipt(dataAPI);
    if (dataStatus === "OK") {
      notifySuccess({
        title: t("notification_successfully_edited_information"),
      });
      setIsSubmitting(false);
      fetchData();
    }
  };
  const calculateOption = (item) => {
    let sum = 0;
    item.option.forEach((itemOption) => {
      sum = sum + itemOption.value;
    });
    return sum + item.price;
  };
  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      {isLoading ? (
        <>
          <Loading />
        </>
      ) : (
        <BoxWrapper
          sx={(theme) => ({
            maxWidth: "900px",
            margin: "auto",
            p: 2,
            [theme.breakpoints.down("md")]: { px: 2 },
          })}
        >
          <DialogReceipt
            props={{
              configShow,
              onConfigShow,
              register,
              handleSubmit,
              errors,
              onConfirmForm,
              setImageLogo,
              imageLogo,
              fileInputRef,
              exampleReceipt,
              imageQRCode,
              setImageQRCode,
              statusButton,
              setStatusButton,
              isSubmitting,
              duplicateDetail,
              onDuplicateDetail,
            }}
          />
          <Box
            sx={(theme) => ({
              maxWidth: "410px",
              boxSizing: "border-box",
              margin: "auto",
              [theme.breakpoints.down("sm")]: {},
              [theme.breakpoints.up("sm")]: {
                height: "75vh",
                overflow: "auto",
                minWidth: "300px",
              },
            })}
          >
            <Paper sx={{ px: 2, py: 4 }} align="center">
              <Box align="center" sx={{ width: "60%" }}>
                <BoxImage image={imageLogo} type={"table"} />
              </Box>
              <Box sx={{ width: "80%" }}>
                <Typography
                  sx={{
                    wordBreak: "break-word",
                    fontSize: 14,
                    fontWeight: "bold",
                  }}
                >
                  {watch("storeName")}
                </Typography>
                <Typography
                  sx={{
                    fontSize: 12,
                    wordBreak: "break-word",
                  }}
                >
                  {watch("branchName")}
                </Typography>
                <Typography
                  sx={{
                    fontSize: 12,
                    wordBreak: "break-word",
                  }}
                >
                  {watch("businessName")}
                </Typography>
                <Typography
                  sx={{
                    fontSize: 12,
                    wordBreak: "break-word",
                  }}
                >
                  {watch("branchCode")
                    ? "No. Branch: " + watch("branchCode")
                    : null}
                </Typography>
                <Typography
                  sx={{
                    wordBreak: "break-word",
                    fontSize: 12,
                  }}
                >
                  {watch("address")}
                </Typography>
                <Typography
                  sx={{
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    width: "100%",
                    fontSize: 12,
                  }}
                >
                  {watch("taxId") ? <>Tax ID: {watch("taxId")}</> : null}
                </Typography>
                <Typography
                  sx={{
                    wordBreak: "break-word",
                    fontSize: 12,
                  }}
                >
                  {watch("tel") ? <>Tel: {watch("tel")}</> : null}
                </Typography>
                <Typography
                  sx={{
                    wordBreak: "break-word",
                    fontSize: 14,
                    fontWeight: "bold",
                  }}
                >
                  Receipt
                </Typography>
                <Typography
                  sx={{
                    wordBreak: "break-word",
                    fontSize: 12,
                  }}
                >
                  {watch("textHeader")}
                </Typography>
              </Box>
              <Divider sx={{ my: 1 }} variant="horizontal" />

              {watch("posId") ? (
                <>
                  <Box sx={{ display: "flex", gap: 1 }}>
                    <Typography sx={(theme) => ({ fontSize: 12 })}>
                      POS ID :
                    </Typography>
                    <Typography sx={(theme) => ({ fontSize: 12 })}>
                      {watch("posId")}
                    </Typography>
                  </Box>
                </>
              ) : null}
              {watch("invoiceName") || watch("invoiceLastNumber") ? (
                <>
                  <Box sx={{ display: "flex", gap: 1 }}>
                    <Typography sx={(theme) => ({ fontSize: 12 })}>
                      NO :
                    </Typography>
                    <Typography sx={(theme) => ({ fontSize: 12 })}>
                      {watch("invoiceName")}/
                      {watch("invoiceLastNumber")
                        ? watch("invoiceLastNumber")
                        : "00000000"}
                    </Typography>
                  </Box>
                </>
              ) : null}
              <Box align="left">
                {exampleReceipt.detailReceipt.map((item, index) => (
                  <Box sx={{ display: "flex", gap: 1 }} key={index}>
                    <Typography sx={(theme) => ({ fontSize: 12 })}>
                      {item.name}
                    </Typography>
                    <Typography sx={(theme) => ({ fontSize: 12 })}>
                      {item.value}
                    </Typography>
                  </Box>
                ))}
                {exampleReceipt.detailBetween.map((item, index) => (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                    key={index}
                  >
                    <Typography sx={(theme) => ({ fontSize: 12 })}>
                      {item.name}
                    </Typography>
                    <Typography sx={(theme) => ({ fontSize: 12 })}>
                      {item.name2}
                    </Typography>
                  </Box>
                ))}
                <Divider sx={{ my: 1 }} variant="horizontal" />
                {exampleReceipt.productReceipt.map((item, index) => (
                  <React.Fragment key={index}>
                    {item.price === 0 && configShow.menuAtPriceZero ? null : (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            gap: 1,
                          }}
                        >
                          <Typography sx={(theme) => ({ fontSize: 12 })}>
                            {item.amount}
                          </Typography>
                          <Typography sx={(theme) => ({ fontSize: 12 })}>
                            {item.name}
                          </Typography>
                        </Box>
                        <Typography sx={(theme) => ({ fontSize: 12 })}>
                          <NumberDecimal
                            number={
                              !configShow.menuOption
                                ? calculateOption(item)
                                : item.price
                            }
                            decimal={2}
                          />
                        </Typography>
                      </Box>
                    )}
                    {item.discount ? (
                      <Box
                        sx={(theme) => ({
                          display: "flex",
                          justifyContent: "space-between",
                          color: theme.palette.secondaryText.main,
                        })}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            gap: 1,
                          }}
                        >
                          <Typography sx={(theme) => ({ fontSize: 12 })}>
                            #
                          </Typography>
                          <Typography sx={(theme) => ({ fontSize: 12 })}>
                            {item.discount.name}
                          </Typography>
                        </Box>
                        <Typography sx={(theme) => ({ fontSize: 12 })}>
                          <NumberDecimal
                            number={item.discount.value}
                            decimal={2}
                          />
                        </Typography>
                      </Box>
                    ) : null}
                    {configShow.menuOption ? (
                      <>
                        {item.option.length > 0 ? (
                          <>
                            {item.option.map((ItemOption) => (
                              <Box
                                sx={(theme) => ({
                                  display: "flex",
                                  justifyContent: "space-between",
                                  color: theme.palette.secondaryText.main,
                                })}
                              >
                                <Typography
                                  sx={(theme) => ({ fontSize: 12, ml: 2 })}
                                >
                                  {ItemOption.name}
                                </Typography>
                                <Typography sx={(theme) => ({ fontSize: 12 })}>
                                  <NumberDecimal
                                    number={ItemOption.value}
                                    decimal={2}
                                  />
                                </Typography>
                              </Box>
                            ))}
                          </>
                        ) : null}
                      </>
                    ) : null}
                  </React.Fragment>
                ))}
                <Divider sx={{ my: 1 }} variant="horizontal" />
                {exampleReceipt.calculateReceipt.map((item, index) => (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                    key={index}
                  >
                    <Typography sx={(theme) => ({ fontSize: 12 })}>
                      {item.name}
                    </Typography>
                    <Typography sx={(theme) => ({ fontSize: 12 })}>
                      {index === 0 ? (
                        item.value
                      ) : (
                        <NumberDecimal number={item.value} decimal={2} />
                      )}
                    </Typography>
                  </Box>
                ))}
                {configBranch.service_charge === 0 ? null : (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography sx={(theme) => ({ fontSize: 12 })}>
                      Service Charge {`${configBranch.service_charge}%`}
                    </Typography>
                    <Typography sx={(theme) => ({ fontSize: 12 })}>
                      <NumberDecimal
                        number={
                          ((exampleReceipt.calculateReceipt[1].value +
                            exampleReceipt.calculateReceipt[2].value) *
                            configBranch.service_charge) /
                          100
                        }
                        decimal={2}
                      />
                    </Typography>
                  </Box>
                )}
                {configBranch.vat_type === "exclude" ? (
                  <>
                    {configBranch.vat === 0 ? null : (
                      <>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography sx={(theme) => ({ fontSize: 12 })}>
                            Total Before VAT
                          </Typography>
                          <Typography sx={(theme) => ({ fontSize: 12 })}>
                            <NumberDecimal
                              number={
                                exampleReceipt.calculateReceipt[1].value +
                                exampleReceipt.calculateReceipt[2].value +
                                ((exampleReceipt.calculateReceipt[1].value +
                                  exampleReceipt.calculateReceipt[2].value) *
                                  configBranch.service_charge) /
                                  100
                              }
                              decimal={2}
                            />
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography sx={(theme) => ({ fontSize: 12 })}>
                            VAT {`${configBranch.vat}%`}
                          </Typography>
                          <Typography sx={(theme) => ({ fontSize: 12 })}>
                            <NumberDecimal
                              number={
                                ((exampleReceipt.calculateReceipt[1].value +
                                  exampleReceipt.calculateReceipt[2].value +
                                  ((exampleReceipt.calculateReceipt[1].value +
                                    exampleReceipt.calculateReceipt[2].value) *
                                    configBranch.service_charge) /
                                    100) *
                                  configBranch.vat) /
                                100
                              }
                              decimal={2}
                            />
                          </Typography>
                        </Box>
                      </>
                    )}
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography sx={(theme) => ({ fontSize: 12 })}>
                        Rounding
                      </Typography>
                      <Typography sx={(theme) => ({ fontSize: 12 })}>
                        <NumberDecimal
                          number={
                            Math.ceil(
                              exampleReceipt.calculateReceipt[1].value +
                                exampleReceipt.calculateReceipt[2].value +
                                ((exampleReceipt.calculateReceipt[1].value +
                                  exampleReceipt.calculateReceipt[2].value) *
                                  configBranch.service_charge) /
                                  100 +
                                ((exampleReceipt.calculateReceipt[1].value +
                                  exampleReceipt.calculateReceipt[2].value +
                                  ((exampleReceipt.calculateReceipt[1].value +
                                    exampleReceipt.calculateReceipt[2].value) *
                                    configBranch.service_charge) /
                                    100) *
                                  configBranch.vat) /
                                  100
                            ) -
                            (exampleReceipt.calculateReceipt[1].value +
                              exampleReceipt.calculateReceipt[2].value +
                              ((exampleReceipt.calculateReceipt[1].value +
                                exampleReceipt.calculateReceipt[2].value) *
                                configBranch.service_charge) /
                                100 +
                              ((exampleReceipt.calculateReceipt[1].value +
                                exampleReceipt.calculateReceipt[2].value +
                                ((exampleReceipt.calculateReceipt[1].value +
                                  exampleReceipt.calculateReceipt[2].value) *
                                  configBranch.service_charge) /
                                  100) *
                                configBranch.vat) /
                                100)
                          }
                          decimal={2}
                        />
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography sx={(theme) => ({ fontSize: 12 })}>
                        Grand Total
                      </Typography>
                      <Typography sx={(theme) => ({ fontSize: 12 })}>
                        <NumberDecimal
                          number={Math.ceil(
                            exampleReceipt.calculateReceipt[1].value +
                              exampleReceipt.calculateReceipt[2].value +
                              ((exampleReceipt.calculateReceipt[1].value +
                                exampleReceipt.calculateReceipt[2].value) *
                                configBranch.service_charge) /
                                100 +
                              ((exampleReceipt.calculateReceipt[1].value +
                                exampleReceipt.calculateReceipt[2].value +
                                ((exampleReceipt.calculateReceipt[1].value +
                                  exampleReceipt.calculateReceipt[2].value) *
                                  configBranch.service_charge) /
                                  100) *
                                configBranch.vat) /
                                100
                          )}
                          decimal={2}
                        />
                      </Typography>
                    </Box>{" "}
                    <Divider sx={{ my: 1 }} variant="horizontal" />
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography sx={(theme) => ({ fontSize: 12 })}>
                        Cash
                      </Typography>
                      <Typography sx={(theme) => ({ fontSize: 12 })}>
                        <NumberDecimal number={1500} decimal={2} />
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography sx={(theme) => ({ fontSize: 12 })}>
                        Change
                      </Typography>
                      <Typography sx={(theme) => ({ fontSize: 12 })}>
                        <NumberDecimal
                          number={
                            1500 -
                            Math.ceil(
                              exampleReceipt.calculateReceipt[1].value +
                                exampleReceipt.calculateReceipt[2].value +
                                ((exampleReceipt.calculateReceipt[1].value +
                                  exampleReceipt.calculateReceipt[2].value) *
                                  configBranch.service_charge) /
                                  100 +
                                ((exampleReceipt.calculateReceipt[1].value +
                                  exampleReceipt.calculateReceipt[2].value +
                                  ((exampleReceipt.calculateReceipt[1].value +
                                    exampleReceipt.calculateReceipt[2].value) *
                                    configBranch.service_charge) /
                                    100) *
                                  configBranch.vat) /
                                  100
                            )
                          }
                          decimal={2}
                        />
                      </Typography>
                    </Box>
                  </>
                ) : (
                  <>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography sx={(theme) => ({ fontSize: 12 })}>
                        Rounding
                      </Typography>
                      <Typography sx={(theme) => ({ fontSize: 12 })}>
                        <NumberDecimal
                          number={
                            Math.ceil(
                              exampleReceipt.calculateReceipt[1].value +
                                exampleReceipt.calculateReceipt[2].value +
                                ((exampleReceipt.calculateReceipt[1].value +
                                  exampleReceipt.calculateReceipt[2].value) *
                                  configBranch.service_charge) /
                                  100
                            ) -
                            (exampleReceipt.calculateReceipt[1].value +
                              exampleReceipt.calculateReceipt[2].value +
                              ((exampleReceipt.calculateReceipt[1].value +
                                exampleReceipt.calculateReceipt[2].value) *
                                configBranch.service_charge) /
                                100)
                          }
                          decimal={2}
                        />
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography sx={(theme) => ({ fontSize: 12 })}>
                        Grand Total
                      </Typography>
                      <Typography sx={(theme) => ({ fontSize: 12 })}>
                        <NumberDecimal
                          number={Math.ceil(
                            exampleReceipt.calculateReceipt[1].value +
                              exampleReceipt.calculateReceipt[2].value +
                              ((exampleReceipt.calculateReceipt[1].value +
                                exampleReceipt.calculateReceipt[2].value) *
                                configBranch.service_charge) /
                                100
                          )}
                          decimal={2}
                        />
                      </Typography>
                    </Box>
                    <Divider sx={{ my: 1 }} variant="horizontal" />
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography sx={(theme) => ({ fontSize: 12 })}>
                        Cash
                      </Typography>
                      <Typography sx={(theme) => ({ fontSize: 12 })}>
                        <NumberDecimal number={1500} decimal={2} />
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography sx={(theme) => ({ fontSize: 12 })}>
                        Change
                      </Typography>
                      <Typography sx={(theme) => ({ fontSize: 12 })}>
                        <NumberDecimal
                          number={
                            1500 -
                            Math.ceil(
                              exampleReceipt.calculateReceipt[1].value +
                                exampleReceipt.calculateReceipt[2].value +
                                ((exampleReceipt.calculateReceipt[1].value +
                                  exampleReceipt.calculateReceipt[2].value) *
                                  configBranch.service_charge) /
                                  100
                            )
                          }
                          decimal={2}
                        />
                      </Typography>
                    </Box>
                  </>
                )}

                {configShow.vat &&
                configBranch.vat_type === "include" &&
                configBranch.vat !== 0 ? (
                  <>
                    <Divider sx={{ my: 1 }} variant="horizontal" />
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography sx={(theme) => ({ fontSize: 12 })}>
                        Total Before VAT
                      </Typography>
                      <Typography sx={(theme) => ({ fontSize: 12 })}>
                        <NumberDecimal
                          number={
                            (Math.ceil(
                              exampleReceipt.calculateReceipt[1].value +
                                exampleReceipt.calculateReceipt[2].value +
                                ((exampleReceipt.calculateReceipt[1].value +
                                  exampleReceipt.calculateReceipt[2].value) *
                                  configBranch.service_charge) /
                                  100
                            ) *
                              100) /
                            (100 + configBranch.vat)
                          }
                          decimal={2}
                        />
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography sx={(theme) => ({ fontSize: 12 })}>
                        VAT {`${configBranch.vat}%`}
                      </Typography>
                      <Typography sx={(theme) => ({ fontSize: 12 })}>
                        <NumberDecimal
                          number={
                            (((Math.ceil(
                              exampleReceipt.calculateReceipt[1].value +
                                exampleReceipt.calculateReceipt[2].value +
                                ((exampleReceipt.calculateReceipt[1].value +
                                  exampleReceipt.calculateReceipt[2].value) *
                                  configBranch.service_charge) /
                                  100
                            ) *
                              100) /
                              (100 + configBranch.vat)) *
                              configBranch.vat) /
                            100
                          }
                          decimal={2}
                        />
                      </Typography>
                    </Box>
                  </>
                ) : null}
                <Divider sx={{ my: 1 }} variant="horizontal" />
                <Box align="center">
                  <Typography
                    sx={{
                      wordBreak: "break-word",
                      fontSize: 12,
                    }}
                  >
                    {watch("textFooter1")}
                  </Typography>
                  {imageQRCode ? (
                    <>
                      <img
                        src={
                          typeof imageQRCode === "string"
                            ? imageQRCode
                            : typeof imageQRCode === "object"
                            ? URL.createObjectURL(imageQRCode)
                            : null
                        }
                        alt="showImage"
                        style={{
                          width: "100px",
                          height: "100px",

                          objectFit: "cover",
                        }}
                      />
                    </>
                  ) : (
                    <></>
                  )}
                  <Typography
                    sx={{
                      wordBreak: "break-word",
                      fontSize: 12,
                    }}
                  >
                    {watch("textFooter2")}
                  </Typography>
                </Box>
              </Box>
            </Paper>
          </Box>
        </BoxWrapper>
      )}
    </div>
  );
}

export default BranchReceipt;
