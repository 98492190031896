
import axios from "axios";
import {
  AccessTokenAPI,
  BusinessId,
  CompanyId,
  ServiceId,
} from "../../getLocalStorage";

export const getSelfPickupLineProvider = async () => {
  var config = {
    method: "get",
    url: `${process.env.REACT_APP_API_URL}/api/setting/selfpickup/getSelfPickupLineProvider?company_uid=${CompanyId}&business_uid=${BusinessId}&service_code=${ServiceId}`,
    headers: {
      Authorization: `Bearer ${AccessTokenAPI}`,
    },
  };

  return axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      console.log(error);
    });
};
