import React, { useState, useEffect, useContext } from "react";
import { Box } from "@mui/material";
// import OptionsQRCode from "./paymentOptions/optionsQRCode";
import ConfirmButton from "../../components/ui/button/confirmButton";

import TitleForm from "../../components/ui/formComponent/titleForm";
// import OptionsCreditCard from "./paymentOptions/optionsCreditCard";
import { useTranslation } from "react-i18next";

import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {
  addPaymentTypeToTemplate,
  getAllPaymentTypeTemplate,
  getPaymentType,
  updatePaymentType,
} from "../../service/paymentTypeAPI";
import { notifySuccess } from "../../components/ui/popup/toastPopup";
import { getAllBranch } from "../../service/branchAPI";
import Swal from "sweetalert2";
import BreadcrumbsTitle from "../../components/ui/breadcrumbsTitle";
import StatusForm from "../../components/ui/formComponent/statusForm";
import Loading from "../../components/ui/loading";
import SettingTypeBranch from "../../components/ui/addTypeTemplate/settingTypeBranch";
import SelectComponent from "../../components/ui/textfield/selectComponent";
import paymentData from "../../components/utils/payment.json";
import { useSchema } from "../../components/hooks/use-schema";
import { paymentTypeSchema } from "../../components/validation/paymentTypeSchema";
import paymentAll from "../../components/utils/payment.json";
import PaperWrapper from "../../components/ui/paper/paperWrapper";
import { GridContainerSize } from "../../components/utils/gridContainerSize";
import { getImage } from "../../service/upload/getImage";
import { UseConvertImageToBase64 } from "../../components/hooks/use-convert-image-to-base-64";
import { BoxWrapper } from "../../components/ui/box/boxWrapper";
import { BoxFormTitleAction } from "../../components/ui/box/boxFormTitleAction";
import TitleHeaderForm from "../../components/ui/formComponent/titleHeaderForm";
import { BoxGapInput } from "../../components/ui/box/boxGapInput";
import { MyContext } from "../../context/MyContext";

function PaymentTypeDetail() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    control,
    watch,
  } = useForm(useSchema(paymentTypeSchema));
  const [isLoading, setIsLoading] = useState(true);
  const [paymentTypeShow, setPaymentTypeShow] = useState("");
  const [statusButton, setStatusButton] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [allDataTemplate, setAllDataTemplate] = useState([]);
  const { fetchPaymentType } = useContext(MyContext);

  const [dataAll, setDataAll] = useState([]);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const paramsId =
    window.location.pathname.split("/")[
      window.location.pathname.split("/").length - 1
    ];
  const fetchData = async () => {
    // detail
    let statusCate = await fetchDataGetPaymentType();
    // branch
    if (statusCate) {
      await fetchAllBranch();
    }
    await setIsLoading(false);
  };

  const fetchDataGetPaymentType = async () => {
    let dataAPI = await getPaymentType(paramsId);
    let { status, type, image, payment_type_lang } = dataAPI;
    setDataAll(dataAPI);
    setStatusButton(status);
    let paymentName = paymentAll.find(
      (itemPayment) => itemPayment.type.toLowerCase() === type.toLowerCase()
    );
    if (paymentName) {
      setPaymentTypeShow(paymentName.id);
      setValue("paymentType", paymentName.id);
    } else {
      setPaymentTypeShow("");
      setValue("paymentType", "");
    }
    if (image) {
      let imageUrl = await getImage(image);
      image = UseConvertImageToBase64(imageUrl);
      await Promise.all(imageUrl);
      setValue("image", image);
    }
    setValue("name", payment_type_lang.name);
    setValue("altName", payment_type_lang.alt_name);
    return status;
  };
  const onConfirmForm = async (register) => {
    setIsSubmitting(true);

    let paymentName = paymentAll.find(
      (itemPayment) => itemPayment.id === paymentTypeShow
    );
    let dataAPI = {
      id: String(dataAll.id),
      type: paymentName.type,
      image: "",
      status: statusButton,
      payment_type_lang: {
        name: register.name,
        alt_name: register.altName,
        language_code: String(localStorage.getItem("language_form")),
      },
    };
    let statusAPI = await updatePaymentType(dataAPI);
    if (statusAPI === "OK") {
      notifySuccess({
        title: t("notification_successfully_edited_information"),
      });
      fetchPaymentType();
      navigate("/payment-type");
    }
    await new Promise((resolve) => setTimeout(resolve, 2000));
    setIsSubmitting(false);
  };
  const onAddPaymentTypeToTemplate = async () => {
    let branchArray = allDataTemplate.filter((item) => item.select === true);
    let dataSelect = [];
    branchArray.forEach((item) => {
      dataSelect.push(item.branch.id);
    });
    let dataAPI = {
      payment_type_id: dataAll.id,
      branches: dataSelect,
      priority: 1,
    };
    let dataStatus = await addPaymentTypeToTemplate(dataAPI);
    if (dataStatus === "OK") {
      notifySuccess({
        title: t("notification_successfully_edited_information"),
      });
      fetchAllBranch();
    }
  };
  const fetchAllBranch = async () => {
    let dataAPI = await getAllBranch();
    dataAPI = dataAPI.filter((item) => item.branch_lang !== null);
    await fetchSettingSelectTemplate(dataAPI);
  };
  const fetchSettingSelectTemplate = async (dataBranch) => {
    let dataAPI = await getAllPaymentTypeTemplate(paramsId);
    dataAPI = dataAPI[0].template_payment_types;
    let dataBox = [];
    dataBranch.forEach((item) => {
      let dataNews = dataAPI.filter(
        (itemTemplate) => itemTemplate.template_id === item.template_id
      );
      if (dataNews.length > 0) {
        dataBox.push({
          branch: item,
          data: dataNews[0],
          select: false,
        });
      } else {
        dataBox.push({
          branch: item,
          data: null,
          select: false,
        });
      }
    });
    await setAllDataTemplate(dataBox);
  };
  const onPaymentTypeShow = (item) => {
    setValue("paymentType", item);
    clearErrors();
    if (item === 1) {
      setValue("name", "Cash");
      setValue("altName", "Cash");
    }
    if (item === 2) {
      setValue("name", "Bank Transfer");
      setValue("altName", "Bank Transfer");
    }
    if (item === 3) {
      setValue("name", "Card");
      setValue("altName", "Card");
    }

    if (item === 4) {
      setValue("name", "Voucher");
      setValue("altName", "Voucher");
    }

    if (item === 5) {
      setValue("name", "E-Wallet");
      setValue("altName", "E-Wallet");
    }

    if (item === 6) {
      setValue("name", "Delivery");
      setValue("altName", "Delivery");
    }

    if (item === 7) {
      setValue("name", "NETS");
      setValue("altName", "NETS");
    }
    if (item === 8) {
      setValue("name", "Cash Card");
      setValue("altName", "Cash Card");
    }
    if (item === 9) {
      setValue("name", "Other");
      setValue("altName", "Other");
    }
    setPaymentTypeShow(item);
  };

  const onConfirmStatusChange = async (checked) => {
    if (checked) {
      setStatusButton(checked);
    } else {
      Swal.fire({
        title: t("notification_confirm_change_status"),
        text: t(
          "notification_changing_the_status_will_affect_the_branch_settings"
        ),
        icon: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          setStatusButton(checked);
          //sss
        }
      });
    }
  };
  useEffect(() => {
    localStorage.removeItem("uploadImage");
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box>
      <BreadcrumbsTitle
        props={{
          title: t("payment_type_info"),
          dataLink: [
            { text: t("payment_type"), link: "/payment-type" },
            { text: t("payment_type_info"), link: null },
          ],
        }}
      />
      {isLoading ? (
        <>
          <Loading />
        </>
      ) : (
        <GridContainerSize>
          <Box p={2}>
            <form onSubmit={handleSubmit(onConfirmForm)}>
              <BoxWrapper>
                <PaperWrapper props={{ growFull: 1 }}>
                  <BoxFormTitleAction>
                    <TitleHeaderForm props={{ text: t("payment_type") }} />
                    {paymentTypeShow === 1 || paymentTypeShow === 4 ? null : (
                      <StatusForm
                        statusButton={statusButton}
                        setStatusButton={onConfirmStatusChange}
                      />
                    )}
                  </BoxFormTitleAction>
                  <BoxGapInput>
                    <Box className="boxWrapper-container">
                      <PaperWrapper props={{ growFull: null }}>
                        <TitleForm props={{ text: t("payment_type_info") }} />
                        <Box p={2}>
                          <SelectComponent
                            title={t("payment_type")}
                            register={register}
                            name="paymentType"
                            errors={errors}
                            control={control}
                            setValue={setValue}
                            clearErrors={clearErrors}
                            dataEmpty={t("payment_type_select")}
                            optionsData={paymentData}
                            dataValue={paymentTypeShow}
                            onFunctionName={onPaymentTypeShow}
                            disabled={true}
                            watch={watch}
                            fullGrid={true}
                          />
                        </Box>
                      </PaperWrapper>
                      <PaperWrapper props={{ growFull: 1 }}>
                        <SettingTypeBranch
                          statusData={statusButton}
                          title={t("payment_type_branch")}
                          allDataTemplate={allDataTemplate}
                          setAllDataTemplate={setAllDataTemplate}
                          onAddTypeToTemplate={onAddPaymentTypeToTemplate}
                          directToBranch={"paymentType"}
                        />
                      </PaperWrapper>
                    </Box>
                    <ConfirmButton disabled={isSubmitting} />
                  </BoxGapInput>
                </PaperWrapper>
              </BoxWrapper>
            </form>
          </Box>
        </GridContainerSize>
      )}
    </Box>
  );
}

export default PaymentTypeDetail;
