import React from "react";
import { Box, Button, CircularProgress } from "@mui/material";
import { t } from "i18next";
import CheckIcon from "@mui/icons-material/Check";
function ConfirmButton(props) {
  const { onClick, disabled, text } = props;
  return (
    <Box sx={{ display: "flex", gap: 2, mt: 2, justifyContent: "flex-end" }}>
      <Button
        variant="contained"
        type={onClick ? "button" : "submit"}
        color="success"
        onClick={onClick}
        sx={{ color: "#fff", borderRadius: 2 }}
        disabled={disabled}
        // fullWidth
        startIcon={
          disabled ? (
            <CircularProgress size="1rem" sx={{ color: "#bbb" }} />
          ) : (
            <CheckIcon sx={{ fontSize: 12 }} />
          )
        }
        // startIcon={disabled ? <></> : <CheckIcon sx={{ fontSize: 12 }} />}
      >
        {text ? text : t("confirm_button")}
      </Button>
    </Box>
  );
}

export default ConfirmButton;
