import React from "react";
import moment from "moment";
import { Box, Typography, Switch, Button, FormHelperText } from "@mui/material";
import ImageNotFound from "../../../../assets/images/imageNotFound.jpeg";
import DateRangeIcon from "@mui/icons-material/DateRange";
import { t } from "i18next";
import { BoxImageTable } from "../../../../components/ui/boxImage/boxImageTable";
function PromotionCard({ props }) {
  let {
    onDirectToDetail,
    itemPromotion,
    onUpdateConfirm,
    disabled,
    indexPromotion,
  } = props;
  const textTitle = (text) => {
    return (
      <>
        <Typography
          sx={(theme) => ({
            fontWeight: "bold",
            height: "70px",
            color: theme.palette.primary.main,
            [theme.breakpoints.up("xs")]: {
              fontSize: 16,
            },
            [theme.breakpoints.up("sm")]: {
              fontSize: 18,
            },
            [theme.breakpoints.up("lg")]: {
              fontSize: 16,
            },
          })}
          className="line-clamp-promotion"
        >
          {text}
        </Typography>
      </>
    );
  };
  return (
    <>
      <Box
        // item
        // xs={12}
        // sm={4}
        // lg={2.4}
        // xl={2}
        sx={(theme) => ({
          boxSizing: "border-box",
          p: 2,
          position: "relative",
          // width: "100%",
        })}
      >
        <Box
          sx={(theme) => ({
            boxShadow: "0px 3px 4px 0px rgba(0, 0, 0, .03)",
            borderRadius: "0.75rem",
            border: "1px solid #F1F1F1",
            backgroundColor: "#fff",
          })}
        >
          {itemPromotion.image ? (
            <BoxImageTable
              image={itemPromotion.image}
              height={256}
              // type={"table"}
            />
          ) : (
            <img src={ImageNotFound} alt="example" width={"100%"} />
          )}
          <Box
            sx={(theme) => ({
              justifyContent: "space-between",
              gap: 1,
              alignItems: "center",
              boxSizing: "border-box",
              p: 2,
            })}
          >
            {textTitle(itemPromotion.name)}
            <Box sx={{ display: "flex", gap: 2 }}>
              <Box
                sx={(theme) => ({
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: 1,
                  color: "#00000080",
                })}
              >
                <DateRangeIcon />
                <Typography
                  sx={(theme) => ({
                    [theme.breakpoints.up("xs")]: {
                      fontSize: 16,
                    },
                    [theme.breakpoints.up("sm")]: {
                      fontSize: 13,
                    },
                    [theme.breakpoints.up("lg")]: {
                      fontSize: 13,
                    },
                  })}
                >
                  {itemPromotion.end !== "" ? (
                    <>
                      {moment(itemPromotion.start, "YYYYMMDDHHmmss").format(
                        "DD/MM/YYYY"
                      )}{" "}
                      -{" "}
                      {moment(itemPromotion.end, "YYYYMMDDHHmmss").format(
                        "DD/MM/YYYY"
                      )}
                    </>
                  ) : (
                    <>{t("global_un_limit")}</>
                  )}
                </Typography>
              </Box>
            </Box>
            <Box
              align="end"
              sx={{
                mt: 1,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Button
                variant="outlined"
                size="small"
                onClick={() => onDirectToDetail(itemPromotion.promotion_id)}
                sx={{ borderRadius: 2 }}
              >
                Detail
              </Button>
              <Switch
                onChange={(e) =>
                  onUpdateConfirm(itemPromotion, e, indexPromotion)
                }
                checked={itemPromotion.status}
                disabled={disabled ? disabled : false}
              />
            </Box>
          </Box>
        </Box>
        {itemPromotion.statusPromotion === false ? (
          <FormHelperText sx={{ textAlign: "center" }}>
            {t("notification_status_setting_is_closed")}
          </FormHelperText>
        ) : null}
      </Box>
    </>
  );
}

export default PromotionCard;
