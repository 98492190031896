import { Box } from "@mui/material";
import React from "react";
import PaperWrapper from "../../../components/ui/paper/paperWrapper";
import TitleForm from "../../../components/ui/formComponent/titleForm";
import { PaddingContainer } from "../../../components/utils/paddingContainer";
import { t } from "i18next";
import TextfieldComponent from "../../../components/ui/textfield/textfieldComponent";
import StatusForm from "../../../components/ui/formComponent/statusForm";
import ConfirmButton from "../../../components/ui/button/confirmButton";
import ImageCrop from "../../../components/ui/upload/imageCrop";
import { BoxWrapper } from "../../../components/ui/box/boxWrapper";

function TableListItemForm({ props }) {
  const {
    handleSubmit,
    onConfirmForm,
    register,
    errors,
    statusButton,
    setStatusButton,
    isSubmitting,
    image,
    setImage,
  } = props;
  return (
    <Box p={2} sx={{ width: "50%", margin: "auto" }}>
      <form onSubmit={handleSubmit(onConfirmForm)}>
        <Box className="boxWrapper-container">
          <BoxWrapper>
            <PaperWrapper props={{ growFull: null }}>
              <TitleForm props={{ text: t("table_image") }} />
              <Box align="center">
                <ImageCrop
                  props={{
                    setImage,
                    image,
                    heightCrop: 256,
                    widthCrop: 256,
                    label: "staffPOS",
                  }}
                />
              </Box>
            </PaperWrapper>
            <PaperWrapper props={{ growFull: 1 }}>
              <TitleForm props={{ text: t("table_info") }} />
              <Box p={PaddingContainer.size}>
                <TextfieldComponent
                  title={t("table_name")}
                  name="tableName"
                  register={register}
                  placeholder={t("table_name_example")}
                  errors={errors}
                  fullGrid={true}
                />
                <TextfieldComponent
                  title={t("table_amount")}
                  name="amount"
                  register={register}
                  placeholder={t("E.g. 4")}
                  errors={errors}
                  fullGrid={true}
                />
              </Box>
            </PaperWrapper>
          </BoxWrapper>
          <StatusForm
            statusButton={statusButton}
            setStatusButton={setStatusButton}
          />
          <ConfirmButton disabled={isSubmitting} />
        </Box>
      </form>
    </Box>
  );
}

export default TableListItemForm;
