import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import React from "react";
import TextfieldComponent from "../../../../components/ui/textfield/textfieldComponent";
import { t } from "i18next";
import StatusForm from "../../../../components/ui/formComponent/statusForm";
import ConfirmButton from "../../../../components/ui/button/confirmButton";
import CloseIcon from "@mui/icons-material/Close";
import { Delete } from "@mui/icons-material";

function BranchCreateManageZoneForm({ props }) {
  let {
    dialogAddZone,
    handleSubmit,
    onCreateZone,
    register,
    errors,
    statusButton,
    setStatusButton,
    isSubmitting,
    onCloseZoneForm,
    onEditZone,
    onRemoveZone,
  } = props;
  return (
    <Dialog open={dialogAddZone.status} maxWidth="sm" fullWidth>
      <DialogTitle>
        {dialogAddZone.mode === "create"
          ? t("table_add_zone")
          : t("table_edit_zone")}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => onCloseZoneForm()}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Box>
          <form
            onSubmit={handleSubmit(
              dialogAddZone.mode === "create" ? onCreateZone : onEditZone
            )}
          >
            <TextfieldComponent
              title={t("table_zone_name")}
              name="zoneName"
              register={register}
              placeholder={t("table_zone_name_example")}
              errors={errors}
              fullGrid={true}
            />
            <StatusForm
              statusButton={statusButton}
              setStatusButton={setStatusButton}
            />
            <ConfirmButton disabled={isSubmitting} />
            {dialogAddZone.mode === "edit" ? (
              <Button
                variant="standard"
                color="error"
                startIcon={<Delete />}
                sx={(theme) => ({
                  color: theme.palette.error.main,
                  borderRadius: 2,
                  fontSize: 16,
                })}
                fullWidth
                onClick={() => onRemoveZone(dialogAddZone.data)}
              >
                Remove
              </Button>
            ) : null}
          </form>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default BranchCreateManageZoneForm;
