import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import React from "react";
import SelectLanguage from "../sidebar/components/selectLanguage";

function BreadcrumbsTitle({ props }) {
  let { title, dataLink } = props;
  const navigate = useNavigate();
  return (
    <Box sx={(theme) => ({ position: "relative" })}>
      <Box
        sx={(theme) => ({
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          px: 2,
          py: 1,
          boxSizing: "border-box",
          height: "64px",
        })}
      ></Box>
      <Box
        sx={(theme) => ({
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          px: 2,
          py: 1,
          boxSizing: "border-box",
          position: "absolute",
          top: 0,
          backgroundColor: theme.palette.white.main,
          boxShadow:
            "0 0 #000, 0 0 #0000,0 0 #0000, 0 0 #0000,0 1px 2px 0 rgb(0 0 0 / .05)",
          zIndex: 99,
          height: "64px",
        })}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          <Box>
            {dataLink ? (
              <Box
                sx={{
                  display: "flex",
                  gap: 2,
                  alignItems: "center",
                  width: "100%",
                }}
              >
                {dataLink.map((itemLink, index) => (
                  <>
                    <Typography
                      sx={(theme) => ({
                        [theme.breakpoints.down("sm")]: {
                          fontSize: 20,
                        },
                        [theme.breakpoints.up("md")]: {
                          fontSize: 15,
                        },
                        [theme.breakpoints.up("lg")]: {
                          fontSize: 18,
                        },
                        fontWeight: "500",
                        textTransform: "capitalize",
                        fontFamily: "Kanit",
                        color: itemLink.link
                          ? theme.palette.secondaryText.main
                          : theme.palette.primaryText.main,
                        cursor: itemLink.link ? "pointer" : "default",
                      })}
                      onClick={() => navigate(itemLink.link)}
                    >
                      {itemLink.text}
                    </Typography>
                    {dataLink.length - 1 !== index && (
                      <Typography
                        sx={(theme) => ({
                          color: theme.palette.secondaryText.main,
                        })}
                      >
                        {">"}
                      </Typography>
                    )}
                  </>
                ))}
              </Box>
            ) : (
              <Typography
                sx={(theme) => ({
                  [theme.breakpoints.down("sm")]: {
                    fontSize: 20,
                  },
                  [theme.breakpoints.up("md")]: {
                    fontSize: 15,
                  },
                  [theme.breakpoints.up("lg")]: {
                    fontSize: 18,
                  },
                  fontWeight: "500",
                  textTransform: "capitalize",
                  fontFamily: "Kanit",
                  color: theme.palette.primaryText.main,
                })}
              >
                {title}
              </Typography>
            )}
          </Box>
        </Box>
        <Box>
          <SelectLanguage />
        </Box>
      </Box>
    </Box>
  );
}

export default BreadcrumbsTitle;
