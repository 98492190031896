import { Box } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";

import { t } from "i18next";
import dayjs from "dayjs";
import BreadcrumbsTitle from "../../../components/ui/breadcrumbsTitle";
import SelectCheckMarkBranch from "../../../components/ui/select/selectCheckMarkBranch";
import { TextTable } from "../../../components/ui/textfield/textfieldCustom";
import DetailBillReceipt from "./detailBillReceipt";
import { UseConvertImageToBase64 } from "../../../components/hooks/use-convert-image-to-base-64";
import { getImage } from "../../../service/upload/getImage";
import { useLocation } from "react-router-dom";
import { MyContext } from "../../../context/MyContext";
import DataTableReport from "../../../components/ui/dataTable/dataTableReport";
import i18n from "../../../locals/i18n";

function ReportByBill() {
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const location = useLocation();
  const [exportColumnName, setExportColumnName] = useState(null);
  const [dialogData, setDialogData] = useState({ status: false, data: null });
  const {
    setBranchSelectReport,
    branchSelectReportDate,
    reportAll,
    fetchReport,
  } = useContext(MyContext);

  const [isLoadingData, setIsLoadingData] = useState(
    reportAll.length > 0 ? false : true
  );
  const { state } = location;
  const onDialogBill = async (item) => {
    let { receipt_config_receipt } = item.data;
    if (receipt_config_receipt) {
      let { config_receipt_lang } = receipt_config_receipt;
      let { logo, img_footer } = config_receipt_lang;
      setDialogData({ status: true, data: item.data });
      if (logo.includes("data:image") === false) {
        if (item.data.receipt_config_receipt.config_receipt_lang.logo) {
          item.data.receipt_config_receipt.config_receipt_lang.logo =
            UseConvertImageToBase64(await getImage(logo));
        }
        if (item.data.receipt_config_receipt.config_receipt_lang.img_footer) {
          item.data.receipt_config_receipt.config_receipt_lang.img_footer =
            UseConvertImageToBase64(await getImage(img_footer));
        }
        await setDialogData({ status: true, data: item.data });
      } else {
        await setDialogData({ status: true, data: item.data });
      }
    } else {
      setDialogData({ status: true, data: item.data });
    }
  };
  const handleClose = () => {
    setDialogData({ status: false, data: null });
  };
  const fetchData = async () => {
    let headers = [];
    let dataBox = [];
    if (reportAll) {
      reportAll.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));
      reportAll.forEach((item) => {
        let sumDiscountByItems = 0;
        item.receipt_sale_products.forEach((product) => {
          product.receipt_sale_product_promotions.forEach(
            (itemSaleProductPromotion) =>
              (sumDiscountByItems += Number(itemSaleProductPromotion.price))
          );
        });
        let sumDiscountTypeOnePromotion = 0;
        item.receipt_promotions?.forEach((promotion) => {
          if (promotion.discount_type === 1) {
            sumDiscountTypeOnePromotion += promotion.price;
          }
        });
        let receiptDiscount = item.receipt_discount
          ? item.receipt_discount.total_cash
          : 0;

        dataBox.push({
          invNo: item.invoice_number,
          data: item,
          paymentDate: dayjs(item.datetime).format("DD/MM/YYYY"),
          paymentTime: dayjs(item.datetime).format("HH:mm"),

          summaryPrice: item.sub_total,
          discountByItems: sumDiscountByItems,
          subTotalBillDiscount: Number(
            sumDiscountTypeOnePromotion + receiptDiscount
          ),
          subTotal: item.total_after_discount,
          serviceCharge: item.total_service_charge,
          nonVat: Number(item.total_non_vat),
          exVat: Number(item.total_ex_vat),
          beforeVat: Number(item.total_before_vat_after_service_charge),
          vat: Number(item.total_vat),
          voucherAmount: item.receipt_payment_types.find(
            (item) => item.type === "voucher"
          )
            ? item.receipt_payment_types.find((item) => item.type === "voucher")
                .received
            : 0,
          voucherDiscount: item.receipt_payment_types.find(
            (item) => item.type === "voucher"
          )
            ? item.receipt_payment_types.find((item) => item.type === "voucher")
                .received > item.grand_total
              ? item.grand_total
              : item.receipt_payment_types.find(
                  (item) => item.type === "voucher"
                ).received
            : 0,
          roundingAmount: Number(item.total_round_up),
          total: item.grand_total,
          refund: item.receipt_status === "void" ? item.grand_total : 0,
          orderType: item.type,
          posId: item.receipt_config_receipt
            ? item.receipt_config_receipt.config_receipt_lang.pos_id_number
            : null,
          drawerId: item.open_close_uid
            .substring(
              item.open_close_uid.length - 5,
              item.open_close_uid.length
            )
            .toUpperCase(),
          paymentType: [
            ...new Set(item.receipt_payment_types.map((item) => t(item.type))),
          ],
          paymentTypeChannel:
            item.receipt_payment_types[0]?.receipt_payment_channels.length > 0
              ? item.receipt_payment_types.map(
                  (item) => item.receipt_payment_channels[0].name
                )
              : null,
          seatAmount: item.type === "Take Away" ? 1 : 0,
          billOpenBy: item.created_by_name,
          billCloseBy: item.created_by_name,
          branchName: item.receipt_config_receipt
            ? item.receipt_config_receipt.config_receipt_lang.branch_name
            : null,
        });
      });
      if (state) {
        dataBox.filter((itemPayment) =>
          itemPayment.paymentType.some((itemPayment) => itemPayment.paymentType)
        );
      }
      dataBox.sort(
        (a, b) =>
          dayjs(b.paymentDate, "DD/MM/YYYY") -
          dayjs(a.paymentDate, "DD/MM/YYYY")
      );

      // dataBox.push({
      //   invNo: "Total",
      //   paymentDate: null,
      //   paymentTime: null,
      //   summaryPrice: dataBox.reduce((acc, item) => acc + item.summaryPrice, 0),
      //   discountByItems: dataBox.reduce(
      //     (acc, item) => acc + item.discountByItems,
      //     0
      //   ),
      //   subTotalBillDiscount: dataBox.reduce(
      //     (acc, item) => acc + item.subTotalBillDiscount,
      //     0
      //   ),
      //   subTotal: dataBox.reduce((acc, item) => acc + item.subTotal, 0),
      //   serviceCharge: dataBox.reduce(
      //     (acc, item) => acc + item.serviceCharge,
      //     0
      //   ),
      //   nonVat: dataBox.reduce((acc, item) => acc + Number(item.nonVat), 0),
      //   exVat: dataBox.reduce(
      //     (acc, item) => Number(acc) + Number(item.exVat),
      //     0
      //   ),
      //   beforeVat: dataBox.reduce(
      //     (acc, item) => acc + Number(item.beforeVat),
      //     0
      //   ),
      //   vat: dataBox.reduce((acc, item) => acc + Number(item.vat), 0),
      //   voucherAmount: dataBox.reduce(
      //     (acc, item) => acc + item.voucherAmount,
      //     0
      //   ),
      //   voucherDiscount: dataBox.reduce(
      //     (acc, item) => acc + item.voucherDiscount,
      //     0
      //   ),
      //   roundingAmount: dataBox.reduce(
      //     (acc, item) => acc + item.roundingAmount,
      //     0
      //   ),
      //   total: dataBox.reduce((acc, item) => acc + item.total, 0),
      //   refund: dataBox.reduce((acc, item) => acc + item.refund, 0),
      //   orderType: null,
      //   posId: null,
      //   drawerId: null,
      //   paymentType: "",
      //   billOpenBy: null,
      //   billCloseBy: null,
      //   branchName: null,
      // });

      setRows(dataBox);
    }
    let dataColumns = [
      {
        header: t("report_receipt_number"),
        accessorKey: "invNo",
        minWidth: i18n.language === "th" ? 150 : 100,
        fixed: true,
        onClick: (row) => onDialogBill(row),
        Cell: ({ cell }) => <TextTable props={{ text: cell.getValue() }} />,
      },
      {
        header: t("report_date"),
        accessorKey: "paymentDate",
        isSorting: true,
        minWidth: 80,
        Cell: ({ cell }) => <TextTable props={{ text: cell.getValue() }} />,
      },
      {
        header: t("report_time"),
        accessorKey: "paymentTime",
        isSorting: true,
        minWidth: 60,
        Cell: ({ cell }) => <TextTable props={{ text: cell.getValue() }} />,
      },
      {
        header: t("report_summary_price"),
        accessorKey: "summaryPrice",
        isSorting: true,
        minWidth: i18n.language === "th" ? 100 : 110,
        Cell: ({ cell }) => (
          <TextTable
            props={{
              text: Number(cell.getValue()).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }),
              alignText: "right",
            }}
          />
        ),
      },
      {
        header: t("report_discount_by_item"),
        accessorKey: "discountByItems",
        isSorting: true,
        minWidth: i18n.language === "th" ? 100 : 120,
        Cell: ({ cell }) => (
          <TextTable
            props={{
              text: Number(cell.getValue()).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }),
              alignText: "right",
            }}
          />
        ),
      },
      {
        header: t("report_discount_bill_end"),
        accessorKey: "subTotalBillDiscount",
        isSorting: true,
        minWidth: i18n.language === "th" ? 100 : 120,
        Cell: ({ cell }) => (
          <TextTable
            props={{
              text: Number(cell.getValue()).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }),
              alignText: "right",
            }}
          />
        ),
      },
      {
        header: t("report_sub_total"),
        accessorKey: "subTotal",
        isSorting: true,
        minWidth: 80,
        Cell: ({ cell }) => (
          <TextTable
            props={{
              text: Number(cell.getValue()).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }),
              alignText: "right",
            }}
          />
        ),
      },
      {
        header: t("report_service_charge"),
        accessorKey: "serviceCharge",
        isSorting: true,
        minWidth: i18n.language === "th" ? 80 : 110,
        Cell: ({ cell }) => (
          <TextTable
            props={{
              text: Number(cell.getValue()).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }),
              alignText: "right",
            }}
          />
        ),
      },
      {
        header: t("report_none_vat"),
        accessorKey: "nonVat",
        isSorting: true,
        minWidth: i18n.language === "th" ? 140 : 70,
        Cell: ({ cell }) => (
          <TextTable
            props={{
              text: Number(cell.getValue()).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }),
              alignText: "right",
            }}
          />
        ),
      },
      {
        header: t("report_ex_vat"),
        accessorKey: "exVat",
        isSorting: true,
        minWidth: i18n.language === "th" ? 130 : 70,
        Cell: ({ cell }) => (
          <TextTable
            props={{
              text: Number(cell.getValue()).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }),
              alignText: "right",
            }}
          />
        ),
      },
      {
        header: t("report_before_vat"),
        accessorKey: "beforeVat",
        isSorting: true,
        minWidth: i18n.language === "th" ? 130 : 100,
        Cell: ({ cell }) => (
          <TextTable
            props={{
              text: Number(cell.getValue()).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }),
              alignText: "right",
            }}
          />
        ),
      },
      {
        header: t("report_vat"),
        accessorKey: "vat",
        isSorting: true,
        minWidth: i18n.language === "th" ? 60 : 50,
        Cell: ({ cell }) => (
          <TextTable
            props={{
              text: Number(cell.getValue()).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }),
              alignText: "right",
            }}
          />
        ),
      },
      {
        header: t("report_voucher_amount"),
        accessorKey: "voucherAmount",
        isSorting: true,
        minWidth: i18n.language === "th" ? 120 : 120,
        Cell: ({ cell }) => (
          <TextTable
            props={{
              text: Number(cell.getValue()).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }),
              alignText: "right",
            }}
          />
        ),
      },
      {
        header: t("report_voucher_discount"),
        accessorKey: "voucherDiscount",
        isSorting: true,
        minWidth: i18n.language === "th" ? 130 : 130,
        Cell: ({ cell }) => (
          <TextTable
            props={{
              text: Number(cell.getValue()).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }),
              alignText: "right",
            }}
          />
        ),
      },
      {
        header: t("report_rounding_amount"),
        accessorKey: "roundingAmount",
        isSorting: true,
        minWidth: i18n.language === "th" ? 100 : 130,
        Cell: ({ cell }) => (
          <TextTable
            props={{
              text: Number(cell.getValue()).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }),
              alignText: "right",
            }}
          />
        ),
      },
      {
        header: t("report_total"),
        accessorKey: "total",
        isSorting: true,
        minWidth: 80,
        Cell: ({ cell }) => (
          <TextTable
            props={{
              text: Number(cell.getValue()).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }),
              alignText: "right",
            }}
          />
        ),
      },
      {
        header: t("report_refund"),
        accessorKey: "refund",
        isSorting: true,
        minWidth: 80,
        Cell: ({ cell }) => (
          <TextTable
            props={{
              text: Number(cell.getValue()).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }),
              alignText: "right",
            }}
          />
        ),
      },
      {
        header: t("report_order_type"),
        accessorKey: "orderType",
        minWidth: i18n.language === "th" ? 110 : 100,
        Cell: ({ cell }) => <TextTable props={{ text: cell.getValue() }} />,
      },
      {
        header: t("report_pos_id"),
        accessorKey: "posId",
        minWidth: 80,
        Cell: ({ cell }) => <TextTable props={{ text: cell.getValue() }} />,
      },
      {
        header: t("report_drawer_id"),
        accessorKey: "drawerId",
        minWidth: i18n.language === "th" ? 120 : 100,
        Cell: ({ cell }) => <TextTable props={{ text: cell.getValue() }} />,
      },
      {
        header: t("report_payment_type"),
        accessorKey: "paymentType",
        minWidth: i18n.language === "th" ? 140 : 120,
        Cell: ({ cell }) => (
          <Box>
            <TextTable
              props={{
                text: cell.getValue()
                  ? cell
                      .getValue()
                      .sort((a, b) => {
                        if (a.paymentType < b.paymentType) return -1;
                        if (a.paymentType > b.paymentType) return 1;
                        return 0;
                      })
                      .join(", ")
                  : null,
              }}
            />
          </Box>
        ),
      },
      {
        header: t("report_payment_channel"),
        accessorKey: "paymentTypeChannel",
        minWidth: i18n.language === "th" ? 140 : 120,
        Cell: ({ cell }) => (
          <Box>
            <TextTable
              props={{
                text: cell.getValue()
                  ? cell
                      .getValue()
                      .sort((a, b) => {
                        if (a.paymentTypeChannel < b.paymentTypeChannel)
                          return -1;
                        if (a.paymentTypeChannel > b.paymentTypeChannel)
                          return 1;
                        return 0;
                      })
                      .join(", ")
                  : "-",
              }}
            />
          </Box>
        ),
      },
      {
        header: t("report_bill_open_by"),
        accessorKey: "billOpenBy",
        minWidth: 100,
        Cell: ({ cell }) => <TextTable props={{ text: cell.getValue() }} />,
      },
      {
        header: t("report_bill_close_by"),
        accessorKey: "billCloseBy",
        minWidth: 100,
        Cell: ({ cell }) => <TextTable props={{ text: cell.getValue() }} />,
      },
      {
        header: t("report_branch"),
        minWidth: 100,
        accessorKey: "branchName",
        Cell: ({ cell }) => <TextTable props={{ text: cell.getValue() }} />,
      },
    ];
    dataColumns.forEach((item) => {
      if (item.header !== "Image" && item.header !== "Action") {
        headers.push(item.header);
      }
    });
    setColumns(dataColumns);
    //===== Start set header name export csv
    const csvStringArray = [
      headers,
      ...dataBox.map((item) => [
        item.invNo,
        item.paymentDate,
        item.paymentTime,
        item.summaryPrice,
        item.discountByItems,
        item.subTotalBillDiscount,
        item.subTotal,
        item.serviceCharge,
        item.nonVat,
        item.exVat,
        item.beforeVat,
        item.vat,
        item.voucherAmount,
        item.voucherDiscount,
        item.roundingAmount,
        item.total,
        item.refund,
        item.orderType,
        item.posId,
        item.drawerId,
        item.paymentType,
        item.paymentTypeChannel,
        item.billOpenBy,
        item.billCloseBy,
        item.branchName,
      ]),
    ]
      .map((row) => row.join(","))
      .join("\n");
    setExportColumnName(csvStringArray);
    //===== End set header name export csv
    setIsLoadingData(false);
  };
  const onConfirm = async () => {
    await setIsLoadingData(true);
    await fetchReport();
  };
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportAll]);
  return (
    <Box>
      <BreadcrumbsTitle
        props={{
          title: t("report_by_bill"),
        }}
      />
      <Box>
        <SelectCheckMarkBranch
          props={{
            onConfirm,
            setBranchSelectReport,
          }}
        />

        <DataTableReport
          statusViewMode={true}
          rows={rows}
          isLoadingData={isLoadingData}
          exportColumnName={exportColumnName}
          columns={columns}
          height={0.85}
          fileName={
            t("report_by_bill") +
            `${dayjs(branchSelectReportDate.startDate).format(
              "DD/MM/YYYY"
            )} - ${dayjs(branchSelectReportDate.endDate).format("DD/MM/YYYY")}`
          }
        />
        {dialogData.status ? (
          <DetailBillReceipt props={{ dialogData, handleClose }} />
        ) : null}
      </Box>
    </Box>
  );
}

export default ReportByBill;
