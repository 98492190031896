import { Box, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { ActionDetail } from "../../../../../components/ui/dataTable/components/actionDetail";
import { t } from "i18next";
import { ActionBtn } from "../../../../../components/ui/dataTable/components/actionBtn";
import { MomentConvert } from "../../../../../components/ui/moment/momentConvert";
import { getAllTemplateSaleProduct } from "../../../../../service/selfPickUp/saleProduct/getAllTemplateSaleProduct";
import { getSaleProductBranch } from "../../../../../service/selfPickUp/saleProduct/getSaleProductBranch";
import DataMaterialTable from "../../../../../components/ui/dataTable/dataMaterialTable";
import { SelfPickupSaleProductDialog } from "../../../selfPickupSaleProduct/selfPickupSaleProductDialog";
import { updateSaleProductBranch } from "../../../../../service/selfPickUp/saleProduct/updateSaleProductBranch";
import { notifySuccess } from "../../../../../components/ui/popup/toastPopup";

function SelfPickupBranchSaleProduct({ props }) {
  let { paramsId } = props;
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [saleProductAll, setSaleProductAll] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const onAddSaleProduct = async () => {
    setDialogOpen(true);
  };
  const onConfirmAddSaleProduct = async () => {
    const paramsId =
      window.location.pathname.split("/")[
        window.location.pathname.split("/").length - 1
      ];
    let dataBox = [];
    saleProductAll.forEach((item) => {
      if (item.select) {
        dataBox.push({
          sale_product_id: item.id,
          limit: item.limit,
          amount: item.amount,
          status: item.status,
        });
      }
    });
    let dataAPI = {
      branch_id: String(paramsId),
      sale_products: dataBox,
    };
    let statusAPI = await updateSaleProductBranch(dataAPI);
    if (statusAPI === "OK") {
      notifySuccess({
        title: t("notification_successfully_edited_information"),
      });
      setDialogOpen(false);
      fetchData();
    }
  };
  const fetchData = async () => {
    let dataColumns = [
      {
        accessorKey: "action",
        enableSorting: false,
        size: 10,
        header: "Action",
        sortable: false,
        Cell: ({ row }) => (
          <Box sx={{ display: "flex", gap: 2 }}>
            <ActionDetail params={row} detailPath={"/sale-product/detail/"} />
          </Box>
        ),
      },
      // {
      //   accessorKey: "image",
      //   size: 50,
      //   enableSorting: false,
      //   header: t("global_image"),
      //   Cell: ({ row }) => (
      //     <Box sx={{ display: "flex", gap: 1 }}>
      //       <Box>
      //         {row.original.data.image ? (
      //           <BoxImage
      //             image={row.original.data.image}
      //             width={"50px"}
      //             height={"50px"}
      //             type="table"
      //           />
      //         ) : (
      //           <BoxImageBlank width={50} height={50} />
      //         )}
      //       </Box>
      //     </Box>
      //   ),
      // },
      {
        accessorKey: "amount",
        size: 50,
        header: t("global_amount"),
      },
      {
        accessorKey: "limit",
        size: 50,
        header: t("global_limit"),
        Cell: ({ row }) => (
          <ActionBtn row={row} status={row.original.limit} disabled={true} />
        ),
      },
      {
        accessorKey: "updated_at",
        size: 100,
        header: t("global_updated_at"),
        Cell: ({ cell }) => (
          <Typography sx={{ fontSize: "14px" }}>
            {MomentConvert(cell.getValue())}
          </Typography>
        ),
      },
      {
        accessorKey: "status",
        enableSorting: false,
        size: 50,
        header: t("global_status"),
        Cell: ({ row }) => (
          <ActionBtn row={row} status={row.original.status} disabled={true} />
        ),
      },
    ];
    let dataSaleProductAll = await getAllTemplateSaleProduct(paramsId);
    let dataAPI = await getSaleProductBranch(paramsId);
    if (dataAPI) {
      dataAPI.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));
      dataAPI.forEach((product) => {
        let dataNews = dataSaleProductAll.find(
          (item) => item.id === product.id
        );
        if (dataNews) {
          product.data = dataNews;
        }
      });
      setRows(dataAPI);
    }
    let dataNewBox = [];
    dataSaleProductAll.forEach((item) => {
      if (dataAPI.find((product) => product.id !== item.id) === undefined) {
        dataNewBox.push(item);
      }
    });
    setColumns(dataColumns);
    dataNewBox.forEach((item) => {
      item.select = false;
      item.limit = true;
      item.amount = 0;
    });
    setSaleProductAll(dataNewBox);

    setIsLoadingData(false);
  };
  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <Box>
      <DataMaterialTable statusViewMode={true}
        rows={rows}
        isLoadingData={isLoadingData}
        columns={columns}
        createButton={t("self_pickup_setup_add_sale_product")}
        onCreateFunction={() => onAddSaleProduct()}
      />
      <SelfPickupSaleProductDialog
        props={{
          saleProductAll,
          setSaleProductAll,
          dialogOpen,
          setDialogOpen,
          onConfirmAddSaleProduct,
        }}
      />
    </Box>
  );
}

export default SelfPickupBranchSaleProduct;
