import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import SettingDeviceForm from "./settingDeviceForm";
import { postDevice } from "../../service/deviceAPI";
import { notifySuccess } from "../../components/ui/popup/toastPopup";
import BreadcrumbsTitle from "../../components/ui/breadcrumbsTitle";
import { getAllBranch } from "../../service/branchAPI";
import Loading from "../../components/ui/loading";
import SelectComponent from "../../components/ui/textfield/selectComponent";
import { BoxWrapper } from "../../components/ui/box/boxWrapper";
import PaperWrapper from "../../components/ui/paper/paperWrapper";
import { Box } from "@mui/material";
import TitleForm from "../../components/ui/formComponent/titleForm";
import { useSchema } from "../../components/hooks/use-schema";
import { deviceSchema } from "../../components/validation/deviceSchema";
import { GridContainerSize } from "../../components/utils/gridContainerSize";
import { BoxFormTitleAction } from "../../components/ui/box/boxFormTitleAction";
import TitleHeaderForm from "../../components/ui/formComponent/titleHeaderForm";
import { BoxGapInput } from "../../components/ui/box/boxGapInput";

function SettingDeviceCreate() {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    clearErrors,
    setValue,
    control,
    watch,
    formState: { errors },
  } = useForm(useSchema(deviceSchema));
  const [statusButton, setStatusButton] = useState(true);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [branchAll, setBranchAll] = useState([]);
  const onConfirmForm = async (register) => {
    setIsSubmitting(true);
    var dataAPI = {
      branch_id: String(register.branch),
      serial_number: register.serial_number,
      device_model: register.device_model,
      version_securitypatch: register.version_security_patch,
      version_sdkInt: register.version_sdkInt,
      version_release: register.version_release,
      status: statusButton,
      device_lang: {
        name: register.deviceName,
        language_code: "all",
      },
    };
    let statusAPI = await postDevice(dataAPI);
    if (statusAPI === "OK") {
      notifySuccess({
        title: t("toast_successfully_added_information"),
      });
      navigate("/setting-device");
    }
    await new Promise((resolve) => setTimeout(resolve, 2000));
    setIsSubmitting(false);
  };
  const fetchData = async () => {
    let dataAPI = await getAllBranch();
    let dataBox = [];
    dataAPI.forEach((item) => {
      dataBox.push({ id: item.id, name: item.branch_lang.name });
    });
    setValue("device_model", "1");
    setValue("version_security_patch", "1");
    setValue("version_sdkInt", "1");
    setValue("version_release", "1");
    setBranchAll(dataBox);
    setIsLoading(false);
  };
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div align="left">
      <BreadcrumbsTitle
        props={{
          title: t("device_pos"),
          dataLink: [
            {
              text: t("device_pos"),
              link: "/setting-device",
            },
            {
              text: t("device_pos"),
              link: null,
            },
          ],
        }}
      />
      {isLoading ? (
        <Loading />
      ) : (
        <GridContainerSize>
          <Box sx={{ p: 2, boxSizing: "border-box" }}>
            <Box mb={2}>
              <BoxWrapper>
                <PaperWrapper props={{ growFull: 1 }}>
                  <BoxFormTitleAction>
                    <TitleHeaderForm props={{ text: t("branch") }} />
                  </BoxFormTitleAction>
                  <BoxGapInput>
                    <BoxWrapper>
                      <PaperWrapper props={{ growFull: 1 }}>
                        <TitleForm props={{ text: t("branch_info") }} />
                        <Box p={2}>
                          <SelectComponent
                            title={t("branch")}
                            watch={watch}
                            name="branch"
                            errors={errors}
                            setValue={setValue}
                            dataEmpty={t("branch_select")}
                            optionsData={branchAll}
                            clearErrors={clearErrors}
                            control={control}
                          />
                        </Box>
                      </PaperWrapper>
                    </BoxWrapper>
                  </BoxGapInput>
                </PaperWrapper>
              </BoxWrapper>
            </Box>
            <SettingDeviceForm
              props={{
                handleSubmit,
                onConfirmForm,
                watch,
                register,
                errors,
                statusButton,
                setStatusButton,
                isSubmitting,
                mode: "create",
              }}
            />
          </Box>
        </GridContainerSize>
      )}
    </div>
  );
}

export default SettingDeviceCreate;
