import { Box } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";

import { t } from "i18next";
import dayjs from "dayjs";
import BreadcrumbsTitle from "../../../components/ui/breadcrumbsTitle";
import SelectCheckMarkBranch from "../../../components/ui/select/selectCheckMarkBranch";
import { TextTable } from "../../../components/ui/textfield/textfieldCustom";
import { MyContext } from "../../../context/MyContext";
import DataTableReport from "../../../components/ui/dataTable/dataTableReport";
import { getAllPromotion } from "../../../service/promotionAPI";
import i18n from "../../../locals/i18n";

function ReportByPromotion() {
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [exportColumnName, setExportColumnName] = useState(null);
  const {
    branchAll,
    branchSelectReport,
    setBranchSelectReport,
    branchSelectReportDate,
    reportAll,
    fetchReport,
    salePromotionSetAll,
  } = useContext(MyContext);

  const [isLoadingData, setIsLoadingData] = useState(
    reportAll.length > 0 ? false : true
  );
  const fetchData = async (dataReportNew) => {
    let dataPromotionRows = [];
    let headers = [];
    let dataSentAPI = {
      start: dayjs(branchSelectReportDate.startDate).format("YYYYMMDDHHmmss"),
      end: dayjs(branchSelectReportDate.endDate).format("YYYYMMDDHHmmss"),
      branches: branchSelectReport,
    };

    let dataAPI = dataReportNew
      ? dataReportNew
      : reportAll.length > 0
      ? reportAll
      : await fetchReport(dataSentAPI);
    if (dataAPI) {
      let dataAPIPromotion =
        salePromotionSetAll.length > 0
          ? salePromotionSetAll
          : await getAllPromotion();
      dataAPI = dataAPI.filter(
        (itemPromotion) => itemPromotion.receipt_promotions.length > 0
      );
      let dataBranch = [];
      branchAll.forEach((itemBranch) => {
        let dataAllReceipt = dataAPI.filter(
          (item) => item.branch_id === itemBranch.id
        );
        if (dataAllReceipt.length > 0) {
          dataBranch.push({
            branch_id: itemBranch.id,
            receiptAll: dataAllReceipt,
          });
        }
      });

      let dataAllPromotion = [];
      dataBranch.forEach((itemBranch) => {
        let dataCollectPromotion = [];
        itemBranch.receiptAll
          .filter((itemNoVoid) => itemNoVoid.receipt_status === "success")
          .forEach((itemReceipt) => {
            let dataBoxPromotion = [];
            itemReceipt.receipt_promotions.forEach((itemPromotion) => {
              let existPromotion = dataBoxPromotion.find(
                (item) => item.promotion_id === itemPromotion.promotion_id
              );
              if (existPromotion === undefined) {
                dataBoxPromotion.push(itemPromotion);
              }
            });
            dataBoxPromotion.forEach((itemPromotion, index) => {
              let discountTypeName = "";
              if (itemPromotion.discount_type === 1) {
                discountTypeName = "Bill End Discount";
              } else if (itemPromotion.discount_type === 2) {
                discountTypeName = "Discount By Items";
              } else if (itemPromotion.discount_type === 3) {
                discountTypeName = "Free Items";
              } else {
                discountTypeName = "";
              }
              dataCollectPromotion.push({
                branch_id: itemBranch.branch_id,
                namePromotion: dataAPIPromotion.find(
                  (itemPromotionAll) =>
                    itemPromotionAll.id === itemPromotion.promotion_id
                ).promotion_lang.name,
                promotionType: discountTypeName,
                discount:
                  itemPromotion.discount_type === 2
                    ? itemPromotion.price * itemPromotion.amount
                    : itemPromotion.price,
                amount: 1,
                branchName: itemReceipt.receipt_config_receipt
                  ? itemReceipt.receipt_config_receipt.config_receipt_lang
                      .branch_name
                  : null,
              });
            });
          });
        dataAllPromotion.push(dataCollectPromotion);
      });
      let dataBox = [];
      dataAllPromotion.forEach((itemPromotion) => {
        let dataItemPromotionBox = [];
        itemPromotion.forEach((itemPromotionEach) => {
          let existingItem = dataItemPromotionBox.find(
            (itemFind) =>
              itemFind.namePromotion === itemPromotionEach.namePromotion
          );
          if (existingItem === undefined) {
            dataItemPromotionBox.push(itemPromotionEach);
          } else {
            existingItem.amount += itemPromotionEach.amount;
          }
        });
        dataBox.push(dataItemPromotionBox);
      });
      dataBox.forEach((itemPromotion) => {
        itemPromotion.sort((a, b) => {
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        });
      });

      dataBox.forEach((item) => {
        item.forEach((itemPromotion) => {
          dataPromotionRows.push(itemPromotion);
        });
      });
      // dataPromotionRows.push({
      //   namePromotion: "Total",
      //   promotionType: null,
      //   amount: dataPromotiondataPromotionRows.reduce((acc, item) => acc + item.amount, 0),
      //   branchName: null,
      // });
      setRows(dataPromotionRows);
    }

    let dataColumns = [
      {
        header: t("Name"),
        accessorKey: "namePromotion",
        flexGrow: 1,
        Cell: ({ cell }) => <TextTable props={{ text: cell.getValue() }} />,
      },
      {
        header: t("report_promotion_type"),
        accessorKey: "promotionType",
        flexGrow: 1,
        Cell: ({ cell }) => <TextTable props={{ text: cell.getValue() }} />,
      },
      {
        header: t("report_amount"),
        accessorKey: "amount",
        minWidth: i18n.language === "th" ? 40 : 40,
        isSorting: true,
        decimal: 0,
        Cell: ({ cell }) => (
          <TextTable
            props={{
              text: Number(cell.getValue()).toLocaleString(undefined, {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              }),
              alignText: "right",
            }}
          />
        ),
      },
      {
        header: t("report_discounted_price"),
        accessorKey: "discount",
        minWidth: i18n.language === "th" ? 40 : 40,
        isSorting: true,
        Cell: ({ cell }) => (
          <TextTable
            props={{
              text: Number(cell.getValue()).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }),
              alignText: "right",
            }}
          />
        ),
      },
      {
        flexGrow: 1,
        header: t("report_branch"),
        accessorKey: "branchName",
        Cell: ({ cell }) => <TextTable props={{ text: cell.getValue() }} />,
      },
    ];

    dataColumns.forEach((item) => {
      if (item.header !== "Image" && item.header !== "Action") {
        headers.push(item.header);
      }
    });
    setColumns(dataColumns);
    //===== Start set header name export csv
    const csvStringArray = [
      headers,
      ...dataPromotionRows.map((item) => [
        item.namePromotion,
        item.promotionType,
        item.amount,
        item.branchName,
      ]),
    ]
      .map((row) => row.join(","))
      .join("\n");

    setExportColumnName(csvStringArray);
    //===== End set header name export csv
    setIsLoadingData(false);
  };
  const onConfirm = async () => {
    await setIsLoadingData(true);
    let dataReportNew = await fetchReport();
    await fetchData(dataReportNew);
  };
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Box>
      <BreadcrumbsTitle
        props={{
          title: t("report_by_promotion"),
        }}
      />
      <Box>
        <SelectCheckMarkBranch
          props={{
            onConfirm,
            setBranchSelectReport,
          }}
        />

        <DataTableReport
          statusViewMode={true}
          rows={rows}
          isLoadingData={isLoadingData}
          exportColumnName={exportColumnName}
          columns={columns}
          height={0.85}
          fileName={
            t("report_by_promotion") +
            `${dayjs(branchSelectReportDate.startDate).format(
              "DD/MM/YYYY"
            )} - ${dayjs(branchSelectReportDate.endDate).format("DD/MM/YYYY")}`
          }
        />
      </Box>
    </Box>
  );
}

export default ReportByPromotion;
