import {
  Box,
  Button,
  Checkbox,
  Chip,
  Fab,
  FormControlLabel,
  FormGroup,
  Grid,
  // InputAdornment,
  Radio,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import TitleForm from "../../../../components/ui/formComponent/titleForm";
import ImageCrop from "../../../../components/ui/upload/imageCrop";
import { useTranslation } from "react-i18next";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DesktopTimePicker } from "@mui/x-date-pickers";
import { TitleTextfield } from "../../../../components/ui/textfield/textfieldCustom";
// import AllInclusiveIcon from "@mui/icons-material/AllInclusive";
import Loading from "../../../../components/ui/loading";
import MaterialReactTable from "material-react-table";
import TextfieldComponent from "../../../../components/ui/textfield/textfieldComponent";
import { PaddingContainer } from "../../../../components/utils/paddingContainer";
import { isBrowser } from "react-device-detect";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import PaperWrapper from "../../../../components/ui/paper/paperWrapper";
import { BoxWrapper } from "../../../../components/ui/box/boxWrapper";
import { DatePicker, Space } from "antd";
import dayjs from "dayjs";
import { BoxGapInput } from "../../../../components/ui/box/boxGapInput";
// import SelectCheckMarkComponent from "../../../../components/ui/select/selectCheckMarkComponent";
// import { MyContext } from "../../../../context/MyContext";

function PromotionCreateDetail({ props }) {
  const { RangePicker } = DatePicker;
  const dateFormat = "DD/MM/YYYY HH:mm";
  // const { salePromotionSetAll } = useContext(MyContext);
  let {
    register,
    setValue,
    errors,
    // clearErrors,
    // control,
    image,
    setImage,
    promotionSchedules,
    setPromotionSchedules,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    promotionSchedulesAll,
    setPromotionSchedulesAll,
    autoApply,
    setAutoApply,
    limitUse,
    setLimitUse,
    couponCode,
    setCouponCode,
    datePromotionStatus,
    setDatePromotionStatus,
    receiptTypeAll,
    setReceiptTypeAll,
    couponAll,
    setDialogCoupon,
    handleErrorTime,
    handleErrorTimeAll,
    promotionSchedulesAllError,
    // handleErrorDate,
    // datePromotionError,
    // promotionConjunction,
    // setPromotionConjunction,
    // discountType,
    // promotionMultipleTime,
    // setPromotionMultipleTime,
    // setPromotionConjunctionListName,
    // promotionConjunctionListName,
    // setPromotionConjunctionListId,
  } = props;
  const onDatePromotionStatus = (e) => {
    setDatePromotionStatus(e.target.checked);
  };
  // const handleStartDate = (newValue) => {
  //   setStartDate(newValue);
  // };

  // const handleEndDate = (newValue) => {
  //   setEndDate(newValue);
  // };

  const { t } = useTranslation();

  const onSchedule = (index) => {
    setPromotionSchedules((prevSchedules) => {
      const updatedSchedules = [...prevSchedules];
      updatedSchedules[index].select = !updatedSchedules[index].select;
      return updatedSchedules;
    });
  };
  const onChangeStatusTimeAll = (e) => {
    setPromotionSchedulesAll((prevSchedules) => {
      const newItem = { ...prevSchedules };
      newItem.select = e.target.checked;
      return newItem;
    });

    let dataNews = [...promotionSchedules];
    dataNews.forEach((item) => {
      delete item.errorFormat;
    });
  };
  const handleTimeScheduleAllStart = (newValue) => {
    setPromotionSchedulesAll({
      ...promotionSchedulesAll,
      start_time: newValue,
    });
  };
  const handleTimeScheduleAllEnd = (newValue) => {
    setPromotionSchedulesAll({
      ...promotionSchedulesAll,
      end_time: newValue,
    });
  };

  const handleChangeDate = (newValue) => {
    setStartDate(dayjs(newValue[0], dateFormat));
    setEndDate(dayjs(newValue[1], dateFormat));
  };
  // const maxDate = new Date();
  // const disabledDate = (current) => {
  //   return current && current > maxDate;
  // };

  const handleOrderType = (item, indexItem) => {
    let dataNews = [...receiptTypeAll];
    dataNews[indexItem].select = !item.select;
    setReceiptTypeAll(dataNews);
  };
  const onLimitUse = (e) => {
    setLimitUse({ ...limitUse, status: e.target.checked });
    setValue("limitUse", "");
  };

  const onLimitRadio = (status) => {
    let dataNews = { ...couponCode };
    dataNews.type = status;
    setCouponCode(dataNews);
  };
  const onCouponCode = (e) => {
    setCouponCode({ ...couponCode, status: e.target.checked });
  };
  const handleTimeScheduleEachStart = (newValue, index) => {
    let dateNews = [...promotionSchedules];
    dateNews[index].start_time = new Date(newValue);
    setPromotionSchedules(dateNews);
  };
  const handleTimeScheduleEachEnd = (newValue, index) => {
    let dateNews = [...promotionSchedules];
    dateNews[index].end_time = new Date(newValue);
    setPromotionSchedules(dateNews);
  };
  return (
    <Box className="boxWrapper-container">
      <BoxWrapper>
        <PaperWrapper props={{ growFull: null }}>
          <TitleForm props={{ text: t("promotion_image") }} />
          <Box align="center">
            <ImageCrop
              props={{
                setImage,
                image,
                heightCrop: 256,
                widthCrop: 256,
                label: "staffPOS",
              }}
            />
          </Box>
        </PaperWrapper>
        <PaperWrapper props={{ growFull: 1 }}>
          <TitleForm props={{ text: t("promotion_info") }} />
          <BoxGapInput>
            <TextfieldComponent
              title={t("promotion_name")}
              name="promotionName"
              register={register}
              placeholder={t("promotion_name_example")}
              errors={errors}
              fullGrid={true}
            />
            <TextfieldComponent
              title={t("promotion_description")}
              name="promotionDescription"
              register={register}
              placeholder={t("promotion_description_example")}
              errors={errors}
              multiline={true}
              rows={4}
              fullGrid={true}
            />
          </BoxGapInput>
        </PaperWrapper>
      </BoxWrapper>
      <PaperWrapper props={{ growFull: 1 }}>
        <TitleForm props={{ text: t("order_type") }} />
        <Box
          sx={{
            p: 1,
            px: 2,
          }}
        >
          <Typography
            sx={(theme) => ({
              color: theme.palette.secondaryText.main,
              mb: 1,
              fontSize: 14,
            })}
          >
            {t("order_select")}
          </Typography>
          <Grid
            container
            alignItems={"center"}
            justifyContent={"space-between"}
            spacing={2}
            sx={{ mb: 2, pl: 2 }}
          >
            <FormGroup></FormGroup>
            {receiptTypeAll.length > 0 ? (
              <>
                {receiptTypeAll.map((item, indexItem) => (
                  <Grid item xs={12} sm={6} sx={(theme) => ({})}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={item.select}
                          onClick={() => handleOrderType(item, indexItem)}
                        />
                      }
                      label={item.name}
                      sx={{
                        border: 1,
                        boxSizing: "border-box",
                        width: "100%",
                        borderRadius: 2,
                      }}
                    />
                    {/* <Button
                      variant={item.select ? "contained" : "outlined"}
                      color={item.select ? "primary" : "primary"}
                      onClick={() => handleOrderType(item, indexItem)}
                      fullWidth
                    >
                      {item.name}
                    </Button> */}
                  </Grid>
                ))}
              </>
            ) : (
              <>
                <Loading />
              </>
            )}
          </Grid>
        </Box>
      </PaperWrapper>
      <PaperWrapper props={{ growFull: 1 }}>
        <TitleForm props={{ text: t("coupon") }} />
        <Grid
          container
          justifyContent={"space-between"}
          p={PaddingContainer.size}
        >
          <Grid item xs={12} sx={{ mb: 2 }}>
            <FormControlLabel
              control={
                <Switch checked={couponCode.status} onChange={onCouponCode} />
              }
              label={
                <Typography>
                  {t("coupon_use_with_coupon_or_promotion_code_only")}(
                  {t("coupon_use_for_customer_to_fill_out")})
                </Typography>
              }
            />
          </Grid>
          {couponCode.status && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                width: "100%",
              }}
            >
              <Grid item xs={12}>
                <TitleTextfield textError={null} text={t("coupon_type")} />
                <FormControlLabel
                  control={
                    <Radio
                      name="limit"
                      checked={couponCode.type === "limit"}
                      onChange={(e) => onLimitRadio("limit")}
                    />
                  }
                  label={t("global_limit")}
                />
                <FormControlLabel
                  control={
                    <Radio
                      name="unlimited"
                      checked={couponCode.type === "unlimit"}
                      onChange={(e) => onLimitRadio("unlimit")}
                    />
                  }
                  label={t("global_un_limit")}
                />
              </Grid>
              {couponCode.type === "limit" && (
                <TextfieldComponent
                  title={t("coupon_amount")}
                  name="amountOfCoupon"
                  register={register}
                  placeholder={t("coupon_amount_example")}
                  errors={errors}
                />
              )}
              {couponCode.type === "unlimit" && (
                <TextfieldComponent
                  title={t("coupon_code")}
                  name="couponCodeTicket"
                  register={register}
                  placeholder={t("coupon_code_example")}
                  errors={errors}
                  iconEnd={"Code"}
                  iconEndOnclick={false}
                />
              )}
              <TextfieldComponent
                title={t("coupon_name")}
                name="couponName"
                register={register}
                placeholder={t("coupon_name_example")}
                errors={errors}
              />

              <TextfieldComponent
                title={t("coupon_name_sub")}
                name="subCouponName"
                register={register}
                placeholder={t("coupon_name_sub_example")}
                errors={errors}
              />
              <TextfieldComponent
                title={t("coupon_description")}
                name="couponDescription"
                register={register}
                placeholder={t("coupon_description_example")}
                errors={errors}
                multiline={true}
                rows={4}
              />

              {couponAll && (
                <Grid container>
                  <Grid xs={12} mt={1}>
                    <TitleTextfield textError={null} text={t("coupon_code")} />
                  </Grid>
                  {couponAll.length > 20 ? (
                    <>
                      <MaterialReactTable
                        enableStickyHeader
                        enableColumnActions={false}
                        initialState={{
                          showGlobalFilter: true,
                          pagination: {
                            pageSize: 25,
                            pageIndex: 0,
                          },
                        }}
                        enableRowNumbers
                        enableToolbarInternalActions={false}
                        muiSearchTextFieldProps={{
                          variant: "outlined",
                          size: "small",
                          placeholder: "Search",
                          sx: { borderRadius: 1, p: 2 },
                          InputLabelProps: {
                            shrink: true,
                          },
                        }}
                        muiTableProps={{
                          sx: {
                            tableLayout: "fixed",
                          },
                        }}
                        data={couponAll}
                        muiTablePaginationProps={{
                          rowsPerPageOptions: [25, 50, 100],
                          showFirstButton: true,
                          showLastButton: true,
                        }}
                        muiTableContainerProps={{
                          sx: { maxHeight: window.innerHeight * 0.24 },
                        }}
                        columns={[
                          {
                            accessorKey: "code",
                            enableClickToCopy: true,
                            header: "Coupon Code",
                            size: 150,
                          },
                          {
                            accessorKey: "state",
                            header: "State",
                            size: 150,
                            Cell: ({ row }) => (
                              <>
                                {row.original.state === "ready" ? (
                                  <Typography sx={(theme) => ({})}>
                                    {row.original.state}
                                  </Typography>
                                ) : (
                                  <Typography
                                    sx={(theme) => ({
                                      color: theme.palette.error.main,
                                      fontWeight: "bold",
                                    })}
                                  >
                                    {row.original.state}
                                  </Typography>
                                )}
                              </>
                            ),
                          },
                          {
                            accessorKey: "code",
                            header: "#",
                            Cell: ({ row }) => (
                              <>
                                <Chip
                                  onClick={() =>
                                    row.original.state === "ready"
                                      ? setDialogCoupon({
                                          status: true,
                                          data: row.original.code,
                                        })
                                      : null
                                  }
                                  sx={{
                                    width: "100%",
                                  }}
                                  color="secondary"
                                  icon={
                                    <QrCodeScannerIcon sx={{ fontSize: 15 }} />
                                  }
                                  label={row.original.code}
                                  disabled={
                                    row.original.state === "ready"
                                      ? false
                                      : true
                                  }
                                />
                              </>
                            ),
                          },
                        ]}
                      />
                    </>
                  ) : (
                    <>
                      {couponAll.map((coupon) => (
                        <Grid item xs={6}>
                          <Box
                            sx={{
                              boxSizing: "border-box",
                              p: 1,
                            }}
                          >
                            <Chip
                              onClick={() =>
                                coupon.state === "ready"
                                  ? setDialogCoupon({
                                      status: true,
                                      data: coupon.code,
                                    })
                                  : null
                              }
                              sx={{
                                width: "100%",
                              }}
                              color="secondary"
                              icon={<QrCodeScannerIcon sx={{ fontSize: 15 }} />}
                              label={coupon.code}
                              disabled={coupon.state === "ready" ? false : true}
                            />
                          </Box>
                        </Grid>
                      ))}
                    </>
                  )}
                </Grid>
              )}
            </Box>
          )}
        </Grid>
      </PaperWrapper>
      <PaperWrapper props={{ growFull: 1 }}>
        <TitleForm props={{ text: t("promotion_condition_additional") }} />
        <Grid container p={2}>
          <Grid
            item
            xs={12}
            sx={(theme) => ({
              display: "flex",
              gap: 1,
              alignItems: "center",
            })}
          >
            <FormControlLabel
              control={
                <Switch
                  onChange={(e) => setAutoApply(e.target.checked)}
                  checked={autoApply}
                />
              }
              label={
                <Typography>
                  {t(
                    "promotion_notify_me_that_the_promotion_conditions_are_met",
                    { data: "Auto Apply Promotion" }
                  )}
                </Typography>
              }
            />
          </Grid>
          <Grid
            item
            xs={12}
            sx={(theme) => ({
              display: "flex",
              gap: 1,
              alignItems: "center",
              justifyContent: "space-between",
            })}
          >
            <Grid container justifyContent={"space-between"}>
              <Grid
                item
                xs={12}
                sm={7.8}
                sx={(theme) => ({
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                })}
              >
                <FormControlLabel
                  control={
                    <Switch onChange={onLimitUse} checked={limitUse.status} />
                  }
                  label={
                    <>
                      <Typography
                        sx={(theme) => ({
                          fontFamily: "Kanit",
                        })}
                      >
                        {t("promotion_limit_of_use_promotion")}
                      </Typography>
                      <Typography sx={(theme) => ({ color: "#cccccc" })}>
                        {t("promotion_amount_of_this_promotion_can_be_used")}
                      </Typography>
                    </>
                  }
                />
              </Grid>
              {limitUse.status && (
                <>
                  <Grid item xs={12} sm={3.8} lg={2} xl={2}>
                    <TextfieldComponent
                      name="limitAmountUse"
                      register={register}
                      placeholder={t(
                        "promotion_limit_of_use_promotion_example"
                      )}
                      errors={errors}
                      iconEnd={t("global_time")}
                      fullGrid={true}
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
          {/* {(discountType === 1 || discountType === 3) && (
            <>
              <Grid
                item
                xs={12}
                sx={(theme) => ({
                  display: "flex",
                  gap: 1,
                  alignItems: "center",
                })}
              >
                <FormControlLabel
                  control={
                    <Switch
                      onChange={(e) =>
                        setPromotionMultipleTime(e.target.checked)
                      }
                      checked={promotionMultipleTime}
                    />
                  }
                  label={
                    <Typography>
                      {t("promotion_can_be_used_multiple_times")}
                    </Typography>
                  }
                />
              </Grid>
            </>
          )}
          <Grid
            item
            xs={12}
            sx={(theme) => ({
              display: "flex",
              gap: 1,
              mb: 2,
              alignItems: "center",
            })}
          >
            <FormControlLabel
              control={
                <Switch
                  onChange={(e) => setPromotionConjunction(e.target.checked)}
                  checked={promotionConjunction}
                />
              }
              label={<Typography>{t("promotion_conjunction")}</Typography>}
            />
          </Grid> */}
        
        </Grid>
      </PaperWrapper>
      <PaperWrapper props={{ growFull: 1 }}>
        <TitleForm props={{ text: t("promotion_option_date_and_time") }} />
        <Box sx={{ p: 2, boxSizing: "border-box", width: "100%" }}>
          <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
            <Switch
              checked={datePromotionStatus}
              onChange={onDatePromotionStatus}
            />
            <Box
              sx={(theme) => ({
                width: "100%",
                [theme.breakpoints.down("sm")]: {
                  mb: 1,
                },
              })}
            >
              <Typography sx={{ fontWeight: 600 }}>
                {t("promotion_date")}
              </Typography>
              <Typography sx={{ fontSize: 13 }}>
                {t("promotion_choose_a_date_for_the_promotion_with_exp")}
              </Typography>
            </Box>
          </Box>
          {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Box
              sx={(theme) => ({
                [theme.breakpoints.down("sm")]: {
                  display: "inline",
                },
                my: 2,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                gap: 2,
              })}
            >
              {datePromotionStatus ? (
                <>
                  <DesktopDateTimePicker
                    label={t("global_start_date")}
                    minDate={new Date()}
                    value={startDate}
                    sx={{ width: "100%" }}
                    ampm={false}
                    format="dd/MM/yyyy HH:mm"
                    fullWidth
                    onChange={handleStartDate}
                    renderInput={(params) => (
                      <TextField {...params} fullWidth />
                    )}
                  />
                </>
              ) : (
                <>
                  <TextField
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AllInclusiveIcon />
                        </InputAdornment>
                      ),
                    }}
                    fullWidth
                    disabled
                  />
                </>
              )}

              <Typography
                sx={(theme) => ({
                  [theme.breakpoints.down("sm")]: {
                    textAlign: "center",
                    my: 1,
                  },
                })}
              >
                ถึง
              </Typography>

              {datePromotionStatus ? (
                <>
                  <DesktopDateTimePicker
                    label={
                      datePromotionError
                        ? t("validate_please_fill_out_correct_format")
                        : t("global_end_date")
                    }
                    minDate={startDate}
                    value={endDate}
                    sx={{ width: "100%" }}
                    ampm={false}
                    format="dd/MM/yyyy HH:mm"
                    fullWidth
                    onError={(e) => handleErrorDate(e)}
                    onChange={handleEndDate}
                    renderInput={(params) => (
                      <TextField {...params} size="small" fullWidth />
                    )}
                    disabled={datePromotionStatus === false}
                  />
                </>
              ) : (
                <>
                  <TextField
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AllInclusiveIcon />
                        </InputAdornment>
                      ),
                    }}
                    fullWidth
                    disabled
                  />
                </>
              )}
            </Box>
          </LocalizationProvider> */}
          <Box
            sx={(theme) => ({
              boxSizing: "border-box",
            })}
          >
            <Space direction="vertical">
              <RangePicker
                defaultValue={[startDate, endDate]}
                onChange={handleChangeDate}
                // disabledDate={disabledDate}
                format={dateFormat}
                showTime
                disabled={!datePromotionStatus}
                // showTime
                style={{
                  height: "40px",
                  width: "100%",
                }}
              />
            </Space>
          </Box>

          <Box
            sx={(theme) => ({
              display: "flex",
              alignItems: "flex-start",
              width: "100%",
              my: 2,
            })}
          >
            <Box
              sx={{
                width: "100%",
              }}
            >
              <Box mb={2}>
                <Typography sx={(theme) => ({ fontWeight: 600 })}>
                  {t("promotion_date_and_time_for_promotion_use")}
                </Typography>
                <Typography sx={(theme) => ({ fontSize: 13 })}>
                  {t("promotion_select_the_desire_date")}
                </Typography>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  border: "1px solid #cccccc",
                  borderRadius: 1,
                }}
              >
                <Box
                  sx={(theme) => ({
                    display: "flex",
                    justifyContent: "space-evenly",
                    p: 1,
                    my: 2,
                  })}
                >
                  {promotionSchedules.map((itemDate, indexDate) => (
                    <Fab
                      size="small"
                      sx={(theme) => ({
                        boxShadow: 0,
                        position: "static",
                        zIndex: 90,
                      })}
                      color={itemDate?.select ? "primary" : "default"}
                      aria-label="add"
                      onClick={() => onSchedule(indexDate)}
                    >
                      {itemDate.day.substring(0, 2)}
                    </Fab>
                  ))}
                </Box>
                <Box sx={(theme) => ({ px: 2, mb: 2 })}>
                  <Grid container alignItems={"center"}>
                    <Grid
                      item
                      xs={12}
                      sm={3}
                      sx={(theme) => ({
                        [theme.breakpoints.down("sm")]: {
                          justifyContent: "flex-start",
                        },
                        display: "flex",
                        justifyContent: "center",
                      })}
                    >
                      <Box>
                        <Switch
                          onChange={onChangeStatusTimeAll}
                          checked={promotionSchedulesAll.select}
                        />
                        <Typography>{t("promotion_same_time")}</Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={9}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Box
                          sx={(theme) => ({
                            justifyContent: "center",
                            display: "flex",
                            alignItems: "center",
                            gap: 2,
                          })}
                        >
                          <DesktopTimePicker
                            label={t("global_start_time")}
                            name="startTime"
                            ampm={false}
                            onChange={handleTimeScheduleAllStart}
                            value={
                              !promotionSchedulesAll.select
                                ? null
                                : promotionSchedulesAll.start_time
                            }
                            sx={{
                              width: "100%",
                            }}
                            renderInput={(params) => (
                              <TextField {...params} size="small" />
                            )}
                            disabled={!promotionSchedulesAll.select}
                          />

                          <Typography>-</Typography>
                          <DesktopTimePicker
                            label={
                              promotionSchedulesAllError
                                ? t("validate_please_fill_out_correct_format")
                                : t("global_end_time")
                            }
                            value={
                              !promotionSchedulesAll.select
                                ? null
                                : promotionSchedulesAll.end_time
                            }
                            name="endDate"
                            ampm={false}
                            minTime={promotionSchedulesAll.start_time}
                            onChange={handleTimeScheduleAllEnd}
                            onError={(e) => handleErrorTimeAll(e)}
                            sx={{
                              width: "100%",
                            }}
                            renderInput={(params) => (
                              <TextField {...params} size="small" />
                            )}
                            disabled={!promotionSchedulesAll.select}
                          />
                        </Box>
                      </LocalizationProvider>
                    </Grid>
                  </Grid>
                </Box>
                <Box
                  sx={(theme) => ({
                    px: 2,
                  })}
                >
                  <Grid container alignItems={"center"}>
                    {promotionSchedules.map((itemDate, indexDate) => (
                      <>
                        {itemDate.select && (
                          <>
                            <Grid
                              item
                              xs={12}
                              sm={3}
                              sx={{ boxSizing: "border-box", px: 2, mb: 2 }}
                            >
                              <Button
                                variant="contained"
                                fullWidth
                                disabled={promotionSchedulesAll.select}
                                sx={{ borderRadius: 2 }}
                              >
                                {isBrowser
                                  ? itemDate.day
                                  : itemDate.day.substring(0, 2)}
                              </Button>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={9}
                              sx={(theme) => ({ mb: 2 })}
                            >
                              <LocalizationProvider
                                dateAdapter={AdapterDateFns}
                              >
                                <Box
                                  sx={(theme) => ({
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 2,
                                  })}
                                >
                                  <DesktopTimePicker
                                    label={t("global_start_time")}
                                    name={"start" + itemDate.day}
                                    value={itemDate.start_time}
                                    onChange={(e) =>
                                      handleTimeScheduleEachStart(e, indexDate)
                                    }
                                    ampm={false}
                                    sx={{
                                      width: "100%",
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        error
                                        helperText="Your error message"
                                        size="small"
                                      />
                                    )}
                                    disabled={promotionSchedulesAll.select}
                                  />
                                  <Typography>-</Typography>
                                  <DesktopTimePicker
                                    label={
                                      itemDate.errorFormat === "wrong"
                                        ? t(
                                            "validate_please_fill_out_correct_format"
                                          )
                                        : t("global_end_time")
                                    }
                                    value={itemDate.end_time}
                                    ampm={false}
                                    name={"end" + itemDate.day}
                                    minTime={itemDate.start_time}
                                    onChange={(e) =>
                                      handleTimeScheduleEachEnd(e, indexDate)
                                    }
                                    sx={{
                                      width: "100%",
                                    }}
                                    onError={(e) =>
                                      handleErrorTime(e, indexDate)
                                    }
                                    renderInput={(params) => (
                                      <TextField {...params} size="small" />
                                    )}
                                    disabled={promotionSchedulesAll.select}
                                  />
                                </Box>
                              </LocalizationProvider>
                            </Grid>
                          </>
                        )}
                      </>
                    ))}
                  </Grid>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </PaperWrapper>
    </Box>
  );
}

export default PromotionCreateDetail;
