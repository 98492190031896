import React, { useState, useEffect } from "react";
import { Box, Chip, Tab } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import BranchPayment from "./branchPayment";
import BranchReceipt from "./branchReceipt";
import BranchUserPOS from "./branchUserPOS";
// import BranchTaxInvoice from "./branchTaxInvoice";
import BranchDevice from "./branchDevice";
import { useTranslation } from "react-i18next";
import BranchBackScreen from "./branchBackScreen";
import Person from "@mui/icons-material/Person";
import { Money, Monitor, Receipt, Settings } from "@mui/icons-material";
import { isMobile } from "react-device-detect";
import BranchConfigure from "./branchConfigure";

function BranchPOSTab(props) {
  let { templateId, valueStepPOS, dataBranch, setCurrentProduct } = props;
  const [value, setValue] = useState(valueStepPOS);
  const { t } = useTranslation();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const fetchData = async () => {};
  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-next-line react-hooks/exhaustive-deps
  const dataTabs = [
    { name: t("staff_pos"), icon: <Person /> },
    { name: t("branch_config"), icon: <Settings /> },
    { name: t("payment_type"), icon: <Money /> },
    { name: t("receipt"), icon: <Receipt /> },
    { name: t("device_pos"), icon: <Monitor /> },
    { name: t("back_screen"), icon: <Monitor /> },
  ];
  return (
    <div align="left">
      <TabContext value={value}>
        {!isMobile ? (
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              backgroundColor: "#fff",
            }}
          >
            <TabList
              onChange={handleChange}
              aria-label="lab API tabs"
              variant="scrollable"
              scrollButtons="auto"
            >
              {dataTabs.map((itemTab, index) => (
                <Tab
                  label={itemTab.name}
                  key={index}
                  icon={itemTab.icon}
                  iconPosition="start"
                  sx={{ height: 0 }}
                  value={String(index + 1)}
                />
              ))}
            </TabList>
          </Box>
        ) : (
          <>
            <Box
              sx={(theme) => ({
                display: "flex",
                overflowX: "auto",
                gap: 2,
                width: "100%",
                p: 2,
                boxSizing: "border-box",
              })}
            >
              {dataTabs.map((item, index) => (
                <Chip
                  label={item.name}
                  key={index}
                  icon={item.icon}
                  color="primary"
                  variant={
                    value === String(index + 1) ? "contained" : "outlined"
                  }
                  onClick={(e) => handleChange(e, String(index + 1))}
                  value={String(index + 1)}
                />
              ))}
            </Box>
          </>
        )}
        <Box>
          <TabPanel
            value="1"
            sx={(theme) => ({
              p: 0,
            })}
          >
            <BranchUserPOS templateId={templateId} />
          </TabPanel>
          <TabPanel
            value="2"
            sx={(theme) => ({
              p: 0,
            })}
          >
            <BranchConfigure
              templateId={templateId}
              dataBranch={dataBranch}
              setCurrentProduct={setCurrentProduct}
            />
          </TabPanel>

          <TabPanel
            value="3"
            sx={(theme) => ({
              p: 0,
            })}
          >
            <BranchPayment
              templateId={templateId}
              setCurrentProduct={setCurrentProduct}
            />
          </TabPanel>

          <TabPanel
            value="4"
            sx={(theme) => ({
              p: 0,
            })}
          >
            <BranchReceipt templateId={templateId} dataBranch={dataBranch} />
          </TabPanel>

          <TabPanel
            value="5"
            sx={(theme) => ({
              p: 0,
            })}
          >
            <BranchDevice templateId={templateId} />
          </TabPanel>
          <TabPanel
            value="6"
            sx={(theme) => ({
              p: 0,
            })}
          >
            <BranchBackScreen templateId={templateId} />
          </TabPanel>
        </Box>
      </TabContext>
    </div>
  );
}

export default BranchPOSTab;
