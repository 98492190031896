import React, { useContext, useEffect, useState } from "react";
import {
  configGetAllPromotion,
  configUpdatePromotion,
} from "../../../../service/branchConfigAPI/branchConfigPromotion";
import { Box, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import {
  notifyDefault,
  notifySuccess,
} from "../../../../components/ui/popup/toastPopup";
import Loading from "../../../../components/ui/loading";
import moment from "moment";
import DataNotFound from "../../../../components/ui/dataNotFound";
import { useNavigate } from "react-router-dom";
import PromotionCard from "./promotionCard";
import { PaddingContainer } from "../../../../components/utils/paddingContainer";
import { isBrowser, isMobile, isTablet } from "react-device-detect";
import EmptyDataDirect from "../../../../components/ui/emptyDataDirect";
import ViewMode from "./viewMode";
import ViewMore from "./viewMore";
import DataMaterialTable from "../../../../components/ui/dataTable/dataMaterialTable";
import { TextTable } from "../../../../components/ui/textfield/textfieldCustom";
import StatusTable from "../../../../components/chip/statusTable";
import { ActionDetail } from "../../../../components/ui/dataTable/components/actionDetail";
import { BoxImage } from "../../../../components/ui/boxImage/boxImage";
import { BoxImageBlank } from "../../../../components/ui/boxImage/boxImageBlank";
import { getImage } from "../../../../service/upload/getImage";
import { UseConvertImageToBase64 } from "../../../../components/hooks/use-convert-image-to-base-64";
import { MyContext } from "../../../../context/MyContext";
import { BoxFormTitleAction } from "../../../../components/ui/box/boxFormTitleAction";
import TitleHeaderForm from "../../../../components/ui/formComponent/titleHeaderForm";
function BranchPromotion({ props }) {
  let { templateId } = props;
  const { dataBranchPromotion, setDataBranchPromotion } = useContext(MyContext);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [viewMode, setViewMode] = useState({
    activeMode: "grid",
    inActiveMode: "grid",
    promotionExpire: "grid",
  });
  const [viewMore, setViewMore] = useState({
    activeMode: false,
    inActiveMode: false,
    promotionExpire: false,
  });
  const showItem = isMobile ? 3 : isTablet ? 4 : isBrowser ? 6 : 6;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const fetchData = async () => {
    setIsLoading(true);

    let promotionAPI = [];
    let dataContext = [...dataBranchPromotion];
    if (
      dataContext.find((item) => item.template_id === templateId) === undefined
    ) {
      promotionAPI = (await configGetAllPromotion(templateId)).sort(
        (a, b) => new Date(b.updated_at) - new Date(a.updated_at)
      );
      fetchDataImage(dataContext, promotionAPI);
    } else {
      promotionAPI = dataContext.find(
        (item) => item.template_id === templateId
      ).data;
      promotionAPI.forEach(async (item, index) => {
        if (item.promotion.status === false) {
          item.status = false;
          let dataAPI = {
            template_id: String(templateId),
            promotion_id: String(item.promotion_id),
            priority: 1,
            status: false,
          };
          await configUpdatePromotion(dataAPI);
        }
      });
      let dataNews = promotionAPI.map((item) => ({
        id: item.id,
        promotion_id: item.promotion_id,
        image: item.promotion.image,
        name: item.promotion.promotion_lang.name,
        start: item.promotion.start,
        end: item.promotion.end,
        status: item.status,
        statusPromotion: item.promotion.status,
      }));
      setData(dataNews);
      setIsLoading(false);
    }
  };

  const fetchDataImage = async (dataContext, promotionAPI) => {
    const promises = promotionAPI.map(async (item) => {
      let imageUrl = await getImage(item.promotion.image);
      item.promotion.image = UseConvertImageToBase64(imageUrl);
    });
    await Promise.all(promises);
    dataContext.push({ template_id: templateId, data: promotionAPI });
    setDataBranchPromotion(dataContext);

    promotionAPI.forEach(async (item, index) => {
      if (item.promotion.status === false) {
        item.status = false;
        let dataAPI = {
          template_id: String(templateId),
          promotion_id: String(item.promotion_id),
          priority: 1,
          status: false,
        };
        await configUpdatePromotion(dataAPI);
      }
    });
    let dataNews = promotionAPI.map((item) => ({
      id: item.id,
      promotion_id: item.promotion_id,
      image: item.promotion.image,
      name: item.promotion.promotion_lang.name,
      start: item.promotion.start,
      end: item.promotion.end,
      status: item.status,
      statusPromotion: item.promotion.status,
    }));
    setData(dataNews);
    setIsLoading(false);
  };

  const onUpdateConfirm = async (item, e, indexPromotion) => {
    let checked = e.target.checked;
    if (checked) {
      onUpdateStatus(item, checked, indexPromotion);
    } else {
      Swal.fire({
        title: t("notification_confirm_change_status"),
        icon: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          onUpdateStatus(item, checked, indexPromotion);
        }
      });
    }
  };
  const onUpdateStatus = async (item, checked, indexPromotion) => {
    let dataNews = [...data];
    let findIndex = dataNews.find(
      (itemFind) => itemFind.promotion_id === item.promotion_id
    );
    findIndex.status = checked;
    setData(dataNews);
    let dataAPI = {
      template_id: String(templateId),
      promotion_id: String(item.promotion_id),
      priority: 1,
      status: checked,
    };
    let dataStatus = await configUpdatePromotion(dataAPI);
    if (dataStatus === "OK") {
      if (checked) {
        notifySuccess({ title: t("toast_active") });
      } else {
        notifyDefault({ title: t("toast_in_active") });
      }
    }
  };
  const onDirectToDetail = async (itemId) => {
    navigate("/promotions/detail/" + itemId);
  };

  let dataColumns = [
    {
      accessorKey: "image",
      size: 50,
      enableSorting: false,
      header: t("global_image"),
      Cell: ({ row, cell }) => (
        <Box sx={{ display: "flex", gap: 1 }}>
          <Box>
            {cell.getValue() ? (
              <BoxImage
                image={cell.getValue()}
                width={"50px"}
                height={"50px"}
                type="table"
              />
            ) : (
              <BoxImageBlank width={50} height={50} />
            )}
          </Box>
        </Box>
      ),
    },
    {
      accessorKey: "name",
      size: 100,
      header: t("global_name"),
      Cell: ({ cell }) => <TextTable props={{ text: cell.getValue() }} />,
    },
    {
      accessorKey: "start",
      size: 100,
      header: t("global_start_date"),
      Cell: ({ cell }) => (
        <TextTable
          props={{
            text: moment(cell.getValue(), "YYYYMMDDHHmmss").format(
              "DD/MM/YYYY"
            ),
          }}
        />
      ),
    },
    {
      accessorKey: "end",
      size: 100,
      header: t("global_end_date"),
      Cell: ({ cell }) => (
        <TextTable
          props={{
            text: moment(cell.getValue(), "YYYYMMDDHHmmss").format(
              "DD/MM/YYYY"
            ),
          }}
        />
      ),
    },
    {
      accessorKey: "status",
      enableSorting: false,
      size: 20,
      header: t("global_status"),
      Cell: ({ row }) => (
        <StatusTable props={{ status: row.original.status }} />
      ),
    },
    {
      accessorKey: "action",
      enableSorting: false,
      size: 100,
      header: "Action",
      Cell: ({ row }) => (
        <Box sx={{ display: "flex", gap: 2 }}>
          <ActionDetail params={row} detailPath={"/promotions/detail/"} />
        </Box>
      ),
    },
  ];
  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <Grid
            container
            justifyContent={"space-between"}
            p={PaddingContainer.size}
          >
            {data.length > 0 ? (
              <>
                <Grid
                  container
                  direction="row"
                  justifyContent={"space-between"}
                  className="grid-paper-white"
                  mb={2}
                >
                  <Grid item xs={12}>
                    <BoxFormTitleAction>
                      <TitleHeaderForm
                        props={{ text: t("promotion_active") }}
                      />
                      <ViewMode
                        props={{
                          setViewMode,
                          viewMode,
                          name: "activeMode",
                        }}
                      />
                    </BoxFormTitleAction>
                    <Grid container justifyContent={"flex-start"} p={2}>
                      {viewMode.activeMode === "grid" ? (
                        <>
                          {data
                            .filter(
                              (item) =>
                                item.status === true &&
                                Number(
                                  moment(item.end, "YYYYMMDDHHmmss").format(
                                    "YYYYMMDDHHmmss"
                                  )
                                ) >=
                                  Number(
                                    moment().format("YYYYMMDDHHmmss") ||
                                      item.end === ""
                                  )
                            )
                            .slice(
                              0,
                              viewMore.activeMode ? undefined : showItem
                            )
                            .map((itemPromotion, indexPromotion) => (
                              <>
                                {
                                  <PromotionCard
                                    props={{
                                      onDirectToDetail,
                                      itemPromotion,
                                      onUpdateConfirm,
                                      disabled: false,
                                      indexPromotion,
                                    }}
                                  />
                                }
                              </>
                            ))}
                          {data.filter(
                            (item) =>
                              item.status === true &&
                              Number(
                                moment(item.end, "YYYYMMDDHHmmss").format(
                                  "YYYYMMDDHHmmss"
                                )
                              ) >= Number(moment().format("YYYYMMDDHHmmss"))
                          ).length > 6 ? (
                            <Box
                              sx={{ display: "flex", justifyContent: "center" }}
                            ></Box>
                          ) : null}
                        </>
                      ) : (
                        <>
                          <DataMaterialTable
                            statusViewMode={false}
                            rows={data.filter(
                              (item) =>
                                item.status === true &&
                                Number(
                                  moment(item.end, "YYYYMMDDHHmmss").format(
                                    "YYYYMMDDHHmmss"
                                  )
                                ) >=
                                  Number(
                                    moment().format("YYYYMMDDHHmmss") ||
                                      item.end === ""
                                  )
                            )}
                            columns={dataColumns}
                          />
                        </>
                      )}
                      {data.filter(
                        (item) =>
                          item.status === true &&
                          Number(
                            moment(item.end, "YYYYMMDDHHmmss").format(
                              "YYYYMMDDHHmmss"
                            )
                          ) >=
                            Number(
                              moment().format("YYYYMMDDHHmmss") ||
                                item.end === ""
                            )
                      ).length === 0 && <DataNotFound />}
                    </Grid>
                    {viewMode.activeMode === "grid" &&
                    data.filter(
                      (item) =>
                        item.status === true &&
                        Number(
                          moment(item.end, "YYYYMMDDHHmmss").format(
                            "YYYYMMDDHHmmss"
                          )
                        ) >=
                          Number(
                            moment().format("YYYYMMDDHHmmss") || item.end === ""
                          )
                    ).length > 6 ? (
                      <>
                        <ViewMore
                          props={{
                            setViewMore,
                            viewMore,
                            name: "activeMode",
                          }}
                        />
                      </>
                    ) : (
                      <></>
                    )}
                  </Grid>
                </Grid>
                <Grid
                  container
                  direction="row"
                  justifyContent={"space-between"}
                  className="grid-paper-white"
                  mb={2}
                >
                  <Grid item xs={12}>
                    <BoxFormTitleAction>
                      <TitleHeaderForm
                        props={{ text: t("promotion_in_active") }}
                      />
                      <ViewMode
                        props={{
                          setViewMode,
                          viewMode,
                          name: "inActiveMode",
                        }}
                      />
                    </BoxFormTitleAction>
                    <Grid container justifyContent={"flex-start"} p={2}>
                      {viewMode.inActiveMode === "grid" ? (
                        <>
                          {data
                            .filter(
                              (item) =>
                                item.status === false &&
                                (Number(
                                  moment(item.end, "YYYYMMDDHHmmss").format(
                                    "YYYYMMDDHHmmss"
                                  )
                                ) >=
                                  Number(moment().format("YYYYMMDDHHmmss")) ||
                                  item.end === "")
                            )
                            .slice(0, viewMore.inActiveMode ? -1 : showItem)
                            .map((itemPromotion, indexPromotion) => (
                              <>
                                {
                                  <PromotionCard
                                    props={{
                                      onDirectToDetail,
                                      itemPromotion,
                                      onUpdateConfirm,
                                      disabled:
                                        itemPromotion.statusPromotion === false
                                          ? true
                                          : false,
                                      indexPromotion,
                                    }}
                                  />
                                }
                              </>
                            ))}
                          {data.filter(
                            (item) =>
                              item.status === false &&
                              Number(
                                moment(item.end, "YYYYMMDDHHmmss").format(
                                  "YYYYMMDDHHmmss"
                                )
                              ) >= Number(moment().format("YYYYMMDDHHmmss"))
                          ).length === 0 && <DataNotFound />}
                          {data.filter(
                            (item) =>
                              item.status === false &&
                              Number(
                                moment(item.end, "YYYYMMDDHHmmss").format(
                                  "YYYYMMDDHHmmss"
                                )
                              ) >= Number(moment().format("YYYYMMDDHHmmss"))
                          ).length > 6 ? (
                            <Box
                              sx={{ display: "flex", justifyContent: "center" }}
                            ></Box>
                          ) : null}
                        </>
                      ) : (
                        <>
                          <DataMaterialTable
                            statusViewMode={false}
                            rows={data.filter(
                              (item) =>
                                item.status === false &&
                                (Number(
                                  moment(item.end, "YYYYMMDDHHmmss").format(
                                    "YYYYMMDDHHmmss"
                                  )
                                ) >=
                                  Number(moment().format("YYYYMMDDHHmmss")) ||
                                  item.end === "")
                            )}
                            columns={dataColumns}
                          />
                        </>
                      )}
                    </Grid>
                    {viewMode.inActiveMode === "grid" &&
                    data.filter(
                      (item) =>
                        item.status === false &&
                        (Number(
                          moment(item.end, "YYYYMMDDHHmmss").format(
                            "YYYYMMDDHHmmss"
                          )
                        ) >= Number(moment().format("YYYYMMDDHHmmss")) ||
                          item.end === "")
                    ).length > 6 ? (
                      <>
                        <ViewMore
                          props={{
                            setViewMore,
                            viewMore,
                            name: "inActiveMode",
                          }}
                        />
                      </>
                    ) : (
                      <></>
                    )}
                  </Grid>
                </Grid>
                <Grid
                  container
                  direction="row"
                  justifyContent={"space-between"}
                  className="grid-paper-white"
                >
                  <Grid item xs={12}>
                    <BoxFormTitleAction>
                      <TitleHeaderForm
                        props={{ text: t("promotion_expired") }}
                      />
                      <ViewMode
                        props={{
                          setViewMode,
                          viewMode,
                          name: "promotionExpire",
                        }}
                      />
                    </BoxFormTitleAction>
                    <Grid container justifyContent={"flex-start"} p={2}>
                      {viewMode.promotionExpire === "grid" ? (
                        <>
                          {data
                            .filter(
                              (item) =>
                                Number(
                                  moment(item.end, "YYYYMMDDHHmmss").format(
                                    "YYYYMMDDHHmmss"
                                  )
                                ) < Number(moment().format("YYYYMMDDHHmmss"))
                            )
                            .slice(
                              0,
                              viewMore.promotionExpire ? undefined : showItem
                            )
                            .map((itemPromotion, indexPromotion) => (
                              <>
                                {
                                  <PromotionCard
                                    props={{
                                      onDirectToDetail,
                                      itemPromotion,
                                      onUpdateConfirm,
                                      disabled: true,
                                      indexPromotion,
                                    }}
                                  />
                                }
                              </>
                            ))}
                          {data.filter(
                            (item) =>
                              Number(
                                moment(item.end, "YYYYMMDDHHmmss").format(
                                  "YYYYMMDDHHmmss"
                                )
                              ) < Number(moment().format("YYYYMMDDHHmmss"))
                          ).length > 6 ? (
                            <Box
                              sx={{ display: "flex", justifyContent: "center" }}
                            ></Box>
                          ) : null}
                        </>
                      ) : (
                        <>
                          <DataMaterialTable
                            statusViewMode={false}
                            rows={data.filter(
                              (item) =>
                                Number(
                                  moment(item.end, "YYYYMMDDHHmmss").format(
                                    "YYYYMMDDHHmmss"
                                  )
                                ) < Number(moment().format("YYYYMMDDHHmmss"))
                            )}
                            columns={dataColumns}
                          />
                        </>
                      )}
                      {data.filter(
                        (item) =>
                          Number(
                            moment(item.end, "YYYYMMDDHHmmss").format(
                              "YYYYMMDDHHmmss"
                            )
                          ) < Number(moment().format("YYYYMMDDHHmmss"))
                      ).length === 0 && <DataNotFound />}
                    </Grid>

                    {viewMode.promotionExpire === "grid" &&
                    data.filter(
                      (item) =>
                        Number(
                          moment(item.end, "YYYYMMDDHHmmss").format(
                            "YYYYMMDDHHmmss"
                          )
                        ) < Number(moment().format("YYYYMMDDHHmmss"))
                    ).length > 6 ? (
                      <>
                        <ViewMore
                          props={{
                            setViewMore,
                            viewMore,
                            name: "promotionExpire",
                          }}
                        />
                      </>
                    ) : (
                      <></>
                    )}
                  </Grid>
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={12}>
                  {data.length === 0 ? (
                    <EmptyDataDirect
                      props={{ url: "/promotions", text: t("promotion") }}
                    />
                  ) : null}
                </Grid>
              </>
            )}
          </Grid>
        </>
      )}
    </>
  );
}

export default BranchPromotion;
