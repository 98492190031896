import {
  Box,
  Button,
  Fab,
  Grid,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { DesktopTimePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { isBrowser } from "react-device-detect";
import PaperWrapper from "../../../../components/ui/paper/paperWrapper";
import { getSalePickupBranchSchedule } from "../../../../service/selfPickUp/config/getSelfPickupBranchSchedule";
import moment from "moment";
import ConfirmButton from "../../../../components/ui/button/confirmButton";
import Loading from "../../../../components/ui/loading";
import { updateSelfPickupBranchSchedule } from "../../../../service/selfPickUp/config/updateSelfPickupBranchSchedule";
import { notifySuccess } from "../../../../components/ui/popup/toastPopup";

function SelfPickupOpenClosed({ props }) {
  let { paramsId } = props;
  const [promotionSchedulesAll, setPromotionSchedulesAll] = useState({
    open: new Date().setHours(8, 0, 0),
    close: new Date().setHours(18, 0, 0),
    select: false,
  });
  const [isLoading, setIsLoading] = useState(true);
  const handleErrorTime = (newValue, index) => {
    let dataNews = [...promotionSchedules];
    if (newValue) {
      dataNews[index].errorFormat = "wrong";
    } else {
      delete dataNews[index].errorFormat;
    }
    setPromotionSchedules(dataNews);
  };

  const handleErrorTimeAll = (newValue) => {
    if (newValue) {
      setPromotionSchedulesAllError(true);
    } else {
      setPromotionSchedulesAllError(false);
    }
  };
  const [promotionSchedulesAllError, setPromotionSchedulesAllError] =
    useState(false);
  const [promotionSchedules, setPromotionSchedules] = useState([
    {
      day: "Sunday",
      open: new Date().setHours(8, 0, 0),
      close: new Date().setHours(18, 0, 0),
      select: true,
    },
    {
      day: "Monday",
      open: new Date().setHours(8, 0, 0),
      close: new Date().setHours(18, 0, 0),
      select: false,
    },
    {
      day: "Tuesday",
      open: new Date().setHours(8, 0, 0),
      close: new Date().setHours(18, 0, 0),
      select: false,
    },
    {
      day: "Wednesday",
      open: new Date().setHours(8, 0, 0),
      close: new Date().setHours(18, 0, 0),
      select: false,
    },
    {
      day: "Thursday",
      open: new Date().setHours(8, 0, 0),
      close: new Date().setHours(18, 0, 0),
      select: false,
    },
    {
      day: "Friday",
      open: new Date().setHours(8, 0, 0),
      close: new Date().setHours(18, 0, 0),
      select: false,
    },
    {
      day: "Saturday",
      open: new Date().setHours(8, 0, 0),
      close: new Date().setHours(18, 0, 0),
      select: false,
    },
  ]);
  const onSchedule = (index) => {
    setPromotionSchedules((prevSchedules) => {
      const updatedSchedules = [...prevSchedules];
      updatedSchedules[index].select = !updatedSchedules[index].select;
      return updatedSchedules;
    });
  };
  const onChangeStatusTimeAll = (e) => {
    setPromotionSchedulesAll((prevSchedules) => {
      const newItem = { ...prevSchedules };
      newItem.select = e.target.checked;
      return newItem;
    });

    let dataNews = [...promotionSchedules];
    dataNews.forEach((item) => {
      delete item.errorFormat;
    });
  };
  const handleTimeScheduleAllStart = (newValue) => {
    setPromotionSchedulesAll({
      ...promotionSchedulesAll,
      open: newValue,
    });
  };
  const handleTimeScheduleAllEnd = (newValue) => {
    setPromotionSchedulesAll({
      ...promotionSchedulesAll,
      close: newValue,
    });
  };
  const handleTimeScheduleEachStart = (newValue, index) => {
    let dateNews = [...promotionSchedules];
    dateNews[index].open = new Date(newValue);
    setPromotionSchedules(dateNews);
  };
  const handleTimeScheduleEachEnd = (newValue, index) => {
    let dateNews = [...promotionSchedules];
    dateNews[index].close = new Date(newValue);
    setPromotionSchedules(dateNews);
  };
  const fetchData = async () => {
    let dataAPI = await getSalePickupBranchSchedule(paramsId);

    let dataSchedulesBox = [
      {
        day: "sunday",
        open: new Date(),
        close: new Date(),
        select: false,
      },
      {
        day: "monday",
        open: new Date(),
        close: new Date(),
        select: false,
      },
      {
        day: "tuesday",
        open: new Date(),
        close: new Date(),
        select: false,
      },
      {
        day: "wednesday",
        open: new Date(),
        close: new Date(),
        select: false,
      },
      {
        day: "thursday",
        open: new Date(),
        close: new Date(),
        select: false,
      },
      {
        day: "friday",
        open: new Date(),
        close: new Date(),
        select: false,
      },
      {
        day: "saturday",
        open: new Date(),
        close: new Date(),
        select: false,
      },
    ];
    if (dataAPI.length > 0) {
      if (
        dataAPI.every((item) => item.open === dataAPI[0].open) &&
        dataAPI.every((item) => item.close === dataAPI[0].close)
      ) {
        setPromotionSchedulesAll({
          open: moment(dataAPI[0].open, "HHmmss").toDate(),
          close: moment(dataAPI[0].close, "HHmmss").toDate(),
          select: true,
        });
      }
      dataSchedulesBox.forEach((itemDate) => {
        let dateNews = dataAPI.find(
          (promotions) => promotions.day === itemDate.day
        );
        if (dateNews) {
          itemDate.open = moment(dateNews.open, "HHmmss").toDate();
          itemDate.close = moment(dateNews.close, "HHmmss").toDate();
          itemDate.select = true;
        }
      });
    }
    setPromotionSchedules(dataSchedulesBox);
    setIsLoading(false);
  };
  const onConfirmForm = async () => {
    let dataNew = promotionSchedules.filter(
      (itemPromotion) => itemPromotion.select === true
    );
    let promotionSchedulesBox = [];
    let statusDate = promotionSchedulesAll.select;
    dataNew.forEach((itemPromotionDate) => {
      promotionSchedulesBox.push({
        day: itemPromotionDate.day,
        open: moment(
          statusDate ? promotionSchedulesAll.open : itemPromotionDate.open
        ).format("HHmmss"),
        close: moment(
          statusDate ? promotionSchedulesAll.close : itemPromotionDate.close
        ).format("HHmmss"),
        open_day: itemPromotionDate.select,
      });
    });
    let dataAPI = {
      branch_id: String(paramsId),
      schedules: promotionSchedulesBox,
    };
    let statusAPI = await updateSelfPickupBranchSchedule(dataAPI);
    if (statusAPI === "OK") {
      notifySuccess({
        title: t("toast_successfully_updated_information"),
      });
    }
  };
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Box
      sx={(theme) => ({
        display: "flex",
        alignItems: "flex-start",
        width: "100%",
        boxSizing: "border-box",
        p: 2,
      })}
    >
      {isLoading ? (
        <Loading />
      ) : (
        <PaperWrapper props={{ flexGrow: 1, setting: { p: 2 } }}>
          <Box
            sx={{
              width: "100%",
            }}
          >
            <Box mb={2}>
              <Typography sx={(theme) => ({ fontWeight: "bold" })}>
                {t("วันที่สำหรับใช้เปิดการใช้งานระบบ")}
              </Typography>
              <Typography sx={(theme) => ({ fontSize: 13 })}>
                {t("promotion_select_the_desire_date")}
              </Typography>
            </Box>
            <Box
              sx={{
                width: "100%",
                boxSizing: "border-box",
                border: "1px solid #cccccc",
                borderRadius: 1,
              }}
            >
              <Box
                sx={(theme) => ({
                  display: "flex",
                  justifyContent: "space-evenly",
                  p: 1,
                  my: 2,
                })}
              >
                {promotionSchedules.map((itemDate, indexDate) => (
                  <Fab
                    size="small"
                    sx={(theme) => ({ boxShadow: 0 })}
                    color={itemDate?.select ? "primary" : "default"}
                    aria-label="add"
                    onClick={() => onSchedule(indexDate)}
                  >
                    {itemDate.day.substring(0, 2)}
                  </Fab>
                ))}
              </Box>
              <Box sx={(theme) => ({ px: 2, mb: 2, boxSizing: "border-box" })}>
                <Grid container alignItems={"center"}>
                  <Grid
                    item
                    xs={12}
                    sm={3}
                    sx={(theme) => ({
                      [theme.breakpoints.down("sm")]: {
                        justifyContent: "flex-start",
                      },
                      display: "flex",
                      justifyContent: "center",
                    })}
                  >
                    <Box>
                      <Switch
                        onChange={onChangeStatusTimeAll}
                        checked={promotionSchedulesAll.select}
                      />
                      <Typography>{t("promotion_same_time")}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={9}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <Box
                        sx={(theme) => ({
                          justifyContent: "center",
                          display: "flex",
                          alignItems: "center",
                          gap: 2,
                        })}
                      >
                        <DesktopTimePicker
                          label={t("global_open")}
                          name="startDate"
                          ampm={false}
                          onChange={handleTimeScheduleAllStart}
                          value={
                            !promotionSchedulesAll.select
                              ? null
                              : promotionSchedulesAll.open
                          }
                          sx={{
                            width: "100%",
                          }}
                          renderInput={(params) => (
                            <TextField {...params} size="small" />
                          )}
                          disabled={!promotionSchedulesAll.select}
                        />

                        <Typography>-</Typography>
                        <DesktopTimePicker
                          label={
                            promotionSchedulesAllError
                              ? t("validate_please_fill_out_correct_format")
                              : t("global_close")
                          }
                          value={
                            !promotionSchedulesAll.select
                              ? null
                              : promotionSchedulesAll.close
                          }
                          name="endDate"
                          ampm={false}
                          minTime={promotionSchedulesAll.open}
                          onChange={handleTimeScheduleAllEnd}
                          onError={(e) => handleErrorTimeAll(e)}
                          sx={{
                            width: "100%",
                          }}
                          renderInput={(params) => (
                            <TextField {...params} size="small" />
                          )}
                          disabled={!promotionSchedulesAll.select}
                        />
                      </Box>
                    </LocalizationProvider>
                  </Grid>
                </Grid>
              </Box>
              <Box
                sx={(theme) => ({
                  px: 2,
                })}
              >
                <Grid container alignItems={"center"}>
                  {promotionSchedules.map((itemDate, indexDate) => (
                    <>
                      {itemDate.select && (
                        <>
                          <Grid
                            item
                            xs={12}
                            sm={3}
                            sx={{ boxSizing: "border-box", px: 2, mb: 2 }}
                          >
                            <Button
                              variant="contained"
                              fullWidth
                              disabled={promotionSchedulesAll.select}
                              sx={{ borderRadius: 2 }}
                            >
                              {isBrowser
                                ? itemDate.day
                                : itemDate.day.substring(0, 2)}
                            </Button>
                          </Grid>
                          <Grid item xs={12} sm={9} sx={(theme) => ({ mb: 2 })}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <Box
                                sx={(theme) => ({
                                  display: "flex",
                                  alignItems: "center",
                                  gap: 2,
                                })}
                              >
                                <DesktopTimePicker
                                  label={t("global_open")}
                                  name={"start" + itemDate.day}
                                  value={itemDate.open}
                                  onChange={(e) =>
                                    handleTimeScheduleEachStart(e, indexDate)
                                  }
                                  ampm={false}
                                  sx={{
                                    width: "100%",
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      error
                                      helperText="Your error message"
                                      size="small"
                                    />
                                  )}
                                  disabled={promotionSchedulesAll.select}
                                />
                                <Typography>-</Typography>
                                <DesktopTimePicker
                                  label={
                                    itemDate.errorFormat === "wrong"
                                      ? t(
                                          "validate_please_fill_out_correct_format"
                                        )
                                      : t("global_close")
                                  }
                                  value={itemDate.close}
                                  ampm={false}
                                  name={"end" + itemDate.day}
                                  minTime={itemDate.open}
                                  onChange={(e) =>
                                    handleTimeScheduleEachEnd(e, indexDate)
                                  }
                                  sx={{
                                    width: "100%",
                                  }}
                                  onError={(e) => handleErrorTime(e, indexDate)}
                                  renderInput={(params) => (
                                    <TextField {...params} size="small" />
                                  )}
                                  disabled={promotionSchedulesAll.select}
                                />
                              </Box>
                            </LocalizationProvider>
                          </Grid>
                        </>
                      )}
                    </>
                  ))}
                </Grid>
              </Box>
            </Box>
          </Box>
          <Box sx={{ mt: 2 }}>
            <ConfirmButton onClick={() => onConfirmForm()} />
          </Box>
        </PaperWrapper>
      )}
    </Box>
  );
}

export default SelfPickupOpenClosed;
