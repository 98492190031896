import { Box, Typography } from "@mui/material";
import React from "react";

function CardPromotionType({ props }) {
  const { handleChangeFc, icon, text, receiptItemType, valueCard } = props;
  return (
    <Box
      sx={(theme) => ({
        p: 2,
        boxSizing: "border-box",
        border: "1px solid #cccccc",
        borderRadius: 1,
        width: "100%",
        backgroundColor:
          receiptItemType === valueCard ? theme.palette.primary.main : null,
        color: receiptItemType === valueCard ? "#ffffff" : null,
        ":hover": { cursor: "pointer", opacity: 0.8 },
      })}
      onClick={() => handleChangeFc()}
      align="center"
    >
      {icon}
      <Typography>{text}</Typography>
    </Box>
  );
}

export default CardPromotionType;
