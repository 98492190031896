import React, { useState, useEffect, useContext } from "react";
import BreadcrumbsTitle from "../../../components/ui/breadcrumbsTitle";
import { useTranslation } from "react-i18next";
import {
  Box,
  Chip,
  Divider,
  Grid,
  List,
  Paper,
  Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import moment from "moment";
import { uploadImage } from "../../../service/upload/uploadImage";
import {
  addPromotionToTemplate,
  getAllPromotionTemplate,
  getPromotion,
  updatePromotion,
} from "../../../service/promotionAPI";
import { notifySuccess } from "../../../components/ui/popup/toastPopup";
import { useNavigate } from "react-router-dom";
import Loading from "../../../components/ui/loading";
import { getAllSaleProduct } from "../../../service/saleProductAPI";
import SettingTypeBranch from "../../../components/ui/addTypeTemplate/settingTypeBranch";
import { getAllBranch } from "../../../service/branchAPI";
import Swal from "sweetalert2";
import { WarningPopup } from "../../../components/ui/popup/successPopup";
import { getAllReceiptType } from "../../../service/orderType";
import { promotionSchema } from "../../../components/validation/promotionSchema";
import { useSchema } from "../../../components/hooks/use-schema";
import CategoryIcon from "@mui/icons-material/Category";
import serviceType from "../../../components/utils/serviceType.json";
// import { ServiceStatus } from "../../../service/getLocalStorage";
import { GridContainerSize } from "../../../components/utils/gridContainerSize";
import PaperWrapper from "../../../components/ui/paper/paperWrapper";
import dayjs from "dayjs";
import { BoxImage } from "../../../components/ui/boxImage/boxImage";
import ResultDiscountTypeList from "../../../components/utils/resultDiscountType.json";

import i18next from "i18next";
import DateRange from "@mui/icons-material/DateRange";
import {
  AccessTime,
  CalendarToday,
  Check,
  Close,
  ConfirmationNumber,
  Inbox,
} from "@mui/icons-material";
import { getImage } from "../../../service/upload/getImage";
import { UseConvertImageToBase64 } from "../../../components/hooks/use-convert-image-to-base-64";
import { getAllMainCategory } from "../../../service/mainCategory";
import PromotionService from "./promotionCreateStep/promotionService";
import PromotionCondition from "./promotionCreateStep/promotionCondition";
import PromotionResult from "./promotionCreateStep/promotionResult";
import PromotionCreateDetail from "./promotionCreateStep/promotionCreateDetail";
import StatusForm from "../../../components/ui/formComponent/statusForm";
import { SaleProductDialog } from "../../../components/ui/productSet/saleProductDialog";
import DialogAddCategory from "../../../components/ui/categorySet/dialogAddCategory";
import DialogCoupon from "../../../components/ui/dialog/dialog-coupon/dialogCoupon";
import ConfirmButton from "../../../components/ui/button/confirmButton";
import { MyContext } from "../../../context/MyContext";
import { BoxWrapper } from "../../../components/ui/box/boxWrapper";
import { BoxFormTitleAction } from "../../../components/ui/box/boxFormTitleAction";
import TitleHeaderForm from "../../../components/ui/formComponent/titleHeaderForm";
import { BoxGapInput } from "../../../components/ui/box/boxGapInput";

export default function PromotionDetail() {
  const [couponCode, setCouponCode] = useState({
    status: false,
    type: "unlimit",
  });
  const [limitUse, setLimitUse] = useState({
    status: false,
    data: null,
  });
  const [data, setData] = useState(null);
  const {
    fetchPromotion,
    setDataBranchPromotion,
    saleProductSetAll,
    setSaleProductSetAll,
    fetchDataImageSaleProduct,
    salePromotionSetAll,
  } = useContext(MyContext);
  const [menuConditionMinimum, setMenuConditionMinimum] = useState(null);
  const [resultDiscountType, setResultDiscountType] = useState(null);
  const [discountType, setDiscountType] = useState(null);
  const [dialogProduct, setDialogProduct] = useState(false);
  const [dialogProductFree, setDialogProductFree] = useState(false);
  const [dialogCategory, setDialogCategory] = useState(false);
  const [dialogCategoryFree, setDialogCategoryFree] = useState(false);
  const [dialogCoupon, setDialogCoupon] = useState(false);
  const {
    register,
    formState: { errors },
    setValue,
    clearErrors,
    watch,
    handleSubmit,
    control,
  } = useForm(
    useSchema(
      promotionSchema(
        resultDiscountType,
        menuConditionMinimum,
        couponCode,
        limitUse.status,
        discountType
      )
    )
  );
  const [serviceSelect, setServiceSelect] = useState(null);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [allDataTemplate, setAllDataTemplate] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingBranch, setIsLoadingBranch] = useState(true);
  const paramsId =
    window.location.pathname.split("/")[
      window.location.pathname.split("/").length - 1
    ];
  const [productAll, setProductAll] = useState(null);
  const [dataAllProduct, setDataAllProduct] = useState([]);

  const [productAllFree, setProductAllFree] = useState(null);
  const [dataAllProductFree, setDataAllProductFree] = useState([]);

  const [categoryAll, setCategoryAll] = useState(null);
  const [dataAllCategory, setDataAllCategory] = useState([]);

  const [categoryAllFree, setCategoryAllFree] = useState(null);
  const [dataAllCategoryFree, setDataAllCategoryFree] = useState([]);

  const [receiptItemType, setReceiptItemType] = useState(null);
  const [receiptItemTypeFree, setReceiptItemTypeFree] = useState(null);

  const [resultDiscountPercent, setResultDiscountPercent] = useState();
  const [resultDiscountPrice, setResultDiscountPrice] = useState();
  const [resultItemFreeAmount, setResultItemFreeAmount] = useState(0);

  const [image, setImage] = useState();
  const [receiptTypeAll, setReceiptTypeAll] = useState([]);

  const [couponAll, setCouponAll] = useState([]);
  const [autoApply, setAutoApply] = useState(false);

  const [datePromotionStatus, setDatePromotionStatus] = useState(false);
  const [startDate, setStartDate] = useState(dayjs().startOf("date"));
  const [endDate, setEndDate] = useState(dayjs().endOf("date"));
  const [statusButton, setStatusButton] = useState(true);
  const [promotionSchedulesAll, setPromotionSchedulesAll] = useState({
    start_time: new Date(),
    end_time: new Date(),
    select: false,
  });
  const [promotionSchedulesAllError, setPromotionSchedulesAllError] =
    useState(false);
  // const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [saleProductAllAPI, setSaleProductAllAPI] = useState([]);
  const [categoryAllAPI, setCategoryAllAPI] = useState([]);
  const [promotionSchedules, setPromotionSchedules] = useState([
    {
      day: "Sunday",
      start_time: new Date(),
      end_time: new Date(),
      select: false,
    },
    {
      day: "Monday",
      start_time: new Date(),
      end_time: new Date(),
      select: false,
    },
    {
      day: "Tuesday",
      start_time: new Date(),
      end_time: new Date(),
      select: false,
    },
    {
      day: "Wednesday",
      start_time: new Date(),
      end_time: new Date(),
      select: false,
    },
    {
      day: "Thursday",
      start_time: new Date(),
      end_time: new Date(),
      select: false,
    },
    {
      day: "Friday",
      start_time: new Date(),
      end_time: new Date(),
      select: false,
    },
    {
      day: "Saturday",
      start_time: new Date(),
      end_time: new Date(),
      select: false,
    },
  ]);

  const [promotionIsStarted, setPromotionIsStarted] = useState(false);
  const [datePromotionError, setDatePromotionError] = useState(false);
  const [promotionConjunction, setPromotionConjunction] = useState(false);
  const [promotionMultipleTime, setPromotionMultipleTime] = useState(false);
  const [promotionConjunctionListName, setPromotionConjunctionListName] =
    useState([]);
  const [promotionConjunctionListId, setPromotionConjunctionListId] = useState(
    []
  );
  const onConfirmCreatePromotion = async (register) => {
    if (localStorage.getItem("uploadImage")) {
      WarningPopup({ title: t("notification_please_upload_image") });
    } else {
      // setIsButtonDisabled(true);
      let {
        conditionMinimum,
        resultDiscount,
        promotionName,
        promotionDescription,
        couponCodeTicket,
        limitAmountUse,
        amountOfCoupon,
        couponName,
        subCouponName,
        couponDescription,
      } = register;
      let promotionConditionProductsBox = [];
      let promotionConditionProductCategoriesBox = [];
      let promotionResultFreeProductsBox = [];
      let promotionResultFreeProductCategoriesBox = [];
      if (productAll) {
        productAll.forEach((itemProduct) => {
          promotionConditionProductsBox.push(itemProduct.id);
        });
      }
      if (productAllFree) {
        productAllFree.forEach((itemProduct) => {
          promotionResultFreeProductsBox.push(itemProduct.id);
        });
      }
      if (categoryAll) {
        categoryAll.forEach((itemCate) => {
          promotionConditionProductCategoriesBox.push(itemCate.id);
        });
      }
      if (categoryAllFree) {
        categoryAllFree.forEach((itemCate) => {
          promotionResultFreeProductCategoriesBox.push(itemCate.id);
        });
      }

      let receiptTypeList = [];
      receiptTypeAll.forEach((itemReceiptType) => {
        if (itemReceiptType) {
          receiptTypeList.push(itemReceiptType.id);
        }
      });
      let promotionSchedulesBox = [];
      let statusDate = promotionSchedulesAll.select;
      let dataNew = promotionSchedules.filter(
        (itemPromotion) => itemPromotion.select === true
      );
      dataNew.forEach((itemPromotionDate) => {
        promotionSchedulesBox.push({
          day: itemPromotionDate.day,
          start_time: moment(
            statusDate
              ? promotionSchedulesAll.start_time
              : itemPromotionDate.start_time
          ).format("HHmmss"),
          end_time: moment(
            statusDate
              ? promotionSchedulesAll.end_time
              : itemPromotionDate.end_time
          ).format("HHmmss"),
        });
      });

      let newImage = "";
      if (typeof image === "object") {
        newImage = await uploadImage(image);
      } else {
        newImage = data.image;
      }
      let couponBox = {
        type: null,
        code: null,
        amount: null,
        title: null,
        subtitle: null,
        description: null,
        language_code: null,
      };
      if (couponCode.status !== false) {
        couponBox = {
          type: couponCode.type,
          code: couponCodeTicket,
          amount: couponCode.type === "unlimit" ? 1 : Number(amountOfCoupon),
          title: couponName,
          subtitle: subCouponName,
          description: couponDescription,
          language_code: "all",
        };
      }
      // let serviceName = "";
      // if (ServiceStatus) {
      //   serviceName = serviceType.find(
      //     (item) => item.id === serviceSelect
      //   ).valueName;
      // }
      let dataAPI = {
        id: String(paramsId),
        // service: ServiceStatus ? serviceName : "spos",
        discount_type: Number(discountType),
        receipt_item_type: Number(receiptItemType),
        condition_minimum_price:
          menuConditionMinimum === 2 ? Number(conditionMinimum) : null,
        condition_minimum_amount:
          menuConditionMinimum === 1 ? Number(conditionMinimum) : null,
        result_discount_percent:
          resultDiscountType === 2 ? Number(resultDiscount) : null,
        result_discount_price:
          resultDiscountType === 1 ? Number(resultDiscount) : null,
        result_item_free_amount:
          resultItemFreeAmount === 0 ? null : resultItemFreeAmount,
        image: newImage,
        receipt_types: receiptTypeList,
        coupon_status: couponCode.status,
        coupon: couponBox,
        auto_apply: autoApply,
        limit_use: limitUse.status
          ? limitAmountUse
            ? Number(limitAmountUse)
            : null
          : null,
        start: datePromotionStatus
          ? dayjs(startDate).format("YYYYMMDDHHmmss")
          : dayjs().startOf("date").format("YYYYMMDDHHmmss"),
        end: datePromotionStatus ? dayjs(endDate).format("YYYYMMDDHHmmss") : "",
        status: statusButton,
        promotion_lang: {
          name: promotionName,
          description: promotionDescription,
          language_code: String(localStorage.getItem("language_form")),
        },
        promotion_schedules: promotionSchedulesBox,
        promotion_condition_products: promotionConditionProductsBox,
        promotion_condition_product_categories:
          promotionConditionProductCategoriesBox,
        promotion_result_free_products: promotionResultFreeProductsBox,
        promotion_result_free_product_categories:
          promotionResultFreeProductCategoriesBox,
        stack: promotionMultipleTime,
        shared: promotionConjunction,
        shared_promotions: promotionConjunctionListId,
      };
      let statusAPI = await updatePromotion(dataAPI);
      if (statusAPI === "OK") {
        fetchPromotion()
          .then(() => {
            notifySuccess({
              title: t("toast_successfully_added_information"),
            });
            navigate("/promotions");
          })
          .catch((error) => {
            console.error("Error fetching product:", error);
          });
        // setIsButtonDisabled(false);
      } else {
        // setIsButtonDisabled(false);
      }
    }
  };
  const dataPromotionType = [
    {
      id: 1,
      name: t("promotion_type_discount_receipt"),
    },
    {
      id: 2,
      name: t("promotion_type_discount_menu"),
    },
    {
      id: 3,
      name: t("promotion_type_discount_free"),
    },
  ];
  const fetchData = async () => {
    let dataAPI = await getPromotion(paramsId);
    let currency = "THB";
    setValue("currency", currency);
    let dataCateAll = await getAllMainCategory();

    let dataSaleProductAll = [];
    if (saleProductSetAll) {
      dataSaleProductAll = saleProductSetAll;
      if (
        dataSaleProductAll.some((item) => item.image.includes("data:image")) ===
        false
      ) {
        fetchDataImageSaleProduct(dataSaleProductAll);
      }
      setSaleProductSetAll(dataSaleProductAll);
    } else {
      dataSaleProductAll = await getAllSaleProduct();
      setSaleProductSetAll(dataSaleProductAll);
      fetchDataImageSaleProduct(dataSaleProductAll);
    }

    setCategoryAllAPI(dataCateAll);
    setSaleProductAllAPI(dataSaleProductAll);
    let {
      discount_type,
      receipt_item_type,
      condition_minimum_price,
      condition_minimum_amount,
      result_discount_percent,
      result_discount_price,
      result_item_free_amount,
      image,
      receipt_types,
      coupon_status,
      auto_apply,
      limit_use,
      start,
      end,
      status,
      promotion_lang,
      promotion_coupons,
      promotion_schedules,
      promotion_condition_products,
      promotion_condition_product_categories,
      promotion_result_free_products,
      promotion_result_free_product_categories,
      service,
      shared_promotions,
      stack,
    } = dataAPI;

    let promises = await getImage(image);
    image = UseConvertImageToBase64(promises);
    await Promise.all(promises);

    await fetchReceiptTypeAll(receipt_types);
    let serviceId = serviceType.find((item) =>
      item.name.includes(service.toUpperCase())
    ).id;
    setValue("serviceSelect", serviceId);
    setServiceSelect(serviceId);
    setPromotionIsStarted(
      Number(moment(dataAPI.start, "YYYYMMDDHHmmss").format("yyyyMMDD")) <=
        Number(moment().format("yyyyMMDD"))
    );
    setResultItemFreeAmount(result_item_free_amount);
    setDiscountType(discount_type);
    setReceiptItemType(receipt_item_type);
    setReceiptItemTypeFree(
      promotion_result_free_products.length > 0
        ? 1
        : promotion_result_free_product_categories.length > 0
        ? 2
        : null
    );
    setMenuConditionMinimum(
      condition_minimum_price ? 2 : condition_minimum_amount ? 1 : ""
    );
    setValue(
      "menuConditionMinimum",
      condition_minimum_price ? 2 : condition_minimum_amount ? 1 : ""
    );
    setValue(
      "conditionMinimum",
      condition_minimum_price
        ? condition_minimum_price
        : condition_minimum_amount
    );

    let productAllBox = [];
    let productAllFreeBox = [];
    let cateAllBox = [];
    let cateFreeAllBox = [];
    if (
      promotion_condition_products.length > 0 ||
      promotion_result_free_products.length > 0
    ) {
      // let productAPI = await getAllSaleProduct();

      // const promisesAllSaleProduct = productAPI.map(async (item) => {
      //   let imageUrl = await getImage(item.image);
      //   item.image = UseConvertImageToBase64(imageUrl);
      // });
      // await Promise.all(promisesAllSaleProduct);
      if (promotion_condition_products.length > 0) {
        promotion_condition_products.forEach((product) => {
          let dataNews = dataSaleProductAll.find(
            (products) =>
              products.sale_product_lang.sale_product_id ===
              product.sale_product_id
          );
          productAllBox.push({
            id: dataNews.sale_product_lang.sale_product_id,
            name: dataNews.sale_product_lang.name,
            image: dataNews.image,
          });
        });
        setProductAll(productAllBox);
      }
      if (promotion_result_free_products.length > 0) {
        promotion_result_free_products.forEach((product) => {
          let dataNews = dataSaleProductAll.find(
            (products) =>
              products.sale_product_lang.sale_product_id ===
              product.sale_product_id
          );
          productAllFreeBox.push({
            id: dataNews.sale_product_lang.sale_product_id,
            name: dataNews.sale_product_lang.name,
            image: dataNews.image,
          });
        });
        setProductAllFree(productAllFreeBox);
      }
    }

    if (
      promotion_condition_product_categories.length > 0 ||
      promotion_result_free_product_categories.length > 0
    ) {
      let categoryAPI = await getAllMainCategory();

      if (promotion_condition_product_categories.length > 0) {
        promotion_condition_product_categories.forEach((product) => {
          let dataNews = categoryAPI.find(
            (cate) =>
              cate.main_category_lang.main_category_id ===
              product.main_categories.id
          );
          cateAllBox.push({
            id: dataNews.main_category_lang.main_category_id,
            name: dataNews.main_category_lang.name,
          });
        });
        setCategoryAll(cateAllBox);
      }
      if (promotion_result_free_product_categories.length > 0) {
        promotion_result_free_product_categories.forEach((product) => {
          let dataNews = categoryAPI.find(
            (cate) =>
              cate.main_category_lang.main_category_id ===
              product.main_categories.id
          );
          cateFreeAllBox.push({
            id: dataNews.main_category_lang.main_category_id,
            name: dataNews.main_category_lang.name,
          });
        });
        setCategoryAllFree(cateFreeAllBox);
      }
    }
    let resultDiscountTypeConvert = result_discount_price
      ? 1
      : result_discount_percent
      ? 2
      : "";
    setResultDiscountType(resultDiscountTypeConvert);
    setValue("resultDiscountType", resultDiscountTypeConvert);
    setValue(
      "resultDiscount",
      result_discount_price
        ? result_discount_price
        : result_discount_percent
        ? result_discount_percent
        : ""
    );
    setResultDiscountPrice(result_discount_price);
    setImage(image);
    setValue("promotionName", promotion_lang.name);
    setValue("promotionDescription", promotion_lang.description);
    if (promotion_coupons.length > 0) {
      let { type, code, round, promotion_coupon_lang } = promotion_coupons[0];
      let { title, subtitle, description } = promotion_coupon_lang;
      setCouponAll(promotion_coupons);
      setCouponCode({
        status: coupon_status,
        type: type,
      });
      setValue("couponCodeTicket", type === "limit" ? null : code);
      setValue("amountOfCoupon", round);
      setValue("couponName", title);
      setValue("subCouponName", subtitle);
      setValue("couponDescription", description);
    }
    setAutoApply(auto_apply);
    setLimitUse({ status: limit_use ? true : false, data: limit_use });
    if (limit_use) {
      setValue("limitAmountUse", limit_use);
    }
    setDatePromotionStatus(start ? true : false);
    setStartDate(dayjs(start));
    setEndDate(dayjs(end));

    let dataSchedulesBox = [
      {
        day: "Sunday",
        start_time: new Date(),
        end_time: new Date(),
        select: false,
      },
      {
        day: "Monday",
        start_time: new Date(),
        end_time: new Date(),
        select: false,
      },
      {
        day: "Tuesday",
        start_time: new Date(),
        end_time: new Date(),
        select: false,
      },
      {
        day: "Wednesday",
        start_time: new Date(),
        end_time: new Date(),
        select: false,
      },
      {
        day: "Thursday",
        start_time: new Date(),
        end_time: new Date(),
        select: false,
      },
      {
        day: "Friday",
        start_time: new Date(),
        end_time: new Date(),
        select: false,
      },
      {
        day: "Saturday",
        start_time: new Date(),
        end_time: new Date(),
        select: false,
      },
    ];
    if (
      promotion_schedules.every(
        (item) => item.start_time === promotion_schedules[0].start_time
      ) &&
      promotion_schedules.every(
        (item) => item.end_time === promotion_schedules[0].end_time
      )
    ) {
      setPromotionSchedulesAll({
        start_time: moment(
          promotion_schedules[0].start_time,
          "HHmmss"
        ).toDate(),
        end_time: moment(promotion_schedules[0].end_time, "HHmmss").toDate(),
        select: true,
      });
    }
    dataSchedulesBox.forEach((itemDate) => {
      let dateNews = promotion_schedules.find(
        (promotions) => promotions.day === itemDate.day
      );
      if (dateNews) {
        itemDate.start_time = moment(dateNews.start_time, "HHmmss").toDate();
        itemDate.end_time = moment(dateNews.end_time, "HHmmss").toDate();
        itemDate.select = true;
      }
    });

    if (shared_promotions.length > 0) {
      setPromotionConjunctionListId(shared_promotions);
    }
    setPromotionMultipleTime(stack);
    setPromotionSchedules(dataSchedulesBox);
    setStatusButton(status);
    setIsLoading(false);
    setData(dataAPI);
    await fetchAllBranch();
  };
  const fetchReceiptTypeAll = async (receipt_types) => {
    let dataReceiptAPI = await getAllReceiptType();
    dataReceiptAPI = dataReceiptAPI.filter(
      (receipt) => receipt.status === true
    );
    dataReceiptAPI.forEach((item) => {
      if (receipt_types.find((receipt) => receipt.name === item.name)) {
        item.select = true;
      } else {
        item.select = false;
      }
    });
    setReceiptTypeAll(dataReceiptAPI);
  };
  const fetchAllBranch = async () => {
    let dataAPI = await getAllBranch();
    dataAPI = dataAPI.filter((item) => item.branch_lang !== null);
    await fetchSettingSelectTemplate(dataAPI);
  };
  const fetchSettingSelectTemplate = async (dataBranch) => {
    let dataAPI = await getAllPromotionTemplate(paramsId);
    if (dataAPI.length > 0) {
      dataAPI = dataAPI[0].template_promotions;
      let dataBox = [];
      dataBranch.forEach((item) => {
        let dataNews = dataAPI.filter(
          (itemTemplate) => itemTemplate.template_id === item.template_id
        );
        if (dataNews.length > 0) {
          dataBox.push({
            branch: item,
            data: dataNews[0],
            select: false,
          });
        } else {
          dataBox.push({
            branch: item,
            data: null,
            select: false,
          });
        }
      });
      await setAllDataTemplate(dataBox);
      await setIsLoadingBranch(false);
    } else {
      await setIsLoadingBranch(false);
    }
  };
  const onAddPromotionToTemplate = async () => {
    let branchArray = allDataTemplate.filter((item) => item.select === true);
    let dataSelect = [];
    branchArray.forEach((item) => {
      dataSelect.push(item.branch.id);
    });
    let dataAPI = {
      promotion_id: String(paramsId),
      branches: dataSelect,
      priority: 1,
    };
    let dataStatus = await addPromotionToTemplate(dataAPI);
    if (dataStatus === "OK") {
      setDataBranchPromotion([]);
      notifySuccess({
        title: t("toast_successfully_added_information"),
      });
      setIsLoadingBranch(true);
      fetchAllBranch();
    }
  };

  const onConfirmStatusChange = async (checked) => {
    if (checked) {
      setStatusButton(checked);
    } else {
      Swal.fire({
        title: t("notification_confirm_change_status"),
        text: t(
          "notification_changing_the_status_will_affect_the_branch_settings"
        ),
        icon: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          setStatusButton(checked);
          //sss
        }
      });
    }
  };
  const handleChangeDiscount = (item) => {
    if (receiptItemType && receiptItemType !== item) {
      Swal.fire({
        title: t("notification_change_condition_description"),
        showCancelButton: true,
        confirmButtonText: "confirm",
      }).then((result) => {
        if (result.isConfirmed) {
          let dataNews = [...dataAllProduct];
          dataNews.forEach((cate) =>
            cate.product.forEach((product) => (product.select = false))
          );
          setDataAllProduct(dataNews);

          let cateNews = [...dataAllCategory];
          cateNews.forEach((cate) => (cate.select = false));
          setDataAllCategory(cateNews);

          setReceiptItemType(item);
          setProductAll(null);
          setCategoryAll(null);
        }
      });
    } else {
      setReceiptItemType(item);
    }
  };
  const handleChangeDiscountFree = (item) => {
    if (receiptItemTypeFree && receiptItemTypeFree !== item) {
      Swal.fire({
        title: t("notification_change_condition_description"),
        showCancelButton: true,
        confirmButtonText: "confirm",
      }).then((result) => {
        if (result.isConfirmed) {
          let dataNews = [...dataAllProductFree];
          dataNews.forEach((cate) =>
            cate.product.forEach((product) => (product.select = false))
          );
          setDataAllProductFree(dataNews);

          let cateNews = [...dataAllCategoryFree];
          cateNews.forEach((cate) => (cate.select = false));
          setDataAllCategoryFree(cateNews);

          setReceiptItemTypeFree(item);
          setProductAllFree(null);
          setCategoryAllFree(null);
        }
      });
    } else {
      setReceiptItemTypeFree(item);
    }
  };
  const handleErrorTime = (newValue, index) => {
    let dataNews = [...promotionSchedules];
    if (newValue) {
      dataNews[index].errorFormat = "wrong";
    } else {
      delete dataNews[index].errorFormat;
    }
    setPromotionSchedules(dataNews);
  };

  const handleErrorTimeAll = (newValue) => {
    if (newValue) {
      setPromotionSchedulesAllError(true);
    } else {
      setPromotionSchedulesAllError(false);
    }
  };
  const handleErrorDate = (newValue) => {
    if (newValue) {
      setDatePromotionError(true);
    } else {
      setDatePromotionError(false);
    }
  };
  const onServiceSelect = (item) => {
    setValue("serviceSelect", item);
    clearErrors("serviceSelect");
    setServiceSelect(item);
  };
  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <Box>
      <BreadcrumbsTitle
        props={{
          title: t("promotion_info"),
          dataLink: [
            { text: t("promotion"), link: "/promotions" },
            { text: t("promotion_info"), link: null },
          ],
        }}
      />
      <Box p={2}>
        {isLoading ? (
          <Loading />
        ) : (
          <GridContainerSize>
            {promotionIsStarted ? (
              <BoxWrapper>
                <PaperWrapper props={{ growFull: 1 }}>
                  <BoxFormTitleAction>
                    <TitleHeaderForm props={{ text: t("promotion") }} />
                    <StatusForm
                      statusButton={statusButton}
                      setStatusButton={setStatusButton}
                    />
                  </BoxFormTitleAction>
                  <BoxGapInput>
                    <Paper
                      sx={{
                        boxSizing: "border-box",
                        maxWidth: "700px",
                        margin: "auto",
                      }}
                    >
                      <Box
                        sx={{
                          p: 2,
                        }}
                      >
                        <Box
                          sx={(theme) => ({
                            [theme.breakpoints.down("sm")]: {
                              display: "inline",
                            },
                            display: "flex",
                            gap: 2,
                          })}
                        >
                          <Box>
                            {/* <BoxImage image={image} /> */}
                            {window.innerWidth > 600 ? (
                              <BoxImage image={image} />
                            ) : (
                              <BoxImage
                                image={image}
                                width={"100%"}
                                height={"100%"}
                              />
                            )}
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: 1.5,
                            }}
                          >
                            {/* <Typography
                      sx={(theme) => ({
                        fontSize: 12,
                        color: theme.palette.secondaryText.main,
                        [theme.breakpoints.down("sm")]: {
                          mt: 1,
                        },
                      })}
                    >
                      {t("promotion_info")}
                    </Typography> */}
                            <Typography
                              sx={(theme) => ({
                                fontSize: 28,
                                color: theme.palette.primaryText.main,
                              })}
                            >
                              {watch("promotionName")}
                            </Typography>
                            {watch("promotionDescription") ? (
                              <Typography
                                sx={(theme) => ({
                                  fontSize: 14,
                                  color: theme.palette.secondaryText.main,
                                })}
                              >
                                {watch("promotionDescription")}
                              </Typography>
                            ) : (
                              <Typography
                                sx={(theme) => ({
                                  color:
                                    theme.palette.secondaryText.main + "70",
                                })}
                              >
                                No description
                              </Typography>
                            )}
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 0.5,
                              }}
                            >
                              <DateRange
                                sx={(theme) => ({
                                  fontSize: 20,
                                  color: theme.palette.primaryText.main,
                                })}
                              />
                              <Typography
                                sx={(theme) => ({
                                  fontSize: 14,
                                  color: theme.palette.primaryText.main,
                                })}
                              >
                                {dayjs(startDate).format("DD/MM/YYYY HH:mm:ss")}{" "}
                                - {dayjs(endDate).format("DD/MM/YYYY HH:mm:ss")}
                              </Typography>
                            </Box>
                            <Box>
                              {/* <Typography
                        sx={(theme) => ({
                          fontSize: 12,
                          color: theme.palette.secondaryText.main,
                        })}
                      >
                        {t("order_type")}
                      </Typography> */}
                              <Box sx={{ display: "flex", gap: 1 }}>
                                {receiptTypeAll.map((item, indexItem) => (
                                  <Typography
                                    sx={(theme) => ({
                                      py: 1,
                                      px: 2,
                                      borderRadius: 5,
                                      border: 1,
                                      fontSize: 12,
                                      backgroundColor: item.select
                                        ? theme.palette.primary.main
                                        : null,
                                      color: item.select
                                        ? theme.palette.white.main
                                        : null,
                                    })}
                                  >
                                    {item.name}
                                  </Typography>
                                ))}
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                        <Divider sx={{ width: "100%", my: 2 }} />
                        <Box sx={{}}>
                          <Box mt={1}>
                            <Typography
                              sx={(theme) => ({
                                fontSize: 12,
                                color: theme.palette.secondaryText.main,
                              })}
                            >
                              {t("promotion_type")}
                            </Typography>
                            <Typography
                              sx={(theme) => ({
                                fontSize: 14,
                                color: theme.palette.primaryText.main,
                              })}
                            >
                              {
                                dataPromotionType.find(
                                  (item) => item.id === discountType
                                ).name
                              }
                            </Typography>
                          </Box>
                          {/* Start Condition */}
                          <Box mt={1}>
                            <Typography
                              sx={(theme) => ({
                                fontSize: 12,
                                color: theme.palette.secondaryText.main,
                              })}
                            >
                              Condition
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              gap: 4,
                              mt: 2,
                            }}
                          >
                            <Box
                              sx={{
                                width: "100%",
                                display: "flex",
                                gap: 2,
                                alignItems: "center",
                                borderRadius: 2,
                                border: "1px solid #E0E0E0",
                                p: 2,
                                height: "100%",
                                boxSizing: "border-box",
                                position: "relative",
                              }}
                            >
                              {receiptItemType === 1 ? (
                                <Box
                                  sx={{
                                    width: "100%",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      mb: 2,
                                      gap: 1,
                                    }}
                                  >
                                    <Typography
                                      sx={(theme) => ({
                                        fontSize: 20,
                                        color: theme.palette.primaryText.main,
                                      })}
                                    >
                                      Buy
                                    </Typography>

                                    <Typography
                                      sx={(theme) => ({
                                        fontSize: 12,
                                        color: theme.palette.primaryText.main,
                                      })}
                                    >
                                      X
                                    </Typography>
                                    <Typography
                                      sx={(theme) => ({
                                        fontSize: 20,
                                        color: theme.palette.primaryText.main,
                                      })}
                                    >
                                      {watch("conditionMinimum")}
                                    </Typography>
                                    <Typography
                                      sx={(theme) => ({
                                        fontSize: 24,
                                        color: theme.palette.secondary.main,
                                      })}
                                    >
                                      {data.condition_minimum_price
                                        ? "THB"
                                        : "Pcs"}
                                    </Typography>
                                  </Box>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      gap: 1,
                                      alignItems: "center",
                                      width: "100%",
                                      overflowX: "auto",
                                      justifyContent:
                                        productAll?.length === 1 ||
                                        productAll?.length === 2
                                          ? "center"
                                          : "flex-start",
                                      pb: 1,
                                    }}
                                    align="center"
                                  >
                                    {productAll?.length > 0 && (
                                      <>
                                        {productAll.map((product) => (
                                          <Box
                                            sx={{
                                              p: 1,
                                              boxShadow: 2,
                                              borderRadius: 2,
                                            }}
                                          >
                                            <BoxImage
                                              image={product.image}
                                              // type="table"
                                              height="128px"
                                              width="128px"
                                              borderRadius={"20px"}
                                            />
                                            <Typography sx={{ fontSize: 12 }}>
                                              {product.name}
                                            </Typography>
                                          </Box>
                                        ))}
                                      </>
                                    )}
                                  </Box>
                                  <Box mt={2} align="center">
                                    {watch("resultDiscountType") ? (
                                      <>
                                        <Typography
                                          sx={(theme) => ({
                                            fontSize: 14,
                                            color:
                                              theme.palette.primaryText.main,
                                          })}
                                        >
                                          {
                                            ResultDiscountTypeList[
                                              i18next.language
                                            ].find(
                                              (item) =>
                                                item.id ===
                                                watch("resultDiscountType")
                                            ).name
                                          }
                                        </Typography>
                                        <Box
                                          sx={{
                                            display: "flex",
                                            gap: 1,
                                            alignItems: "center",
                                            justifyContent: "center",
                                            border: 1,
                                            width: "150px",
                                            borderRadius: 2,
                                          }}
                                        >
                                          <Typography sx={{ fontSize: 24 }}>
                                            {watch("resultDiscount")}
                                          </Typography>
                                          <Typography sx={{ fontSize: 24 }}>
                                            {watch("currency")}
                                          </Typography>
                                        </Box>
                                      </>
                                    ) : null}
                                  </Box>
                                </Box>
                              ) : (
                                <Box sx={{ width: "100%" }}>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      mb: 1,
                                      gap: 1,
                                    }}
                                  >
                                    <Typography
                                      sx={(theme) => ({
                                        color: theme.palette.secondary.main,
                                        fontSize: 20,
                                      })}
                                    >
                                      Buy
                                    </Typography>
                                    <Typography
                                      sx={(theme) => ({
                                        color: theme.palette.secondary.main,
                                      })}
                                    >
                                      X
                                    </Typography>
                                    <Typography
                                      sx={(theme) => ({
                                        fontSize: 24,
                                        color: theme.palette.secondary.main,
                                      })}
                                    >
                                      {watch("conditionMinimum")}
                                    </Typography>
                                    <Typography
                                      sx={(theme) => ({
                                        fontSize: 24,
                                        color: theme.palette.secondary.main,
                                      })}
                                    >
                                      {data.condition_minimum_price
                                        ? "THB"
                                        : "Pcs"}
                                    </Typography>
                                  </Box>
                                  {categoryAll?.length > 0 ? (
                                    <>
                                      <Grid
                                        container
                                        justifyContent={
                                          categoryAll?.length === 1
                                            ? "center"
                                            : "flex-start"
                                        }
                                      >
                                        {categoryAll.map((itemCate) => (
                                          <Grid
                                            item
                                            xs={6}
                                            sx={(theme) => ({
                                              boxSizing: "border-box",
                                              p: 1,
                                            })}
                                          >
                                            <Box
                                              sx={(theme) => ({
                                                boxShadow: 1,
                                                borderRadius: 1,
                                                boxSizing: "border-box",
                                                p: 2,
                                                display: "flex",
                                                justifyContent: "center",
                                                gap: 2,
                                                backgroundColor:
                                                  theme.palette.primary.main,
                                                color: theme.palette.white.main,
                                              })}
                                            >
                                              <CategoryIcon />
                                              <Typography>
                                                {itemCate.name}
                                              </Typography>
                                            </Box>
                                          </Grid>
                                        ))}
                                      </Grid>
                                    </>
                                  ) : null}
                                  <Box
                                    align="center"
                                    sx={{ borderTop: "1px solid #ccc", mt: 2 }}
                                  >
                                    {watch("resultDiscountType") ? (
                                      <>
                                        <Typography
                                          sx={(theme) => ({
                                            fontSize: 18,
                                            color: theme.palette.secondary.main,
                                            my: 2,
                                          })}
                                        >
                                          {
                                            ResultDiscountTypeList[
                                              i18next.language
                                            ].find(
                                              (item) =>
                                                item.id ===
                                                watch("resultDiscountType")
                                            ).name
                                          }
                                        </Typography>
                                        <Box
                                          sx={{
                                            display: "flex",
                                            gap: 1,
                                            justifyContent: "center",
                                            border: 1,
                                            width: "150px",
                                            borderRadius: 2,
                                          }}
                                        >
                                          <Typography sx={{ fontSize: 24 }}>
                                            {watch("resultDiscount")}
                                          </Typography>
                                          <Typography sx={{ fontSize: 24 }}>
                                            {ResultDiscountTypeList[
                                              i18next.language
                                            ]
                                              .find(
                                                (item) =>
                                                  item.id ===
                                                  watch("resultDiscountType")
                                              )
                                              .name.includes("Price")
                                              ? watch("currency")
                                              : "%"}
                                          </Typography>
                                        </Box>
                                      </>
                                    ) : null}
                                  </Box>
                                </Box>
                              )}
                            </Box>
                          </Box>

                          {receiptItemTypeFree ? (
                            <Box align="center" mt={2}>
                              <Box
                                align="center"
                                sx={{
                                  width: "100%",
                                  display: "flex",
                                  gap: 2,
                                  alignItems: "center",
                                  borderRadius: 2,
                                  border: "1px solid #E0E0E0",
                                  p: 2,
                                  height: "100%",
                                  boxSizing: "border-box",
                                  position: "relative",
                                }}
                              >
                                <Box
                                  sx={{
                                    width: "100%",
                                  }}
                                >
                                  {receiptItemTypeFree === 1 ? (
                                    <Box>
                                      <Box
                                        sx={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          mb: 2,
                                        }}
                                      >
                                        <Typography
                                          sx={(theme) => ({
                                            fontSize: 20,
                                            color:
                                              theme.palette.primaryText.main,
                                            mr: 1,
                                          })}
                                        >
                                          Free
                                        </Typography>

                                        <Typography
                                          sx={(theme) => ({
                                            fontSize: 12,
                                            color:
                                              theme.palette.primaryText.main,
                                          })}
                                        >
                                          X
                                        </Typography>
                                        <Typography
                                          sx={(theme) => ({
                                            fontSize: 20,
                                            color:
                                              theme.palette.primaryText.main,
                                          })}
                                        >
                                          {resultItemFreeAmount}
                                        </Typography>
                                      </Box>
                                      <Box
                                        sx={{
                                          display: "flex",
                                          gap: 1,
                                          alignItems: "center",
                                          width: "100%",
                                          overflowX: "auto",
                                          justifyContent:
                                            productAllFree?.length === 1 ||
                                            productAllFree?.length === 2
                                              ? "center"
                                              : "flex-start",
                                          pb: 1,
                                        }}
                                      >
                                        {productAllFree?.length > 0 && (
                                          <>
                                            {productAllFree.map((product) => (
                                              <Box
                                                sx={{
                                                  p: 1,
                                                  boxShadow: 2,
                                                  borderRadius: 2,
                                                }}
                                              >
                                                <BoxImage
                                                  image={product.image}
                                                  // type="table"
                                                  height="128px"
                                                  width="128px"
                                                  borderRadius={"20px"}
                                                />
                                                <Typography
                                                  sx={{ fontSize: 12 }}
                                                >
                                                  {product.name}
                                                </Typography>
                                              </Box>
                                            ))}
                                          </>
                                        )}
                                      </Box>
                                    </Box>
                                  ) : (
                                    <>
                                      <Box
                                        sx={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          mb: 1,
                                        }}
                                      >
                                        <Typography
                                          sx={(theme) => ({
                                            color: theme.palette.secondary.main,
                                            fontSize: 20,
                                            mr: 1,
                                          })}
                                        >
                                          Free
                                        </Typography>
                                        <Typography
                                          sx={(theme) => ({
                                            color: theme.palette.secondary.main,
                                          })}
                                        >
                                          X
                                        </Typography>
                                        <Typography
                                          sx={(theme) => ({
                                            fontSize: 20,
                                            color: theme.palette.secondary.main,
                                          })}
                                        >
                                          {resultItemFreeAmount}
                                        </Typography>
                                      </Box>
                                      <Box
                                        sx={(theme) => ({
                                          display: "flex",
                                          gap: 1,
                                          alignItems: "center",
                                          width: "100%",
                                          overflowX: "auto",
                                          pb: 1,
                                        })}
                                      >
                                        {categoryAllFree?.length > 0 ? (
                                          <>
                                            <Grid
                                              container
                                              justifyContent={
                                                categoryAllFree?.length === 1
                                                  ? "center"
                                                  : "flex-start"
                                              }
                                            >
                                              {categoryAllFree.map(
                                                (itemCate) => (
                                                  <Grid
                                                    item
                                                    xs={6}
                                                    sx={(theme) => ({
                                                      boxSizing: "border-box",
                                                      p: 1,
                                                    })}
                                                  >
                                                    <Box
                                                      sx={(theme) => ({
                                                        boxShadow: 1,
                                                        borderRadius: 1,
                                                        boxSizing: "border-box",
                                                        p: 2,
                                                        display: "flex",
                                                        justifyContent:
                                                          "center",
                                                        gap: 2,
                                                        backgroundColor:
                                                          theme.palette.primary
                                                            .main,
                                                        color:
                                                          theme.palette.white
                                                            .main,
                                                      })}
                                                    >
                                                      <CategoryIcon />
                                                      <Typography>
                                                        {itemCate.name}
                                                      </Typography>
                                                    </Box>
                                                  </Grid>
                                                )
                                              )}
                                            </Grid>
                                          </>
                                        ) : null}
                                      </Box>
                                    </>
                                  )}
                                </Box>
                              </Box>
                            </Box>
                          ) : null}
                          {/* End Condition */}

                          <Box
                            mt={2}
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: 1,
                            }}
                          >
                            <Typography
                              sx={(theme) => ({
                                fontSize: 12,
                                color: theme.palette.secondaryText.main,
                              })}
                            >
                              {t("promotion_condition_additional")}
                            </Typography>
                            <Chip
                              sx={(theme) => ({
                                color: data.auto_apply
                                  ? theme.palette.success.main
                                  : theme.palette.white.main,
                                backgroundColor: data.auto_apply
                                  ? theme.palette.successBg.main
                                  : "#ccc",
                                borderRadius: 2,
                              })}
                              icon={
                                data.auto_apply ? (
                                  <Check
                                    sx={(theme) => ({
                                      fontSize: 15,
                                    })}
                                    color={data.auto_apply ? "success" : "info"}
                                  />
                                ) : (
                                  <Close
                                    sx={(theme) => ({
                                      fontSize: 15,
                                    })}
                                  />
                                )
                              }
                              label={t("Notify when match promotion")}
                            />
                            <Chip
                              sx={(theme) => ({
                                color: data.shared
                                  ? theme.palette.success.main
                                  : theme.palette.white.main,
                                backgroundColor: data.shared
                                  ? theme.palette.successBg.main
                                  : "#ccc",
                                borderRadius: 2,
                              })}
                              icon={
                                data.shared ? (
                                  <Check
                                    sx={(theme) => ({
                                      fontSize: 15,
                                    })}
                                    color={data.shared ? "success" : "info"}
                                  />
                                ) : (
                                  <Close
                                    sx={(theme) => ({
                                      fontSize: 15,
                                    })}
                                  />
                                )
                              }
                              label={t("promotion_conjunction")}
                            />
                            <Chip
                              sx={(theme) => ({
                                color: data.stack
                                  ? theme.palette.success.main
                                  : theme.palette.white.main,
                                backgroundColor: data.stack
                                  ? theme.palette.successBg.main
                                  : "#ccc",
                                borderRadius: 2,
                              })}
                              icon={
                                data.stack ? (
                                  <Check
                                    sx={(theme) => ({
                                      fontSize: 15,
                                    })}
                                    color={data.stack ? "success" : "info"}
                                  />
                                ) : (
                                  <Close
                                    sx={(theme) => ({
                                      fontSize: 15,
                                    })}
                                  />
                                )
                              }
                              label={t("promotion_can_be_used_multiple_times")}
                            />
                            {data.shared_promotions.length > 0 ? (
                              <Box mt={2}>
                                <Typography
                                  sx={(theme) => ({
                                    fontSize: 12,
                                    color: theme.palette.secondaryText.main,
                                  })}
                                >
                                  {t("promotion_conjunction")}
                                </Typography>
                                <List
                                  component="nav"
                                  aria-label="main mailbox folders"
                                >
                                  {/* 
                                {
                                  salePromotionSetAll.find(
                                    (itemFind) => itemFind.id === item.id
                                  ).promotion_lang.name
                                }
                              </>
                            ))} */}
                                  {data.shared_promotions.map((item) => (
                                    <Box
                                      sx={{
                                        display: "flex",
                                        gap: 1,
                                        alignItems: "center",
                                      }}
                                    >
                                      <Inbox />
                                      <Typography>
                                        {
                                          salePromotionSetAll.find(
                                            (itemFind) =>
                                              itemFind.id === item.id
                                          ).promotion_lang.name
                                        }
                                      </Typography>
                                    </Box>
                                  ))}
                                </List>
                              </Box>
                            ) : null}
                            <Box
                              sx={{
                                display: "flex",
                                gap: 1,
                                alignItems: "center",
                                mt: 1,
                              }}
                            >
                              {data.limit_use ? (
                                <>
                                  <Chip
                                    sx={(theme) => ({
                                      color: data.limit_use
                                        ? theme.palette.success.main
                                        : theme.palette.white.main,
                                      backgroundColor: data.limit_use
                                        ? theme.palette.successBg.main
                                        : "#ccc",
                                      borderRadius: 2,
                                    })}
                                    icon={
                                      <ConfirmationNumber
                                        sx={(theme) => ({
                                          fontSize: 15,
                                        })}
                                        color={
                                          data.limit_use ? "success" : "info"
                                        }
                                      />
                                    }
                                    label={
                                      t("promotion_limit_of_use_promotion") +
                                      "X2"
                                    }
                                  />

                                  <Typography sx={{ fontSize: 14 }}>
                                    X
                                  </Typography>
                                  <Typography sx={{ fontSize: 14 }}>
                                    {data.limit_use}
                                  </Typography>
                                </>
                              ) : null}
                            </Box>
                          </Box>
                          <Box mt={1}>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 0.5,
                              }}
                            >
                              <Typography
                                sx={(theme) => ({
                                  fontSize: 12,
                                  color: theme.palette.secondaryText.main,
                                })}
                              >
                                {t("promotion_date_and_time_for_promotion_use")}
                              </Typography>
                            </Box>

                            <Box
                              sx={(theme) => ({
                                display: promotionSchedulesAll.select
                                  ? "flex"
                                  : "inline",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                gap: 0.5,
                                mt: 1,
                              })}
                            >
                              {promotionSchedulesAll.select ? (
                                <Box>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      gap: 1,
                                      alignItems: "center",
                                    }}
                                  >
                                    <AccessTime
                                      sx={(theme) => ({
                                        fontSize: 15,
                                      })}
                                    />
                                    <Typography
                                      sx={(theme) => ({
                                        fontSize: 14,
                                        color: theme.palette.primaryText.main,
                                      })}
                                    >
                                      {t("promotion_same_time")}
                                    </Typography>
                                    <Typography
                                      sx={(theme) => ({
                                        fontSize: 14,
                                        color: theme.palette.primaryText.main,
                                      })}
                                    >
                                      {dayjs(
                                        promotionSchedulesAll.start_time
                                      ).format("HH:mm")}
                                    </Typography>
                                    <Typography>-</Typography>
                                    <Typography
                                      sx={(theme) => ({
                                        fontSize: 14,
                                        color: theme.palette.primaryText.main,
                                      })}
                                    >
                                      {dayjs(
                                        promotionSchedulesAll.end_time
                                      ).format("HH:mm")}
                                    </Typography>
                                  </Box>

                                  <Box
                                    sx={(theme) => ({
                                      display: "flex",
                                      justifyContent: "flex-start",
                                      alignItems: "center",
                                      gap: 1,
                                      mt: 0.5,
                                    })}
                                  >
                                    <CalendarToday
                                      sx={(theme) => ({
                                        fontSize: 15,
                                      })}
                                    />
                                    {promotionSchedules.map(
                                      (itemDate, indexDate) => (
                                        <Chip
                                          label={itemDate.day.substring(0, 3)}
                                        />
                                      )
                                    )}
                                  </Box>
                                </Box>
                              ) : (
                                <>
                                  {promotionSchedules.map(
                                    (itemDate, indexDate) => (
                                      <>
                                        {itemDate.select && (
                                          <Box
                                            sx={{
                                              display: "flex",
                                              gap: 1,
                                              alignItems: "center",
                                              width: "100%",
                                            }}
                                          >
                                            <Typography
                                              sx={(theme) => ({
                                                fontSize: 14,
                                                color:
                                                  theme.palette.primaryText
                                                    .main,
                                                minWidth: "32px",
                                              })}
                                            >
                                              {itemDate.day.substring(0, 3)}
                                            </Typography>
                                            <Typography
                                              sx={(theme) => ({
                                                fontSize: 14,
                                                color:
                                                  theme.palette.primaryText
                                                    .main,
                                              })}
                                            >
                                              {dayjs(
                                                itemDate.start_time
                                              ).format("HH:mm")}
                                            </Typography>
                                            <Typography>-</Typography>
                                            <Typography
                                              sx={(theme) => ({
                                                fontSize: 14,
                                                color:
                                                  theme.palette.primaryText
                                                    .main,
                                              })}
                                            >
                                              {dayjs(itemDate.end_time).format(
                                                "HH:mm"
                                              )}
                                            </Typography>
                                          </Box>
                                        )}
                                      </>
                                    )
                                  )}
                                </>
                              )}
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                      {isLoadingBranch ? (
                        <>
                          <Loading />
                        </>
                      ) : (
                        <>
                          <PaperWrapper
                            props={{ growFull: 1, setting: { mt: 2 } }}
                          >
                            <SettingTypeBranch
                              title={t("promotion_branch")}
                              allDataTemplate={allDataTemplate}
                              setAllDataTemplate={setAllDataTemplate}
                              onAddTypeToTemplate={onAddPromotionToTemplate}
                              directToBranch={"promotion"}
                            />
                          </PaperWrapper>
                        </>
                      )}
                    </Paper>
                  </BoxGapInput>
                </PaperWrapper>
              </BoxWrapper>
            ) : (
              <form onSubmit={handleSubmit(onConfirmCreatePromotion)}>
                <BoxWrapper>
                  <PaperWrapper props={{ growFull: 1 }}>
                    <BoxFormTitleAction>
                      <TitleHeaderForm props={{ text: t("promotion") }} />
                      <StatusForm
                        statusButton={statusButton}
                        setStatusButton={setStatusButton}
                      />
                    </BoxFormTitleAction>
                    <BoxGapInput>
                      <Chip
                        icon={<CategoryIcon />}
                        color="primary"
                        label={
                          dataPromotionType.find(
                            (item) => item.id === discountType
                          ).name
                        }
                        sx={{ mb: 2, p: 2, width: "100%" }}
                      />
                      <Grid item xs={12}>
                        <PromotionService
                          props={{
                            control,
                            errors,
                            clearErrors,
                            serviceSelect,
                            onServiceSelect,
                            watch,
                          }}
                        />
                      </Grid>
                      <PromotionCondition
                        props={{
                          register,
                          watch,
                          errors,
                          setDialogProduct,
                          productAll,
                          setDialogProductFree,
                          productAllFree,
                          discountType,
                          receiptItemType,
                          receiptItemTypeFree,
                          resultItemFreeAmount,
                          setResultItemFreeAmount,
                          categoryAll,
                          setDialogCategory,
                          categoryAllFree,
                          setDialogCategoryFree,
                          menuConditionMinimum,
                          setMenuConditionMinimum,
                          categoryAllAPI,
                          saleProductAllAPI,
                          clearErrors,
                          control,
                          setValue,
                          handleChangeDiscount,
                          handleChangeDiscountFree,
                        }}
                      />
                      {discountType === 3 ? null : (
                        <>
                          <PromotionResult
                            props={{
                              register,
                              errors,
                              setValue,
                              resultDiscountPercent,
                              setResultDiscountPercent,
                              resultDiscountPrice,
                              setResultDiscountPrice,
                              resultDiscountType,
                              setResultDiscountType,
                              control,
                              clearErrors,
                              watch,
                            }}
                          />
                        </>
                      )}
                      <PromotionCreateDetail
                        props={{
                          register,
                          setValue,
                          errors,
                          image,
                          setImage,
                          promotionSchedules,
                          setPromotionSchedules,
                          promotionSchedulesAll,
                          setPromotionSchedulesAll,
                          datePromotionStatus,
                          setDatePromotionStatus,
                          startDate,
                          setStartDate,
                          endDate,
                          setEndDate,
                          autoApply,
                          setAutoApply,
                          limitUse,
                          setLimitUse,
                          couponCode,
                          setCouponCode,
                          receiptTypeAll,
                          setReceiptTypeAll,
                          couponAll,
                          setDialogCoupon,
                          dialogCoupon,
                          handleErrorTime,
                          handleErrorTimeAll,
                          promotionSchedulesAllError,
                          handleErrorDate,
                          datePromotionError,
                          promotionConjunction,
                          setPromotionConjunction,
                          promotionMultipleTime,
                          setPromotionMultipleTime,
                          discountType,
                          setPromotionConjunctionListName,
                          promotionConjunctionListName,
                          setPromotionConjunctionListId,
                          clearErrors,
                          control,
                        }}
                      />
                      {isLoadingBranch ? (
                        <>
                          <Loading />
                        </>
                      ) : (
                        <>
                          <PaperWrapper
                            props={{ growFull: 1, setting: { mt: 2 } }}
                          >
                            <SettingTypeBranch
                              title={t("promotion_branch")}
                              allDataTemplate={allDataTemplate}
                              setAllDataTemplate={setAllDataTemplate}
                              onAddTypeToTemplate={onAddPromotionToTemplate}
                              directToBranch={"promotion"}
                            />
                          </PaperWrapper>
                        </>
                      )}
                      <StatusForm
                        statusButton={statusButton}
                        setStatusButton={onConfirmStatusChange}
                      />
                      <ConfirmButton />
                    </BoxGapInput>
                  </PaperWrapper>
                </BoxWrapper>

                {categoryAllAPI.length > 0 && saleProductAllAPI.length > 0 ? (
                  <>
                    <SaleProductDialog
                      props={{
                        productAll,
                        setProductAll,
                        dataAllProduct,
                        setDataAllProduct,
                        dialogProduct,
                        setDialogProduct,
                        saleProductAllAPI,
                        categoryAllAPI,
                      }}
                    />
                    <SaleProductDialog
                      props={{
                        productAll: productAllFree,
                        setProductAll: setProductAllFree,
                        dataAllProduct: dataAllProductFree,
                        setDataAllProduct: setDataAllProductFree,
                        dialogProduct: dialogProductFree,
                        setDialogProduct: setDialogProductFree,
                        saleProductAllAPI: saleProductAllAPI,
                        categoryAllAPI: categoryAllAPI,
                      }}
                    />
                    <DialogAddCategory
                      props={{
                        categoryAll,
                        setCategoryAll,
                        dataAllCategory,
                        setDataAllCategory,
                        dialogCategory,
                        setDialogCategory,
                      }}
                    />
                    <DialogAddCategory
                      props={{
                        categoryAll: categoryAllFree,
                        setCategoryAll: setCategoryAllFree,
                        dataAllCategory: dataAllCategoryFree,
                        setDataAllCategory: setDataAllCategoryFree,
                        dialogCategory: dialogCategoryFree,
                        setDialogCategory: setDialogCategoryFree,
                      }}
                    />
                    <DialogCoupon props={{ dialogCoupon, setDialogCoupon }} />
                  </>
                ) : null}
              </form>
            )}
          </GridContainerSize>
        )}
      </Box>
    </Box>
  );
}
