import { Box, FormControlLabel, Switch } from "@mui/material";
import React from "react";
import { BoxWrapper } from "../../../components/ui/box/boxWrapper";
import TextfieldComponent from "../../../components/ui/textfield/textfieldComponent";
import { t } from "i18next";
import PaperWrapper from "../../../components/ui/paper/paperWrapper";
import TitleForm from "../../../components/ui/formComponent/titleForm";
import { TitleTextfield } from "../../../components/ui/textfield/textfieldCustom";

function SelfPickupBankAccountForm({ props }) {
  let { register, errors, promptPay, setPromptPay } = props;
  return (
    <PaperWrapper props={{ growFull: 1 }}>
      <TitleForm props={{ text: t("self_pickup_setup_bank_account_info") }} />
      <Box sx={{ p: 2 }}>
        <BoxWrapper>
          <TextfieldComponent
            title={t("self_pickup_setup_account_name")}
            name={`accountName`}
            register={register}
            placeholder={t("self_pickup_setup_account_name")}
            errors={errors}
          />
          <TextfieldComponent
            title={t("self_pickup_setup_account_no")}
            name={`accountNo`}
            register={register}
            placeholder={t("self_pickup_setup_account_no")}
            errors={errors}
          />
        </BoxWrapper>
        <BoxWrapper>
          <TextfieldComponent
            title={t("self_pickup_setup_bank_name")}
            name={`bankName`}
            register={register}
            placeholder={t("self_pickup_setup_bank_name")}
            errors={errors}
          />
          <TextfieldComponent
            title={t("self_pickup_setup_branch_name")}
            name={`branchName`}
            register={register}
            placeholder={t("self_pickup_setup_branch_name")}
            errors={errors}
          />
        </BoxWrapper>
        <BoxWrapper>
          <Box mt={1}>
            <TitleTextfield text={t("self_pickup_setup_bank_prompt_pay")} />
            <FormControlLabel
              label={promptPay ? t("global_active") : t("global_in_active")}
              control={
                <Switch
                  onChange={(e) => setPromptPay(!promptPay)}
                  checked={promptPay}
                />
              }
            />
          </Box>
        </BoxWrapper>
      </Box>
    </PaperWrapper>
  );
}

export default SelfPickupBankAccountForm;
