import { Box, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import WarningIcon from "@mui/icons-material/Warning";
function DataNotFound() {
  const { t } = useTranslation();
  return (
    <>
      <Box sx={{ margin: "auto", width: "10%" }}>
        {/* <img
          src={
            "https://img.freepik.com/free-vector/error-404-concept-illustration_114360-1811.jpg"
          }
          width="100%"
          alt="ImageNotFound"
        /> */}
      </Box>
      <Box
        sx={(theme) => ({
          fontWeight: "bold",
          color: theme.palette.notFound.main,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: 1,
          width: "100%",
        })}
      >
        <WarningIcon />
        <Typography>{t("global_data_not_found")}</Typography>
      </Box>
    </>
  );
}

export default DataNotFound;
