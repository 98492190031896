import React, { useState } from "react";
import { Box } from "@mui/material";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { postUnitCounter } from "../../service/unitCounterAPI";
import { notifySuccess } from "../../components/ui/popup/toastPopup";
import { unitSchema } from "../../components/validation/unitSchema";
import { useSchema } from "../../components/hooks/use-schema";
import BreadcrumbsTitle from "../../components/ui/breadcrumbsTitle";
import { t } from "i18next";
import { GridContainerSize } from "../../components/utils/gridContainerSize";
import UnitForm from "./unitForm";

function UnitCreate() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm(useSchema(unitSchema));
  const [statusButton, setStatusButton] = useState(true);
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const onConfirmForm = async (register) => {
    setIsSubmitting(true);
    let dataAPI = {
      status: statusButton,
      unit_counter_lang: {
        name: register.unit,
        language_code: "ALL",
      },
    };
    let statusAPI = await postUnitCounter(dataAPI);
    if (statusAPI === "OK") {
      notifySuccess({
        title: t("toast_successfully_added_information"),
      });
      navigate("/unit");
    }
    await new Promise((resolve) => setTimeout(resolve, 2000));
    setIsSubmitting(false);
  };
  return (
    <div align="left">
      <BreadcrumbsTitle
        props={{
          title: t("unit_create"),
          dataLink: [
            { text: t("unit"), link: "/unit" },
            { text: t("unit_create"), link: null },
          ],
        }}
      />
      <GridContainerSize>
        <Box p={2}>
          <form onSubmit={handleSubmit(onConfirmForm)}>
            <UnitForm
              props={{
                register,
                errors,
                isSubmitting,
                statusButton,
                setStatusButton,
              }}
            />
          </form>
        </Box>
      </GridContainerSize>
    </div>
  );
}

export default UnitCreate;
