import React from "react";
import ImageNotFound from "../../../assets/images/imageNotFound.jpeg";
import { LazyLoadImage } from "react-lazy-load-image-component";
export const BoxImageBlank = (props) => {
  let { width, height } = props;
  return (
    <div>
      <LazyLoadImage
        src={ImageNotFound}
        alt="example"
        effect="blur"
        style={{
          borderRadius: "10px",
          objectFit: "cover",
          width: width ? width + "px" : "100px",
          height: height ? height + "px" : "100px",
        }}
      />
    </div>
  );
};
