import React from "react";
import TextField from "@mui/material/TextField";
import { TextFormWithError } from "./textfieldCustom";
import { Box, IconButton, InputAdornment } from "@mui/material";

const TextfieldComponent = ({
  title,
  name,
  register,
  placeholder,
  errors,
  iconEnd,
  iconEndOnclick,
  disabled,
  multiline,
  rows,
  type,
  fullGrid,
}) => {
  return (
    <Box sx={{ width: "100%" }}>
      {/* <Box sx={{ mb: "0.5rem" }}>
        {title ? <TitleTextfield text={title} /> : null}
      </Box> */}
      <TextField
        {...register(name)}
        name={name}
        type={type ? type : "text"}
        placeholder={placeholder ? placeholder : null}
        error={errors[name]}
        fullWidth
        InputLabelProps={{ shrink: true }}
        label={title}
        size="small"
        InputProps={{
          endAdornment: (
            <>
              {errors[
                name
              ] ? //   sx={(theme) => ({ color: theme.palette.primary.main })} // <ErrorIcon
              // />
              null : (
                <>
                  {iconEndOnclick ? (
                    <IconButton onClick={() => iconEndOnclick()}>
                      <InputAdornment position="end">{iconEnd}</InputAdornment>
                    </IconButton>
                  ) : (
                    <InputAdornment position="end">{iconEnd}</InputAdornment>
                  )}
                </>
              )}
            </>
          ),
        }}
        disabled={disabled}
        multiline={multiline}
        rows={rows}
      />
      <TextFormWithError textError={errors[name]} />
    </Box>
  );
};

export default TextfieldComponent;
