import React, { useState, useEffect, useContext } from "react";
import { ActionDetail } from "../../../components/ui/dataTable/components/actionDetail";
import { Box } from "@mui/material";
import { MomentConvert } from "../../../components/ui/moment/momentConvert";
import DataMaterialTable from "../../../components/ui/dataTable/dataMaterialTable";
import { useTranslation } from "react-i18next";
import { getAllProduct } from "../../../service/productAPI";
import { BoxImageBlank } from "../../../components/ui/boxImage/boxImageBlank";
import { getAllProductCategory } from "../../../service/productCategoryAPI";
import BreadcrumbsTitle from "../../../components/ui/breadcrumbsTitle";
import StatusTable from "../../../components/chip/statusTable";
import { TextTable } from "../../../components/ui/textfield/textfieldCustom";
import { MyContext } from "../../../context/MyContext";
import { BoxImageTable } from "../../../components/ui/boxImage/boxImageTable";
import dayjs from "dayjs";

function Product() {
  const [rows, setRows] = useState([]);
  const { t } = useTranslation();
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [columns, setColumns] = useState([]);
  const [exportColumnName, setExportColumnName] = useState(null);
  const { productSetAll, productAllTable, fetchDataImageProductTable } =
    useContext(MyContext);

  const fetchData = async () => {
    let dataColumns = [
      {
        accessorKey: "image",
        enableSorting: false,
        size: 50,
        header: t("global_image"),
        Cell: ({ row }) => (
          <>
            {row.original.image ? (
              <BoxImageTable
                image={row.original.image}
                width={"50px"}
                height={"50px"}
                type="table"
                borderRadius={true}
              />
            ) : (
              <BoxImageBlank width={50} height={50} />
            )}
          </>
        ),
      },
      {
        accessorKey: "name",
        flexGrow: 1,
        header: t("global_name"),
        Cell: ({ cell }) => <TextTable props={{ text: cell.getValue() }} />,
      },
      {
        accessorKey: "cateName",
        size: 100,
        header: t("product_category"),
        Cell: ({ cell }) => <TextTable props={{ text: cell.getValue() }} />,
      },
      {
        accessorKey: "update",
        size: 100,
        header: t("global_updated_at"),
        Cell: ({ cell }) => <TextTable props={{ text: cell.getValue() }} />,
      },
      {
        accessorKey: "status",
        enableSorting: false,
        size: 20,
        header: t("global_status"),
        Cell: ({ row }) => (
          <StatusTable props={{ status: row.original.status }} />
        ),
      },
      {
        accessorKey: "action",
        enableSorting: false,
        size: 100,
        header: "Action",
        sortable: false,
        Cell: ({ row }) => (
          <Box sx={{ display: "flex", gap: 2 }}>
            <ActionDetail params={row} detailPath={"/products/detail/"} />
          </Box>
        ),
      },
    ];
    let dataAPI = [];
    let dataBox = [];
    if (productSetAll) {
      dataAPI = productSetAll;
    } else {
      dataAPI = await getAllProduct();
    }
    let dataCateAll = await getAllProductCategory();
    if (dataAPI) {
      if (productAllTable.length === 0) {
        dataAPI.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));
        dataAPI.forEach((item) => {
          item.categoriesName = dataCateAll.find(
            (itemCate) => itemCate.id === item.product_category_id
          ).name;
        });
        dataAPI.map(async (item) => {
          dataBox.push({
            id: item.id,
            image: item.image,
            name: item.product_lang.name,
            cateName: item.categoriesName,
            update: MomentConvert(item.updated_at),
            status: item.status,
          });
        });

        setRows(dataBox);
        fetchDataImageProductTable(dataBox, setRows);
      } else {
        dataBox = productAllTable;
        setRows(dataBox);
      }

      //===== Start set header name export csv
      let headers = [];
      dataColumns.forEach((item) => {
        if (item.header !== "Image" && item.header !== "Action") {
          headers.push(item.header);
        }
      });
      const csvStringArray = [
        headers,
        ...dataBox.map((item) => [
          item.image,
          item.name,
          item.cateName,
          item.update,
          item.status,
        ]),
      ]
        .map((row) => row.join(","))
        .join("\n");

      setExportColumnName(csvStringArray);
      //===== End set header name export csv
    }
    setColumns(dataColumns);
    setIsLoadingData(false);
  };

  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <Box>
      <BreadcrumbsTitle
        props={{
          title: t("product") + " " + t("product_example"),
        }}
      />
      <DataMaterialTable
        statusViewMode={true}
        rows={rows}
        isLoadingData={isLoadingData}
        exportColumnName={exportColumnName}
        fileName={t("product") + ` ${dayjs().format("DD/MM/YYYY")}`}
        columns={columns}
        height={0.95}
        detailPath={"/products/detail/"}
        createButton={t("product")}
      />
    </Box>
  );
}

export default Product;
