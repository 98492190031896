import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { notifySuccess } from "../../../components/ui/popup/toastPopup";
import Loading from "../../../components/ui/loading";
import { useTranslation } from "react-i18next";
import BreadcrumbsTitle from "../../../components/ui/breadcrumbsTitle";
import { useSchema } from "../../../components/hooks/use-schema";
import TableListItemForm from "./tableListItemForm";
import { uploadImage } from "../../../service/upload/uploadImage";
import { getTable, updateTable } from "../../../service/tableAPI";
import { tableSchema } from "../../../components/validation/tableSchema";

function TableListItemDetail() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm(useSchema(tableSchema));

  const { t } = useTranslation();
  const [statusButton, setStatusButton] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const [image, setImage] = useState(null);
  const paramsId =
    window.location.pathname.split("/")[
      window.location.pathname.split("/").length - 1
    ];
  const onConfirmForm = async (register) => {
    setIsSubmitting(true);
    let newImage = "";
    if (image) {
      if (typeof image === "object") {
        newImage = await uploadImage(image);
      } else {
        newImage = image;
      }
    }
    let dataAPI = {
      id: String(paramsId),
      name: register.tableName,
      image: newImage,
      amount: Number(register.amount),
      status: statusButton,
    };
    let statusAPI = await updateTable(dataAPI);
    if (statusAPI === "OK") {
      notifySuccess({
        title: t("notification_successfully_edited_information"),
      });
      navigate("/table");
    }
    await new Promise((resolve) => setTimeout(resolve, 2000));
    setIsSubmitting(false);
  };
  const fetchData = async () => {
    let dataAPI = await getTable(paramsId);
    let { name, amount, status, image } = dataAPI;
    setImage(image);
    setStatusButton(status);
    setValue("tableName", name);
    setValue("amount", amount);
    setIsLoading(false);
  };
  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <Box>
      <BreadcrumbsTitle
        props={{
          title: t("table"),
          dataLink: [
            {
              text: t("table"),
              link: "/table",
            },
            { text: t("table"), link: null },
          ],
        }}
      />
      {isLoading ? (
        <>
          <Loading />
        </>
      ) : (
        <>
          <TableListItemForm
            props={{
              handleSubmit,
              onConfirmForm,
              register,
              errors,
              statusButton,
              setStatusButton,
              isSubmitting,
              image,
              setImage,
            }}
          />
        </>
      )}
    </Box>
  );
}

export default TableListItemDetail;
