import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { notifySuccess } from "../../../components/ui/popup/toastPopup";
import {
  getVoidReceipt,
  updateVoidReceipt,
} from "../../../service/voidReceipt";
import Loading from "../../../components/ui/loading";
import { useTranslation } from "react-i18next";
import BreadcrumbsTitle from "../../../components/ui/breadcrumbsTitle";
import { useSchema } from "../../../components/hooks/use-schema";
import { voidReceiptSchema } from "../../../components/validation/voidReceiptSchema";
import CancelReceiptForm from "./voidReceiptForm";
import { GridContainerSize } from "../../../components/utils/gridContainerSize";
import { Box } from "@mui/material";

function VoidReceiptDetail() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm(useSchema(voidReceiptSchema));

  const { t } = useTranslation();
  const [statusButton, setStatusButton] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [dataAll, setDataAll] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const paramsId =
    window.location.pathname.split("/")[
      window.location.pathname.split("/").length - 1
    ];
  const onConfirmForm = async (register) => {
    setIsSubmitting(true);
    let dataAPI = JSON.stringify({
      id: dataAll.id,
      status: statusButton,
      void_receipt_lang: {
        name: register.voidReceipt,
        language_code: String(localStorage.getItem("language_form")),
      },
    });
    let statusAPI = await updateVoidReceipt(dataAPI);
    if (statusAPI === "OK") {
      notifySuccess({
        title: t("notification_successfully_edited_information"),
      });
      navigate("/cancel-receipt");
    }
    await new Promise((resolve) => setTimeout(resolve, 2000));
    setIsSubmitting(false);
  };
  const fetchData = async () => {
    let dataAPI = await getVoidReceipt(paramsId);
    let { void_receipt_lang, status } = dataAPI;
    setDataAll(dataAPI);
    setStatusButton(status);
    setValue("productCancelReceipt", void_receipt_lang.name);
    setIsLoading(false);
  };
  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <>
      <BreadcrumbsTitle
        props={{
          title: t("void_receipt_info"),
          dataLink: [
            {
              text: t("void_receipt"),
              link: "/cancel-receipt",
            },
            {
              text: t("void_receipt_info"),
              link: null,
            },
          ],
        }}
      />
      {isLoading ? (
        <>
          <Loading />
        </>
      ) : (
        <>
          <GridContainerSize>
            <Box p={2}>
              <CancelReceiptForm
                props={{
                  handleSubmit,
                  onConfirmForm,
                  register,
                  errors,
                  statusButton,
                  setStatusButton,
                  isSubmitting,
                }}
              />
            </Box>
          </GridContainerSize>
        </>
      )}
    </>
  );
}

export default VoidReceiptDetail;
