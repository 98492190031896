import React, { useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import TitleForm from "../formComponent/titleForm";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Fab,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { BoxImage } from "../boxImage/boxImage";
import ClearIcon from "@mui/icons-material/Clear";
import { getImage } from "../../../service/upload/getImage";
import { UseConvertImageToBase64 } from "../../hooks/use-convert-image-to-base-64";

export function ProductDialog({ props }) {
  let {
    menuType,
    productAll,
    setProductAll,
    dataAllProduct,
    setDataAllProduct,
    setDialogProduct,
    dialogProduct,
  } = props;
  const [productType, setProductType] = useState(dataAllProduct[0]?.id);
  const [search, setSearch] = useState("");
  const { t } = useTranslation();
  const handleProductType = (productType) => {
    setProductType(productType.id);
  };
  const handleProductTypeMobile = (e) => {
    setProductType(e.target.value);
  };
  const handleAmount = (item, indexProduct, productCate, status) => {
    if (menuType === 1) {
      setDataAllProduct((prevData) => {
        const newData = [...prevData];
        newData.forEach((cate) => {
          cate.amount = 0;
          cate.product.forEach((product) => {
            product.amount = 0;
          });
        });
        newData.find((itemCate) => itemCate.id === productCate.id).product[
          indexProduct
        ].amount = status === "increase" ? 1 : 0;
        newData.forEach((cate) => {
          cate.amount = 0;
          cate.product.forEach((product) => (cate.amount += product.amount));
        });
        return newData;
      });
    } else {
      if (item.amount >= 0) {
        setDataAllProduct((prevData) => {
          const newData = [...prevData];
          newData.find((itemCate) => itemCate.id === productCate.id).product[
            indexProduct
          ].amount += status === "increase" ? 1 : item.amount === 0 ? 0 : -1;
          newData.forEach((cate) => {
            cate.amount = 0;
            cate.product.forEach((product) => (cate.amount += product.amount));
          });
          return newData;
        });
      }
    }
  };

  const handleClear = () => {
    setSearch("");
    let dataSearch = "";
    setDataAllProduct((prevData) => {
      const newData = prevData.map((cate) => {
        const newProduct = cate.product.map((product) => {
          const productName = product.data.product_lang.name.toUpperCase();
          product.show = !dataSearch || productName.includes(dataSearch);
          return product;
        });
        cate.show = !dataSearch || newProduct.some((product) => product.show);
        return { ...cate, product: newProduct };
      });
      if (newData.filter((item) => item.show === true).length > 0) {
        setProductType(newData.filter((item) => item.show === true)[0].id);
      }
      return newData;
    });
  };

  const handleSearchProduct = async (e) => {
    const dataSearch = e.target.value.toUpperCase();
    setSearch(e.target.value);
    setDataAllProduct((prevData) => {
      const newData = prevData.map((cate) => {
        const newProduct = cate.product.map((product) => {
          const productName = product.data.product_lang.name.toUpperCase();
          product.show = !dataSearch || productName.includes(dataSearch);
          return product;
        });
        cate.show = !dataSearch || newProduct.some((product) => product.show);
        return { ...cate, product: newProduct };
      });
      if (newData.filter((item) => item.show === true).length > 0) {
        setProductType(newData.filter((item) => item.show === true)[0].id);
      }
      return newData;
    });
  };
  const onConfirmProduct = async () => {
    let dataConfirm = [];
    let count = 0;
    dataAllProduct.forEach(async (cate) => {
      cate.show = true;

      cate.product.forEach(async (product) => {
        if (product.data.image.includes("data:image") === false) {
          let imageUrl = await getImage(product.data.image);
          product.data.image = await UseConvertImageToBase64(imageUrl);
        }
        product.show = true;
        if (product.amount > 0) {
          dataConfirm.push({
            id: product.id,
            amount: product.amount,
            name: product.data.product_lang.name,
            image: product.data.image,
          });
        }
      });
      count++;
      if (count === dataAllProduct.length) {
        await handleClear()
        await setProductAll(dataConfirm);
        await setDialogProduct(false);
      }
    });
  };
  const onClose = () => {
    setSearch("");
    setTimeout(() => {
      dataAllProduct.forEach((cate) => {
        cate.amount = 0;
        cate.show = true;
        if (cate.product.length > 0) {
          cate.product.forEach((product) => {
            product.show = true;
            if (productAll?.length > 0) {
              productAll.forEach((itemProductAll) => {
                if (itemProductAll.id === product.id) {
                  product.amount = itemProductAll.amount;
                  cate.amount += itemProductAll.amount;
                } else {
                  product.amount = 0;
                }
              });
            } else {
              product.amount = 0;
            }
          });
        }
      });
    }, 500);
    setDialogProduct(false);
  };
  return (
    <Dialog open={dialogProduct} maxWidth="md" fullWidth>
      <DialogTitle align="center">
        <Typography variant="h5">{t("product_add_product")}</Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container justifyContent={"space-between"} pt={1}>
          <Grid item xs={12} sm={2.8}>
            <TitleForm props={{ text: t("product_category") }} />
            {window.innerWidth < 600 ? (
              <>
                <Select
                  fullWidth
                  sx={{ my: 1 }}
                  value={productType}
                  onChange={handleProductTypeMobile}
                >
                  {dataAllProduct
                    .filter((cate) => cate.show === true)
                    .map((cate) => (
                      <MenuItem value={cate.id}>{cate.name}</MenuItem>
                    ))}
                </Select>
              </>
            ) : (
              <>
                {dataAllProduct
                  .filter((cate) => cate.show === true)
                  .sort(function (a, b) {
                    return a.id - b.id;
                  })
                  .map((cate) => (
                    <Box
                      sx={(theme) => ({
                        width: "100%",
                        borderBottom: "1px solid #cccccc",
                        p: 2,
                        boxSizing: "border-box",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        backgroundColor:
                          productType === cate.id
                            ? theme.palette.primary.main
                            : null,
                        color: productType === cate.id ? "#ffffff" : null,
                      })}
                      onClick={() => handleProductType(cate)}
                    >
                      <Typography sx={(theme) => ({ fontWeight: 600 })}>
                        {cate.name}
                      </Typography>
                      {cate.amount > 0 && (
                        <Box
                          sx={(theme) => ({
                            p: 1,
                            backgroundColor: theme.palette.secondary.main,
                            color: theme.palette.white.main,
                            borderRadius: "50%",
                            width: "20px",
                            height: "20px",
                            textAlign: "center",
                            lineHeight: 0,
                            placeItems: "center",
                            display: "grid",
                          })}
                        >
                          {cate.amount}
                        </Box>
                      )}
                    </Box>
                  ))}{" "}
              </>
            )}
          </Grid>
          <Grid item sm={9}>
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                endAdornment: search && (
                  <InputAdornment position="end">
                    <ClearIcon
                      onClick={handleClear}
                      style={{ cursor: "pointer" }}
                    />
                  </InputAdornment>
                ),
              }}
              value={search}
              onChange={handleSearchProduct}
              placeholder={t("global_search_product")}
              size="small"
              fullWidth
            />
            <Grid
              container
              sx={(theme) => ({
                height: "40vh",
                maxHeight: "50vh",
                overflow: "auto",
              })}
            >
              {dataAllProduct
                .filter((data) => data.id === productType)
                .map((productCate, cateIndex) => (
                  <>
                    {productCate.product.length === 0 ? (
                      <Grid item sm={12} align="center" p={2}>
                        <Typography>{t("global_data_not_found")}</Typography>
                      </Grid>
                    ) : (
                      <>
                        {productCate.show === true &&
                          productCate.product.map((product, indexProduct) => (
                            <>
                              {product.show === true && (
                                <Grid
                                  item
                                  xs={12}
                                  sm={6}
                                  align="center"
                                  sx={{ boxSizing: "border-box", p: 1 }}
                                >
                                  <Grid
                                    container
                                    sx={{ boxShadow: 1, borderRadius: 1 }}
                                  >
                                    <Grid xs={6}>
                                      <BoxImage
                                        image={product.data.image}
                                        height={200}
                                        type="table"
                                      />
                                    </Grid>
                                    <Grid
                                      xs={6}
                                      sx={{
                                        display: "grid",
                                        placeItems: "center",
                                      }}
                                    >
                                      <Typography
                                        sx={(theme) => ({
                                          fontFamily: "Kanit",
                                        })}
                                      >
                                        {product.data.product_lang.name}
                                      </Typography>
                                      <Box
                                        sx={{
                                          display: "flex",
                                          alignItems: "center",
                                          gap: 1,
                                        }}
                                      >
                                        <Fab
                                          sx={{ boxShadow: 0 }}
                                          aria-label="like"
                                          size="small"
                                          onClick={() =>
                                            handleAmount(
                                              product,
                                              indexProduct,
                                              productCate,
                                              "decrease"
                                            )
                                          }
                                        >
                                          <RemoveIcon />
                                        </Fab>
                                        <Typography
                                          sx={(theme) => ({
                                            fontFamily: "Kanit",
                                          })}
                                        >
                                          {product.amount}
                                        </Typography>
                                        <Fab
                                          sx={{ boxShadow: 0 }}
                                          aria-label="like"
                                          size="small"
                                          onClick={() =>
                                            handleAmount(
                                              product,
                                              indexProduct,
                                              productCate,
                                              "increase"
                                            )
                                          }
                                        >
                                          <AddIcon />
                                        </Fab>
                                      </Box>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              )}
                            </>
                          ))}
                      </>
                    )}
                  </>
                ))}
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} sx={{ my: 2 }}>
            <Box
              sx={(theme) => ({
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                gap: 2,
              })}
            >
              <Button
                size="large"
                variant="outlined"
                onClick={() => onClose()}
                sx={{ borderRadius: 2 }}
              >
                {t("cancel_button")}
              </Button>
              <Button
                size="large"
                variant="contained"
                onClick={() => onConfirmProduct()}
                sx={{ borderRadius: 2 }}
              >
                {t("confirm_button")}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
