import React, { useState, useEffect } from "react";
import { Box, Grid, Tab } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
// import BranchTaxInvoice from "./branchTaxInvoice";
import { useTranslation } from "react-i18next";
import SelfPickupConfig from "./selfPickupConfig/selfPickupConfig";
import SelfPickupTheme from "./selfPickupTheme/selfPickupTheme";
import BreadcrumbsTitle from "../../../components/ui/breadcrumbsTitle";
import { checkSelfPickup } from "../../../service/selfPickUp/config/checkSelfPickup";
import Loading from "../../../components/ui/loading";
import SelfPickupSetup from "./selfPickupSetup";
import SelfPickupLineProvider from "./selfPickupLineProvider";

function SelfPickupSetupTab() {
  const [value, setValue] = useState("1");
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation();
  const [statusConfig, setStatusConfig] = useState(false);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const fetchDataConfig = async () => {
    setIsLoading(true);
    let dataAPI = await checkSelfPickup();
    if (dataAPI) {
      setStatusConfig(true);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    fetchDataConfig();
  }, []); // eslint-disable-next-line react-hooks/exhaustive-deps
  return (
    <div align="left">
      <BreadcrumbsTitle
        props={{
          title: t("self pick up config"),
        }}
      />
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {statusConfig ? (
            <Grid
              container
              justifyContent={"space-between"}
            >
              <Grid item xs={12}>
                <TabContext value={value}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <TabList
                      onChange={handleChange}
                      aria-label="lab API tabs"
                      variant="scrollable"
                      scrollButtons="auto"
                    >
                      {[t("config"), t("theme"), t("line provider")].map(
                        (itemTab, index) => (
                          <Tab label={itemTab} value={String(index + 1)} />
                        )
                      )}
                    </TabList>
                  </Box>

                  <TabPanel value="1">
                    <SelfPickupConfig />
                  </TabPanel>
                  <TabPanel value="2">
                    <SelfPickupTheme />
                  </TabPanel>
                  <TabPanel value="3">
                    <SelfPickupLineProvider />
                  </TabPanel>
                </TabContext>
              </Grid>
            </Grid>
          ) : (
            <SelfPickupSetup props={{ fetchDataConfig }} />
          )}
        </>
      )}
    </div>
  );
}

export default SelfPickupSetupTab;
