import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  IconButton,
  FormControlLabel,
  Grid,
  Switch,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { getAllBranch } from "../../service/branchAPI";
import { uploadImage } from "../../service/upload/uploadImage";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { notifySuccess } from "../../components/ui/popup/toastPopup";
import Swal from "sweetalert2";
import {
  addSliderToTemplate,
  getAllSliderTemplate,
  getSlider,
  updateSlider,
} from "../../service/sliderAPI";
import screenSize from "../../components/utils/screenSize.json";
import ConfirmButton from "../../components/ui/button/confirmButton";
import AwesomeSlider from "react-awesome-slider";
import withAutoplay from "react-awesome-slider/dist/autoplay";
import SettingPlatformScreen from "../../assets/images/settingPlatformScreen.png";
import Loading from "../../components/ui/loading";
import { useTranslation } from "react-i18next";
import { useRef } from "react";
import BreadcrumbsTitle from "../../components/ui/breadcrumbsTitle";
import TitleForm from "../../components/ui/formComponent/titleForm";
import StatusForm from "../../components/ui/formComponent/statusForm";
import SettingTypeBranch from "../../components/ui/addTypeTemplate/settingTypeBranch";
import { useSchema } from "../../components/hooks/use-schema";
import { backScreenSchema } from "../../components/validation/backScreenSchema";
import { PaddingContainer } from "../../components/utils/paddingContainer";
import TextfieldComponent from "../../components/ui/textfield/textfieldComponent";
import SelectComponent from "../../components/ui/textfield/selectComponent";
import { BrowserView } from "react-device-detect";
import { BoxImage } from "../../components/ui/boxImage/boxImage";
import { getImage } from "../../service/upload/getImage";
import { UseConvertImageToBase64 } from "../../components/hooks/use-convert-image-to-base-64";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import PaperWrapper from "../../components/ui/paper/paperWrapper";
import { GridContainerSize } from "../../components/utils/gridContainerSize";
import { convertToPNG } from "../../components/ui/upload/convertHEIC";
import ImageUploadDialog from "./imageUploadDialog";
import { BoxGapInput } from "../../components/ui/box/boxGapInput";
import { MyContext } from "../../context/MyContext";
import { BoxWrapper } from "../../components/ui/box/boxWrapper";
import { BoxFormTitleAction } from "../../components/ui/box/boxFormTitleAction";
import TitleHeaderForm from "../../components/ui/formComponent/titleHeaderForm";
import SunmiDevice from "../../assets/images/Sunmi-T2s-device.png";
import Sunmi3ProDevice from "../../assets/images/sunmi-d3-pro.png";
function BackScreenDetail(props) {
  const fileInputRef = useRef(null);
  const AutoplaySlider = withAutoplay(AwesomeSlider);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    clearErrors,
    control,
  } = useForm(useSchema(backScreenSchema));
  let todoItemDrag = useRef();
  let todoItemDragOver = useRef();
  const { t } = useTranslation();
  const [numImage, setNumImage] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [statusButton, setStatusButton] = useState(true);
  const [allDataTemplate, setAllDataTemplate] = useState([]);
  const [dataAll, setDataAll] = useState([]);
  const [sizeSlider, setSizeSlider] = useState(null);
  const [sizeShow, setSizeShow] = useState("");
  const [sizeWHSlider, setSizeWHSlider] = useState({
    width: 1024,
    height: 600,
  });
  const navigate = useNavigate();
  const paramsId =
    window.location.pathname.split("/")[
      window.location.pathname.split("/").length - 1
    ];
  const [imageAll, setImageAll] = useState([
    { url: null, priority: 99, status: true },
  ]);
  const [loadingImage, setLoadingImage] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [dialogCropImage, setDialogCropImage] = useState(false);
  const previewCanvasRef = useRef();
  const [imageUpload, setImageUpload] = useState(null);
  const [crop, setCrop] = useState(null);
  const [completedCrop, setCompletedCrop] = useState();
  const { fetchBackScreen } = useContext(MyContext);

  const imgRef = useRef();
  const aspect =
    sizeWHSlider.width || sizeWHSlider.height
      ? sizeWHSlider.width / sizeWHSlider.height
      : 1 / 1;

  const onConfirmForm = async (register) => {
    let imageScreen = imageAll.filter((item) => item.url !== null);
    if (imageScreen.length > 0) {
      setIsSubmitting(true);
      let imageAllConvert = [];
      let count = 0;
      imageScreen.forEach(async (itemImage, index) => {
        let newImage = "";
        if (typeof itemImage.url === "object") {
          newImage = await uploadImage(itemImage.url);
          imageAllConvert.push({
            priority: index + 1,
            status: itemImage.status,
            url: newImage,
          });
          count = count + 1;
        } else {
          imageAllConvert.push({
            priority: itemImage.priority,
            status: itemImage.status,
            url: itemImage.urlOld,
          });
          count = count + 1;
        }
        if (count === imageScreen.length) {
          let dataAPI = {
            id: String(paramsId),
            name: register.nameSlider,
            status: statusButton,
            slider_images: imageAllConvert,
            type: sizeSlider === 1 ? "full" : "half",
          };
          let statusAPI = await updateSlider(dataAPI);
          if (statusAPI === "OK") {
            fetchBackScreen()
              .then(() => {
                notifySuccess({
                  title: t("notification_successfully_edited_information"),
                });
                navigate("/slider");
              })
              .catch((error) => {
                console.error("Error fetching product:", error);
              });
          }
        }
      });
    } else {
      Swal.fire({
        title: t("notification_please_upload_image"),
        icon: "warning",
      });
    }
    await new Promise((resolve) => setTimeout(resolve, 2000));
    setIsSubmitting(false);
  };
  // const processImage = async (file, numImage) => {
  //   return new Promise((resolve, reject) => {
  //     let img = new Image();
  //     img.onload = () => {
  //       let canvas = document.createElement("canvas");
  //       let context = canvas.getContext("2d");
  //       canvas.width = sizeWHSlider.width;
  //       canvas.height = sizeWHSlider.height;
  //       context.drawImage(img, 0, 0, sizeWHSlider.width, sizeWHSlider.height);
  //       let resizedImageUrl = canvas.toDataURL(file.type);
  //       let fileConvert = base64StringToFile(
  //         resizedImageUrl,
  //         "productImage.png"
  //       );
  //       resolve(fileConvert);
  //     };
  //     img.onerror = (error) => reject(error);
  //     img.src = URL.createObjectURL(file);
  //   });
  // };

  // const onUploadFile = async (e) => {
  //   let dataImageAll = [...imageAll];
  //   if (e.target.files.length > 1) {
  //     setLoadingImage(true);
  //     let fileBox = e.target.files;
  //     let newDataImageAll = [];

  //     for (let indexFile = 0; indexFile < fileBox.length; indexFile++) {
  //       try {
  //         // const fileConvert = await processImage(fileBox[indexFile]);
  //         newDataImageAll.push({
  //           url: fileBox[indexFile],
  //           priority: 0,
  //           status: true,
  //           draggable: false,
  //         });
  //       } catch (error) {
  //         console.error("Error processing image:", error);
  //       }
  //     }
  //     setImageAll((prevDataImageAll) => [
  //       ...prevDataImageAll,
  //       ...newDataImageAll,
  //     ]);
  //     setLoadingImage(false);
  //     onAddImage();
  //   } else {
  //     const file = e.target.files[0];

  //     try {
  //       const fileConvert = await processImage(file);
  //       dataImageAll[num].url = fileConvert;
  //       setImageAll([...dataImageAll]);
  //       setLoadingImage(false);
  //       onAddImage();
  //     } catch (error) {
  //       console.error("Error processing image:", error);
  //     }
  //   }
  // };
  const onUploadFile = async (e) => {
    localStorage.setItem("uploadImage", true);
    const file = e.target.files[0];
    let newImage = null;
    if (file.type === "image/heic") {
      const pngBlob = await convertToPNG(file);
      newImage = URL.createObjectURL(pngBlob);
    } else {
      newImage = URL.createObjectURL(file);
    }
    setCrop(null);
    setCompletedCrop(null);

    setImageUpload(newImage);
    setDialogCropImage(true);
  };
  const onChangeSizeSlider = (itemSlider) => {
    let dataFindScreenSize = screenSize.find(
      (screen) => screen.id === itemSlider
    );
    let dataSizeSlider = dataFindScreenSize.name.includes("Full") ? 1 : 2;
    setValue("selectSize", itemSlider);
    if (sizeSlider) {
      Swal.fire({
        title: t("notification_confirm_change_size_screen"),
        text: t("notification_if_you_change_screen_size_image_will_reset"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Confirm",
      }).then((result) => {
        if (result.isConfirmed) {
          setSizeSlider(dataSizeSlider);
          let dataWH = screenSize.find((screen) => screen.id === itemSlider);
          setSizeWHSlider({
            width: dataWH.value.width,
            height: dataWH.value.height,
          });
          setImageAll([
            { url: null, priority: 0, status: true, draggable: false },
          ]);
        }
      });
    } else {
      setSizeSlider(dataSizeSlider);
      let dataWH = screenSize.find((screen) => screen.id === itemSlider);
      setSizeWHSlider({
        width: dataWH.value.width,
        height: dataWH.value.height,
      });
      setImageAll([{ url: null, priority: 0, status: true, draggable: false }]);
    }
  };
  const onAddImage = async () => {
    let dataAdd = { url: null, priority: 0, status: true };
    let dataImageAll = [...imageAll];
    dataImageAll.push(dataAdd);
    setImageAll(dataImageAll);
  };
  const removeUploadDataImage = async (item) => {
    if (imageAll.length > 0) {
      let array = [...imageAll];
      let index = array.indexOf(item);
      if (index !== -1) {
        array.splice(index, 1);
        setImageAll(array);
      }
    }
    if (imageAll.length === 1) {
      setImageAll([{ url: null, priority: 0, status: true }]);
    }
  };
  const onChangeStatus = (index) => async (e) => {
    let dataNews = [...imageAll];
    dataNews[index].status = e.target.checked;
    setImageAll(dataNews);
  };
  const fetchDataGetSlider = async (dataAPI) => {
    let dataImageBox = [];
    if (dataAPI.status) {
      await fetchAllBranch();
    }
    dataAPI.slider_images.forEach((item) => {
      dataImageBox.push({
        urlOld: item.urlOld,
        url: item.url,
        priority: item.priority,
        status: item.status,
      });
    });
    dataImageBox.push({ url: null, priority: 0, status: true });
    await setImageAll(dataImageBox);
    await setStatusButton(dataAPI.status);
    await setValue("nameSlider", dataAPI.name);
    await setDataAll(dataAPI);
    await onCheckHeightImage(dataImageBox);
    // branch
  };
  const onCheckHeightImage = async (dataImageBox) => {
    let dataAPICheck = await getSlider(paramsId);
    // if (dataAPICheck.slider_images.length > 0) {
    if (dataImageBox.length > 0 && dataAPICheck.slider_images[0].url !== null) {
      const newGetImage = await getImage(dataAPICheck.slider_images[0].url);
      const fileSize = UseConvertImageToBase64(newGetImage);
      const img = new Image();
      img.onload = () => {
        if (img.height === 600 && img.width === 1024) {
          setSizeSlider(1);
          setSizeShow("T2s");
          setValue("selectSize", 1);
          setSizeWHSlider({
            width: 1024,
            height: 600,
          });
          setIsLoading(false);
        } else if (img.height === 600 && img.width === 512) {
          setSizeSlider(2);
          setSizeShow("T2s");
          setValue("selectSize", 2);
          setSizeWHSlider({
            width: 512,
            height: 600,
          });
          setIsLoading(false);
        } else if (img.height === 800 && img.width === 1280) {
          setSizeSlider(3);
          setSizeShow("D3Pro");
          setValue("selectSize", 3);
          setSizeWHSlider({
            width: 1280,
            height: 800,
          });
          setIsLoading(false);
        } else if (img.height === 800 && img.width === 640) {
          setSizeSlider(4);
          setSizeShow("D3Pro");
          setValue("selectSize", 4);
          setSizeWHSlider({
            width: 640,
            height: 800,
          });
          setIsLoading(false);
        }
      };
      img.src = fileSize;
    } else {
      setIsLoading(false);
    }
    // } else {
    //   setSizeSlider(2);
    //   setValue("selectSize", 2);
    //   setSizeWHSlider({
    //     width: 512,
    //     height: 600,
    //   });
    //   setIsLoading(false);
    // }
  };
  const fetchData = async () => {
    localStorage.removeItem("uploadImage");
    let dataAPI = await getSlider(paramsId);
    const promises = dataAPI.slider_images.map(async (itemImage) => {
      let imageUrl = await getImage(itemImage.url);
      itemImage.urlOld = itemImage.url;
      itemImage.url = UseConvertImageToBase64(imageUrl);
    });
    await Promise.all(promises);
    // detail
    await fetchDataGetSlider(dataAPI);
  };
  const onAddSliderToTemplate = async () => {
    let branchArray = allDataTemplate.filter((item) => item.select === true);
    let dataSelect = [];
    branchArray.forEach((item) => {
      dataSelect.push(item.branch.id);
    });
    let typeOfSlider = sizeSlider === 1 ? "full" : "half";
    let dataAPI = {
      slider_id: dataAll.id,
      type: typeOfSlider,
      branches: dataSelect,
    };
    if (sizeSlider) {
      let dataStatus = await addSliderToTemplate(dataAPI);
      if (dataStatus === "OK") {
        notifySuccess({
          title: t("notification_successfully_edited_information"),
        });
        fetchAllBranch();
      }
    }
  };
  const fetchAllBranch = async () => {
    let dataAPI = await getAllBranch();
    dataAPI = dataAPI.filter((item) => item.branch_lang !== null);
    await fetchSettingSelectTemplate(dataAPI);
  };
  const fetchSettingSelectTemplate = async (dataBranch) => {
    let dataAPI = await getAllSliderTemplate(paramsId);
    dataAPI = dataAPI[0].template_sliders;
    let dataBox = [];
    dataBranch.forEach((item) => {
      let dataNews = dataAPI.filter(
        (itemTemplate) => itemTemplate.template_id === item.template_id
      );
      if (dataNews.length > 0) {
        dataBox.push({
          branch: item,
          data: dataNews[0],
          select: false,
        });
      } else {
        dataBox.push({
          branch: item,
          data: null,
          select: false,
        });
      }
    });
    await setAllDataTemplate(dataBox);
  };
  function D_Start(e, index) {
    todoItemDrag.current = index;
  }
  function D_Enter(e, index) {
    todoItemDragOver.current = index;
    const newItems = [...imageAll];
    newItems.forEach((item) => {
      item.draggable = false;
    });
    newItems[index].draggable = true;
    setImageAll(newItems);
  }
  function D_End(e, index) {
    const newItems = [...imageAll];
    let todo_item_main = newItems[todoItemDrag.current];
    newItems.splice(todoItemDrag.current, 1);
    newItems.splice(todoItemDragOver.current, 0, todo_item_main);
    todoItemDrag.current = null;
    todoItemDragOver.current = null;
    newItems.forEach((item) => {
      item.draggable = false;
    });
    setImageAll(newItems);
  }

  const onConfirmStatusChange = async (checked) => {
    if (checked) {
      setStatusButton(checked);
    } else {
      Swal.fire({
        title: t("notification_confirm_change_status"),
        text: t(
          "notification_changing_the_status_will_affect_the_branch_settings"
        ),
        icon: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          setStatusButton(checked);
          //sss
        }
      });
    }
  };
  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <>
      <BreadcrumbsTitle
        props={{
          title: t("back_screen_info"),
          dataLink: [
            {
              text: t("back_screen"),
              link: "/slider",
            },
            {
              text: t("back_screen_info"),
              link: null,
            },
          ],
        }}
      />
      {isLoading ? (
        <Loading />
      ) : (
        <GridContainerSize>
          <form onSubmit={handleSubmit(onConfirmForm)}>
            <BoxWrapper>
              <PaperWrapper props={{ growFull: 1 }}>
                <BoxFormTitleAction>
                  <TitleHeaderForm props={{ text: t("back_screen") }} />
                  <StatusForm
                    statusButton={statusButton}
                    setStatusButton={
                      onConfirmStatusChange
                        ? onConfirmStatusChange
                        : setStatusButton
                    }
                  />
                </BoxFormTitleAction>
                <BoxGapInput>
                  <Box p={PaddingContainer.size}>
                    <Box className="boxWrapper-container">
                      <PaperWrapper props={{ growFull: 1 }}>
                        <TitleForm
                          props={{
                            text: t("back_screen_info"),
                          }}
                        />
                        <BoxGapInput>
                          <TextfieldComponent
                            title={t("back_screen_name")}
                            name="nameSlider"
                            register={register}
                            placeholder={t("back_screen_name_example")}
                            errors={errors}
                          />
                          <SelectComponent
                            title={t("back_screen_size_screen")}
                            register={register}
                            name="selectSize"
                            errors={errors}
                            dataEmpty={t("back_screen_size_screen_select")}
                            optionsData={screenSize}
                            dataValue={sizeSlider}
                            onFunctionName={onChangeSizeSlider}
                            clearErrors={clearErrors}
                            control={control}
                            watch={watch}
                          />
                        </BoxGapInput>
                      </PaperWrapper>

                      <PaperWrapper props={{ growFull: 1 }}>
                        <TitleForm
                          props={{ text: t("back_screen_sequence_image") }}
                        />
                        <Box p={PaddingContainer.size}>
                          <Grid container justifyContent={"flex-start"}>
                            {loadingImage ? (
                              <>
                                <Loading />
                              </>
                            ) : (
                              <>
                                {imageAll.map((itemImage, index) => (
                                  <Grid
                                    item
                                    xs={12}
                                    sm={sizeSlider === 1 ? 6 : 4}
                                    lg={sizeSlider === 1 ? 4 : 6}
                                    xl={sizeSlider === 1 ? 3 : 3}
                                    key={index}
                                    draggable
                                    droppable
                                    onDragStart={(e) =>
                                      itemImage.urlOld
                                        ? D_Start(e, index)
                                        : null
                                    }
                                    onDragEnter={(e) =>
                                      itemImage.urlOld
                                        ? D_Enter(e, index)
                                        : null
                                    }
                                    onDragEnd={(e) =>
                                      itemImage.urlOld ? D_End(e, index) : null
                                    }
                                    sx={(theme) => ({
                                      transition: "all 0.2s ease-in-out",
                                      border: itemImage.draggable
                                        ? `10px solid ${theme.palette.primary.main}`
                                        : null,
                                      opacity: itemImage.draggable ? 0.5 : 1,
                                      p: 1,
                                      boxSizing: "border-box",
                                    })}
                                  >
                                    <Box
                                      sx={(theme) => ({
                                        boxSizing: "border-box",
                                        p: 1,
                                        boxShadow: 2,
                                        borderRadius: 1,
                                        backgroundColor:
                                          theme.palette.mode === "dark"
                                            ? "#202020"
                                            : null,
                                      })}
                                    >
                                      <BrowserView>
                                        <Box
                                          sx={(theme) => ({
                                            display: "flex",
                                            justifyContent: "center",
                                            transform: "rotate(-90deg)",
                                            ":hover": {
                                              cursor: "grab",
                                            },
                                          })}
                                        >
                                          <DragIndicatorIcon />
                                        </Box>
                                      </BrowserView>
                                      <label htmlFor="icon-button-image-slider-update">
                                        {itemImage.url ? (
                                          <BoxImage
                                            image={itemImage.url}
                                            height={sizeWHSlider.height}
                                            width={sizeWHSlider.width}
                                            type="table"
                                          />
                                        ) : (
                                          <Box
                                            onClick={() => setNumImage(index)}
                                          >
                                            <Box
                                              sx={(theme) => ({
                                                border: "2px solid #cccccc80",
                                                borderRadius: 1,
                                                gap: 1,
                                                p: 2,
                                                boxSizing: "border-box",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                ":hover": {
                                                  opacity: 0.8,
                                                  cursor: "pointer",
                                                },
                                                backgroundColor: "#cccccc50",
                                                maxHeight: sizeWHSlider.height
                                                  ? sizeWHSlider.height
                                                  : "100%",
                                                height: "100%",
                                                maxWidth: sizeWHSlider.width
                                                  ? sizeWHSlider.width
                                                  : "100%",
                                                objectFit: "cover",
                                                width: "100%",
                                                display: "flex",
                                              })}
                                            >
                                              <Box sx={{ width: "100%" }}>
                                                <Box
                                                  sx={{
                                                    display: "flex",
                                                    gap: 1,
                                                    backgroundColor:
                                                      "#00000090",
                                                    color: "#fff",
                                                    borderRadius: 1,
                                                    boxSizing: "border-box",
                                                    p: 1,
                                                    justifyContent: "center",
                                                    algnItems: "center",
                                                  }}
                                                >
                                                  <AddAPhotoIcon />
                                                  <Typography>
                                                    Add Photo
                                                  </Typography>
                                                </Box>
                                                <Box my={1} align="center">
                                                  <Typography
                                                    sx={{ opacity: 0.5 }}
                                                  >
                                                    Recommended size <br />
                                                    {sizeWHSlider.width} x{" "}
                                                    {sizeWHSlider.height} PX{" "}
                                                    <br /> Format suggest
                                                    JPG,PNG
                                                  </Typography>
                                                </Box>
                                              </Box>
                                            </Box>
                                          </Box>
                                        )}
                                        <input
                                          ref={fileInputRef}
                                          accept="image/*"
                                          id="icon-button-image-slider-update"
                                          type="file"
                                          key={index}
                                          style={{
                                            display: "none",
                                            width: "100%",
                                            height: "160px",
                                          }}
                                          onChange={onUploadFile}
                                          name="imageDataUpdate"
                                          // multiple
                                        />
                                      </label>
                                      <Box
                                        sx={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          alignItems: "center",
                                        }}
                                      >
                                        <FormControlLabel
                                          control={
                                            <Switch
                                              onChange={onChangeStatus(index)}
                                              checked={itemImage.status}
                                            />
                                          }
                                          label={t("global_status")}
                                        />
                                        <IconButton
                                          onClick={() =>
                                            removeUploadDataImage(itemImage)
                                          }
                                          disabled={!itemImage.url}
                                        >
                                          <DeleteIcon />
                                        </IconButton>
                                      </Box>
                                    </Box>
                                  </Grid>
                                ))}
                              </>
                            )}
                          </Grid>
                        </Box>
                      </PaperWrapper>

                      <PaperWrapper props={{ growFull: 1 }}>
                        <TitleForm
                          props={{ text: t("back_screen_example_slider") }}
                        />
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            p: 2,
                            boxSizing: "border-box",
                            position: "relative",
                          }}
                        >
                          <Box sx={{ margin: "auto", width: "80%" }}>
                            {sizeShow.includes("T2s") ? (
                              <img
                                src={SunmiDevice}
                                alt="SunmiDevice"
                                width="100%"
                              />
                            ) : (
                              <img
                                src={Sunmi3ProDevice}
                                alt="Sunmi3ProDevice"
                                width="100%"
                              />
                            )}
                          </Box>
                          <Box
                            sx={(theme) => ({
                              width: sizeShow.includes("T2s") ? "62%" : "32%",
                              position: "absolute",
                              top: sizeShow.includes("T2s") ? "10%" : "35%",
                            })}
                          >
                            {sizeSlider % 2 !== 0 ? (
                              <>
                                <AutoplaySlider
                                  play={true}
                                  organicArrows={false}
                                  infinite={true}
                                  bullets={false}
                                  mobileTouch={true}
                                  cancelOnInteraction={false}
                                  interval={400}
                                >
                                  {imageAll
                                    .filter((item) => item.url !== null)
                                    .map((itemImage, index) => (
                                      <>
                                        <div>
                                          <>
                                            <BoxImage
                                              image={itemImage.url}
                                              height={sizeWHSlider.height}
                                              width={sizeWHSlider.width}
                                              type="table"
                                            />
                                          </>
                                        </div>
                                      </>
                                    ))}
                                </AutoplaySlider>
                              </>
                            ) : (
                              <>
                                <Grid container>
                                  <Grid
                                    item
                                    xs={6}
                                    sm={6}
                                    sx={{ backgroundColor: "#ffffff" }}
                                  >
                                    <AutoplaySlider
                                      play={true}
                                      organicArrows={false}
                                      infinite={true}
                                      mobileTouch={true}
                                      bullets={false}
                                      cancelOnInteraction={false} // should stop playing on user interaction
                                      interval={400}
                                      style={{ height: "100%" }}
                                    >
                                      {imageAll
                                        .filter((item) => item.url !== null)
                                        .map((itemImage, index) => (
                                          <>
                                            <BoxImage
                                              image={itemImage.url}
                                              height={sizeWHSlider.height}
                                              width={sizeWHSlider.width}
                                              type="table"
                                            />
                                          </>
                                        ))}
                                    </AutoplaySlider>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={6}
                                    sm={6}
                                    sx={{ backgroundColor: "#ffffff" }}
                                  >
                                    <img
                                      src={SettingPlatformScreen}
                                      alt="SettingPlatformScreen"
                                      width="100%"
                                    />
                                  </Grid>
                                </Grid>
                              </>
                            )}
                          </Box>
                        </Box>
                      </PaperWrapper>
                      <PaperWrapper props={{ growFull: 1 }}>
                        <SettingTypeBranch
                          title={t("back_screen_branch")}
                          allDataTemplate={allDataTemplate}
                          setAllDataTemplate={setAllDataTemplate}
                          onAddTypeToTemplate={onAddSliderToTemplate}
                          directToBranch={"sliderScreen"}
                        />
                      </PaperWrapper>
                    </Box>
                  </Box>
                  <ConfirmButton disabled={isSubmitting} />
                </BoxGapInput>
              </PaperWrapper>
            </BoxWrapper>
          </form>
        </GridContainerSize>
      )}
      <ImageUploadDialog
        props={{
          onAddImage,
          setDialogCropImage,
          setImageUpload,
          setImageAll,
          sizeWHSlider,
          setLoadingImage,
          imageAll,
          previewCanvasRef,
          numImage,
          imageUpload,
          setCompletedCrop,
          setCrop,
          completedCrop,
          aspect,
          dialogCropImage,
          imgRef,
          crop,
        }}
      />
    </>
  );
}

export default BackScreenDetail;
