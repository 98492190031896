import { Chip, Typography } from "@mui/material";
import { t } from "i18next";
import React from "react";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
function StatusTable({ props }) {
  let { status } = props;
  return (
    <Chip
      label={
        status ? (
          <Typography
            sx={{
              fontSize: 13,
            }}
          >
            {t("global_active")}
          </Typography>
        ) : (
          <Typography
            sx={{
              fontSize: 13,
            }}
          >
            {t("global_in_active")}
          </Typography>
        )
      }
      color={status ? "success" : "default"}
      icon={
        status ? (
          <FiberManualRecordIcon
            sx={(theme) => ({
              fontSize: 13,
            })}
          />
        ) : null
      }
      sx={(theme) => ({
        color: status ? theme.palette.success.main : theme.palette.white.main,
        backgroundColor: status ? theme.palette.successBg.main : "#ccc",
        borderRadius: 2,
      })}
    />
  );
}

export default StatusTable;
