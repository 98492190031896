import { Box } from "@mui/material";
import React from "react";
import BarOneDayDashBoard from "../chart/barOneDayDashboard";

function DashboardTotalSaleOneDay({ props }) {
  let { data, branchSelect, nameValue, symbol, title, summary, isConfirmData } =
    props;
  return (
    <div>
      <Box
        sx={{
          backgroundColor: "#ffffff",
          boxSizing: "border-box",
          p: 2,
          borderRadius: 2,
          overflow: "auto",
          width: "100%",
        }}
      >
        <BarOneDayDashBoard
          props={{
            dataValues: data,
            nameValue: nameValue,
            branchSelect,
            symbol: symbol,
            title,
            summary,
            isConfirmData,
          }}
        />
      </Box>
    </div>
  );
}

export default DashboardTotalSaleOneDay;
