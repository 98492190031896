import { Box, FormControlLabel, Radio, Switch } from "@mui/material";
import React from "react";
import PaperWrapper from "../../../components/ui/paper/paperWrapper";
import TitleForm from "../../../components/ui/formComponent/titleForm";
import { BoxGapInput } from "../../../components/ui/box/boxGapInput";
import SelectComponent from "../../../components/ui/textfield/selectComponent";
import EmptyDataDirect from "../../../components/ui/emptyDataDirect";
import { t } from "i18next";
import TextfieldComponent from "../../../components/ui/textfield/textfieldComponent";
import { Money } from "@mui/icons-material";
import ConfirmButton from "../../../components/ui/button/confirmButton";
import { BoxWrapper } from "../../../components/ui/box/boxWrapper";
import { BoxFormTitleAction } from "../../../components/ui/box/boxFormTitleAction";
import TitleHeaderForm from "../../../components/ui/formComponent/titleHeaderForm";
import StatusForm from "../../../components/ui/formComponent/statusForm";

function ProductOptionsForm({ props }) {
  let {
    register,
    errors,
    watch,
    setValue,
    control,
    clearErrors,
    categoryProductList,
    unitCounterAll,
    vatStatus,
    onVatStatus,
    statusPrice,
    onStatusPrice,
    amountCount,
    setAmountCount,
    statusButton,
    setStatusButton,
    isSubmitting,
  } = props;
  return (
    <BoxWrapper>
      <PaperWrapper props={{ growFull: 1 }}>
        <BoxFormTitleAction>
          <TitleHeaderForm props={{ text: t("unit_info") }} />
          <StatusForm
            statusButton={statusButton}
            setStatusButton={setStatusButton}
          />
        </BoxFormTitleAction>
        <BoxGapInput>
          <Box className="boxWrapper-container">
            <PaperWrapper props={{ growFull: 1 }}>
              <TitleForm props={{ text: t("product_option_info") }} />
              <BoxGapInput>
                <SelectComponent
                  title={t("product_option_category")}
                  watch={watch}
                  register={register}
                  setValue={setValue}
                  name="productCategory"
                  errors={errors}
                  dataEmpty={t("product_option_category_select")}
                  optionsData={categoryProductList}
                  disabled={categoryProductList.length === 0}
                  dataAlert={
                    <>
                      {categoryProductList.length === 0 ? (
                        <EmptyDataDirect
                          props={{
                            url: "/pre-define-category",
                            text: t("product_option_category"),
                          }}
                        />
                      ) : null}
                    </>
                  }
                  clearErrors={clearErrors}
                  control={control}
                />

                <TextfieldComponent
                  title={t("product_option_name")}
                  name="productOptionName"
                  register={register}
                  placeholder={t("product_option_name_example")}
                  errors={errors}
                />
                <SelectComponent
                  title={t("unit")}
                  watch={watch}
                  register={register}
                  setValue={setValue}
                  name="unit"
                  errors={errors}
                  dataEmpty={t("unit_select")}
                  optionsData={unitCounterAll}
                  disabled={unitCounterAll.length === 0}
                  dataAlert={
                    <>
                      {unitCounterAll.length === 0 ? (
                        <EmptyDataDirect
                          props={{ url: "/unit", text: t("unit") }}
                        />
                      ) : null}
                    </>
                  }
                  clearErrors={clearErrors}
                  control={control}
                />
              </BoxGapInput>
            </PaperWrapper>
            <PaperWrapper props={{ growFull: 1 }}>
              <TitleForm props={{ text: t("global_calculate_vat_title") }} />
              <Box p={2}>
                <FormControlLabel
                  control={
                    <Radio
                      name="vatStatus"
                      checked={vatStatus}
                      onChange={(e) => onVatStatus(true)}
                    />
                  }
                  label={t("global_calculate_vat")}
                />
                <FormControlLabel
                  control={
                    <Radio
                      name="vatStatus"
                      checked={!vatStatus}
                      onChange={(e) => onVatStatus(false)}
                    />
                  }
                  label={t("global_not_calculate_vat")}
                />
              </Box>
            </PaperWrapper>
            <PaperWrapper props={{ growFull: 1 }}>
              <TitleForm props={{ text: t("global_price") }} />
              <BoxGapInput>
                <TextfieldComponent
                  name="price"
                  register={register}
                  placeholder={t("global_price_example")}
                  errors={errors}
                  iconEnd={<Money />}
                  disabled={statusPrice === false}
                  fullGrid={true}
                />
                <Box align="end">
                  <FormControlLabel
                    control={
                      <Switch
                        checked={statusPrice}
                        onChange={(e) => onStatusPrice(!statusPrice)}
                      />
                    }
                    label={t("global_price_status")}
                  />
                </Box>
              </BoxGapInput>
            </PaperWrapper>
            <PaperWrapper props={{ growFull: 1 }}>
              <TitleForm props={{ text: t("product_option_amount") }} />
              <Box p={2}>
                <FormControlLabel
                  control={
                    <Radio
                      name="limit"
                      checked={amountCount === true}
                      onChange={(e) => setAmountCount(true)}
                    />
                  }
                  label={t("product_option_limit")}
                />
                <FormControlLabel
                  control={
                    <Radio
                      name="unlimited"
                      checked={amountCount === false}
                      onChange={(e) => setAmountCount(false)}
                    />
                  }
                  label={t("product_option_un_limit")}
                />
              </Box>
            </PaperWrapper>

            <ConfirmButton disabled={isSubmitting} />
          </Box>
        </BoxGapInput>
      </PaperWrapper>
    </BoxWrapper>
  );
}

export default ProductOptionsForm;
