import React from "react";
import PaperWrapper from "../../../../components/ui/paper/paperWrapper";
import TitleForm from "../../../../components/ui/formComponent/titleForm";
import { Box, Typography } from "@mui/material";
import { t } from "i18next";
import { Compact } from "@uiw/react-color";

function SelfPickupThemeForm({ props }) {
  let { primaryColor, setPrimaryColor, secondaryColor, setSecondaryColor } =
    props;
  return (
    <PaperWrapper props={{ growFull: 1 }}>
      <TitleForm props={{ text: t("self_pickup_setup_theme_color") }} />
      <Box p={2}>
        <Typography>{t("global_primary_color")}</Typography>
        <Box align="center">
          <Compact
            style={{ marginLeft: 20 }}
            color={primaryColor}
            onChange={(color) => {
              setPrimaryColor(color.hex);
            }}
          />
        </Box>

        <Typography>{t("global_secondary_color")}</Typography>
        <Box align="center">
          <Compact
            style={{ marginLeft: 20 }}
            color={secondaryColor}
            onChange={(color) => {
              setSecondaryColor(color.hex);
            }}
          />
        </Box>
      </Box>
    </PaperWrapper>
  );
}

export default SelfPickupThemeForm;
