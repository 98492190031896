import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { getSelfPickupLineProvider } from "../../../service/selfPickUp/config/getSelfPickupLineProvider";
import { updateSelfPickupLineProvider } from "../../../service/selfPickUp/config/updateSelfPickupLineProvider";
import Swal from "sweetalert2";
import Loading from "../../../components/ui/loading";
import { Box, Grid } from "@mui/material";
import TextfieldComponent from "../../../components/ui/textfield/textfieldComponent";
import { t } from "i18next";
import ConfirmButton from "../../../components/ui/button/confirmButton";

function SelfPickupLineProvider() {
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);
  const [statusButton, setStatusButton] = useState(true);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const fetchData = async () => {
    let dataAPI = await getSelfPickupLineProvider();
    if (dataAPI?.data.line_provider) {
      let {
        client_id,
        client_secret,
        message_client_id,
        client_liff_id,
        message_client_secret,
        message_channel_access_token,
        message_alert_channel_access_token,
        message_alert_groupid,
        status,
      } = dataAPI.data.line_provider;
      setValue("clientId", client_id);
      setValue("clientLiffId", client_liff_id);
      setValue("clientSecret", client_secret);
      setValue("messageClientId", message_client_id);
      setValue("messageClientSecret", message_client_secret);
      setValue("messageChannelAccessToken", message_channel_access_token);
      setValue(
        "message_alert_channel_access_token",
        message_alert_channel_access_token
      );
      setValue("message_alert_groupid", message_alert_groupid);
      setData(dataAPI.data);
      setStatusButton(status);
    }
    setIsLoading(false);
  };
  const onConfirmForm = async (register) => {
    setIsSubmitted(true);
    let {
      clientId,
      clientLiffId,
      clientSecret,
      messageClientId,
      messageClientSecret,
      messageChannelAccessToken,
    } = register;
    let dataAPI = {
      client_id: clientId,
      client_secret: clientSecret,
      client_liff_id: clientLiffId,
      message_client_id: messageClientId,
      message_client_secret: messageClientSecret,
      message_channel_access_token: messageChannelAccessToken,
      status: statusButton,
    };
    let statusAPI = await updateSelfPickupLineProvider(dataAPI);
    if (statusAPI.message === "OK") {
      Swal.fire({
        icon: "success",
        title: "Success",
        showConfirmButton: false,
        timer: 1500,
      });
      fetchData();
      setIsSubmitted(false);
      setIsLoading(true);
    }
  };
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <Box>
          <form onSubmit={handleSubmit(onConfirmForm)}>
            <Grid container xs={12} p={2} className={"grid-paper-white"}>
              <TextfieldComponent
                title={t("line_oa_client_id")}
                name="clientId"
                register={register}
                placeholder={t("line_oa_client_id_example")}
                errors={errors}
                fullGrid={true}
              />
              <TextfieldComponent
                title={t("line_oa_client_liff_id")}
                name="clientLiffId"
                register={register}
                placeholder={t("line_oa_client_liff_id_example")}
                errors={errors}
                fullGrid={true}
              />
              {data ? null : (
                <TextfieldComponent
                  title={t("line_oa_client_secret")}
                  name="clientSecret"
                  register={register}
                  placeholder={t("line_oa_client_secret_example")}
                  errors={errors}
                  fullGrid={true}
                />
              )}

              <TextfieldComponent
                title={t("line_message_client_id")}
                name="messageClientId"
                register={register}
                placeholder={t("line_message_client_id_example")}
                errors={errors}
                fullGrid={true}
              />

              <TextfieldComponent
                title={t("line_message_client_secret")}
                name="messageClientSecret"
                register={register}
                placeholder={t("line_message_client_secret_example")}
                errors={errors}
                fullGrid={true}
              />
              <TextfieldComponent
                title={t("line_message_channel_access_token")}
                name="messageChannelAccessToken"
                register={register}
                placeholder={t("line_message_channel_access_token_example")}
                errors={errors}
                fullGrid={true}
              />
              <TextfieldComponent
                title={t("line_message_alert_channel_access_token")}
                name="messageChannelAccessToken"
                register={register}
                placeholder={t(
                  "line_message_alert_channel_access_token_example"
                )}
                errors={errors}
                fullGrid={true}
              />
              <TextfieldComponent
                title={t("line_message_alert_groupid")}
                name="messageChannelAccessToken"
                register={register}
                placeholder={t("line_message_alert_groupid_example")}
                errors={errors}
                fullGrid={true}
              />
              <Box sx={{ mt: 2, width: "100%" }}>
                <ConfirmButton disabled={isSubmitted} />
              </Box>
            </Grid>
          </form>
        </Box>
      )}
    </>
  );
}

export default SelfPickupLineProvider;
