import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import React, { useEffect } from "react";
import TextfieldComponent from "../../../../components/ui/textfield/textfieldComponent";
import { t } from "i18next";
import { BoxGapInput } from "../../../../components/ui/box/boxGapInput";
import ConfirmButton from "../../../../components/ui/button/confirmButton";
import { useSchema } from "../../../../components/hooks/use-schema";
import { useForm } from "react-hook-form";
import { branchTableInZoneSchema } from "../../../../components/validation/branchTableInZoneSchema";
import { Close, Delete } from "@mui/icons-material";
import { BoxImageZone } from "./boxImageZone";
import { notifyError } from "../../../../components/ui/popup/toastPopup";

function DialogTableForm({ props }) {
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    reset,
  } = useForm(useSchema(branchTableInZoneSchema));
  let {
    dialogFormTable,
    setTableZoneBox,
    setDialogFormTable,
    tableZoneBox,
    zoneSelect,
    onCreateTable,
    onRemoveTable,
  } = props;
  const onFormTable = async (register) => {
    let dataBox = [...tableZoneBox];
    if (dialogFormTable.mode === "edit") {
      let dataExist = dataBox.find(
        (item) => item.id === dialogFormTable.data.id
      );
      dataExist.name = register.TableName;
      dataExist.amount = register.TableAmount;
      setTableZoneBox(dataBox);
      reset();
      setDialogFormTable({
        status: false,
        data: null,
      });
    } else {
      let number = "";
      while (number.length < 4) {
        number += Math.floor(Math.random() * 9 + 1);
        number = number.substring(0, 4);
      }
      let checkNameDuplicate = dataBox.find(
        (item) => item.name === register.TableName
      );
      if (checkNameDuplicate) {
        setValue("TableName", "");
        notifyError({ title: "Duplicate table name" });
      } else {
        let itemNews = {
          id: number,
          template_zone_id: zoneSelect,
          name: register.TableName,
          image: dialogFormTable.data.image,
          amount: register.TableAmount,
          position_x: 100,
          position_y: 100,
          rotate: 0,
          status: true,
        };
        dataBox.push(itemNews);
        setDialogFormTable({
          status: false,
          data: null,
        });
        reset();
        onCreateTable(itemNews);
        setTableZoneBox(dataBox);
      }
    }
  };
  useEffect(() => {
    if (dialogFormTable.data && dialogFormTable.mode === "edit") {
      setValue("TableName", dialogFormTable.data.name);
      setValue("TableAmount", dialogFormTable.data.amount);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Dialog open={dialogFormTable.status} fullWidth maxWidth="sm">
      <DialogTitle>Form Table</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() =>
          setDialogFormTable({
            status: false,
            data: null,
            mode: null,
          })
        }
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Close />
      </IconButton>
      <DialogContent>
        <Box>
          {dialogFormTable.data ? (
            <>
              <form onSubmit={handleSubmit(onFormTable)}>
                <BoxGapInput>
                  <Box align="center">
                    {dialogFormTable.data.image.includes("data:image") ? (
                      <img
                        src={dialogFormTable.data.image}
                        alt="table"
                        width="30%"
                      />
                    ) : (
                      <BoxImageZone
                        image={dialogFormTable.data.image}
                        width="30%"
                        type="table"
                      />
                    )}
                  </Box>
                  <TextfieldComponent
                    title={t("table_name")}
                    name="TableName"
                    register={register}
                    placeholder={t("Table 1")}
                    errors={errors}
                    fullGrid={true}
                  />
                  <TextfieldComponent
                    title={t("table_amount")}
                    name="TableAmount"
                    register={register}
                    placeholder={t("1")}
                    errors={errors}
                    fullGrid={true}
                  />
                </BoxGapInput>
                <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                  <ConfirmButton />
                  {dialogFormTable.mode === "edit" ? (
                    <Button
                      variant="standard"
                      color="error"
                      startIcon={<Delete />}
                      sx={(theme) => ({
                        color: theme.palette.error.main,
                        borderRadius: 2,
                        fontSize: 16,
                      })}
                      fullWidth
                      onClick={() => onRemoveTable(dialogFormTable.data)}
                    >
                      Remove
                    </Button>
                  ) : null}
                </Box>
              </form>
            </>
          ) : null}
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default DialogTableForm;
