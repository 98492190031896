import React, { useState } from "react";
import { Box, Dialog, DialogContent } from "@mui/material";
import TitleForm from "../../../components/ui/formComponent/titleForm";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { notifySuccess } from "../../../components/ui/popup/toastPopup";
import { getAllBranch } from "../../../service/branchAPI";
import StatusForm from "../../../components/ui/formComponent/statusForm";
import ConfirmButton from "../../../components/ui/button/confirmButton";
import BreadcrumbsTitle from "../../../components/ui/breadcrumbsTitle";
import { useNavigate } from "react-router-dom";
import SettingTypeBranch from "../../../components/ui/addTypeTemplate/settingTypeBranch";
import { useSchema } from "../../../components/hooks/use-schema";
import { categorySchema } from "../../../components/validation/categorySchema";
import TextfieldComponent from "../../../components/ui/textfield/textfieldComponent";
import { validationSchemas } from "../../../components/validation/data";
import PaperWrapper from "../../../components/ui/paper/paperWrapper";
import { GridContainerSize } from "../../../components/utils/gridContainerSize";
import {
  storeMainCategory,
  addMainCategoryToTemplate,
  getAllMainCategoryTemplate,
} from "../../../service/mainCategory";
import { BoxWrapper } from "../../../components/ui/box/boxWrapper";
import { BoxFormTitleAction } from "../../../components/ui/box/boxFormTitleAction";
import TitleHeaderForm from "../../../components/ui/formComponent/titleHeaderForm";
import { BoxGapInput } from "../../../components/ui/box/boxGapInput";

function CategoryCreate() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm(
    useSchema(
      categorySchema({
        mainCategory: validationSchemas.name,
      })
    )
  );
  const { t } = useTranslation();
  const [statusButton, setStatusButton] = useState(true);
  const [idTemplate, setIdTemplate] = useState(null);
  const [showTemplate, setShowTemplate] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [allDataTemplate, setAllDataTemplate] = useState([]);

  const navigate = useNavigate();
  const onConfirmForm = async (register) => {
    setIsSubmitting(true);
    let dataAPI = {
      status: statusButton,
      main_category_lang: {
        name: register.mainCategory,
        language_code: "ALL",
      },
    };
    let statusAPI = await storeMainCategory(dataAPI);
    if (statusAPI.message === "OK") {
      notifySuccess({
        title: t("toast_successfully_added_information"),
      });
      if (statusButton) {
        setIdTemplate(statusAPI.data.id);
        fetchAllBranch(statusAPI.data.id);
        setShowTemplate(true);
      } else {
        navigate("/category");
      }
    }
    await new Promise((resolve) => setTimeout(resolve, 2000));
    setIsSubmitting(false);
  };
  const fetchAllBranch = async (id) => {
    let dataAPI = await getAllBranch();
    dataAPI = dataAPI.filter((item) => item.branch_lang !== null);
    await fetchSettingSelectTemplate(dataAPI, id);
  };
  const fetchSettingSelectTemplate = async (dataBranch, id) => {
    let dataAPI = await getAllMainCategoryTemplate(id);
    dataAPI = dataAPI[0].template_main_categories;
    let dataBox = [];
    dataBranch.forEach((item) => {
      let dataNews = dataAPI.filter(
        (itemTemplate) => itemTemplate.template_id === item.template_id
      );
      if (dataNews.length > 0) {
        dataBox.push({
          branch: item,
          data: dataNews[0],
          select: false,
        });
      } else {
        dataBox.push({
          branch: item,
          data: null,
          select: false,
        });
      }
    });
    await setAllDataTemplate(dataBox);
  };
  const onAddMainCategoryToTemplate = async () => {
    let branchArray = allDataTemplate.filter((item) => item.select === true);
    let dataSelect = [];
    branchArray.forEach((item) => {
      dataSelect.push(item.branch.id);
    });
    let dataAPI = {
      main_category_id: idTemplate,
      branches: dataSelect,
      priority: 1,
    };
    let dataStatus = await addMainCategoryToTemplate(dataAPI);
    if (dataStatus === "OK") {
      notifySuccess({
        title: t("toast_successfully_added_information"),
      });
      navigate("/category");
    }
  };
  return (
    <div align="left">
      <BreadcrumbsTitle
        props={{
          title: t("category_create"),
          dataLink: [
            { text: t("category"), link: "/category" },
            { text: t("category_create"), link: null },
          ],
        }}
      />
      <GridContainerSize>
        <Box p={2}>
          <form onSubmit={handleSubmit(onConfirmForm)}>
            <BoxWrapper>
              <PaperWrapper props={{ growFull: 1 }}>
                <BoxFormTitleAction>
                  <TitleHeaderForm props={{ text: t("category") }} />
                  <StatusForm
                    statusButton={statusButton}
                    setStatusButton={setStatusButton}
                  />
                </BoxFormTitleAction>
                <BoxGapInput>
                  <PaperWrapper props={{ growFull: 1 }}>
                    <TitleForm props={{ text: t("category") }} />
                    <Box p={2} className="boxWrapper-container">
                      <TextfieldComponent
                        title={t("category_name")}
                        name="mainCategory"
                        register={register}
                        placeholder={t("category_example")}
                        errors={errors}
                        fullGrid={true}
                      />
                      {/* <Box>
                  <TitleTextfield text={t("category_sub")} />
                  */}
                    </Box>
                  </PaperWrapper>
                  <ConfirmButton disabled={isSubmitting} />
                </BoxGapInput>
              </PaperWrapper>
            </BoxWrapper>
          </form>
        </Box>
      </GridContainerSize>
      <Dialog open={showTemplate} maxWidth="md" fullWidth>
        <DialogContent>
          <SettingTypeBranch
            statusData={true}
            cancelPath={"/category"}
            title={t("category_branch")}
            onAddTypeToTemplate={onAddMainCategoryToTemplate}
            allDataTemplate={allDataTemplate}
            setAllDataTemplate={setAllDataTemplate}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default CategoryCreate;
