import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { notifySuccess } from "../../../components/ui/popup/toastPopup.js";
import { useForm } from "react-hook-form";
import BreadcrumbsTitle from "../../../components/ui/breadcrumbsTitle.js";
import { useNavigate } from "react-router-dom";
import { useSchema } from "../../../components/hooks/use-schema.js";
import OnlineAdminForm from "./selfPickupAdminForm.js";
import { getSelfPickupAdmin } from "../../../service/selfPickUp/selfPickupAdmin/getSelfPickupAdmin.js.js";
import { uploadImage } from "../../../service/upload/uploadImage.js";
import { updateSelfPickupAdmin } from "../../../service/selfPickUp/selfPickupAdmin/updateSelfPickupAdmin.js";
import { selfPickupAdminSchema } from "../../../components/validation/selfPickupAdminSchema.js";
import Loading from "../../../components/ui/loading.js";

function SelfPickupAdminDetail() {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm(useSchema(selfPickupAdminSchema));
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [statusButton, setStatusButton] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [image, setImage] = useState(false);
  const paramsId =
    window.location.pathname.split("/")[
      window.location.pathname.split("/").length - 1
    ];
  const navigate = useNavigate();

  const fetchData = async () => {
    let dataAPI = await getSelfPickupAdmin(paramsId);
    let {
      profile_img,
      self_pickup_admin_lang,
      email,
      nat_id,
      pin,
      status,
      tel,
      position,
    } = dataAPI;
    setValue("imageRef", profile_img);
    setImage(profile_img);
    setValue(
      "firstName",
      self_pickup_admin_lang ? self_pickup_admin_lang.firstname : null
    );
    setValue(
      "lastName",
      self_pickup_admin_lang ? self_pickup_admin_lang.lastname : null
    );
    setValue("tel", tel);
    setValue("email", email);
    setValue("positionUser", position);
    setValue("idCardUser", nat_id);
    setValue("pin", pin);
    setStatusButton(status);
    setIsLoading(false);
  };
  const onConfirmForm = async (register) => {
    setIsSubmitting(true);
    let newImage = "";
    if (typeof image === "object") {
      newImage = await uploadImage(image);
    } else {
      newImage = image;
    }
    let dataAPI = {
      id: String(paramsId),
      position: register.positionUser,
      pin: register.pin,
      profile_img: newImage,
      nat_id: register.idCardUser,
      tel: register.tel,
      email: register.email,
      status: statusButton,
      self_pickup_admin_lang: {
        firstname: register.firstName,
        lastname: register.lastName,
        language_code: String(localStorage.getItem("language_form")),
      },
    };
    let statusAPI = await updateSelfPickupAdmin(dataAPI);
    if (statusAPI === "OK") {
      notifySuccess({
        title: t("notification_successfully_edited_information"),
      });
      navigate("/self-pickup-admin");
    }
    await new Promise((resolve) => setTimeout(resolve, 2000));
    setIsSubmitting(false);
  };
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div align="left">
      <BreadcrumbsTitle
        props={{
          title: t("self_pickup_admin"),
          dataLink: [
            {
              text: t("self_pickup_admin"),
              link: "/self-pickup-admin",
            },
            { text: t("self_pickup_admin_info"), link: null },
          ],
        }}
      />
      {isLoading ? (
        <Loading />
      ) : (
        <OnlineAdminForm
          props={{
            handleSubmit,
            onConfirmForm,
            register,
            errors,
            statusButton,
            setStatusButton,
            isSubmitting,
            image,
            setImage,
          }}
        />
      )}
    </div>
  );
}

export default SelfPickupAdminDetail;
