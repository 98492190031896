import React, { useState, useEffect } from "react";

import { t } from "i18next";

import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import { getSelfPickupBranchBankAccount } from "../../../../service/selfPickUp/config/getSelfPickupBranchBankAccount";
import DataMaterialTable from "../../../../components/ui/dataTable/dataMaterialTable";
import { getAllBankAccount } from "../../../../service/selfPickUp/bankaccount/getAllBankAccount";
import { updateBankAccount } from "../../../../service/selfPickUp/bankaccount/updateBankAccount";
import { notifySuccess } from "../../../../components/ui/popup/toastPopup";
import StatusTable from "../../../../components/chip/statusTable";
import { TextTable } from "../../../../components/ui/textfield/textfieldCustom";

function SelfPickupPaymentBank({ props }) {
  let { paramsId } = props;

  const [rows, setRows] = useState([]);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [dataAllBank, setDataAllBank] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [columns, setColumns] = useState([]);

  const fetchData = async () => {
    let dataColumns = [
      {
        accessorKey: "self_pickup_bank_account.account_name",
        size: 100,
        header: t("Account Name"),
      },
      {
        accessorKey: "self_pickup_bank_account.account_no",
        size: 100,
        header: t("Account NO"),
      },
      {
        accessorKey: "self_pickup_bank_account.bank_name",
        size: 100,
        header: t("Bank Name"),
      },
      {
        accessorKey: "self_pickup_bank_account.branch_name",
        size: 100,
        header: t("Branch Name"),
      },
      {
        accessorKey: "unit_counter_lang.updated_at",
        size: 100,
        header: t("global_updated_at"),
        Cell: ({ cell }) => (
          <TextTable props={{ text: cell.getValue() }} />
        ),
      },
      {
        accessorKey: "status",
        enableSorting: false,
        size: 20,
        header: t("global_status"),
        Cell: ({ row }) => (
          <StatusTable props={{ status: row.original.status }} />
        ),
      },
    ];

    let dataAPI = await getSelfPickupBranchBankAccount(paramsId);
    if (dataAPI) {
      dataAPI.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));
      setRows(dataAPI);
    }
    let dataBankAll = await getAllBankAccount();
    let dataBoxBank = [];
    dataBankAll.forEach((item) => {
      if (
        dataAPI.find(
          (itemTable) => itemTable.self_pickup_bank_account.id !== item.id
        )
      ) {
        item.checked = false;
        dataBoxBank.push(item);
      }
    });
    setDataAllBank(dataBoxBank);
    setColumns(dataColumns);
    setIsLoadingData(false);
  };
  const onAddDialogBankAccount = async () => {
    setDialogOpen(true);
  };
  const onConfirmAddBankAccount = async () => {
    let dataBox = [];
    dataAllBank.forEach((item) => {
      if (item.checked) {
        dataBox.push(item.id);
      }
    });
    let dataAPI = {
      branch_id: String(paramsId),
      bank_accounts: dataBox,
    };
    let statusAPI = await updateBankAccount(dataAPI);
    if (statusAPI === "OK") {
      notifySuccess({
        title: t("toast_successfully_added_information"),
      });
      setDialogOpen(false);
    }
  };
  const onChangeBank = async (e, index) => {
    let dataNews = [...dataAllBank];
    dataNews[index].checked = e.target.checked;
    setDataAllBank(dataNews);
  };
  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <Box>
      <DataMaterialTable
        statusViewMode={true}
        rows={rows}
        isLoadingData={isLoadingData}
        columns={columns}
        createButton={
          dataAllBank.length > 0 ? t("self_pickup_admin_bank_account") : null
        }
        onCreateFunction={
          dataAllBank.length > 0 ? () => onAddDialogBankAccount() : null
        }
      />
      {dialogOpen ? (
        <Dialog open={dialogOpen}>
          <DialogTitle>Insert bank account</DialogTitle>
          <DialogContent>
            <FormGroup>
              {dataAllBank.map((item, index) => (
                <>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(e) => onChangeBank(e, index)}
                        checked={item.checked}
                      />
                    }
                    label={item.bank_name}
                  />
                </>
              ))}
            </FormGroup>

            <Button
              variant="contained"
              onClick={() => onConfirmAddBankAccount()}
              fullWidth
              sx={{ borderRadius: 2 }}
            >
              Confirm
            </Button>
          </DialogContent>
        </Dialog>
      ) : null}
    </Box>
  );
}

export default SelfPickupPaymentBank;
