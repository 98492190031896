import React, { useState, useEffect } from "react";

import { ActionDetail } from "../../../components/ui/dataTable/components/actionDetail";
import { Box } from "@mui/material";
import { MomentConvert } from "../../../components/ui/moment/momentConvert";
import DataMaterialTable from "../../../components/ui/dataTable/dataMaterialTable";
import { useTranslation } from "react-i18next";
import { getAllVoidReceipt } from "../../../service/voidReceipt";

import BreadcrumbsTitle from "../../../components/ui/breadcrumbsTitle";
import StatusTable from "../../../components/chip/statusTable";
import { TextTable } from "../../../components/ui/textfield/textfieldCustom";
import dayjs from "dayjs";
function VoidReceipt() {
  const [rows, setRows] = useState([]);
  const { t } = useTranslation();
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [columns, setColumns] = useState([]);
  const [exportColumnName, setExportColumnName] = useState(null);

  const fetchData = async () => {
    let dataColumns = [
      {
        accessorKey: "name",
        header: t("global_name"),
        Cell: ({ cell }) => <TextTable props={{ text: cell.getValue() }} />,
      },
      {
        accessorKey: "update",
        header: t("global_updated_at"),
        Cell: ({ cell }) => <TextTable props={{ text: cell.getValue() }} />,
      },
      {
        accessorKey: "status",
        enableSorting: false,
        header: t("global_status"),
        Cell: ({ row }) => (
          <StatusTable props={{ status: row.original.status }} />
        ),
      },
      {
        accessorKey: "action",
        enableSorting: false,
        header: "Action",
        sortable: false,
        Cell: ({ cell, row }) => (
          <Box sx={{ display: "flex", gap: 2 }}>
            <ActionDetail params={row} detailPath={"/cancel-receipt/detail/"} />
          </Box>
        ),
      },
    ];

    let dataAPI = await getAllVoidReceipt();
    if (dataAPI) {
      let dataBox = dataAPI
        .sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at))
        .map((item) => ({
          id: item.id,
          name: item.void_receipt_lang.name,
          update: MomentConvert(item.updated_at),
          status: item.status,
        }));
      setRows(dataBox);
      //===== Start set header name export csv
      let headers = [];
      dataColumns.forEach((item) => {
        if (item.header !== "Image" && item.header !== "Action") {
          headers.push(item.header);
        }
      });
      const csvStringArray = [
        headers,
        ...dataBox.map((item) => [item.name, item.update, item.status]),
      ]
        .map((row) => row.join(","))
        .join("\n");

      setExportColumnName(csvStringArray);
      //===== End set header name export csv
    }
    setColumns(dataColumns);
    setIsLoadingData(false);
  };

  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <Box>
      <BreadcrumbsTitle
        props={{
          title: t("void_receipt"),
        }}
      />
      <DataMaterialTable
        statusViewMode={true}
        rows={rows}
        exportColumnName={exportColumnName}
        fileName={t("void_receipt") + ` ${dayjs().format("DD/MM/YYYY")}`}
        isLoadingData={isLoadingData}
        columns={columns}
        detailPath={"/cancel-receipt/detail/"}
        createButton={t("void_receipt")}
      />
    </Box>
  );
}

export default VoidReceipt;
