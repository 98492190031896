import React from "react";
import {
  Avatar,
  Box,
  FormHelperText,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import ProductProductPerformance from "./productProductPerformance";
import CategoryProductPerformance from "./categoryProductPerformance";
import { HelpOutline } from "@mui/icons-material";
import { t } from "i18next";
import productPerformanceIcon from "../../../../../assets/svg/dashboard/productPerformance.svg";

function ProductPerformance({ props }) {
  let { dataProductPerformance } = props;
  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          mb: 2,
          gap: 2,
        }}
      >
        <Avatar
          src={productPerformanceIcon}
          sx={{ borderRadius: 0, width: 32, height: 32 }}
        />
        <Box>
          <Typography
            variant="h3"
            sx={{
              fontWeight: "500",
              lineHeight: 0.7,
            }}
          >
            Product Performance
          </Typography>
          <FormHelperText>
            Table show top 10 product performance and category performance
          </FormHelperText>
        </Box>
        <Tooltip
          title={
            <>
              <Typography>
                {t("helpPercentChangeOne")}
                <br />
                {t("helpPercentChangeTwo")}
              </Typography>
            </>
          }
          placement="right"
        >
          <IconButton>
            <HelpOutline />
          </IconButton>
        </Tooltip>
      </Box>
      <Grid container justifyContent={"space-between"}>
        <Grid xs={12} md={5.9}>
          <Box
            className="grid-paper-white"
            sx={{ p: 2, boxSizing: "border-box", height: "100%" }}
          >
            <CategoryProductPerformance
              props={{ dataPlot: dataProductPerformance.data1 }}
            />
          </Box>
        </Grid>
        <Grid xs={12} md={5.9}>
          <Box
            className="grid-paper-white"
            sx={{ p: 2, boxSizing: "border-box", height: "100%" }}
          >
            <ProductProductPerformance
              props={{ dataPlot: dataProductPerformance.data2 }}
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default ProductPerformance;
