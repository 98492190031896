import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bubble } from "react-chartjs-2";
import "chartjs-plugin-datalabels";
import ChartDataLabels from "chartjs-plugin-datalabels";
import MaterialReactTable from "material-react-table";
import { TextTable } from "../../../../../components/ui/textfield/textfieldCustom";
import { t } from "i18next";
import { Typography } from "@mui/material";
import { NumberDecimal } from "../../../../../components/utils/numberDecimal";
import annotationPlugin from "chartjs-plugin-annotation";
import colorDashBoard from "../../colorDashboard.json";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  annotationPlugin
);

function ProductProductPerformance({ props }) {
  let { dataPlot } = props;
  let colorBg = colorDashBoard;

  const datasets = dataPlot.chart.map((item, index) => ({
    label: item.name,
    data: [
      {
        x: item.x_total_receipt,
        y: item.y_total_sale,
        r: item.r_receipt_frequency,
      },
    ],
    sequence: item.r_receipt_frequency,
    backgroundColor: colorBg[index],
  }));

  const minRadius = 5; // Minimum bubble size in pixels
  const maxRadius = 50; // Maximum bubble size in pixels
  const minValue = Math.min(...datasets.map((d) => d.sequence));
  const maxValue = Math.max(...datasets.map((d) => d.sequence));
  const scaleRadius = (value) => {
    return (
      ((value - minValue) / (maxValue - minValue)) * (maxRadius - minRadius) +
      minRadius
    );
  };

  const resizeBubbles = (data) => {
    return data.map((dataset) => ({
      ...dataset,
      data: dataset.data.map((point) => ({
        ...point,
        r: scaleRadius(point.r),
      })),
    }));
  };
  const resizedData = resizeBubbles(datasets);
  // Calculate the 50th percentile (median) for X and Y
  const xValues = resizedData
    .map((point) => point.data[0].x)
    .sort((a, b) => a - b);
  const yValues = resizedData
    .map((point) => point.data[0].y)
    .sort((a, b) => a - b);
  const medianX = xValues[Math.floor(xValues.length / 2)];
  const medianY = yValues[Math.floor(yValues.length / 2)];

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      x: {
        title: {
          display: true,
          text: "Receipt Number",
        },
        min: 0,
        // max: xMax + xPadding,
      },
      y: {
        title: {
          display: true,
          text: "Total",
        },
        min: 0,
        // max: yMax + yPadding,
      },
    },
    plugins: {
      legend: {
        display: true,
        position: "bottom",
      },
      // scales: {
      //   x: {
      //     title: {
      //       display: true,
      //       text: "Day of week",
      //     },
      //   },
      //   y: { title: { display: true, text: "Total Sales" } },
      // },
      datalabels: {
        display: false,
        color: "white",
        // formatter: (value) => (value ? `${value.toFixed(2)}%` : null), // Format percentage to two decimal places
      },
      title: {
        display: false,
        text: "Top 10 : Product",
      },
      tooltip: {
        callbacks: {
          title: function (context) {
            return context[0].dataset.label;
          },

          label: function (context) {
            return `Total Sale: ${context.raw.y.toLocaleString()} THB`;
          },
          afterLabel: function (context) {
            return `Total Receipt : ${context.raw.x.toLocaleString()}`;
          },
        },
      },
      annotation: {
        annotations: {
          xMedianLine: {
            type: "line",
            xMin: medianX,
            xMax: medianX,
            // yMin: 0,
            // yMax: yMax + yPadding,
            borderColor: "#00000060",
            borderWidth: 2,
            borderDash: [5, 5], // Dotted line: 5px dash, 5px gap
            label: {
              content: `X Median: ${medianX}`,
              enabled: true,
              position: "top",
            },
          },
          yMedianLine: {
            type: "line",
            yMin: medianY,
            yMax: medianY,
            // xMin: 0,
            // xMax: xMax + xPadding,
            borderColor: "#00000060",
            borderWidth: 2,
            borderDash: [5, 5], // Dotted line: 5px dash, 5px gap
            label: {
              content: `Y Median: ${medianY}`,
              enabled: true,
              position: "right",
            },
          },
        },
      },
    },
  };

  const labels = [""];
  const sortedData = resizedData
    .map((datasetFind) => ({
      ...datasetFind,
      data: datasetFind.data.sort((a, b) => b.r - a.r), // Sort by 'r' in descending order
    }))
    .reverse(); // Reverse the order of datasets so that largest ones are rendered last

  const data = {
    labels,
    datasets: sortedData,
  };

  let dataColumns = [
    {
      accessorKey: "name",
      size: 100,
      header: t("Name"),
      Cell: ({ cell }) => <TextTable props={{ text: cell.getValue() }} />,
    },
    {
      accessorKey: "totalSale",
      size: 100,
      header: t("Total Sale"),
      Cell: ({ cell }) => (
        <TextTable
          props={{
            text: NumberDecimal({ number: cell.getValue(), decimal: 2 }),
          }}
        />
      ),
    },
    {
      accessorKey: "percentChangePerTotal",
      size: 100,
      header: t("%Change"),
      Cell: ({ cell }) => (
        <TextTable
          props={{
            text: cell.getValue(),
            colorSetting:
              Number(cell.getValue().replace("%", "")) === 0
                ? "#FFB300"
                : Number(cell.getValue().replace("%", "")) > 0
                ? "#45cb85"
                : "#E53935E5",
          }}
        />
      ),
    },
    {
      accessorKey: "valuePerReceipt",
      size: 100,
      header: t("Total Receipt"),
      Cell: ({ cell }) => (
        <TextTable
          props={{
            text: NumberDecimal({ number: cell.getValue(), decimal: 2 }),
          }}
        />
      ),
    },
    {
      accessorKey: "percentChangePerReceipt",
      size: 100,
      header: t("%Change"),
      Cell: ({ cell }) => (
        <TextTable
          props={{
            text: cell.getValue(),
            colorSetting:
              Number(cell.getValue().replace("%", "")) === 0
                ? "#FFB300"
                : Number(cell.getValue().replace("%", "")) > 0
                ? "#45cb85"
                : "#E53935E5",
          }}
        />
      ),
    },
  ];
  const rows = dataPlot.table.map((item) => ({
    name: item.name,
    totalSale: item.total_sale,
    percentChangePerTotal: item.pct_chg_total_sale,
    valuePerReceipt: item.total_receipt,
    percentChangePerReceipt: item.pct_chg_total_receipt,
  }));
  return (
    <div>
      <Typography sx={{ textAlign: "center" }}>Top 10 : Product</Typography>

      {/* <Box sx={{ height: "280px", display: "grid", placeItems: "center" }}> */}
      <div style={{ height: "350px", width: "100%" }}>
        <Bubble options={options} plugins={[ChartDataLabels]} data={data} />
      </div>
      {/* </Box> */}
      <MaterialReactTable
        columns={dataColumns}
        data={rows}
        enableStickyHeader
        // defaultColumn={{
        //   maxSize: 400,
        //   minSize: 80,
        //   size: 100,
        // }}
        options={{
          search: false,
        }}
        muiTableContainerProps={{
          sx: {
            maxHeight: "256px",
            height: "256px",
          },
        }}
        enableTopToolbar={false}
        enableColumnResizing={true}
        enableColumnActions={false}
        enableColumnFilters={false}
        // enableSorting={false}
        muiTableBodyRowProps={{ hover: false }}
        enableToolbarInternalActions={false}
        muiTableProps={{
          sx: {
            tableLayout: "fixed",
          },
        }}
        muiTablePaginationProps={{
          rowsPerPageOptions: [25, 50, 100],
          showFirstButton: true,
          showLastButton: true,
        }}
      />
    </div>
  );
}

export default ProductProductPerformance;
