import { FormControlLabel, Switch, Typography } from "@mui/material";
import { useState } from "react";

export const ActionBtn = (props) => {
  const { label, status, onUpdateStatus, row, index, disabled } = props;
  const [disableBtn, setDisableBtn] = useState(false);
  const handleChange = (row, e) => {
    setDisableBtn(true);
    onUpdateStatus(row, e, index);
    setTimeout(() => {
      setDisableBtn(false);
    }, 1500);
  };
  return (
    <>
      <FormControlLabel
        label={
          label ? (
            <Typography
              sx={(theme) => ({ color: theme.palette.primaryText.main })}
            >
              {label}
            </Typography>
          ) : (
            ""
          )
        }
        control={
          <Switch
            onChange={(e) => handleChange(row, e, index)}
            checked={status}
            disabled={disableBtn || disabled}
          />
        }
      ></FormControlLabel>
    </>
  );
};
