import {
  Avatar,
  Box,
  Button,
  FormHelperText,
  Grid,
  Skeleton,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import BreadcrumbsTitle from "../../../components/ui/breadcrumbsTitle";
import { t } from "i18next";
// import { getStat } from "../../../service/dashboard/getStat";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useForm } from "react-hook-form";
import { getAllBranch } from "../../../service/branchAPI";
import { DatePicker, Space } from "antd";
import dayjs from "dayjs";
import { getAllReceiptType } from "../../../service/orderType";
// import { getStatOneDay } from "../../../service/dashboard/getStatOneDay";
import SelectCheckMarkComponent from "../../../components/ui/select/selectCheckMarkComponent";
import BranchPerformance from "./chartComponent/branchPerformance";
import WeeklyPerformance from "./chartComponent/weeklyPerformance";
import TotalSalesVsTotalReceipts from "./chartComponent/totalSalesVsTotalReceipts";
import MovementBranchesPerformance from "./chartComponent/movementBranchesPerformance";
import PaymentMethod from "./chartComponent/paymentMethodType/paymentMethod";
import CouponPerformance from "./chartComponent/couponPerformance";
import ProductPerformance from "./chartComponent/productPerformance/productPerformance";
import ProductComboSuggestions from "./chartComponent/productComboSuggestions";
import TotalSale from "./chartComponent/totalSaleInsignt/totalSale";
import { getInsightDashboard } from "../../../service/dashboard/getDashboardInsight";
import basicChart from "../../../assets/svg/dashboard/basicChart.svg";
import basicBranch from "../../../assets/svg/dashboard/basicBranch.svg";
import colorDashboard from "./colorDashboard.json";
// import dataMockCoupon from "./dataMock.json";
function DashboardInsight() {
  const { RangePicker } = DatePicker;
  const {
    setValue,
    clearErrors,
    control,
    formState: { errors },
  } = useForm();
  const dateFormat = "DD/MM/YYYY";
  const [tabSelect, setTabSelect] = useState("total_sale");
  const [statusLine, setStatusLine] = useState("totalBranch");
  const [isLoadingAll, setIsLoadingAll] = useState({
    all: true,
    summary: true,
    weeklyPerformance: true,
    branchPerformance: true,
    totalSalesVsTotalReceipts: true,
    movementBranchesPerformance: true,
    paymentMethod: true,
    couponPerformance: true,
    productPerformance: true,
    productCombo: true,
  });

  const [branchAll, setBranchAll] = useState([]);
  const [branchSelect, setBranchSelect] = useState([]);
  const [branchSelectName, setBranchSelectName] = useState([]);
  const [orderAll, setOrderAll] = useState([]);
  const [orderSelect, setOrderSelect] = useState([]);

  const [dataTotalSale, setDataTotalSale] = useState(null);
  const [dataTotalSaleLine, setDataTotalSaleLine] = useState([]);
  const [dataWeekly, setDataWeekly] = useState(null);
  const [dataBranchPerformance, setDataBranchPerformance] = useState(null);
  const [dataPaymentMethod, setDataPaymentMethod] = useState(null);
  const [dataCouponPerformance, setDataCouponPerformance] = useState(null);
  const [dataProductPerformance, setDataProductPerformance] = useState(null);
  const [dataProductCombo, setDataProductCombo] = useState(null);
  const [promotionAll, setPromotionAll] = useState([]);

  const [startDate, setStartDate] = useState(dayjs());
  const [endDate, setEndDate] = useState(dayjs());

  // const [startDate, setStartDate] = useState(dayjs().startOf("day"));
  // const [endDate, setEndDate] = useState(dayjs().endOf("day"));
  const [isLoadingUpdate, setIsLoadingUpdate] = useState(true);

  const handleChange = (event, newValue) => {
    setTabSelect(newValue);
  };
  const fetchData = async () => {
    let orderAll = await getOrderAPI();
    let defaultBranch = await getAllBranchAPI();
    let dataAPIStat = {
      start: dayjs(startDate).startOf("day").format("YYYYMMDDHHmmss"),
      end: dayjs(endDate).endOf("day").format("YYYYMMDDHHmmss"),
      types: orderAll.map((order) => order.name),
      branches: defaultBranch.map((item) => item.id),
    };

    await getSaleInsight01(dataAPIStat);
    await getSaleInsight02(dataAPIStat);
    await onWeeklyPerformance(dataAPIStat);
    await onBranchPerformance(dataAPIStat);
    await onPaymentMethod(dataAPIStat);
    await onCouponPerformance(dataAPIStat);
    await onProductPerformance(dataAPIStat);
    await onProductCombo(dataAPIStat);

    setBranchSelectName(defaultBranch.map((item) => item.name));

    setBranchSelect(defaultBranch.map((item) => item.id));
    setValue(
      "branchSelect",
      defaultBranch.map((item) => item.name)
    );

    setOrderSelect(orderAll.map((order) => order.name));
    setValue(
      "orderSelect",
      orderAll.map((order) => order.name)
    );
    setIsLoadingUpdate(false);
  };
  const handleChangeDate = (newValue) => {
    setStartDate(dayjs(newValue[0], dateFormat));
    setEndDate(dayjs(newValue[1], dateFormat));
  };

  const getAllBranchAPI = async () => {
    let statusBranch = await getAllBranch();
    let statusBranchBox = [];
    statusBranch.forEach((item) => {
      statusBranchBox.push({ id: item.id, name: item.branch_lang.name });
    });
    setBranchAll(statusBranchBox);
    return statusBranchBox;
  };
  const getOrderAPI = async () => {
    //Order
    let statusOrder = await getAllReceiptType();
    let statusOrderBox = [];
    statusOrder.forEach((item) => {
      statusOrderBox.push({ id: item.id, name: item.name });
    });
    setOrderAll(statusOrderBox);
    return statusOrderBox;
  };
  const maxDate = new Date();
  const disabledDate = (current) => {
    // Disable dates after the maxDate
    return current && current > maxDate;
  };
  const onConfirm = async () => {
    await setIsLoadingAll({
      all: true,
      summary: true,
      weeklyPerformance: true,
      branchPerformance: true,
      totalSalesVsTotalReceipts: true,
      movementBranchesPerformance: true,
      paymentMethod: true,
      couponPerformance: true,
      productPerformance: true,
      productCombo: true,
    });
    await setIsLoadingUpdate(true);

    let dataAPIStat = {
      start: dayjs(startDate).startOf("day").format("YYYYMMDDHHmmss"),
      end: dayjs(endDate).endOf("day").format("YYYYMMDDHHmmss"),
      types: orderSelect,
      branches: branchSelect,
    };
    await getSaleInsight01(dataAPIStat);
    await getSaleInsight02(dataAPIStat);
    await onWeeklyPerformance(dataAPIStat);
    await onBranchPerformance(dataAPIStat);
    await onPaymentMethod(dataAPIStat);
    await onCouponPerformance(dataAPIStat);
    await onProductPerformance(dataAPIStat);
    await onProductCombo(dataAPIStat);

    await setIsLoadingUpdate(false);
  };

  const getSaleInsight01 = async (dataAPIStat) => {
    try {
      let dataAPISale01 = await getInsightDashboard(
        dataAPIStat,
        "sale_insight_N01"
      );
      // let dataAPISale01 = dataMockCoupon.dataSl01;
      await setDataTotalSale(dataAPISale01.N01);
    } catch (e) {
      console.log(e);
    }
  };
  const getSaleInsight02 = async (dataAPIStat) => {
    try {
      let dataAPISaleLine = await getInsightDashboard(
        dataAPIStat,
        "sale_insight_N02"
      );
      // let dataAPISaleLine = dataMockCoupon.dataSl02;
      await setDataTotalSaleLine(dataAPISaleLine);
    } catch (e) {
      console.log(e);
    }
    setIsLoadingAll((prevState) => ({
      ...prevState,
      summary: false,
    }));
  };
  const onWeeklyPerformance = async (dataAPIStat) => {
    try {
      let dataAPISale03 = await getInsightDashboard(
        dataAPIStat,
        "sale_insight_N03"
      );
      // let dataAPISale03 = dataMockCoupon.dataSl03;
      await setDataWeekly(dataAPISale03);
    } catch (e) {
      console.log(e);
    }
    setIsLoadingAll((prevState) => ({
      ...prevState,
      weeklyPerformance: false,
    }));
  };
  const onBranchPerformance = async (dataAPIStat) => {
    try {
      let dataAPISale04 = await getInsightDashboard(
        dataAPIStat,
        "sale_insight_N04"
      );
      // let dataAPISale04 = dataMockCoupon.dataSl04;
      await setDataBranchPerformance(dataAPISale04);
    } catch (e) {
      console.log(e);
    }
    setIsLoadingAll((prevState) => ({
      ...prevState,
      branchPerformance: false,
      totalSalesVsTotalReceipts: false,
      movementBranchesPerformance: false,
    }));
  };
  // const onMovementBranchesPerformance = () => {};
  const onPaymentMethod = async (dataAPIStat) => {
    let dataAPISale05 = await getInsightDashboard(
      dataAPIStat,
      "sale_insight_N05"
    );
    await setDataPaymentMethod({ data: dataAPISale05.SI_N05 });
    setIsLoadingAll((prevState) => ({
      ...prevState,
      paymentMethod: false,
    }));
  };
  const onCouponPerformance = async (dataAPIStat) => {
    let dataAPISale06 = await getInsightDashboard(
      dataAPIStat,
      "sale_insight_N06"
    );
    // let dataAPISale06 = dataMockCoupon.dataSl06_Raw;
    let totalPieColor = colorDashboard;
    let dataPromotionColor = [];
    let dataPromotionCut = [];
    dataAPISale06.current.forEach((item, index) => {
      if (
        dataPromotionCut.find(
          (itemFind) => itemFind.promotion_id === item.promotion_id
        ) === undefined &&
        item.promotion_id !== null
      ) {
        dataPromotionCut.push({
          promotion_id: item.promotion_id,
        });
      }
    });
    dataPromotionColor.push({
      promotion_id: null,
      color: "#cccccc",
    });
    dataPromotionCut.forEach((item, index) => {
      dataPromotionColor.push({
        promotion_id: item.promotion_id,
        color: totalPieColor[index],
      });
    });
    setPromotionAll(dataPromotionColor);
    setDataCouponPerformance(dataAPISale06);
    setIsLoadingAll((prevState) => ({
      ...prevState,
      couponPerformance: false,
    }));
  };

  const onProductPerformance = async (dataAPIStat) => {
    let dataAPISale071 = await getInsightDashboard(
      dataAPIStat,
      "sale_insight_N07_1"
    );
    let dataAPISale072 = await getInsightDashboard(
      dataAPIStat,
      "sale_insight_N07_2"
    );

    let dataChart1 = dataAPISale071.SI_N07_1_1.data
      .sort((a, b) => b.y_total_sale - a.y_total_sale)
      .splice(0, 10);
    let dataTable1 = dataAPISale071.SI_N07_1_2.data
      .sort((a, b) => b.total_sale - a.total_sale)
      .splice(0, 10);
    let dataChart2 = dataAPISale072.SI_N07_2_1.data
      .sort((a, b) => b.y_total_sale - a.y_total_sale)
      .splice(0, 10);
    let dataTable2 = dataAPISale072.SI_N07_2_2.data
      .sort((a, b) => b.total_sale - a.total_sale)
      .splice(0, 10);
    setDataProductPerformance({
      data1: {
        chart: dataChart1,
        table: dataTable1,
      },
      data2: {
        chart: dataChart2,
        table: dataTable2,
      },
    });
    setIsLoadingAll((prevState) => ({
      ...prevState,
      productPerformance: false,
    }));
  };
  const onProductCombo = async (dataAPIStat) => {
    let dataAPISale081 = await getInsightDashboard(
      dataAPIStat,
      "sale_insight_N08_1"
    );
    let dataAPISale082 = await getInsightDashboard(
      dataAPIStat,
      "sale_insight_N08_2"
    );
    // let dataAPISale081 = dataMockCoupon.dataSl08_1;
    // let dataAPISale082 = dataMockCoupon.dataSl08_2;

    setDataProductCombo({
      data1: dataAPISale081,
      data2: dataAPISale082,
    });
    setIsLoadingAll((prevState) => ({
      ...prevState,
      productCombo: false,
    }));
  };
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <BreadcrumbsTitle
        props={{
          title: t("side_bar_report_summary"),
        }}
      />

      <Grid
        container
        justifyContent={"space-between"}
        sx={(theme) => ({
          boxSizing: "border-box",
          p: 2,
          color: theme.palette.primaryText.main,
        })}
        spacing={2}
      >
        <Grid item xs={12}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Box
              sx={(theme) => ({
                display: "flex",
                alignItems: "center",
                width: "100%",
                gap: 2,
              })}
            >
              <Grid container justifyContent={"space-between"} spacing={2}>
                <Grid item xs={12} md={3}>
                  <Box
                    sx={(theme) => ({
                      boxSizing: "border-box",
                      // p: 1,
                      // borderRadius: 2,
                      // border: `3px solid ${theme.palette.primary.main}`,
                    })}
                  >
                    <SelectCheckMarkComponent
                      dataEmpty={t("branch_select")}
                      errors={errors}
                      name="branchSelect"
                      dataOptions={branchAll}
                      clearErrors={clearErrors}
                      control={control}
                      setValue={setValue}
                      valueData={branchSelectName}
                      setValueData={setBranchSelectName}
                      setValueDataId={setBranchSelect}
                      disabled={isLoadingUpdate}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Box
                    sx={(theme) => ({
                      boxSizing: "border-box",
                    })}
                  >
                    <SelectCheckMarkComponent
                      dataEmpty={t("order_select")}
                      errors={errors}
                      name="orderSelect"
                      dataOptions={orderAll}
                      clearErrors={clearErrors}
                      control={control}
                      setValue={setValue}
                      valueData={orderSelect}
                      setValueData={setOrderSelect}
                      disabled={isLoadingUpdate}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Box
                    sx={(theme) => ({
                      boxSizing: "border-box",
                    })}
                  >
                    <Space direction="vertical">
                      <RangePicker
                        defaultValue={[startDate, endDate]}
                        onChange={handleChangeDate}
                        disabledDate={disabledDate}
                        format={dateFormat}
                        style={{
                          height: "40px",
                          width: "100%",
                        }}
                        disabled={isLoadingUpdate}
                      />
                    </Space>
                  </Box>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Button
                    variant="contained"
                    fullWidth
                    onClick={() => onConfirm()}
                    sx={{ borderRadius: 2 }}
                    disabled={isLoadingUpdate}
                  >
                    Confirm
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </LocalizationProvider>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Box
            className="grid-paper-white"
            sx={{ p: 2, boxSizing: "border-box", height: "100%" }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: 2,
              }}
            >
              <Avatar
                src={basicChart}
                sx={{ borderRadius: 0, width: 32, height: 32 }}
              />
              <Box>
                <Typography
                  variant="h3"
                  sx={{
                    fontWeight: "500",
                    lineHeight: 0.7,
                  }}
                >
                  Performance
                </Typography>
                <FormHelperText>
                  Show summary of sale and performance.
                </FormHelperText>
              </Box>
            </Box>
            <Grid
              container
              justifyContent={"space-between"}
              sx={(theme) => ({
                boxSizing: "border-box",
                py: 2,
                color: theme.palette.primaryText.main,
              })}
              spacing={2}
            >
              {isLoadingAll.summary ? (
                <Grid item xs={12} sm={12} md={12} lg={7}>
                  <Skeleton
                    sx={{
                      borderRadius: "10px",
                    }}
                    variant="rectangular"
                    width="100%"
                    height="40vh"
                  />
                </Grid>
              ) : (
                <Grid item xs={12} sm={12} md={12} lg={7}>
                  <Box
                    className="grid-paper-white"
                    sx={{ boxSizing: "border-box", height: "100%" }}
                  >
                    <TotalSale
                      props={{
                        tabSelect,
                        handleChange,
                        setStatusLine,
                        statusLine,
                        startDate,
                        endDate,
                        dataTotalSale,
                        dataTotalSaleLine,
                      }}
                    />
                  </Box>
                </Grid>
              )}
              {isLoadingAll.weeklyPerformance ? (
                <Grid item xs={12} sm={12} md={12} lg={5}>
                  <Skeleton
                    sx={{
                      borderRadius: "10px",
                    }}
                    variant="rectangular"
                    width="100%"
                    height="40vh"
                  />
                </Grid>
              ) : (
                <Grid item xs={12} sm={12} md={12} lg={5}>
                  <Box
                    className="grid-paper-white"
                    sx={{ p: 2, boxSizing: "border-box", height: "100%" }}
                  >
                    <WeeklyPerformance
                      props={{ dataWeekly, nameValue: tabSelect }}
                    />
                  </Box>
                </Grid>
              )}
            </Grid>
          </Box>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Box
            className="grid-paper-white"
            sx={{ p: 2, boxSizing: "border-box", height: "100%" }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: 2,
                mb: 2,
              }}
            >
              <Avatar
                src={basicBranch}
                sx={{ borderRadius: 0, width: 32, height: 32 }}
              />
              <Box mt={1}>
                <Typography
                  variant="h3"
                  sx={{
                    fontWeight: "500",
                    lineHeight: 0.5,
                  }}
                >
                  Branch Performance
                </Typography>
                <FormHelperText>
                  Show percentage total sale and total receipt of each branch.
                </FormHelperText>
              </Box>
            </Box>
            <Grid
              container
              justifyContent={"space-between"}
              sx={(theme) => ({
                color: theme.palette.primaryText.main,
              })}
              spacing={2}
            >
              {isLoadingAll.branchPerformance ? (
                <Grid item xs={12} sm={12} md={12} lg={6}>
                  <Skeleton
                    sx={{
                      borderRadius: "10px",
                    }}
                    variant="rectangular"
                    width="100%"
                    height="40vh"
                  />
                </Grid>
              ) : (
                <>
                  <Grid item xs={12} sm={12} md={12} lg={6}>
                    <Box
                      className="grid-paper-white"
                      sx={{ p: 2, boxSizing: "border-box", height: "100%" }}
                    >
                      <BranchPerformance
                        props={{ dataBranchPerformance, branchSelectName }}
                      />
                    </Box>
                  </Grid>
                </>
              )}
              {isLoadingAll.totalSalesVsTotalReceipts ? (
                <>
                  <Grid item xs={12} sm={12} md={12} lg={6}>
                    <Skeleton
                      sx={{
                        borderRadius: "10px",
                      }}
                      variant="rectangular"
                      width="100%"
                      height="40vh"
                    />
                  </Grid>
                </>
              ) : (
                <Grid item xs={12} sm={12} md={12} lg={6}>
                  <Box
                    className="grid-paper-white"
                    sx={{ p: 2, boxSizing: "border-box", height: "100%" }}
                  >
                    <TotalSalesVsTotalReceipts
                      props={{ dataBranchPerformance, branchSelectName }}
                    />
                  </Box>
                </Grid>
              )}
              {isLoadingAll.movementBranchesPerformance ? (
                <>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Skeleton
                      sx={{
                        borderRadius: "10px",
                      }}
                      variant="rectangular"
                      width="100%"
                      height="40vh"
                    />
                  </Grid>
                </>
              ) : (
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Box
                    className="grid-paper-white"
                    sx={{ p: 2, boxSizing: "border-box", height: "100%" }}
                  >
                    <MovementBranchesPerformance
                      props={{
                        dataBranchPerformance,
                        branchSelectName,
                        branchAll,
                      }}
                    />
                  </Box>
                </Grid>
              )}
            </Grid>
          </Box>
        </Grid>

        {isLoadingAll.paymentMethod ? (
          <>
            <Grid item xs={12} sm={12} md={5} lg={4}>
              <Skeleton
                sx={{
                  borderRadius: "10px",
                }}
                variant="rectangular"
                width="100%"
                height="40vh"
              />
            </Grid>
          </>
        ) : (
          <Grid item xs={12} sm={12} md={5} lg={4}>
            <Box
              className="grid-paper-white"
              sx={{ p: 2, boxSizing: "border-box", height: "100%" }}
            >
              <PaymentMethod
                props={{
                  dataPaymentMethod,
                  orderSelect,
                  dataTotalSale,
                }}
              />
            </Box>
          </Grid>
        )}
        {isLoadingAll.couponPerformance ? (
          <>
            <Grid item xs={12} sm={12} md={7} lg={8}>
              <Skeleton
                sx={{
                  borderRadius: "10px",
                }}
                variant="rectangular"
                width="100%"
                height="40vh"
              />
            </Grid>
          </>
        ) : (
          <Grid item xs={12} sm={12} md={7} lg={8}>
            <Box
              className="grid-paper-white"
              sx={{ p: 2, boxSizing: "border-box", height: "100%" }}
            >
              <CouponPerformance
                props={{
                  dataCouponPerformance,
                  promotionAll,
                  branchAll,
                }}
              />
            </Box>
          </Grid>
        )}
        {isLoadingAll.productPerformance ? (
          <>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Skeleton
                sx={{
                  borderRadius: "10px",
                }}
                variant="rectangular"
                width="100%"
                height="40vh"
              />
            </Grid>
          </>
        ) : (
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Box
              className="grid-paper-white"
              sx={{ p: 2, boxSizing: "border-box", height: "100%" }}
            >
              <ProductPerformance
                props={{
                  dataProductPerformance,
                  branchSelectName,
                  orderSelect,
                }}
              />
            </Box>
          </Grid>
        )}
        {isLoadingAll.productCombo ? (
          <>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Skeleton
                sx={{
                  borderRadius: "10px",
                }}
                variant="rectangular"
                width="100%"
                height="40vh"
              />
            </Grid>
          </>
        ) : (
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <Box
              className="grid-paper-white"
              sx={{ p: 2, boxSizing: "border-box", height: "100%" }}
            >
              <ProductComboSuggestions props={{ dataProductCombo }} />
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}

export default DashboardInsight;
