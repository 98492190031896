import {
  Box,
  Button,
  FormControlLabel,
  Switch,
  Typography,
} from "@mui/material";
import React from "react";
// import StatusForm from "../../../../components/ui/formComponent/statusForm";
import ImageCrop from "../../../../components/ui/upload/imageCrop";
import { t } from "i18next";
import TextfieldComponent from "../../../../components/ui/textfield/textfieldComponent";
import ConfirmButton from "../../../../components/ui/button/confirmButton";
import PaperWrapper from "../../../../components/ui/paper/paperWrapper";
import TitleForm from "../../../../components/ui/formComponent/titleForm";
import { ContentCopyOutlined } from "@mui/icons-material";
import { BoxGapInput } from "../../../../components/ui/box/boxGapInput";

function DialogReceipt({ props }) {
  let {
    configShow,
    onConfigShow,
    register,
    handleSubmit,
    errors,
    onConfirmForm,
    setImageLogo,
    imageLogo,
    setImageQRCode,
    imageQRCode,
    // statusButton,
    // setStatusButton,
    isSubmitting,
    onDuplicateDetail,
  } = props;
  return (
    <Box
      sx={(theme) => ({
        width: "100%",
        // width: "315px",
        boxSizing: "border-box",
        margin: "auto",
        overflow: "auto",
        [theme.breakpoints.up("md")]: {
          height: "75vh",
        },
      })}
    >
      <Box align="left">
        <form onSubmit={handleSubmit(onConfirmForm)}>
          <Box className="boxWrapper-container">
            <PaperWrapper props={{ growFull: null }}>
              <TitleForm props={{ text: t("receipt_logo") }} />
              <Box sx={{ margin: "auto" }}>
                <ImageCrop
                  props={{
                    setImage: setImageLogo,
                    image: imageLogo,
                    heightCrop: 300,
                    widthCrop: 500,
                    heightView: 150,
                    widthView: 250,
                    label: "logo",
                  }}
                />
              </Box>
            </PaperWrapper>
            <PaperWrapper props={{ growFull: 1 }}>
              <TitleForm props={{ text: t("receipt_info") }} />
              <BoxGapInput>
                <Box sx={{ mb: 2 }}>
                  <Button
                    onClick={() => onDuplicateDetail()}
                    variant="contained"
                    color="primary"
                    startIcon={<ContentCopyOutlined />}
                    sx={{ mb: 1, borderRadius: 2 }}
                    fullWidth
                  >
                    {t("receipt_same_branch_detail")}
                  </Button>
                </Box>
                <TextfieldComponent
                  title={t("branch_name")}
                  name="branchName"
                  register={register}
                  placeholder={t("validate_please_fill_out_information")}
                  errors={errors}
                  fullGrid={true}
                />
                <TextfieldComponent
                  title={t("global_business_name") + " (Optional)"}
                  name="businessName"
                  register={register}
                  placeholder={t("validate_please_fill_out_information")}
                  errors={errors}
                  fullGrid={true}
                />
                <TextfieldComponent
                  title={t("branch_code") + " (Optional)"}
                  name="branchCode"
                  register={register}
                  placeholder={t("validate_please_fill_out_information")}
                  errors={errors}
                  fullGrid={true}
                />
                <TextfieldComponent
                  title={t("address") + " (Optional)"}
                  name="address"
                  register={register}
                  multiline={true}
                  placeholder={t("validate_please_fill_out_information")}
                  errors={errors}
                  fullGrid={true}
                />
              </BoxGapInput>
            </PaperWrapper>
            <PaperWrapper props={{ growFull: 1 }}>
              <TitleForm props={{ text: t("receipt_store_detail") }} />
              <BoxGapInput>
                <TextfieldComponent
                  title={t("global_store_name")}
                  name="storeName"
                  register={register}
                  placeholder={t("validate_please_fill_out_information")}
                  errors={errors}
                  fullGrid={true}
                />
                <TextfieldComponent
                  title={t("global_tel") + " (Optional)"}
                  name="tel"
                  register={register}
                  placeholder={t("validate_please_fill_out_information")}
                  errors={errors}
                  fullGrid={true}
                />
              </BoxGapInput>
            </PaperWrapper>
            <PaperWrapper props={{ growFull: 1 }}>
              <TitleForm props={{ text: t("TAX Invoice Information") }} />
              <BoxGapInput>
                <TextfieldComponent
                  title={"POS ID Number (Optional)"}
                  name="posId"
                  register={register}
                  placeholder={t("validate_please_fill_out_information")}
                  errors={errors}
                  fullGrid={true}
                />
                <TextfieldComponent
                  title={"Tax ID (Optional)"}
                  name="taxId"
                  register={register}
                  placeholder={t("validate_please_fill_out_information")}
                  errors={errors}
                  fullGrid={true}
                />
                <TextfieldComponent
                  title={"Invoice Name (Optional)"}
                  name="invoiceName"
                  register={register}
                  placeholder={t("validate_please_fill_out_information")}
                  errors={errors}
                  fullGrid={true}
                />
                <TextfieldComponent
                  title={"Invoice Last Number (Optional)"}
                  name="invoiceLastNumber"
                  register={register}
                  placeholder={t("validate_please_fill_out_information")}
                  errors={errors}
                  fullGrid={true}
                />
              </BoxGapInput>
            </PaperWrapper>
            <PaperWrapper props={{ growFull: 1 }}>
              <TitleForm props={{ text: t("Header and Footer Text") }} />
              <BoxGapInput>
                <TextfieldComponent
                  title={"Text Header (Optional)"}
                  name="textHeader"
                  register={register}
                  placeholder={t("validate_please_fill_out_information")}
                  errors={errors}
                  fullGrid={true}
                />
                <TextfieldComponent
                  title={t("Text Footer 1") + " (Optional)"}
                  name="textFooter1"
                  register={register}
                  placeholder={t("validate_please_fill_out_information")}
                  errors={errors}
                  fullGrid={true}
                />
                <Typography>
                  {t("receipt_logo_footage") + " (Optional)"}
                </Typography>
                <Box sx={{ margin: "auto" }}>
                  <ImageCrop
                    props={{
                      setImage: setImageQRCode,
                      image: imageQRCode,
                      heightCrop: 256,
                      widthCrop: 256,
                      label: "staffPOS",
                    }}
                  />
                </Box>
                <Box mt={1}>
                  <TextfieldComponent
                    title={t("Text Footer 2") + " (Optional)"}
                    name="textFooter2"
                    register={register}
                    placeholder={t("validate_please_fill_out_information")}
                    errors={errors}
                    fullGrid={true}
                  />
                </Box>
              </BoxGapInput>
            </PaperWrapper>

            <PaperWrapper props={{ growFull: 1 }}>
              <TitleForm
                props={{ text: t("receipt_config_printing_option") }}
              />
              <BoxGapInput>
                <FormControlLabel
                  onChange={(e) => onConfigShow(e.target.checked, "menuOption")}
                  control={<Switch checked={configShow.menuOption} />}
                  label={t("receipt_config_display_menu_option")}
                  sx={{ width: "100%" }}
                />
                <FormControlLabel
                  onChange={(e) =>
                    onConfigShow(e.target.checked, "menuAtPriceZero")
                  }
                  control={<Switch checked={configShow.menuAtPriceZero} />}
                  label={t("receipt_config_not_print_menu_at_zero")}
                  sx={{ width: "100%" }}
                />
                <FormControlLabel
                  onChange={(e) => onConfigShow(e.target.checked, "vat")}
                  control={<Switch checked={configShow.vat} />}
                  label={t("receipt_config_display_vat_before_vat")}
                  sx={{ width: "100%" }}
                />
              </BoxGapInput>
            </PaperWrapper>
          </Box>
          {/* <StatusForm
            statusButton={statusButton}
            setStatusButton={setStatusButton}
          /> */}
          <ConfirmButton disabled={isSubmitting} />
        </form>
      </Box>
    </Box>
  );
}

export default DialogReceipt;
