import { Box } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";

import { t } from "i18next";
import dayjs from "dayjs";
import BreadcrumbsTitle from "../../../components/ui/breadcrumbsTitle";
import SelectCheckMarkBranch from "../../../components/ui/select/selectCheckMarkBranch";
import { TextTable } from "../../../components/ui/textfield/textfieldCustom";
import { getAllSaleProduct } from "../../../service/saleProductAPI";
import { MyContext } from "../../../context/MyContext";
import DataTableReport from "../../../components/ui/dataTable/dataTableReport";
import i18n from "../../../locals/i18n";

function ReportBySaleProduct() {
  const [rows, setRows] = useState([]);

  const [columns, setColumns] = useState([]);
  const [exportColumnName, setExportColumnName] = useState(null);
  const {
    setBranchSelectReport,
    branchSelectReportDate,
    reportAll,
    fetchReport,
    saleProductSetAll,
  } = useContext(MyContext);
  const [isLoadingData, setIsLoadingData] = useState(
    reportAll.length > 0 ? false : true
  );

  const fetchData = async () => {
    let headers = [];
    let dataSaleProduct = [];

    let dataAPISaleProduct =
      saleProductSetAll.length > 0
        ? saleProductSetAll
        : await getAllSaleProduct();
    let groupBranch = [];
    if (reportAll) {
      reportAll.forEach((itemReceipt) => {
        let dataExists = groupBranch.find(
          (branch) => branch.branch_id === itemReceipt.branch_id
        );
        if (dataExists) {
          dataExists.receiptAll.push(itemReceipt);
        } else {
          groupBranch.push({
            branch_id: itemReceipt.branch_id,
            receiptAll: [itemReceipt],
          });
        }
      });
      let dataAllSaleProduct = [];
      groupBranch.forEach((itemBranch) => {
        let dataCollectSaleProduct = [];
        itemBranch.receiptAll
          .filter((itemNoVoid) => itemNoVoid.receipt_status === "success")
          .forEach((itemReceipt) => {
            itemReceipt.receipt_sale_products.forEach((itemSaleProduct) => {
              dataCollectSaleProduct.push({
                name: itemSaleProduct.name,
                id: itemSaleProduct.sale_product_id,
                mainCategory: dataAPISaleProduct.find(
                  (itemSaleProductAll) =>
                    itemSaleProductAll.id === itemSaleProduct.sale_product_id
                ).main_categories[0].main_category_lang.name,
                minorCategory:
                  dataAPISaleProduct.find(
                    (itemSaleProductAll) =>
                      itemSaleProductAll.id === itemSaleProduct.sale_product_id
                  ).minor_categories.length > 0
                    ? dataAPISaleProduct.find(
                        (itemSaleProductAll) =>
                          itemSaleProductAll.id ===
                          itemSaleProduct.sale_product_id
                      ).minor_categories[0].minor_category_lang.name
                    : "None",
                amount: itemSaleProduct.amount,
                price: itemSaleProduct.price,
                discountByItems:
                  (itemSaleProduct.price -
                    itemSaleProduct.price_after_discount) *
                  itemSaleProduct.amount,
                branch_id: itemBranch.branch_id,
                branchName: itemReceipt.receipt_config_receipt
                  ? itemReceipt.receipt_config_receipt.config_receipt_lang
                      .branch_name
                  : null,
              });
            });
          });
        dataAllSaleProduct.push(dataCollectSaleProduct);
      });
      let dataBox = [];
      dataAllSaleProduct.forEach((itemBranch) => {
        let dataItemBrachBoxWithSaleProduct = [];
        itemBranch.forEach((itemAllProduct) => {
          let existingItem = dataItemBrachBoxWithSaleProduct.find(
            (itemFind) => itemFind.id === itemAllProduct.id
          );
          if (existingItem === undefined) {
            dataItemBrachBoxWithSaleProduct.push(itemAllProduct);
          } else {
            existingItem.amount = existingItem.amount + itemAllProduct.amount;
            existingItem.discountByItems += itemAllProduct.discountByItems;
          }
        });
        dataBox.push(dataItemBrachBoxWithSaleProduct);
      });
      dataBox.forEach((itemReceipt) => {
        itemReceipt.sort((a, b) => {
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        });
        itemReceipt.forEach((itemSaleProduct) => {
          itemSaleProduct.grossSale =
            itemSaleProduct.amount * itemSaleProduct.price;
          itemSaleProduct.discountedPrice =
            itemSaleProduct.grossSale - itemSaleProduct.discountByItems;
        });
      });
      dataBox.forEach((item) => {
        item.forEach((itemSaleProduct) => {
          dataSaleProduct.push(itemSaleProduct);
        });
      });

      // dataSaleProduct.push({
      //   name: "Total",
      //   mainCategory: null,
      //   minorCategory: null,
      //   amount: dataSaleProduct.reduce((acc, item) => acc + item.amount, 0),
      //   price: dataSaleProduct.reduce((acc, item) => acc + item.price, 0),
      //   grossSale: dataSaleProduct.reduce(
      //     (acc, item) => acc + item.grossSale,
      //     0
      //   ),
      //   discountByItems: dataSaleProduct.reduce(
      //     (acc, item) => acc + item.discountByItems,
      //     0
      //   ),
      //   discountedPrice: dataSaleProduct.reduce(
      //     (acc, item) => acc + item.discountedPrice,
      //     0
      //   ),
      //   branchName: null,
      // });

      setRows(dataSaleProduct);
    } //===== Start set header name export csv

    let dataColumns = [
      {
        header: t("global_name"),
        accessorKey: "name",
        minWidth: 150,
        Cell: ({ cell }) => <TextTable props={{ text: cell.getValue() }} />,
      },
      {
        header: t("report_main_category"),
        accessorKey: "mainCategory",
        minWidth: 150,
        Cell: ({ cell }) => <TextTable props={{ text: cell.getValue() }} />,
      },
      {
        header: t("report_subcategory"),
        accessorKey: "minorCategory",
        minWidth: i18n.language === "th" ? 160 : 150,
        Cell: ({ cell }) => <TextTable props={{ text: cell.getValue() }} />,
      },
      {
        header: t("report_amount"),
        accessorKey: "amount",
        isSorting: true,
        minWidth: i18n.language === "th" ? 70 : 70,
        Cell: ({ cell }) => (
          <TextTable
            props={{
              text: Number(cell.getValue()).toLocaleString(undefined, {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              }),
              alignText: "right",
            }}
          />
        ),
      },
      {
        header: t("report_price_per_unit"),
        accessorKey: "price",
        isSorting: true,
        minWidth: i18n.language === "th" ? 110 : 100,
        Cell: ({ cell }) => (
          <TextTable
            props={{
              text: Number(cell.getValue()).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }),
              alignText: "right",
            }}
          />
        ),
      },
      {
        header: t("report_gross_sale"),
        accessorKey: "grossSale",
        isSorting: true,
        minWidth: 110,
        Cell: ({ cell }) => (
          <TextTable
            props={{
              text: Number(cell.getValue()).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }),
              alignText: "right",
            }}
          />
        ),
      },
      {
        header: t("report_discount_by_item"),
        accessorKey: "discountByItems",
        isSorting: true,
        minWidth: i18n.language === "th" ? 150 : 130,

        Cell: ({ cell }) => (
          <TextTable
            props={{
              text: Number(cell.getValue()).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }),
              alignText: "right",
            }}
          />
        ),
      },

      {
        header: t("report_discounted_price"),
        accessorKey: "discountedPrice",
        isSorting: true,
        minWidth: i18n.language === "th" ? 100 : 130,
        Cell: ({ cell }) => (
          <TextTable
            props={{
              text: Number(cell.getValue()).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }),
              alignText: "right",
            }}
          />
        ),
      },

      {
        header: t("report_branch"),
        accessorKey: "branchName",
        Cell: ({ cell }) => <TextTable props={{ text: cell.getValue() }} />,
      },
    ];
    dataColumns.forEach((item) => {
      if (item.header !== "Image" && item.header !== "Action") {
        headers.push(item.header);
      }
    });
    setColumns(dataColumns);
    const csvStringArray = [
      headers,
      ...dataSaleProduct.map((item) => [
        item.name,
        item.mainCategory,
        item.minorCategory,
        item.amount,
        item.price,
        item.grossSale,
        item.discountByItems,
        item.discountedPrice,
        item.branchName,
      ]),
    ]
      .map((row) => row.join(","))
      .join("\n");

    setExportColumnName(csvStringArray);
    //===== End set header name export csv
    setIsLoadingData(false);
  };
  const onConfirm = async () => {
    await setIsLoadingData(true);
    await fetchReport();
  };
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportAll]);
  return (
    <Box>
      <BreadcrumbsTitle
        props={{
          title: t("report_by_sale_product"),
        }}
      />
      <Box>
        <SelectCheckMarkBranch
          props={{
            onConfirm,
            setBranchSelectReport,
          }}
        />

        <DataTableReport
          statusViewMode={true}
          rows={rows}
          isLoadingData={isLoadingData}
          exportColumnName={exportColumnName}
          columns={columns}
          height={0.85}
          fileName={
            t("report_by_sale_product") +
            `${dayjs(branchSelectReportDate.startDate).format(
              "DD/MM/YYYY"
            )} - ${dayjs(branchSelectReportDate.endDate).format("DD/MM/YYYY")}`
          }
        />
      </Box>
    </Box>
  );
}

export default ReportBySaleProduct;
