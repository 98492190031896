import React, { useState, useEffect } from "react";
import { getAllUnitCounter } from "../../service/unitCounterAPI";
import { t } from "i18next";
import { Box } from "@mui/material";
import { ActionDetail } from "../../components/ui/dataTable/components/actionDetail";
import { MomentConvert } from "../../components/ui/moment/momentConvert";
import DataMaterialTable from "../../components/ui/dataTable/dataMaterialTable";
import BreadcrumbsTitle from "../../components/ui/breadcrumbsTitle";
import StatusTable from "../../components/chip/statusTable";
import { TextTable } from "../../components/ui/textfield/textfieldCustom";
import dayjs from "dayjs";

function Unit() {
  const [rows, setRows] = useState([]);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [columns, setColumns] = useState([]);
  const [exportColumnName, setExportColumnName] = useState(null);

  const fetchData = async () => {
    let dataColumns = [
      {
        accessorKey: "name",
        flexGrow: 1,
        header: t("global_name"),
        Cell: ({ cell }) => <TextTable props={{ text: cell.getValue() }} />,
      },
      {
        accessorKey: "update",
        size: 100,
        header: t("global_updated_at"),
        Cell: ({ cell }) => <TextTable props={{ text: cell.getValue() }} />,
      },
      {
        accessorKey: "status",
        enableSorting: false,
        size: 20,
        header: t("global_status"),
        Cell: ({ row }) => (
          <StatusTable props={{ status: row.original.status }} />
        ),
      },
      {
        accessorKey: "action",
        enableSorting: false,
        size: 100,
        header: "Action",
        Cell: ({ row }) => (
          <Box sx={{ display: "flex", gap: 2 }}>
            <ActionDetail params={row} detailPath={"/unit/detail/"} />
          </Box>
        ),
      },
    ];

    let dataAPI = await getAllUnitCounter();
    if (dataAPI) {
      let dataBox = dataAPI
        .sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at))
        .map((item) => ({
          id: item.id,
          name: item.name,
          update: MomentConvert(item.unit_counter_lang.updated_at),
          status: item.status,
        }));
      //===== Start set header name export csv
      let headers = [];
      dataColumns.forEach((item) => {
        if (item.header !== "Image" && item.header !== "Action") {
          headers.push(item.header);
        }
      });
      const csvStringArray = [
        headers,
        ...dataBox.map((item) => [
          item.name,
          item.update,
          item.status,
        ]),
      ]
        .map((row) => row.join(","))
        .join("\n");

      setExportColumnName(csvStringArray);
      //===== End set header name export csv
      setRows(dataBox);
    }

    setColumns(dataColumns);
    setIsLoadingData(false);
  };

  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <Box>
      <BreadcrumbsTitle
        props={{
          title: t("unit"),
        }}
      />
      <DataMaterialTable
        statusViewMode={true}
        rows={rows}
        exportColumnName={exportColumnName}
        fileName={t("unit") + ` ${dayjs().format("DD/MM/YYYY")}`}
        isLoadingData={isLoadingData}
        columns={columns}
        detailPath={"/unit/detail/"}
        createButton={t("unit")}
      />
    </Box>
  );
}

export default Unit;
