import React, { useState } from "react";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { notifySuccess } from "../../components/ui/popup/toastPopup";
import { postCurrency } from "../../service/currencyAPI";
import { useNavigate } from "react-router-dom";
import BreadcrumbsTitle from "../../components/ui/breadcrumbsTitle";
import { useSchema } from "../../components/hooks/use-schema";
import { currencySchema } from "../../components/validation/currencySchema";
import { GridContainerSize } from "../../components/utils/gridContainerSize";
import CurrencyForm from "./currencyForm";
function CurrencyCreate() {
  const {
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    register,
    control,
  } = useForm(useSchema(currencySchema));
  const [statusButton, setStatusButton] = useState(true);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onConfirmForm = async (register) => {
    setIsSubmitting(true);
    let dataAPI = {
      status: statusButton,
      currency_lang: {
        name: register.currency,
        country: register.country,
        language_code: "ALL",
      },
    };
    let statusAPI = await postCurrency(dataAPI);
    if (statusAPI === "OK") {
      notifySuccess({
        title: t("toast_successfully_added_information"),
      });
      navigate("/currency");
    }
    await new Promise((resolve) => setTimeout(resolve, 2000));
    setIsSubmitting(false);
  };

  return (
    <>
      <BreadcrumbsTitle
        props={{
          title: t("currency_create"),
          dataLink: [
            { text: t("currency"), link: "/currency" },
            { text: t("currency_create"), link: null },
          ],
        }}
      />
      <GridContainerSize>
        <Box p={2}>
          <form onSubmit={handleSubmit(onConfirmForm)}>
            <CurrencyForm
              props={{
                register,
                errors,
                setValue,
                clearErrors,
                control,
                statusButton,
                setStatusButton,
                isSubmitting,
              }}
            />
          </form>
        </Box>
      </GridContainerSize>
    </>
  );
}

export default CurrencyCreate;
