import React, { useEffect, useState } from "react";
import { Box, Skeleton } from "@mui/material";
import "react-lazy-load-image-component/src/effects/blur.css";
import imageNotFound from "../../../../assets/images/imageNotFound.jpeg";
import { getImage } from "../../../../service/upload/getImage";
import { UseConvertImageToBase64 } from "../../../../components/hooks/use-convert-image-to-base-64";
export const BoxImageZone = (props) => {
  const { image, height, width, type } = props;
  const [loaded, setLoaded] = useState(false);
  const [imageShow, setImageShow] = useState(null);
  const loadImage = async (image) => {
    try {
      if (!image) {
        return imageNotFound;
      }

      if (typeof image === "string") {
        if (image.includes("not found")) {
          return imageNotFound;
        } else {
          const imageUrl = await getImage(image);
          return UseConvertImageToBase64(imageUrl);
        }
      } else if (typeof image === "object") {
        return URL.createObjectURL(image);
      }

      return "";
    } catch (error) {
      console.error("Error loading image:", error);
      return imageNotFound;
    }
  };

  useEffect(() => {
    const loadImageAsync = async () => {
      const loadedImage = await loadImage(image);
      setImageShow(loadedImage);
      setLoaded(true);
    };
    loadImageAsync();
  }, [image]);
  const handleLoad = () => {
    setLoaded(true);
  };
  return (
    <Box
      sx={(theme) => ({
        position: "relative",
      })}
    >
      {!loaded ? (
        <Skeleton
          sx={{
            borderRadius: "10px",
          }}
          variant="rectangular"
          width={"100px"}
          height={"100px"}
        />
      ) : (
        <img
          src={imageShow}
          onLoad={handleLoad}
          effect="blur"
          className="imageGhost"
          alt={"spos_business_picture"}
          style={
            type === "table"
              ? {
                  maxHeight: height ? height : "256px",
                  height: "100%",
                  maxWidth: width ? width : "256px",
                  width: "100%",
                  borderRadius: "10px",
                  objectFit: "cover",
                  display: "block",
                  opacity: !loaded ? 0 : 1,
                }
              : {
                  height: height ? height : "256px",
                  // height: "100%",
                  width: width ? width : "256px",
                  // width: "100%",
                  borderRadius: "10px",
                  objectFit: "cover",
                  display: "block",
                  opacity: !loaded ? 0 : 1,
                }
          }
        />
      )}
    </Box>
  );
};
