import {
  Box,
  TextField,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import BreadcrumbsTitle from "../../../components/ui/breadcrumbsTitle";
import { t } from "i18next";
import Loading from "../../../components/ui/loading";
import { GridContainerSize } from "../../../components/utils/gridContainerSize";
import PaperWrapper from "../../../components/ui/paper/paperWrapper";
import TitleForm from "../../../components/ui/formComponent/titleForm";
import { TextFormWithError } from "../../../components/ui/textfield/textfieldCustom";
import { useForm } from "react-hook-form";
import ConfirmButton from "../../../components/ui/button/confirmButton";
import {
  getAllMinorCategory,
  storeMinorCategory,
  updateMinorCategory,
} from "../../../service/minorCategory";
import { notifySuccess } from "../../../components/ui/popup/toastPopup";
import { getMainCategory } from "../../../service/mainCategory";
import { BakeryDining, Edit } from "@mui/icons-material";
import TextfieldComponent from "../../../components/ui/textfield/textfieldComponent";
import StatusForm from "../../../components/ui/formComponent/statusForm";
import CloseIcon from "@mui/icons-material/Close";
import { BoxGapInput } from "../../../components/ui/box/boxGapInput";
import { BoxWrapper } from "../../../components/ui/box/boxWrapper";
import { BoxFormTitleAction } from "../../../components/ui/box/boxFormTitleAction";
import TitleHeaderForm from "../../../components/ui/formComponent/titleHeaderForm";
function SubCategory() {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
  } = useForm();
  const [isLoading, setIsLoading] = useState(true);
  const [mainCategory, setMainCategory] = useState(null);
  const [subSelect, setSubSelect] = useState(null);
  const [dialogAddMinor, setDialogAddMinor] = useState({
    status: false,
    data: null,
  });
  const [minorCategory, setMinorCategory] = useState(null);
  const [statusButton, setStatusButton] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const paramsId =
    window.location.pathname.split("/")[
      window.location.pathname.split("/").length - 1
    ];

  const onAddCategorySub = () => {
    setDialogAddMinor({
      status: true,
      data: null,
    });
    setSubSelect(null);
  };
  const handleClose = () => {
    setDialogAddMinor({
      status: false,
      data: null,
    });
    setSubSelect(null);
    reset();
  };
  const onUpdateCategorySub = (data) => {
    setDialogAddMinor({
      status: true,
      data: data,
    });
    setValue("name", data.minor_category_lang.name);
    setSubSelect(data.id);
  };

  const onConfirmForm = async (register) => {
    setIsSubmitting(true);
    let dataAPI = {
      status: statusButton,
      main_category_id: String(paramsId),
      minor_category_lang: {
        name: register.name,
        language_code: "ALL",
      },
    };
    let statusAPI = await storeMinorCategory(dataAPI);
    if (statusAPI.message === "OK") {
      notifySuccess({
        title: t("toast_successfully_added_information"),
      });
      setStatusButton(true);
      fetchData();
    }
    await new Promise((resolve) => setTimeout(resolve, 2000));
  };
  const onConfirmUpdateForm = async (register) => {
    setIsSubmitting(true);
    let dataAPI = {
      id: subSelect,
      status: statusButton,
      minor_category_lang: {
        name: register.name,
        language_code: String(localStorage.getItem("language_form")),
      },
    };
    let statusAPI = await updateMinorCategory(dataAPI);
    if (statusAPI === "OK") {
      notifySuccess({
        title: t("notification_successfully_edited_information"),
      });
      setStatusButton(true);
      fetchData();
    }
    await new Promise((resolve) => setTimeout(resolve, 2000));
  };

  const fetchData = async () => {
    handleClose();
    let dataAPIAll = await getAllMinorCategory(paramsId);
    let dataAPIMain = await getMainCategory(paramsId);
    let dataBox = dataAPIAll.filter(
      (item) => item.main_category_id === String(paramsId)
    );
    setMainCategory(dataAPIMain);
    setMinorCategory(dataBox);
    setIsLoading(false);
    setIsSubmitting(false);
  };
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Box>
      <BreadcrumbsTitle
        props={{
          title: t("category_sub"),
          dataLink: [
            { text: t("category"), link: "/category" },
            { text: t("category_sub"), link: null },
          ],
        }}
      />

      {isLoading ? (
        <Loading />
      ) : (
        <GridContainerSize>
          <Box p={2} className="boxWrapper-container">
            <BoxWrapper>
              <PaperWrapper props={{ growFull: 1 }}>
                <BoxFormTitleAction>
                  <TitleHeaderForm props={{ text: t("category_sub") }} />
                </BoxFormTitleAction>
                <BoxGapInput>
                  <Box sx={{ display: "flex", gap: 1 }}>
                    <BakeryDining color="secondary" />
                    <Typography sx={{ fontWeight: 600 }}>
                      {mainCategory.main_category_lang.name}
                    </Typography>
                  </Box>
                  <PaperWrapper props={{ growFull: 1 }}>
                    <TitleForm props={{ text: t("category_sub_add") }} />
                    <Box px={2}>
                      {minorCategory.length > 0 ? (
                        <>
                          {minorCategory.map((element, index) => (
                            <>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: 1.5,
                                }}
                                mt={1}
                              >
                                <TextField
                                  type="text"
                                  fullWidth
                                  size="small"
                                  value={element.minor_category_lang.name}
                                  id={index + 1}
                                  error={
                                    errors["categorySub" + String(index + 1)]
                                  }
                                />
                                <IconButton
                                  onClick={() => onUpdateCategorySub(element)}
                                >
                                  <Edit />
                                </IconButton>
                              </Box>
                              <TextFormWithError
                                textError={
                                  errors["categorySub" + String(index + 1)]
                                }
                              />
                            </>
                          ))}
                        </>
                      ) : null}
                      <Box my={2}>
                        <Button
                          onClick={() => onAddCategorySub()}
                          variant="outlined"
                          color="secondary"
                          fullWidth
                          sx={{ borderRadius: 2 }}
                        >
                          {t("category_sub_add")}
                        </Button>
                      </Box>
                      {/* <ConfirmButton disabled={isSubmitting} /> */}
                    </Box>
                  </PaperWrapper>
                </BoxGapInput>
              </PaperWrapper>
            </BoxWrapper>
          </Box>
        </GridContainerSize>
      )}
      <Dialog
        open={dialogAddMinor.status}
        onClose={() => setDialogAddMinor(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>{t("category_sub_add")}</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Box>
            <form
              onSubmit={handleSubmit(
                dialogAddMinor.data ? onConfirmUpdateForm : onConfirmForm
              )}
            >
              <TextfieldComponent
                title={t("category_sub_name")}
                name="name"
                register={register}
                placeholder={t("category_sub_example")}
                errors={errors}
                fullGrid={true}
              />
              <StatusForm
                statusButton={statusButton}
                setStatusButton={setStatusButton}
              />
              <ConfirmButton disabled={isSubmitting} />
            </form>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
}

export default SubCategory;
