import Swal from "sweetalert2";

export const SuccessPopup = (props) => {
  const { title } = props;
  Swal.fire({
    title: title,
    icon: "success",
    timer: 1500,
    showConfirmButton: false,
  });
};
export const WarningPopup = (props) => {
  const { title } = props;
  Swal.fire({
    title: title,
    icon: "warning",
    timer: 1500,
    showConfirmButton: false,
  });
};
