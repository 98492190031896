import * as yup from "yup";
import { validationSchemas } from "./data";

export const deviceSchema = yup.object().shape({
  branch: validationSchemas.name,
  deviceName: validationSchemas.name,
  serial_number: validationSchemas.name,
  // device_model: validationSchemas.name,
  // version_security_patch: validationSchemas.name,
  // version_sdkInt: validationSchemas.name,
  // version_release: validationSchemas.name,
});
