import React from "react";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  DoughnutController,
} from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { Avatar, Box, Typography, useTheme } from "@mui/material";
import "chartjs-plugin-datalabels";
// import ChartDataLabels from "chartjs-plugin-datalabels";
import { t } from "i18next";
import { Circle } from "@mui/icons-material";
import colorDashBoard from "../../colorDashboard.json";
import backIcon from "../../../../../assets/svg/dashboard/backIcon.svg";
ChartJS.register(ArcElement, Tooltip, Legend, DoughnutController);

function PaymentChannelChart({ props }) {
  let { dataPaymentType, onBackSelectPaymentType } = props;
  const theme = useTheme();
  const dataBox = dataPaymentType.payment_name.map(
    (item) => item.payment_total_sales
  );
  const numberReceipt = dataPaymentType.payment_total_receipt_count;
  const data = {
    labels: dataPaymentType.payment_name.map((item) => item.payment_name),
    // labels: ["Bank Transfer", "Cash", "Credit Card"],
    datasets: [
      {
        label: "",
        data: dataBox,
        backgroundColor: colorDashBoard,

        borderColor: theme.palette.background.default,
      },
    ],
  };
  const colorSet = colorDashBoard;
  const options = {
    maintainAspectRatio: false,
    width: 100,
    // elements: {
    //   center: {
    //     text: "Center Text",
    //     color: "#000", // Color of the text
    //     fontStyle: "Arial", // Font style of the text
    //     sidePadding: 20, // Padding around the text
    //   },
    // },
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
        color: "white",
        formatter: (value) =>
          value ? `${((value / 100) * 100).toFixed(2)}%` : null,
      },
      tooltip: {
        callbacks: {
          title: function (context) {
            return t(context[0].label);
          },

          label: function (context) {
            let dataTotalSale = 0;
            let dataTotalSalePercent = 0;
            dataTotalSale =
              dataPaymentType.payment_name[context.dataIndex]
                .payment_total_sales;
            dataTotalSalePercent =
              dataPaymentType.payment_name[context.dataIndex]
                .payment_pct_total_sales;

            return `Total Sale: ${dataTotalSale.toLocaleString()} (${dataTotalSalePercent})`;
          },
          afterLabel: function (context) {
            let dataReceiptCount = 0;
            let dataReceiptCountPercent = 0;
            dataReceiptCount =
              dataPaymentType.payment_name[context.dataIndex]
                .payment_total_receipt_count;
            dataReceiptCountPercent =
              dataPaymentType.payment_name[context.dataIndex]
                .payment_pct_total_receipt_count;

            return `Total Receipt: ${dataReceiptCount.toLocaleString()} (${dataReceiptCountPercent})`;
          },
        },
      },
    },
  };
  const centerTextPlugin = {
    id: "centerText",
    beforeDraw: (chart) => {
      const { width, height } = chart;
      const ctx = chart.ctx;
      ctx.restore();
      const fontSize = (height / 300).toFixed(2);
      ctx.font = `${fontSize}em sans-serif`;
      ctx.textBaseline = "middle";
      ctx.textAlign = "center"; // Align text horizontally to the center

      // Prepare the text and break it into lines
      const line1 = `${t("Total Receipt")}`;
      const line2 = `${Number(numberReceipt).toLocaleString()}`;

      // Calculate vertical position based on the font size
      const line1Y = height / 2 - fontSize * 10;
      const line2Y = height / 2 + 20;

      // Draw first line with a different color
      ctx.fillStyle = "#000"; // Color for the first line
      ctx.fillText(line1, width / 2, line1Y);

      // Draw second line with a different color
      ctx.fillStyle = theme.palette.primary.main; // Color for the second line (black)
      ctx.font = `bold ${fontSize * 1.5}em sans-serif`; // Bold font
      ctx.fillText(line2, width / 2, line2Y);

      ctx.save();
    },
  };
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
          height: 40,
        }}
      >
        <Avatar
          onClick={onBackSelectPaymentType}
          src={backIcon}
          sx={{ borderRadius: 0, width: 24, height: 24 }}
        />
        <Typography
          variant="h3"
          sx={{
            fontWeight: "500",
          }}
        >
          Payment Channel
        </Typography>
      </Box>

      <Box
        sx={(theme) => ({
          position: "relative",
          alignItems: "center",
          [theme.breakpoints.down("sm")]: {
            display: "inline",
          },
          // display: "flex",
          justifyContent: "center",
          width: "100%",
        })}
      >
        <div
          style={{
            // maxWidth: "400px",
            height: "300px",
            marginTop: "24px",
            position: "relative",
          }}
        >
          <Doughnut
            data={data}
            plugins={[centerTextPlugin]}
            options={options}
          />
        </div>

        <Box
          sx={(theme) => ({
            m: 4,
            display: "flex",
            flexDirection: "column",
            gap: 2,
          })}
        >
          {dataPaymentType.payment_name.map((item, index) => (
            <>
              <Box
                sx={(theme) => ({
                  display: "flex",
                  justifyContent: "space-between",
                })}
              >
                <Box
                  sx={{
                    mt: 0.5,
                    display: "flex",
                    justifyContent: "space-between",
                    gap: 1,
                    alignItems: "center",
                  }}
                >
                  <Circle sx={{ color: colorSet[index] }} />
                  <Typography sx={(theme) => ({ fontSize: 12 })}>
                    {t(`${item.payment_name}`)}
                  </Typography>
                </Box>
                <Typography sx={(theme) => ({ fontSize: 18 })}>
                  {item.payment_total_sales.toLocaleString()}
                </Typography>
              </Box>
            </>
          ))}
        </Box>
      </Box>
    </Box>
  );
}

export default PaymentChannelChart;
