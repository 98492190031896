import axios from "axios";
import {
  AccessTokenAPI,
  BusinessId,
  CompanyId,
  LanguageForm,
  ServiceId,
} from "../getLocalStorage";
import { expiredToken } from "../expiredToken";

export const getCloseShopSummary = async (dataAPI) => {
  let { start, end, branches } = dataAPI;
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_API_URL}/api/report/getCloseShopSummary?language_code=${LanguageForm}&company_uid=${CompanyId}&business_uid=${BusinessId}&service_code=${ServiceId}&start=${start}&end=${end}&branches=${branches}`,
    headers: {
      Authorization: `Bearer ${AccessTokenAPI}`,
    },
  };
  return axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      console.log(error);
      expiredToken(error);
    });
};
