import React from "react";
import TitleForm from "../../components/ui/formComponent/titleForm";
import { t } from "i18next";
import TextfieldComponent from "../../components/ui/textfield/textfieldComponent";
import ConfirmButton from "../../components/ui/button/confirmButton";
import StatusForm from "../../components/ui/formComponent/statusForm";
import { BoxWrapper } from "../../components/ui/box/boxWrapper";
import PaperWrapper from "../../components/ui/paper/paperWrapper";
import { BoxGapInput } from "../../components/ui/box/boxGapInput";
import { BoxFormTitleAction } from "../../components/ui/box/boxFormTitleAction";
import TitleHeaderForm from "../../components/ui/formComponent/titleHeaderForm";

function SettingDeviceForm({ props }) {
  let {
    handleSubmit,
    onConfirmForm,
    register,
    errors,
    statusButton,
    setStatusButton,
    watch,
    isSubmitting,
    mode,
  } = props;
  return (
    <div>
      <form onSubmit={handleSubmit(onConfirmForm)}>
        <BoxWrapper>
          <PaperWrapper props={{ growFull: 1 }}>
            <BoxFormTitleAction>
              <TitleHeaderForm props={{ text: t("device_pos") }} />
              <StatusForm
                statusButton={statusButton}
                setStatusButton={setStatusButton}
              />
            </BoxFormTitleAction>
            <BoxGapInput>
              <BoxWrapper>
                <PaperWrapper props={{ growFull: 1 }}>
                  <TitleForm props={{ text: t("device_pos_info") }} />
                  <BoxGapInput>
                    <BoxWrapper>
                      <TextfieldComponent
                        title={t("device_pos_name")}
                        name="deviceName"
                        register={register}
                        placeholder={"E.g. SunmiT2s"}
                        errors={errors}
                        fullGrid={true}
                      />
                      <TextfieldComponent
                        title={t("device_serial_number")}
                        name="serial_number"
                        register={register}
                        placeholder={"E.g. TS112"}
                        errors={errors}
                        fullGrid={true}
                        disabled={mode === "edit"}
                      />
                    </BoxWrapper>
                    {/* <BoxWrapper>
                <TextfieldComponent
                  title={t("device_model_name")}
                  name="device_model"
                  register={register}
                  placeholder={"E.g. TS112"}
                  errors={errors}
                  fullGrid={true}
                />
                <TextfieldComponent
                  title={t("device_version_security_patch")}
                  name="version_security_patch"
                  register={register}
                  placeholder={"E.g. 1.0"}
                  errors={errors}
                  fullGrid={true}
                />
              </BoxWrapper>
              <BoxWrapper>
                <TextfieldComponent
                  title={t("device_sdk_int")}
                  name="version_sdkInt"
                  register={register}
                  placeholder={"E.g. 10"}
                  errors={errors}
                  fullGrid={true}
                />
                <TextfieldComponent
                  title={t("device_release_version")}
                  name="version_release"
                  register={register}
                  placeholder={"E.g. 10"}
                  errors={errors}
                  fullGrid={true}
                />
              </BoxWrapper> */}
                  </BoxGapInput>
                </PaperWrapper>
              </BoxWrapper>

              <ConfirmButton disabled={isSubmitting || !watch("branch")} />
            </BoxGapInput>
          </PaperWrapper>
        </BoxWrapper>
      </form>
    </div>
  );
}

export default SettingDeviceForm;
