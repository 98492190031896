import Cookies from "js-cookie";

export const AccessTokenAPI = Cookies.get("access_token")
  ? Cookies.get("access_token")
  : undefined;
export const LanguageSystem = localStorage.getItem("language_system")
  ? localStorage.getItem("language_system")
  : undefined;
export const LanguageForm = localStorage.getItem("language_form")
  ? localStorage.getItem("language_form").toUpperCase()
  : undefined;
export const BusinessId = Cookies.get("business_uid")
  ? Cookies.get("business_uid")
  : undefined;
export const BusinessName = Cookies.get("business_name")
  ? Cookies.get("business_name")
  : undefined;
export const CompanyId = Cookies.get("company_uid")
  ? Cookies.get("company_uid")
  : undefined;
export const ServiceId = Cookies.get("service_code")
  ? Cookies.get("service_code")
  : undefined;
export const ServiceStatus = Cookies.get("status_pair")
  ? Cookies.get("status_pair")
  : undefined;
