import axios from "axios";
import {
  AccessTokenAPI,
  BusinessId,
  CompanyId,
  LanguageForm,
  ServiceId,
} from "../getLocalStorage";

export const getAllBranchConfigTable = async (paramsId) => {
  let config = {
    method: "get",
    url: `${process.env.REACT_APP_API_URL}/api/setting/branch/getAllTable?template_id=${paramsId}&language_code=${LanguageForm}&company_uid=${CompanyId}&business_uid=${BusinessId}&service_code=${ServiceId}`,
    headers: {
      Authorization: `Bearer ${AccessTokenAPI}`,
    },
  };

  return axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      console.log(error);
    });
};
export const storeBranchConfigTable = async (dataAPI) => {
  let config = {
    method: "post",
    url: `${process.env.REACT_APP_API_URL}/api/setting/branch/storeTable?company_uid=${CompanyId}&business_uid=${BusinessId}&service_code=${ServiceId}`,
    headers: {
      Authorization: `Bearer ${AccessTokenAPI}`,
      "Content-Type": "application/json",
    },
    data: JSON.stringify(dataAPI),
  };

  return axios(config)
    .then(function (response) {
      return response.data.message;
    })
    .catch(function (error) {
      console.log(error);
    });
};
export const getBranchConfigTable = async (paramsId) => {
  let data = "";

  let config = {
    method: "get",
    url: `${process.env.REACT_APP_API_URL}/api/setting/branch/getTable?template_id=${paramsId}&language_code=${LanguageForm}&company_uid=${CompanyId}&business_uid=${BusinessId}&service_code=${ServiceId}`,
    headers: {
      Authorization: `Bearer ${AccessTokenAPI}`,
    },
    data: data,
  };

  return axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      console.log(error);
    });
};
export const updateBranchConfigTable = async (dataAPI) => {
  let config = {
    method: "put",
    url: `${process.env.REACT_APP_API_URL}/api/setting/branch/updateTable?company_uid=${CompanyId}&business_uid=${BusinessId}&service_code=${ServiceId}`,
    headers: {
      Authorization: `Bearer ${AccessTokenAPI}`,
      "Content-Type": "application/json",
    },
    data: JSON.stringify(dataAPI),
  };

  return axios(config)
    .then(function (response) {
      return response.data.message;
    })
    .catch(function (error) {
      console.log(error);
    });
};

export const deleteBranchConfigTable = async (dataAPI) => {
  let config = {
    method: "delete",
    url: `${process.env.REACT_APP_API_URL}/api/setting/branch/deleteTable?company_uid=${CompanyId}&business_uid=${BusinessId}&service_code=${ServiceId}`,
    headers: {
      Authorization: `Bearer ${AccessTokenAPI}`,
      "Content-Type": "application/json",
    },
    data: JSON.stringify(dataAPI),
  };

  return axios(config)
    .then(function (response) {
      return response.data.message;
    })
    .catch(function (error) {
      console.log(error);
    });
};
