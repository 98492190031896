import React from "react";
import { Avatar, Box, IconButton, Tooltip, Typography } from "@mui/material";
import { t } from "i18next";
import { TextTable } from "../../../../components/ui/textfield/textfieldCustom";
import MaterialReactTable from "material-react-table";
import { HelpOutline } from "@mui/icons-material";
import movementBranchIcon from "../../../../assets/svg/dashboard/movementBranchIcon.svg";

function MovementBranchesPerformance({ props }) {
  let { dataBranchPerformance, branchAll } = props;
  let dataColumns = [
    {
      accessorKey: "branch_id",
      flexGrow: 1,
      header: t("branch"),
      Cell: ({ cell }) => (
        <TextTable
          props={{
            text: branchAll.find((item) => item.id === cell.getValue()).name,
          }}
        />
      ),
    },
    {
      accessorKey: "total_sale",
      flexGrow: 1,
      header: t("Total Sale"),
      Cell: ({ cell }) => (
        <TextTable
          props={{
            text: Number(cell.getValue()).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }),
          }}
        />
      ),
    },
    {
      accessorKey: "pct_chg_total_sale",
      flexGrow: 1,
      header: t("%Change"),
      Cell: ({ cell }) => (
        <TextTable
          props={{
            text: cell.getValue(),
            colorSetting:
              Number(cell.getValue().replace("%", "")) === 0
                ? "#FFB300"
                : Number(cell.getValue().replace("%", "")) > 0
                ? "#45cb85"
                : "#E53935E5",
          }}
        />
      ),
    },
    {
      accessorKey: "total_receipt",
      flexGrow: 1,
      header: t("Total Receipt"),
      Cell: ({ cell }) => (
        <TextTable props={{ text: Number(cell.getValue()).toLocaleString() }} />
      ),
    },
    {
      accessorKey: "pct_total_receipt",
      flexGrow: 1,
      header: t("%Change"),
      Cell: ({ cell }) => (
        <TextTable
          props={{
            text: cell.getValue(),
            colorSetting:
              Number(cell.getValue().replace("%", "")) === 0
                ? "#FFB300"
                : Number(cell.getValue().replace("%", "")) > 0
                ? "#45cb85"
                : "#E53935E5",
          }}
        />
      ),
    },
    {
      accessorKey: "avg_price_per_receipt",
      flexGrow: 1,
      header: t("Value per Receipt"),
      Cell: ({ cell }) => (
        <TextTable
          props={{
            text: cell.getValue(),
          }}
        />
      ),
    },
    {
      accessorKey: "pct_chg_avg_price_per_receipt",
      flexGrow: 1,
      header: t("%Change"),
      Cell: ({ cell }) => (
        <TextTable
          props={{
            text: cell.getValue(),
            colorSetting:
              Number(cell.getValue().replace("%", "")) === 0
                ? "#FFB300"
                : Number(cell.getValue().replace("%", "")) > 0
                ? "#45cb85"
                : "#E53935E5",
          }}
        />
      ),
    },
  ];
  return (
    <>
      <Box
        sx={(theme) => ({
          [theme.breakpoints.down("sm")]: {
            display: "flex",
            flexDirection: "column",
            gap: 2,
          },
          [theme.breakpoints.up("sm")]: {
            display: "flex",
            justifyContent: "space-between",
          },
          mb: 2,
        })}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <Avatar
              src={movementBranchIcon}
              sx={{ borderRadius: 0, width: 18, height: 18 }}
            />
            <Typography variant="h4">Movement Branch Performance</Typography>
          </Box>
          <Tooltip
            title={
              <>
                <Typography>
                  {t("helpPercentChangeOne")}
                  <br />
                  {t("helpPercentChangeTwo")}
                </Typography>
              </>
            }
            placement="right"
          >
            <IconButton>
              <HelpOutline />
            </IconButton>
          </Tooltip>
        </Box>
        {/* <Box
          sx={(theme) => ({
            [theme.breakpoints.up("sm")]: {
              display: "flex",
              gap: 2,
            },
            [theme.breakpoints.down("sm")]: {
              display: "flex",
              flexDirection: "column",
              gap: 2,
            },
          })}
        >
          <Button
            size="small"
            color="success"
            variant="outlined"
            sx={{ borderRadius: 2 }}
            onClick={() => onSelectTotalValueType("Total Value")}
          >
            Total Value
          </Button>
          <Button
            size="small"
            variant="outlined"
            sx={{ borderRadius: 2 }}
            onClick={() => onSelectTotalValueType("Total as Percent")}
          >
            Total as Percent
          </Button>
        </Box> */}
      </Box>
      <MaterialReactTable
        columns={dataColumns}
        data={dataBranchPerformance.N04_3.data.data}
        enableStickyHeader
        // defaultColumn={{
        //   maxSize: 400,
        //   minSize: 80,
        //   size: 100,
        // }}
        options={{
          search: false,
        }}
        enableTopToolbar={false}
        enableColumnResizing={true}
        enableColumnActions={false}
        enableColumnFilters={false}
        // enableSorting={false}
        muiTableBodyRowProps={{ hover: false }}
        enableToolbarInternalActions={false}
        muiTableProps={{
          sx: {
            tableLayout: "fixed",
          },
        }}
        muiTablePaginationProps={{
          rowsPerPageOptions: [25, 50, 100],
          showFirstButton: true,
          showLastButton: true,
        }}
      />
    </>
  );
}

export default MovementBranchesPerformance;
