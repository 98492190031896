import React, { useState, useEffect, useContext } from "react";
import { getAllDevice } from "../../service/deviceAPI";
import { Box } from "@mui/material";
import { MomentConvert } from "../../components/ui/moment/momentConvert";
import { ActionDetail } from "../../components/ui/dataTable/components/actionDetail";
import DataMaterialTable from "../../components/ui/dataTable/dataMaterialTable";
import { t } from "i18next";
import BreadcrumbsTitle from "../../components/ui/breadcrumbsTitle";
import StatusTable from "../../components/chip/statusTable";
import { TextTable } from "../../components/ui/textfield/textfieldCustom";
import { MyContext } from "../../context/MyContext";
import dayjs from "dayjs";
// import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

function SettingDevice() {
  const { branchAll } = useContext(MyContext);
  const [rows, setRows] = useState([]);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [columns, setColumns] = useState([]);
  const [exportColumnName, setExportColumnName] = useState(null);

  const fetchData = async () => {
    let dataColumns = [
      {
        accessorKey: "name",
        header: t("device_pos_name"),
      },
      {
        accessorKey: "branch_id",
        enableSorting: false,
        header: t("branch"),
        Cell: ({ cell }) => <TextTable props={{ text: cell.getValue() }} />,
      },
      {
        accessorKey: "serial_number",
        enableSorting: false,
        header: t("device_serial_number"),
        Cell: ({ cell }) => <TextTable props={{ text: cell.getValue() }} />,
      },
      {
        accessorKey: "update",
        header: t("global_updated_at"),
        Cell: ({ cell }) => <TextTable props={{ text: cell.getValue() }} />,
      },
      {
        accessorKey: "status",
        enableSorting: false,
        header: t("global_status"),

        Cell: ({ cell }) => <StatusTable props={{ status: cell.getValue() }} />,
      },
      {
        accessorKey: "action",
        enableSorting: false,
        header: "Action",
        Cell: ({ row }) => (
          <ActionDetail params={row} detailPath={"/setting-device/detail/"} />
        ),
      },
    ];
    let dataAPI = await getAllDevice();
    if (dataAPI) {
      let dataBox = dataAPI
        .sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at))
        .map((item) => ({
          id: item.id,
          branch_id: branchAll.find((itemAll) => itemAll.id === item.branch_id)
            .name,
          serial_number: item.serial_number,
          name: item.device_lang.name,
          update: MomentConvert(item.updated_at),
          status: item.status,
        }));
      setRows(dataBox);
      //===== Start set header name export csv
      let headers = [];
      dataColumns.forEach((item) => {
        if (item.header !== "Image" && item.header !== "Action") {
          headers.push(item.header);
        }
      });
      const csvStringArray = [
        headers,
        ...dataBox.map((item) => [
          item.name,
          item.branch_id,
          item.serial_number,
          item.update,
          item.status,
        ]),
      ]
        .map((row) => row.join(","))
        .join("\n");

      setExportColumnName(csvStringArray);
      //===== End set header name export csv
    }
    setColumns(dataColumns);
    setIsLoadingData(false);
  };

  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <Box>
      <BreadcrumbsTitle
        props={{
          title: t("device_pos"),
        }}
      />
      <DataMaterialTable
        statusViewMode={true}
        rows={rows}
        isLoadingData={isLoadingData}
        columns={columns}
        exportColumnName={exportColumnName}
        fileName={t("device_pos") + ` ${dayjs().format("DD/MM/YYYY")}`}
        detailPath={"/setting-device/detail/"}
        createButton={t("device_pos")}
      />
    </Box>
  );
}

export default SettingDevice;
