import React from "react";
import TextfieldComponent from "../../../../components/ui/textfield/textfieldComponent";
import { t } from "i18next";
import { Box } from "@mui/material";

function BankTransferOptions({ props }) {
  let { register, errors } = props;
  return (
    <Box sx={{
        display: "flex",
        gap: 2,
        flexDirection: "column",
    }}>
      <TextfieldComponent
        title={t("payment_channel_bank_transfer_name")}
        name="bankTransferName"
        register={register}
        placeholder={t("Thai QR-Code")}
        errors={errors}
      />

      <TextfieldComponent
        title={t("payment_channel_bank_transfer_name_alt")}
        name="bankTransferNameAlt"
        register={register}
        placeholder={t("Thai QR-Code")}
        errors={errors}
      />
    </Box>
  );
}

export default BankTransferOptions;
