import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import Loading from "../../../../components/ui/loading";
import { configGetAllDevice } from "../../../../service/branchConfigAPI/branchConfigDevice";
import { MomentConvert } from "../../../../components/ui/moment/momentConvert";
import DataMaterialTable from "../../../../components/ui/dataTable/dataMaterialTable";
import { useTranslation } from "react-i18next";
import EmptyDataDirect from "../../../../components/ui/emptyDataDirect";
import { TextTable } from "../../../../components/ui/textfield/textfieldCustom";
import dayjs from "dayjs";

function BranchDevice(props) {
  let { templateId } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [rows, setRows] = useState([]);
  const { t } = useTranslation();
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [exportColumnName, setExportColumnName] = useState(null);
  const [columns, setColumns] = useState([]);
  const fetchData = async () => {
    let dataColumns = [
      {
        accessorKey: "name",
        flexGrow: 1,
        header: t("device_pos_name"),
        Cell: ({ cell }) => <TextTable props={{ text: cell.getValue() }} />,
      },
      {
        accessorKey: "update",
        size: 100,
        header: t("global_updated_at"),
        Cell: ({ cell }) => <TextTable props={{ text: cell.getValue() }} />,
      },
      {
        accessorKey: "serial_number",
        enableSorting: false,
        header: t("device_serial_number"),
        Cell: ({ cell }) => <TextTable props={{ text: cell.getValue() }} />,
      },
    ];
    let dataAPI = await configGetAllDevice(templateId);
    let dataBox = [];
    if (dataAPI) {
      dataAPI
        .sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at))
        .map(async (item) => {
          dataBox.push({
            id: item.id,
            name: item.device_lang.name,
            update: MomentConvert(item.updated_at),
            serial_number: item.serial_number,
          });
        });
    }

    //===== Start set header name export csv
    let headers = [];
    dataColumns.forEach((item) => {
      if (item.header !== "Image" && item.header !== "Action") {
        headers.push(item.header);
      }
    });
    const csvStringArray = [
      headers,
      ...dataBox.map((item) => [item.name, item.update, item.serial_number]),
    ]
      .map((row) => row.join(","))
      .join("\n");

    setExportColumnName(csvStringArray);
    //===== End set header name export csv
    setColumns(dataColumns);
    setRows(dataBox);
    setIsLoadingData(false);
    await setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <Box>
      {isLoading ? (
        <>
          <Loading />
        </>
      ) : (
        <>
          {rows.length > 0 ? (
            <>
              <DataMaterialTable
                statusViewMode={false}
                rows={rows}
                columns={columns}
                exportColumnName={exportColumnName}
                fileName={t("staff_pos") + ` ${dayjs().format("DD/MM/YYYY")}`}
                isLoadingData={isLoadingData}
                height={0.62}
              />
            </>
          ) : (
            <Box p={2}>
              {rows.length === 0 ? (
                <EmptyDataDirect
                  props={{ url: "/setting-device", text: t("device_pos") }}
                />
              ) : null}
            </Box>
          )}
        </>
      )}
    </Box>
  );
}

export default BranchDevice;
