// Icon
import { useTranslation } from "react-i18next";
import LeaderboardOutlinedIcon from "@mui/icons-material/LeaderboardOutlined";
import Dashboard from "../../../pages/dashboard/overview/dashboard";
import DashboardInsight from "../../../pages/dashboard/insight/dashboardInsight";
export const ListDashboard = () => {
  const { t } = useTranslation();
  const listItem = [
    // ==== จัดการข้อมูล ====
    {
      name: t("side_bar_dashboard"),
      exact: true,
      iconClassName: <LeaderboardOutlinedIcon sx={{ fontSize: 20 }} />,
      expanded: true,
      subMenus: [
        {
          name: t("side_bar_overview"),
          component: <Dashboard />,
          to: "/overview",
        },
        {
          name: t("Insight"),
          component: <DashboardInsight />,
          to: "/insight",
        },
      ],
    },
  ];
  return listItem;
};
