import React, { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  DoughnutController,
} from "chart.js";
import { Bar, Doughnut } from "react-chartjs-2";
import {
  Avatar,
  Box,
  Checkbox,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Typography,
  useTheme,
} from "@mui/material";
import "chartjs-plugin-datalabels";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { t } from "i18next";
import InfoIcon from "@mui/icons-material/Info";
import { Controller, useForm } from "react-hook-form";
import couponPerformance from "../../../../assets/svg/dashboard/couponPerformance.svg";
import colorDashBoard from "../colorDashboard.json";

ChartJS.register(ArcElement, Tooltip, Legend, DoughnutController);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
function CouponPerformance({ props }) {
  let { dataCouponPerformance, branchAll, promotionAll } = props;
  const { control } = useForm();
  const theme = useTheme();
  const [valueDataId, setValueDataId] = useState([]);
  const [dataCouponList, setDataCouponList] = useState([]);
  const [couponSelect, setCouponSelect] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [dataCouponAll, setDataCouponAll] = useState({
    data: [],
    dataCoupon: [],
  });
  const [total, setTotal] = useState(0);
  const [totalPie, setTotalPie] = useState([]);
  const [totalUsed, setTotalUsed] = useState(0);
  // const [totalPrevious, setTotalPrevious] = useState(0);
  const [totalDiscount, setTotalDiscount] = useState(0);
  const [optionsBar, setOptionsBar] = useState();
  const onCouponPerformanceFilter = (dataProps) => {
    let dataBox = [];
    // let branchAll = [
    //   {
    //     id: "1a89ad99-c737-4b3a-bb17-10797854fa89",
    //     name: "CentralwOrld 6th",
    //   },
    //   {
    //     id: "f01f7d0e-9fa9-4b99-a418-4c1ec79da5a4",
    //     name: "Siam Paragon",
    //   },
    //   {
    //     id: "b612778c-a27e-4c6b-a9c2-56e719c44371",
    //     name: "Central Ladprao",
    //   },
    // ];
    let dataAll = dataProps.current;
    let dataFindCouponAll = [];
    if (dataAll.length > 0) {
      dataAll.forEach((item) => {
        if (
          item.promotion_name !== null &&
          dataFindCouponAll.find(
            (itemFind) => itemFind.promotion_name === item.promotion_name
          ) === undefined
        ) {
          dataFindCouponAll.push({
            promotion_id: item.promotion_id,
            promotion_name: item.promotion_name,
          });
        }
      });

      setDataCouponList(dataFindCouponAll);

      if (valueDataId.length > 0) {
        let dataDD = dataCouponPerformance.current.filter(
          (item) => item.promotion_name === null
        );

        dataAll = dataCouponPerformance.current.filter((item) =>
          valueDataId.includes(item.promotion_id)
        );
        dataAll = dataAll.concat(dataDD);
      }
      dataBox = dataAll.reduce((acc, item) => {
        if (!acc[item.branch_id]) {
          acc[item.branch_id] = [];
        }
        acc[item.branch_id].push(item);
        return acc;
      }, []);

      const groupedArray = Object.entries(dataBox).map(
        ([branch_id, items]) => ({
          branch_id,
          items,
        })
      );
      groupedArray.forEach((item) => {
        item.name = branchAll.find(
          (itemBranch) => itemBranch.id === item.branch_id
        ).name;
        let dataCoupon = item.items.reduce((acc, item) => {
          if (!acc[item.promotion_id]) {
            acc[item.promotion_id] = [];
          }
          acc[item.promotion_id].push(item);
          return acc;
        }, []);
        item.dataCoupon = Object.entries(dataCoupon).map(
          ([promotion_id, items]) => ({
            promotion_id,
            promotion_name: items[0].promotion_name,
            items,
          })
        );
      });
      let dataFindCoupon = [];
      groupedArray.forEach((itemCoupon) => {
        itemCoupon.dataCoupon.forEach((itemCouponFind) => {
          if (
            dataFindCoupon.find(
              (itemFind) =>
                itemFind.promotion_name === itemCouponFind.promotion_name
            ) === undefined
          ) {
            dataFindCoupon.push({
              promotion_id: itemCouponFind.promotion_id,
              promotion_name: itemCouponFind.promotion_name,
              data: itemCouponFind.items,
            });
          } else {
            let dataFind = dataFindCoupon.find(
              (itemFind) =>
                itemFind.promotion_name === itemCouponFind.promotion_name
            );
            dataFind.data = dataFind.data.concat(itemCouponFind.items);
          }
        });
      });
      return {
        data: groupedArray,
        dataCoupon: dataFindCoupon,
      };
    } else {
      return {
        data: [],
        dataCoupon: [],
      };
    }
  };

  const calculateGrowthRate = () => {
    if (dataCouponPerformance.smart.percent_changes.total_sale === 0) {
      return <></>; //
    } else {
      if (
        dataCouponPerformance.smart.percent_changes.total_sale !== "No data"
      ) {
        return (
          <>
            <Box
              sx={{
                width: "100%",
                // backgroundColor: "#dbf9df",
                display: "flex",
                gap: 1,
                p: 1,
                borderRadius: "25px",
                boxSizing: "border-box",
                alignItems: "center",
              }}
            >
              <InfoIcon
                color={
                  Number(
                    dataCouponPerformance.smart.percent_changes.total_sale.replace(
                      "%",
                      ""
                    ) > 0
                  )
                    ? "success"
                    : "error"
                }
              />
              <Typography>
                Since the coupons launch, there has been a{" "}
                <FormLabel
                  sx={{
                    color: (theme) =>
                      Number(
                        dataCouponPerformance.smart.percent_changes.total_sale.replace(
                          "%",
                          ""
                        ) > 0
                      )
                        ? theme.palette.success.main
                        : theme.palette.error.main,
                    fontWeight: 600,
                  }}
                >
                  {dataCouponPerformance.smart.percent_changes.total_sale}{" "}
                  {Number(
                    dataCouponPerformance.smart.percent_changes.total_sale.replace(
                      "%",
                      ""
                    ) > 0
                  )
                    ? "increase"
                    : "decrease"}
                </FormLabel>{" "}
                in the total number of receipts,{" "}
              </Typography>
            </Box>
            <Box
              sx={{
                width: "100%",
                // backgroundColor: "#dbf9df",
                display: "flex",
                gap: 1,
                p: 1,
                borderRadius: "25px",
                boxSizing: "border-box",
                alignItems: "center",
              }}
            >
              <InfoIcon
                color={
                  Number(
                    dataCouponPerformance.smart.percent_changes.total_receipt.replace(
                      "%",
                      ""
                    ) > 0
                  )
                    ? "success"
                    : "error"
                }
              />
              <Typography>
                {Number(
                  dataCouponPerformance.smart.percent_changes.total_receipt.replace(
                    "%",
                    ""
                  ) > 0
                )
                  ? "And"
                  : "But"}{" "}
                a{" "}
                <FormLabel
                  sx={{
                    color: (theme) =>
                      Number(
                        dataCouponPerformance.smart.percent_changes.total_receipt.replace(
                          "%",
                          ""
                        ) > 0
                      )
                        ? theme.palette.success.main
                        : theme.palette.error.main,
                    fontWeight: 600,
                  }}
                >
                  {dataCouponPerformance.smart.percent_changes.total_receipt}{" "}
                  {Number(
                    dataCouponPerformance.smart.percent_changes.total_receipt.replace(
                      "%",
                      ""
                    ) > 0
                  )
                    ? "increase"
                    : "decrease"}
                </FormLabel>{" "}
                in total sales.
              </Typography>
            </Box>{" "}
            <Box
              sx={{
                width: "100%",
                // backgroundColor: "#FFB30020",
                display: "flex",
                gap: 1,
                p: 1,
                borderRadius: "25px",
                boxSizing: "border-box",
                alignItems: "center",
              }}
            >
              <InfoIcon
                color={
                  Number(
                    dataCouponPerformance.smart.percent_changes.avg_total_sale.replace(
                      "%",
                      ""
                    ) > 0
                  )
                    ? "success"
                    : "warning"
                }
              />
              <Typography>
                {Number(
                  dataCouponPerformance.smart.percent_changes.avg_total_sale.replace(
                    "%",
                    ""
                  ) > 0
                )
                  ? "And"
                  : "But"}{" "}
                a{" "}
                <FormLabel
                  sx={{
                    color: (theme) =>
                      Number(
                        dataCouponPerformance.smart.percent_changes.avg_total_sale.replace(
                          "%",
                          ""
                        ) > 0
                      )
                        ? theme.palette.success.main
                        : theme.palette.error.main,
                    fontWeight: 600,
                  }}
                >
                  {dataCouponPerformance.smart.percent_changes.avg_total_sale}{" "}
                  {Number(
                    dataCouponPerformance.smart.percent_changes.avg_total_sale.replace(
                      "%",
                      ""
                    ) > 0
                  )
                    ? "increase"
                    : "decrease"}
                </FormLabel>{" "}
                in <b>average</b> total sales.
              </Typography>
            </Box>
          </>
        );
      }
    }
  };
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    let dataBox = typeof value === "string" ? value.split(",") : value;
    setCouponSelect(dataBox);
    let dataFindId = [];
    dataBox.forEach((itemFind) => {
      let dataFind = dataCouponList.find(
        (item) => item.promotion_name === itemFind
      );
      dataFindId.push(dataFind.promotion_id);
    });
    setValueDataId(dataFindId);
    // fetchData(dataCouponPerformance, dataFindId);
  };

  const fetchData = async (dataCouponPerformance, valueDataId) => {
    // let dataNew = dataCouponPerformance;
    let dataNew = await onCouponPerformanceFilter(
      dataCouponPerformance,
      valueDataId
    );
    // dataNew.forEach((item) => {});
    setDataCouponAll(dataNew);
    // setTotalPrevious(
    //   dataCouponPerformance.previous.reduce(
    //     (acc, item) => acc + item.invoice_number,
    //     0
    //   )
    // );
    setCouponSelect(
      dataNew.dataCoupon
        .filter((itemPromotion) => itemPromotion.promotion_name !== null)
        .map((item) => item.promotion_name)
    );
    setTotal(
      dataNew.dataCoupon.reduce(
        (acc, itemCoupon) =>
          acc +
          itemCoupon.data.reduce((acc, item) => acc + item.invoice_number, 0),
        0
      )
    );
    let dataInsertColor = [];
    dataNew.dataCoupon.forEach((itemCoupon, index) => {
      let dataSum = itemCoupon.data.reduce(
        (acc, item) => acc + item.invoice_number,
        0
      );
      let promotionAllFind = promotionAll.find(
        (itemPromotion) =>
          itemPromotion.promotion_id === itemCoupon.promotion_id
      );

      dataInsertColor.push({
        label: itemCoupon.promotion_name,
        data: dataSum,
        promotion_id: itemCoupon.promotion_id,
        percent: dataSum,
        color: promotionAllFind ? promotionAllFind.color : "#cccccc",
      });
    });
    setTotalPie(
      dataInsertColor.sort((a, b) => {
        if (b.promotion_id === "null") return -1;
        if (a.promotion_id === "null") return 1;
        return b.promotion_id.localeCompare(a.promotion_id);
      })
    );
    // setValueDataId(dataInsertColor.map((item) => item.promotion_id));
    setTotalUsed(
      dataNew.dataCoupon
        .filter((itemCheck) => itemCheck.promotion_name !== null)
        .reduce(
          (acc, itemCoupon) =>
            acc +
            itemCoupon.data.reduce((acc, item) => acc + item.invoice_number, 0),
          0
        )
    );
    setTotalDiscount(
      dataNew.dataCoupon
        .filter((itemCheck) => itemCheck.promotion_name !== null)
        .reduce(
          (acc, itemCoupon) =>
            acc + itemCoupon.data.reduce((acc, item) => acc + item.discount, 0),
          0
        )
    );

    setOptionsBar({
      responsive: true,
      maintainAspectRatio: false,
      indexAxis: "y",
      scales: {
        x: {
          stacked: true,
          display: true,
          grid: {
            display: false,
          },
          ticks: {
            callback: (value) => {
              // Customize the text labels as needed
              return value === "branch all" ? value : `${value}%`;
            },
          },
          title: { display: true, text: "% Receipt used by Branch" },
        },

        y: {
          stacked: true,
          height: 100,
          display: false,
          grid: {
            display: false,
          },
          // title: { display: true, text: "Receipt Number" },
        },
      },
      plugins: {
        legend: {
          display: true,
        },
        datalabels: {
          display: false,
          color: "white",
          formatter: (value) => (value ? `${value.toFixed(2)}%` : null), // Format percentage to two decimal places
        },
        tooltip: {
          callbacks: {
            title: function (context) {
              return context[0].dataset.label;
            },
            label: function (context) {
              let dataSum = dataNew.data[context.datasetIndex].items.reduce(
                (acc, item) => acc + item.invoice_number,
                0
              );
              let sumPercent = Number((dataSum / total) * 100).toFixed(2) + "%";
              return (
                "Total Invoice: " +
                Number(dataSum).toLocaleString() +
                ` (${sumPercent})`
              );
            },
          },
        },
      },
    });
    setIsLoading(false);
  };
  const data = {
    labels: couponSelect,
    datasets: [
      {
        label: "coupon",
        data: totalPie.map((item) => item.data),
        backgroundColor: totalPie.map((item) => item.color),

        borderColor: theme.palette.background.default,
      },
    ],
  };
  const options = {
    maintainAspectRatio: false,
    width: 100,
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: true,
        color: "white",
        formatter: (value) =>
          value ? `${((value / total) * 100).toFixed(2)}%` : null,
      },
      tooltip: {
        callbacks: {
          title: function (context) {
            return context[0].label ? context[0].label : "No coupon used";
          },

          label: function (context) {
            let sum = Number(context.raw).toLocaleString();
            let sumPercent = `${((context.raw / total) * 100).toFixed(2)}%`;
            return "Total Invoice: " + sum + ` (${sumPercent})`;
          },
        },
      },
    },
  };
  //  chart part
  let colorBg = colorDashBoard;
  const labelsBar = [""];
  const dataBar = {
    labels: labelsBar,
    datasets: dataCouponAll?.data.map((item, index) => ({
      label: item.name,
      data: [
        (item.items.reduce((acc, itemIN) => acc + itemIN.invoice_number, 0) *
          100) /
          total,
      ],
      backgroundColor: colorBg[index],
    })),
  };
  const centerTextPlugin = {
    id: "centerText",
    beforeDraw: (chart) => {
      const { width, height } = chart;
      const ctx = chart.ctx;
      ctx.restore();
      const fontSize = (height / 300).toFixed(2);
      ctx.font = `${fontSize}em sans-serif`;
      ctx.textBaseline = "middle";
      ctx.textAlign = "center"; // Align text horizontally to the center

      // Prepare the text and break it into lines
      const line1 = `${t("Coupon")}`;
      const line2 = `${Number(total).toLocaleString()}`;

      // Calculate vertical position based on the font size
      const line1Y = height / 2 - fontSize * 10;
      const line2Y = height / 2 + 20;

      // Draw first line with a different color
      ctx.fillStyle = "#000"; // Color for the first line
      ctx.fillText(line1, width / 2, line1Y);

      // Draw second line with a different color
      ctx.fillStyle = theme.palette.primary.main; // Color for the second line (black)
      ctx.font = `bold ${fontSize * 1.5}em sans-serif`; // Bold font
      ctx.fillText(line2, width / 2, line2Y);

      ctx.save();
    },
  };
  useEffect(() => {
    fetchData(dataCouponPerformance, valueDataId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueDataId]);
  // if (dataCouponPerformance.current.length === 0) {
  //   return <Typography variant="h5">No data available</Typography>;
  // }
  return (
    <Box>
      {isLoading ? (
        <></>
      ) : (
        <>
          <Box
            sx={{
              display: {
                xs: "block",
                sm: "flex",
              },
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                gap: 2,
                height: 40,
                mb: {
                  xs: 1,
                  sm: 0,
                },
              }}
            >
              <Avatar
                src={couponPerformance}
                sx={{ borderRadius: 0, width: 32, height: 32 }}
              />
              <Box>
                <Typography
                  variant="h3"
                  sx={{
                    fontWeight: "500",
                    lineHeight: 0.7,
                  }}
                >
                  Coupon Performance
                </Typography>
                <FormHelperText>
                  Click piece of Doughnut Chart for view payment channel
                </FormHelperText>
              </Box>
            </Box>
            {dataCouponList.length > 0 ? (
              <Box sx={{ maxWidth: "400px" }}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label" shrink={true}>
                    {t("coupon")}
                  </InputLabel>
                  <Controller
                    control={control}
                    name={"coupon"}
                    defaultValue={""}
                    render={({ field }) => (
                      <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        InputLabelProps={{ shrink: true }}
                        notched={true}
                        value={couponSelect}
                        label={t("coupon")}
                        onChange={handleChange}
                        placeholder="Please select branch"
                        //   input={<OutlinedInput label="Select Branch" />}
                        // renderValue={(selected) => selected.join(", ")}
                        MenuProps={MenuProps}
                        size="small"
                        fullWidth
                        renderValue={(selected) => selected.join(", ")}
                      >
                        {dataCouponList
                          .map((item) => ({
                            name: item.promotion_name,
                            data: item.promotion_id,
                          }))
                          .map((branchItem) => (
                            <MenuItem
                              key={branchItem.name}
                              value={branchItem.name}
                            >
                              <Checkbox
                                checked={
                                  couponSelect.indexOf(branchItem.name) > -1
                                }
                              />
                              <ListItemText primary={branchItem.name} />
                            </MenuItem>
                          ))}
                      </Select>
                    )}
                  />
                </FormControl>
              </Box>
            ) : null}
          </Box>

          <Box
            sx={(theme) => ({
              position: "relative",
              alignItems: "center",
              [theme.breakpoints.down("sm")]: {
                display: "inline",
              },
              display: "flex",
              gap: 8,
              justifyContent: "center",
              width: "100%",
            })}
          >
            <div
              style={{
                maxWidth: "400px",
                height: "300px",
                marginTop: "24px",
                position: "relative",
              }}
            >
              <Doughnut
                data={data}
                plugins={[ChartDataLabels, centerTextPlugin]}
                options={options}
              />
              {/* <Typography
                sx={{
                  position: "absolute",
                  left: "50%",
                  top: "50%",
                  transform: "translate(-50%, -50%)",
                  textAlign: "center",
                  fontSize: 16,
                }}
              >
                {t("Coupon")} <br /> {total.toLocaleString()}
              </Typography> */}
            </div>

            <Box
              sx={(theme) => ({
                [theme.breakpoints.down("sm")]: {
                  mt: 4,
                },
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                gap: 2,
              })}
            >
              <Typography
                sx={(theme) => ({ color: theme.palette.secondaryText.main })}
              >
                % Receipt used coupon
              </Typography>

              <Typography variant="h3">
                {totalUsed === 0
                  ? "0%"
                  : `${Number((totalUsed * 100) / total).toLocaleString(
                      undefined,
                      {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }
                    )}%`}
              </Typography>
              <Typography
                sx={(theme) => ({
                  color: theme.palette.secondaryText.main,
                })}
                variant="h3"
              >
                {Number(totalUsed).toLocaleString()} /{" "}
                {Number(total).toLocaleString()}
              </Typography>
              <Divider maxWidth />
              <Typography
                sx={(theme) => ({ color: theme.palette.secondaryText.main })}
                variant="h4"
              >
                Total value discount
              </Typography>
              <Typography
                variant="h3"
                sx={(theme) => ({
                  color: theme.palette.secondaryText.main,
                })}
              >
                {Number(totalDiscount).toLocaleString()} THB
              </Typography>
            </Box>
          </Box>

          <div style={{ height: "128px", margin: "20px 0" }}>
            <Bar
              options={optionsBar}
              plugins={[ChartDataLabels]}
              data={dataBar}
            />
          </div>
        </>
      )}
      <>{calculateGrowthRate()}</>
    </Box>
  );
}

export default CouponPerformance;
