import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import receiptPromotion from "../../../../assets/images/receiptPromotion.png";
import menuPromotion from "../../../../assets/images/menuPromotion.png";
import freePromotion from "../../../../assets/images/freePromotion.png";
import { useTranslation } from "react-i18next";
function PromotionDiscountType({ props }) {
  let { discountType, setDiscountType } = props;
  const onSelectType = (item) => {
    setDiscountType(item);
  };
  const { t } = useTranslation();
  return (
    <Box
      mb={2}
      sx={(theme) => ({
        display: "flex",
        justifyContent: "center",
      })}
    >
      <Box
        sx={{
          backgroundColor: "#ffffff",
          px: 2,
          boxSizing: "border-box",
          borderRadius: 1,
          pb: 2,
          // boxShadow: 1,
          height: "100%",
          width: "100%",
        }}
        align="center"
      >
        <Typography
          variant="h5"
          sx={(theme) => ({
            fontWeight: "bold",
            pt: 2,
            color: theme.palette.primary.main,
          })}
        >
          {t("promotion_type")}
        </Typography>
        <Grid container justifyContent={"space-between"} alignItems={"center"}>
          {[
            {
              id: 1,
              name: t("promotion_type_discount_receipt"),
              image: receiptPromotion,
            },
            {
              id: 2,
              name: t("promotion_type_discount_menu"),
              image: menuPromotion,
            },
            {
              id: 3,
              name: t("promotion_type_discount_free"),
              image: freePromotion,
            },
          ].map((item, index) => (
            <Grid
              item
              xs={12}
              md={4}
              sx={{
                p: 2,
                boxSizing: "border-box",
              }}
            >
              <Box
                sx={(theme) => ({
                  [theme.breakpoints.down("md")]: {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: 2,
                  },
                  border: `2px solid ${
                    discountType === item.id
                      ? theme.palette.primary.main
                      : "#F1F1F1"
                  }`,
                  px: 2,
                  boxSizing: "border-box",
                  p: 4,
                  boxShadow: "0px 3px 4px 0px rgba(0, 0, 0, .03)",
                  borderRadius: "0.75rem",
                  // border: "1px solid #F1F1F1",
                  backgroundColor: "#fff",
                  display:"grid",
                  placeItems:"center",
                  minHeight: "196px",
                  ":hover": {
                    cursor: "pointer",
                    opacity: 0.4,
                  },
                  width: "100%",
                })}
                onClick={() => onSelectType(item.id)}
                align="center"
              >
                <Box
                  sx={(theme) => ({
                    [theme.breakpoints.down("md")]: {
                      width: "20%",
                    },
                    width: "60%",
                  })}
                >
                  <img src={item.image} alt="selectType" width="100%" />
                </Box>
                <Typography
                  sx={(theme) => ({
                    color: theme.palette.primary.main,
                    whiteSpace: "pre-line",
                  })}
                >
                  {item.name}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
}

export default PromotionDiscountType;
