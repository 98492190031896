import React from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { Controller } from "react-hook-form";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function SelectCheckMarkComponent(props) {
  const {
    dataOptions,
    valueData,
    setValueData,
    dataEmpty,
    disabled,
    control,
    name,
    setValue,
    errors,
    clearErrors,
    setValueDataId,
  } = props;
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    let dataNews = typeof value === "string" ? value.split(",") : value;
    setValueData(dataNews);
    setValue(name, dataNews);

    if (setValueDataId) {
      let dataFindId = [];
      dataNews.forEach((itemFind) => {
        let dataFind = dataOptions.find((item) => item.name === itemFind);
        dataFindId.push(dataFind.id);
      });
      setValueDataId(dataFindId);
    }
    clearErrors(name);
    if (dataNews.length === 0) {
      setValue(name, "");
    }
  };
  return (
    <FormControl fullWidth disabled={disabled}>
      <Controller
        control={control}
        name={name}
        defaultValue={""}
        render={({ field, fieldState }) => (
          <Select
            {...field}
            error={errors[name]}
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            displayEmpty
            multiple
            style={{
              backgroundColor: "#ffffff", // Background color
            }}
            value={valueData}
            onChange={handleChange}
            renderValue={(selected) => {
              if (selected.length === 0) {
                return <em>{dataEmpty}</em>;
              }

              return selected.join(", ");
            }}
            MenuProps={MenuProps}
            placeholder=""
            size="small"
          >
            {dataOptions.map((name) => (
              <MenuItem key={name.name} value={name.name}>
                <Checkbox checked={valueData.indexOf(name.name) > -1} />
                <ListItemText primary={name.name} />
              </MenuItem>
            ))}
          </Select>
        )}
      />
    </FormControl>
  );
}
