import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { getCurrency, updateCurrency } from "../../service/currencyAPI";
import { notifySuccess } from "../../components/ui/popup/toastPopup";
import Loading from "../../components/ui/loading";
import Swal from "sweetalert2";
import BreadcrumbsTitle from "../../components/ui/breadcrumbsTitle";
import { useSchema } from "../../components/hooks/use-schema";
import { currencySchema } from "../../components/validation/currencySchema";
import { GridContainerSize } from "../../components/utils/gridContainerSize";
import CurrencyForm from "./currencyForm";

function CurrencyInfoDetail() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [statusButton, setStatusButton] = useState(true);
  const [dataAll, setDataAll] = useState([]);
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const paramsId =
    window.location.pathname.split("/")[
      window.location.pathname.split("/").length - 1
    ];
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    control,
  } = useForm(useSchema(currencySchema));

  const fetchData = async () => {
    let dataAPI = await getCurrency(paramsId);
    setDataAll(dataAPI);
    setStatusButton(dataAPI.status);
    setValue("currency", dataAPI.currency_lang.name);
    setValue("country", dataAPI.currency_lang.country);
    setIsLoading(false);
  };
  const onConfirmForm = async (register) => {
    setIsSubmitting(true);
    setIsLoading(true);
    let dataAPI = {
      id: dataAll.id,
      status: statusButton,
      currency_lang: {
        name: register.currency,
        country: register.country,
        language_code: String(localStorage.getItem("language_form")),
      },
    };
    let statusAPI = await updateCurrency(dataAPI);
    if (statusAPI === "OK") {
      notifySuccess({
        title: t("notification_successfully_edited_information"),
      });
      navigate("/currency");
    }
    await new Promise((resolve) => setTimeout(resolve, 2000));
    setIsSubmitting(false);
  };
  const onConfirmStatusChange = async (checked) => {
    if (checked) {
      setStatusButton(checked);
    } else {
      Swal.fire({
        title: t("notification_confirm_change_status"),
        text: t(
          "notification_changing_the_status_will_affect_the_branch_settings"
        ),
        icon: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          setStatusButton(checked);
          //sss
        }
      });
    }
  };

  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <Box>
      <BreadcrumbsTitle
        props={{
          title: t("currency_info"),
          dataLink: [
            { text: t("currency"), link: "/currency" },
            { text: t("currency_info"), link: null },
          ],
        }}
      />
      {isLoading ? (
        <>
          <Loading />
        </>
      ) : (
        <GridContainerSize>
          <Box p={2}>
            <form onSubmit={handleSubmit(onConfirmForm)}>
              <CurrencyForm
                props={{
                  register,
                  errors,
                  setValue,
                  clearErrors,
                  control,
                  statusButton,
                  setStatusButton,
                  isSubmitting,
                  onConfirmStatusChange,
                }}
              />
            </form>
          </Box>
        </GridContainerSize>
      )}
    </Box>
  );
}

export default CurrencyInfoDetail;
