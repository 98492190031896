import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";

import ConfirmButton from "../../../components/ui/button/confirmButton";
import { useTranslation } from "react-i18next";

import { getAllBranch } from "../../../service/branchAPI";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { notifySuccess } from "../../../components/ui/popup/toastPopup";
import Loading from "../../../components/ui/loading";
import BreadcrumbsTitle from "../../../components/ui/breadcrumbsTitle";
import TitleForm from "../../../components/ui/formComponent/titleForm";
import StatusForm from "../../../components/ui/formComponent/statusForm";
import SettingTypeBranch from "../../../components/ui/addTypeTemplate/settingTypeBranch";
import TextfieldComponent from "../../../components/ui/textfield/textfieldComponent";
import { validationSchemas } from "../../../components/validation/data";
import { categorySchema } from "../../../components/validation/categorySchema";
import { useSchema } from "../../../components/hooks/use-schema";
import PaperWrapper from "../../../components/ui/paper/paperWrapper";
import { GridContainerSize } from "../../../components/utils/gridContainerSize";
import {
  addMainCategoryToTemplate,
  getAllMainCategoryTemplate,
  getMainCategory,
  updateMainCategory,
} from "../../../service/mainCategory";
import { BoxWrapper } from "../../../components/ui/box/boxWrapper";
import { BoxFormTitleAction } from "../../../components/ui/box/boxFormTitleAction";
import TitleHeaderForm from "../../../components/ui/formComponent/titleHeaderForm";
import { BoxGapInput } from "../../../components/ui/box/boxGapInput";

function CategoryDetail() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm(
    useSchema(
      categorySchema({
        mainCategory: validationSchemas.name,
      })
    )
  );
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [statusButton, setStatusButton] = useState(true);
  const [allDataTemplate, setAllDataTemplate] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [dataAll, setDataAll] = useState([]);
  const navigate = useNavigate();
  const paramsId =
    window.location.pathname.split("/")[
      window.location.pathname.split("/").length - 1
    ];

  const fetchData = async () => {
    // detail
    let statusCate = await fetchDataGetCategory();
    // branch
    if (statusCate) {
      await fetchAllBranch();
    }
    await setIsLoading(false);
  };
  const fetchDataGetCategory = async () => {
    let dataAPI = await getMainCategory(paramsId);
    let { main_category_lang, status } = dataAPI;

    let dataCategory = main_category_lang ? main_category_lang.name : "";

    await setDataAll(dataAPI);
    await setStatusButton(status);
    await setValue("mainCategory", dataCategory);
    return dataAPI.status;
  };
  const onConfirmForm = async (register) => {
    setIsSubmitting(true);

    let dataAPI = {
      id: dataAll.id,
      status: statusButton,
      main_category_lang: {
        name: register.mainCategory,
        language_code: String(localStorage.getItem("language_form")),
      },
    };
    let statusAPI = await updateMainCategory(dataAPI);
    if (statusAPI === "OK") {
      notifySuccess({
        title: t("notification_successfully_edited_information"),
      });
      navigate("/category");
    }
    await new Promise((resolve) => setTimeout(resolve, 2000));
    setIsSubmitting(false);
  };

  const onAddTypeToTemplate = async () => {
    let branchArray = allDataTemplate.filter((item) => item.select === true);
    let dataSelect = [];
    branchArray.forEach((item) => {
      dataSelect.push(item.branch.id);
    });
    let dataAPI = {
      main_category_id: Number(dataAll.main_category_lang.main_category_id),
      branches: dataSelect,
      priority: 1,
    };
    let dataStatus = await addMainCategoryToTemplate(dataAPI);
    if (dataStatus === "OK") {
      notifySuccess({
        title: t("notification_successfully_edited_information"),
      });
      fetchAllBranch();
    }
  };
  const fetchAllBranch = async () => {
    let dataAPI = await getAllBranch();
    dataAPI = dataAPI.filter((item) => item.branch_lang !== null);
    await fetchSettingSelectTemplate(dataAPI);
  };
  const fetchSettingSelectTemplate = async (dataBranch) => {
    let dataAPI = await getAllMainCategoryTemplate(paramsId);
    dataAPI = dataAPI[0].template_main_categories;
    let dataBox = [];
    dataBranch.forEach((item) => {
      let dataNews = dataAPI.filter(
        (itemTemplate) => itemTemplate.template_id === item.template_id
      );
      if (dataNews.length > 0) {
        dataBox.push({
          branch: item,
          data: dataNews[0],
          select: false,
        });
      } else {
        dataBox.push({
          branch: item,
          data: null,
          select: false,
        });
      }
    });
    await setAllDataTemplate(dataBox);
  };

  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <Box>
      <BreadcrumbsTitle
        props={{
          title: t("category_info"),
          dataLink: [
            { text: t("category"), link: "/category" },
            { text: t("category_info"), link: null },
          ],
        }}
      />
      {isLoading ? (
        <Loading />
      ) : (
        <GridContainerSize>
          <Box p={2} className="boxWrapper-container">
            <form onSubmit={handleSubmit(onConfirmForm)}>
              <BoxWrapper>
                <PaperWrapper props={{ growFull: 1 }}>
                  <BoxFormTitleAction>
                    <TitleHeaderForm props={{ text: t("category") }} />
                    <StatusForm
                      statusButton={statusButton}
                      setStatusButton={setStatusButton}
                    />
                  </BoxFormTitleAction>
                  <BoxGapInput>
                    <PaperWrapper props={{ growFull: 1 }}>
                      <TitleForm props={{ text: t("category") }} />
                      <Box p={2}>
                        <TextfieldComponent
                          title={t("category_name")}
                          name="mainCategory"
                          register={register}
                          placeholder={t("category_example")}
                          errors={errors}
                          fullGrid={true}
                        />
                      </Box>
                    </PaperWrapper>
                    <PaperWrapper props={{ growFull: 1 }}>
                      <SettingTypeBranch
                        title={t("category_branch")}
                        allDataTemplate={allDataTemplate}
                        setAllDataTemplate={setAllDataTemplate}
                        onAddTypeToTemplate={onAddTypeToTemplate}
                        directToBranch={"category"}
                      />
                    </PaperWrapper>

                    <ConfirmButton disabled={isSubmitting} />
                  </BoxGapInput>
                </PaperWrapper>
              </BoxWrapper>
            </form>
          </Box>
        </GridContainerSize>
      )}
    </Box>
  );
}

export default CategoryDetail;
