import React, { useEffect, useState } from "react";
import SelfPickupBankAccountForm from "./selfPickupBankAccountForm";
import { useForm } from "react-hook-form";
import BreadcrumbsTitle from "../../../components/ui/breadcrumbsTitle";
import { t } from "i18next";
import { Box } from "@mui/material";
import StatusForm from "../../../components/ui/formComponent/statusForm";
import ConfirmButton from "../../../components/ui/button/confirmButton";
import { notifySuccess } from "../../../components/ui/popup/toastPopup";
import { getBankAccount } from "../../../service/selfPickUp/bankaccount/getBankAccount";
import Loading from "../../../components/ui/loading";
import { updateBankAccount } from "../../../service/selfPickUp/bankaccount/updateBankAccount";
import { useNavigate } from "react-router-dom";

function SelfPickupBankAccountDetail() {
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm();
  const [statusButton, setStatusButton] = useState(false);
  const [promptPay, setPromptPay] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const paramsId =
    window.location.pathname.split("/")[
      window.location.pathname.split("/").length - 1
    ];

  const onConfirmForm = async (register) => {
    let { accountName, bankName, branchName, accountNo } = register;
    setIsSubmitting(true);
    let dataAPI = {
      id: String(paramsId),
      account_name: accountName,
      bank_name: bankName,
      branch_name: branchName,
      account_no: accountNo,
      image: "",
      promptpay: promptPay,
      status: statusButton,
    };
    let statusAPI = await updateBankAccount(dataAPI);
    if (statusAPI === "OK") {
      notifySuccess({
        title: t("toast_successfully_added_information"),
      });
      navigate("/self-pickup-admin-bank-account");
    }
    await new Promise((resolve) => setTimeout(resolve, 2000));
    setIsSubmitting(false);
  };
  const fetchData = async () => {
    let dataAPI = await getBankAccount(paramsId);
    let {
      account_name,
      bank_name,
      branch_name,
      account_no,
      status,
      promptpay,
    } = dataAPI;
    setValue("accountName", account_name);
    setValue("bankName", bank_name);
    setValue("branchName", branch_name);
    setValue("accountNo", account_no);
    setValue("promptpay", promptpay);
    // setImage(image);
    setIsLoading(false);
    setStatusButton(status);
  };
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Box>
      <BreadcrumbsTitle
        props={{
          title: t("self_pickup_admin_bank_account"),
          dataLink: [
            {
              text: t("self_pickup_admin_bank_account"),
              link: "/self-pickup-admin-bank-account",
            },
            { text: t("self_pickup_admin_bank_account_create"), link: null },
          ],
        }}
      />
      {isLoading ? (
        <Loading />
      ) : (
        <Box p={2}>
          <form onSubmit={handleSubmit(onConfirmForm)}>
            <SelfPickupBankAccountForm
              props={{ register, errors, promptPay, setPromptPay }}
            />
            <StatusForm
              statusButton={statusButton}
              setStatusButton={setStatusButton}
            />
            <ConfirmButton disabled={isSubmitting} />
          </form>
        </Box>
      )}
    </Box>
  );
}

export default SelfPickupBankAccountDetail;
