import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import ConfirmButton from "../../../components/ui/button/confirmButton";
import CloseIcon from "@mui/icons-material/Close";
import { t } from "i18next";
import { BoxImage } from "../../../components/ui/boxImage/boxImage";

function SelfPickupSaleProductUpdate({ props }) {
  let {
    openDialogUpdate,
    onCloseOpenDialogUpdate,
    onUpdateData,
    dataUpdate,
    setDataUpdate,
  } = props;

  return (
    <Dialog open={openDialogUpdate}>
      <DialogTitle sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
          {t("self_pickup_setup_update_sale_product")}
        </Typography>
        <IconButton onClick={() => onCloseOpenDialogUpdate()}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box
          sx={(theme) => ({
            ":hover": {
              opacity: 0.8,
              cursor: "pointer",
            },
          })}
          align="center"
        >
          <BoxImage
            image={dataUpdate.data.image}
            height={"256px"}
            type="table"
          />
        </Box>
        <Box sx={{ mt: 2 }} align="end">
          <TextField
            fullWidth
            placeholder="10"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">pcs.</InputAdornment>
              ),
            }}
            value={dataUpdate.amount}
            onChange={(e) =>
              setDataUpdate({ ...dataUpdate, amount: Number(e.target.value) })
            }
            size="small"
          />
          <Box>
            <FormControlLabel
              label={t("global_limit")}
              control={<Switch checked={dataUpdate.limit} disabled={true} />}
            />
          </Box>

          <Box>
            <FormControlLabel
              label={t("global_status")}
              onChange={(e) =>
                setDataUpdate({ ...dataUpdate, status: e.target.checked })
              }
              control={<Switch checked={dataUpdate.status} />}
            />
          </Box>
        </Box>
        <ConfirmButton onClick={() => onUpdateData()} />
      </DialogContent>
    </Dialog>
  );
}

export default SelfPickupSaleProductUpdate;
