import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  Switch,
  Tab,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  configGetAllCategory,
  configSortMainCategory,
  configUpdateMainCategory,
  // configUpdateMinorCategory,
} from "../../../../service/branchConfigAPI/branchConfigCategory";
import {
  configGetAllSaleProduct,
  configUpdateAllSaleProduct,
} from "../../../../service/branchConfigAPI/branchConfigSaleProduct";
import { ActionBtn } from "../../../../components/ui/dataTable/components/actionBtn";
import Loading from "../../../../components/ui/loading";
import {
  notifyDefault,
  notifySuccess,
} from "../../../../components/ui/popup/toastPopup";
import { configSortSaleProduct } from "../../../../service/branchConfigAPI/branchConfigSaleProduct";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { useTranslation } from "react-i18next";
import { BrowserView, isBrowser } from "react-device-detect";
import EmptyDataDirect from "../../../../components/ui/emptyDataDirect";
import Edit from "@mui/icons-material/Edit";
import DataNotFound from "../../../../components/ui/dataNotFound";
import { getImage } from "../../../../service/upload/getImage";
import { UseConvertImageToBase64 } from "../../../../components/hooks/use-convert-image-to-base-64";
import Check from "@mui/icons-material/Check";
import Swal from "sweetalert2";
import { MyContext } from "../../../../context/MyContext";
import { BoxImageTable } from "../../../../components/ui/boxImage/boxImageTable";

function BranchSaleProduct(props) {
  let { currencyProduct, templateId } = props;
  const {
    dataBranchSaleProduct,
    setDataBranchSaleProduct,
    setProgress,
    setTitleProgress,
  } = useContext(MyContext);

  const [dataCategoryAll, setDataCategoryAll] = useState([]);
  const [statusDragMain, setStatusDragMain] = useState(false);
  const [statusDragMinor, setStatusDragMinor] = useState(false);
  const [statusDragSaleProduct, setStatusDragSaleProduct] = useState(false);
  const [data, setData] = useState([]);
  const [isLoadingDrag, setIsLoadingDrag] = useState(false);
  const [valueMain, setValueMain] = useState("1");
  const [valueMinor, setValueMinor] = useState("1");
  const [isLoading, setIsLoading] = useState(true);
  const [checkAllSaleProducts, setCheckAllSaleProducts] = useState(false);
  const [checkAllCategory, setCheckAllCategory] = useState(false);
  const [saleProductAll, setSaleProductAll] = useState([]);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const fetchData = async () => {
    await fetchSaleProduct();
  };

  const onConfirmStatusChangeCategory = async (e) => {
    let checked = e.target.checked;
    Swal.fire({
      title: t("notification_confirm_change_status"),
      text: t(
        "notification_changing_the_status_will_affect_the_branch_settings"
      ),
      icon: "warning",
      showCancelButton: true,
    }).then((result) => {
      let count = 0;
      if (result.isConfirmed) {
        setCheckAllCategory(checked);
        dataCategoryAll.forEach(async (itemCate, index) => {
          setTimeout(async () => {
            itemCate.status = checked;
            let dataAPI = {
              template_id: String(templateId),
              main_category_id: itemCate.main_category_id,
              priority: itemCate.priority,
              status: checked,
            };
            await configUpdateMainCategory(dataAPI);
            count++;
            if (count === dataCategoryAll.length) {
              if (checked) {
                notifySuccess({ title: t("global_active") });
              } else {
                notifyDefault({ title: t("global_in_active") });
              }
              fetchSaleProduct();
            }
          }, 300 * index);
        });
        //sss
      }
    });
  };
  const onConfirmStatusChangeSaleProduct = async (e) => {
    let checked = e.target.checked;
    Swal.fire({
      title: t("notification_confirm_change_status"),
      text: t(
        "notification_changing_the_status_will_affect_the_branch_settings"
      ),
      icon: "warning",
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        setCheckAllSaleProducts(checked);
        setTitleProgress(t("notification_loading_update_sale_product"));
        let countRound = 0;
        saleProductAll.forEach(async (itemProduct, indexProduct) => {
          setTimeout(async () => {
            countRound++;
            if (itemProduct.status === checked) {
              setProgress((prevProgress) =>
                prevProgress >= 100
                  ? 0
                  : prevProgress + 100 / saleProductAll.length
              );
            } else {
              itemProduct.status = checked;
              let dataAPI = {
                template_id: String(templateId),
                sale_product_id: itemProduct.sale_product_id,
                priority: itemProduct.priority,
                status: checked,
              };
              let dataStatus = await configUpdateAllSaleProduct(dataAPI);
              if (dataStatus === "OK") {
                setProgress((prevProgress) =>
                  prevProgress >= 100
                    ? 0
                    : prevProgress + 100 / saleProductAll.length
                );
              }

              if (countRound === saleProductAll.length) {
                let dataNews = [...data];
                dataNews.forEach(async (mainCate, indexMain) => {
                  mainCate.minor_category.forEach((minorCate, indexMinor) => {
                    minorCate.saleProduct.forEach(
                      async (item, indexProduct) => {
                        item.status = checked;
                      }
                    );
                  });
                });
                setDataBranchSaleProduct(dataNews);
                setProgress(0);
                setTitleProgress("");

                if (checked) {
                  notifySuccess({ title: t("global_active") });
                } else {
                  notifyDefault({ title: t("global_in_active") });
                }
              }
            }
          }, 300 * indexProduct);
        });
      }
    });
  };

  const fetchDataImage = async (dataNews, productAPI) => {
    const promises = productAPI.map(async (item) => {
      let imageUrl = await getImage(item.sale_product.image);
      item.sale_product.image = UseConvertImageToBase64(imageUrl);
    });
    await Promise.all(promises);
    dataNews.push({ template_id: templateId, data: productAPI });
    setDataBranchSaleProduct(dataNews);
  };

  const fetchSaleProduct = async (cateAPI) => {
    let cateProduct = [];
    if (cateAPI) {
      cateProduct = cateAPI;
    } else {
      cateProduct = await configGetAllCategory(templateId);
    }
    cateProduct = cateProduct.filter(
      (item) => item.main_category.status === true
    );
    if (cateProduct.every((item) => item.status === true)) {
      setCheckAllCategory(true);
    } else {
      setCheckAllCategory(false);
    }
    setDataCategoryAll(cateProduct);
    let productAPI = [];
    let dataNews = [...dataBranchSaleProduct];
    if (
      dataNews.find((item) => item.template_id === templateId) === undefined
    ) {
      productAPI = await configGetAllSaleProduct(templateId);
      fetchDataImage(dataNews, productAPI);
    } else {
      productAPI = dataNews.find(
        (item) => item.template_id === templateId
      ).data;
    }
    if (productAPI.every((item) => item.status === true)) {
      setCheckAllSaleProducts(true);
    } else {
      setCheckAllSaleProducts(false);
    }
    setSaleProductAll(productAPI);
    let mainBox = [];
    if (productAPI.length === 0) {
      setData(mainBox);
      setIsLoading(false);
      setIsLoadingDrag(false);
    } else {
      productAPI.sort((item1, item2) => item1.priority - item2.priority);
      cateProduct.forEach((mainCate, index) => {
        let minorCate = [];
        let listProductFilter = productAPI.filter(
          (product) =>
            product.sale_product.main_categories[0].id ===
            mainCate.main_category_id
        );
        minorCate.push({
          minor: {
            name: "All",
          },
          saleProduct: listProductFilter,
        });
        if (mainCate.main_category.minor_categories.length > 0) {
          mainCate.main_category.minor_categories.forEach((subCate) => {
            let listSaleProduct = [];
            listProductFilter.forEach((itemSaleProduct) => {
              if (itemSaleProduct.sale_product.minor_categories.length > 0) {
                if (
                  itemSaleProduct.sale_product.minor_categories[0].id ===
                  subCate.id
                ) {
                  listSaleProduct.push(itemSaleProduct);
                }
              }
            });
            minorCate.push({
              minor: {
                name: subCate.minor_category_lang.name,
              },
              saleProduct: listSaleProduct,
            });
          });
        }
        mainBox.push({
          main_category: mainCate,
          minor_category: minorCate,
        });
      });
      setData(mainBox);
      setIsLoading(false);
      setIsLoadingDrag(false);
    }
  };
  // Category
  const updateMainConfigCategory = async (itemMain, e, index) => {
    let dataNews = [...dataCategoryAll];
    dataNews[index].status = e.target.checked;
    setDataCategoryAll(dataNews);
    let dataAPI = {
      template_id: String(templateId),
      main_category_id: itemMain.main_category_id,
      priority: itemMain.priority,
      status: e.target.checked,
    };
    let dataStatus = await configUpdateMainCategory(dataAPI);
    if (dataStatus === "OK") {
      if (e.target.checked) {
        notifySuccess({ title: t("global_active") });
      } else {
        notifyDefault({ title: t("global_in_active") });
      }
      fetchSaleProduct(dataNews);
    }
  };
  let todoItemMainDrag = useRef();
  let todoItemMainDragOver = useRef();
  const onDragStartMainCategory = async (e, index) => {
    todoItemMainDrag.current = index;
  };
  const onEnterStartMainCategory = async (e, index) => {
    todoItemMainDragOver.current = index;
    let cpArr = [...dataCategoryAll];
    cpArr.forEach((item) => (item.draggable = false));
    cpArr[index].draggable = true;
    setDataCategoryAll(cpArr);
  };
  const onDragEndStartMainCategory = async (e, index) => {
    let arr1 = [...dataCategoryAll];
    let todo_item_main = arr1[todoItemMainDrag.current];
    arr1.splice(todoItemMainDrag.current, 1);
    arr1.splice(todoItemMainDragOver.current, 0, todo_item_main);

    todoItemMainDrag.current = null;
    todoItemMainDragOver.current = null;

    arr1.forEach((item) => (item.draggable = false));
    setDataCategoryAll(arr1);
    setIsLoadingDrag(true);
    let boxSortMainCategory = [];
    arr1.forEach(async (itemCate, index) => {
      boxSortMainCategory.push(itemCate.main_category_id);
    });
    let uniqueArr = Array.from(new Set(boxSortMainCategory));

    let dataAPI = {
      template_id: String(templateId),
      main_categories: uniqueArr,
    };
    let dataSortStatus = await configSortMainCategory(dataAPI);
    if (dataSortStatus === "OK") {
      notifySuccess({ title: t("notification_successfully_sequence") });
      setIsLoadingDrag(false);
      fetchData();
    }
  };
  const onCloseDragStatus = () => {
    setStatusDragMain(false);
    setStatusDragMinor(false);
  };

  const handleChangeMain = (event, newValue) => {
    setValueMain(newValue);
    setValueMinor("1");
  };
  const handleChangeMinor = (event, newValue) => {
    setValueMinor(newValue);
  };
  // Category

  // SaleProduct
  let todoItemDrag = useRef();
  let todoItemDragOver = useRef();

  function D_Start(e, index) {
    todoItemDrag.current = index;
  }
  function D_Enter(e, index) {
    todoItemDragOver.current = index;
    let cpArr = [...data];
    cpArr[valueMain - 1].minor_category[valueMinor - 1].saleProduct.forEach(
      (item) => (item.draggable = false)
    );
    cpArr[valueMain - 1].minor_category[valueMinor - 1].saleProduct[
      index
    ].draggable = true;
    setData(cpArr);
  }
  const D_End = async (e, index, typeDrag) => {
    let arr1 = [...data];
    let todo_item_main =
      arr1[valueMain - 1].minor_category[valueMinor - 1].saleProduct[
        todoItemDrag.current
      ];
    arr1[valueMain - 1].minor_category[valueMinor - 1].saleProduct.splice(
      todoItemDrag.current,
      1
    );
    arr1[valueMain - 1].minor_category[valueMinor - 1].saleProduct.splice(
      todoItemDragOver.current,
      0,
      todo_item_main
    );

    todoItemDrag.current = null;
    todoItemDragOver.current = null;

    arr1[valueMain - 1].minor_category[valueMinor - 1].saleProduct.forEach(
      (item) => (item.draggable = false)
    );
    setData(arr1);

    // setIsLoading(true);
    setIsLoadingDrag(true);
    let boxSaleProductUpdate = [];
    arr1.forEach((itemMain) =>
      itemMain.minor_category.forEach((itemMinor) =>
        itemMinor.saleProduct.forEach(async (product, index) => {
          boxSaleProductUpdate.push(product.id);
        })
      )
    );
    let uniqueArr = [];
    uniqueArr = Array.from(new Set(boxSaleProductUpdate));

    if (boxSaleProductUpdate) {
      let dataAPI = {
        template_id: String(templateId),
        sale_products: uniqueArr,
      };
      let dataSortStatus = await configSortSaleProduct(dataAPI);
      if (dataSortStatus === "OK") {
        notifySuccess({ title: t("notification_successfully_sequence") });
        setIsLoadingDrag(false);
      }
    }
  };
  const onUpdateStatus = async (item, e, index) => {
    let dataNews = [...data];
    dataNews[valueMain - 1].minor_category[valueMinor - 1].saleProduct[
      index
    ].status = e.target.checked;
    setData(dataNews);
    setDataBranchSaleProduct(dataNews);
    let dataAPI = {
      template_id: String(templateId),
      sale_product_id: item.sale_product_id,
      priority: item.priority,
      status: e.target.checked,
    };
    let dataStatus = await configUpdateAllSaleProduct(dataAPI);
    if (dataStatus === "OK") {
      if (e.target.checked) {
        notifySuccess({
          title: `${t("toast_active")}  ${
            item.sale_product.sale_product_lang.name
          }`,
        });
      } else {
        notifyDefault({
          title: `${t("toast_in_active")}  ${
            item.sale_product.sale_product_lang.name
          }`,
        });
      }
    }
  };
  // SaleProduct
  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <Box>
      {isLoading ? (
        <>
          <Loading />
        </>
      ) : (
        <>
          <Box>
            {dataCategoryAll.length > 0 ? (
              <>
                <Grid container justifyContent={"space-between"}>
                  <Typography
                    sx={(theme) => ({
                      mb: 1,
                      fontSize: 16,
                      color: theme.palette.primaryText.main,
                      fontWeight: 400,
                    })}
                  >
                    {t("category")}
                  </Typography>
                  <Box
                    sx={{
                      mb: 2,
                    }}
                  >
                    {isBrowser ? (
                      <Box
                        sx={{ display: "flex", gap: 2, alignItems: "center" }}
                      >
                        <Button
                          variant="outlined"
                          size="small"
                          onClick={() => setStatusDragMain(true)}
                          startIcon={<Edit />}
                          disabled={statusDragMain}
                          sx={{ borderRadius: 2 }}
                        >
                          {t("category_sequence")}
                        </Button>
                        {statusDragMain || statusDragMinor ? (
                          <Button
                            variant="contained"
                            size="small"
                            onClick={() => onCloseDragStatus()}
                            startIcon={<Check />}
                            sx={{ borderRadius: 2 }}
                          >
                            {t("finish_button")}
                          </Button>
                        ) : null}
                        <FormHelperText sx={{}}>
                          {t("global_drag_for_sequence")}
                        </FormHelperText>
                      </Box>
                    ) : null}
                  </Box>
                  <Grid item xs={12} align="end">
                    <FormControlLabel
                      control={<Switch checked={checkAllCategory} />}
                      label={t("button_check_all")}
                      onChange={onConfirmStatusChangeCategory}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container justifyContent={"flex-start"} spacing={2}>
                      {dataCategoryAll
                        .sort((a, b) => a.priority - b.priority)
                        .map((itemMain, indexMain) => (
                          <Grid
                            item
                            xs={12}
                            sm={4}
                            md={6}
                            lg={2.5}
                            sx={(theme) => ({
                              boxSizing: "border-box",
                              borderLeft: itemMain.draggable
                                ? `5px dashed ${theme.palette.primary.main}`
                                : null,
                              pl: itemMain.draggable ? -2 : 0,
                            })}
                            key={indexMain}
                          >
                            <Box>
                              {isLoadingDrag ? (
                                <Box
                                  sx={{
                                    position: "absolute",
                                    transform: "translate(-50%,-50%)",
                                    left: "50%",
                                    top: "45%",
                                    zIndex: 99,
                                  }}
                                >
                                  <Loading />
                                </Box>
                              ) : null}
                              <Box>
                                <Box
                                  sx={(theme) => ({
                                    boxShadow: 2,
                                    px: 4,
                                    // mt: 2,
                                    borderRadius: 1,
                                    backgroundColor: theme.palette.white.main,
                                    color: theme.palette.primaryText.main,
                                    ":hover": {
                                      cursor: statusDragMain ? "grab" : null,
                                      opacity: statusDragMain ? 0.5 : null,
                                    },
                                  })}
                                  draggable={statusDragMain}
                                  droppable={statusDragMain}
                                  onDragStart={(e) =>
                                    statusDragMain
                                      ? onDragStartMainCategory(e, indexMain)
                                      : null
                                  }
                                  onDragEnter={(e) =>
                                    statusDragMain
                                      ? onEnterStartMainCategory(e, indexMain)
                                      : null
                                  }
                                  onDragEnd={(e) =>
                                    statusDragMain
                                      ? onDragEndStartMainCategory(e, indexMain)
                                      : null
                                  }
                                >
                                  {isBrowser && statusDragMain ? (
                                    <Box
                                      sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        transform: "rotate(-90deg)",
                                        ":hover": { cursor: "grab" },
                                      }}
                                    >
                                      <DragIndicatorIcon className="itemPopUp" />
                                    </Box>
                                  ) : null}
                                  {itemMain.main_category.status === false ? (
                                    <FormControlLabel
                                      sx={{
                                        height: "40px",
                                      }}
                                      label={
                                        <Typography
                                          sx={(theme) => ({
                                            textOverflow: "ellipsis",
                                            overflow: "hidden",
                                            whiteSpace: "nowrap",
                                            width: "100%",
                                            color:
                                              theme.palette.primaryText.main,
                                          })}
                                        >
                                          {itemMain.main_category
                                            .main_category_lang
                                            ? itemMain.main_category
                                                .main_category_lang.name
                                            : t("global_data_not_found")}
                                        </Typography>
                                      }
                                      control={
                                        <Switch
                                          disabled={
                                            itemMain.main_category.status ===
                                            false
                                          }
                                        />
                                      }
                                    ></FormControlLabel>
                                  ) : (
                                    <ActionBtn
                                      label={
                                        <Typography
                                          sx={(theme) => ({
                                            textOverflow: "ellipsis",
                                            overflow: "hidden",
                                            whiteSpace: "nowrap",
                                            width: "100%",
                                          })}
                                        >
                                          {itemMain.main_category
                                            .main_category_lang
                                            ? itemMain.main_category
                                                .main_category_lang.name
                                            : t("global_data_not_found")}
                                        </Typography>
                                      }
                                      row={itemMain}
                                      status={itemMain.status}
                                      index={indexMain}
                                      onUpdateStatus={updateMainConfigCategory}
                                    />
                                  )}
                                </Box>
                              </Box>
                            </Box>
                          </Grid>
                        ))}
                    </Grid>
                  </Grid>
                </Grid>
              </>
            ) : (
              <Box>
                {dataCategoryAll.length === 0 ? (
                  <EmptyDataDirect
                    props={{ url: "/category", text: t("category") }}
                  />
                ) : null}
              </Box>
            )}
          </Box>
          <Divider fullWidth sx={{ mt: 4, mb: 2 }} />
          {data.length > 0 ? (
            <>
              <Grid container justifyContent={"space-between"}>
                <Typography
                  sx={(theme) => ({
                    mb: 1,
                    fontSize: 16,
                    color: theme.palette.primaryText.main,
                    fontWeight: 400,
                  })}
                >
                  {t("sale_product")}
                </Typography>
                <BrowserView>
                  {isBrowser ? (
                    <Box
                      sx={{
                        display: "flex",
                        gap: 2,
                        alignItems: "center",
                        mb: 2,
                      }}
                    >
                      <Button
                        variant="outlined"
                        size="small"
                        onClick={() => setStatusDragSaleProduct(true)}
                        sx={{ borderRadius: 2 }}
                        disabled={statusDragSaleProduct}
                        startIcon={<Edit />}
                      >
                        {t("sale_product_sequence")}
                      </Button>

                      {statusDragSaleProduct ? (
                        <Button
                          sx={{ borderRadius: 2 }}
                          variant="contained"
                          size="small"
                          onClick={() => setStatusDragSaleProduct(false)}
                        >
                          {t("finish_button")}
                        </Button>
                      ) : null}
                      <FormHelperText sx={{}}>
                        {t("global_drag_for_sequence")}
                      </FormHelperText>
                    </Box>
                  ) : null}
                </BrowserView>
                <Grid item xs={12} align="end">
                  <FormControlLabel
                    control={<Switch checked={checkAllSaleProducts} />}
                    label={t("button_check_all")}
                    onChange={onConfirmStatusChangeSaleProduct}
                  />
                </Grid>

                <Grid item xs={12} className="grid-paper-white">
                  <Box sx={{ width: "100%", typography: "body1" }}>
                    <TabContext value={String(valueMain)}>
                      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <TabList
                          onChange={handleChangeMain}
                          aria-label="lab API tabs example"
                          variant="scrollable"
                        >
                          {data
                            .sort(
                              (a, b) =>
                                a.main_category.priority -
                                b.main_category.priority
                            )
                            .map((item, index) => (
                              <Tab
                                sx={{ width: "150px" }}
                                key={index}
                                label={
                                  <Typography
                                    sx={{
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      width: "100%",
                                    }}
                                  >
                                    {
                                      item.main_category.main_category
                                        .main_category_lang.name
                                    }
                                  </Typography>
                                }
                                disabled={item.main_category.status === false}
                                value={String(index + 1)}
                              />
                            ))}
                        </TabList>
                      </Box>
                      <TabPanel value={String(valueMain)} sx={{ pl: 2 }}>
                        <TabContext value={String(valueMinor)}>
                          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                            <TabList
                              onChange={handleChangeMinor}
                              aria-label="lab API tabs example"
                              variant="scrollable"
                            >
                              {data[valueMain - 1].minor_category.map(
                                (item, index) => (
                                  <Tab
                                    sx={{ width: "150px" }}
                                    key={index}
                                    label={
                                      <Typography
                                        sx={{
                                          whiteSpace: "nowrap",
                                          overflow: "hidden",
                                          width: "100%",
                                        }}
                                      >
                                        {item.minor
                                          ? item.minor.name
                                          : t("all")}
                                      </Typography>
                                    }
                                    disabled={
                                      data[valueMain - 1].main_category
                                        .status === false
                                    }
                                    value={String(index + 1)}
                                  />
                                )
                              )}
                            </TabList>
                          </Box>
                          <TabPanel value={String(valueMinor)} sx={{ p: 0 }}>
                            <Grid
                              container
                              justifyContent={"flex-start"}
                              sx={{ p: 2, boxSizing: "border-box" }}
                            >
                              {data[valueMain - 1].minor_category[
                                valueMinor - 1
                              ].saleProduct.length === 0 ? (
                                <>
                                  <DataNotFound />
                                </>
                              ) : null}
                              {data[valueMain - 1].minor_category[
                                valueMinor - 1
                              ].saleProduct.map((product, index) => (
                                <React.Fragment key={index}>
                                  <Grid
                                    item
                                    xs={12}
                                    sm={4}
                                    md={4}
                                    lg={3}
                                    xl={2}
                                    draggable={statusDragSaleProduct}
                                    droppable={statusDragSaleProduct}
                                    onDragStart={(e) =>
                                      statusDragSaleProduct
                                        ? D_Start(e, index)
                                        : null
                                    }
                                    onDragEnter={(e) =>
                                      statusDragSaleProduct
                                        ? D_Enter(e, index)
                                        : null
                                    }
                                    onDragEnd={(e) =>
                                      statusDragSaleProduct
                                        ? D_End(e, index)
                                        : null
                                    }
                                    sx={(theme) => ({
                                      p: 1,
                                      boxSizing: "border-box",
                                    })}
                                    mb={1}
                                  >
                                    <Box
                                      sx={(theme) => ({
                                        transition: "all 0.2s ease-in-out",
                                        borderLeft: product.draggable
                                          ? `5px dashed ${theme.palette.primary.main}`
                                          : null,
                                        pl: product.draggable ? -2 : 0,
                                        opacity: product.draggable ? 0.5 : 1,
                                        position: "relative",
                                        boxShadow: 2,
                                        borderRadius: 1,
                                        px: 2,
                                        pt: statusDragSaleProduct ? 0 : 2,
                                        boxSizing: "border-box",
                                        backgroundColor:
                                          theme.palette.white.main,
                                        ":hover": {
                                          cursor: statusDragSaleProduct
                                            ? "grab"
                                            : null,
                                          opacity: statusDragSaleProduct
                                            ? 0.5
                                            : null,
                                        },
                                      })}
                                    >
                                      {statusDragSaleProduct ? (
                                        <BrowserView>
                                          <Box
                                            sx={{
                                              display: "flex",
                                              justifyContent: "center",
                                              transform: "rotate(-90deg)",
                                              ":hover": { cursor: "grab" },
                                            }}
                                          >
                                            <DragIndicatorIcon />
                                          </Box>
                                        </BrowserView>
                                      ) : (
                                        <></>
                                      )}
                                      {isLoadingDrag ? (
                                        <Box
                                          sx={{
                                            position: "absolute",
                                            transform: "translate(-50%,-50%)",
                                            left: "50%",
                                            top: "45%",
                                            zIndex: 99,
                                          }}
                                        >
                                          <Loading />
                                        </Box>
                                      ) : null}
                                      <Box
                                        sx={{
                                          display: "block",
                                          opacity: isLoadingDrag ? 0.4 : null,
                                          filter:
                                            product.status === false
                                              ? "saturate(0)"
                                              : "saturate(1)",
                                        }}
                                      >
                                        <BoxImageTable
                                          image={product.sale_product.image}
                                          type="table"
                                          width={"100%"}
                                          height={"256px"}
                                        />
                                      </Box>
                                      <Box
                                        sx={(theme) => ({
                                          zIndex: 99,
                                          bottom: 0,
                                          backgroundColor:
                                            theme.palette.primary.main,
                                          color: "#fff",
                                          width: "100%",
                                        })}
                                      >
                                        <Box
                                          sx={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            p: 1,
                                          }}
                                        >
                                          <Typography noWrap>
                                            {product.sale_product
                                              .sale_product_lang
                                              ? product.sale_product
                                                  .sale_product_lang.name
                                              : t("global_data_not_found")}
                                          </Typography>
                                          <Box
                                            sx={{
                                              display: "flex",
                                              justifyContent: "space-between",
                                              gap: 1,
                                            }}
                                          >
                                            <Typography>
                                              {product.sale_product.price}
                                            </Typography>
                                            <Typography>
                                              {currencyProduct}
                                            </Typography>
                                          </Box>
                                        </Box>
                                      </Box>
                                      <Box
                                        sx={(theme) => ({
                                          py: 2,
                                          boxSizing: "border-box",
                                          display: "flex",
                                          justifyContent: "space-between",
                                          borderRadius: 1,
                                          backgroundColor:
                                            theme.palette.white.main,
                                        })}
                                      >
                                        <IconButton
                                          onClick={() =>
                                            navigate(
                                              "/sale-product/detail/" +
                                                product.id
                                            )
                                          }
                                        >
                                          <Edit />
                                        </IconButton>
                                        <ActionBtn
                                          row={product}
                                          status={product.status}
                                          onUpdateStatus={onUpdateStatus}
                                          index={index}
                                          disabled={
                                            product.sale_product.status ===
                                              false ||
                                            data[valueMain - 1].main_category
                                              .status === false
                                          }
                                          label={
                                            product.status
                                              ? t("toast_active")
                                              : t("toast_in_active")
                                          }
                                        />
                                      </Box>
                                    </Box>
                                  </Grid>
                                </React.Fragment>
                              ))}
                            </Grid>
                          </TabPanel>
                        </TabContext>
                      </TabPanel>
                    </TabContext>
                  </Box>
                </Grid>
              </Grid>
            </>
          ) : (
            <Box>
              {data.length === 0 ? (
                <EmptyDataDirect
                  props={{ url: "/sale-product", text: t("sale_product") }}
                />
              ) : null}
            </Box>
          )}
        </>
      )}
    </Box>
  );
}

export default BranchSaleProduct;
