import React, { useEffect, useState } from "react";
import { Box, Button, Skeleton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import "react-lazy-load-image-component/src/effects/blur.css";
import imageNotFound from "../../../assets/images/imageNotFound.jpeg";
import { t } from "i18next";
export const BoxImageTable = (props) => {
  const {
    image,
    iconUpload,
    setImage,
    setImageUpload,
    height,
    width,
    type,
    borderRadius,
  } = props;
  const [loaded, setLoaded] = useState(false);
  const [imageShow, setImageShow] = useState(null);
  const loadImage = async (image) => {
    try {
      if (!image) {
        return imageNotFound;
      }

      if (typeof image === "string") {
        if (image.includes("not found")) {
          return imageNotFound;
        } else {
          return image;
        }
      } else if (typeof image === "object") {
        return URL.createObjectURL(image);
      }

      return "";
    } catch (error) {
      console.error("Error loading image:", error);
      return imageNotFound;
    }
  };
  useEffect(() => {
    const loadImageAsync = async () => {
      const loadedImage = await loadImage(image);
      setImageShow(loadedImage);
      setLoaded(true);
    };
    loadImageAsync();
  }, [image]);

  const onClearImage = () => {
    setImageUpload();
    setImage();
  };

  const handleLoad = () => {
    setLoaded(true);
  };

  if (image === null) {
    return (
      <img
        src={imageNotFound}
        onLoad={handleLoad}
        effect="blur"
        alt={"spos_business_picture"}
        style={
          type === "table"
            ? {
                maxHeight: height ? height : "256px",
                height: "100%",
                maxWidth: width ? width : "256px",
                width: "100%",
                borderRadius: borderRadius ? "8px" : null,
                objectFit: "cover",
                display: "block",
                opacity: !loaded ? 0 : 1,
              }
            : {
                height: height ? height : "256px",
                // height: "100%",
                width: width ? width : "256px",
                // width: "100%",
                borderRadius: borderRadius ? "8px" : null,
                objectFit: "cover",
                display: "block",
                opacity: !loaded ? 0 : 1,
              }
        }
      />
    );
  }
  return (
    <Box
      sx={(theme) => ({
        position: "relative",
      })}
    >
      {!loaded ? (
        <Skeleton
          sx={{
            borderRadius: "8px",
          }}
          variant="rectangular"
          width={width ? width : "100%"}
          height={height ? height : "100%"}
        />
      ) : (
        <>
          {!image.includes("data:image/") ? (
            <Skeleton
              sx={{
                borderRadius: "8px",
              }}
              variant="rectangular"
              width={width ? width : "100%"}
              height={height ? height : "100%"}
            />
          ) : (
            <img
              src={imageShow}
              onLoad={handleLoad}
              effect="blur"
              alt={"spos_business_picture"}
              style={
                type === "table"
                  ? {
                      maxHeight: height ? height : "256px",
                      height: "100%",
                      maxWidth: width ? width : "256px",
                      width: "100%",
                      borderRadius: borderRadius ? "8px" : null,
                      objectFit: "cover",
                      display: "block",
                      opacity: !loaded ? 0 : 1,
                    }
                  : {
                      height: height ? height : "256px",
                      // height: "100%",
                      width: width ? width : "256px",
                      // width: "100%",
                      borderRadius: borderRadius ? "8px" : null,
                      objectFit: "cover",
                      display: "block",
                      opacity: !loaded ? 0 : 1,
                    }
              }
            />
          )}
        </>
      )}

      {iconUpload && loaded ? (
        <>
          <Button
            startIcon={<DeleteIcon />}
            variant="outlined"
            onClick={() => onClearImage()}
            sx={{ mt: 2, borderRadius: 2 }}
          >
            {t("button_remove_image")}
          </Button>
        </>
      ) : null}
    </Box>
  );
};
