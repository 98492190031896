import axios from "axios";
import {
  LanguageForm,
  AccessTokenAPI,
  BusinessId,
  CompanyId,
  ServiceId,
} from "../../getLocalStorage";

export const storeBankAccount = async (dataAPI) => {
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: ` ${process.env.REACT_APP_API_URL}/api/setting/selfpickup/storeBankAccount?language_code=${LanguageForm}&company_uid=${CompanyId}&business_uid=${BusinessId}&service_code=${ServiceId}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${AccessTokenAPI}`,
    },
    data: JSON.stringify(dataAPI),
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data.message;
    })
    .catch((error) => {
      console.log(error);
    });
};
