import { useTranslation } from "react-i18next";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import ReportByDateDrawer from "../../../pages/report/reportByDateDrawer/reportByDateDrawer";
import ReportByBill from "../../../pages/report/reportByBill/reportByBill";
import ReportByBillDetail from "../../../pages/report/reportByBillDetail/reportByBillDetail";
import ReportByPayment from "../../../pages/report/reportByPayments.js/reportByPayment";
import ReportBySaleProduct from "../../../pages/report/reportBySaleProduct/reportBySaleProduct";
import ReportByHourlyProduct from "../../../pages/report/reportByHourlyProduct/reportByHourlyProduct";
import ReportByMonthlyProduct from "../../../pages/report/reportByMonthlyProduct/reportByMonthlyProduct";
import ReportByPromotion from "../../../pages/report/branchReportPromotion/reportByPromotion";
import ReportByPromotionDetail from "../../../pages/report/reportByPromotionDetail/reportByPromotionDetail";
import ReportByMinorCategory from "../../../pages/report/reportByMinorCategory/reportByMinorCategory";
import ReportByCategory from "../../../pages/report/reportByCategory/reportByCategory";
import ReportByDateSummary from "../../../pages/report/reportByDateSummary/reportByDateSummary";
import ReportByPaymentChannel from "../../../pages/report/reportByPaymentChannel/reportByPaymentChannel";
export const ListReport = () => {
  const { t } = useTranslation();
  const listItem = [
    {
      name: t("report_sales"),
      exact: true,
      iconClassName: <AttachMoneyIcon sx={{ fontSize: 20 }} />,
      expanded: true,
      subMenus: [
        // {
        //   name: t("report_by_summary_branch"),
        //   component: <ReportBySummaryBranch />,
        //   to: "/report-by-summary-branch",
        // },
        {
          name: t("report_by_date_drawer"),
          component: <ReportByDateDrawer />,
          to: "/report-by-date-drawer",
        },
        {
          name: t("report_by_date_summary"),
          component: <ReportByDateSummary />,
          to: "/report-by-date-summary",
        },
        {
          name: t("report_by_bill"),
          component: <ReportByBill />,
          to: "/report-by-bills",
        },
        {
          name: t("report_by_bill_detail"),
          component: <ReportByBillDetail />,
          to: "/report-by-bill-detail",
        },
        // {
        //   name: t("report_by_channel_summary"),
        //   component: <ReportByDateDrawer />,
        //   to: "/report-by-channel-summary",
        // },
        {
          name: t("report_by_payment_type"),
          component: <ReportByPayment />,
          to: "/report-by-payment",
        },
        {
          name: t("report_by_payment_channel"),
          component: <ReportByPaymentChannel />,
          to: "/report-by-channel-payment",
        },
        {
          name: t("report_by_sale_product"),
          component: <ReportBySaleProduct />,
          to: "/report-by-product",
        },
        {
          name: t("report_by_hourly_product"),
          component: <ReportByHourlyProduct />,
          to: "/report-by-hourly-product",
        },
        {
          name: t("report_by_monthly_product"),
          component: <ReportByMonthlyProduct />,
          to: "/report-by-monthly-product",
        },
        {
          name: t("report_by_category"),
          component: <ReportByCategory />,
          to: "/report-by-category",
        },
        {
          name: t("report_by_minor_category"),
          component: <ReportByMinorCategory />,
          to: "/report-by-minor-category",
        },
        {
          name: t("report_by_promotion"),
          component: <ReportByPromotion />,
          to: "/report-by-promotion",
        },
        {
          name: t("report_by_promotion_detail"),
          component: <ReportByPromotionDetail />,
          to: "/report-by-detail-promotion",
        },
        // {
        //   name: t("report_by_group"),
        //   component: <ReportByDateDrawer />,
        //   to: "/report-by-group",
        // },
        // {
        //   name: t("report_by_option"),
        //   component: <ReportByDateDrawer />,
        //   to: "/report-by-option",
        // },
      ],
    },
  ];
  return listItem;
};
