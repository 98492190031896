import React, { useState, useEffect, useContext } from "react";
import { ActionDetail } from "../../components/ui/dataTable/components/actionDetail";
import { Box } from "@mui/material";
import { MomentConvert } from "../../components/ui/moment/momentConvert";
import DataMaterialTable from "../../components/ui/dataTable/dataMaterialTable";
import { useTranslation } from "react-i18next";
import { addSliderToTemplate, getAllSlider } from "../../service/sliderAPI";
import { BoxImageOutline } from "../../components/ui/boxImage/boxImageOutline";
import BreadcrumbsTitle from "../../components/ui/breadcrumbsTitle";
import { BoxImage } from "../../components/ui/boxImage/boxImage";
import StatusTable from "../../components/chip/statusTable";
import { TextTable } from "../../components/ui/textfield/textfieldCustom";
import { getImage } from "../../service/upload/getImage";
import { UseConvertImageToBase64 } from "../../components/hooks/use-convert-image-to-base-64";
import { notifySuccess } from "../../components/ui/popup/toastPopup";
import { MyContext } from "../../context/MyContext";
import dayjs from "dayjs";

function BackScreen() {
  const [rows, setRows] = useState([]);
  const { t } = useTranslation();
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [columns, setColumns] = useState([]);
  const [exportColumnName, setExportColumnName] = useState(null);
  const {
    backScreenAll,
    backScreenAllTable,
    setbackScreenAllTable,
    setProgress,
  } = useContext(MyContext);
  const fetchData = async () => {
    let dataColumns = [
      {
        accessorKey: "image",
        size: 100,
        enableSorting: false,
        header: t("global_image"),
        Cell: ({ row, cell }) => (
          <Box sx={{ display: "flex", gap: 1, overflow: "auto" }}>
            {cell.getValue().length > 0 ? (
              <>
                {cell.getValue().map((item) => (
                  <>
                    <BoxImage
                      image={item.url}
                      width={"50px"}
                      height={"50px"}
                      type="table"
                      borderRadius={true}
                    />
                  </>
                ))}
              </>
            ) : (
              <BoxImageOutline width={50} height={50} />
            )}
          </Box>
        ),
      },
      {
        accessorKey: "name",
        flexGrow: 1,
        header: t("global_name"),
        Cell: ({ cell }) => <TextTable props={{ text: cell.getValue() }} />,
      },
      {
        accessorKey: "update",
        size: 100,
        header: t("global_updated_at"),
        Cell: ({ cell }) => <TextTable props={{ text: cell.getValue() }} />,
      },
      {
        accessorKey: "status",
        enableSorting: false,
        size: 20,
        header: t("global_status"),

        Cell: ({ row }) => (
          <StatusTable props={{ status: row.original.status }} />
        ),
      },
      {
        accessorKey: "action",
        enableSorting: false,
        size: 100,
        header: "Action",
        Cell: ({ row }) => (
          <Box sx={{ display: "flex", gap: 2 }}>
            <ActionDetail params={row} detailPath={"/slider/detail/"} />
          </Box>
        ),
      },
    ];

    let dataAPI = [];
    if (backScreenAll) {
      dataAPI = backScreenAll;
    } else {
      dataAPI = await getAllSlider();
    }

    if (dataAPI) {
      let dataBox = [];
      if (backScreenAllTable.length === 0) {
        const promises = dataAPI.map(async (itemBox) => {
          await Promise.all(
            itemBox.slider_images.map(async (itemImage) => {
              let imageUrl = await getImage(itemImage.url);
              itemImage.url = UseConvertImageToBase64(imageUrl);
            })
          );
        });
        await Promise.all(promises);

        dataAPI
          .sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at))
          .map(async (item) => {
            dataBox.push({
              id: item.id,
              image: item.slider_images,
              name: item.name,
              update: MomentConvert(item.updated_at),
              status: item.status,
            });
          });

        setRows(dataBox);
        setbackScreenAllTable(dataBox);
      } else {
        dataBox = backScreenAllTable;
        setRows(dataBox);
      }

      //===== Start set header name export csv
      let headers = [];
      dataColumns.forEach((item) => {
        if (item.header !== "Image" && item.header !== "Action") {
          headers.push(item.header);
        }
      });
      const csvStringArray = [
        headers,
        ...dataBox.map((item) => [
          item.image,
          item.name,
          item.update,
          item.status,
        ]),
      ]
        .map((row) => row.join(","))
        .join("\n");

      setExportColumnName(csvStringArray);
      //===== End set header name export csv
      setColumns(dataColumns);
      setIsLoadingData(false);
    }
  };

  const onInsertToBranches = async (rowsData, branchSelect) => {
    let countRound = 0;
    let timeBoost = 0;
    const promises = rowsData.map(async (row, index) => {
      await new Promise((resolve) => {
        setTimeout(async () => {
          let startTime = performance.now();
          let dataAPI = {
            slider_id: String(row.original.id),
            branches: branchSelect,
            type: row.original.name,
          };
          let dataStatus = await addSliderToTemplate(dataAPI);
          if (dataStatus === "OK") {
            countRound++;
            setProgress((prevProgress) =>
              prevProgress >= 100 ? 0 : prevProgress + 100 / rowsData.length
            );
          } else {
            countRound++;
            setProgress((prevProgress) =>
              prevProgress >= 100 ? 0 : prevProgress + 100 / rowsData.length
            );
          }
          if (countRound === rowsData.length) {
            setProgress(0);
            notifySuccess({
              title: t("notification_successfully_insert_information"),
            });
            resolve(true);
          } else {
            resolve(false);
          }
          if (index === 0) {
            let endTime = performance.now();
            let processTime = endTime - startTime;
            timeBoost = processTime;
          }
        }, (timeBoost ? timeBoost + 200 : 1800) * index);
      });
    });
    await Promise.all(promises);
  };
  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <Box>
      <BreadcrumbsTitle
        props={{
          title: t("back_screen"),
        }}
      />
      <DataMaterialTable
        rows={rows}
        onInsertToBranch={onInsertToBranches}
        isLoadingData={isLoadingData}
        columns={columns}
        exportColumnName={exportColumnName}
        fileName={t("back_screen") + ` ${dayjs().format("DD/MM/YYYY")}`}
        enableSelection={true}
        detailPath={"/slider/detail/"}
        createButton={t("back_screen")}
        imageStatus={"multiple"}
        statusViewMode={false}
      />
    </Box>
  );
}

export default BackScreen;
