import axios from "axios";
import { expiredToken } from "./expiredToken";
import {
  AccessTokenAPI,
  BusinessId,
  CompanyId,
  LanguageForm,
  ServiceId,
} from "./getLocalStorage";

export const getAllMinorCategory = () => {
  let config = {
    method: "get",
    url: `${process.env.REACT_APP_API_URL}/api/setting/getAllMinorCategory?language_code=${LanguageForm}&company_uid=${CompanyId}&business_uid=${BusinessId}&service_code=${ServiceId}`,
    headers: {
      Authorization: `Bearer ${AccessTokenAPI}`,
    },
  };

  return axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      console.log(error);
      expiredToken(error);
    });
};
export const storeMinorCategory = (dataAPI) => {
  let config = {
    method: "post",
    url: `${process.env.REACT_APP_API_URL}/api/setting/storeMinorCategory?company_uid=${CompanyId}&business_uid=${BusinessId}&service_code=${ServiceId}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${AccessTokenAPI}`,
    },
    data: dataAPI,
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      expiredToken(error);
    });
};
export const getMinorCategory = (paramId) => {
  let config = {
    method: "get",
    url: `${process.env.REACT_APP_API_URL}/api/setting/getMinorCategory?id=${paramId}&language_code=${LanguageForm}&company_uid=${CompanyId}&business_uid=${BusinessId}&service_code=${ServiceId}`,
    headers: {
      Authorization: `Bearer ${AccessTokenAPI}`,
    },
  };

  return axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      console.log(error);
      expiredToken(error);
    });
};
export const updateMinorCategory = (dataAPI) => {
  let config = {
    method: "put",
    url: `${process.env.REACT_APP_API_URL}/api/setting/updateMinorCategory?company_uid=${CompanyId}&business_uid=${BusinessId}&service_code=${ServiceId}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${AccessTokenAPI}`,
    },
    data: dataAPI,
  };

  return axios(config)
    .then(function (response) {
      return response.data.message;
    })
    .catch(function (error) {
      console.log(error);
      expiredToken(error);
    });
};
