import { Box, Typography } from "@mui/material";
import React from "react";
import { BoxImageTable } from "../boxImage/boxImageTable";

function CardItem({ props }) {
  const { product, count, amount, name } = props;
  return (
    <Box
      sx={(theme) => ({
        justifyContent: "flex-end",
        alignItems: "flex-start",
        position: "relative",
        "&:hover": {
          opacity: "0.9",
          cursor: "pointer",
        },
        boxShadow: "0px 3px 4px 0px rgba(0, 0, 0, .03)",
        borderRadius: "0.75rem",
        border: "2px solid #F1F1F1",
        backgroundColor: "#fff",
        p: 2,
      })}
    >
      <Typography
        sx={(theme) => ({
          position: "absolute",
          right: 0,
          top: 0,
          backgroundColor: theme.palette.primary.main,
          padding: "0px 10px",
          color: "#fff",
          fontSize: theme.typography.h20,
          zIndex: "99",
        })}
      >
        {count ? "x" : ""}
        {amount}
      </Typography>
      <BoxImageTable
        image={product.image}
        height={"256px"}
        width={"100%"}
        type="table"
        borderRadius={true}
      />
      <Typography
        align="center"
        sx={{
          padding: "5px 0",
          backgroundColor: "",
          color: "",
          width: "100%",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {name}
      </Typography>
    </Box>
  );
}

export default CardItem;
