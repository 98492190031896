import axios from "axios";
import {
  AccessTokenAPI,
  BusinessId,
  CompanyId,
  ServiceId,
} from "../../getLocalStorage";

export const updateSelfPickupAdmin = async (dataAPI) => {
  //   let data = JSON.stringify({
  //     id: 2,
  //     position: "admin++",
  //     pin: "123123",
  //     profile_img: "image.jpg",
  //     nat_id: "123123123123123",
  //     tel: "0811231234",
  //     email: "admin@admin.com",
  //     status: true,
  //     self_pickup_admin_lang: {
  //       firstname: "Firstname++",
  //       lastname: "Lastname++",
  //       language_code: "en",
  //     },
  //   });

  let config = {
    method: "put",
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_API_URL}/api/setting/selfpickup/updateSelfPickupAdmin?company_uid=${CompanyId}&business_uid=${BusinessId}&service_code=${ServiceId}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${AccessTokenAPI}`,
    },
    data: JSON.stringify(dataAPI),
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data.message;
    })
    .catch((error) => {
      console.log(error);
    });
};
