import React from "react";
import Sidebar from "../components/sidebar/sidebar";
import BranchTab from "../pages/branch/branchTab/branchTab";
import TypeOfProductOptionsDetail from "../pages/productOptions/productOptionsCategory/productOptionsCategoryDetail";
import MoneyInDetail from "../pages/cashDrawer/moneyIn/moneyInDetail";
import MoneyOutDetail from "../pages/cashDrawer/moneyOut/moneyOutDetail";
import VoidReceiptDetail from "../pages/cashDrawer/voidReceipt/voidReceiptDetail";
import CurrencyInfoDetail from "../pages/currency/currencyInfoDetail";
import StaffPOSCreate from "../pages/staffPOS/staffPOSCreate";
import SettingDeviceDetail from "../pages/settingPOS/settingDeviceDetail";
import CategoryCreate from "../pages/product/category/categoryCreate";
import CategoryDetail from "../pages/product/category/categoryDetail";
import ProductDetail from "../pages/product/product/productDetail";
import ProductCategoryDetail from "../pages/product/productCategory/productCategoryDetail";
import PromotionDetail from "../pages/promotion/promotion/promotionDetail";
import ProductCreate from "../pages/product/product/productCreate";
import StaffPOSDetail from "../pages/staffPOS/staffPOSDetail";
import CurrencyCreate from "../pages/currency/currencyCreate";
import PaymentTypeCreate from "../pages/paymentType/paymentTypeCreate";
import PaymentTypeDetail from "../pages/paymentType/paymentTypeDetail";
import ProductCategoryCreate from "../pages/product/productCategory/productCategoryCreate";
import TypeOfProductOptionsCreate from "../pages/productOptions/productOptionsCategory/productOptionsCategoryCreate";
import ProductOptionsCreate from "../pages/productOptions/productOptions/productOptionsCreate";
import ProductOptionsDetail from "../pages/productOptions/productOptions/productOptionsDetail";
import VoidReceiptCreate from "../pages/cashDrawer/voidReceipt/voidReceiptCreate";
import MoneyInCreate from "../pages/cashDrawer/moneyIn/moneyInCreate";
import MoneyOutCreate from "../pages/cashDrawer/moneyOut/moneyOutCreate";
import BranchCreate from "../pages/branch/branchCreate";
import SaleProductDetail from "../pages/product/saleProduct/saleProductDetail";
import SaleProductCreate from "../pages/product/saleProduct/saleProductCreate";
import PromotionCreateStep from "../pages/promotion/promotion/promotionCreateStep/promotionCreateStep";
import OrderTypeDetail from "../pages/promotion/orderType/orderTypeDetail";
import { ListManagement } from "../components/sidebar/listMenu/listManagement";
import { ListSettingPos } from "../components/sidebar/listMenu/listSettingPos";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { Box } from "@mui/material";
import { AccessTokenAPI } from "../service/getLocalStorage";
import BackScreenCreate from "../pages/back-screen/backScreenCreate";
import BackScreenDetail from "../pages/back-screen/backScreenDetail";
import UnitCreate from "../pages/unit/unitCreate";
import UnitDetail from "../pages/unit/unitDetail";
import PageNotFound from "../components/ui/pageNotFound/pageNotFound";
import { ListDashboard } from "../components/sidebar/listMenu/listDashBoard";
import { ListOnline } from "../components/sidebar/listMenu/listOnline";
import OnlineBranchDetail from "../pages/selfPickup/selfPickupBranch/selfPickupBranchTab/selfPickupBranchDetail";
import SelfPickupAdminCreate from "../pages/selfPickup/selfPickupAdmin/selfPickupAdminCreate";
import SelfPickupAdminDetail from "../pages/selfPickup/selfPickupAdmin/selfPickupAdminDetail";
import SelfPickupBankAccountCreate from "../pages/selfPickup/selfPickupBankAccount/selfPickupBankAccountCreate";
import SelfPickupBankAccountDetail from "../pages/selfPickup/selfPickupBankAccount/selfPickupBankAccountDetail";
import { ListTableManagement } from "../components/sidebar/listMenu/listTableManagement";
import { ListReport } from "../components/sidebar/listMenu/listReport";
import SettingDeviceCreate from "../pages/settingPOS/settingDeviceCreate";
import TableListItemCreate from "../pages/manageTable/table/tableListItemCreate";
import TableListItemDetail from "../pages/manageTable/table/tableListItemDetail";
import SubCategory from "../pages/product/category/subCategory";
import PaymentChannel from "../pages/paymentType/paymentChannel/paymentChannel";
import PaymentChannelDetail from "../pages/paymentType/paymentChannel/paymentChannelDetail";
import PaymentChannelCreate from "../pages/paymentType/paymentChannel/paymentChannelCreate";

function AppRouter() {
  const listManagementItem = ListManagement();
  const listSettingPosItem = ListSettingPos();
  const listDashBoard = ListDashboard();
  const listOnline = ListOnline();
  const listReport = ListReport();
  const listTableManagement = ListTableManagement();
  return (
    <div>
      <BrowserRouter>
        <Box
          className="App"
          sx={(theme) => ({
            color: theme.palette.secondary.main,
          })}
        >
          <Routes>
            <Route path="/" element={<Navigate to="/branch" />} />
            <Route
              path="/"
              element={
                AccessTokenAPI ? <Sidebar /> : <Navigate to="/" replace />
              }
            >
              {listDashBoard.map((menu, index) => (
                <React.Fragment key={index}>
                  <Route
                    key={menu.name}
                    path={menu.to}
                    exact={menu.exact}
                    element={<ProtectedRoute>{menu.component}</ProtectedRoute>}
                  />
                  {menu.subMenus && menu.subMenus.length > 0
                    ? menu.subMenus.map((subMenu, i) => (
                        <Route
                          key={subMenu.name}
                          path={subMenu.to}
                          element={
                            <ProtectedRoute>{subMenu.component}</ProtectedRoute>
                          }
                        />
                      ))
                    : null}
                </React.Fragment>
              ))}
              {listManagementItem.map((menu, index) => (
                <React.Fragment key={index}>
                  <Route
                    key={menu.name}
                    path={menu.to}
                    exact={menu.exact}
                    element={<ProtectedRoute>{menu.component}</ProtectedRoute>}
                  />
                  {menu.subMenus && menu.subMenus.length > 0
                    ? menu.subMenus.map((subMenu, i) => (
                        <Route
                          key={subMenu.name}
                          path={subMenu.to}
                          element={
                            <ProtectedRoute>{subMenu.component}</ProtectedRoute>
                          }
                        />
                      ))
                    : null}
                </React.Fragment>
              ))}

              {listSettingPosItem.map((menu, index) => (
                <React.Fragment key={index}>
                  <Route
                    key={menu.name}
                    path={menu.to}
                    exact={menu.exact}
                    element={<ProtectedRoute>{menu.component}</ProtectedRoute>}
                  />
                  {menu.subMenus && menu.subMenus.length > 0
                    ? menu.subMenus.map((subMenu, i) => (
                        <Route
                          key={subMenu.name}
                          path={subMenu.to}
                          element={
                            <ProtectedRoute>{subMenu.component}</ProtectedRoute>
                          }
                        />
                      ))
                    : null}
                </React.Fragment>
              ))}

              {listOnline.map((menu, index) => (
                <React.Fragment key={index}>
                  <Route
                    key={menu.name}
                    path={menu.to}
                    exact={menu.exact}
                    element={<ProtectedRoute>{menu.component}</ProtectedRoute>}
                  />
                  {menu.subMenus && menu.subMenus.length > 0
                    ? menu.subMenus.map((subMenu, i) => (
                        <Route
                          key={subMenu.name}
                          path={subMenu.to}
                          element={
                            <ProtectedRoute>{subMenu.component}</ProtectedRoute>
                          }
                        />
                      ))
                    : null}
                </React.Fragment>
              ))}
              {listTableManagement.map((menu, index) => (
                <React.Fragment key={index}>
                  <Route
                    key={menu.name}
                    path={menu.to}
                    exact={menu.exact}
                    element={<ProtectedRoute>{menu.component}</ProtectedRoute>}
                  />
                  {menu.subMenus && menu.subMenus.length > 0
                    ? menu.subMenus.map((subMenu, i) => (
                        <Route
                          key={subMenu.name}
                          path={subMenu.to}
                          element={
                            <ProtectedRoute>{subMenu.component}</ProtectedRoute>
                          }
                        />
                      ))
                    : null}
                </React.Fragment>
              ))}
              {listReport.map((menu, index) => (
                <React.Fragment key={index}>
                  <Route
                    key={menu.name}
                    path={menu.to}
                    exact={menu.exact}
                    element={<ProtectedRoute>{menu.component}</ProtectedRoute>}
                  />
                  {menu.subMenus && menu.subMenus.length > 0
                    ? menu.subMenus.map((subMenu, i) => (
                        <Route
                          key={subMenu.name}
                          path={subMenu.to}
                          element={
                            <ProtectedRoute>{subMenu.component}</ProtectedRoute>
                          }
                        />
                      ))
                    : null}
                </React.Fragment>
              ))}

              {/* Staff POS  */}
              <Route
                path="/posUser/create"
                element={
                  <ProtectedRoute>
                    <StaffPOSCreate />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/posUser/detail/:id"
                element={
                  <ProtectedRoute>
                    <StaffPOSDetail />
                  </ProtectedRoute>
                }
              />

              {/* branch page */}
              <Route
                path="/branch/create"
                element={
                  <ProtectedRoute>
                    <BranchCreate />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/branch/detail/:id"
                element={
                  <ProtectedRoute>
                    <BranchTab />
                  </ProtectedRoute>
                }
              />

              {/* currency page*/}
              <Route
                path="currency/create"
                element={
                  <ProtectedRoute>
                    <CurrencyCreate />
                  </ProtectedRoute>
                }
              />
              <Route
                path="currency/detail/:id"
                element={
                  <ProtectedRoute>
                    <CurrencyInfoDetail />
                  </ProtectedRoute>
                }
              />
              {/* unit page */}
              <Route
                path="unit/create"
                element={
                  <ProtectedRoute>
                    <UnitCreate />
                  </ProtectedRoute>
                }
              />
              <Route
                path="unit/detail/:id"
                element={
                  <ProtectedRoute>
                    <UnitDetail />
                  </ProtectedRoute>
                }
              />
              {/* payment type page Cash*/}
              <Route
                path="payment-type/create"
                element={
                  <ProtectedRoute>
                    <PaymentTypeCreate />
                  </ProtectedRoute>
                }
              />
              <Route
                path="payment-type/detail/:id"
                element={
                  <ProtectedRoute>
                    <PaymentTypeDetail />
                  </ProtectedRoute>
                }
              />
              <Route
                path="payment-type/sub/detail/:id"
                element={
                  <ProtectedRoute>
                    <PaymentChannel />
                  </ProtectedRoute>
                }
              />
              <Route
                path="payment-type/sub/detail/:id/create"
                element={
                  <ProtectedRoute>
                    <PaymentChannelCreate />
                  </ProtectedRoute>
                }
              />
              <Route
                path="payment-type/sub/detail/:id/payment-channel/:id"
                element={
                  <ProtectedRoute>
                    <PaymentChannelDetail />
                  </ProtectedRoute>
                }
              />
              {/* category page */}
              <Route
                path="category/create"
                element={
                  <ProtectedRoute>
                    <CategoryCreate />
                  </ProtectedRoute>
                }
              />
              <Route
                path="category/detail/:id"
                element={
                  <ProtectedRoute>
                    <CategoryDetail />
                  </ProtectedRoute>
                }
              />
              <Route
                path="category/sub/detail/:id"
                element={
                  <ProtectedRoute>
                    <SubCategory />
                  </ProtectedRoute>
                }
              />
              {/* product */}
              <Route
                path="products/create"
                element={
                  <ProtectedRoute>
                    <ProductCreate />
                  </ProtectedRoute>
                }
              />
              <Route
                path="products/detail/:id"
                element={
                  <ProtectedRoute>
                    <ProductDetail />
                  </ProtectedRoute>
                }
              />
              {/* product category */}
              <Route
                path="product-category/create"
                element={
                  <ProtectedRoute>
                    <ProductCategoryCreate />
                  </ProtectedRoute>
                }
              />
              <Route
                path="product-category/detail/:id"
                element={
                  <ProtectedRoute>
                    <ProductCategoryDetail />
                  </ProtectedRoute>
                }
              />
              {/* sale product page */}
              <Route
                path="sale-product/create"
                element={
                  <ProtectedRoute>
                    <SaleProductCreate />
                  </ProtectedRoute>
                }
              />
              <Route
                path="sale-product/detail/:id"
                element={
                  <ProtectedRoute>
                    <SaleProductDetail />
                  </ProtectedRoute>
                }
              />

              {/* pre define category page */}
              <Route
                path="pre-define-category/create"
                element={
                  <ProtectedRoute>
                    <TypeOfProductOptionsCreate />
                  </ProtectedRoute>
                }
              />
              <Route
                path="pre-define-category/detail/:id"
                element={
                  <ProtectedRoute>
                    <TypeOfProductOptionsDetail />
                  </ProtectedRoute>
                }
              />
              {/* pre define product */}
              <Route
                path="pre-define-product/create"
                element={
                  <ProtectedRoute>
                    <ProductOptionsCreate />
                  </ProtectedRoute>
                }
              />
              <Route
                path="pre-define-product/detail/:id"
                element={
                  <ProtectedRoute>
                    <ProductOptionsDetail />
                  </ProtectedRoute>
                }
              />
              {/* pre define cancel receipt */}
              <Route
                path="cancel-receipt/create"
                element={
                  <ProtectedRoute>
                    <VoidReceiptCreate />
                  </ProtectedRoute>
                }
              />
              <Route
                path="cancel-receipt/detail/:id"
                element={
                  <ProtectedRoute>
                    <VoidReceiptDetail />
                  </ProtectedRoute>
                }
              />
              {/* pre define money in */}
              <Route
                path="money-in/create"
                element={
                  <ProtectedRoute>
                    <MoneyInCreate />
                  </ProtectedRoute>
                }
              />
              <Route
                path="money-in/detail/:id"
                element={
                  <ProtectedRoute>
                    <MoneyInDetail />
                  </ProtectedRoute>
                }
              />
              {/* pre define money out */}
              <Route
                path="money-out/create"
                element={
                  <ProtectedRoute>
                    <MoneyOutCreate />
                  </ProtectedRoute>
                }
              />
              <Route
                path="money-out/detail/:id"
                element={
                  <ProtectedRoute>
                    <MoneyOutDetail />
                  </ProtectedRoute>
                }
              />
              {/* promotion */}
              <Route
                path="promotions/create"
                element={
                  <ProtectedRoute>
                    <PromotionCreateStep />
                  </ProtectedRoute>
                }
              />
              <Route
                path="promotions/detail/:id"
                element={
                  <ProtectedRoute>
                    <PromotionDetail />
                  </ProtectedRoute>
                }
              />
              {/* order type */}
              {/* <Route
                path="order-type/create"
                element={
                  <ProtectedRoute>
                    <OrderTypeCreate />
                  </ProtectedRoute>
                }
              /> */}
              <Route
                path="order-type/detail/:id"
                element={
                  <ProtectedRoute>
                    <OrderTypeDetail />
                  </ProtectedRoute>
                }
              />
              {/* setting slider  */}
              <Route
                path="slider/create"
                element={
                  <ProtectedRoute>
                    <BackScreenCreate />
                  </ProtectedRoute>
                }
              />
              <Route
                path="slider/detail/:id"
                element={
                  <ProtectedRoute>
                    <BackScreenDetail />
                  </ProtectedRoute>
                }
              />
              {/* setting device  */}
              <Route
                path="setting-device/create"
                element={
                  <ProtectedRoute>
                    <SettingDeviceCreate />
                  </ProtectedRoute>
                }
              />
              <Route
                path="setting-device/detail/:id"
                element={
                  <ProtectedRoute>
                    <SettingDeviceDetail />
                  </ProtectedRoute>
                }
              />
              {/* online menu */}
              <Route
                path="self-pickup-admin/detail/:id"
                element={
                  <ProtectedRoute>
                    <OnlineBranchDetail />
                  </ProtectedRoute>
                }
              />
              {/* self pickup admin create */}
              <Route
                path="self-pickup-admin/create"
                element={
                  <ProtectedRoute>
                    <SelfPickupAdminCreate />
                  </ProtectedRoute>
                }
              />
              <Route
                path="self-pickup-admin/detail/:id"
                element={
                  <ProtectedRoute>
                    <SelfPickupAdminDetail />
                  </ProtectedRoute>
                }
              />
              {/* self pickup admin bank account */}
              <Route
                path="self-pickup-admin-bank-account/create"
                element={
                  <ProtectedRoute>
                    <SelfPickupBankAccountCreate />
                  </ProtectedRoute>
                }
              />
              <Route
                path="self-pickup-admin-bank-account/detail/:id"
                element={
                  <ProtectedRoute>
                    <SelfPickupBankAccountDetail />
                  </ProtectedRoute>
                }
              />
              {/* table management */}
              <Route
                path="table/create"
                element={
                  <ProtectedRoute>
                    <TableListItemCreate />
                  </ProtectedRoute>
                }
              />
              <Route
                path="table/detail/:id"
                element={
                  <ProtectedRoute>
                    <TableListItemDetail />
                  </ProtectedRoute>
                }
              />
            </Route>
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </Box>
      </BrowserRouter>
    </div>
  );
}

export default AppRouter;

const ProtectedRoute = ({ children }) => {
  if (!AccessTokenAPI) {
    return <Navigate to="/" replace />;
  }
  return children;
};
