import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import dayjs from "dayjs";
import { FormatNumber } from "../../../formatNumber";
import colorDashBoard from "../../colorDashboard.json";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);
function LineSeparateBranchTotalSale({ props }) {
  let { dataValues, nameValue, symbol, title, startDate, endDate } = props;
  let colorBg = colorDashBoard;
  let currentDate = startDate;
  let dateArray = [];
  while (currentDate.isBefore(endDate) || currentDate.isSame(endDate)) {
    dateArray.push(currentDate.format("YYYY-MM-DD"));
    currentDate = currentDate.add(1, "day");
  }
  let labelDate = dateArray.map((item) => dayjs(item).format("DD MMM"));
  let dataBox = [];
  dataValues.forEach((item) => {
    let dataFind = dataBox.find(
      (itemBranch) => itemBranch.branch_id === item.branch_id
    );
    let dataNewBranch = "";
    if (dataFind === undefined) {
      dataNewBranch = item.branch_id;
      dataBox.push({
        branch_id: dataNewBranch,
        data: [item.data],
        borderColor: colorBg[dataBox.length],
        tension: 0.1,
      });
    } else {
      dataFind.data.push(item.data);
    }
  });
  let dataInsertAllBranch = [];
  dataBox.forEach((itemBranch) => {
    let dataInsertDate = [];
    // itemBranch.data.forEach((itemDateBranch) => {
    dateArray.forEach((itemDate) => {
      let dataFind = itemBranch.data.find(
        (itemFind) =>
          dayjs(itemFind.date, "DD-MM-YYYY HH:mm:ss").format("YYYY-MM-DD") ===
          itemDate
      );
      if (dataFind) {
        dataInsertDate.push({
          date: dayjs(itemDate).format("YYYY-MM-DD"),
          data: {
            total_sale: dataFind.data.total_sale,
            total_receipt: dataFind.data.total_receipt,
            avg_price_per_receipt: dataFind.data.avg_price_per_receipt,
          },
        });
      } else {
        dataInsertDate.push({
          date: dayjs(itemDate).format("YYYY-MM-DD"),
          data: {
            total_sale: 0,
            total_receipt: 0,
            avg_price_per_receipt: 0,
          },
        });
      }
    });
    // });
    dataInsertAllBranch.push({
      branch_id: itemBranch.branch_id,
      data: dataInsertDate,
    });
  });

  let dataBoxShowBranch = [];

  dataInsertAllBranch.forEach((itemBranch, index) => {
    let dataCombine = itemBranch.data.map((item) => item.data[nameValue]);
    dataBoxShowBranch.push({
      label: itemBranch.branch_id,
      data: dataCombine,
      borderColor: colorBg[index],
      tension: 0.1,
    });
  });
  const data = {
    labels: labelDate,
    datasets: dataBoxShowBranch,
  };
  const options = {
    maintainAspectRatio: false,
    scales: {
      x: {
        display: true,
        grid: {
          display: false,
        },
        title: {
          display: window.innerWidth < 600 ? false : true, // Enable the axis title
          text: "Date", // Set the title text
          font: {
            // Optional: Customize title font
            size: window.innerWidth < 600 ? 12 : 16,
            weight: "bold",
          },
        },
      },
      y: {
        display: true,
        grid: {
          display: true,
        },
        ticks: {
          display: window.innerWidth < 600 ? false : true,
          callback: (value) => {
            // Customize the text labels as needed
            return `${symbol ? "" : ""} ${FormatNumber(value)}`;
          },
        },
        title: {
          display: true, // Enable the axis title
          text: title, // Set the title text
          font: {
            // Optional: Customize title font
            size: window.innerWidth < 600 ? 12 : 16,
            weight: "bold",
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false,
        // position: "bottom",
      },
      title: {
        display: false,
        text: "Total Sales",
        position: "top",
      },
      // datalabels: {
      //   color: theme.palette.primary.main,
      //   backgroundColor: "#ffffff",
      //   formatter: (value) => `${value.toFixed(2)}`,
      // },
      tooltip: {
        callbacks: {
          title: function (context) {
            return `${dayjs(context[0].label, "DD MMM").format("DD/MMM/YYYY")}`;
          },
          label: function (context) {
            let sumPercent = "";
            if (nameValue === "total_sale") {
              sumPercent = `Total Sales`;
            } else if (nameValue === "total_receipt") {
              sumPercent = `Total Receipt`;
            } else {
              sumPercent = `Average price per receipt`;
            }
            return `${sumPercent}: ${Number(context.raw).toLocaleString()}`;
          },
        },
      },
    },
  };
  return (
    <div style={{ height: "300px", width: "100%" }}>
      <Line data={data} options={options} />
    </div>
  );
}

export default LineSeparateBranchTotalSale;
