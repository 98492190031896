import * as React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Box, Button, Grid, Skeleton } from "@mui/material";
import PaperWrapper from "../paper/paperWrapper";
import { DatePicker, Space } from "antd";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import dayjs from "dayjs";
import { t } from "i18next";
import { LocalizationProvider } from "@mui/x-date-pickers";
import SelectCheckMarkComponent from "./selectCheckMarkComponent";
import { useForm } from "react-hook-form";
import { MyContext } from "../../../context/MyContext";

const rangePresets = [
  {
    label: "Last 7 Days",
    value: [dayjs().add(-7, "d").startOf("day"), dayjs().endOf("day")],
  },
  {
    label: "Last 14 Days",
    value: [dayjs().add(-14, "d").startOf("day"), dayjs().endOf("day")],
  },
  {
    label: "Last 30 Days",
    value: [dayjs().add(-30, "d").startOf("day"), dayjs().endOf("day")],
  },
  {
    label: "Last 90 Days",
    value: [dayjs().add(-90, "d").startOf("day"), dayjs().endOf("day")],
  },
];
export default function SelectCheckMarkBranch({ props }) {
  const {
    setValue,
    clearErrors,
    control,
    formState: { errors },
  } = useForm();
  let { onConfirm, mode } = props;
  const [isLoadingUpdate, setIsLoadingUpdate] = useState(true);
  const dateFormat = mode === "month" ? "YYYY-MM" : "DD/MM/YYYY";
  const {
    branchAll,
    setBranchSelectReport,
    branchSelectReportDate,
    setBranchSelectReportDate,
    branchSelectName,
    setBranchSelectName,
  } = React.useContext(MyContext);

  const { RangePicker } = DatePicker;
  const [isLoading, setIsLoading] = useState(true);

  const fetchDataBranchAll = async () => {
    let branchNames = null;
    if (branchSelectName.length === 0) {
      branchNames = branchAll.map((item) => item.name);
      setBranchSelectName(branchNames);
    } else {
      branchNames = branchSelectName;
    }
    setIsLoadingUpdate(false);
    setIsLoading(false);
  };
  const handleChangeDate = (newValue) => {
    setBranchSelectReportDate({
      ...branchSelectReportDate,
      startDate: dayjs(newValue[0], dateFormat).startOf("day"),
      endDate: dayjs(newValue[1], dateFormat).endOf("day"),
    });
  };
  const handleChangeMonth = (newValue) => {
    setBranchSelectReportDate({
      ...branchSelectReportDate,
      startDate: dayjs(newValue, dateFormat).startOf("month"),
      endDate: dayjs(newValue, dateFormat).endOf("month"),
    });
  };
  const maxDate = new Date();
  const disabledDate = (current) => {
    return current && current > maxDate;
  };
  useEffect(() => {
    fetchDataBranchAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Box sx={{ boxSizing: "border-box", pt: 2, px: 2 }}>
      <PaperWrapper props={{ growFull: 1, setting: { p: 1 } }}>
        {isLoading ? (
          <Box>
            <Grid container justifyContent={"space-between"} spacing={2}>
              <Grid item xs={12} sm={6}>
                <Skeleton
                  variant="rounded"
                  sx={{ width: "100%" }}
                  height={40}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Skeleton
                  variant="rounded"
                  sx={{ width: "100%" }}
                  height={40}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Skeleton
                  variant="rounded"
                  sx={{ width: "100%" }}
                  height={40}
                />
              </Grid>
            </Grid>
          </Box>
        ) : (
          <>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Box
                sx={(theme) => ({
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  gap: 2,
                })}
              >
                <Grid container justifyContent={"space-between"} spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Box
                      sx={(theme) => ({
                        boxSizing: "border-box",
                      })}
                    >
                      <SelectCheckMarkComponent
                        dataEmpty={t("branch_select")}
                        errors={errors}
                        name="branchSelect"
                        dataOptions={branchAll}
                        clearErrors={clearErrors}
                        control={control}
                        setValue={setValue}
                        valueData={branchSelectName}
                        setValueData={setBranchSelectName}
                        setValueDataId={setBranchSelectReport}
                        disabled={isLoadingUpdate}
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <Box
                      sx={(theme) => ({
                        boxSizing: "border-box",
                      })}
                    >
                      <Space direction="vertical">
                        {mode === "month" ? (
                          <DatePicker
                            onChange={handleChangeMonth}
                            defaultValue={dayjs()}
                            picker="month"
                            format={dateFormat}
                            style={{
                              height: "40px",
                              width: "100%",
                            }}
                            disabled={isLoadingUpdate}
                          />
                        ) : (
                          <RangePicker
                            defaultValue={[
                              branchSelectReportDate.startDate,
                              branchSelectReportDate.endDate,
                            ]}
                            presets={[
                              {
                                label: (
                                  <span aria-label="Current Time to End of Day">
                                    Now ~ EOD
                                  </span>
                                ),
                                value: () => [dayjs(), dayjs().endOf("day")], // 5.8.0+ support function
                              },
                              ...rangePresets,
                            ]}
                            // showTime
                            onChange={handleChangeDate}
                            disabledDate={disabledDate}
                            format={dateFormat}
                            style={{
                              height: "40px",
                              width: "100%",
                            }}
                            disabled={isLoadingUpdate}
                          />
                        )}
                      </Space>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <Button
                      variant="contained"
                      fullWidth
                      onClick={() => onConfirm()}
                      sx={{ borderRadius: 2 }}
                      disabled={
                        isLoadingUpdate || branchSelectName.length === 0
                      }
                    >
                      Confirm
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </LocalizationProvider>
          </>
        )}
      </PaperWrapper>
    </Box>
  );
}
