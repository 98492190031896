import React, { useState, useEffect, useContext } from "react";
import {
  Grid,
  FormControlLabel,
  // Checkbox,
  Box,
  Button,
  Radio,
  Dialog,
  DialogContent,
} from "@mui/material";
import TitleForm from "../../../components/ui/formComponent/titleForm";
import { TitleTextfield } from "../../../components/ui/textfield/textfieldCustom";
import { useNavigate } from "react-router-dom";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ImageCrop from "../../../components/ui/upload/imageCrop";
import { uploadImage } from "../../../service/upload/uploadImage";
import { getAllUnitCounter } from "../../../service/unitCounterAPI";
import { notifySuccess } from "../../../components/ui/popup/toastPopup";
import { getAllBranch } from "../../../service/branchAPI";
import { useTranslation } from "react-i18next";
import { getAllProduct } from "../../../service/productAPI";
import Swal from "sweetalert2";
import { getAllProductCategory } from "../../../service/productCategoryAPI";
import { useForm } from "react-hook-form";
// import PercentIcon from "@mui/icons-material/Percent";
import {
  addSaleProductToTemplate,
  getAllSaleProductTemplate,
  postSaleProduct,
} from "../../../service/saleProductAPI";
import BreadcrumbsTitle from "../../../components/ui/breadcrumbsTitle";
import StatusForm from "../../../components/ui/formComponent/statusForm";
import ConfirmButton from "../../../components/ui/button/confirmButton";
import Loading from "../../../components/ui/loading";
import SettingTypeBranch from "../../../components/ui/addTypeTemplate/settingTypeBranch";
import { ProductDialog } from "../../../components/ui/productSet/productDialog";
import { WarningPopup } from "../../../components/ui/popup/successPopup";
import TextfieldComponent from "../../../components/ui/textfield/textfieldComponent";
import SelectComponent from "../../../components/ui/textfield/selectComponent";
import { useSchema } from "../../../components/hooks/use-schema";
import { saleProductSchema } from "../../../components/validation/saleProductSchema";
import MoneyIcon from "@mui/icons-material/Money";
import { PaddingContainer } from "../../../components/utils/paddingContainer";
import CardItem from "../../../components/ui/card/cardItem";
import saleProductType from "../../../components/utils/saleProductType.json";
import i18next from "i18next";
import EmptyDataDirect from "../../../components/ui/emptyDataDirect";
import useCopyToClipboard from "../../../components/hooks/use-copy-to-clipboard";
import PaperWrapper from "../../../components/ui/paper/paperWrapper";
import { BoxWrapper } from "../../../components/ui/box/boxWrapper";
import { GridContainerSize } from "../../../components/utils/gridContainerSize";
import { getImage } from "../../../service/upload/getImage";
import { UseConvertImageToBase64 } from "../../../components/hooks/use-convert-image-to-base-64";
import { BoxGapInput } from "../../../components/ui/box/boxGapInput";
import { getAllMainCategory } from "../../../service/mainCategory";
import { getAllMinorCategory } from "../../../service/minorCategory";
import { MyContext } from "../../../context/MyContext";
import { BoxFormTitleAction } from "../../../components/ui/box/boxFormTitleAction";
import TitleHeaderForm from "../../../components/ui/formComponent/titleHeaderForm";

function SaleProductCreate() {
  const [statusMainCategory, setStatusMainCategory] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    watch,
    clearErrors,
    getValues,
  } = useForm(useSchema(saleProductSchema(statusMainCategory)));

  const {
    productSetAll,
    productSetDialog,
    setProductSetDialog,
    fetchSaleProduct,
    setDataBranchSaleProduct,
  } = useContext(MyContext);
  const { t } = useTranslation();
  const [isLoadingProduct, setIsLoadingProduct] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [categoryAll, setCategoryAll] = useState([]);
  const [statusButton, setStatusButton] = useState(true);
  const [image, setImage] = useState(null);
  const [amountCount, setAmountCount] = useState(false);
  const [vatStatus, setVatStatus] = useState(true);
  const navigate = useNavigate();
  const [dialogProduct, setDialogProduct] = useState(false);
  const [productType, setProductType] = useState("");
  const [unitCounterAll, setUnitCounterAll] = useState([]);
  const [idTemplate, setIdTemplate] = useState(null);
  const [showTemplate, setShowTemplate] = useState(false);
  const [allDataTemplate, setAllDataTemplate] = useState([]);
  const [productAll, setProductAll] = useState([]);
  const [dataAllProduct, setDataAllProduct] = useState([]);
  const [mainCategory, setMainCategory] = useState("");
  const [handleCopy] = useCopyToClipboard();
  const onChangeMainCategory = (item) => {
    let statusMain = categoryAll.find((cate) => cate.id === item);
    if (statusMain.minor_categories.length > 0) {
      setStatusMainCategory(true);
    } else {
      setStatusMainCategory(false);
    }
    setValue("mainCategory", item);
    clearErrors("mainCategory");
    setMainCategory(item);
    setValue("minorCategory", "");
  };
  const fetchData = async () => {
    localStorage.removeItem("uploadImage");
    let statusCategory = false;
    let statusProductCategory = false;
    let statusUnitFetch = false;
    setValue("vat", "");
    setValue("serviceCharge", "");
    setValue("priceSaleProduct", "");
    statusUnitFetch = await fetchDataUnit();
    if (statusUnitFetch) {
      statusCategory = await fetchCategory();
    }
    if (statusCategory) {
      statusProductCategory = await fetchProductCategory(false, "stable");
    }
    if (statusUnitFetch && statusCategory && statusProductCategory) {
      await setIsLoading(false);
    }
  };
  const fetchDataUnit = async () => {
    let dataAPI = await getAllUnitCounter();
    dataAPI = dataAPI.filter((item) => item.status === true);
    setUnitCounterAll(dataAPI);
    return true;
  };
  const fetchCategory = async () => {
    let dataAPI = await getAllMainCategory();
    let dataAPIMinor = await getAllMinorCategory();
    let newDataAPI = dataAPI.filter((item) => item.status === true);
    let categoryBox = [];
    newDataAPI.forEach((item) => {
      let minorBox = [];
      dataAPIMinor
        .filter((itemSub) => itemSub.main_category_id === item.id)
        .forEach((subCategory) => {
          minorBox.push({
            id: subCategory.minor_category_lang.minor_category_id,
            name: subCategory.minor_category_lang.name,
          });
        });
      categoryBox.push({
        id: item.main_category_lang.main_category_id,
        name: item.main_category_lang.name,
        minor_categories: minorBox,
      });
    });
    setCategoryAll(categoryBox);
    return true;
  };
  const fetchProductCategory = async (statusAmount, statusMount) => {
    setIsLoadingProduct(true);
    if (productSetDialog.length > 0 && statusMount !== "reload") {
      setDataAllProduct(productSetDialog);
      setIsLoadingProduct(false);
      return true;
    } else {
      let dataRows = [];
      let productStatus = false;
      let dataAPICategoriesProduct = await getAllProductCategory();
      dataAPICategoriesProduct = dataAPICategoriesProduct.filter(
        (item) => item.status === true
      );
      dataAPICategoriesProduct.forEach((item) => {
        dataRows.push({
          id: item.id,
          name: item.name,
        });
      });
      productStatus = await fetchProduct(dataRows, statusAmount);
      if (productStatus) {
        setIsLoadingProduct(false);
        return true;
      } else {
        setIsLoadingProduct(false);
        return false;
      }
    }
  };
  const fetchProduct = async (productCategoryAll, statusAmount) => {
    let dataProduct = [];
    let boxCategoriesProduct = [];
    let dataAPIProduct = [];
    if (productSetAll) {
      dataAPIProduct = productSetAll;
    } else {
      dataAPIProduct = await getAllProduct();
    }
    if (
      dataAPIProduct.some((item) => item.image.includes("data:image")) === false
    ) {
      const promises = dataAPIProduct.map(async (item) => {
        if (item.image) {
          let imageUrl = await getImage(item.image);
          item.image = UseConvertImageToBase64(imageUrl);
        }
      });
      await Promise.all(promises);
    }

    if (productSetDialog.length > 0) {
      setDataAllProduct(productSetDialog);
    } else {
      dataAPIProduct.forEach((item) => {
        if (item.product_lang && item.status === true) {
          dataProduct.push(item);
        }
      });
      let newDataProduct = dataProduct.filter(
        (product) => product.stock_count === statusAmount
      );
      productCategoryAll.forEach((itemCate) => {
        let productBox = newDataProduct.filter(
          (item) => item.product_category_id === itemCate.id
        );
        let dataSub = [];
        if (productBox.length > 0) {
          productBox.forEach((item) => {
            dataSub.push({
              id: item.product_lang.product_id,
              data: item,
              amount: 0,
              show: true,
            });
          });
        }
        boxCategoriesProduct.push({
          id: itemCate.id,
          name: itemCate.name,
          amount: 0,
          show: true,
          product: dataSub,
        });
      });
      setDataAllProduct(
        boxCategoriesProduct.sort(function (a, b) {
          return a.id - b.id;
        })
      );
      setProductSetDialog(
        boxCategoriesProduct.sort(function (a, b) {
          return a.id - b.id;
        })
      );
    }

    return true;
  };
  const onProductType = (item) => {
    setValue("productType", item);
    clearErrors("productType");
    setProductType(item);
    setProductAll([]);
    if (productType) {
      fetchProductCategory(amountCount, "reload");
    }
  };
  // Step 1
  const onCountAmount = (status) => {
    setValue("productType", "");
    setAmountCount(status);
    setProductAll([]);
    fetchProductCategory(status, "stable");
    setProductType("");
  };
  // Step2
  const onOpenDialogList = () => {
    if (productType === "single") {
      fetchProductCategory(amountCount, "stable");
    }
    setDialogProduct(true);
  };
  const onVatStatus = (status) => {
    setVatStatus(status);
  };
  const onConfirmForm = async (register) => {
    setIsSubmitting(true);
    if (localStorage.getItem("uploadImage")) {
      WarningPopup({ title: t("notification_please_upload_image") });
    } else {
      if (productAll.length > 0) {
        let dataProductList = [];
        let newImage = "";
        if (image) {
          newImage = await uploadImage(image);
        }
        if (productAll) {
          productAll.forEach((itemProduct) => {
            dataProductList.push({
              id: itemProduct.id,
              amount: itemProduct.amount,
            });
          });
        }
        let productTypeName = saleProductType[i18next.language].find(
          (item) => item.id === productType
        );
        let dataAPI = {
          unit_counter_id: register.unit,
          vat_status: vatStatus,
          image: newImage,
          price: Number(register.priceSaleProduct),
          type: productTypeName.valueName,
          stock_count: amountCount,
          status: statusButton,
          sale_product_lang: {
            name: register.saleProductName,
            alt_name: register.productNameReceipt,
            description: register.descriptionSaleProduct,
            language_code: "ALL",
          },
          products: dataProductList,
          main_categories: [register.mainCategory],
          minor_categories: register.minorCategory
            ? [register.minorCategory]
            : [],
        };
        let statusAPI = await postSaleProduct(dataAPI);
        if (statusAPI.message === "OK") {
          fetchSaleProduct()
            .then(() => {
              notifySuccess({
                title: t("toast_successfully_added_information"),
              });
              if (statusButton) {
                setIdTemplate(statusAPI.data.id);
                fetchAllBranch(statusAPI.data.id);
                setShowTemplate(true);
              } else {
                navigate("/sale-product");
              }
            })
            .catch((error) => {
              console.error("Error fetching product:", error);
            });
        }
      } else {
        Swal.fire({
          title: t("notification_please_select_product_list"),
          icon: "warning",
          showConfirmButton: false,
        });
      }
    }
    await new Promise((resolve) => setTimeout(resolve, 2000));
    setIsSubmitting(false);
  };
  const fetchAllBranch = async (id) => {
    let dataAPI = await getAllBranch();
    dataAPI = dataAPI.filter((item) => item.branch_lang !== null);
    await fetchSettingSelectTemplate(dataAPI, id);
  };
  const fetchSettingSelectTemplate = async (dataBranch, id) => {
    let dataAPI = await getAllSaleProductTemplate(id);
    if (dataAPI.length === 0) {
      notifySuccess({ title: t("toast_successfully_added_information") });
      navigate("/sale-product");
    } else {
      dataAPI = dataAPI[0].template_sale_products;
      let dataBox = [];
      dataBranch.forEach((item) => {
        let dataNews = dataAPI.filter(
          (itemTemplate) => itemTemplate.template_id === item.template_id
        );
        if (dataNews.length > 0) {
          dataBox.push({
            branch: item,
            data: dataNews[0],
            select: false,
          });
        } else {
          dataBox.push({
            branch: item,
            data: null,
            select: false,
          });
        }
      });
      await setAllDataTemplate(dataBox);
    }
  };
  const onAddSaleProductToTemplate = async () => {
    let branchArray = allDataTemplate.filter((item) => item.select === true);
    let dataSelect = [];
    branchArray.forEach((item) => {
      dataSelect.push(item.branch.id);
    });
    let dataAPI = {
      sale_product_id: idTemplate,
      branches: dataSelect,
      priority: 1,
    };
    let dataStatus = await addSaleProductToTemplate(dataAPI);
    if (dataStatus === "OK") {
      setDataBranchSaleProduct([]);
      notifySuccess({
        title: t("toast_successfully_added_information"),
      });
      navigate("/sale-product");
    }
  };
  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onClickCopy = (item) => {
    let dataCopy = getValues("saleProductName");
    handleCopy(dataCopy);
    notifySuccess({
      title: "Copy " + dataCopy,
    });
  };
  return (
    <Box align="left" sx={{ boxSizing: "border-box" }}>
      <BreadcrumbsTitle
        props={{
          title: t("sale_product_create"),
          dataLink: [
            { text: t("sale_product"), link: "/sale-product" },
            { text: t("sale_product_create"), link: null },
          ],
        }}
      />
      {isLoading ? (
        <Loading />
      ) : (
        <GridContainerSize>
          <Box p={2}>
            <form onSubmit={handleSubmit(onConfirmForm)}>
              <BoxWrapper>
                <PaperWrapper props={{ growFull: 1 }}>
                  <BoxFormTitleAction>
                    <TitleHeaderForm props={{ text: t("sale_product") }} />
                    <StatusForm
                      statusButton={statusButton}
                      setStatusButton={setStatusButton}
                    />
                  </BoxFormTitleAction>
                  <BoxGapInput>
                    <Box className="boxWrapper-container">
                      <BoxWrapper>
                        <PaperWrapper props={{ growFull: null }}>
                          <TitleForm
                            props={{ text: t("sale_product_image") }}
                          />
                          <Box align="center">
                            <ImageCrop
                              props={{
                                setImage,
                                image,
                                heightCrop: 256,
                                widthCrop: 256,
                                label: "staffPOS",
                              }}
                            />
                          </Box>
                        </PaperWrapper>
                        <PaperWrapper props={{ growFull: 1 }}>
                          <TitleForm props={{ text: t("sale_product_info") }} />
                          <BoxGapInput>
                            <TextfieldComponent
                              title={t("sale_product_name")}
                              name="saleProductName"
                              register={register}
                              placeholder={t("sale_product_example")}
                              errors={errors}
                              iconEnd={<ContentCopyIcon />}
                              iconEndOnclick={onClickCopy}
                            />
                            <TextfieldComponent
                              title={t("sale_product_receipt_name")}
                              name="productNameReceipt"
                              register={register}
                              placeholder={t("sale_product_example")}
                              errors={errors}
                            />
                            <SelectComponent
                              watch={watch}
                              title={t("unit")}
                              register={register}
                              setValue={setValue}
                              name="unit"
                              errors={errors}
                              dataEmpty={t("unit_select")}
                              optionsData={unitCounterAll}
                              fullGrid={true}
                              disabled={unitCounterAll.length === 0}
                              dataAlert={
                                <>
                                  {unitCounterAll.length === 0 ? (
                                    <EmptyDataDirect
                                      props={{ url: "/unit", text: t("unit") }}
                                    />
                                  ) : null}
                                </>
                              }
                              clearErrors={clearErrors}
                              control={control}
                            />
                            <TextfieldComponent
                              title={t("sale_product_description")}
                              name="descriptionSaleProduct"
                              register={register}
                              placeholder={t(
                                "sale_product_description_example"
                              )}
                              errors={errors}
                              multiline={true}
                              rows={4}
                              fullGrid={true}
                            />
                          </BoxGapInput>
                        </PaperWrapper>
                      </BoxWrapper>
                      <BoxWrapper>
                        <PaperWrapper props={{ growFull: 1 }}>
                          <TitleForm
                            props={{ text: t("global_calculate_vat_title") }}
                          />
                          <Box p={PaddingContainer.size}>
                            <FormControlLabel
                              control={
                                <Radio
                                  name="vatStatus"
                                  checked={vatStatus}
                                  onChange={(e) => onVatStatus(true)}
                                />
                              }
                              label={t("global_calculate_vat")}
                            />
                            <FormControlLabel
                              control={
                                <Radio
                                  name="vatStatus"
                                  checked={!vatStatus}
                                  onChange={(e) => onVatStatus(false)}
                                />
                              }
                              label={t("global_not_calculate_vat")}
                            />
                          </Box>
                        </PaperWrapper>
                      </BoxWrapper>
                      <BoxWrapper>
                        <PaperWrapper props={{ growFull: 1 }}>
                          <TitleForm props={{ text: t("global_price") }} />
                          <Box p={PaddingContainer.size}>
                            <TextfieldComponent
                              title={t("global_price")}
                              name="priceSaleProduct"
                              register={register}
                              placeholder={t("global_price_example")}
                              errors={errors}
                              iconEnd={<MoneyIcon />}
                              fullGrid={true}
                            />
                          </Box>
                        </PaperWrapper>
                      </BoxWrapper>
                      <BoxWrapper>
                        <PaperWrapper props={{ growFull: 1 }}>
                          <TitleForm props={{ text: t("category") }} />
                          <BoxGapInput>
                            <SelectComponent
                              watch={watch}
                              title={t("category")}
                              register={register}
                              setValue={setValue}
                              name="mainCategory"
                              errors={errors}
                              dataEmpty={t("category_select")}
                              optionsData={categoryAll}
                              dataValue={mainCategory}
                              onFunctionName={onChangeMainCategory}
                              fullGrid={true}
                              disabled={categoryAll.length === 0}
                              dataAlert={
                                <>
                                  {categoryAll.length === 0 ? (
                                    <EmptyDataDirect
                                      props={{
                                        url: "/category",
                                        text: t("category"),
                                      }}
                                    />
                                  ) : null}
                                </>
                              }
                              clearErrors={clearErrors}
                              control={control}
                            />
                            {mainCategory &&
                            categoryAll.find(
                              (itemCate) => itemCate.id === mainCategory
                            ).minor_categories.length > 0 ? (
                              <Box>
                                <SelectComponent
                                  watch={watch}
                                  title={t("category_sub")}
                                  register={register}
                                  setValue={setValue}
                                  name="minorCategory"
                                  errors={errors}
                                  dataEmpty={
                                    t("category_sub_select") + " (Optional)"
                                  }
                                  optionsData={
                                    categoryAll.find(
                                      (itemCate) => itemCate.id === mainCategory
                                    ).minor_categories
                                  }
                                  fullGrid={true}
                                  clearErrors={clearErrors}
                                  control={control}
                                />
                              </Box>
                            ) : null}
                          </BoxGapInput>
                        </PaperWrapper>
                      </BoxWrapper>
                      <BoxWrapper>
                        <PaperWrapper props={{ growFull: 1 }}>
                          <TitleForm
                            props={{ text: t("sale_product_amount") }}
                          />
                          <Box p={PaddingContainer.size}>
                            <FormControlLabel
                              control={
                                <Radio
                                  name="limit"
                                  checked={amountCount === true}
                                  onChange={(e) => onCountAmount(true)}
                                />
                              }
                              label={t("sale_product_amount_limit")}
                            />
                            <FormControlLabel
                              control={
                                <Radio
                                  name="unlimited"
                                  checked={amountCount === false}
                                  onChange={(e) => onCountAmount(false)}
                                />
                              }
                              label={t("sale_product_amount_un_limit")}
                            />
                          </Box>
                        </PaperWrapper>
                      </BoxWrapper>
                      <BoxWrapper>
                        <PaperWrapper props={{ growFull: 1 }}>
                          <TitleForm props={{ text: t("product_list") }} />
                          <Box p={PaddingContainer.size}>
                            <SelectComponent
                              watch={watch}
                              title={t("sale_product_option_single_or_set")}
                              name="productType"
                              errors={errors}
                              dataEmpty={t(
                                "sale_product_option_single_or_set_select"
                              )}
                              optionsData={saleProductType[i18next.language]}
                              dataValue={productType}
                              clearErrors={clearErrors}
                              fullGrid={true}
                              onFunctionName={onProductType}
                              control={control}
                            />
                            <Box mt={1}>
                              {productAll ? (
                                <>
                                  {productType && (
                                    <TitleTextfield text={t("product")} />
                                  )}
                                  <Grid container spacing={2}>
                                    {productAll.map((product) => (
                                      <Grid
                                        item
                                        xs={12}
                                        sm={6}
                                        md={4}
                                        onClick={() => onOpenDialogList()}
                                      >
                                        <CardItem
                                          props={{
                                            product,
                                            count: true,
                                            name: product.name,
                                            amount: product.amount,
                                          }}
                                        />
                                      </Grid>
                                    ))}
                                  </Grid>
                                </>
                              ) : null}
                            </Box>
                            <Button
                              fullWidth
                              variant="contained"
                              onClick={() => onOpenDialogList()}
                              disabled={!productType || isLoadingProduct}
                              sx={{ mt: 2, borderRadius: 2 }}
                            >
                              {t("product_add_product")}
                            </Button>
                          </Box>
                        </PaperWrapper>
                      </BoxWrapper>

                      <ConfirmButton disabled={isSubmitting} />
                    </Box>
                  </BoxGapInput>
                </PaperWrapper>
              </BoxWrapper>
            </form>

            <ProductDialog
              props={{
                menuType: productType,
                productAll,
                setProductAll,
                dataAllProduct,
                setDataAllProduct,
                dialogProduct,
                setDialogProduct,
              }}
            />
            <Dialog open={showTemplate} maxWidth="md" fullWidth>
              <DialogContent>
                <SettingTypeBranch
                  title={t("sale_product_branch")}
                  cancelPath={"/sale-product"}
                  onAddTypeToTemplate={onAddSaleProductToTemplate}
                  allDataTemplate={allDataTemplate}
                  setAllDataTemplate={setAllDataTemplate}
                />
              </DialogContent>
            </Dialog>
          </Box>
        </GridContainerSize>
      )}
    </Box>
  );
}

export default SaleProductCreate;
