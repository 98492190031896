import React from "react";
import { BoxWrapper } from "../../components/ui/box/boxWrapper";
import PaperWrapper from "../../components/ui/paper/paperWrapper";
import { BoxFormTitleAction } from "../../components/ui/box/boxFormTitleAction";
import TitleHeaderForm from "../../components/ui/formComponent/titleHeaderForm";
import { t } from "i18next";
import StatusForm from "../../components/ui/formComponent/statusForm";
import TitleForm from "../../components/ui/formComponent/titleForm";
import countries from "../../components/utils/countries.json";
import currencies from "../../components/utils/currencies.json";
import AutocompleteComponent from "../../components/ui/textfield/autoCompleteComponent";
import { BoxGapInput } from "../../components/ui/box/boxGapInput";
import ConfirmButton from "../../components/ui/button/confirmButton";

function CurrencyForm({ props }) {
  let {
    register,
    errors,
    setValue,
    clearErrors,
    control,
    statusButton,
    setStatusButton,
    isSubmitting,
    onConfirmStatusChange,
  } = props;
  return (
    <>
      <BoxWrapper>
        <PaperWrapper props={{ growFull: 1 }}>
          <BoxFormTitleAction>
            <TitleHeaderForm props={{ text: t("currency") }} />
            <StatusForm
              statusButton={statusButton}
              setStatusButton={
                onConfirmStatusChange ? onConfirmStatusChange : setStatusButton
              }
            />
          </BoxFormTitleAction>
          <BoxGapInput>
            <BoxWrapper>
              <PaperWrapper props={{ growFull: 1 }}>
                <TitleForm props={{ text: t("currency_info") }} />
                <BoxGapInput p={2}>
                  <AutocompleteComponent
                    title={t("currency")}
                    name="currency"
                    control={control}
                    setValue={setValue}
                    placeholder={t("currency_example")}
                    errors={errors}
                    register={register}
                    clearErrors={clearErrors}
                    optionsData={currencies.map((option) => option.code)}
                  />
                  <AutocompleteComponent
                    title={t("country")}
                    name="country"
                    control={control}
                    setValue={setValue}
                    placeholder={t("country_example")}
                    errors={errors}
                    register={register}
                    clearErrors={clearErrors}
                    optionsData={countries.map((option) => option.name)}
                  />
                </BoxGapInput>
              </PaperWrapper>
            </BoxWrapper>
            <ConfirmButton disabled={isSubmitting} />
          </BoxGapInput>
        </PaperWrapper>
      </BoxWrapper>
    </>
  );
}

export default CurrencyForm;
