import React, { useContext, useEffect, useState } from "react";
import BreadcrumbsTitle from "../../../../components/ui/breadcrumbsTitle";
import { useTranslation } from "react-i18next";
import { Box, Chip, Dialog, DialogContent, Grid } from "@mui/material";
import { useForm } from "react-hook-form";
import PromotionResult from "./promotionResult";
import PromotionCondition from "./promotionCondition";
import PromotionDiscountType from "./promotionDiscountType";
import PromotionCreateDetail from "./promotionCreateDetail";
import { SaleProductDialog } from "../../../../components/ui/productSet/saleProductDialog";
import ConfirmButton from "../../../../components/ui/button/confirmButton";
import moment from "moment";
import DialogAddCategory from "../../../../components/ui/categorySet/dialogAddCategory";
import { uploadImage } from "../../../../service/upload/uploadImage";
import {
  addPromotionToTemplate,
  getAllPromotionTemplate,
  postPromotion,
} from "../../../../service/promotionAPI";
import CategoryIcon from "@mui/icons-material/Category";
import { notifySuccess } from "../../../../components/ui/popup/toastPopup";
import { useNavigate } from "react-router-dom";
import SettingTypeBranch from "../../../../components/ui/addTypeTemplate/settingTypeBranch";
import { getAllBranch } from "../../../../service/branchAPI";
import { WarningPopup } from "../../../../components/ui/popup/successPopup";
import StatusForm from "../../../../components/ui/formComponent/statusForm";
import { getAllReceiptType } from "../../../../service/orderType";
import { useSchema } from "../../../../components/hooks/use-schema";
import { promotionSchema } from "../../../../components/validation/promotionSchema";
import Loading from "../../../../components/ui/loading";
import { PaddingContainer } from "../../../../components/utils/paddingContainer";
import { getAllSaleProduct } from "../../../../service/saleProductAPI";
import Swal from "sweetalert2";
// import PromotionService from "./promotionService";
// import serviceType from "../../../../components/utils/serviceType.json";
import { ServiceStatus } from "../../../../service/getLocalStorage";
import { GridContainerSize } from "../../../../components/utils/gridContainerSize";
import dayjs from "dayjs";
import { getAllMainCategory } from "../../../../service/mainCategory";
import { MyContext } from "../../../../context/MyContext";
import { BoxWrapper } from "../../../../components/ui/box/boxWrapper";
import PaperWrapper from "../../../../components/ui/paper/paperWrapper";
import { BoxFormTitleAction } from "../../../../components/ui/box/boxFormTitleAction";
import TitleHeaderForm from "../../../../components/ui/formComponent/titleHeaderForm";
import { BoxGapInput } from "../../../../components/ui/box/boxGapInput";

export default function PromotionCreateStep() {
  const [couponCode, setCouponCode] = useState({
    status: false,
    type: "unlimit",
  });
  const [limitUse, setLimitUse] = useState({
    status: false,
    data: null,
  });
  const {
    fetchPromotion,
    setDataBranchPromotion,
    saleProductSetAll,
    setSaleProductSetAll,
    fetchDataImageSaleProduct,
  } = useContext(MyContext);
  const [resultDiscountType, setResultDiscountType] = useState(null);
  const [discountType, setDiscountType] = useState(null);
  const [menuConditionMinimum, setMenuConditionMinimum] = useState(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    control,
    watch,
  } = useForm(
    useSchema(
      promotionSchema(
        resultDiscountType,
        menuConditionMinimum,
        couponCode,
        limitUse.status,
        discountType
      )
    )
  );
  const { t } = useTranslation();
  const navigate = useNavigate();
  // const [serviceSelect, setServiceSelect] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [showTemplate, setShowTemplate] = useState(false);
  const [idTemplate, setIdTemplate] = useState(null);
  const [allDataTemplate, setAllDataTemplate] = useState([]);
  const [productAll, setProductAll] = useState(null);
  const [dataAllProduct, setDataAllProduct] = useState([]);
  const [dialogProduct, setDialogProduct] = useState(false);

  const [productAllFree, setProductAllFree] = useState(null);
  const [dataAllProductFree, setDataAllProductFree] = useState([]);
  const [dialogProductFree, setDialogProductFree] = useState(false);

  const [categoryAll, setCategoryAll] = useState([]);
  const [dataAllCategory, setDataAllCategory] = useState([]);
  const [dialogCategory, setDialogCategory] = useState(false);

  const [categoryAllFree, setCategoryAllFree] = useState([]);
  const [dataAllCategoryFree, setDataAllCategoryFree] = useState([]);
  const [dialogCategoryFree, setDialogCategoryFree] = useState(false);

  const [receiptItemType, setReceiptItemType] = useState(null);
  const [receiptItemTypeFree, setReceiptItemTypeFree] = useState(null);

  const [resultItemFreeAmount, setResultItemFreeAmount] = useState(null);

  const [image, setImage] = useState();
  const [receiptTypeAll, setReceiptTypeAll] = useState([]);
  const [autoApply, setAutoApply] = useState(false);

  const [datePromotionStatus, setDatePromotionStatus] = useState(true);
  const [datePromotionError, setDatePromotionError] = useState(false);
  const [startDate, setStartDate] = useState(dayjs().startOf("date"));
  const [endDate, setEndDate] = useState(dayjs().endOf("date"));
  const [statusButton, setStatusButton] = useState(true);
  const [promotionSchedulesAll, setPromotionSchedulesAll] = useState({
    start_time: new Date().setHours(8, 0, 0),
    end_time: new Date().setHours(18, 0, 0),
    select: false,
  });
  const [promotionSchedulesAllError, setPromotionSchedulesAllError] =
    useState(false);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const dataPromotionType = [
    {
      id: 1,
      name: t("promotion_type_discount_receipt"),
    },
    {
      id: 2,
      name: t("promotion_type_discount_menu"),
    },
    {
      id: 3,
      name: t("promotion_type_discount_free"),
    },
  ];
  const [saleProductAllAPI, setSaleProductAllAPI] = useState([]);
  const [categoryAllAPI, setCategoryAllAPI] = useState([]);
  const [promotionSchedules, setPromotionSchedules] = useState([
    {
      day: "Sunday",
      start_time: new Date().setHours(8, 0, 0),
      end_time: new Date().setHours(18, 0, 0),
      select: true,
    },
    {
      day: "Monday",
      start_time: new Date().setHours(8, 0, 0),
      end_time: new Date().setHours(18, 0, 0),
      select: true,
    },
    {
      day: "Tuesday",
      start_time: new Date().setHours(8, 0, 0),
      end_time: new Date().setHours(18, 0, 0),
      select: true,
    },
    {
      day: "Wednesday",
      start_time: new Date().setHours(8, 0, 0),
      end_time: new Date().setHours(18, 0, 0),
      select: true,
    },
    {
      day: "Thursday",
      start_time: new Date().setHours(8, 0, 0),
      end_time: new Date().setHours(18, 0, 0),
      select: true,
    },
    {
      day: "Friday",
      start_time: new Date().setHours(8, 0, 0),
      end_time: new Date().setHours(18, 0, 0),
      select: true,
    },
    {
      day: "Saturday",
      start_time: new Date().setHours(8, 0, 0),
      end_time: new Date().setHours(18, 0, 0),
      select: true,
    },
  ]);

  const [promotionConjunction, setPromotionConjunction] = useState(false);
  const [promotionMultipleTime, setPromotionMultipleTime] = useState(false);
  const [promotionConjunctionListName, setPromotionConjunctionListName] =
    useState([]);
  const [promotionConjunctionListId, setPromotionConjunctionListId] = useState(
    []
  );
  const onConfirmCreatePromotion = async (register) => {
    if (receiptItemType === 1 && productAll === null) {
      WarningPopup({ title: t("notification_please_select_product_list") });
    } else if (receiptItemType === 2 && categoryAll === null) {
      WarningPopup({ title: t("notification_please_select_category_list") });
    } else if (
      discountType === 3 &&
      productAllFree === null &&
      categoryAllFree === null
    ) {
      if (receiptItemTypeFree === 1 && productAllFree === null) {
        WarningPopup({
          title: t("notification_please_select_product_list_free"),
        });
      }
      if (receiptItemTypeFree === 2 && categoryAllFree === null) {
        WarningPopup({
          title: t("notification_please_select_product_list_free"),
        });
      }
    } else if (
      receiptTypeAll.every((order) => order.select === false) === true
    ) {
      WarningPopup({
        title: t("notification_please_select_order_type"),
      });
    } else if (image) {
      if (localStorage.getItem("uploadImage")) {
        WarningPopup({ title: t("notification_please_upload_image") });
      } else {
        setIsLoadingSubmit(true);
        let promotionConditionProductsBox = [];
        let promotionConditionProductCategoriesBox = [];
        let promotionResultFreeProductsBox = [];
        let promotionResultFreeProductCategoriesBox = [];

        if (productAll) {
          productAll.forEach((itemProduct) => {
            promotionConditionProductsBox.push(itemProduct.id);
          });
        }
        if (productAllFree) {
          productAllFree.forEach((itemProduct) => {
            promotionResultFreeProductsBox.push(itemProduct.id);
          });
        }
        if (categoryAll) {
          categoryAll.forEach((itemCate) => {
            promotionConditionProductCategoriesBox.push(itemCate.id);
          });
        }
        if (categoryAllFree) {
          categoryAllFree.forEach((itemCate) => {
            promotionResultFreeProductCategoriesBox.push(itemCate.id);
          });
        }
        let orderTypeList = [];
        receiptTypeAll.forEach((itemOrder) => {
          if (itemOrder.select === true) {
            orderTypeList.push(itemOrder.id);
          }
        });
        let promotionSchedulesBox = [];
        let statusDate = promotionSchedulesAll.select;
        let dataNew = promotionSchedules.filter(
          (itemPromotion) => itemPromotion.select === true
        );
        dataNew.forEach((itemPromotionDate) => {
          promotionSchedulesBox.push({
            day: itemPromotionDate.day,
            start_time: moment(
              statusDate
                ? promotionSchedulesAll.start_time
                : itemPromotionDate.start_time
            ).format("HHmmss"),
            end_time: moment(
              statusDate
                ? promotionSchedulesAll.end_time
                : itemPromotionDate.end_time
            ).format("HHmmss"),
          });
        });

        let newImage = "";
        if (image) {
          newImage = await uploadImage(image);
        }
        let {
          conditionMinimum,
          resultDiscount,
          amountOfCoupon,
          couponName,
          subCouponName,
          couponDescription,
          promotionName,
          promotionDescription,
          couponCodeTicket,
          limitAmountUse,
        } = register;

        let couponBox = {
          type: null,
          code: null,
          amount: null,
          title: null,
          subtitle: null,
          description: null,
          language_code: null,
        };
        if (couponCode.status !== false) {
          couponBox = {
            type: couponCode.type,
            code: couponCodeTicket,
            amount: couponCode.type === "unlimit" ? 1 : Number(amountOfCoupon),
            title: couponName,
            subtitle: subCouponName,
            description: couponDescription,
            language_code: "all",
          };
        }
        let serviceName = "";
        // if (ServiceStatus) {
        //   serviceName = serviceType.find(
        //     (item) => item.id === serviceSelect
        //   ).valueName;
        // }
        let dataAPI = {
          service: ServiceStatus ? serviceName : "spos",
          discount_type: Number(discountType),
          receipt_item_type: Number(receiptItemType),
          condition_minimum_price:
            menuConditionMinimum === 2 ? Number(conditionMinimum) : null,
          condition_minimum_amount:
            menuConditionMinimum === 1 ? Number(conditionMinimum) : null,
          result_discount_percent:
            resultDiscountType === 2 ? Number(resultDiscount) : null,
          result_discount_price:
            resultDiscountType === 1 ? Number(resultDiscount) : null,
          result_item_free_amount:
            resultItemFreeAmount === 0 ? null : resultItemFreeAmount,
          image: newImage,
          receipt_types: orderTypeList,
          coupon_status: couponCode.status,
          coupon: couponBox,
          auto_apply: autoApply,
          limit_use: limitUse.status
            ? limitAmountUse
              ? Number(limitAmountUse)
              : null
            : null,
          start: datePromotionStatus
            ? dayjs(startDate).format("YYYYMMDDHHmmss")
            : dayjs().startOf("date").format("YYYYMMDDHHmmss"),
          end: datePromotionStatus
            ? dayjs(endDate).format("YYYYMMDDHHmmss")
            : "",
          status: statusButton,
          promotion_lang: {
            name: promotionName,
            description: promotionDescription,
            language_code: "ALL",
          },
          promotion_schedules: promotionSchedulesBox,
          promotion_condition_products: promotionConditionProductsBox,
          promotion_condition_product_categories:
            promotionConditionProductCategoriesBox,
          promotion_result_free_products: promotionResultFreeProductsBox,
          promotion_result_free_product_categories:
            promotionResultFreeProductCategoriesBox,
          stack: promotionMultipleTime,
          shared: promotionConjunction,
          shared_promotions: promotionConjunctionListId,
        };
        let statusAPI = await postPromotion(dataAPI);
        if (statusAPI.message === "OK") {
          fetchPromotion()
            .then(() => {
              notifySuccess({
                title: t("toast_successfully_added_information"),
              });
              if (statusButton) {
                setIdTemplate(statusAPI.data.id);
                fetchAllBranch(statusAPI.data.id);
                setShowTemplate(true);
                setIsLoadingSubmit(false);
              } else {
                navigate("/promotions");
              }
            })
            .catch((error) => {
              console.error("Error fetching product:", error);
            });
        } else {
          setIsLoadingSubmit(false);
        }
      }
    } else {
      WarningPopup({ title: t("notification_please_upload_image") });
    }
  };

  const fetchAllBranch = async (id) => {
    let dataAPI = await getAllBranch();
    dataAPI = dataAPI.filter((item) => item.branch_lang !== null);
    await fetchSettingSelectTemplate(dataAPI, id);
  };
  const fetchSettingSelectTemplate = async (dataBranch, id) => {
    let dataAPI = await getAllPromotionTemplate(id);
    dataAPI = dataAPI[0].template_promotions;
    let dataBox = [];
    dataBranch.forEach((item) => {
      let dataNews = dataAPI.filter(
        (itemTemplate) => itemTemplate.template_id === item.template_id
      );
      if (dataNews.length > 0) {
        dataBox.push({
          branch: item,
          data: dataNews[0],
          select: false,
        });
      } else {
        dataBox.push({
          branch: item,
          data: null,
          select: false,
        });
      }
    });
    await setAllDataTemplate(dataBox);
  };
  const onAddPromotionToTemplate = async () => {
    let branchArray = allDataTemplate.filter((item) => item.select === true);
    let dataSelect = [];
    branchArray.forEach((item) => {
      dataSelect.push(item.branch.id);
    });
    let dataAPI = {
      promotion_id: idTemplate,
      branches: dataSelect,
      priority: 1,
    };
    let dataStatus = await addPromotionToTemplate(dataAPI);
    if (dataStatus === "OK") {
      setDataBranchPromotion([]);
      notifySuccess({
        title: t("toast_successfully_added_information"),
      });
      navigate("/promotions");
    }
  };
  const fetchData = async () => {
    let dataOrderAPI = await getAllReceiptType();
    dataOrderAPI = dataOrderAPI.filter((order) => order.status === true);
    let dataCateAll = await getAllMainCategory();

    let dataSaleProductAll = [];
    if (saleProductSetAll) {
      dataSaleProductAll = saleProductSetAll;
      if (
        dataSaleProductAll.some((item) => item.image.includes("data:image")) ===
        false
      ) {
        fetchDataImageSaleProduct(dataSaleProductAll);
      }
      setSaleProductSetAll(dataSaleProductAll);
    } else {
      dataSaleProductAll = await getAllSaleProduct();
      setSaleProductSetAll(dataSaleProductAll);
      fetchDataImageSaleProduct(dataSaleProductAll);
    }
    setCategoryAllAPI(dataCateAll);
    setSaleProductAllAPI(dataSaleProductAll);

    setValue("conditionMinimum", "");
    setValue("resultDiscount", "");
    dataOrderAPI.forEach((item) => {
      item.select = true;
    });
    setReceiptTypeAll(dataOrderAPI);
    setIsLoading(false);
  };
  const handleChangeDiscount = (item) => {
    if (receiptItemType && receiptItemType !== item) {
      Swal.fire({
        title: t("notification_change_condition_description"),
        showCancelButton: true,
        confirmButtonText: "Confirm",
      }).then((result) => {
        if (result.isConfirmed) {
          let dataNews = [...dataAllProduct];
          dataNews.forEach((cate) =>
            cate.product.forEach((product) => (product.select = false))
          );
          setDataAllProduct(dataNews);

          let cateNews = [...dataAllCategory];
          cateNews.forEach((cate) => (cate.select = false));
          setDataAllCategory(cateNews);

          setReceiptItemType(item);
          setProductAll([]);
          setCategoryAll([]);
        }
      });
    } else {
      setReceiptItemType(item);
    }
  };
  const handleChangeDiscountFree = (item) => {
    if (receiptItemTypeFree && receiptItemTypeFree !== item) {
      Swal.fire({
        title: t("notification_change_condition_description"),
        showCancelButton: true,
        confirmButtonText: "Confirm",
      }).then((result) => {
        if (result.isConfirmed) {
          let dataNews = [...dataAllProductFree];
          dataNews.forEach((cate) =>
            cate.product.forEach((product) => (product.select = false))
          );
          setDataAllProductFree(dataNews);

          let cateNews = [...dataAllCategoryFree];
          cateNews.forEach((cate) => (cate.select = false));
          setDataAllCategoryFree(cateNews);

          setReceiptItemTypeFree(item);
          setProductAllFree([]);
          setCategoryAllFree([]);
        }
      });
    } else {
      setReceiptItemTypeFree(item);
    }
  };
  const handleErrorTime = (newValue, index) => {
    let dataNews = [...promotionSchedules];
    if (newValue) {
      dataNews[index].errorFormat = "wrong";
    } else {
      delete dataNews[index].errorFormat;
    }
    setPromotionSchedules(dataNews);
  };

  const handleErrorTimeAll = (newValue) => {
    if (newValue) {
      setPromotionSchedulesAllError(true);
    } else {
      setPromotionSchedulesAllError(false);
    }
  };
  const handleErrorDate = (newValue) => {
    if (newValue) {
      setDatePromotionError(true);
    } else {
      setDatePromotionError(false);
    }
  };
  // const onServiceSelect = (item) => {
  //   setValue("serviceSelect", item);
  //   clearErrors("serviceSelect");
  //   setServiceSelect(item);
  // };
  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <Box>
      <BreadcrumbsTitle
        props={{
          title: t("promotion_create"),
          dataLink: [
            { text: t("promotion"), link: "/promotions" },
            { text: t("promotion_create"), link: null },
          ],
        }}
      />
      <GridContainerSize>
        <Box p={2}>
          <form onSubmit={handleSubmit(onConfirmCreatePromotion)}>
            <BoxWrapper>
              <PaperWrapper props={{ growFull: 1 }}>
                <BoxFormTitleAction>
                  <TitleHeaderForm props={{ text: t("promotion") }} />
                  <StatusForm
                    statusButton={statusButton}
                    setStatusButton={setStatusButton}
                  />
                </BoxFormTitleAction>
                <BoxGapInput>
                  {isLoading ? (
                    <Loading />
                  ) : (
                    <Box p={2}>
                      {discountType ? (
                        <>
                          <Grid
                            container
                            justifyContent={"space-between"}
                            p={PaddingContainer.size}
                          >
                            <Chip
                              icon={<CategoryIcon />}
                              color="primary"
                              label={
                                dataPromotionType.find(
                                  (item) => item.id === discountType
                                ).name
                              }
                              sx={{ mb: 2, p: 2, width: "100%" }}
                            />
                            {/* <Grid item xs={12}>
                              <PromotionService
                                props={{
                                  control,
                                  errors,
                                  clearErrors,
                                  serviceSelect,
                                  onServiceSelect,
                                  watch,
                                }}
                              />
                            </Grid> */}
                            {/* {ServiceStatus ? (
                      <Grid item xs={12}>
                        <PromotionService
                          props={{
                            control,
                            errors,
                            clearErrors,
                            serviceSelect,
                            onServiceSelect,
                            watch,
                          }}
                        />
                      </Grid>
                    ) : null} */}

                            <Grid item xs={12}>
                              <PromotionCondition
                                props={{
                                  register,
                                  watch,
                                  errors,
                                  setDialogProduct,
                                  productAll,
                                  setDialogProductFree,
                                  productAllFree,
                                  discountType,
                                  receiptItemType,
                                  receiptItemTypeFree,
                                  resultItemFreeAmount,
                                  setResultItemFreeAmount,
                                  categoryAll,
                                  setDialogCategory,
                                  categoryAllFree,
                                  setDialogCategoryFree,
                                  menuConditionMinimum,
                                  setMenuConditionMinimum,
                                  categoryAllAPI,
                                  saleProductAllAPI,
                                  clearErrors,
                                  control,
                                  setValue,
                                  handleChangeDiscount,
                                  handleChangeDiscountFree,
                                }}
                              />
                              {discountType === 3 ? null : (
                                <>
                                  <PromotionResult
                                    props={{
                                      register,
                                      errors,
                                      setValue,
                                      resultDiscountType,
                                      setResultDiscountType,
                                      discountType,
                                      control,
                                      watch,
                                      clearErrors,
                                    }}
                                  />
                                </>
                              )}

                              <PromotionCreateDetail
                                props={{
                                  register,
                                  setValue,
                                  errors,
                                  image,
                                  setImage,
                                  promotionSchedules,
                                  setPromotionSchedules,
                                  promotionSchedulesAll,
                                  setPromotionSchedulesAll,
                                  datePromotionStatus,
                                  setDatePromotionStatus,
                                  startDate,
                                  setStartDate,
                                  endDate,
                                  setEndDate,
                                  autoApply,
                                  setAutoApply,
                                  limitUse,
                                  setLimitUse,
                                  couponCode,
                                  setCouponCode,
                                  receiptTypeAll,
                                  setReceiptTypeAll,
                                  handleErrorTime,
                                  handleErrorTimeAll,
                                  promotionSchedulesAllError,
                                  handleErrorDate,
                                  datePromotionError,
                                  promotionConjunction,
                                  setPromotionConjunction,
                                  promotionMultipleTime,
                                  setPromotionMultipleTime,
                                  discountType,
                                  setPromotionConjunctionListName,
                                  promotionConjunctionListName,
                                  setPromotionConjunctionListId,
                                  clearErrors,
                                  control,
                                }}
                              />

                              <ConfirmButton
                                disabled={
                                  // (ServiceStatus ? !serviceSelect : false) ||
                                  isLoadingSubmit ||
                                  !receiptItemType ||
                                  (discountType === 3
                                    ? !receiptItemTypeFree
                                    : false) ||
                                  promotionSchedules.some(
                                    (date) => date.errorFormat
                                  ) ||
                                  promotionSchedulesAllError ||
                                  datePromotionError
                                }
                              />
                            </Grid>
                          </Grid>
                        </>
                      ) : (
                        <PromotionDiscountType props={{ setDiscountType }} />
                      )}
                      {categoryAllAPI.length > 0 &&
                      saleProductAllAPI.length > 0 ? (
                        <>
                          <SaleProductDialog
                            props={{
                              productAll,
                              setProductAll,
                              dataAllProduct,
                              setDataAllProduct,
                              dialogProduct,
                              setDialogProduct,
                              categoryAllAPI,
                              saleProductAllAPI,
                            }}
                          />
                          <SaleProductDialog
                            props={{
                              productAll: productAllFree,
                              setProductAll: setProductAllFree,
                              dataAllProduct: dataAllProductFree,
                              setDataAllProduct: setDataAllProductFree,
                              dialogProduct: dialogProductFree,
                              setDialogProduct: setDialogProductFree,
                              categoryAllAPI: categoryAllAPI,
                              saleProductAllAPI: saleProductAllAPI,
                            }}
                          />
                          <DialogAddCategory
                            props={{
                              categoryAll,
                              setCategoryAll,
                              dataAllCategory,
                              setDataAllCategory,
                              dialogCategory,
                              setDialogCategory,
                            }}
                          />
                          <DialogAddCategory
                            props={{
                              categoryAll: categoryAllFree,
                              setCategoryAll: setCategoryAllFree,
                              dataAllCategory: dataAllCategoryFree,
                              setDataAllCategory: setDataAllCategoryFree,
                              dialogCategory: dialogCategoryFree,
                              setDialogCategory: setDialogCategoryFree,
                            }}
                          />
                        </>
                      ) : null}
                      <Dialog open={showTemplate} maxWidth="md" fullWidth>
                        <DialogContent>
                          <SettingTypeBranch
                            statusData={true}
                            cancelPath={"/promotions"}
                            title={t("promotion_branch")}
                            onAddTypeToTemplate={onAddPromotionToTemplate}
                            allDataTemplate={allDataTemplate}
                            setAllDataTemplate={setAllDataTemplate}
                          />
                        </DialogContent>
                      </Dialog>
                    </Box>
                  )}
                </BoxGapInput>
              </PaperWrapper>
            </BoxWrapper>
          </form>
        </Box>
      </GridContainerSize>
    </Box>
  );
}
