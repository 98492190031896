import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import DataMaterialTable from "../../../components/ui/dataTable/dataMaterialTable";
import { useTranslation } from "react-i18next";
import { ActionBtn } from "../../../components/ui/dataTable/components/actionBtn";
import { ActionDetail } from "../../../components/ui/dataTable/components/actionDetail";
import {
  notifyDefault,
  notifySuccess,
} from "../../../components/ui/popup/toastPopup";
import { BoxImageBlank } from "../../../components/ui/boxImage/boxImageBlank";
import Swal from "sweetalert2";
import { BoxImage } from "../../../components/ui/boxImage/boxImage";
import BreadcrumbsTitle from "../../../components/ui/breadcrumbsTitle";
import { getAllBankAccount } from "../../../service/selfPickUp/bankaccount/getAllBankAccount";
import { updateBankAccount } from "../../../service/selfPickUp/bankaccount/updateBankAccount";
function SelfPickupBankAccount() {
  const [rows, setRows] = useState([]);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [columns, setColumns] = useState([]);
  const { t } = useTranslation();

  const onUpdateConfirm = async (item, e) => {
    let checked = e.target.checked;
    if (checked) {
      onUpdateStatus(item, checked);
    } else {
      Swal.fire({
        title: t("notification_confirm_change_status"),
        text: t(
          "notification_changing_the_status_will_affect_the_branch_settings"
        ),
        icon: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          onUpdateStatus(item, checked);
          //sss
        }
      });
    }
  };
  const onUpdateStatus = async (item, checked) => {
    setRows((prevRows) => {
      const dataNews = [...prevRows];
      dataNews[item.index].status = checked;
      return dataNews;
    });
    let { account_name, bank_name, branch_name, account_no, image } =
      item.original;

    let dataAPI = {
      id: item.original.id,
      account_name,
      bank_name,
      branch_name,
      account_no,
      image: image,
      status: checked,
    };
    let statusAPI = await updateBankAccount(dataAPI);
    if (statusAPI === "OK") {
      if (checked) {
        notifySuccess({ title: t("toast_active") });
      } else {
        notifyDefault({ title: t("toast_in_active") });
      }
      fetchData();
    }
  };
  const fetchData = async () => {
    let dataColumns = [
      {
        accessorKey: "action",
        enableSorting: false,
        header: "Action",
        size: 100,
        sortable: false,
        Cell: ({ row }) => (
          <Box sx={{ display: "flex", gap: 2 }}>
            <ActionDetail
              params={row}
              detailPath={"/self-pickup-admin-bank-account/detail/"}
            />
          </Box>
        ),
      },
      {
        accessorKey: "image",
        size: 100,
        enableSorting: false,
        header: t("global_image"),
        Cell: ({ row }) => (
          <>
            {row.original.profile_img ? (
              <BoxImage
                image={row.original.profile_img}
                width={"50px"}
                height={"50px"}
                type="table"
              />
            ) : (
              <BoxImageBlank width={50} height={50} />
            )}
          </>
        ),
      },
      {
        accessorKey: "promptpay",
        size: 100,
        header: t("promptpay"),
        Cell: ({ row }) => (
          <ActionBtn row={row} status={row.original.promptpay} disabled={true} />
        ),
      },
      {
        accessorKey: "account_name",
        header: t("name"),
        size: 100,
      },
      {
        accessorKey: "account_no",
        header: t("account no"),
        size: 100,
      },
      {
        accessorKey: "bank_name",
        header: t("bank name"),
        size: 100,
      },
      {
        accessorKey: "branch_name",
        header: t("branch name"),
        size: 100,
      },
      {
        accessorKey: "status",
        enableSorting: false,
        header: t("global_status"),
        size: 100,
        Cell: ({ row }) => (
          <ActionBtn
            row={row}
            status={row.original.status}
            onUpdateStatus={onUpdateConfirm}
          />
        ),
      },
    ];

    let dataAPI = await getAllBankAccount();

    if (dataAPI) {
      dataAPI.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));
      setRows(dataAPI);
    }
    setColumns(dataColumns);

    setIsLoadingData(false);
  };

  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box>
      <BreadcrumbsTitle
        props={{
          title: t("self_pickup_admin_bank_account"),
        }}
      />
      <DataMaterialTable statusViewMode={true}
        rows={rows}
        isLoadingData={isLoadingData}
        columns={columns}
        detailPath={"/self-pickup-admin-bank-account/detail/"}
        createButton={t("self_pickup_admin_bank_account")}
      />
    </Box>
  );
}

export default SelfPickupBankAccount;
