import { Box, Button, Fab, Grid, Typography } from "@mui/material";
import React from "react";
import TitleForm from "../../../../components/ui/formComponent/titleForm";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import GridViewIcon from "@mui/icons-material/GridView";
import { useTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import CategoryIcon from "@mui/icons-material/Category";
import CardPromotionType from "../../../../components/ui/card/cardPromotionType";
import CardItem from "../../../../components/ui/card/cardItem";
import SelectComponent from "../../../../components/ui/textfield/selectComponent";
import promotionMinimumCondition from "../../../../components/utils/promotionMinimumCondition.json";
import TextfieldComponent from "../../../../components/ui/textfield/textfieldComponent";
import i18next from "i18next";
import EmptyDataDirect from "../../../../components/ui/emptyDataDirect";
import PaperWrapper from "../../../../components/ui/paper/paperWrapper";
import { BoxGapInput } from "../../../../components/ui/box/boxGapInput";
function PromotionCondition({ props }) {
  let {
    register,
    errors,
    setDialogProduct,
    productAll,
    setDialogProductFree,
    productAllFree,
    discountType,
    receiptItemType,
    receiptItemTypeFree,
    resultItemFreeAmount,
    setResultItemFreeAmount,
    categoryAll,
    setDialogCategory,
    categoryAllFree,
    setDialogCategoryFree,
    menuConditionMinimum,
    setMenuConditionMinimum,
    categoryAllAPI,
    saleProductAllAPI,
    clearErrors,
    control,
    setValue,
    handleChangeDiscount,
    handleChangeDiscountFree,
    watch,
  } = props;
  const { t } = useTranslation();
  const handleMenuConditionMinimum = (item) => {
    setMenuConditionMinimum(item);
    setValue("menuConditionMinimum", item);
    setValue("conditionMinimum", "");
    clearErrors("menuConditionMinimum");
  };
  const handleAmount = (item) => {
    if (resultItemFreeAmount !== 0) {
      if (item === "decrease") {
        setResultItemFreeAmount(resultItemFreeAmount - 1);
      }
    }
    if (item === "increase") {
      setResultItemFreeAmount(resultItemFreeAmount + 1);
    }
  };

  return (
    <>
      <Box className="boxWrapper-container">
        <PaperWrapper props={{ growFull: 1 }}>
          <TitleForm props={{ text: t("promotion_select_condition") }} />
          <Grid
            container
            sx={{
              p: 2,
              boxSizing: "border-box",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Grid item xs={12} sm={6} p={2}>
              <CardPromotionType
                props={{
                  handleChangeFc: () => handleChangeDiscount(1),
                  icon: (
                    <CheckBoxOutlineBlankIcon
                      sx={{ width: "100%", fontSize: 50 }}
                    />
                  ),
                  valueCard: 1,
                  text: t("sale_product"),
                  receiptItemType: receiptItemType,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} p={2}>
              <CardPromotionType
                props={{
                  handleChangeFc: () => handleChangeDiscount(2),
                  icon: <GridViewIcon sx={{ width: "100%", fontSize: 50 }} />,
                  valueCard: 2,
                  text: t("category"),
                  receiptItemType: receiptItemType,
                }}
              />
            </Grid>
          </Grid>
        </PaperWrapper>
        <PaperWrapper props={{ growFull: 1 }}>
          <TitleForm
            props={{
              text: `${t("promotion_select_list_condition", {
                data:
                  receiptItemType === 1
                    ? t("sale_product")
                    : receiptItemType === 2
                    ? t("category")
                    : "",
              })} `,
            }}
          />

          {receiptItemType === 1 ? (
            <>
              {productAll?.length > 0 ? (
                <Box
                  sx={{
                    display: "flex",
                    gap: 4,
                    p: 2,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Grid container justifyContent="flex-start">
                    {productAll.map((product) => (
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        sx={(theme) => ({
                          p: 1,
                          boxSizing: "border-box",
                        })}
                      >
                        <CardItem props={{ product, name: product.name }} />
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              ) : null}
            </>
          ) : (
            <>
              {categoryAll?.length > 0 ? (
                <Box
                  sx={{
                    display: "flex",
                    gap: 4,
                    p: 2,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Grid container justifyContent="flex-start">
                    {categoryAll.map((itemCate) => (
                      <Grid
                        item
                        xs={6}
                        sx={(theme) => ({ boxSizing: "border-box", p: 1 })}
                      >
                        <Box
                          sx={(theme) => ({
                            boxShadow: 1,
                            borderRadius: 1,
                            boxSizing: "border-box",
                            p: 2,
                            display: "flex",
                            justifyContent: "center",
                            gap: 2,
                            backgroundColor: theme.palette.primary.main,
                            color: theme.palette.white.main,
                          })}
                        >
                          <CategoryIcon />
                          <Typography>{itemCate.name}</Typography>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              ) : null}
            </>
          )}
          <Box px={2} p={2}>
            <Button
              fullWidth
              variant="outlined"
              onClick={() =>
                receiptItemType === 1
                  ? setDialogProduct(true)
                  : setDialogCategory(true)
              }
              disabled={
                !receiptItemType ||
                (receiptItemType === 1
                  ? saleProductAllAPI.length === 0
                  : categoryAllAPI.length === 0)
              }
              sx={{ borderRadius: 2 }}
            >
              {`${t("promotion_select_list_condition", {
                data:
                  receiptItemType === 1
                    ? t("sale_product")
                    : receiptItemType === 2
                    ? t("category")
                    : "",
              })} `}
            </Button>
            <Box mt={1}>
              {receiptItemType === 1 ? (
                <>
                  {saleProductAllAPI.length === 0 ? (
                    <EmptyDataDirect
                      props={{ url: "/sale-product", text: t("sale_product") }}
                    />
                  ) : null}
                </>
              ) : (
                <>
                  {categoryAllAPI.length === 0 ? (
                    <EmptyDataDirect
                      props={{ url: "/category", text: t("category") }}
                    />
                  ) : null}
                </>
              )}
            </Box>
          </Box>
        </PaperWrapper>
        <PaperWrapper props={{ growFull: 1 }}>
          <TitleForm
            props={{ text: t("promotion_select_condition_amount_minimum") }}
          />
          <BoxGapInput>
            <SelectComponent
              title={t("promotion_type_condition")}
              watch={watch}
              register={register}
              name="menuConditionMinimum"
              errors={errors}
              dataEmpty={t("promotion_type_condition_select")}
              optionsData={promotionMinimumCondition[i18next.language]}
              dataValue={menuConditionMinimum}
              onFunctionName={handleMenuConditionMinimum}
              control={control}
              clearErrors={clearErrors}
              fullGrid={true}
            />
            <TextfieldComponent
              // title={t("global_amount")}
              name="conditionMinimum"
              register={register}
              placeholder={t("global_amount_example")}
              errors={errors}
              iconEnd={
                menuConditionMinimum === 2
                  ? "THB"
                  : menuConditionMinimum === 1
                  ? t("global_piece")
                  : ""
              }
              disabled={!menuConditionMinimum}
              fullGrid={true}
            />
          </BoxGapInput>
        </PaperWrapper>
        {Number(discountType) === 3 && (
          <>
            <PaperWrapper props={{ growFull: 1 }}>
              <TitleForm
                props={{ text: t("promotion_select_condition_free") }}
              />
              <Grid
                container
                sx={{
                  p: 2,
                  boxSizing: "border-box",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Grid item xs={12} sm={6} p={2}>
                  <CardPromotionType
                    props={{
                      handleChangeFc: () => handleChangeDiscountFree(1),
                      icon: (
                        <CheckBoxOutlineBlankIcon
                          sx={{ width: "100%", fontSize: 50 }}
                        />
                      ),
                      valueCard: 1,
                      text: t("sale_product"),
                      receiptItemType: receiptItemTypeFree,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} p={2}>
                  <CardPromotionType
                    props={{
                      handleChangeFc: () => handleChangeDiscountFree(2),
                      icon: (
                        <GridViewIcon sx={{ width: "100%", fontSize: 50 }} />
                      ),
                      valueCard: 2,
                      text: t("category"),
                      receiptItemType: receiptItemTypeFree,
                    }}
                  />
                </Grid>
              </Grid>
            </PaperWrapper>
            <PaperWrapper props={{ growFull: 1 }}>
              <TitleForm
                props={{
                  text: `${t("promotion_select_list_condition", {
                    data:
                      receiptItemTypeFree === 1
                        ? t("sale_product")
                        : receiptItemType === 2
                        ? t("category")
                        : "",
                  })} `,
                }}
              />
              <Box
                sx={{
                  display: "flex",
                  gap: 4,
                  p: 2,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {receiptItemTypeFree === 1 ? (
                  <Grid container justifyContent="flex-start">
                    {productAllFree?.length > 0 && (
                      <>
                        {productAllFree.map((product) => (
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={4}
                            sx={(theme) => ({
                              p: 1,
                              boxSizing: "border-box",
                            })}
                          >
                            <CardItem props={{ product, name: product.name }} />
                          </Grid>
                        ))}
                      </>
                    )}
                  </Grid>
                ) : (
                  <>
                    {categoryAllFree?.length > 0 ? (
                      <>
                        <Grid container justifyContent="flex-start">
                          {categoryAllFree.map((itemCateDree) => (
                            <Grid
                              item
                              xs={6}
                              sx={(theme) => ({
                                boxSizing: "border-box",
                                p: 1,
                              })}
                            >
                              <Box
                                sx={(theme) => ({
                                  boxShadow: 1,
                                  borderRadius: 1,
                                  boxSizing: "border-box",
                                  p: 2,
                                  display: "flex",
                                  justifyContent: "center",
                                  gap: 2,
                                  backgroundColor: theme.palette.primary.main,
                                  color: theme.palette.white.main,
                                })}
                              >
                                <CategoryIcon />
                                <Typography>{itemCateDree.name}</Typography>
                              </Box>
                            </Grid>
                          ))}
                        </Grid>
                      </>
                    ) : null}
                  </>
                )}
              </Box>
              <Box px={2} pb={2}>
                <Button
                  fullWidth
                  variant="outlined"
                  onClick={() =>
                    receiptItemTypeFree === 1
                      ? setDialogProductFree(true)
                      : setDialogCategoryFree(true)
                  }
                  sx={{ borderRadius: 2 }}
                  disabled={
                    !receiptItemTypeFree ||
                    (receiptItemTypeFree === 1
                      ? saleProductAllAPI.length === 0
                      : categoryAllAPI.length === 0)
                  }
                >
                  {`${t("promotion_select_list_condition", {
                    data:
                      receiptItemTypeFree === 1
                        ? t("sale_product")
                        : receiptItemTypeFree === 2
                        ? t("category")
                        : "",
                  })} `}
                </Button>

                <Box mt={1}>
                  {receiptItemTypeFree === 1 ? (
                    <>
                      {saleProductAllAPI.length === 0 ? (
                        <EmptyDataDirect
                          props={{
                            url: "/sale-product",
                            text: t("sale_product"),
                          }}
                        />
                      ) : null}
                    </>
                  ) : (
                    <>
                      {categoryAllAPI.length === 0 ? (
                        <EmptyDataDirect
                          props={{ url: "/category", text: t("category") }}
                        />
                      ) : null}
                    </>
                  )}
                </Box>
              </Box>
            </PaperWrapper>
            <PaperWrapper props={{ growFull: 1 }}>
              <TitleForm
                props={{ text: t("promotion_select_amount_minimum") }}
              />
              <Box
                sx={{
                  p: 5,
                }}
              >
                <Box
                  sx={(theme) => ({
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                  })}
                  align="center"
                >
                  <Box>
                    <Typography sx={(theme) => ({ mb: 2 })}>
                      {t("global_amount")}
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: 2,
                      }}
                    >
                      <Fab
                        sx={{ boxShadow: 0 }}
                        aria-label="decrease"
                        size="small"
                        onClick={() => handleAmount("decrease")}
                      >
                        <RemoveIcon />
                      </Fab>
                      <Typography
                        sx={(theme) => ({
                          fontWeight: "bold",
                        })}
                      >
                        {resultItemFreeAmount ? resultItemFreeAmount : 0}
                      </Typography>
                      <Fab
                        sx={{ boxShadow: 0 }}
                        aria-label="increase"
                        size="small"
                        onClick={() => handleAmount("increase")}
                      >
                        <AddIcon />
                      </Fab>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </PaperWrapper>
          </>
        )}
      </Box>
    </>
  );
}

export default PromotionCondition;
