import { Avatar, Box, Typography } from "@mui/material";
import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import "chartjs-plugin-datalabels";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { t } from "i18next";
import colorDashBoard from "../colorDashboard.json";
import percentIcon from "../../../../assets/svg/dashboard/percentIcon.svg";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function BranchPerformance({ props }) {
  let { dataBranchPerformance, branchSelectName } = props;
  let colorBg = colorDashBoard;
  const dataSetsBox = dataBranchPerformance.N04_1.data.data.map(
    (item, index) => {
      let dataNews = {
        label: t(item.name),
        data: item.data.map((item) => Number(item.data.split("%")[0])),
        backgroundColor: colorBg[index],
      };
      return dataNews;
    }
  );
  const datasets = dataSetsBox;
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    indexAxis: "y",
    scales: {
      x: {
        // display: true,
        // grid: {
        //   display: false,
        // },
        ticks: {
          callback: (value) => {
            return `${value}%`;
          },
        },
      },

      y: {
        stacked: true,
        height: 100,
        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        display: true,
      },
      datalabels: {
        display: false,
        color: "white",
        formatter: (value) => (value ? `${value.toFixed(2)}%` : null), // Format percentage to two decimal places
      },
      tooltip: {
        callbacks: {
          title: function (context) {
            return `Branch: ${context[0].label}`;
          },

          label: function (context) {
            return `${context.dataset.label}: ${context.raw.toLocaleString()}%`;
          },
        },
      },
    },
  };

  const labels = branchSelectName;

  const data = {
    labels,
    datasets: datasets,
  };
  return (
    <>
      <Box mb={2} sx={{ display: "flex", alignItems: "center", gap:1 }}>
        <Avatar
          src={percentIcon}
          sx={{ borderRadius: 0, width: 18, height: 18 }}
        />
        <Typography variant="h4">Total Sales vs Total Receipts</Typography>
      </Box>
      <div
        style={{
          height:
            branchSelectName.length > 3
              ? "40vh"
              : branchSelectName.length === 2
              ? "30vh"
              : "20vh",
        }}
      >
        <Bar options={options} plugins={[ChartDataLabels]} data={data} />
      </div>
    </>
  );
}

export default BranchPerformance;
