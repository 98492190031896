import * as yup from "yup";
import { validationSchemas } from "./data";
export const saleProductSchema = (statusMainCategory) =>
  yup.object().shape({
    saleProductName: validationSchemas.name,
    productNameReceipt: validationSchemas.name,
    unit: validationSchemas.numberStringSelectValidation,
    descriptionSaleProduct: validationSchemas.free,
    priceSaleProduct: validationSchemas.number,
    mainCategory: validationSchemas.numberStringSelectValidation,
    // minorCategory:
    //   statusMainCategory === true
    //     ? validationSchemas.numberSelect
    //     : validationSchemas.free,
    productType: validationSchemas.numberSelect,
  });
