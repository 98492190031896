import { expiredToken } from "./expiredToken";
import {
  AccessTokenAPI,
  BusinessId,
  CompanyId,
  LanguageForm,
  ServiceId,
} from "./getLocalStorage";

import axios from "axios";

export const postUnitCounter = (dataAPI) => {
  let config = {
    method: "post",
    url: `${process.env.REACT_APP_API_URL}/api/setting/storeUnitCounter?company_uid=${CompanyId}&business_uid=${BusinessId}&service_code=${ServiceId}`,
    headers: {
      Authorization: `Bearer ${AccessTokenAPI}`,
      "Content-Type": "application/json",
    },
    data: JSON.stringify(dataAPI),
  };
  return axios(config)
    .then(function (response) {
      return response.data.message;
    })
    .catch(function (error) {
      console.log(error);
      expiredToken(error);
    });
};
export const getAllUnitCounter = () => {
  let config = {
    method: "get",
    url: `${process.env.REACT_APP_API_URL}/api/setting/getAllUnitCounter?language_code=${LanguageForm}&company_uid=${CompanyId}&business_uid=${BusinessId}&service_code=${ServiceId}`,
    headers: {
      Authorization: `Bearer ${AccessTokenAPI}`,
    },
  };

  return axios(config)
    .then(function (response) {
      let dataBox = response.data.data;
      let dataModel = [];
      dataBox.forEach((item) => {
        if (item.unit_counter_lang) {
          dataModel.push({
            id: item.unit_counter_lang.unit_counter_id,
            name: item.unit_counter_lang.name,
            status: item.status,
            unit_counter_lang: item.unit_counter_lang,
          });
        }
      });
      return dataModel;
    })
    .catch(function (error) {
      console.log(error);
      expiredToken(error);
    });
};
export const getUnitCounter = (paramsId) => {
  let dataAPI = "";
  let config = {
    method: "get",
    url: `${process.env.REACT_APP_API_URL}/api/setting/getUnitCounter?id=${paramsId}&language_code=${LanguageForm}&company_uid=${CompanyId}&business_uid=${BusinessId}&service_code=${ServiceId}`,
    headers: {
      Authorization: `Bearer ${AccessTokenAPI}`,
    },
    data: dataAPI,
  };
  return axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      console.log(error);
      expiredToken(error);
    });
};
export const updateUnitCounter = (dataAPI) => {
  let config = {
    method: "put",
    url: `${process.env.REACT_APP_API_URL}/api/setting/updateUnitCounter?company_uid=${CompanyId}&business_uid=${BusinessId}&service_code=${ServiceId}`,
    headers: {
      Authorization: `Bearer ${AccessTokenAPI}`,
      "Content-Type": "application/json",
    },
    data: JSON.stringify(dataAPI),
  };

  return axios(config)
    .then(function (response) {
      return response.data.message;
    })
    .catch(function (error) {
      console.log(error);
      expiredToken(error);
    });
};
