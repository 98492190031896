import { IconButton, Box } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
export function ActionDetail(props) {
  const { idParams, params, detailPath, settingIconButton } = props;
  const navigate = useNavigate();
  const handleMenuEdit = (params) => {
    if (idParams) {
      navigate(detailPath + idParams);
    } else {
      navigate(detailPath + params.original.id, { state: "edit" });
    }
  };

  return (
    <Box sx={{ display: "flex", justifyContent: "flex-start", gap: "1rem" }}>
      <IconButton
        aria-label="Example"
        onClick={() => handleMenuEdit(params)}
        // sx={{ boxShadow: 5 }}
      >
        {settingIconButton ? settingIconButton : <EditIcon />}
      </IconButton>
    </Box>
  );
}
