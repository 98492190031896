import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import StatusForm from "../../../../components/ui/formComponent/statusForm";
import ConfirmButton from "../../../../components/ui/button/confirmButton";
import { notifySuccess } from "../../../../components/ui/popup/toastPopup";
import { t } from "i18next";
import Loading from "../../../../components/ui/loading";
import SelfPickupThemeForm from "./selfPickupThemeForm";
import { getSelfPickupTheme } from "../../../../service/selfPickUp/config/getSelfPickupTheme";
import { updateSelfPickupTheme } from "../../../../service/selfPickUp/config/updateSelfPickupTheme";

function SelfPickupTheme() {
  const [primaryColor, setPrimaryColor] = useState("#ffffff");
  const [secondaryColor, setSecondaryColor] = useState("#ffffff");
  const [statusButton, setStatusButton] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const fetchData = async () => {
    setIsLoading(true);
    let dataAPI = await getSelfPickupTheme();
    let { primary_color, secondary_color } = dataAPI;
    setPrimaryColor(primary_color);
    setSecondaryColor(secondary_color);
    setIsLoading(false);
  };
  const onConfirmForm = async () => {
    setIsSubmitting(true);

    setTimeout(() => {
      setIsSubmitting(false);
    }, 1000);
    let dataAPI = {
      background_image: "",
      primary_color: primaryColor,
      secondary_color: secondaryColor,
    };
    let statusAPI = await updateSelfPickupTheme(dataAPI);
    if (statusAPI === "OK") {
      notifySuccess({
        title: t("notification_successfully_edited_information"),
      });
      fetchData();
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <Box p={1}>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <SelfPickupThemeForm
            props={{
              primaryColor,
              setPrimaryColor,
              secondaryColor,
              setSecondaryColor,
            }}
          />
          <StatusForm
            statusButton={statusButton}
            setStatusButton={setStatusButton}
          />
          <ConfirmButton
            disabled={isSubmitting}
            onClick={() => onConfirmForm()}
          />
        </>
      )}
    </Box>
  );
}

export default SelfPickupTheme;
