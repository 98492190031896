import axios from "axios";
import {
  AccessTokenAPI,
  BusinessId,
  CompanyId,
  ServiceId,
} from "../../getLocalStorage";

export const updateSelfPickupBranchSchedule = async (dataAPI) => {
  var config = {
    method: "post",
    url: `${process.env.REACT_APP_API_URL}/api/setting/selfpickup/updateSelfPickupBranchSchedule?company_uid=${CompanyId}&business_uid=${BusinessId}&service_code=${ServiceId}`,
    headers: {
      Authorization: `Bearer ${AccessTokenAPI}`,
      "Content-Type": "application/json",
    },
    data: JSON.stringify(dataAPI),
  };

  return axios(config)
    .then(function (response) {
      return response.data.message;
    })
    .catch(function (error) {
      console.log(error);
    });
};
