import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { postMoneyOut } from "../../../service/moneyOutAPI";
import { notifySuccess } from "../../../components/ui/popup/toastPopup";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import BreadcrumbsTitle from "../../../components/ui/breadcrumbsTitle";
import { useSchema } from "../../../components/hooks/use-schema";
import { moneyOutSchema } from "../../../components/validation/moneyOutSchema";
import MoneyOutForm from "./moneyOutForm";
import { GridContainerSize } from "../../../components/utils/gridContainerSize";
import { Box } from "@mui/material";

function MoneyOutCreate(props) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm(useSchema(moneyOutSchema));
  const { t } = useTranslation();
  const [statusButton, setStatusButton] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const onConfirmForm = async (register) => {
    setIsSubmitting(true);
    let dataAPI = {
      status: statusButton,
      money_out_lang: {
        name: register.moneyOut,
        language_code: "ALL",
      },
    };
    let statusAPI = await postMoneyOut(dataAPI);
    if (statusAPI === "OK") {
      notifySuccess({
        title: t("toast_successfully_added_information"),
      });
      navigate("/money-out");
    }
    await new Promise((resolve) => setTimeout(resolve, 2000));
    setIsSubmitting(false);
  };
  return (
    <div align="left">
      <BreadcrumbsTitle
        props={{
          title: t("money_out_create"),
          dataLink: [
            {
              text: t("money_out"),
              link: "/money-out",
            },
            {
              text: t("money_out_create"),
              link: null,
            },
          ],
        }}
      />
      <GridContainerSize>
        <Box p={2}>
          <MoneyOutForm
            props={{
              handleSubmit,
              onConfirmForm,
              register,
              errors,
              statusButton,
              setStatusButton,
              isSubmitting,
            }}
          />
        </Box>
      </GridContainerSize>
    </div>
  );
}

export default MoneyOutCreate;
