import { expiredToken } from "./expiredToken";
import {
  AccessTokenAPI,
  BusinessId,
  CompanyId,
  LanguageForm,
  ServiceId,
} from "./getLocalStorage";

import axios from "axios";

export const postDevice = (dataAPI) => {
  let config = {
    method: "post",
    url: `${process.env.REACT_APP_API_URL}/api/setting/storeDevice?company_uid=${CompanyId}&business_uid=${BusinessId}&service_code=${ServiceId}`,
    headers: {
      Authorization: `Bearer ${AccessTokenAPI}`,
      "Content-Type": "application/json",
    },

    data: JSON.stringify(dataAPI),
  };

  return axios(config)
    .then(function (response) {
      return response.data.message;
    })
    .catch(function (error) {
      console.log(error);
    });
};
export const getAllDevice = () => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_API_URL}/api/setting/getAllDevice?language_code=${LanguageForm}&company_uid=${CompanyId}&business_uid=${BusinessId}&service_code=${ServiceId}`,
    headers: {
      Authorization: `Bearer ${AccessTokenAPI}`,
    },
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      console.log(error);
      expiredToken(error);
    });
};
export const getDevice = (paramsId) => {
  let data = "";

  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_API_URL}/api/setting/getDevice?id=${paramsId}&language_code=${LanguageForm}&company_uid=${CompanyId}&business_uid=${BusinessId}&service_code=${ServiceId}`,
    headers: {
      Authorization: `Bearer ${AccessTokenAPI}`,
    },
    data: data,
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      console.log(error);
      expiredToken(error);
    });
};
export const updateDevice = (dataAPI) => {
  let config = {
    method: "put",
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_API_URL}/api/setting/updateDevice?company_uid=${CompanyId}&business_uid=${BusinessId}&service_code=${ServiceId}`,
    headers: {
      Authorization: `Bearer ${AccessTokenAPI}`,
      "Content-Type": "application/json",
    },
    data: JSON.stringify(dataAPI),
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data.message;
    })
    .catch((error) => {
      console.log(error);
      expiredToken(error);
    });
};
