import React, { useState } from "react";
import { Avatar, Box, Grid, Typography } from "@mui/material";
import { t } from "i18next";
// import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
// import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import basicCart from "../../../../assets/svg/dashboard/basicCart.svg";
function TableDashboard({ props }) {
  const { listOfSaleProduct, setListOfSaleProduct } = props;
  const [statusSort, setStatusSort] = useState({ QTY: false, Sale: false });
  const onClickSortBestSale = (type, status) => {
    let dataSort = [...listOfSaleProduct];
    if (type === "QTY") {
      dataSort.sort((a, b) => a.pct_total_QTY - b.pct_total_QTY);
    }
    if (type === "QTY" && status === "Reverse") {
      dataSort.reverse((a, b) => b.pct_total_QTY - a.pct_total_QTY);
    }
    if (type === "Sale") {
      dataSort.sort((a, b) => a.pct_total_sale - b.pct_total_sale);
    }
    if (type === "Sale" && status === "Reverse") {
      dataSort.reverse((a, b) => b.pct_total_sale - a.pct_total_sale);
    }
    setStatusSort({ ...statusSort, [type]: !statusSort[type] });
    setListOfSaleProduct(dataSort);
  };
  return (
    <Box p={2} className="grid-paper-white" sx={{ height: "100%" }}>
      <Box
        sx={(theme) => ({
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          gap: 1,
          color: theme.palette.primaryText.main,
        })}
      >
        <Avatar
          src={basicCart}
          sx={{ borderRadius: 0, width: 24, height: 24 }}
        />
        <Typography
          variant="h3"
          sx={{
            fontWeight: "500",
          }}
        >
          Top 10 Best Sale product
        </Typography>
      </Box>
      <Grid container mt={2}>
        <Grid
          item
          xs={12}
          sx={(theme) => ({
            borderBottom: "2px solid #cccccc",
            color: theme.palette.primaryText.main,
            fontSize: 12,
          })}
        >
          <Grid container>
            <Grid item xs={1} align="center"></Grid>
            <Grid item xs={4} align="left">
              <Typography variant="h5">{t("product_name")}</Typography>
            </Grid>
            <Grid item xs={3} align="left">
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <Typography variant="h5">{t("Branch name")}</Typography>
              </Box>
            </Grid>
            <Grid item xs={2} align="center">
              {/* Total QTY */}
              {/* <ShoppingCartIcon /> */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography variant="h5">QTY</Typography>
                {!statusSort.QTY ? (
                  <ArrowDropDownIcon
                    onClick={() => onClickSortBestSale("QTY", "Sort")}
                    sx={{
                      fontSize: 14,
                      ":hover": { opacity: 0.8, cursor: "pointer" },
                    }}
                  />
                ) : (
                  <ArrowDropUpIcon
                    onClick={() => onClickSortBestSale("QTY", "Reverse")}
                    sx={{
                      fontSize: 14,
                      ":hover": { opacity: 0.8, cursor: "pointer" },
                    }}
                  />
                )}
              </Box>
            </Grid>
            <Grid item xs={2} align="center">
              {/* Total Sales */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography variant="h5">Sale</Typography>
                {/* <MonetizationOnIcon /> */}
                {!statusSort.Sale ? (
                  <ArrowDropDownIcon
                    onClick={() => onClickSortBestSale("Sale", "Sort")}
                    sx={{
                      fontSize: 14,
                      ":hover": { opacity: 0.8, cursor: "pointer" },
                    }}
                  />
                ) : (
                  <ArrowDropUpIcon
                    onClick={() => onClickSortBestSale("Sale", "Reverse")}
                    sx={{
                      fontSize: 14,
                      ":hover": { opacity: 0.8, cursor: "pointer" },
                    }}
                  />
                )}
              </Box>
            </Grid>
          </Grid>
        </Grid>
        {listOfSaleProduct.map((item, index) => (
          <Grid
            item
            xs={12}
            sx={(theme) => ({
              borderBottom: "2px solid #cccccc80",
              my: 1,
              color: theme.palette.primaryText.main,
            })}
          >
            <Grid container>
              <Grid item xs={1} align="center">
                <Typography sx={{ fontSize: 12 }}>{index + 1}</Typography>
              </Grid>
              <Grid item xs={4} align="left">
                <Typography sx={{ fontSize: 12 }}>
                  {item.product_name}
                </Typography>
              </Grid>
              <Grid item xs={3} align="left">
                <Typography sx={{ fontSize: 12 }}>
                  {item.leading_branch}
                </Typography>
              </Grid>
              <Grid item xs={2} align="center">
                <Typography sx={{ fontSize: 12 }}>
                  {item.pct_total_QTY.toFixed(2)}%
                </Typography>
              </Grid>
              <Grid item xs={2} align="center">
                <Typography sx={{ fontSize: 12 }}>
                  {item.pct_total_sale.toFixed(2)}%
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default TableDashboard;
