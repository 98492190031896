import { Box, Grid } from "@mui/material";
import React from "react";
import TitleForm from "../../../../components/ui/formComponent/titleForm";
import { t } from "i18next";
import SelectComponent from "../../../../components/ui/textfield/selectComponent";
import serviceType from "../../../../components/utils/serviceType.json";

function PromotionService({ props }) {
  const {
    errors,
    clearErrors,
    control,
    serviceSelect,
    onServiceSelect,
    watch,
  } = props;
  return (
    <Grid item xs={12} className="grid-paper-white">
      <TitleForm props={{ text: t("promotion_service") }} />
      <Box p={2}>
        <SelectComponent
          name="serviceSelect"
          watch={watch}
          errors={errors}
          dataEmpty={t("promotion_service_select")}
          optionsData={serviceType}
          dataValue={serviceSelect}
          clearErrors={clearErrors}
          fullGrid={true}
          onFunctionName={onServiceSelect}
          control={control}
        />
      </Box>
    </Grid>
  );
}

export default PromotionService;
