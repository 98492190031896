import React, { useState, useEffect } from "react";
import { Grid, Box, Typography, Drawer } from "@mui/material";
import { Outlet, useLocation } from "react-router-dom";
import MenuItem from "./menuItem";
import MenuIcon from "@mui/icons-material/Menu";
import { ListManagement } from "./listMenu/listManagement";
import { ListSettingPos } from "./listMenu/listSettingPos";
import { useTranslation } from "react-i18next";
import IconButton from "@mui/material/IconButton";
import AirplayIcon from "@mui/icons-material/Airplay";
import Loading from "../ui/loading";
import TypographyTitleSidebar from "./typographyTitleSidebar";
import { ListDashboard } from "./listMenu/listDashBoard";
import LogoSPOS from "../../assets/images/LogoSPOS.png";
import { MenuOpen } from "@mui/icons-material";
import { ListReport } from "./listMenu/listReport";
// import LogoBGCRM from "../../assets/images/new-bg1.png";

function Sidebar() {
  const { t } = useTranslation();
  const listManagementItem = ListManagement();
  const listSettingPosItem = ListSettingPos();
  const listReport = ListReport();
  const listDashboard = ListDashboard();
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const [menuExpanded, setMenuExpanded] = useState(false);
  const [menuExpandedSub, setMenuExpandedSub] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [drawerBar, setDrawerBar] = useState(false);
  const fetchData = async () => {
    if (localStorage.getItem("side_bar_expanded") === null) {
      localStorage.setItem("side_bar_expanded", "expand");
    }
    if (localStorage.getItem("showTable") === null) {
      localStorage.setItem("showTable", "table");
    }
    localStorage.removeItem("headSidebar");
    let sidebarExpandedSS =
      localStorage.getItem("side_bar_expanded") === "expand" ? false : true;
    setMenuExpanded(sidebarExpandedSS);
    const currentPath = location.pathname;
    listManagementItem.forEach((itemMenu) => {
      if (itemMenu.subMenus !== undefined) {
        itemMenu.subMenus.forEach((itemMenuSub) => {
          if (currentPath.includes(itemMenuSub.to)) {
            setExpanded(itemMenu);
          }
        });
      } else {
        if (currentPath.includes(itemMenu.to)) {
          setExpanded(itemMenu);
        }
      }
    });
    listSettingPosItem.forEach((itemMenu) => {
      if (itemMenu.subMenus !== undefined) {
        itemMenu.subMenus.forEach((itemMenuSub) => {
          if (currentPath.includes(itemMenuSub.to)) {
            setExpanded(itemMenu);
          }
        });
      } else {
        if (currentPath.includes(itemMenu.to)) {
          setExpanded(itemMenu);
        }
      }
    });
    listDashboard.forEach((itemMenu) => {
      if (itemMenu.subMenus !== undefined) {
        itemMenu.subMenus.forEach((itemMenuSub) => {
          if (currentPath.includes(itemMenuSub.to)) {
            setExpanded(itemMenu);
          }
        });
      } else {
        if (currentPath.includes(itemMenu.to)) {
          setExpanded(itemMenu);
        }
      }
    });
    listReport.forEach((itemMenu) => {
      if (itemMenu.subMenus !== undefined) {
        itemMenu.subMenus.forEach((itemMenuSub) => {
          if (currentPath.includes(itemMenuSub.to)) {
            setExpanded(itemMenu);
          }
        });
      } else {
        if (currentPath.includes(itemMenu.to)) {
          setExpanded(itemMenu);
        }
      }
    });

    setIsLoading(false);
  };
  const listDrawer = () => (
    <Box
      sx={(theme) => ({
        width: 300,
        height: "100vh",
        overflow: "auto",
        boxSizing: "border-box",
        p: 1,
      })}
      role="presentation"
      className="smoothTransition"
    >
      <img src={LogoSPOS} style={{ width: "108px" }} alt="logo" />
      {/* <SelectLanguage props={{ menuExpanded }} /> */}
      <TypographyTitleSidebar
        props={{
          text: menuExpanded
            ? t("side_bar_dashboard")
            : `${t("side_bar_dashboard")}`,
          menuExpanded,
        }}
      />
      {listDashboard.map((menuItem, index) => (
        <React.Fragment key={index}>
          <MenuItem
            props={{
              menuExpandedSub,
              setMenuExpandedSub,
              menuItem,
              menuExpanded,
              setDrawerBar,
              expanded,
              setExpanded,
              setMenuExpanded,
              drawerBar,
            }}
          />
        </React.Fragment>
      ))}
      <TypographyTitleSidebar
        props={{
          text: menuExpanded
            ? t("side_bar_reports")
            : `${t("side_bar_reports")}`,
          menuExpanded,
        }}
      />
      {listReport.map((menuItem, index) => (
        <MenuItem
          props={{
            menuExpandedSub,
            setMenuExpandedSub,
            menuItem,
            menuExpanded,
            setDrawerBar,
            expanded,
            setExpanded,
            setMenuExpanded,
            drawerBar,
          }}
        />
      ))}
      <TypographyTitleSidebar
        props={{
          text: menuExpanded
            ? t("side_bar_menu")
            : `${t("side_bar_management_menu")}`,
          menuExpanded,
        }}
      />
      {listManagementItem.map((menuItem, index) => (
        <React.Fragment key={index}>
          <MenuItem
            props={{
              menuExpandedSub,
              setMenuExpandedSub,
              menuItem,
              menuExpanded,
              setDrawerBar,
              expanded,
              setExpanded,
              setMenuExpanded,
              drawerBar,
            }}
          />
        </React.Fragment>
      ))}

      <TypographyTitleSidebar
        props={{
          text: menuExpanded ? "POS" : `${t("side_bar_setting")}`,
          menuExpanded,
        }}
      />
      {listSettingPosItem.map((menuItem, index) => (
        <React.Fragment key={index}>
          <MenuItem
            props={{
              menuExpandedSub,
              setMenuExpandedSub,
              menuItem,
              menuExpanded,
              setDrawerBar,
              expanded,
              setExpanded,
              setMenuExpanded,
              drawerBar,
            }}
          />
        </React.Fragment>
      ))}
    </Box>
  );
  const onMenuExpanded = (value) => {
    setMenuExpanded(value);
    setMenuExpandedSub(false);
    // setExpanded(false);
    localStorage.setItem("side_bar_expanded", value ? "collapse" : "expand");
  };
  const onDrawerMobile = (value) => {
    setDrawerBar(value);
    setMenuExpanded(false);
  };
  useEffect(() => {
    fetchData();
  }, [window.location.pathname]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {/* <Typography sx={{ position: "absolute", zIndex: 9999 }}>
        {window.innerWidth}
      </Typography> */}
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {window.innerWidth < 600 ? (
            <>
              <Box
                container
                direction="row"
                sx={{
                  textAlign: "start",
                }}
              >
                <Box
                  sx={(theme) => ({
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    boxSizing: "border-box",
                    px: 2,
                    boxShadow: 5,
                  })}
                >
                  <Box
                    sx={(theme) => ({
                      gap: 2,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                    })}
                  >
                    <Box
                      sx={(theme) => ({
                        gap: 2,
                        display: "flex",
                        alignItems: "center",
                        color: theme.palette.primary.main,
                      })}
                    >
                      <IconButton
                        size="large"
                        edge="start"
                        aria-label="menu"
                        onClick={() => onDrawerMobile(true)}
                      >
                        <MenuIcon />
                      </IconButton>
                      <AirplayIcon />
                      <Typography
                        sx={(theme) => ({
                          fontFamily: "Kanit",
                          // color: theme.palette.primary.main,
                        })}
                      >
                        Service Business
                      </Typography>
                    </Box>
                    {/* <SelectLanguage /> */}
                  </Box>
                </Box>
                <Box
                  sx={(theme) => ({
                    height: "95vh",
                    overflow: "auto",
                    boxSizing: "border-box",
                    backgroundColor: theme.palette.bgSizeBarRight.main,
                  })}
                >
                  <Outlet />
                </Box>
                <Drawer
                  anchor={"left"}
                  open={drawerBar}
                  onClose={() => onDrawerMobile(false)}
                >
                  {listDrawer()}
                </Drawer>
              </Box>
            </>
          ) : (
            <>
              <Grid
                container
                direction="row"
                sx={{
                  textAlign: "start",
                  position: "relative",
                  boxSizing: "border-box",
                }}
              >
                <Typography
                  sx={(theme) => ({
                    position: "absolute",
                    bottom: 10,
                    left: 20,
                    zIndex: 99,
                    color: theme.palette.primaryText.main + "50",
                  })}
                >
                  V.{process.env.REACT_APP_VERSION_CONTROL}
                </Typography>
                <Grid
                  item
                  xs={12}
                  sm={menuExpanded ? 1 : 3.5}
                  md={menuExpanded ? 0.8 : 2}
                  lg={menuExpanded ? 0.8 : 2}
                  xl={menuExpanded ? 0.5 : 1.5}
                  className="smoothTransition"
                  sx={(theme) => ({
                    height: "100vh",
                    boxSizing: "border-box",
                    // boxShadow: "2px 8px 20px #00000020",
                    borderRight: "1px solid #cccccc70",
                    zIndex: 3,
                    backgroundColor: theme.palette.white.main,
                    // backgroundColor: "#fff",
                  })}
                >
                  <Box>
                    <Box
                      sx={(theme) => ({
                        boxSizing: "border-box",
                        display: "flex",
                        justifyContent: menuExpanded
                          ? "center"
                          : "space-between",
                        height: "64px",
                        px: 2,
                      })}
                    >
                      <Box
                        sx={{
                          width: "100%",
                          display: menuExpanded ? "none" : "flex",
                          alignItems: "center",
                          gap: 1,
                          px: 1,
                        }}
                      >
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <img
                            src={LogoSPOS}
                            style={{ width: "108px" }}
                            alt="logo"
                          />
                        </Box>
                      </Box>
                      <Box sx={{ display: "grid", placeItems: "center" }}>
                        <IconButton
                          size="large"
                          aria-label="menu"
                          onClick={() => onMenuExpanded(!menuExpanded)}
                          className="smoothTransition"
                        >
                          {menuExpanded ? (
                            <MenuOpen
                              sx={(theme) => ({
                                color: theme.palette.primary.main,
                                fontSize: 30,
                                transform: "rotate(180deg)",
                              })}
                            />
                          ) : (
                            <MenuOpen
                              sx={(theme) => ({
                                color: theme.palette.primary.main,
                                fontSize: 30,
                              })}
                            />
                          )}
                        </IconButton>
                      </Box>
                    </Box>
                    <Box sx={(theme) => ({ p: 1.5 })}>
                      <Box
                        sx={(theme) => ({
                          height: "86vh",
                          overflow: "auto",
                          boxSizing: "border-box",
                          position: menuExpanded ? null : "relative",
                          [theme.breakpoints.down("md")]: {
                            height: "85vh",
                          },
                        })}
                      >
                        <TypographyTitleSidebar
                          props={{
                            text: menuExpanded
                              ? t("side_bar_reports")
                              : `${t("side_bar_reports")}`,
                            menuExpanded,
                          }}
                        />
                        {listDashboard.map((menuItem, index) => (
                          <React.Fragment key={index}>
                            <MenuItem
                              props={{
                                menuExpandedSub,
                                setMenuExpandedSub,
                                menuItem,
                                menuExpanded,
                                setDrawerBar,
                                expanded,
                                setExpanded,
                                setMenuExpanded,
                                drawerBar,
                              }}
                            />
                          </React.Fragment>
                        ))}

                        {/* <TypographyTitleSidebar
                          props={{
                            text: menuExpanded
                              ? t("side_bar_reports")
                              : `${t("side_bar_reports")}`,
                            menuExpanded,
                          }}
                        /> */}
                        {listReport.map((menuItem, index) => (
                          <MenuItem
                            props={{
                              menuExpandedSub,
                              setMenuExpandedSub,
                              menuItem,
                              menuExpanded,
                              setDrawerBar,
                              expanded,
                              setExpanded,
                              setMenuExpanded,
                              drawerBar,
                            }}
                          />
                        ))}
                        <TypographyTitleSidebar
                          props={{
                            text: menuExpanded
                              ? `${t("side_bar_menu")}`
                              : `${t("side_bar_management_menu")}`,
                            menuExpanded,
                          }}
                        />
                        {listManagementItem.map((menuItem, index) => (
                          <React.Fragment key={index}>
                            <MenuItem
                              props={{
                                menuExpandedSub,
                                setMenuExpandedSub,
                                menuItem,
                                menuExpanded,
                                setDrawerBar,
                                expanded,
                                setExpanded,
                                setMenuExpanded,
                                drawerBar,
                              }}
                            />
                          </React.Fragment>
                        ))}
                        <TypographyTitleSidebar
                          props={{
                            text: menuExpanded
                              ? "POS"
                              : `${t("side_bar_setting")}`,
                            menuExpanded,
                          }}
                        />
                        {listSettingPosItem.map((menuItem, index) => (
                          <React.Fragment key={index}>
                            <MenuItem
                              props={{
                                menuExpandedSub,
                                setMenuExpandedSub,
                                menuItem,
                                menuExpanded,
                                setDrawerBar,
                                expanded,
                                setExpanded,
                                setMenuExpanded,
                                drawerBar,
                              }}
                            />
                          </React.Fragment>
                        ))}
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={menuExpanded ? 11 : 8.5}
                  md={menuExpanded ? 11.2 : 10}
                  lg={menuExpanded ? 11.2 : 10}
                  xl={menuExpanded ? 11.5 : 10.5}
                  sx={(theme) => ({
                    height: "100vh",
                    overflow: "auto",
                    position: "relative",
                  })}
                  onClick={() =>
                    window.innerWidth < 600 ? setMenuExpanded(true) : null
                  }
                  className="smoothTransition"
                >
                  <Box
                    sx={(theme) => ({
                      [theme.breakpoints.down("md")]: {
                        height: "100vh",
                      },
                      overflow: "auto",
                      height: "100vh",
                      boxSizing: "border-box",
                      backgroundColor: theme.palette.bgSizeBarRight.main,
                      // backgroundImage: `url(${LogoBGCRM})`,
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                      position: "relative",
                    })}
                  >
                    <Outlet />
                  </Box>
                </Grid>
              </Grid>
            </>
          )}
        </>
      )}
    </>
  );
}
export default Sidebar;
