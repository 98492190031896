import React, { useState } from "react";
import {
  Box,
  Button,
  Skeleton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Download, HelpOutline, Search } from "@mui/icons-material";
import Paper from "@mui/material/Paper";
import * as XLSX from "xlsx";

const DataTableReport = (props) => {
  let { rows, columns, isLoadingData, exportColumnName, fileName } = props;
  const [dataSearch, setDataSearch] = useState([]);
  const [order, setOrder] = useState("asc"); // 'asc' or 'desc'
  const [orderBy, setOrderBy] = useState(""); // Column to sort by
  const [page, setPage] = useState(0); // Current page
  const [rowsPerPage, setRowsPerPage] = useState(10); // Rows per page
  const theme = useTheme();
  const { t } = useTranslation();

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const sortData = (data) => {
    const comparator = (a, b) => {
      if (orderBy) {
        const valA = a[orderBy] || 0;
        const valB = b[orderBy] || 0;

        // If sorting by time (HH:mm), convert to minutes for comparison
        if (orderBy === "paymentTime") {
          const timeToMinutes = (time) => {
            const [hours, minutes] = time.split(":").map(Number);
            return hours * 60 + minutes; // Convert time to total minutes
          };
          const timeA = timeToMinutes(valA);
          const timeB = timeToMinutes(valB);
          return order === "asc" ? timeA - timeB : timeB - timeA;
        }

        // If sorting by date, convert to Date objects for comparison
        if (orderBy === "paymentDate") {
          const dateA = new Date(valA);
          const dateB = new Date(valB);
          return order === "asc" ? dateA - dateB : dateB - dateA;
        }

        // For other fields (numbers or strings)
        return order === "asc" ? valA - valB : valB - valA;
      }
      return 0;
    };

    return [...data].sort(comparator);
  };

  // const sortedData = sortData(rows);
  const sortedData = sortData(dataSearch.length ? dataSearch : rows);
  const onChangeSearch = (e) => {
    let search = e.target.value.toLowerCase(); // Convert the search term to lowercase for case-insensitive search
    let dataFind = [];
    dataFind = rows.filter((item) => {
      // Check if any of the values in the object match the search term
      return Object.values(item).some((val) =>
        String(val).toLowerCase().includes(search)
      );
    });

    setDataSearch(dataFind);
  };
  const renderSkeletonRows = () => {
    return Array.from(new Array(5)).map((_, index) => (
      <TableRow key={index}>
        {columns.map((column) => (
          <TableCell key={column.field}>
            <Skeleton variant="text" />
          </TableCell>
        ))}
      </TableRow>
    ));
  };
  const downloadCSV = () => {
    const csvContent = exportColumnName;
    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.setAttribute("href", url);
    a.setAttribute("download", `${fileName}.csv`);
    a.click();
  };

  const downloadExcel = () => {
    if (!Array.isArray(rows)) {
      console.error("rows is not an array");
      return;
    }

    const headers = columns.reduce((acc, item) => {
      acc[item.accessorKey] = item.header;
      return acc;
    }, {});
    const worksheetData = [
      Object.values(headers), // First row contains custom headers
      ...rows.map(
        (item) => Object.keys(headers).map((key) => item[key] ?? "") // Map data according to header order
      ),
    ];
    worksheetData.forEach((row) => {
      row.forEach((cell, index) => {
        if (typeof cell === "number") {
          row[index] = cell.toFixed(2); // Format numeric values
        } else if (typeof cell === "object") {
          row[index] = cell.join(","); // Convert objects to strings
        }
      });
    });
    const ws = XLSX.utils.aoa_to_sheet(worksheetData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, `${fileName}.xlsx`);
  };

  // Pagination handlers
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset page to the first when changing page size
  };

  const getTotalForColumn = (column) => {
    const paginatedData = sortedData.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    );
    return paginatedData
      .reduce((sum, row) => sum + (Number(row[column.accessorKey]) || 0), 0)
      .toFixed(column.decimal === 0 ? 0 : 2);
  };

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: "#efefef",
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));
  // let tableShow = localStorage.getItem("showTable");
  return (
    <Box
      sx={{
        p: 2,
        boxSizing: "border-box",
      }}
    >
      <Box
        sx={(theme) => ({
          width: "100%",
          [theme.breakpoints.down("sm")]: {
            p: 0,
          },
          p: 2,
          boxSizing: "border-box",
          borderRadius: 2,
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#ffffff", // Background color
          gap: 2,
        })}
      >
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Box sx={{ gap: 2, display: "flex" }}>
            {/* <Box sx={{ display: "flex", gap: 1 }}> */}
            <TextField
              size="small"
              style={{
                backgroundColor: "#ffffff", // Background color
              }}
              onChange={(e) => onChangeSearch(e)}
              // placeholder="Search by Phone number"
              placeholder="Search"
              InputProps={{ endAdornment: <Search /> }}
            />
            {/* {renderButtonSearch ? renderButtonSearch() : null} */}
            {/* </Box> */}
            <Button
              startIcon={<Download />}
              variant="outlined"
              onClick={downloadCSV}
            >
              CSV
            </Button>
            <Button
              startIcon={<Download />}
              variant="outlined"
              onClick={downloadExcel}
            >
              Excel
            </Button>
          </Box>
        </Box>
        <TableContainer
          component={Paper}
          sx={{
            height: rowsPerPage > 10 ? "65vh" : null,
            overflowY: "auto",
          }}
        >
          <Table aria-label="simple table" stickyHeader>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.accessorKey}
                    sx={{}}
                    style={{
                      minWidth: column.minWidth,
                      position: column.fixed ? "sticky" : "relative",
                      left: column.fixed ? 0 : "auto",
                      // background: column.fixed ? "#fff" : "inherit",
                      zIndex: column.fixed ? 1000 : "auto",
                      borderRight: "1px solid #ddd",
                      // borderRight: column.fixed ? "1px solid #ddd" : "none",
                      cursor: "pointer",
                    }}
                    sortDirection={
                      orderBy === column.accessorKey ? order : false
                    }
                    onClick={() => handleRequestSort(column.accessorKey)}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 0.5,
                        justifyContent: column.center ? "center" : "flex-start",
                      }}
                    >
                      {column.header}
                      {column.accessorKey === "subTotal" && (
                        <>
                          {" "}
                          <Tooltip
                            title={`${t("report_summary_price")}-${t(
                              "report_discount_by_item"
                            )}`}
                          >
                            <HelpOutline sx={{ fontSize: 14 }} />
                          </Tooltip>
                        </>
                      )}
                      {column.accessorKey === "beforeVat" && (
                        <>
                          {" "}
                          <Tooltip
                            title={`${t("report_sub_total")} - ${t(
                              "report_service_charge"
                            )}`}
                          >
                            <HelpOutline sx={{ fontSize: 14 }} />
                          </Tooltip>
                        </>
                      )}
                      {column.accessorKey === "total" && (
                        <>
                          <Tooltip
                            title={`(${t("report_before_vat")} + ${t(
                              "report_vat"
                            )} + ${t(
                              "report_rounding_amount"
                            )})- Non-VAT amount`}
                          >
                            <HelpOutline sx={{ fontSize: 14 }} />
                          </Tooltip>
                        </>
                      )}
                      {/* Sorting arrows for numeric columns */}
                      {column.isSorting && column.accessorKey === orderBy && (
                        <> {order === "asc" ? " ↑" : " ↓"}</>
                      )}
                    </Box>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {isLoadingData ? (
                <>{renderSkeletonRows()}</>
              ) : (
                <>
                  {sortedData
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => (
                      <StyledTableRow key={index}>
                        {columns.map((column, colIndex) => {
                          const isClickable =
                            typeof column.onClick === "function";
                          return (
                            <TableCell
                              key={colIndex}
                              onClick={
                                isClickable
                                  ? () => column.onClick(row)
                                  : undefined
                              }
                              style={{
                                position: column.fixed ? "sticky" : "relative",
                                left: column.fixed ? 0 : "auto",
                                background: column.fixed ? "#fff" : "inherit",
                                zIndex: column.fixed ? 1000 : "auto",
                                borderRight: "1px solid #ddd",
                                cursor: isClickable ? "pointer" : "default",
                                color: isClickable
                                  ? theme.palette.secondary.main
                                  : "inherit",
                              }}
                            >
                              {column.Cell ? (
                                column.Cell({
                                  cell: {
                                    getValue: () => row[column.accessorKey],
                                  },
                                })
                              ) : (
                                <Typography variant="body1">
                                  {row[column.accessorKey]}
                                </Typography>
                              )}
                            </TableCell>
                          );
                        })}
                      </StyledTableRow>
                    ))}

                  <TableRow key="total" style={{ backgroundColor: "#fff" }}>
                    <TableCell
                      sx={{
                        borderRight: "1px solid #fff",
                        position: "sticky",
                        left: 0,
                        background: "#ddd",
                        zIndex: 1000,
                      }}
                    >
                      <b>Total</b>
                    </TableCell>
                    {columns.map((column, colIndex) => {
                      if (colIndex === 0) return null; // Skip first column ("Total" label)

                      const total = column.isSorting
                        ? getTotalForColumn(column)
                        : "";

                      return (
                        <TableCell
                          key={colIndex}
                          sx={{
                            borderRight: "1px solid #ddd",
                            textAlign: column.center ? "center" : "right",
                          }}
                        >
                          <Typography
                            variant="body1"
                            sx={{
                              fontWeight: 500,
                            }}
                          >
                            {total}
                          </Typography>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={sortedData.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[10, 25, 50]} // Page size options
          labelRowsPerPage="Rows per page"
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to} of ${count}`
          }
        />
      </Box>
    </Box>
  );
};

export default DataTableReport;
