import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormHelperText,
} from "@mui/material";
import ImageCrop from "../../../../components/ui/upload/imageCrop";
import { t } from "i18next";
import { configUpdatePaymentChannel } from "../../../../service/branchConfigAPI/branchConfigPaymentChannel";
import {
  notifyError,
  notifySuccess,
} from "../../../../components/ui/popup/toastPopup";
import { uploadImage } from "../../../../service/upload/uploadImage";
import { getImage } from "../../../../service/upload/getImage";
import { UseConvertImageToBase64 } from "../../../../components/hooks/use-convert-image-to-base-64";
import Loading from "../../../../components/ui/loading";

function ImageUploadPaymentDialog({ props }) {
  let { dialogCropImage, setDialogCropImage, templateId } = props;
  let [image, setImage] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const onConfirmUpdatePaymentChannel = async () => {
    let newImage = "";
    if (image) {
      if (typeof image === "object") {
        newImage = await uploadImage(image);
      } else {
        newImage = dialogCropImage.data.image;
      }
    }
    if (!newImage) {
      notifyError({
        title: t("notification_please_upload_image"),
      });
    } else {
      let dataAPI = {
        template_id: templateId,
        payment_channel_id: dialogCropImage.data.payment_channel_id,
        priority: dialogCropImage.data.priority,
        image: newImage,
        status: dialogCropImage.data.status,
      };
      let statusAPI = await configUpdatePaymentChannel(dataAPI);
      if (statusAPI === "OK") {
        notifySuccess({
          title: t("notification_successfully_edited_information"),
        });
        setDialogCropImage({
          status: false,
          data: null,
        });
      }
    }
  };
  const fetchData = async () => {
    if (dialogCropImage.data.image) {
      let imageUrl = await getImage(dialogCropImage.data.image);
      let imageNew = UseConvertImageToBase64(imageUrl);
      await Promise.all(imageUrl);
      setImage(imageNew);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Dialog open={dialogCropImage.status} maxWidth="sm" fullWidth>
      <DialogContent>
        {isLoading ? (
          <Loading />
        ) : (
          <Box align="center">
            <ImageCrop
              props={{
                setImage,
                image,
                heightCrop: 256,
                widthCrop: 256,
                label: t("payment_type_qr_code"),
              }}
            />
            <FormHelperText sx={{ textAlign: "center" }}>
              Click remove for upload new image
            </FormHelperText>
          </Box>
        )}
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          borderTop: "1px solid #cccccc",
          gap: 1,
          p: 2,
          boxSizing: "border-box",
        }}
      >
        <Button
          color="info"
          onClick={() => setDialogCropImage({ status: false, data: null })}
          variant="outlined"
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          type="button"
          onClick={() => onConfirmUpdatePaymentChannel()}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ImageUploadPaymentDialog;
