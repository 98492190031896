import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Chip,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import TitleForm from "../formComponent/titleForm";
import { useNavigate } from "react-router-dom";
import DirectionsIcon from "@mui/icons-material/Directions";
import WarningIcon from "@mui/icons-material/Warning";
import { useTranslation } from "react-i18next";
import StoreIcon from "@mui/icons-material/Store";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

function SettingTypeBranch(props) {
  let {
    onAddTypeToTemplate,
    allDataTemplate,
    setAllDataTemplate,
    cancelPath,
    directToBranch,
  } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isDisabledBtn, setIsButtonDisabled] = useState(false);
  const onControlBucket = (item, index, e) => {
    let dataSelect = [...allDataTemplate];
    dataSelect = dataSelect.filter(
      (itemBranch) => itemBranch.branch.status === true
    );
    dataSelect[index].select = e.target.checked;
    setAllDataTemplate(dataSelect);
  };
  const onDirectToCreateBranch = (e) => {
    localStorage.setItem("pathName", t("sideBar.management.branch"));
    localStorage.removeItem("expanded");
    navigate("/branch/create");
  };
  const handleConfirm = () => {
    setIsButtonDisabled(true);
    onAddTypeToTemplate();
    setTimeout(() => {
      setIsButtonDisabled(false);
    }, 1500);
  };
  return (
    <Box>
      <TitleForm
        props={{ text: t("global_add_information_to", { data: t("branch") }) }}
      />
      <Box sx={{ p: 2 }}>
        {allDataTemplate.length !== 0 ? (
          <>
            <Box>
              {allDataTemplate.length === 0 ||
              allDataTemplate.some(
                (itemBranch) => itemBranch.branch.status === true
              ) === false ? (
                <>
                  <Box
                    sx={(theme) => ({
                      width: "100%",
                      p: 2,
                    })}
                    align={"center"}
                  >
                    <WarningIcon sx={{ fontSize: 100 }} />
                    <Typography p={2}>{t("branch_not_found")}</Typography>
                  </Box>
                  <Box
                    sx={(theme) => ({
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: 1,
                      width: "100%",
                      p: 2,
                      backgroundColor:
                        theme.palette.mode === "dark" ? "#202020" : null,
                      gap: 1,
                      ":hover": { cursor: "pointer", opacity: 0.5 },
                    })}
                    onClick={(e) => onDirectToCreateBranch(e)}
                  >
                    <DirectionsIcon />
                    <Typography>{t("global_direct_to_list")}</Typography>
                  </Box>
                </>
              ) : (
                <>
                  {allDataTemplate
                    .filter((itemBranch) => itemBranch.branch.status === true)
                    .map((itemBranch, index) => (
                      <label htmlFor={`check-select-branch${index}`}>
                        <Box
                          sx={(theme) => ({
                            boxShadow: 2,
                            borderRadius: 1,
                            display: "flex",
                            alignItems: "center",
                            gap: 1,
                            mb: 2,
                            pr: 2,
                            ":hover": { cursor: "pointer" },
                          })}
                        >
                          <ListItem>
                            <ListItemAvatar>
                              <Avatar
                                sx={(theme) => ({
                                  backgroundColor: theme.palette.primary.main,
                                })}
                              >
                                <StoreIcon />
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              primary={
                                <Typography
                                  sx={(theme) => ({
                                    [theme.breakpoints.down("sm")]: {
                                      width: "100px",
                                    },
                                    width: "100%",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    fontFamily: "Kanit",
                                    fontSize: "18px",
                                    color: theme.palette.primaryText.main,
                                  })}
                                >
                                  {itemBranch.branch.branch_lang.name}
                                </Typography>
                              }
                              // secondary={
                              //   itemBranch.data?.status
                              //     ? t("toast_active")
                              //     : t("global_waiting_for_active")
                              // }
                              secondary={
                                <Chip
                                  label={
                                    itemBranch.branch?.status ? (
                                      <Typography
                                        sx={{
                                          fontSize: 13,
                                        }}
                                      >
                                        {t("global_active")}
                                      </Typography>
                                    ) : (
                                      <Typography
                                        sx={{
                                          fontSize: 13,
                                        }}
                                      >
                                        {t("global_in_active")}
                                      </Typography>
                                    )
                                  }
                                  color={
                                    itemBranch.data?.status
                                      ? "success"
                                      : "default"
                                  }
                                  icon={
                                    itemBranch.data?.status ? (
                                      <FiberManualRecordIcon
                                        sx={(theme) => ({
                                          fontSize: 13,
                                        })}
                                      />
                                    ) : null
                                  }
                                  sx={(theme) => ({
                                    color: itemBranch.data?.status
                                      ? theme.palette.success.main
                                      : theme.palette.white.main,
                                    backgroundColor: itemBranch.data?.status
                                      ? theme.palette.successBg.main
                                      : "#ccc",
                                    borderRadius: 2,
                                  })}
                                />
                              }
                            />
                          </ListItem>
                          {itemBranch.data ? (
                            <>
                              {itemBranch.data?.status ? (
                                <>
                                  <Chip
                                    onClick={() =>
                                      itemBranch.data
                                        ? itemBranch.data.status === false ||
                                          itemBranch.data
                                          ? navigate(
                                              "/branch/detail/" +
                                                itemBranch.branch.id,
                                              {
                                                state: directToBranch,
                                              }
                                            )
                                          : null
                                        : null
                                    }
                                    sx={(theme) => ({
                                      backgroundColor:
                                        theme.palette.primary.main,
                                      color: "#fff",
                                    })}
                                    avatar={
                                      <DirectionsIcon sx={{ color: "#fff" }} />
                                    }
                                    label="Setting Branch"
                                  />
                                </>
                              ) : (
                                <>
                                  <Chip
                                    onClick={() =>
                                      itemBranch.data
                                        ? itemBranch.data.status === false ||
                                          itemBranch.data
                                          ? navigate(
                                              "/branch/detail/" +
                                                itemBranch.branch.id,
                                              {
                                                state: directToBranch,
                                              }
                                            )
                                          : null
                                        : null
                                    }
                                    sx={(theme) => ({
                                      backgroundColor:
                                        theme.palette.primary.main,
                                      color: "#fff",
                                    })}
                                    avatar={
                                      <DirectionsIcon sx={{ color: "#fff" }} />
                                    }
                                    label={t("global_waiting_for_active")}
                                  />
                                </>
                              )}
                            </>
                          ) : (
                            <>
                              {/* <Switch
                            color="primary"
                            onChange={(e) =>
                              onControlBucket(itemBranch, index, e)
                            }
                            checked={itemBranch.data?.status}
                          /> */}
                              <Checkbox
                                id={`check-select-branch${index}`}
                                checked={itemBranch.data?.status}
                                onChange={(e) =>
                                  onControlBucket(itemBranch, index, e)
                                }
                              />
                            </>
                          )}
                        </Box>
                      </label>
                    ))}
                </>
              )}
            </Box>
            <Box>
              <Button
                variant="contained"
                color="primary"
                sx={(theme) => ({ borderRadius: 2 })}
                onClick={() => handleConfirm()}
                fullWidth
                disabled={
                  allDataTemplate.filter((item) => item.select === true)
                    .length === 0 || isDisabledBtn
                }
              >
                {isDisabledBtn ? (
                  <>{t("global_is_loading")}</>
                ) : (
                  <>
                    {t("branch_submit_data_to_template", {
                      data: allDataTemplate.filter(
                        (item) => item.select === true
                      ).length,
                    })}
                  </>
                )}
              </Button>
            </Box>
          </>
        ) : (
          <>
            {/* {directToBranch ? ( */}
            <Box
              sx={(theme) => ({
                boxSizing: "border-box",
                display: "flex",
                justifyContent: "center",
                py: 2,
                color: theme.palette.primaryText.main,
              })}
            >
              <Box align="center">
                <WarningIcon sx={{ fontSize: 100 }} />
                <Typography>
                  {t("branch_please_active_information_before_setting_branch")}
                </Typography>
              </Box>
            </Box>
            {/* ) : (
            <>
              <Loading />
            </>
          )} */}
          </>
        )}
        {/* {cancelPath ? (
        <Button
          variant="outlined"
          onClick={() => navigate(cancelPath)}
          fullWidth
          sx={{ borderRadius: 2 }}
        >
          {t("cancel_button")}
        </Button>
      ) : null} */}

        {cancelPath ? (
          <Box
            align="center"
            sx={{
              width: "100px",
              display: "flex",
              justifyContent: "center",
              margin: "auto",
            }}
          >
            <Typography
              onClick={() => navigate(cancelPath)}
              fullWidth
              sx={{
                mt: 2,
                borderRadius: 2,
                cursor: "pointer",
                opacity: 0.5,
                ":hover": { opacity: 1 },
              }}
            >
              {t("cancel_button")}
            </Typography>
          </Box>
        ) : null}
      </Box>
    </Box>
  );
}

export default SettingTypeBranch;
