import React from "react";
import PaperWrapper from "../../../components/ui/paper/paperWrapper";
import { t } from "i18next";
import TextfieldComponent from "../../../components/ui/textfield/textfieldComponent";
import StatusForm from "../../../components/ui/formComponent/statusForm";
import ConfirmButton from "../../../components/ui/button/confirmButton";
import { BoxWrapper } from "../../../components/ui/box/boxWrapper";
import { BoxFormTitleAction } from "../../../components/ui/box/boxFormTitleAction";
import TitleHeaderForm from "../../../components/ui/formComponent/titleHeaderForm";
import { BoxGapInput } from "../../../components/ui/box/boxGapInput";

function MoneyInForm({ props }) {
  const {
    handleSubmit,
    onConfirmForm,
    register,
    errors,
    statusButton,
    setStatusButton,
    isSubmitting,
  } = props;
  return (
    <div>
      <form onSubmit={handleSubmit(onConfirmForm)}>
        <BoxWrapper>
          <PaperWrapper props={{ growFull: 1 }}>
            <BoxFormTitleAction>
              <TitleHeaderForm props={{ text: t("money_in") }} />
              <StatusForm
                statusButton={statusButton}
                setStatusButton={setStatusButton}
              />
            </BoxFormTitleAction>
            <BoxGapInput>
              <TextfieldComponent
                title={t("money_in_name")}
                name="moneyIn"
                register={register}
                placeholder={t("money_in_example")}
                errors={errors}
                fullGrid={true}
              />
              <ConfirmButton disabled={isSubmitting} />
            </BoxGapInput>
          </PaperWrapper>
        </BoxWrapper>
      </form>
    </div>
  );
}

export default MoneyInForm;
