import "./App.css";
import React from "react";
import { StyledEngineProvider } from "@mui/material/styles";
// Settings
import { I18nextProvider } from "react-i18next";
import i18n from "./locals/i18n";
import AppRouter from "./router/appRouter";
import { MyProvider } from "./context/MyContext";

function App() {
  return (
    <MyProvider>
      <StyledEngineProvider injectFirst>
        <I18nextProvider i18n={i18n}>
          <AppRouter />
        </I18nextProvider>
      </StyledEngineProvider>
    </MyProvider>
  );
}
export default App;
