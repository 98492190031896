import {
  Box,
  FormControlLabel,
  Switch,
  FormHelperText,
  Radio,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Loading from "../../../../components/ui/loading";
import { getAllCurrency } from "../../../../service/currencyAPI";
import {
  configGetAllCurrency,
  configUpdateAllCurrency,
} from "../../../../service/branchConfigAPI/branchConfigCurrency";
import { notifySuccess } from "../../../../components/ui/popup/toastPopup";
import {
  configGetAllServiceCharge,
  configUpdateServiceCharge,
} from "../../../../service/branchConfigAPI/branchConfigServiceCharge";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useSchema } from "../../../../components/hooks/use-schema";
import { branchPaymentSchema } from "../../../../components/validation/branch/branchPayment";
import SelectComponent from "../../../../components/ui/textfield/selectComponent";
import TextfieldComponent from "../../../../components/ui/textfield/textfieldComponent";
import PercentIcon from "@mui/icons-material/Percent";
import EmptyDataDirect from "../../../../components/ui/emptyDataDirect";
import { TitleTextfield } from "../../../../components/ui/textfield/textfieldCustom";
import ConfirmButton from "../../../../components/ui/button/confirmButton";
import PaperWrapper from "../../../../components/ui/paper/paperWrapper";
import TitleForm from "../../../../components/ui/formComponent/titleForm";
import { BoxGapInput } from "../../../../components/ui/box/boxGapInput";
import { BoxWrapper } from "../../../../components/ui/box/boxWrapper";
import { BoxFormTitleAction } from "../../../../components/ui/box/boxFormTitleAction";
import TitleHeaderForm from "../../../../components/ui/formComponent/titleHeaderForm";
function BranchConfigure(props) {
  let { templateId, setCurrentProduct } = props;
  const {
    register,
    formState: { errors },
    setValue,
    watch,
    handleSubmit,
    getValues,
    control,
    clearErrors,
  } = useForm(useSchema(branchPaymentSchema));
  const { t } = useTranslation();
  const [currencyAll, setCurrencyAll] = useState([]);
  const [currency, setCurrency] = useState("");
  const [statusServiceCharge, setStatusServiceCharge] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [roundUpStatus, setRoundUpStatus] = useState(false);
  const [importItemStatus, setImportItemStatus] = useState(false);
  const [vatType, setVatType] = useState(false);
  const [serviceChargeData, setServiceChargeData] = useState(null);
  // const [serviceChargeStatus, setServiceChargeStatus] = useState(false);
  const fetchData = async () => {
    // currency
    await fetchAllCurrency();
    // unit
    await fetchServiceCharge();
    // Loading
    await setIsLoading(false);
  };
  // Currency
  const fetchAllCurrency = async () => {
    let dataAPI = await getAllCurrency();
    dataAPI = dataAPI.filter((payment) => payment.currency_lang !== null);
    dataAPI = dataAPI.filter((payment) => payment.status !== false);
    let currencyBox = [];
    dataAPI.forEach((itemCurrency) => {
      if (
        itemCurrency.currency_lang !== null &&
        itemCurrency.status !== false
      ) {
        currencyBox.push({
          id: itemCurrency.id,
          name: itemCurrency.currency_lang.name,
        });
      }
    });
    setCurrencyAll(currencyBox);
    fetchConfigGetAllCurrency(dataAPI);
  };
  const fetchConfigGetAllCurrency = async (currencyPack) => {
    let dataAPI = await configGetAllCurrency(templateId);
    setCurrency(dataAPI.currency_id ? dataAPI.currency_id : "");
    setValue("currency", dataAPI.currency_id ? dataAPI.currency_id : "");
  };
  const updateConfigGetAllCurrency = async (currencyId) => {
    setValue("currency", currencyId);
    let currencyBranch = currencyAll.find((item) => item.id === currencyId);
    setCurrentProduct(currencyBranch.name);
    let dataAPI = {
      template_id: String(templateId),
      currency_id: String(currencyId),
    };
    setCurrency(currencyId);
    let dataStatus = await configUpdateAllCurrency(dataAPI);
    if (dataStatus === "OK") {
      notifySuccess({
        title: t("notification_successfully_edited_information"),
      });
    }
  };
  //Service Charge
  const fetchServiceCharge = async () => {
    let dataAPI = await configGetAllServiceCharge(templateId);

    let {
      service_charge,
      service_charge_status,
      round_up,
      import_item,
      vat_type,
      vat,
    } = dataAPI;
    setServiceChargeData(dataAPI);
    setValue("serviceCharge", service_charge);
    setRoundUpStatus(round_up);
    setImportItemStatus(import_item);
    await setVatType(vat_type === "include" ? true : false);
    setValue("vat", vat);
    setStatusServiceCharge(service_charge_status);
    setValue("statusServiceCharge", service_charge_status);
  };
  const updateConfigServiceCharge = async (register) => {
    setIsSubmitted(true);
    let dataAPI = {
      template_id: String(templateId),
      template_config_id: String(serviceChargeData.id),
      service_charge_status: statusServiceCharge,
      service_charge: Number(register.serviceCharge),
      round_up: roundUpStatus,
      import_item: importItemStatus,
      vat_type: vatType ? "include" : "exclude",
      vat: Number(register.vat),
    };
    let dataStatus = await configUpdateServiceCharge(dataAPI);
    if (dataStatus === "OK") {
      notifySuccess({
        title: t("notification_successfully_edited_information"),
      });
      setIsSubmitted(false);
      await fetchServiceCharge();
    }
  };
  const updateConfigServiceChargeRoundUp = async (statusRoundUp) => {
    setRoundUpStatus(statusRoundUp);
    let dataAPI = {
      template_id: String(templateId),
      template_config_id: String(serviceChargeData.id),
      service_charge_status: statusServiceCharge,
      service_charge: Number(getValues("serviceCharge")),
      round_up: statusRoundUp,
      import_item: importItemStatus,
      vat_type: vatType ? "include" : "exclude",
      vat: Number(getValues("vat")),
    };
    let dataStatus = await configUpdateServiceCharge(dataAPI);
    if (dataStatus === "OK") {
      notifySuccess({
        title: t("notification_successfully_edited_information"),
      });
      await fetchServiceCharge();
    }
  };
  const onVatType = (itemCheck, e) => {
    setValue("vat", "");
    setVatType(itemCheck);
    clearErrors("vat");
  };

  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <div>
      {isLoading ? (
        <>
          <Loading />
        </>
      ) : (
        <Box
          className="boxWrapper-container"
          sx={(theme) => ({
            maxWidth: "800px",
            margin: "auto",
            pt: 2,
            [theme.breakpoints.down("md")]: { px: 2 },
            pb: 4,
          })}
        >
          <BoxWrapper>
            <PaperWrapper props={{ growFull: 1 }}>
              <BoxFormTitleAction>
                <TitleHeaderForm props={{ text: t("currency") }} />
              </BoxFormTitleAction>
              <BoxGapInput>
                <PaperWrapper props={{ growFull: 1 }}>
                  <TitleForm props={{ text: t("currency_select") }} />
                  <Box p={2}>
                    <SelectComponent
                      title={t("currency")}
                      watch={watch}
                      register={register}
                      name="currency"
                      errors={errors}
                      dataEmpty={t("currency_select")}
                      optionsData={currencyAll}
                      dataValue={currency}
                      onFunctionName={updateConfigGetAllCurrency}
                      fullGrid={true}
                      control={control}
                      clearErrors={clearErrors}
                      disabled={currencyAll.length === 0}
                      dataAlert={
                        <>
                          {currencyAll.length === 0 ? (
                            <EmptyDataDirect
                              props={{
                                url: "/currency",
                                text: t("currency"),
                              }}
                            />
                          ) : null}
                        </>
                      }
                    />
                  </Box>
                </PaperWrapper>
              </BoxGapInput>
            </PaperWrapper>
          </BoxWrapper>

          <form onSubmit={handleSubmit(updateConfigServiceCharge)}>
            <BoxWrapper>
              <PaperWrapper props={{ growFull: 1 }}>
                <BoxFormTitleAction>
                  <TitleHeaderForm
                    props={{ text: t("branch_vat_and_service_charge") }}
                  />
                  <Box align="end">
                    <FormControlLabel
                      onChange={(e) => setStatusServiceCharge(e.target.checked)}
                      control={<Switch checked={statusServiceCharge} />}
                      label={
                        statusServiceCharge
                          ? t("global_active")
                          : t("global_in_active")
                      }
                    />
                  </Box>
                </BoxFormTitleAction>
                <BoxGapInput>
                  <Box
                    sx={{ display: "flex", flexDirection: "column" }}
                  >
                    <Box sx={{ display: "flex", gap: 2 }}>
                      <PaperWrapper props={{ growFull: 1 }}>
                        <TitleForm
                          props={{
                            text: t("branch_vat"),
                          }}
                        />
                        {/* <TitleTextfield text={t("branch_vat")} /> */}
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 1,
                            p: 2,
                            boxSizing: "border-box",
                          }}
                        >
                          {/* <TitleTextfield text={t("global_service_charge")} /> */}
                          <TextfieldComponent
                            title={t("branch_vat")}
                            name="vat"
                            register={register}
                            placeholder={t("global_vat_example")}
                            errors={errors}
                            iconEnd={<PercentIcon />}
                            fullGrid={true}
                          />
                          <Box>
                            <FormControlLabel
                              control={
                                <Radio
                                  name="include"
                                  checked={vatType}
                                  onChange={(e) => onVatType(true)}
                                />
                              }
                              label={t("branch_vat_include")}
                            />
                            <FormControlLabel
                              control={
                                <Radio
                                  name="exclude"
                                  checked={!vatType}
                                  onChange={(e) => onVatType(false)}
                                />
                              }
                              label={t("branch_vat_exclude")}
                            />
                          </Box>
                        </Box>
                      </PaperWrapper>
                      <PaperWrapper props={{ growFull: 1 }}>
                        <TitleForm
                          props={{
                            text: t("branch_service_charge"),
                          }}
                        />
                        {/* <TitleTextfield text={t("branch_vat")} /> */}
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 1,
                            p: 2,
                            boxSizing: "border-box",
                          }}
                        >
                          <TextfieldComponent
                            title={t("global_service_charge")}
                            name="serviceCharge"
                            register={register}
                            placeholder={t("global_service_example")}
                            errors={errors}
                            iconEnd={<PercentIcon />}
                            disabled={!statusServiceCharge}
                            fullGrid={true}
                          />
                        </Box>
                      </PaperWrapper>
                    </Box>
                    <ConfirmButton disabled={isSubmitted} />
                  </Box>
                </BoxGapInput>
              </PaperWrapper>
            </BoxWrapper>
          </form>
          <BoxWrapper>
            <PaperWrapper props={{ growFull: 1 }}>
              <BoxFormTitleAction>
                <TitleHeaderForm props={{ text: t("branch_pos_config") }} />
              </BoxFormTitleAction>
              <BoxGapInput>
                <PaperWrapper props={{ growFull: 1 }}>
                  {/* <TitleForm props={{ text: t("branch_pos_config") }} /> */}

                  <Box sx={{ p: 2 }}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        gap: 2,
                      }}
                    >
                      <TitleTextfield text={t("global_round_up")} />
                      <FormHelperText>
                        {"( "}E.g. 1.01 {"->"} 2.00{" )"}
                      </FormHelperText>
                    </Box>
                    <FormControlLabel
                      label={
                        roundUpStatus
                          ? t("global_active")
                          : t("global_in_active")
                      }
                      control={
                        <Switch
                          onChange={(e) =>
                            updateConfigServiceChargeRoundUp(!roundUpStatus)
                          }
                          checked={roundUpStatus}
                        />
                      }
                    />

                    {/* <TitleTextfield text={t("global_import_item")} />
                <FormControlLabel
                  label={
                    importItemStatus ? t("global_active") : t("global_in_active")
                  }
                  control={
                    <Switch
                      onChange={(e) => setImportItemStatus(!importItemStatus)}
                      checked={importItemStatus}
                    />
                  }
                /> */}
                  </Box>
                </PaperWrapper>
              </BoxGapInput>
            </PaperWrapper>
          </BoxWrapper>
        </Box>
      )}
    </div>
  );
}

export default BranchConfigure;
