import React, { useState, useEffect, useContext } from "react";
import { ActionDetail } from "../../../components/ui/dataTable/components/actionDetail";
import { Box } from "@mui/material";
import { MomentConvert } from "../../../components/ui/moment/momentConvert";
import DataMaterialTable from "../../../components/ui/dataTable/dataMaterialTable";
import { useTranslation } from "react-i18next";
import {
  addSaleProductToTemplate,
  getAllSaleProduct,
} from "../../../service/saleProductAPI";
import { BoxImageBlank } from "../../../components/ui/boxImage/boxImageBlank";
import BreadcrumbsTitle from "../../../components/ui/breadcrumbsTitle";
import StatusTable from "../../../components/chip/statusTable";
import { TextTable } from "../../../components/ui/textfield/textfieldCustom";
import { MyContext } from "../../../context/MyContext";
import { notifySuccess } from "../../../components/ui/popup/toastPopup";
import { BoxImageTable } from "../../../components/ui/boxImage/boxImageTable";
import dayjs from "dayjs";
function SaleProduct() {
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [exportColumnName, setExportColumnName] = useState(null);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const { t } = useTranslation();
  const {
    saleProductSetAll,
    saleProductAllTable,
    fetchDataImageSaleProductTable,
    setProgress,
    setDataBranchSaleProduct,
  } = useContext(MyContext);
  const fetchData = async () => {
    let dataColumns = [
      {
        accessorKey: "image",
        size: 50,
        enableSorting: false,
        header: t("global_image"),
        Cell: ({ row }) => (
          <Box sx={{ display: "flex", gap: 1 }}>
            <Box>
              {row.original.image ? (
                <BoxImageTable
                  image={row.original.image}
                  width={"50px"}
                  height={"50px"}
                  type="table"
                  borderRadius={true}
                />
              ) : (
                <BoxImageBlank width={50} height={50} />
              )}
            </Box>
          </Box>
        ),
      },
      {
        accessorKey: "name",
        flexGrow: 1,
        header: t("global_name"),
      },
      {
        accessorKey: "price",
        size: 50,
        header: t("global_price"),
      },
      {
        accessorKey: "mainCate",
        flexGrow: 1,
        header: t("category"),
        Cell: ({ cell }) => <TextTable props={{ text: cell.getValue() }} />,
      },
      {
        accessorKey: "subCate",
        flexGrow: 1,
        header: t("category_sub"),
        Cell: ({ cell }) => <TextTable props={{ text: cell.getValue() }} />,
      },
      {
        accessorKey: "update",
        size: 80,
        header: t("global_updated_at"),
        Cell: ({ cell }) => <TextTable props={{ text: cell.getValue() }} />,
      },
      {
        accessorKey: "status",
        enableSorting: false,
        size: 100,
        header: t("global_status"),
        Cell: ({ row }) => (
          <StatusTable props={{ status: row.original.status }} />
        ),
      },
      {
        accessorKey: "action",
        enableSorting: false,
        size: 100,
        header: "Action",
        sortable: false,
        Cell: ({ row }) => (
          <Box sx={{ display: "flex", gap: 2 }}>
            <ActionDetail params={row} detailPath={"/sale-product/detail/"} />
          </Box>
        ),
      },
    ];
    let dataAPI = [];
    let dataBox = [];
    if (saleProductSetAll) {
      dataAPI = saleProductSetAll;
    } else {
      dataAPI = await getAllSaleProduct();
    }
    if (dataAPI) {
      if (saleProductAllTable.length === 0) {
        let dataNews = dataAPI.sort(
          (a, b) => new Date(b.updated_at) - new Date(a.updated_at)
        );
        dataNews.map(async (item) => {
          dataBox.push({
            id: item.id,
            image: item.image,
            name: item.sale_product_lang.name,
            price: item.price,
            mainCate: item.main_categories[0].main_category_lang.name,
            subCate:
              item.minor_categories.length > 0
                ? item.minor_categories[0].minor_category_lang.name
                : "None",
            update: MomentConvert(item.updated_at),
            status: item.status,
          });
        });
        if (
          dataBox.some((item) => item.image.includes("data:image")) === false
        ) {
          setRows(dataBox);
          fetchDataImageSaleProductTable(dataBox, setRows);
        } else {
          setRows(dataBox);
        }
      } else {
        dataBox = saleProductAllTable;
        setRows(dataBox);
      }
      //===== Start set header name export csv
      let headers = [];
      dataColumns.forEach((item) => {
        if (item.header !== "Image" && item.header !== "Action") {
          headers.push(item.header);
        }
      });

      const csvStringArray = [
        headers,
        ...dataBox.map((item) => [
          item.name,
          item.price,
          item.mainCate,
          item.subCate,
          item.update,
          item.status,
        ]),
      ]
        .map((row) => row.join(","))
        .join("\n");
      setExportColumnName(csvStringArray);
      //===== End set header name export csv
    }
    setColumns(dataColumns);
    setIsLoadingData(false);
  };
  const onInsertToBranches = async (rowsData, branchSelect) => {
    let countRound = 0;
    let timeBoost = 0;
    const promises = rowsData.map(async (row, index) => {
      await new Promise((resolve) => {
        setTimeout(async () => {
          let startTime = performance.now();
          let dataAPI = {
            sale_product_id: String(row.original.id),
            branches: branchSelect,
            priority: 1,
          };
          let dataStatus = await addSaleProductToTemplate(dataAPI);
          if (dataStatus === "OK") {
            countRound++;
            setProgress((prevProgress) =>
              prevProgress >= 100 ? 0 : prevProgress + 100 / rowsData.length
            );
          } else {
            countRound++;
            setProgress((prevProgress) =>
              prevProgress >= 100 ? 0 : prevProgress + 100 / rowsData.length
            );
          }
          if (countRound === rowsData.length) {
            setProgress(0);
            setDataBranchSaleProduct([]);
            notifySuccess({
              title: t("notification_successfully_insert_information"),
            });
            resolve(true);
          } else {
            resolve(false);
          }

          if (index === 0) {
            let endTime = performance.now();
            let processTime = endTime - startTime;
            timeBoost = processTime;
          }
        }, (timeBoost ? timeBoost + 200 : 1800) * index);
      });
    });
    await Promise.all(promises);
  };

  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <Box>
      <BreadcrumbsTitle
        props={{
          title: t("sale_product"),
          suggestText: "",
        }}
      />
      <DataMaterialTable
        statusViewMode={true}
        onInsertToBranch={onInsertToBranches}
        enableSelection={true}
        exportColumnName={exportColumnName}
        fileName={t("sale_product") + ` ${dayjs().format("DD/MM/YYYY")}`}
        rows={rows}
        isLoadingData={isLoadingData}
        columns={columns}
        detailPath={"/sale-product/detail/"}
        createButton={t("sale_product")}
        createPath={"/sale-product/create"}
      />
    </Box>
  );
}

export default SaleProduct;
