import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { useTheme } from "@mui/material";
import dayjs from "dayjs";
import { FormatNumber } from "../../../formatNumber";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function LineChartComponent({ props }) {
  let { dataValues, nameValue, symbol, title, startDate, endDate } = props;
  const theme = useTheme();
  const colorBox = ["#005c3a", theme.palette.primary.main];
  let currentDate = startDate;
  let dateArray = [];
  while (currentDate.isBefore(endDate) || currentDate.isSame(endDate)) {
    dateArray.push(currentDate.format("YYYY-MM-DD"));
    currentDate = currentDate.add(1, "day");
  }
  let labelDate = dateArray.map((item) => dayjs(item).format("DD MMM"));

  let dataBox = [];
  if (dateArray.length !== 0 && dataValues !== undefined) {
    dateArray.forEach((itemDate) => {
      let dataFind = dataValues.find(
        (itemFind) =>
          dayjs(itemFind.date, "DD-MM-YYYY HH:mm:ss").format("YYYY-MM-DD") ===
          itemDate
      );
      if (dataFind) {
        dataBox.push({
          date: dayjs(itemDate).format("YYYY-MM-DD"),
          data: {
            total_sale: dataFind.data.total_sale,
            total_receipt: dataFind.data.total_receipt,
            avg_price_per_receipt: dataFind.data.avg_price_per_receipt,
          },
        });
      } else {
        dataBox.push({
          date: dayjs(itemDate).format("YYYY-MM-DD"),
          data: {
            total_sale: 0,
            total_receipt: 0,
            avg_price_per_receipt: 0,
          },
        });
      }
    });
  }

  let datasetsList = dataBox.map((item) => Number(item.data[nameValue]));
  const data = {
    labels: labelDate,
    datasets: [
      {
        label: nameValue,
        data: datasetsList,
        borderColor: colorBox[0],
        tension: 0.1,
      },
    ],
  };
  const options = {
    maintainAspectRatio: false,
    scales: {
      x: {
        display: true,
        grid: {
          display: false,
        },
        title: {
          display: window.innerWidth < 600 ? false : true, // Enable the axis title
          text: "Date", // Set the title text
          font: {
            // Optional: Customize title font
            size: window.innerWidth < 600 ? 12 : 16,
            weight: "bold",
          },
        },
      },
      y: {
        display: true,
        grid: {
          display: true,
        },
        ticks: {
          display: window.innerWidth < 600 ? false : true,
          callback: (value) => {
            // Customize the text labels as needed
            return `${symbol ? "" : ""} ${FormatNumber(value)}`;
          },
        },
        title: {
          display: true, // Enable the axis title
          text: title, // Set the title text
          font: {
            // Optional: Customize title font
            size: window.innerWidth < 600 ? 12 : 16,
            weight: "bold",
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false,
        // position: "bottom",
      },
      title: {
        display: false,
        text: "% Total Sales",
        position: "left",
      },
      // datalabels: {
      //   color: theme.palette.primary.main,
      //   backgroundColor: "#ffffff",
      //   formatter: (value) => `${value.toFixed(2)}`,
      // },
      tooltip: {
        callbacks: {
          title: function (context) {
            return `${dayjs(context[0].label, "DD MMM").format("DD/MMM/YYYY")}`;
          },
          label: function (context) {
            let sumPercent = "";
            if (nameValue === "total_sale") {
              sumPercent = `Total Sales`;
            } else if (nameValue === "total_receipt") {
              sumPercent = `Total Receipt`;
            } else {
              sumPercent = `Average price per receipt`;
            }
            return `${sumPercent}: ${Number(context.raw).toLocaleString()}`;
          },
        },
      },
    },
  };

  return (
    <div style={{ height: "300px", width: "100%" }}>
      <Line data={data} options={options} />
    </div>
  );
}

export default LineChartComponent;
