import React, { useState, useEffect, useContext } from "react";
import { Box, Dialog, DialogContent } from "@mui/material";
import TitleForm from "../../components/ui/formComponent/titleForm";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import {
  addPaymentTypeToTemplate,
  getAllPaymentType,
  getAllPaymentTypeTemplate,
  postPaymentType,
} from "../../service/paymentTypeAPI";
import { getAllBranch } from "../../service/branchAPI";
import { notifySuccess } from "../../components/ui/popup/toastPopup";
import SettingTypeBranch from "../../components/ui/addTypeTemplate/settingTypeBranch";
import BreadcrumbsTitle from "../../components/ui/breadcrumbsTitle";
import StatusForm from "../../components/ui/formComponent/statusForm";
import ConfirmButton from "../../components/ui/button/confirmButton";
import { useNavigate } from "react-router-dom";
import { useSchema } from "../../components/hooks/use-schema";
import { paymentTypeSchema } from "../../components/validation/paymentTypeSchema";
import SelectComponent from "../../components/ui/textfield/selectComponent";
import paymentAll from "../../components/utils/payment.json";
import PaperWrapper from "../../components/ui/paper/paperWrapper";
import { GridContainerSize } from "../../components/utils/gridContainerSize";
import Loading from "../../components/ui/loading";
import { BoxWrapper } from "../../components/ui/box/boxWrapper";
import { BoxFormTitleAction } from "../../components/ui/box/boxFormTitleAction";
import TitleHeaderForm from "../../components/ui/formComponent/titleHeaderForm";
import { BoxGapInput } from "../../components/ui/box/boxGapInput";
import { MyContext } from "../../context/MyContext";
function PaymentTypeCreate() {
  const {
    register,
    handleSubmit,
    setValue,
    control,
    clearErrors,
    watch,
    formState: { errors },
  } = useForm(useSchema(paymentTypeSchema));
  const { fetchPaymentType } = useContext(MyContext);
  const [paymentTypeShow, setPaymentTypeShow] = useState(2);
  const [isLoading, setIsLoading] = useState(true);
  const [statusButton, setStatusButton] = useState(true);
  const [idTemplate, setIdTemplate] = useState(null);
  const [showTemplate, setShowTemplate] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [allDataTemplate, setAllDataTemplate] = useState([]);
  const [paymentTypeAll, setPaymentTypeAll] = useState([]);
  const navigate = useNavigate();
  const fetchAllBranch = async (id) => {
    let dataAPI = await getAllBranch();
    dataAPI = dataAPI.filter((item) => item.branch_lang !== null);
    await fetchSettingSelectTemplate(dataAPI, id);
  };
  const fetchSettingSelectTemplate = async (dataBranch, id) => {
    let dataAPI = await getAllPaymentTypeTemplate(id);
    dataAPI = dataAPI[0].template_payment_types;
    let dataBox = [];
    dataBranch.forEach((item) => {
      let dataNews = dataAPI.filter(
        (itemTemplate) => itemTemplate.template_id === item.template_id
      );
      if (dataNews.length > 0) {
        dataBox.push({
          branch: item,
          data: dataNews[0],
          select: false,
        });
      } else {
        dataBox.push({
          branch: item,
          data: null,
          select: false,
        });
      }
    });
    await setAllDataTemplate(dataBox);
  };
  const onAddPaymentTypeToTemplate = async () => {
    let branchArray = allDataTemplate.filter((item) => item.select === true);
    let dataSelect = [];
    branchArray.forEach((item) => {
      dataSelect.push(item.branch.id);
    });
    let dataAPI = {
      payment_type_id: idTemplate,
      branches: dataSelect,
      priority: 1,
    };
    let dataStatus = await addPaymentTypeToTemplate(dataAPI);
    if (dataStatus === "OK") {
      notifySuccess({
        title: t("toast_successfully_added_information"),
      });
      navigate("/payment-type");
    }
  };
  const onConfirmForm = async (register) => {
    setIsSubmitting(true);

    let paymentName = paymentAll.find(
      (itemPayment) => itemPayment.id === paymentTypeShow
    );
    let dataAPI = {
      type: paymentName.type,
      image: "",
      payment_type_lang: {
        name: register.name,
        alt_name: register.altName,
        language_code: "ALL",
      },
      status: statusButton,
    };
    let statusAPI = await postPaymentType(dataAPI);
    if (statusAPI.message === "OK") {
      notifySuccess({
        title: t("toast_successfully_added_information"),
      });
      if (statusButton) {
        setIdTemplate(statusAPI.data.id);
        fetchAllBranch(statusAPI.data.id);
        fetchPaymentType();
        setShowTemplate(true);
      } else {
        navigate("/payment-type");
      }
    }
    await new Promise((resolve) => setTimeout(resolve, 2000));
    setIsSubmitting(false);
  };

  const onPaymentTypeShow = (item) => {
    setValue("paymentType", item);
    clearErrors();
    if (item === 1) {
      setValue("name", "Cash");
      setValue("altName", "Cash");
    }
    if (item === 2) {
      setValue("name", "Bank Transfer");
      setValue("altName", "Bank Transfer");
    }
    if (item === 3) {
      setValue("name", "Card");
      setValue("altName", "Card");
    }

    if (item === 4) {
      setValue("name", "Voucher");
      setValue("altName", "Voucher");
    }

    if (item === 5) {
      setValue("name", "E-Wallet");
      setValue("altName", "E-Wallet");
    }

    if (item === 6) {
      setValue("name", "Delivery");
      setValue("altName", "Delivery");
    }

    if (item === 7) {
      setValue("name", "NETS");
      setValue("altName", "NETS");
    }

    if (item === 8) {
      setValue("name", "Cash Card");
      setValue("altName", "Cash Card");
    }
    if (item === 9) {
      setValue("name", "Other");
      setValue("altName", "Other");
    }
    setPaymentTypeShow(item);
  };
  const fetchData = async () => {
    let dataAPI = await getAllPaymentType();
    setPaymentTypeAll(dataAPI.map((item) => item.payment_type_lang.name));
    setIsLoading(false);
  };
  useEffect(() => {
    localStorage.removeItem("uploadImage");
    setValue("name", "");
    setValue("altName", "");
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  const { t } = useTranslation();
  return (
    <div align="left">
      <BreadcrumbsTitle
        props={{
          title: t("payment_type_create"),
          dataLink: [
            { text: t("payment_type"), link: "/payment-type" },
            { text: t("payment_type_create"), link: null },
          ],
        }}
      />
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <GridContainerSize>
            <Box p={2}>
              <form onSubmit={handleSubmit(onConfirmForm)}>
                <BoxWrapper>
                  <PaperWrapper props={{ growFull: 1 }}>
                    <BoxFormTitleAction>
                      <TitleHeaderForm props={{ text: t("payment_type") }} />
                      <StatusForm
                        statusButton={statusButton}
                        setStatusButton={setStatusButton}
                      />
                    </BoxFormTitleAction>
                    <BoxGapInput>
                      <Box className="boxWrapper-container">
                        <PaperWrapper props={{ growFull: null }}>
                          <TitleForm props={{ text: t("payment_type_info") }} />
                          <BoxGapInput>
                            <SelectComponent
                              title={t("payment_type")}
                              register={register}
                              name="paymentType"
                              errors={errors}
                              control={control}
                              setValue={setValue}
                              clearErrors={clearErrors}
                              dataEmpty={t("payment_type_select")}
                              optionsData={paymentAll
                                .filter(
                                  (item) => item.id !== 1 && item.id !== 4
                                )
                                .filter(
                                  (itemFind) =>
                                    paymentTypeAll.indexOf(itemFind.name) === -1
                                )}
                              dataValue={paymentTypeShow}
                              onFunctionName={onPaymentTypeShow}
                              watch={watch}
                              fullGrid={true}
                            />
                          </BoxGapInput>
                        </PaperWrapper>
                      </Box>
                      <ConfirmButton disabled={isSubmitting} />
                    </BoxGapInput>
                  </PaperWrapper>
                </BoxWrapper>
              </form>
            </Box>
          </GridContainerSize>
          <Dialog open={showTemplate} maxWidth="md" fullWidth>
            <DialogContent>
              <SettingTypeBranch
                statusData={true}
                cancelPath={"/payment-type"}
                title={t("payment_type_branch")}
                onAddTypeToTemplate={onAddPaymentTypeToTemplate}
                allDataTemplate={allDataTemplate}
                setAllDataTemplate={setAllDataTemplate}
              />
            </DialogContent>
          </Dialog>
        </>
      )}
    </div>
  );
}

export default PaymentTypeCreate;
