import { createContext, useEffect, useMemo, useState } from "react";
import { Box, ThemeProvider, Typography, createTheme } from "@mui/material";
import Loading from "../components/ui/loading";
import { getAllBranch } from "../service/branchAPI";
import { getAllProduct } from "../service/productAPI";
import { getAllSaleProduct } from "../service/saleProductAPI";
import { getAllPromotion } from "../service/promotionAPI";
import { getAllSlider } from "../service/sliderAPI";
import LinearLoading from "../components/ui/dataTable/components/linearLoading";
import { t } from "i18next";
import { getAllPosUser } from "../service/posUserAPI";
import { getImage } from "../service/upload/getImage";
import { UseConvertImageToBase64 } from "../components/hooks/use-convert-image-to-base-64";
import { AccessTokenAPI } from "../service/getLocalStorage";
import dayjs from "dayjs";
import { getReport } from "../service/report/getReport";
import Swal from "sweetalert2";
import { getAllPaymentType } from "../service/paymentTypeAPI";

const MyContext = createContext();

const MyProvider = ({ children }) => {
  const [state, setState] = useState(null);
  const [authRegister, setAuthRegister] = useState(null);
  const [branchAll, setBranchAll] = useState([]);
  const [branchSelectReport, setBranchSelectReport] = useState([]);
  const [branchSelectReportDate, setBranchSelectReportDate] = useState({
    startDate: dayjs().startOf("day"),
    endDate: dayjs().endOf("day"),
  });
  const [branchSelectReportList, setBranchSelectReportList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [productSetAll, setProductSetAll] = useState([]);
  const [productAllTable, setProductAllTable] = useState([]);
  const [branchSelectName, setBranchSelectName] = useState([]);
  const [productSetDialog, setProductSetDialog] = useState([]);

  const [saleProductSetAll, setSaleProductSetAll] = useState([]);
  const [saleProductAllTable, setSaleProductAllTable] = useState([]);
  const [saleProductSetDialog, setSaleProductSetDialog] = useState([]);

  const [salePromotionSetAll, setSalePromotionSetAll] = useState([]);
  const [salePromotionAllTable, setSalePromotionAllTable] = useState([]);

  const [backScreenAll, setbackScreenAll] = useState([]);
  const [backScreenAllTable, setbackScreenAllTable] = useState([]);

  const [staffAll, setStaffAll] = useState([]);
  const [staffAllTable, setStaffAllTable] = useState([]);

  const [dataBranchSaleProduct, setDataBranchSaleProduct] = useState([]);
  const [dataBranchPromotion, setDataBranchPromotion] = useState([]);
  const [progress, setProgress] = useState(0);
  const [titleProgress, setTitleProgress] = useState(null);

  const [paymentTypeAll, setPaymentTypeAll] = useState([]);

  const [reportAll, setReportAll] = useState([]);

  const fetchDataInterface = async () => {
    let dataAPI = await getAllBranch();
    if (dataAPI?.response?.status === 401) {
      Swal.fire({
        title: "Your session has expired.",
        icon: "warning",
        confirmButtonText: "BACK TO LOGIN",
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.replace(
            process.env.REACT_APP_SMART_PLATFORM_DOMAIN_URL + "/login"
          );
        }
      });
    } else {
      await fetchDataContinue();
    }
  };
  const fetchDataContinue = async () => {
    await fetchBranchData();
    await fetchProduct();
    await fetchPromotion();
    await fetchBackScreen();
    await fetchSaleProduct();
    await fetchStaff();
    await fetchPaymentType();
    await fetchReport();
    await setIsLoading(false);
  };
  const fetchPaymentType = async () => {
    let dataAPI = await getAllPaymentType();
    if (dataAPI) {
      setPaymentTypeAll(dataAPI);
    }
  };
  const fetchReport = async () => {
    let dataInitialBranch = [];
    if (branchSelectReport.length === 0) {
      let dataAPI = await getAllBranch();
      if (dataAPI && dataAPI.length > 0) {
        dataInitialBranch = dataAPI.map((item) => item.id);
      }
    }
    let dataSentAPI = {
      start: dayjs(branchSelectReportDate.startDate).format("YYYYMMDDHHmmss"),
      end: dayjs(branchSelectReportDate.endDate).format("YYYYMMDDHHmmss"),
      branches:
        branchSelectReport?.length > 0 ? branchSelectReport : dataInitialBranch,
    };
    let dataAPI = await getReport(dataSentAPI);
    setReportAll(dataAPI);
  };
  //Staff
  const fetchStaff = async () => {
    let dataAPI = await getAllPosUser();
    if (dataAPI) {
      setStaffAll(dataAPI);
      setStaffAllTable([]);
      fetchDataStaffImage(dataAPI);
    }
  };
  const fetchDataStaffImage = async (dataRows) => {
    let dataFetchImage = dataRows;
    const promises = dataFetchImage.map(async (item) => {
      let imageUrl = await getImage(item.image);
      item.image = UseConvertImageToBase64(imageUrl);
    });
    await Promise.all(promises);
    setStaffAll(dataFetchImage);
    setStaffAllTable([]);
  };
  const fetchDataStaffImageTable = async (dataRows, setRows) => {
    let dataFetchImage = [...dataRows];
    for (let i = 0; i < dataFetchImage.length; i++) {
      if (
        dataFetchImage[i].image.includes("data:image") === false &&
        dataFetchImage[i].image !== ""
      ) {
        let imageUrl = await getImage(dataFetchImage[i].image);
        dataFetchImage[i].image = await UseConvertImageToBase64(imageUrl);
      }
      if ((i + 1) % 10 === 0 || i === dataFetchImage.length - 1) {
        setRows([...dataFetchImage]);
        setStaffAllTable([...dataFetchImage]);
      }
    }
  };
  //Promotion
  const fetchPromotion = async () => {
    let dataAPI = await getAllPromotion();
    if (dataAPI) {
      setSalePromotionSetAll(dataAPI);
      setSalePromotionAllTable([]);
    }
  };
  const fetchBackScreen = async () => {
    let dataAPI = await getAllSlider();
    if (dataAPI) {
      setbackScreenAll(dataAPI);
      setbackScreenAllTable([]);
    }
  };
  //fetchProduct
  const fetchProduct = async () => {
    let dataAPI = await getAllProduct();
    if (dataAPI) {
      setProductSetAll(dataAPI);
      fetchDataImageProduct(dataAPI);
      setProductAllTable([]);
    }
  };
  const fetchDataImageProduct = async (dataRows) => {
    let dataFetchImage = [...dataRows];
    for (let i = 0; i < dataFetchImage.length; i++) {
      if (
        dataFetchImage[i].image.includes("data:image") === false &&
        dataFetchImage[i].image !== ""
      ) {
        let imageUrl = await getImage(dataFetchImage[i].image);
        dataFetchImage[i].image = await UseConvertImageToBase64(imageUrl);
        setProductSetAll([...dataFetchImage]);
      }
    }
  };
  const fetchDataImageProductTable = async (dataRows, setRows) => {
    let dataFetchImage = [...dataRows];
    for (let i = 0; i < dataFetchImage.length; i++) {
      if (
        dataFetchImage[i].image.includes("data:image") === false &&
        dataFetchImage[i].image !== ""
      ) {
        let imageUrl = await getImage(dataFetchImage[i].image);
        dataFetchImage[i].image = await UseConvertImageToBase64(imageUrl);
      }
      if ((i + 1) % 10 === 0 || i === dataFetchImage.length - 1) {
        setRows([...dataFetchImage]);
        setProductAllTable([...dataFetchImage]);
      }
    }
  };

  //fetchSaleProduct
  const fetchSaleProduct = async () => {
    let dataAPI = await getAllSaleProduct();
    if (dataAPI) {
      setSaleProductSetAll(dataAPI);
      let dataDialogResetAmount = [...productSetDialog];
      dataDialogResetAmount.forEach((obj) => {
        obj.amount = 0;
        obj.product.forEach((product) => {
          product.amount = 0;
        });
      });
      await setProductSetDialog(dataDialogResetAmount);
      fetchDataImageSaleProduct(dataAPI);
      setSaleProductAllTable([]);
    }
  };
  const fetchDataImageSaleProductTable = async (dataRows, setRows) => {
    let dataFetchImage = [...dataRows];
    for (let i = 0; i < dataFetchImage.length; i++) {
      if (
        dataFetchImage[i].image.includes("data:image") === false &&
        dataFetchImage[i].image !== ""
      ) {
        let imageUrl = await getImage(dataFetchImage[i].image);
        dataFetchImage[i].image = await UseConvertImageToBase64(imageUrl);
      }
      if ((i + 1) % 10 === 0 || i === dataFetchImage.length - 1) {
        setRows([...dataFetchImage]);
        setSaleProductAllTable([...dataFetchImage]);
      }
    }
  };

  const fetchDataImageSaleProduct = async (dataRows) => {
    let dataFetchImage = [...dataRows];
    for (let i = 0; i < dataFetchImage.length; i++) {
      if (
        dataFetchImage[i].image.includes("data:image") === false &&
        dataFetchImage[i].image !== ""
      ) {
        let imageUrl = await getImage(dataFetchImage[i].image);
        dataFetchImage[i].image = await UseConvertImageToBase64(imageUrl);
      }
      setSaleProductSetAll([...dataFetchImage]);
    }
  };

  const fetchBranchData = async () => {
    let dataAPI = await getAllBranch();
    if (dataAPI && dataAPI.length > 0) {
      let dataBox = dataAPI.map((item) => ({
        id: item.id,
        name: item.branch_lang.name,
        templateId: item.template_id,
      }));

      localStorage.setItem("branchSelectId", dataBox[0].id);
      localStorage.setItem("branchSelectName", dataBox[0].name);
      setBranchAll(dataBox);
      setBranchSelectReport(dataBox.map((itemBranch) => itemBranch.id));
    } else {
      localStorage.setItem("branchSelectId", null);
      localStorage.setItem("branchSelectName", null);
      console.error("No data available from API.");
    }

    if (!localStorage.getItem("branchSelectName")) {
      window.location.reload();
    }
  };

  const theme = useMemo(
    () =>
      createTheme({
        typography: {
          fontFamily: ["Kanit"],
          h1: {
            fontSize: "clamp(2.5rem, 3vw, 4.5rem)",
          },
          h2: {
            fontSize: "clamp(1.5rem, 2vw, 3rem)",
          },
          h3: {
            fontSize: "clamp(1.25rem, 1.3vw, 2.5rem)",
          },
          h4: {
            fontSize: "clamp(1rem, 1vw, 2rem)",
          },
          h5: {
            fontSize: "clamp(0.7rem, 0.7vw, 1.2rem)",
          },
          body1: {
            fontSize: "clamp(0.85rem, 0.75vw, 1.325rem)",
          },
          titleTextField: {
            fontSize: "clamp(0.65rem, 0.9vw, 0.8rem)",
          },
        },
        palette: {
          primary: {
            main: "#102C57",
          },
          secondary: {
            main: "#1679AB",
          },
          primaryText: {
            main: "#333333",
          },
          sidebarText: {
            main: "#7d819d",
          },
          info: {
            main: "#5AB2FF",
          },
          secondaryText: {
            main: "#5c5c5c",
          },
          success: {
            main: "#39a751",
          },
          purple: {
            main: "#493D9E",
          },
          successBg: {
            main: "#e6fded",
          },
          error: {
            main: "#E53935E5",
          },
          bgSizeBarRight: {
            main: "#eff1f3",
          },
          warning: {
            main: "#FFB300",
            contrastText: "black",
          },
          white: {
            main: "#ffffff",
          },
          notFound: {
            main: "#cccccc",
          },
        },
        breakpoints: {
          values: {
            xs: 0,
            sm: 601,
            md: 1025,
            lg: 1441,
            xl: 1921,
          },
        },
      }),
    []
  );
  theme.textError = {
    display: "block",
    color: "#dc3545",
    marginTop: "0.5rem 0rem",
    textAlign: "left",
  };
  theme.success = {
    display: "block",
    color: "#28A745",
    marginTop: "0.5rem",
    textAlign: "left",
    [theme.breakpoints.up("sm")]: { fontSize: "16px" },
    [theme.breakpoints.up("md")]: { fontSize: "16px" },
    [theme.breakpoints.up("lg")]: { fontSize: "16px" },
    [theme.breakpoints.up("xl")]: { fontSize: "16px" },
  };
  useEffect(() => {
    if (AccessTokenAPI === undefined) {
      window.location.replace(
        process.env.REACT_APP_SMART_PLATFORM_DOMAIN_URL + "/login"
      );
    } else {
      if (localStorage.getItem("language_form") === null) {
        localStorage.setItem("language_form", "en");
      }
      if (localStorage.getItem("language_system") === null) {
        localStorage.setItem("language_system", "en");
      }
    }
    fetchDataInterface();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <>
      {progress > 0 ? (
        <Box
          sx={{
            width: "100vw",
            height: "100vh",
            backgroundColor: "#00000050",
            position: "absolute",
            zIndex: 9999,
            display: "grid",
            placeItems: "center",
          }}
        >
          <Box
            sx={{
              width: "50vw",
              backgroundColor: theme.palette.white.main,
              p: 2,
              borderRadius: 2,
            }}
          >
            <Typography>
              {titleProgress
                ? titleProgress
                : t("Loading insert data to branch")}
            </Typography>
            <LinearLoading value={progress} />
          </Box>
        </Box>
      ) : null}
      {isLoading ? (
        <Box
          sx={{
            height: "100vh",
            width: "100%",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              transform: "translate(-50%,-50%)",
              left: "50%",
              top: "50%",
            }}
          >
            <Loading />
          </Box>
        </Box>
      ) : (
        <>
          <MyContext.Provider
            value={{
              fetchBranchData,
              state,
              setState,
              authRegister,
              setAuthRegister,
              branchAll,
              branchSelectReport,
              setBranchSelectReport,
              branchSelectReportDate,
              setBranchSelectReportDate,

              productSetAll,
              productAllTable,
              fetchDataImageProductTable,
              setProductAllTable,
              fetchProduct,
              productSetDialog,
              setProductSetDialog,
              fetchDataImageSaleProductTable,
              fetchDataImageSaleProduct,

              saleProductSetAll,
              setSaleProductSetAll,
              saleProductAllTable,
              setSaleProductAllTable,
              fetchSaleProduct,
              saleProductSetDialog,
              setSaleProductSetDialog,

              salePromotionSetAll,
              salePromotionAllTable,
              setSalePromotionAllTable,
              fetchPromotion,

              backScreenAll,
              backScreenAllTable,
              setbackScreenAllTable,

              staffAll,
              staffAllTable,
              setStaffAllTable,
              fetchStaff,
              fetchDataStaffImage,
              fetchDataStaffImageTable,

              setProgress,
              setTitleProgress,

              dataBranchSaleProduct,
              setDataBranchSaleProduct,
              fetchBackScreen,
              dataBranchPromotion,
              setDataBranchPromotion,
              branchSelectReportList,
              setBranchSelectReportList,
              branchSelectName,
              setBranchSelectName,

              fetchReport,
              reportAll,

              paymentTypeAll,
              fetchPaymentType,
            }}
          >
            <ThemeProvider theme={theme}>{children}</ThemeProvider>
          </MyContext.Provider>
        </>
      )}
    </>
  );
};

export { MyContext, MyProvider };
