import React from "react";
import { Box, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
function MenuItem({ props }) {
  const {
    menuExpandedSub,
    setMenuExpandedSub,
    menuItem,
    menuExpanded,
    setDrawerBar,
    expanded,
    setExpanded,
  } = props;
  const subMenus = menuItem.subMenus || [];
  const hasSubMenus = subMenus.length > 0;
  // const statusHeadSidebar = localStorage.getItem("headSidebar");
  const navigate = useNavigate();
  const location = useLocation();
  const handleClick = async () => {
    if (hasSubMenus) {
      if (expanded.name === menuItem.name && subMenus) {
        setMenuExpandedSub(null);
        setExpanded({ name: "" });
      } else {
        setExpanded(menuItem);
        setMenuExpandedSub(menuExpanded ? true : null);
      }
    } else {
      // localStorage.setItem("headSidebar", menuItem.to);
      if (window.innerWidth < 600) {
        setDrawerBar(false);
      }
      setDrawerBar(false);
      setExpanded(menuItem);
      navigate(menuItem.to);
    }
  };
  const onSubNavigate = async (item) => {
    if (window.innerWidth < 600) {
      await setDrawerBar(false);
    }
    // localStorage.setItem("headSidebar", item.to);

    await setDrawerBar(false);
    await setMenuExpandedSub(false);
    navigate(item.to);
  };
  return (
    <Box>
      <Box
        sx={(theme) => ({
          // color: "#ffffff",
          // color: theme.palette.primaryText.main,
          borderRadius: 2,
          backgroundColor:
            (location.pathname.includes(expanded.to) &&
              menuItem?.to === expanded.to) ||
            menuItem.name === expanded.name
              ? theme.palette.primary.main
              : theme.palette.white.main,
          ":hover": {
            backgroundColor:
              (location.pathname.includes(expanded.to) &&
                menuItem?.to === expanded.to) ||
              menuItem.name === expanded.name
                ? theme.palette.primary.main
                : "#cccccc50",
            cursor: "pointer",
          },
          px: 2,
          py: 1.5,
          my: 1,
        })}
        onClick={handleClick}
        className="smoothTransition"
      >
        <Box
          sx={{
            display: "flex",
            alignItems: menuExpanded ? "flex-start" : "center",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={(theme) => ({
              display: "flex",
              alignItems: "center",
              justifyContent: menuExpanded ? "center" : null,
              width: menuExpanded ? "100%" : null,
              gap: 1,
              // color: theme.palette.sidebarText.main,
              color:
                (location.pathname.includes(expanded.to) &&
                  menuItem?.to === expanded.to) ||
                menuItem.name === expanded.name
                  ? theme.palette.white.main
                  : theme.palette.sidebarText.main,
            })}
          >
            <Box
              sx={(theme) => ({
                display: "flex",
                alignItems: "center",
              })}
            >
              {menuItem.iconClassName}
            </Box>

            {menuExpanded === false && (
              <Typography
                sx={(theme) => ({
                  fontFamily: "Kanit",
                  fontWeight: 300,
                  [theme.breakpoints.up("xs")]: { fontSize: "14px" },
                  [theme.breakpoints.up("sm")]: { fontSize: "14px" },
                  [theme.breakpoints.up("md")]: { fontSize: "12px" },
                  [theme.breakpoints.up("lg")]: { fontSize: "14px" },
                })}
              >
                {menuItem.name}
              </Typography>
            )}
          </Box>
          {hasSubMenus && menuExpanded === false ? (
            <ExpandMoreIcon
              sx={(theme) => ({
                fontSize: "1.2rem",
                transform:
                  hasSubMenus &&
                  expanded.name === menuItem.name &&
                  menuExpanded === false
                    ? "rotate(0deg)"
                    : "rotate(-90deg)",
                transition: " all .2s ease-in-out",
                color:
                  (location.pathname.includes(expanded.to) &&
                    menuItem?.to === expanded.to) ||
                  menuItem.name === expanded.name
                    ? theme.palette.white.main
                    : theme.palette.primaryText.main,
              })}
            />
          ) : null}
          {menuExpandedSub ? (
            <>
              {hasSubMenus && expanded.name === menuItem.name ? (
                <Box
                  sx={(theme) => ({
                    position: "absolute",
                    left: "10vw",
                    top: "18vh",
                    [theme.breakpoints.up("xs")]: { left: "7vw" },
                    [theme.breakpoints.up("sm")]: { left: "7vw" },
                    [theme.breakpoints.up("md")]: { left: "7vw" },
                    [theme.breakpoints.up("lg")]: { left: "7vw" },
                    width: "250px",
                    backgroundColor: theme.palette.white.main,
                    borderRadius: 1,
                    border: 1,
                    ml: 1.5,
                    borderColor: "#ccc",
                  })}
                >
                  {subMenus.map((menu, index) => (
                    <Box key={index}>
                      <Typography
                        onClick={() => onSubNavigate(menu)}
                        sx={(theme) => ({
                          fontFamily: "Kanit",
                          [theme.breakpoints.up("xs")]: { fontSize: "14px" },
                          [theme.breakpoints.up("sm")]: { fontSize: "14px" },
                          [theme.breakpoints.up("md")]: { fontSize: "12px" },
                          [theme.breakpoints.up("lg")]: { fontSize: "14px" },

                          borderBottom: "1px solid #ccc",
                          // color:
                          //   menu.to === location.pathname ||
                          //   location.pathname.includes(menu.to)
                          //     ? "#ffffff"
                          //     : "",
                          // color:
                          //   menu.to === location.pathname ||
                          //   location.pathname.includes(menu.to)
                          //     ? "#ffffff"
                          //     : "",

                          color:
                            menu.to === location.pathname ||
                            location.pathname.includes(menu.to)
                              ? theme.palette.primary.main
                              : theme.palette.primaryText.main,
                          backgroundColor:
                            menu.to === location.pathname ||
                            location.pathname.includes(menu.to)
                              ? theme.palette.white.main
                              : "",
                          // borderRadius: 1,
                          padding:
                            menuExpanded === false ? ".5rem 1rem" : "1rem",
                          display: "flex",
                          alignItems: "center",
                          gap: 1,
                          ":hover": {
                            // backgroundColor: theme.palette.primaryText.main,
                            // color: "#ffffff",
                            color: theme.palette.primary.main,
                          },
                        })}
                      >
                        {menu.to === location.pathname ||
                        location.pathname.includes(menu.to) ? (
                          <RadioButtonCheckedIcon fontSize="3" />
                        ) : (
                          <RadioButtonUncheckedIcon fontSize="3" />
                        )}
                        {menu.name}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              ) : null}
            </>
          ) : null}
        </Box>
      </Box>
      {menuExpanded ? null : (
        <>
          {hasSubMenus && expanded.name === menuItem.name ? (
            <Box className="slideSideBar">
              {subMenus.map((menu, index) => (
                <Box key={index} sx={{ ml: 1 }}>
                  <Typography
                    onClick={() => onSubNavigate(menu)}
                    sx={(theme) => ({
                      fontFamily: "Kanit",
                      fontWeight: 300,
                      [theme.breakpoints.up("xs")]: { fontSize: "14px" },
                      [theme.breakpoints.up("sm")]: { fontSize: "14px" },
                      [theme.breakpoints.up("md")]: { fontSize: "12px" },
                      [theme.breakpoints.up("lg")]: { fontSize: "14px" },

                      color:
                        menu.to === location.pathname ||
                        location.pathname.includes(menu.to)
                          ? theme.palette.primary.main
                          : theme.palette.sidebarText.main,
                      borderRadius: 1,
                      padding: menuExpanded === false ? ".4rem 1rem" : "1rem",
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                      // my: 1,
                      ":hover": {
                        cursor: "pointer",
                        color: theme.palette.primary.main,
                      },
                    })}
                  >
                    {menu.to === location.pathname ||
                    location.pathname.includes(menu.to) ? (
                      <RadioButtonCheckedIcon fontSize="3" />
                    ) : (
                      <RadioButtonUncheckedIcon fontSize="3" />
                    )}

                    {menu.name}
                  </Typography>
                </Box>
              ))}
            </Box>
          ) : null}
        </>
      )}
    </Box>
  );
}

export default MenuItem;
