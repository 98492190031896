import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { t } from "i18next";
import React from "react";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { BoxImage } from "../../../components/ui/boxImage/boxImage";
import ConfirmButton from "../../../components/ui/button/confirmButton";
import { ActionBtn } from "../../../components/ui/dataTable/components/actionBtn";
import CloseIcon from "@mui/icons-material/Close";

export function SelfPickupSaleProductDialog({ props }) {
  let {
    dialogOpen,
    setDialogOpen,
    saleProductAll,
    setSaleProductAll,
    onConfirmAddSaleProduct,
  } = props;

  const onSelectProduct = (item, indexProduct) => {
    let dataNews = [...saleProductAll];
    dataNews[indexProduct].select = !item.select;
    setSaleProductAll(dataNews);
  };

  const onChangeSaleProduct = (newValue, indexProduct) => {
    let dataNews = [...saleProductAll];
    dataNews[indexProduct].amount = Number(newValue);
    setSaleProductAll(dataNews);
  };

  const onLimitStock = (item, e, indexProduct) => {
    let dataNews = [...saleProductAll];
    dataNews[indexProduct].limit = e.target.checked;
    if (e.target.checked === false) {
      dataNews[indexProduct].amount = 0;
    }
    setSaleProductAll(dataNews);
  };
  return (
    <Dialog open={dialogOpen} maxWidth="md" fullWidth>
      <DialogTitle sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box>
          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
            {t("self_pickup_setup_add_sale_product")}
          </Typography>
          <Typography>{t("click check product for set limit")}</Typography>
        </Box>
        <IconButton onClick={() => setDialogOpen(false)}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container sx={(theme) => ({ height: "50vh", overflow: "auto" })}>
          {saleProductAll.map((product, indexProduct) => (
            <>
              {product.length === 0 ? (
                <Grid item sm={12} align="center" p={2}>
                  <Typography>{t("global_data_not_found")}</Typography>
                </Grid>
              ) : (
                <>
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    align="center"
                    sx={{ boxSizing: "border-box", p: 1 }}
                  >
                    <Grid
                      container
                      sx={{ borderRadius: 1 }}
                      onClick={() => onSelectProduct(product, indexProduct)}
                    >
                      <Grid
                        xs={12}
                        sx={(theme) => ({
                          position: "relative",
                        })}
                      >
                        {product.select && (
                          <>
                            <Box
                              sx={(theme) => ({
                                zIndex: 99,
                                position: "absolute",
                                display: "grid",
                                placeItems: "center",
                                width: "100%",
                                height: "100%",
                              })}
                            >
                              <CheckCircleOutlineIcon
                                sx={(theme) => ({
                                  color: "#fff",
                                  fontSize: 150,
                                })}
                              />
                            </Box>
                            <Box
                              sx={(theme) => ({
                                width: "100%",
                                height: "100%",
                                backgroundColor: "#fff",
                                opacity: 0.5,
                                position: "absolute",
                              })}
                            ></Box>
                          </>
                        )}
                        <Box
                          sx={(theme) => ({
                            ":hover": {
                              opacity: 0.8,
                              cursor: "pointer",
                            },
                          })}
                        >
                          <BoxImage image={product.image} height={"256px"} />
                        </Box>
                        <Typography>{product.name}</Typography>
                      </Grid>
                    </Grid>
                    <Box sx={{ mt: 2 }} align="end">
                      <TextField
                        fullWidth
                        placeholder="10"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">pcs.</InputAdornment>
                          ),
                        }}
                        value={product.amount}
                        onChange={(e) =>
                          onChangeSaleProduct(e.target.value, indexProduct)
                        }
                        sx={(theme) => ({
                          backgroundColor: product?.select ? null : "#cccccc",
                        })}
                        disabled={product?.select === false}
                        size="small"
                      />
                      <ActionBtn
                        row={saleProductAll}
                        status={product.limit}
                        onUpdateStatus={onLimitStock}
                        index={indexProduct}
                        disabled={product?.select === false}
                        label={t("global_limit")}
                      />
                    </Box>
                  </Grid>
                </>
              )}
            </>
          ))}
        </Grid>
        <Box mt={4}>
          <ConfirmButton
            onClick={() => onConfirmAddSaleProduct()}
            disabled={saleProductAll.every((item) => item.select === false)}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
}
