import React, { useEffect } from "react";
import { canvasPreview } from "../../components/ui/upload/canvasPreview";
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  FormHelperText,
  Skeleton,
} from "@mui/material";
import { base64StringToFile } from "../../components/ui/upload/base64StringToFile";

function ImageUploadDialog({ props }) {
  let {
    onAddImage,
    setDialogCropImage,
    setImageUpload,
    setImageAll,
    sizeWHSlider,
    setLoadingImage,
    imageAll,
    previewCanvasRef,
    numImage,
    imageUpload,
    setCompletedCrop,
    setCrop,
    completedCrop,
    aspect,
    dialogCropImage,
    imgRef,
    crop,
  } = props;
  const onConfirmCrop = async (status) => {
    setLoadingImage(true);
    let dataImageAll = [...imageAll];
    localStorage.removeItem("uploadImage");
    let widthCrop = sizeWHSlider.width;
    let heightCrop = sizeWHSlider.height;
    if (status === "crop") {
      let dataImg = previewCanvasRef.current.toDataURL();
      let fileUploadFromCanvas = await base64StringToFile(dataImg, "image");
      let img = new Image();
      let resizedImageUrl = "";
      img.onload = () => {
        let canvas = document.createElement("canvas");
        let context = canvas.getContext("2d");
        canvas.width = widthCrop;
        canvas.height = heightCrop;
        context.drawImage(img, 0, 0, widthCrop, heightCrop);
        resizedImageUrl = canvas.toDataURL();
        let fileConvertResize = base64StringToFile(
          resizedImageUrl,
          `image${widthCrop}.png`
        );

        dataImageAll[numImage].url = fileConvertResize;
        setImageAll([...dataImageAll]);
        setLoadingImage(false);
        setImageUpload(null);
        setDialogCropImage(false);
        onAddImage();
      };
      img.src = URL.createObjectURL(fileUploadFromCanvas);
    } else {
      let img = new Image();
      let resizedImageUrl = "";
      img.onload = () => {
        let canvas = document.createElement("canvas");
        let context = canvas.getContext("2d");
        canvas.width = widthCrop;
        canvas.height = heightCrop;
        context.drawImage(img, 0, 0, widthCrop, heightCrop);
        resizedImageUrl = canvas.toDataURL();
        let fileConvertResize = base64StringToFile(
          resizedImageUrl,
          `image${widthCrop}.png`
        );
        dataImageAll[numImage].url = fileConvertResize;
        setImageAll([...dataImageAll]);
        setLoadingImage(false);
        setImageUpload(null);
        setDialogCropImage(false);
        onAddImage();
      };
      img.src = imageUpload;
    }
  };
  function onCropImage(crop) {
    setCompletedCrop(crop);
  }
  function onImageLoad(e) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }
  function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
    return centerCrop(
      makeAspectCrop(
        {
          unit: "%",
          width: 100,
        },
        aspect,
        mediaWidth,
        mediaHeight
      ),
      mediaWidth,
      mediaHeight
    );
  }
  useEffect(() => {
    if (
      completedCrop?.width &&
      completedCrop?.height &&
      imgRef.current &&
      previewCanvasRef.current
    ) {
      canvasPreview(
        imgRef.current,
        previewCanvasRef.current,
        completedCrop,
        1, //scale === 1
        0 // rotate === 0
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [completedCrop]);
  return (
    <Dialog open={dialogCropImage} maxWidth="sm" fullWidth>
      <DialogContent>
        <Box align="center" sx={{ width: "100%", height: "100%" }}>
          {!crop ? (
            <Skeleton
              sx={{
                borderRadius: "10px",
              }}
              variant="rectangular"
              width={sizeWHSlider.width ? sizeWHSlider.width : "100%"}
              height={sizeWHSlider.height ? sizeWHSlider.height : "100%"}
            />
          ) : null}
          <ReactCrop
            crop={crop}
            onChange={(_, percentCrop) => setCrop(percentCrop)}
            onComplete={(c) => onCropImage(c)}
            aspect={aspect}
          >
            <img
              ref={imgRef}
              src={imageUpload}
              style={{
                width: "100%",
                opacity: !crop ? 0 : 1,
              }}
              alt="Crop me"
              onLoad={onImageLoad}
            />
          </ReactCrop>
          <br />
          <FormHelperText sx={{ textAlign: "center", mb: 1 }}>
            Crop image to fit size
          </FormHelperText>
          <Button
            variant="contained"
            onClick={() => onConfirmCrop("crop")}
            className="itemPopUp"
            sx={{ borderRadius: 2 }}

          >
            Confirm Upload
          </Button>
          {completedCrop && (
            <>
              <div>
                <canvas
                  ref={previewCanvasRef}
                  style={{
                    border: "1px solid black",
                    objectFit: "contain",
                    width: completedCrop.width,
                    height: completedCrop.height,
                    display: "none",
                  }}
                />
              </div>
            </>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default ImageUploadDialog;
