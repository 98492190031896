import React from "react";
import BoxImageOutlinePNG from "../../../assets/images/imageNotFound.jpeg";
import { LazyLoadImage } from "react-lazy-load-image-component";
export const BoxImageOutline = (props) => {
  let { width, height } = props;
  return (
    <div>
      <LazyLoadImage
        src={BoxImageOutlinePNG}
        alt="example"
        effect="blur"
        style={{
          borderRadius: "10px",
          objectFit: "cover",
          width: width ? width + "px" : "100px",
          height: height ? height + "px" : "100px",
          display: "block",
        }}
      />
    </div>
  );
};
