import { expiredToken } from "./expiredToken";
import {
  AccessTokenAPI,
  BusinessId,
  CompanyId,
  LanguageForm,
  ServiceId,
} from "./getLocalStorage";

import axios from "axios";

export const getAllPosUser = () => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_API_URL}/api/setting/getAllPosUser?language_code=${LanguageForm}&company_uid=${CompanyId}&business_uid=${BusinessId}&service_code=${ServiceId}`,
    headers: {
      Authorization: `Bearer ${AccessTokenAPI}`,
    },
  };

  return axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      console.log(error);
      expiredToken(error);
    });
};
export const getPosUser = (paramsId) => {
  let data = "";
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_API_URL}/api/setting/getPosUser?id=${paramsId}&language_code=${LanguageForm}&company_uid=${CompanyId}&business_uid=${BusinessId}&service_code=${ServiceId}`,
    headers: {
      Authorization: `Bearer ${AccessTokenAPI}`,
    },
    data: data,
  };

  return axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      console.log(error);
      expiredToken(error);
    });
};
export const updatePosUser = (dataAPI) => {
  let config = {
    method: "put",
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_API_URL}/api/setting/updatePosUser?company_uid=${CompanyId}&business_uid=${BusinessId}&service_code=${ServiceId}`,
    headers: {
      Authorization: `Bearer ${AccessTokenAPI}`,
      "Content-Type": "application/json",
    },
    data: JSON.stringify(dataAPI),
  };

  return axios(config)
    .then(function (response) {
      return response.data.message;
    })
    .catch(function (error) {
      console.log(error);
      expiredToken(error);
    });
};

export const storePosUser = (dataAPI) => {
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_API_URL}/api/setting/storePosUser?company_uid=${CompanyId}&business_uid=${BusinessId}&service_code=${ServiceId}`,
    headers: {
      Authorization: `Bearer ${AccessTokenAPI}`,
      "Content-Type": "application/json",
    },
    data: JSON.stringify(dataAPI),
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      expiredToken(error);
    });
};

export const getAllPosUserTemplate = (id) => {
  let data = "";
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_API_URL}/api/setting/getAllPosUserTemplate?id=${id}&language_code=${LanguageForm}&company_uid=${CompanyId}&business_uid=${BusinessId}&service_code=${ServiceId}`,
    headers: {
      Authorization: `Bearer ${AccessTokenAPI}`,
    },
    data: data,
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      console.log(error);
      expiredToken(error);
    });
};
export const addPosUserToTemplate = (dataAPI) => {
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_API_URL}/api/setting/addPosUserToTemplate?company_uid=${CompanyId}&business_uid=${BusinessId}&service_code=${ServiceId}`,
    headers: {
      Authorization: `Bearer ${AccessTokenAPI}`,
      "Content-Type": "application/json",
    },
    data: JSON.stringify(dataAPI),
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data.message;
    })
    .catch((error) => {
      console.log(error);
      expiredToken(error);
    });
};
