import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";

import DataMaterialTable from "../../components/ui/dataTable/dataMaterialTable";
import { useTranslation } from "react-i18next";
import { ActionDetail } from "../../components/ui/dataTable/components/actionDetail";
import { getAllCurrency } from "../../service/currencyAPI";
import { MomentConvert } from "../../components/ui/moment/momentConvert";
import BreadcrumbsTitle from "../../components/ui/breadcrumbsTitle";
import StatusTable from "../../components/chip/statusTable";
import { TextTable } from "../../components/ui/textfield/textfieldCustom";

function Currency() {
  const { t } = useTranslation();
  const [rows, setRows] = useState([]);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [columns, setColumns] = useState([]);

  const fetchData = async () => {
    let dataColumns = [
      {
        accessorKey: "name",
        flexGrow: 1,
        header: t("global_name"),
        Cell: ({ cell }) => <TextTable props={{ text: cell.getValue() }} />,
      },
      {
        accessorKey: "update",
        size: 100,
        header: t("global_updated_at"),
        Cell: ({ cell }) => (
          <TextTable props={{ text: cell.getValue() }} />
        ),
      },
      {
        accessorKey: "country",
        size: 100,
        header: t("country"),
      },
      {
        accessorKey: "status",
        enableSorting: false,
        size: 100,
        header: t("global_status"),
        Cell: ({ row }) => (
          <StatusTable props={{ status: row.original.status }} />
        ),
      },
      {
        accessorKey: "action",
        enableSorting: false,
        size: 100,
        header: "Action",
        // sortable: false,
        Cell: ({ row }) => (
          <Box sx={{ display: "flex", gap: 2 }}>
            <ActionDetail params={row} detailPath={"/currency/detail/"} />
          </Box>
        ),
      },
    ];

    let dataAPI = await getAllCurrency();
    if (dataAPI) {
      let dataBox = dataAPI
        .sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at))
        .map((item) => ({
          id: item.id,
          name: item.currency_lang.name,
          country: item.currency_lang.country,
          update: MomentConvert(item.currency_lang.updated_at),
          status: item.status,
        }));
      setRows(dataBox);
    }
    setIsLoadingData(false);
    setColumns(dataColumns);
  };

  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <Box>
      <BreadcrumbsTitle
        props={{
          title: t("currency"),
        }}
      />
      <DataMaterialTable statusViewMode={true}
        rows={rows}
        isLoadingData={isLoadingData}
        columns={columns}
        detailPath={"/currency/detail/"}
        createButton={t("currency")}
      />
    </Box>
  );
}

export default Currency;
