import React from "react";
import { Button, Typography } from "@mui/material";
function PageNotFound() {
  return (
    <div
      style={{
        position: "absolute",
        transform: "translate(-50%,-50%)",
        left: "50%",
        top: "50%",
      }}
    >
      <div style={{ width: "100%" }}>
        {/* <img src={pageNotFoundImage} alt="pageNotFoundImage" /> */}
        <Typography sx={(theme) => ({ color: theme.palette.secondary.main })}>
          ไม่พบข้อมูล
        </Typography>
        <Button
          variant="contained"
          onClick={() => window.location.replace("/")}
          sx={{ fontSize: "2.5rem", borderRadius: 2 }}
        >
          กลับสู่หน้าหลัก
        </Button>
      </div>
    </div>
  );
}

export default PageNotFound;
