import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import React from "react";
import { notifySuccess } from "../../popup/toastPopup";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import QRCode from "react-qr-code";

function DialogCoupon({ props }) {
  let { dialogCoupon, setDialogCoupon } = props;
  const onClickCopy = (item) => {
    navigator.clipboard.writeText(item);
    notifySuccess({
      title: "Copy " + item,
    });
  };
  return (
    <Dialog
      open={dialogCoupon.status}
      onClose={() => setDialogCoupon({ status: false, data: null })}
    >
      <DialogContent>
        {dialogCoupon.data ? (
          <QRCode
            size={256}
            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
            value={dialogCoupon.data}
            viewBox={`0 0 256 256`}
          />
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button
          startIcon={<ContentCopyIcon />}
          onClick={() => onClickCopy(dialogCoupon.data)}
          fullWidth
          sx={{ borderRadius: 2 }}
        >
          Copy Code
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DialogCoupon;
