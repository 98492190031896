import React, { useState, useEffect, useContext } from "react";
import { Grid, FormControlLabel, Box, Radio } from "@mui/material";
import { TitleTextfield } from "../../../components/ui/textfield/textfieldCustom";
import { getAllBranch } from "../../../service/branchAPI";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { notifySuccess } from "../../../components/ui/popup/toastPopup";
import { useTranslation } from "react-i18next";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ConfirmButton from "../../../components/ui/button/confirmButton";
import {
  addSaleProductToTemplate,
  getAllSaleProductTemplate,
  getSaleProduct,
  updateSaleProduct,
} from "../../../service/saleProductAPI";
import { uploadImage } from "../../../service/upload/uploadImage";
import SettingTypeBranch from "../../../components/ui/addTypeTemplate/settingTypeBranch";
import { getAllUnitCounter } from "../../../service/unitCounterAPI";
import Swal from "sweetalert2";
import BreadcrumbsTitle from "../../../components/ui/breadcrumbsTitle";
import ImageCrop from "../../../components/ui/upload/imageCrop";
import TitleForm from "../../../components/ui/formComponent/titleForm";
import Loading from "../../../components/ui/loading";
import StatusForm from "../../../components/ui/formComponent/statusForm";
import { WarningPopup } from "../../../components/ui/popup/successPopup";
import { useSchema } from "../../../components/hooks/use-schema";
import { saleProductSchema } from "../../../components/validation/saleProductSchema";
import { PaddingContainer } from "../../../components/utils/paddingContainer";
import TextfieldComponent from "../../../components/ui/textfield/textfieldComponent";
import SelectComponent from "../../../components/ui/textfield/selectComponent";
import MoneyIcon from "@mui/icons-material/Money";
import saleProductType from "../../../components/utils/saleProductType.json";
import CardItem from "../../../components/ui/card/cardItem";
import i18next from "i18next";
import useCopyToClipboard from "../../../components/hooks/use-copy-to-clipboard";
import PaperWrapper from "../../../components/ui/paper/paperWrapper";
import { BoxWrapper } from "../../../components/ui/box/boxWrapper";
import { GridContainerSize } from "../../../components/utils/gridContainerSize";
import { getImage } from "../../../service/upload/getImage";
import { UseConvertImageToBase64 } from "../../../components/hooks/use-convert-image-to-base-64";
import { BoxGapInput } from "../../../components/ui/box/boxGapInput";
import { getAllMainCategory } from "../../../service/mainCategory";
import { getAllMinorCategory } from "../../../service/minorCategory";
import { MyContext } from "../../../context/MyContext";
import { BoxFormTitleAction } from "../../../components/ui/box/boxFormTitleAction";
import TitleHeaderForm from "../../../components/ui/formComponent/titleHeaderForm";

function SaleProductDetail() {
  const [statusMainCategory, setStatusMainCategory] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    clearErrors,
    getValues,
    control,
  } = useForm(useSchema(saleProductSchema(statusMainCategory)));
  const { fetchSaleProduct, setDataBranchSaleProduct } = useContext(MyContext);
  const [mainCategory, setMainCategory] = useState();
  const [dataAll, setDataAll] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [image, setImage] = useState(null);
  const [statusButton, setStatusButton] = useState(true);
  const [allDataTemplate, setAllDataTemplate] = useState([]);
  const navigate = useNavigate();
  const paramsId =
    window.location.pathname.split("/")[
      window.location.pathname.split("/").length - 1
    ];
  const { t } = useTranslation();
  const [amountCount, setAmountCount] = useState(false);
  const [productAll, setProductAll] = useState([]);
  const [categoryAll, setCategoryAll] = useState([]);
  const [vatStatus, setVatStatus] = useState(false);
  // const [serviceChargeStatus, setServiceChargeStatus] = useState(false);
  // const [vatType, setVatType] = useState(false);
  const [unit, setUnit] = useState("");
  const [unitCounterAll, setUnitCounterAll] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [handleCopy] = useCopyToClipboard();
  const fetchData = async () => {
    localStorage.removeItem("uploadImage");
    // detail
    await fetchDataUnit();
    await fetchDataGetSaleProduct();
    // branch
    await fetchAllBranch();
    await setIsLoading(false);
  };
  const fetchDataGetSaleProduct = async () => {
    await fetchCategory();
    let dataAPI = await getSaleProduct(paramsId);
    let {
      products,
      sale_product_lang,
      image,
      price,
      status,
      stock_count,
      vat_status,
      main_categories,
      minor_categories,
      unit_counter_id,
      unit_counter,
      type,
    } = dataAPI;
    setDataAll(dataAPI);
    if (image) {
      let imageUrl = await getImage(image);
      image = UseConvertImageToBase64(imageUrl);
      await Promise.all(imageUrl);
    }

    const promises = products.map(async (item) => {
      if (item.image) {
        let imageUrl = await getImage(item.image);
        item.image = UseConvertImageToBase64(imageUrl);
      }
    });
    await Promise.all(promises);

    await setImage(image);
    await setValue("imageRef", image);
    await setVatStatus(vat_status);
    // await setServiceChargeStatus(service_charge_status);

    if (main_categories[0].status === false) {
      await setMainCategory("");
      await setValue("mainCategory", "");
    } else {
      await setValue("mainCategory", main_categories[0].id);
      await setMainCategory(main_categories[0].id);
    }
    setStatusMainCategory(false);
    await setValue(
      "minorCategory",
      minor_categories.length > 0 ? minor_categories[0].id : ""
    );
    if (unit_counter.status === false) {
      await setUnit("");
    } else {
      await setUnit(unit_counter_id);
    }
    // await setVatType(vat_type === "exclude" ? false : true);

    await setValue("unit", unit_counter_id);
    await setValue("saleProductName", sale_product_lang.name);
    await setValue("productNameReceipt", sale_product_lang.alt_name);
    await setValue("priceSaleProduct", price);
    // await setValue("vat", String(vat));
    // await setValue("serviceCharge", String(service_charge));
    await setValue("descriptionSaleProduct", sale_product_lang.description);
    await setValue("productType", type === "single" ? 1 : 2);

    await setStatusButton(status);
    await setAmountCount(stock_count);

    setProductAll(products);
    await setIsLoading(false);
  };
  const fetchCategory = async () => {
    let dataAPI = await getAllMainCategory();
    let dataAPIMinor = await getAllMinorCategory();
    let newDataAPI = dataAPI.filter((item) => item.status === true);
    let categoryBox = [];
    newDataAPI.forEach((item) => {
      let minorBox = [];
      dataAPIMinor
        .filter((itemSub) => itemSub.main_category_id === item.id)
        .forEach((subCategory) => {
          minorBox.push({
            id: subCategory.minor_category_lang.minor_category_id,
            name: subCategory.minor_category_lang.name,
          });
        });
      categoryBox.push({
        id: item.main_category_lang.main_category_id,
        name: item.main_category_lang.name,
        minor_categories: minorBox,
      });
    });
    setCategoryAll(categoryBox);
  };

  const onConfirmForm = async (register) => {
    setIsSubmitting(true);
    if (localStorage.getItem("uploadImage")) {
      WarningPopup({ title: t("notification_please_upload_image") });
    } else {
      let newImage = "";
      if (image) {
        if (typeof image === "object") {
          newImage = await uploadImage(image);
        } else {
          newImage = dataAll.image;
        }
      }
      let productTypeName = saleProductType[i18next.language].find(
        (item) => item.id === Number(register.productType)
      );
      setTimeout(async () => {
        let dataAPI = {
          id: String(paramsId),
          unit_counter_id: unit,
          image: newImage,
          price: Number(register.priceSaleProduct),
          type: productTypeName.valueName,
          vat_status: vatStatus,
          status: statusButton,
          sale_product_lang: {
            name: register.saleProductName,
            alt_name: register.productNameReceipt,
            description: register.descriptionSaleProduct,
            language_code: String(
              localStorage.getItem("language_form").toUpperCase()
            ),
          },
          main_categories: [mainCategory],
          minor_categories: register.minorCategory
            ? [register.minorCategory]
            : [],
        };
        let statusAPI = await updateSaleProduct(dataAPI);
        if (statusAPI === "OK") {
          fetchSaleProduct()
            .then(() => {
              notifySuccess({
                title: t("notification_successfully_edited_information"),
              });
              navigate("/sale-product");
            })
            .catch((error) => {
              console.error("Error fetching product:", error);
            });
        }
      }, 500);
    }
    await new Promise((resolve) => setTimeout(resolve, 2000));
    setIsSubmitting(false);
  };
  const onChangeMainCategory = (item) => {
    let statusMain = categoryAll.find((cate) => cate.id === item);
    if (statusMain.minor_categories.length > 0) {
      setStatusMainCategory(true);
    } else {
      setStatusMainCategory(false);
    }
    setValue("mainCategory", item);
    clearErrors("mainCategory");
    setValue("minorCategory", "");
    setMainCategory(item);
  };
  const onVatStatus = (status) => {
    setVatStatus(status);
  };
  const fetchDataUnit = async () => {
    let dataAPI = await getAllUnitCounter();
    dataAPI = dataAPI.filter((item) => item.status === true);
    setUnitCounterAll(dataAPI);
  };
  const onAddTypeToTemplate = async () => {
    let branchArray = allDataTemplate.filter((item) => item.select === true);
    let dataSelect = [];
    branchArray.forEach((item) => {
      dataSelect.push(item.branch.id);
    });
    let dataAPI = {
      sale_product_id: String(paramsId),
      branches: dataSelect,
      priority: 1,
    };
    let dataStatus = await addSaleProductToTemplate(dataAPI);
    if (dataStatus === "OK") {
      setDataBranchSaleProduct([]);
      notifySuccess({
        title: t("notification_successfully_edited_information"),
      });
      fetchAllBranch();
    }
  };
  const fetchAllBranch = async () => {
    let dataAPI = await getAllBranch();
    dataAPI = dataAPI.filter((item) => item.branch_lang !== null);
    await fetchSettingSelectTemplate(dataAPI);
  };
  const fetchSettingSelectTemplate = async (dataBranch) => {
    let dataAPI = await getAllSaleProductTemplate(paramsId);
    if (dataAPI.length > 0) {
      dataAPI = dataAPI[0].template_sale_products;
      let dataBox = [];
      dataBranch.forEach((item) => {
        let dataNews = dataAPI.filter(
          (itemTemplate) => itemTemplate.template_id === item.template_id
        );
        if (dataNews.length > 0) {
          dataBox.push({
            branch: item,
            data: dataNews[0],
            select: false,
          });
        } else {
          dataBox.push({
            branch: item,
            data: null,
            select: false,
          });
        }
      });
      await setAllDataTemplate(dataBox);
    }
  };
  const onConfirmStatusChange = async (checked) => {
    if (checked) {
      setStatusButton(checked);
    } else {
      Swal.fire({
        title: t("notification_confirm_change_status"),
        text: t(
          "notification_changing_the_status_will_affect_the_branch_settings"
        ),
        icon: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          setStatusButton(checked);
        }
      });
    }
  };
  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onClickCopy = (item) => {
    let dataCopy = getValues("saleProductName");
    handleCopy(dataCopy);
    notifySuccess({
      title: "Copy " + dataCopy,
    });
  };
  return (
    <Box pb={2}>
      <BreadcrumbsTitle
        props={{
          title: t("sale_product_info"),
          dataLink: [
            { text: t("sale_product"), link: "/sale-product" },
            { text: t("sale_product_info"), link: null },
          ],
        }}
      />
      {isLoading ? (
        <Loading />
      ) : (
        <GridContainerSize>
          <Box p={2}>
            <form onSubmit={handleSubmit(onConfirmForm)}>
              <BoxWrapper>
                <PaperWrapper props={{ growFull: 1 }}>
                  <BoxFormTitleAction>
                    <TitleHeaderForm props={{ text: t("sale_product") }} />
                    <StatusForm
                      statusButton={statusButton}
                      setStatusButton={
                        onConfirmStatusChange
                          ? onConfirmStatusChange
                          : setStatusButton
                      }
                    />
                  </BoxFormTitleAction>
                  <BoxGapInput>
                    <Box className="boxWrapper-container">
                      <BoxWrapper>
                        <PaperWrapper props={{ growFull: null }}>
                          <TitleForm
                            props={{ text: t("sale_product_image") }}
                          />
                          <Box align="center">
                            <ImageCrop
                              props={{
                                setImage,
                                image,
                                heightCrop: 256,
                                widthCrop: 256,
                                label: "staffPOS",
                              }}
                            />
                          </Box>
                        </PaperWrapper>
                        <PaperWrapper props={{ growFull: 1 }}>
                          <TitleForm props={{ text: t("sale_product_info") }} />
                          <BoxGapInput>
                            <TextfieldComponent
                              title={t("sale_product_name")}
                              name="saleProductName"
                              register={register}
                              placeholder={t("sale_product_example")}
                              errors={errors}
                              iconEnd={<ContentCopyIcon />}
                              iconEndOnclick={onClickCopy}
                            />
                            <TextfieldComponent
                              title={t("sale_product_receipt_name")}
                              name="productNameReceipt"
                              register={register}
                              placeholder={t("sale_product_example")}
                              errors={errors}
                            />
                            <SelectComponent
                              watch={watch}
                              title={t("unit")}
                              register={register}
                              setValue={setValue}
                              name="unit"
                              errors={errors}
                              dataEmpty={t("unit_select")}
                              optionsData={unitCounterAll}
                              dataValue={unit}
                              onFunctionName={setUnit}
                              fullGrid={true}
                              clearErrors={clearErrors}
                              control={control}
                            />
                            <TextfieldComponent
                              title={t("sale_product_description")}
                              name="descriptionSaleProduct"
                              register={register}
                              placeholder={t(
                                "sale_product_description_example"
                              )}
                              errors={errors}
                              multiline={true}
                              rows={4}
                              fullGrid={true}
                            />
                          </BoxGapInput>
                        </PaperWrapper>
                      </BoxWrapper>
                      <PaperWrapper props={{ growFull: 1 }}>
                        <TitleForm
                          props={{ text: t("global_calculate_vat_title") }}
                        />
                        <Box p={PaddingContainer.size}>
                          {/* <TextfieldComponent
                      name="vat"
                      register={register}
                      placeholder={t("global_vat_example")}
                      errors={errors}
                      iconEnd={<PercentIcon />}
                      disabled={vatStatus}
                      fullGrid={true}
                    /> */}
                          <FormControlLabel
                            control={
                              <Radio
                                name="vatStatus"
                                checked={vatStatus}
                                onChange={(e) => onVatStatus(true)}
                              />
                            }
                            label={t("global_calculate_vat")}
                          />
                          <FormControlLabel
                            control={
                              <Radio
                                name="vatStatus"
                                checked={!vatStatus}
                                onChange={(e) => onVatStatus(false)}
                              />
                            }
                            label={t("global_not_calculate_vat")}
                          />
                        </Box>
                      </PaperWrapper>
                      {/* <PaperWrapper props={{ growFull: 1 }}>
                  <TitleForm props={{ text: t("global_service_charge") }} />
                  <Box p={PaddingContainer.size}>
                    <TextfieldComponent
                      name="serviceCharge"
                      register={register}
                      placeholder={t("global_service_example")}
                      errors={errors}
                      iconEnd={<PercentIcon />}
                      disabled={serviceChargeStatus}
                      fullGrid={true}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="serviceChargeStatus"
                          checked={serviceChargeStatus}
                          onChange={(e) =>
                            onServiceChargeStatus(!serviceChargeStatus)
                          }
                        />
                      }
                      label={t("global_service_charge_exclude")}
                    />
                  </Box>
                </PaperWrapper> */}
                      <BoxWrapper>
                        <PaperWrapper props={{ growFull: 1 }}>
                          <TitleForm props={{ text: t("global_price") }} />
                          <Box p={PaddingContainer.size}>
                            <TextfieldComponent
                              title={t("global_price")}
                              name="priceSaleProduct"
                              register={register}
                              placeholder={t("global_price_example")}
                              errors={errors}
                              iconEnd={<MoneyIcon />}
                              fullGrid={true}
                            />
                          </Box>
                        </PaperWrapper>
                        <PaperWrapper props={{ growFull: 1 }}>
                          <TitleForm props={{ text: t("category") }} />
                          <BoxGapInput>
                            <SelectComponent
                              title={t("category")}
                              watch={watch}
                              register={register}
                              setValue={setValue}
                              name="mainCategory"
                              errors={errors}
                              dataEmpty={t("category_select")}
                              optionsData={categoryAll}
                              dataValue={mainCategory}
                              onFunctionName={onChangeMainCategory}
                              clearErrors={clearErrors}
                              control={control}
                              fullGrid={true}
                            />
                            {mainCategory &&
                            categoryAll.find(
                              (itemCate) => itemCate.id === mainCategory
                            ).minor_categories.length > 0 ? (
                              <Box>
                                <SelectComponent
                                  title={t("category_sub")}
                                  watch={watch}
                                  register={register}
                                  setValue={setValue}
                                  name="minorCategory"
                                  errors={errors}
                                  dataEmpty={t("category_sub_select")}
                                  optionsData={
                                    categoryAll.find(
                                      (itemCate) => itemCate.id === mainCategory
                                    ).minor_categories
                                  }
                                  fullGrid={true}
                                  clearErrors={clearErrors}
                                  control={control}
                                />
                              </Box>
                            ) : null}
                          </BoxGapInput>
                        </PaperWrapper>
                      </BoxWrapper>
                      <BoxWrapper>
                        <PaperWrapper props={{ growFull: 1 }}>
                          <TitleForm
                            props={{ text: t("sale_product_amount") }}
                          />
                          <Box p={PaddingContainer.size}>
                            <FormControlLabel
                              control={
                                <Radio
                                  name="limit"
                                  checked={amountCount === true}
                                  onChange={(e) => setAmountCount(true)}
                                  disabled
                                />
                              }
                              label={t("sale_product_amount_limit")}
                            />
                            <FormControlLabel
                              control={
                                <Radio
                                  name="unlimited"
                                  checked={amountCount === false}
                                  onChange={(e) => setAmountCount(false)}
                                  disabled
                                />
                              }
                              label={t("sale_product_amount_un_limit")}
                            />
                          </Box>
                        </PaperWrapper>
                      </BoxWrapper>
                      <PaperWrapper props={{ growFull: 1 }}>
                        <TitleForm props={{ text: t("product_list") }} />
                        <Box p={PaddingContainer.size}>
                          <SelectComponent
                            title={t("sale_product_option_single_or_set")}
                            watch={watch}
                            register={register}
                            name="productType"
                            errors={errors}
                            dataEmpty={t(
                              "sale_product_option_single_or_set_select"
                            )}
                            optionsData={saleProductType[i18next.language]}
                            disabled={true}
                            clearErrors={clearErrors}
                            control={control}
                            fullGrid={true}
                          />

                          <Box mt={1}>
                            {productAll ? (
                              <>
                                <TitleTextfield text={t("product")} />

                                <Grid container spacing={2}>
                                  {productAll.map((product, index) => (
                                    <Grid
                                      item
                                      xs={12}
                                      sm={6}
                                      md={4}
                                      key={index}
                                    >
                                      <CardItem
                                        props={{
                                          product,
                                          count: true,
                                          name: product.product_lang.name,
                                          amount:
                                            product.sale_product_products
                                              .amount,
                                        }}
                                      />
                                    </Grid>
                                  ))}
                                </Grid>
                              </>
                            ) : null}
                          </Box>
                        </Box>
                      </PaperWrapper>
                      <PaperWrapper props={{ growFull: 1 }}>
                        <SettingTypeBranch
                          title={t("sale_product_branch")}
                          allDataTemplate={allDataTemplate}
                          setAllDataTemplate={setAllDataTemplate}
                          onAddTypeToTemplate={onAddTypeToTemplate}
                          directToBranch={"saleProduct"}
                        />
                      </PaperWrapper>
                    </Box>
                    <ConfirmButton disabled={isSubmitting} />
                  </BoxGapInput>
                </PaperWrapper>
              </BoxWrapper>
            </form>
          </Box>
        </GridContainerSize>
      )}
    </Box>
  );
}

export default SaleProductDetail;
