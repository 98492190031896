import { Box } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";

import { t } from "i18next";
import dayjs from "dayjs";
import BreadcrumbsTitle from "../../../components/ui/breadcrumbsTitle";
import SelectCheckMarkBranch from "../../../components/ui/select/selectCheckMarkBranch";
import { TextTable } from "../../../components/ui/textfield/textfieldCustom";
// import DetailBillReceipt from "./detailBillReceipt";
// import { useNavigate } from "react-router-dom";
import { MyContext } from "../../../context/MyContext";
import DataTableReport from "../../../components/ui/dataTable/dataTableReport";

function ReportByPayment() {
  const [rows, setRows] = useState([]);

  const [columns, setColumns] = useState([]);
  const [exportColumnName, setExportColumnName] = useState(null);
  // const navigate = useNavigate();
  const {
    setBranchSelectReport,
    branchAll,
    branchSelectReportDate,
    reportAll,
    fetchReport,
  } = useContext(MyContext);

    const [isLoadingData, setIsLoadingData] = useState(
      reportAll.length > 0 ? false : true
    );
  // const handleNavigate = (status) => {
  //   navigate("/report-by-bills", {
  //     state: { from: "payment-bill", statusPaymentType: status },
  //   });
  // };

  const fetchData = async () => {
    let headers = [];
    let dataBoxPayment = [];
    let dataBox = [];
    if (reportAll) {
      reportAll.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));
      let groupBranch = [];
      reportAll.forEach((itemReceipt) => {
        let dataExists = groupBranch.find(
          (branch) => branch.branch_id === itemReceipt.branch_id
        );
        if (dataExists) {
          dataExists.receiptAll.push(itemReceipt);
        } else {
          groupBranch.push({
            branch_id: itemReceipt.branch_id,
            receiptAll: [itemReceipt],
          });
        }
      });

      groupBranch.forEach((itemBranch) => {
        let dataCollectPayment = [];
        itemBranch.receiptAll.forEach((itemReceipt) => {
          itemReceipt.receipt_payment_types.forEach((itemPaymentEach) => {
            let dataFind = dataCollectPayment.find(
              (itemPayment) => itemPayment.paymentType === itemPaymentEach.type
            );
            if (dataFind === undefined) {
              dataCollectPayment.push({
                branch_id: itemReceipt.branch_id,
                paymentType: itemPaymentEach.type,
                paymentName: itemPaymentEach.name,
                total: itemPaymentEach.total,
                refund:
                  itemReceipt.receipt_status === "void"
                    ? itemPaymentEach.total
                    : 0,
              });
            } else {
              dataFind.total += itemPaymentEach.total;
              dataFind.refund +=
                itemReceipt.receipt_status === "void"
                  ? itemPaymentEach.total
                  : 0;
            }
          });
        });

        dataBoxPayment.push(dataCollectPayment);
      });
    }
    dataBoxPayment.forEach((itemBranch) => {
      itemBranch.sort((a, b) => {
        if (a.paymentType < b.paymentType) return -1;
        if (a.paymentType > b.paymentType) return 1;
        return 0;
      });
      itemBranch.forEach((itemEach) => {
        dataBox.push({
          paymentName: itemEach.paymentName,
          summaryPrice: itemEach.total,
          refund: itemEach.refund,
          totalSum: itemEach.total - itemEach.refund,
          branch_name: branchAll.find((item) => item.id === itemEach.branch_id)
            .name,
        });
      });
    });
    // dataBox.push({
    //   paymentName: "Total",
    //   summaryPrice: dataBox.reduce((acc, item) => acc + item.summaryPrice, 0),
    //   refund: dataBox.reduce((acc, item) => acc + item.refund, 0),
    //   total: dataBox.reduce((acc, item) => acc + item.total, 0),
    //   branch_name: null,
    // });

    setRows(dataBox);
    let dataColumns = [
      {
        header: t("report_payment_type"),
        accessorKey: "paymentName",
        flexGrow: 1,
        Cell: ({ cell }) => <TextTable props={{ text: cell.getValue() }} />,
      },
      {
        header: t("report_summary_price"),
        accessorKey: "summaryPrice",
        flexGrow: 1,
        Cell: ({ cell }) => (
          <TextTable
            props={{
              text: Number(cell.getValue()).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }),
              alignText: "right",
            }}
          />
        ),
      },
      {
        header: t("report_refund"),
        isSorting: true,
        accessorKey: "refund",
        Cell: ({ cell }) => (
          <TextTable
            props={{
              text: Number(cell.getValue()).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }),
              alignText: "right",
            }}
          />
        ),
      },
      {
        header: t("report_total"),
        isSorting: true,
        accessorKey: "totalSum",
        Cell: ({ cell }) => (
          <TextTable
            props={{
              text: Number(cell.getValue()).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }),
              alignText: "right",
            }}
          />
        ),
      },
      {
        header: t("report_branch"),
        accessorKey: "branch_name",
        flexGrow: 1,
        Cell: ({ cell }) => <TextTable props={{ text: cell.getValue() }} />,
      },
    ];
    dataColumns.forEach((item) => {
      if (item.header !== "Image" && item.header !== "Action") {
        headers.push(item.header);
      }
    });
    setColumns(dataColumns);
    //===== Start set header name export csv
    const csvStringArray = [
      headers,
      ...dataBox.map((item) => [
        item.paymentName,
        item.summaryPrice,
        item.refund,
        item.total,
        item.branch_name,
      ]),
    ]
      .map((row) => row.join(","))
      .join("\n");

    setExportColumnName(csvStringArray);
    //===== End set header name export csv
    setIsLoadingData(false);
  };
  const onConfirm = async () => {
    await setIsLoadingData(true);
    await fetchReport();
  };
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportAll]);
  return (
    <Box>
      <BreadcrumbsTitle
        props={{
          title: t("report_by_payment"),
        }}
      />
      <Box>
        <SelectCheckMarkBranch
          props={{
            onConfirm,
            setBranchSelectReport,
          }}
        />

        <DataTableReport
          statusViewMode={true}
          rows={rows}
          exportColumnName={exportColumnName}
          isLoadingData={isLoadingData}
          columns={columns}
          height={0.85}
          fileName={
            t("report_by_payment") +
            `${dayjs(branchSelectReportDate.startDate).format(
              "DD/MM/YYYY"
            )} - ${dayjs(branchSelectReportDate.endDate).format("DD/MM/YYYY")}`
          }
        />
      </Box>
    </Box>
  );
}

export default ReportByPayment;
