import { Box } from "@mui/material";
import React from "react";
import LineChartComponent from "./chartComponent/lineDashboard";

function DashboardTotalSale({ props }) {
  let {
    data,
    branchSelect,
    nameValue,
    symbol,
    title,
    summary,
    startDate,
    endDate,
    isConfirmData,
  } = props;
  return (
    <div>
      <Box
        sx={(theme) => ({
          backgroundColor: "#ffffff",
          boxSizing: "border-box",
          p: 2,
          [theme.breakpoints.down("sm")]: {
            p: 0,
          },
          borderRadius: 2,
          overflow: "auto",
          width: "100%",
        })}
      >
        <LineChartComponent
          props={{
            dataValues: data,
            nameValue: nameValue,
            branchSelect,
            symbol,
            title,
            summary,
            startDate,
            endDate,
            isConfirmData,
          }}
        />
      </Box>
    </div>
  );
}

export default DashboardTotalSale;
