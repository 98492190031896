import React, { useState, useEffect, useContext } from "react";
import { Box, IconButton, Switch, Typography, Chip } from "@mui/material";

import DataMaterialTable from "../../../components/ui/dataTable/dataMaterialTable";
import { useTranslation } from "react-i18next";
import {
  addPromotionToTemplate,
  disabledPromotion,
  getAllPromotion,
} from "../../../service/promotionAPI";
import { BoxImageBlank } from "../../../components/ui/boxImage/boxImageBlank";
import moment from "moment";
import BreadcrumbsTitle from "../../../components/ui/breadcrumbsTitle";
import StatusTable from "../../../components/chip/statusTable";
import { TextTable } from "../../../components/ui/textfield/textfieldCustom";
import Swal from "sweetalert2";
import {
  notifyDefault,
  notifySuccess,
} from "../../../components/ui/popup/toastPopup";
import { MomentConvert } from "../../../components/ui/moment/momentConvert";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import { RemoveRedEye } from "@mui/icons-material";
import { getImage } from "../../../service/upload/getImage";
import { UseConvertImageToBase64 } from "../../../components/hooks/use-convert-image-to-base-64";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import dayjs from "dayjs";
import { MyContext } from "../../../context/MyContext";
import { BoxImageTable } from "../../../components/ui/boxImage/boxImageTable";

function Promotion() {
  const { t } = useTranslation();
  const [rows, setRows] = useState([]);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [columns, setColumns] = useState([]);
  const navigate = useNavigate();
  const [exportColumnName, setExportColumnName] = useState(null);
  const {
    salePromotionSetAll,
    salePromotionAllTable,
    setSalePromotionAllTable,
    setProgress,
  } = useContext(MyContext);
  const handleMenuEdit = (row, params) => {
    if (row.original.id) {
      navigate("/promotions/detail/" + row.original.id);
    } else {
      navigate("/promotions/detail/" + params.original.id, { state: "edit" });
    }
  };
  const onUpdateConfirm = async (item, checked) => {
    if (checked) {
      onUpdateStatus(item, checked);
    } else {
      Swal.fire({
        title: t("notification_confirm_change_status"),
        text: t(
          "notification_changing_the_status_will_affect_the_branch_settings"
        ),
        icon: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          onUpdateStatus(item, checked);
        }
      });
    }
  };
  const onUpdateStatus = async (item, checked) => {
    setRows((prevRows) => {
      const dataNews = [...prevRows];
      dataNews[item.index].status = checked;
      return dataNews;
    });
    let dataAPI = {
      promotion_id: item.original.id,
      status: checked,
    };

    let statusAPI = await disabledPromotion(dataAPI);
    if (statusAPI === "OK") {
      if (checked) {
        notifySuccess({ title: t("toast_active") });
      } else {
        notifyDefault({ title: t("toast_in_active") });
      }
    }
  };
  const fetchData = async () => {
    let dataColumns = [
      {
        accessorKey: "image",
        size: 50,
        enableSorting: false,
        header: t("global_image"),
        Cell: ({ row }) => (
          <Box sx={{ display: "flex", gap: 1 }}>
            <Box>
              {row.original.image ? (
                <BoxImageTable
                  image={row.original.image}
                  width={50}
                  height={50}
                  type="table"
                  borderRadius={true}
                />
              ) : (
                <BoxImageBlank width={50} height={50} />
              )}
            </Box>
          </Box>
        ),
      },
      {
        accessorKey: "name",
        flexGrow: 1,
        header: t("promotion_name"),
        Cell: ({ cell }) => <TextTable props={{ text: cell.getValue() }} />,
      },
      {
        accessorKey: "limit_use",
        flexGrow: 1,
        header: t("promotion_limit_of_use_promotion"),
        Cell: ({ cell }) => <TextTable props={{ text: cell.getValue() }} />,
      },
      {
        accessorKey: "start",
        flexGrow: 1,
        header: t("promotion_start"),
        Cell: ({ row, cell }) => (
          <>
            <TextTable
              props={{
                text: row.original.start,
              }}
            />
          </>
        ),
      },
      {
        accessorKey: "end",
        flexGrow: 1,
        header: t("promotion_expired"),
        Cell: ({ row }) => (
          <>
            {row.original.end !== t("global_non_expired_date") &&
            row.original.end !== null &&
            row.original.end !== "" ? (
              <>
                <TextTable
                  props={{
                    text: row.original.end,
                  }}
                />
              </>
            ) : (
              <>
                <TextTable props={{ text: t("global_non_expired_date") }} />
              </>
            )}
          </>
        ),
      },
      {
        accessorKey: "statusExpiration",
        enableSorting: false,
        flexGrow: 1,
        header: t("promotion_expirations_status"),
        Cell: ({ cell }) => (
          <>
            {/* <Chip
              label={
                <Typography
                  sx={{
                    fontflexGrow: 1,
                  }}
                >
                  {cell.getValue()}
                </Typography>
              }
              color={
                cell.getValue() === "Expired"
                  ? "error"
                  : cell.getValue() === "Pending"
                  ? "default"
                  : "success"
              }
              variant="outlined"
              icon={
                <FiberManualRecordIcon
                  sx={(theme) => ({
                    fontflexGrow: 1,
                  })}
                />
              }
              sx={(theme) => ({
                borderRadius: 2,
              })}
            /> */}
            <Chip
              label={
                <Typography
                  sx={{
                    fontSize: 13,
                  }}
                >
                  {cell.getValue()}
                </Typography>
              }
              color={
                cell.getValue() === "Expired"
                  ? "error"
                  : cell.getValue() === "Pending"
                  ? "default"
                  : "success"
              }
              icon={
                cell.getValue() ? (
                  <FiberManualRecordIcon
                    sx={(theme) => ({
                      fontSize: 13,
                    })}
                  />
                ) : null
              }
              sx={(theme) => ({
                // color: theme.palette.info.main,
                // backgroundColor: theme.palette.white.main,
                color:
                  cell.getValue() === "Expired"
                    ? theme.palette.error.main
                    : cell.getValue() === "Pending"
                    ? "#ccc"
                    : theme.palette.info.main,
                backgroundColor:
                  cell.getValue() === "Expired"
                    ? theme.palette.white.main
                    : cell.getValue() === "Pending"
                    ? theme.palette.white.main
                    : theme.palette.white.main,
                borderRadius: 2,
                border: `1px solid ${
                  cell.getValue() === "Expired"
                    ? theme.palette.error.main
                    : cell.getValue() === "Pending"
                    ? "#cccccc"
                    : theme.palette.info.main
                }`,
              })}
            />
          </>
        ),
      },
      {
        accessorKey: "update",
        header: t("global_updated_at"),
        flexGrow: 1,
        Cell: ({ cell }) => <TextTable props={{ text: cell.getValue() }} />,
      },
      {
        accessorKey: "status",
        enableSorting: false,
        flexGrow: 1,
        header: t("global_status"),
        Cell: ({ row }) => (
          <StatusTable props={{ status: row.original.status }} />
        ),
      },
      {
        accessorKey: "action",
        enableSorting: false,
        flexGrow: 1,
        header: "Action",
        sortable: false,
        Cell: ({ row, cell }) => (
          <Box sx={{ display: "flex", gap: 2 }}>
            {/* <ActionDetail params={row} detailPath={"/promotions/detail/"} /> */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                gap: "1rem",
              }}
            >
              <IconButton
                aria-label="Example"
                onClick={() => handleMenuEdit(row)}
              >
                {Number(
                  dayjs(row.original.start, "DD/MM/YYYY HH:mm").format(
                    "YYYYMMDDHHmmss"
                  )
                ) > Number(dayjs().format("YYYYMMDDHHmmss")) ? (
                  <EditIcon />
                ) : (
                  <RemoveRedEye />
                )}
              </IconButton>
            </Box>
            <Switch
              checked={row.original.status}
              onClick={() => onUpdateConfirm(row, !row.original.status)}
            />
          </Box>
        ),
      },
    ];

    let dataAPI = [];
    let dataBox = [];
    if (salePromotionSetAll) {
      dataAPI = salePromotionSetAll;
    } else {
      dataAPI = await getAllPromotion();
    }
    if (dataAPI) {
      if (salePromotionAllTable.length === 0) {
        dataBox = dataAPI
          .sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at))
          .map((item) => ({
            id: item.id,
            image: item.image,
            name: item.promotion_lang.name,
            limit_use: item.limit_use ? item.limit_use : t("global_un_limit"),
            update: MomentConvert(item.updated_at),
            start: moment(item.start, "YYYYMMDDHHmmss").format(
              "DD/MM/YYYY HH:mm"
            ),
            end: item.end
              ? moment(item.end, "YYYYMMDDHHmmss").format("DD/MM/YYYY HH:mm")
              : t("global_non_expired_date"),
            statusExpiration:
              item.end < moment().format("yyyyMMDDHHmmss")
                ? "Expired"
                : item.start > moment().format("yyyyMMDDHHmmss")
                ? "Pending"
                : "In Progress",
            status: item.status,
          }));

        setRows(dataBox);
        fetchDataImage(dataBox);
      } else {
        dataBox = salePromotionAllTable;
        setRows(salePromotionAllTable);
      }
      //===== Start set header name export csv
      let headers = [];
      dataColumns.forEach((item) => {
        if (item.header !== "Image" && item.header !== "Action") {
          headers.push(item.header);
        }
      });
      const csvStringArray = [
        headers,
        ...dataBox.map((item) => [
          item.image,
          item.name,
          item.limit_use,
          item.start,
          item.end,
          item.statusExpiration,
          item.update,
          item.status,
        ]),
      ]
        .map((row) => row.join(","))
        .join("\n");

      setExportColumnName(csvStringArray);
      //===== End set header name export csv
    }

    setColumns(dataColumns);
    setIsLoadingData(false);
  };
  const fetchDataImage = async (dataRows) => {
    let dataFetchImage = dataRows;
    const promises = dataFetchImage.map(async (item) => {
      let imageUrl = await getImage(item.image);
      item.image = UseConvertImageToBase64(imageUrl);
    });
    await Promise.all(promises);
    setRows(dataFetchImage);
    setSalePromotionAllTable(dataFetchImage);
  };
  const onInsertToBranches = async (rowsData, branchSelect) => {
    let countRound = 0;
    let timeBoost = 0;
    const promises = rowsData.map(async (row, index) => {
      await new Promise((resolve) => {
        setTimeout(async () => {
          let startTime = performance.now();
          let dataAPI = {
            promotion_id: String(row.original.id),
            branches: branchSelect,
            priority: 1,
          };
          let dataStatus = await addPromotionToTemplate(dataAPI);
          if (dataStatus === "OK") {
            countRound++;
            setProgress((prevProgress) =>
              prevProgress >= 100 ? 0 : prevProgress + 100 / rowsData.length
            );
          } else {
            countRound++;
            setProgress((prevProgress) =>
              prevProgress >= 100 ? 0 : prevProgress + 100 / rowsData.length
            );
          }
          if (countRound === rowsData.length) {
            setProgress(0);
            notifySuccess({
              title: t("notification_successfully_insert_information"),
            });
            resolve(true);
          } else {
            resolve(false);
          }
          if (index === 0) {
            let endTime = performance.now();
            let processTime = endTime - startTime;
            timeBoost = processTime;
          }
        }, (timeBoost ? timeBoost + 100 : 1800) * index);
      });
    });
    await Promise.all(promises);
  };
  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <Box>
      <BreadcrumbsTitle
        props={{
          title: t("promotion"),
        }}
      />
      <DataMaterialTable
        statusViewMode={true}
        onInsertToBranch={onInsertToBranches}
        enableSelection={true}
        exportColumnName={exportColumnName}
        fileName={t("promotion") + ` ${dayjs().format("DD/MM/YYYY")}`}
        rows={rows}
        isLoadingData={isLoadingData}
        columns={columns}
        detailPath={"/promotions/detail/"}
        createButton={t("promotion")}
        createPath={"/promotions/create"}
      />
    </Box>
  );
}

export default Promotion;
