import { Box, FormControlLabel, Grid, Switch, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  configGetAllSlider,
  configUpdateSlider,
} from "../../../../service/branchConfigAPI/branchConfigSlider";
import Loading from "../../../../components/ui/loading";
import MonitorIcon from "@mui/icons-material/Monitor";
import {
  notifyDefault,
  notifySuccess,
} from "../../../../components/ui/popup/toastPopup";
import { useTranslation } from "react-i18next";
import EmptyDataDirect from "../../../../components/ui/emptyDataDirect";
import { getImage } from "../../../../service/upload/getImage";
import { UseConvertImageToBase64 } from "../../../../components/hooks/use-convert-image-to-base-64";

function BranchBackScreen(props) {
  let { templateId } = props;
  const [isLoading, setIsLoading] = useState(true);

  const [data, setData] = useState(null);
  const { t } = useTranslation();

  const fetchData = async () => {
    let dataAPI = await configGetAllSlider(templateId);
    dataAPI = dataAPI.filter((item) => item.slider.status === true);
    const boxScreen = await Promise.all(
      dataAPI.map(async (itemData) => {
        const promises = itemData.slider.slider_images.map(
          async (sliderImage) => {
            const imageUrlNew = await getImage(sliderImage.url);
            sliderImage.url = UseConvertImageToBase64(imageUrlNew);
            return sliderImage;
          }
        );
        await Promise.all(promises);
        return itemData;
      })
    );
    boxScreen.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));
    setData(boxScreen);

    await setIsLoading(false);
  };
  const onChangeStatusSlider = async (e, item, index) => {
    let dataNews = [...data];
    let checked = e.target.checked;
    let promises = [];
    let count = 0;
    dataNews.forEach(async (itemCheck) => {
      if (itemCheck.slider.type === item.slider.type) {
        if (itemCheck.id === item.id && itemCheck.status === false) {
          itemCheck.status = checked;
          let dataAPI = {
            template_id: String(templateId),
            slider_id: itemCheck.slider_id,
            status: true,
          };
          let promise = configUpdateSlider(dataAPI);
          promises.push(promise);
        } else {
          itemCheck.status = false;
          let dataAPI = {
            template_id: String(templateId),
            slider_id: itemCheck.slider_id,
            status: false,
          };
          let promise = configUpdateSlider(dataAPI);
          promises.push(promise);
        }
      }
      count++;
    });

    Promise.all(promises).then(() => {
      if (count === dataNews.length) {
        if (checked) {
          notifySuccess({ title: t("toast_active") });
        } else {
          notifyDefault({ title: t("toast_in_active") });
        }
      }
      setData(dataNews);
    });
  };
  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <Box>
      {isLoading ? (
        <>
          <Loading />
        </>
      ) : (
        <>
          {data.length > 0 ? (
            <>
              <Box
                sx={(theme) => ({
                  overflow: "auto",
                  height: "70vh",
                  boxSizing: "border-box",
                  backgroundColor:
                    theme.palette.mode === "dark" ? "#202020" : null,
                  borderRadius: 1,
                  px: 2,
                  color: theme.palette.primaryText.main,
                })}
              >
                {data.map((itemSlider, index) => (
                  <Box
                    my={2}
                    key={index}
                    sx={{
                      boxShadow: 2,
                      p: 2,
                      borderRadius: 1,
                      boxSizing: "border-box",
                    }}
                    className="grid-paper-white"
                  >
                    <Box
                      sx={(theme) => ({
                        display: "flex",
                        justifyContent: "space-between",
                      })}
                    >
                      <Box
                        sx={(theme) => ({
                          display: "flex",
                          alignItems: "center",
                          gap: 1,
                        })}
                      >
                        <MonitorIcon />
                        <Typography
                          sx={(theme) => ({
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            fontWeight: "bold",
                          })}
                        >
                          {itemSlider.slider.name}
                        </Typography>
                      </Box>

                      <FormControlLabel
                        control={
                          <Switch
                            checked={itemSlider.status}
                            onChange={(e) =>
                              onChangeStatusSlider(e, itemSlider, index)
                            }
                          />
                        }
                        label={
                          itemSlider.status
                            ? t("toast_active")
                            : t("toast_in_active")
                        }
                      />
                    </Box>
                    <Grid container sx={{ gap: 2 }}>
                      {itemSlider.slider.slider_images.map(
                        (itemImage, index) => (
                          <Grid item xs={12} sm={3} md={2} key={index}>
                            {itemImage.url ? (
                              <>
                                <img
                                  src={
                                    typeof itemImage.url === "string"
                                      ? itemImage.url
                                      : typeof itemImage.url === "object"
                                      ? URL.createObjectURL(itemImage.url)
                                      : null
                                  }
                                  alt="slider"
                                  width={"100%"}
                                  style={{
                                    display: "block",
                                    borderRadius: "10px",
                                    filter: itemSlider.status
                                      ? "saturate(1)"
                                      : "saturate(0)",
                                  }}
                                />
                              </>
                            ) : (
                              <>
                                <img
                                  src={
                                    "https://archive.org/download/no-photo-available/no-photo-available.png"
                                  }
                                  alt="slider"
                                  width={"100%"}
                                />
                              </>
                            )}
                          </Grid>
                        )
                      )}
                    </Grid>
                  </Box>
                ))}
              </Box>
            </>
          ) : (
            <Box p={2}>
              {data.length === 0 ? (
                <EmptyDataDirect
                  props={{ url: "/slider", text: t("back_screen") }}
                />
              ) : null}
            </Box>
          )}
        </>
      )}
    </Box>
  );
}

export default BranchBackScreen;
