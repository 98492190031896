import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { notifySuccess } from "../../../components/ui/popup/toastPopup";
import { useForm } from "react-hook-form";
import BreadcrumbsTitle from "../../../components/ui/breadcrumbsTitle";
import { useNavigate } from "react-router-dom";
import { useSchema } from "../../../components/hooks/use-schema";
import TableListItemForm from "./tableListItemForm";
import { uploadImage } from "../../../service/upload/uploadImage";
import { storeTable } from "../../../service/tableAPI";
import { tableSchema } from "../../../components/validation/tableSchema";

function TableListItemCreate(props) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm(useSchema(tableSchema));
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [statusButton, setStatusButton] = useState(true);
  const [image, setImage] = useState(null);
  const navigate = useNavigate();
  const onConfirmForm = async (register) => {
    let newImage = "";
    if (image) {
      newImage = await uploadImage(image);
    }
    setIsSubmitting(true);
    let dataAPI = {
      name: register.tableName,
      image: newImage,
      amount: Number(register.amount),
      status: statusButton,
    };
    let statusAPI = await storeTable(dataAPI);
    if (statusAPI === "OK") {
      notifySuccess({
        title: t("toast_successfully_added_information"),
      });
      navigate("/table");
    }
    await new Promise((resolve) => setTimeout(resolve, 2000));
    setIsSubmitting(false);
  };
  return (
    <div align="left">
      <BreadcrumbsTitle
        props={{
          title: t("table_create"),
          dataLink: [
            {
              text: t("table"),
              link: "/table",
            },
            { text: t("table_create"), link: null },
          ],
        }}
      />
      <TableListItemForm
        props={{
          image,
          setImage,
          handleSubmit,
          onConfirmForm,
          register,
          errors,
          statusButton,
          setStatusButton,
          isSubmitting,
        }}
      />
    </div>
  );
}

export default TableListItemCreate;
