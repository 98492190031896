import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { storeVoidReceipt } from "../../../service/voidReceipt";
import { notifySuccess } from "../../../components/ui/popup/toastPopup";
import BreadcrumbsTitle from "../../../components/ui/breadcrumbsTitle";
import { useNavigate } from "react-router-dom";
import { useSchema } from "../../../components/hooks/use-schema";
import { voidReceiptSchema } from "../../../components/validation/voidReceiptSchema";
import CancelReceiptForm from "./voidReceiptForm";
import { GridContainerSize } from "../../../components/utils/gridContainerSize";
import { Box } from "@mui/material";

function VoidReceiptCreate() {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm(useSchema(voidReceiptSchema));
  const [statusButton, setStatusButton] = useState(true);
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const onConfirmForm = async (register) => {
    setIsSubmitting(true);
    let dataAPI = {
      status: statusButton,
      void_receipt_lang: {
        name: register.voidReceipt,
        language_code: "ALL",
      },
    };
    let statusAPI = await storeVoidReceipt(dataAPI);
    if (statusAPI === "OK") {
      notifySuccess({
        title: t("toast_successfully_added_information"),
      });
      navigate("/cancel-receipt");
    }
    await new Promise((resolve) => setTimeout(resolve, 2000));
    setIsSubmitting(false);
  };
  return (
    <div align="left">
      <BreadcrumbsTitle
        props={{
          title: t("void_receipt_create"),
          dataLink: [
            {
              text: t("void_receipt"),
              link: "/cancel-receipt",
            },
            {
              text: t("void_receipt_create"),
              link: null,
            },
          ],
        }}
      />
      <GridContainerSize>
        <Box p={2}>
          <CancelReceiptForm
            props={{
              handleSubmit,
              onConfirmForm,
              register,
              errors,
              statusButton,
              setStatusButton,
              isSubmitting,
            }}
          />
        </Box>
      </GridContainerSize>
    </div>
  );
}

export default VoidReceiptCreate;
