import { Box } from "@mui/material";
import React from "react";
import TitleForm from "../../../../components/ui/formComponent/titleForm";
import { useTranslation } from "react-i18next";
import SelectComponent from "../../../../components/ui/textfield/selectComponent";
import ResultDiscountTypeList from "../../../../components/utils/resultDiscountType.json";
import TextfieldComponent from "../../../../components/ui/textfield/textfieldComponent";
import i18next from "i18next";
import PaperWrapper from "../../../../components/ui/paper/paperWrapper";
import { BoxGapInput } from "../../../../components/ui/box/boxGapInput";
function PromotionResult({ props }) {
  let {
    register,
    errors,
    setValue,
    resultDiscountType,
    setResultDiscountType,
    discountType,
    control,
    clearErrors,
    watch,
  } = props;
  const { t } = useTranslation();
  const onResultDiscountType = (item) => {
    setResultDiscountType(item);
    setValue("resultDiscountType", item);
    setValue("resultDiscount", "");
    clearErrors("resultDiscountType");
  };
  return (
    <Box my={2}>
      <PaperWrapper props={{ growFull: 1 }}>
        <TitleForm props={{ text: t("promotion_result_definition") }} />
        <BoxGapInput>
          <SelectComponent
            title={t("promotion_select_result_definition")}
            watch={watch}
            register={register}
            name="resultDiscountType"
            errors={errors}
            dataEmpty={
              <>
                {t("promotion_discount_price")} /{" "}
                {t("promotion_discount_percentage")}
              </>
            }
            optionsData={ResultDiscountTypeList[i18next.language]}
            dataValue={resultDiscountType}
            onFunctionName={onResultDiscountType}
            fullGrid={true}
            control={control}
            clearErrors={clearErrors}
          />
          <TextfieldComponent
            name="resultDiscount"
            register={register}
            placeholder={t("global_amount_example")}
            errors={errors}
            iconEnd={resultDiscountType === 1 ? "THB" : "%"}
            disabled={!resultDiscountType || discountType === 3}
            fullGrid={true}
          />
        </BoxGapInput>
      </PaperWrapper>
    </Box>
  );
}

export default PromotionResult;
