import React, { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import TitleForm from "../formComponent/titleForm";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Fab,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import Loading from "../loading";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ClearIcon from "@mui/icons-material/Clear";
import { BoxImageTable } from "../boxImage/boxImageTable";
import { getImage } from "../../../service/upload/getImage";
import { UseConvertImageToBase64 } from "../../hooks/use-convert-image-to-base-64";

export function SaleProductDialog({ props }) {
  let {
    productAll,
    setProductAll,
    dataAllProduct,
    setDataAllProduct,
    setDialogProduct,
    dialogProduct,
    categoryAllAPI,
    saleProductAllAPI,
  } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [productType, setProductType] = useState(0);
  const { t } = useTranslation();
  const fetchData = async () => {
    let statusProductCategory = false;
    statusProductCategory = await fetchAllCategory();
    if (statusProductCategory) {
      await setIsLoading(false);
    }
  };

  const fetchAllCategory = async () => {
    let productStatus = false;
    let cateAll = null;
    cateAll = categoryAllAPI.filter((cate) => cate.status === true);
    productStatus = await fetchSaleProduct(cateAll);
    if (productStatus) {
      return true;
    } else {
      return false;
    }
  };

  const fetchSaleProduct = async (cateAll) => {
    let boxCategories = [];
    let saleProductAll = saleProductAllAPI.filter(
      (product) => product.status === true
    );
    cateAll.forEach((cate) => {
      let saleProductBox = saleProductAll.filter(
        (saleProduct) => saleProduct.main_categories[0].id === cate.id
      );
      let dataSub = [];
      if (saleProductBox.length > 0) {
        saleProductBox.forEach((saleProduct) => {
          dataSub.push({
            id: saleProduct.sale_product_lang.sale_product_id,
            data: saleProduct,
            show: true,
            select: false,
          });
        });
      }
      boxCategories.push({
        id: cate.main_category_lang.main_category_id,
        name: cate.main_category_lang.name,
        show: true,
        product: dataSub,
      });
    });
    setProductType(boxCategories[0].id);
    setDataAllProduct(boxCategories);
    return true;
  };

  const handleProductType = (productType) => {
    setProductType(productType.id);
  };
  const handleProductTypeMobile = (e) => {
    setProductType(e.target.value);
  };

  const onSelectProduct = (item, indexProduct, productCate) => {
    let dataNews = [...dataAllProduct];
    dataNews.forEach((itemCate) => {
      if (itemCate.id === productCate.id) {
        itemCate.product[indexProduct].select = !item.select;
      }
    });
    setDataAllProduct(dataNews);
  };
  const handleClear = () => {
    setSearch("");
    let dataSearch = "";
    setDataAllProduct((prevData) => {
      const newData = prevData.map((cate) => {
        const newProduct = cate.product.map((product) => {
          const productName = product.data.sale_product_lang.name.toUpperCase();
          product.show = !dataSearch || productName.includes(dataSearch);
          return product;
        });
        cate.show = !dataSearch || newProduct.some((product) => product.show);
        return { ...cate, product: newProduct };
      });
      if (newData.filter((item) => item.show === true).length > 0) {
        setProductType(newData.filter((item) => item.show === true)[0].id);
      }
      return newData;
    });
  };
  const handleSearchProduct = async (e) => {
    const dataSearch = e.target.value.toUpperCase();

    setSearch(e.target.value);
    setDataAllProduct((prevData) => {
      const newData = prevData.map((cate) => {
        const newProduct = cate.product.map((product) => {
          const productName = product.data.sale_product_lang.name.toUpperCase();
          product.show = !dataSearch || productName.includes(dataSearch);
          return product;
        });
        cate.show = !dataSearch || newProduct.some((product) => product.show);
        return { ...cate, product: newProduct };
      });
      if (newData.filter((item) => item.show === true).length > 0) {
        setProductType(newData.filter((item) => item.show === true)[0].id);
      }
      return newData;
    });
  };
  const onConfirmProduct = async () => {
    let dataConfirm = [];
    await dataAllProduct.forEach((cate) => {
      cate.show = true;

      cate.product.forEach(async (product) => {
        if (product.data.image.includes("data:image") === false) {
          let imageUrl = await getImage(product.data.image);
          product.data.image = await UseConvertImageToBase64(imageUrl);
        }
        product.show = true;
        if (product.select) {
          dataConfirm.push({
            id: product.id,
            name: product.data.sale_product_lang.name,
            image: product.data.image,
          });
        }
      });
    });
    await setProductAll(dataConfirm);
    await setDialogProduct(false);
  };
  const onClose = () => {
    setSearch("");
    setTimeout(() => {
      if (productAll) {
        let dataAllProductNew = [...dataAllProduct];
        dataAllProductNew.forEach((cate) => {
          cate.show = true;
          if (cate.product.length > 0) {
            cate.product.forEach((product) => {
              product.show = true;
              if (
                productAll.find(
                  (productSelect) =>
                    productSelect.name === product.data.sale_product_lang.name
                )
              ) {
                product.select = true;
              } else {
                product.select = false;
              }
            });
          }
        });
        setDataAllProduct(dataAllProductNew);
      }
    }, 500);
    setDialogProduct(false);
  };
  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <Dialog open={dialogProduct} maxWidth="md" fullWidth>
      <DialogTitle align="center">
        <Typography variant="h5">{t("sale_product_add")}</Typography>
      </DialogTitle>
      <DialogContent>
        {isLoading ? (
          <Loading />
        ) : (
          <Grid container justifyContent={"space-between"} pt={1}>
            <Grid item xs={12} sm={2.8}>
              <TitleForm props={{ text: t("category") }} />
              {window.innerWidth < 600 ? (
                <>
                  <Select
                    fullWidth
                    sx={{ my: 1 }}
                    value={productType}
                    onChange={handleProductTypeMobile}
                  >
                    {dataAllProduct
                      .filter((cate) => cate.show === true)
                      .sort(function (a, b) {
                        return a.id - b.id;
                      })
                      .map((cate) => (
                        <MenuItem value={cate.id}>{cate.name}</MenuItem>
                      ))}
                  </Select>
                </>
              ) : (
                <>
                  {dataAllProduct
                    .filter((cate) => cate.show === true)
                    .map((cate) => (
                      <Box
                        sx={(theme) => ({
                          width: "100%",
                          borderBottom: "1px solid #cccccc",
                          p: 2,
                          boxSizing: "border-box",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          backgroundColor:
                            productType === cate.id
                              ? theme.palette.secondary.main
                              : null,
                          color: productType === cate.id ? "#ffffff" : null,
                        })}
                        onClick={() => handleProductType(cate)}
                      >
                        <Typography sx={(theme) => ({ fontFamily: "Kanit" })}>
                          {cate.name}
                        </Typography>
                        {cate.amount > 0 && (
                          <Fab size="small" color="info" aria-label="add">
                            {cate.amount}
                          </Fab>
                        )}
                      </Box>
                    ))}
                </>
              )}
            </Grid>
            <Grid item xs={12} sm={9}>
              <TextField
                value={search}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  endAdornment: search && (
                    <InputAdornment position="end">
                      <ClearIcon
                        onClick={handleClear}
                        style={{ cursor: "pointer" }}
                      />
                    </InputAdornment>
                  ),
                }}
                onChange={handleSearchProduct}
                placeholder="Search"
                size="small"
                fullWidth
              />
              {/* <FormControlLabel
                  control={<Checkbox defaultChecked />}
                  label="เลือกทั้งหมด"
                /> */}
              <Grid
                container
                sx={(theme) => ({
                  height: "40vh",
                  maxHeight: "50vh",
                  overflow: "auto",
                })}
              >
                {dataAllProduct
                  .filter((data) => data.id === productType)
                  .map((productCate) => (
                    <>
                      {productCate.product.length === 0 ? (
                        <Grid item sm={12} align="center" p={2}>
                          <Typography>{t("global_data_not_found")}</Typography>
                        </Grid>
                      ) : (
                        <>
                          {productCate.show === true &&
                            productCate.product.map((product, indexProduct) => (
                              <>
                                {product.show === true && (
                                  <Grid
                                    item
                                    xs={12}
                                    sm={4}
                                    align="center"
                                    sx={{ boxSizing: "border-box", p: 1 }}
                                  >
                                    <Grid
                                      container
                                      sx={{ borderRadius: 1 }}
                                      onClick={() =>
                                        onSelectProduct(
                                          product,
                                          indexProduct,
                                          productCate
                                        )
                                      }
                                    >
                                      <Grid
                                        xs={12}
                                        sx={(theme) => ({
                                          position: "relative",
                                        })}
                                      >
                                        {product.select && (
                                          <>
                                            <Box
                                              sx={(theme) => ({
                                                zIndex: 99,
                                                position: "absolute",
                                                display: "grid",
                                                placeItems: "center",
                                                width: "100%",
                                                height: "100%",
                                              })}
                                            >
                                              <CheckCircleOutlineIcon
                                                sx={(theme) => ({
                                                  color: "#fff",
                                                  fontSize: 150,
                                                })}
                                              />
                                            </Box>
                                            <Box
                                              sx={(theme) => ({
                                                width: "100%",
                                                height: "100%",
                                                backgroundColor: "#fff",
                                                opacity: 0.5,
                                                position: "absolute",
                                              })}
                                            ></Box>
                                          </>
                                        )}
                                        <Box
                                          sx={(theme) => ({
                                            ":hover": {
                                              opacity: 0.8,
                                              cursor: "pointer",
                                            },
                                          })}
                                        >
                                          <BoxImageTable
                                            image={product.data.image}
                                            height={"256px"}
                                            width={"100%"}
                                            type="table"
                                            borderRadius={true}
                                          />
                                        </Box>
                                      </Grid>
                                      <Grid
                                        xs={12}
                                        sx={(theme) => ({
                                          position: "relative",
                                        })}
                                      >
                                        <Typography
                                          sx={(theme) => ({
                                            fontFamily: "Kanit",
                                          })}
                                        >
                                          {product.data.sale_product_lang.name}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                )}
                              </>
                            ))}
                        </>
                      )}
                    </>
                  ))}
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} sx={{ my: 2 }}>
              <Box
                sx={(theme) => ({
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  gap: 2,
                })}
              >
                <Button
                  size="large"
                  variant="outlined"
                  onClick={() => onClose()}
                  sx={{ borderRadius: 2 }}
                >
                  {t("cancel_button")}
                </Button>
                <Button
                  size="large"
                  variant="contained"
                  onClick={() => onConfirmProduct()}
                  sx={{ borderRadius: 2 }}
                >
                  {t("confirm_button")}
                </Button>
              </Box>
            </Grid>
          </Grid>
        )}
      </DialogContent>
    </Dialog>
  );
}
