import { RemoveRedEye } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import { t } from "i18next";
import React from "react";

function ViewMore({ props }) {
  let { setViewMore, viewMore, name } = props;
  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <Button
        startIcon={<RemoveRedEye />}
        sx={{ mb: 2, borderRadius: 2 }}
        onClick={() =>
          setViewMore({
            ...viewMore,
            [name]: !viewMore[name],
          })
        }
      >
        {t("global_view_more")}
      </Button>
    </Box>
  );
}

export default ViewMore;
