import React, { useEffect, useState } from "react";
import BreadcrumbsTitle from "../../../components/ui/breadcrumbsTitle";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import TitleForm from "../../../components/ui/formComponent/titleForm";
import { Grid } from "@mui/material";
import StatusForm from "../../../components/ui/formComponent/statusForm";
import ConfirmButton from "../../../components/ui/button/confirmButton";
import {
  addReceiptTypeToTemplate,
  getAllReceiptTypeTemplate,
  getReceiptType,
  updateReceiptType,
} from "../../../service/orderType";
import { getAllBranch } from "../../../service/branchAPI";
import { useNavigate } from "react-router-dom";
import SettingTypeBranch from "../../../components/ui/addTypeTemplate/settingTypeBranch";
import { notifySuccess } from "../../../components/ui/popup/toastPopup";
import Loading from "../../../components/ui/loading";
import { useSchema } from "../../../components/hooks/use-schema";
import { orderTypeSchema } from "../../../components/validation/orderTypeSchema";
import { PaddingContainer } from "../../../components/utils/paddingContainer";
import TextfieldComponent from "../../../components/ui/textfield/textfieldComponent";
import { GridContainerSize } from "../../../components/utils/gridContainerSize";

function OrderTypeDetail() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm(useSchema(orderTypeSchema));
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [statusButton, setStatusButton] = useState(true);
  const navigate = useNavigate();
  const [allDataTemplate, setAllDataTemplate] = useState([]);
  const paramsId =
    window.location.pathname.split("/")[
      window.location.pathname.split("/").length - 1
    ];
  const fetchData = async () => {
    // detail
    let statusOrder = await fetchOrderType();
    // branch
    if (statusOrder) {
      await fetchAllBranch();
    }
    await setIsLoading(false);
  };

  const fetchOrderType = async () => {
    let dataAPI = await getReceiptType(paramsId);
    setValue("orderTypeName", dataAPI.name);
    setValue("orderTypeAlt", dataAPI.alt_name);
    setStatusButton(dataAPI.status);
    return dataAPI.status;
  };
  const onConfirmForm = async () => {
    setIsSubmitting(true);
    let dataAPI = {
      id: String(paramsId),
      status: statusButton,
    };
    let statusAPI = await updateReceiptType(dataAPI);
    if (statusAPI === "OK") {
      notifySuccess({
        title: t("notification_successfully_edited_information"),
      });
      navigate("/order-type");
    }
    await new Promise((resolve) => setTimeout(resolve, 2000));
    setIsSubmitting(false);
  };
  const fetchAllBranch = async () => {
    let dataAPI = await getAllBranch();
    dataAPI = dataAPI.filter((item) => item.branch_lang !== null);
    await fetchSettingSelectTemplate(dataAPI);
  };
  const fetchSettingSelectTemplate = async (dataBranch) => {
    let dataAPI = await getAllReceiptTypeTemplate(paramsId);
    dataAPI = dataAPI[0].template_order_types;
    let dataBox = [];
    dataBranch.forEach((item) => {
      let dataNews = dataAPI.filter(
        (itemTemplate) => itemTemplate.template_id === item.template_id
      );
      if (dataNews.length > 0) {
        dataBox.push({
          branch: item,
          data: dataNews[0],
          select: false,
        });
      } else {
        dataBox.push({
          branch: item,
          data: null,
          select: false,
        });
      }
    });
    await setAllDataTemplate(dataBox);
  };
  const onaddReceiptTypeToTemplate = async () => {
    let branchArray = allDataTemplate.filter((item) => item.select === true);
    let dataSelect = [];
    branchArray.forEach((item) => {
      dataSelect.push(item.branch.id);
    });
    let dataAPI = {
      order_type_id: String(paramsId),
      branches: dataSelect,
      priority: 1,
    };
    let dataStatus = await addReceiptTypeToTemplate(dataAPI);
    if (dataStatus === "OK") {
      notifySuccess({ title: t("toast_successfully_added_information") });
      fetchAllBranch();
    }
  };
  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <BreadcrumbsTitle
        props={{
          title: t("order_type_info"),
          dataLink: [
            { text: t("order_type"), link: "/order-type" },
            { text: t("order_type_info"), link: null },
          ],
        }}
      />

      {isLoading ? (
        <Loading />
      ) : (
        <GridContainerSize>
          <form onSubmit={handleSubmit(onConfirmForm)}>
            <Grid
              container
              justifyContent={"space-between"}
              p={PaddingContainer.size}
            >
              <Grid item xs={12} className="grid-paper-white">
                <TitleForm props={{ text: t("orderType.orderType") }} />
                <Grid
                  container
                  justifyContent={"space-between"}
                  p={PaddingContainer.size}
                >
                  <TextfieldComponent
                    title={t("order_type_name")}
                    name="orderTypeName"
                    register={register}
                    placeholder={t("order_type_name_example")}
                    errors={errors}
                  />
                  <TextfieldComponent
                    title={t("order_type_receipt_name")}
                    name="orderTypeAlt"
                    register={register}
                    placeholder={t("order_type_receipt_name_example")}
                    errors={errors}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} className="grid-paper-white">
                <SettingTypeBranch
                  statusData={statusButton}
                  title={t("order_type_branch")}
                  allDataTemplate={allDataTemplate}
                  setAllDataTemplate={setAllDataTemplate}
                  onAddTypeToTemplate={onaddReceiptTypeToTemplate}
                  directToBranch={"paymentType"}
                />
              </Grid>
              <StatusForm
                statusButton={statusButton}
                setStatusButton={setStatusButton}
              />
              <ConfirmButton disabled={isSubmitting} />
            </Grid>
          </form>
        </GridContainerSize>
      )}
    </div>
  );
}

export default OrderTypeDetail;
