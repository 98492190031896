// Icon
import KeyboardIcon from "@mui/icons-material/Keyboard";
import MoneyIcon from "@mui/icons-material/Money";
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import PercentIcon from "@mui/icons-material/Percent";
import MonitorIcon from "@mui/icons-material/Monitor";
import { useTranslation } from "react-i18next";
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import BakeryDiningOutlinedIcon from '@mui/icons-material/BakeryDiningOutlined';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import Branch from "../../../pages/branch/branch";
import Unit from "../../../pages/unit/unit";
import PaymentType from "../../../pages/paymentType/paymentType";
import ProductOptionsCategory from "../../../pages/productOptions/productOptionsCategory/productOptionsCategory";
import ProductOptions from "../../../pages/productOptions/productOptions/productOptions";
import ProductCategory from "../../../pages/product/productCategory/productCategory";
import Product from "../../../pages/product/product/product";
import Category from "../../../pages/product/category/category";
import SaleProduct from "../../../pages/product/saleProduct/saleProduct";
import BackScreen from "../../../pages/back-screen/backScreen";
import Promotion from "../../../pages/promotion/promotion/promotion";
import MoneyOut from "../../../pages/cashDrawer/moneyOut/moneyOut";
import MoneyIn from "../../../pages/cashDrawer/moneyIn/moneyIn";
import VoidReceipt from "../../../pages/cashDrawer/voidReceipt/voidReceipt";
import StaffPOS from "../../../pages/staffPOS/staffPOS";
import Currency from "../../../pages/currency/currency";
import { CurrencyExchange } from "@mui/icons-material";
export const ListManagement = () => {
  const { t } = useTranslation();
  const listItem = [
    {
      name: t("branch"),
      exact: true,
      component: <Branch />,
      to: `/branch`,
      iconClassName: <StorefrontOutlinedIcon  sx={{ fontSize: 20 }}/>,
    },
    {
      name: t("staff_pos"),
      exact: true,
      component: <StaffPOS />,
      to: `/posUser`,
      iconClassName: <GroupsOutlinedIcon  sx={{ fontSize: 20 }}/>,
    },
    {
      name: t("currency"),
      component: <Currency />,
      expanded: true,
      to: "/currency",
      iconClassName: <CurrencyExchange  sx={{ fontSize: 20 }}/>,
    },
    {
      name: t("unit"),
      component: <Unit />,
      expanded: true,
      to: "/unit",
      iconClassName: <PercentIcon  sx={{ fontSize: 20 }}/>,
    },
    {
      name: t("payment_type"),
      component: <PaymentType />,
      to: "/payment-type",
      iconClassName: <MoneyIcon  sx={{ fontSize: 20 }}/>,
    },
    {
      name: t("product_option"),
      exact: true,
      iconClassName: <CategoryOutlinedIcon  sx={{ fontSize: 20 }}/>,
      expanded: true,
      subMenus: [
        {
          name: t("product_option_category"),
          component: <ProductOptionsCategory />,
          to: "/pre-define-category",
        },
        {
          name: t("product_option"),
          component: <ProductOptions />,
          to: "/pre-define-product",
        },
      ],
    },
    {
      name: t("product"),
      exact: true,
      iconClassName: <BakeryDiningOutlinedIcon  sx={{ fontSize: 20 }}/>,
      expanded: true,
      subMenus: [
        {
          name: t("product_category"),
          component: <ProductCategory />,
          to: "/product-category",
        },
        {
          name: t("product"),
          component: <Product />,
          to: "/products",
        },
        {
          name: t("category"),
          component: <Category />,
          to: "/category",
        },
        {
          name: t("sale_product"),
          component: <SaleProduct />,
          to: "/sale-product",
        },
      ],
    },
    {
      name: t("back_screen"),
      exact: true,
      iconClassName: <MonitorIcon  sx={{ fontSize: 20 }}/>,
      expanded: true,
      subMenus: [
        {
          name: t("back_screen"),
          component: <BackScreen />,
          to: "/slider",
        },
      ],
    },
    {
      name: t("promotion"),
      exact: true,
      iconClassName: <LocalOfferOutlinedIcon  sx={{ fontSize: 20 }}/>,
      expanded: true,
      subMenus: [
        {
          name: t("promotion"),
          component: <Promotion />,
          to: "/promotions",
        },
        // {
        //   name: t("order_type"),
        //   component: <OrderType />,
        //   to: "/order-type",
        // },
      ],
    },

    {
      name: t("side_bar_cash_drawer"),
      exact: true,
      iconClassName: <KeyboardIcon  sx={{ fontSize: 20 }}/>,
      expanded: true,
      subMenus: [
        {
          name: t("money_out"),
          component: <MoneyOut />,
          to: "/money-out",
        },
        {
          name: t("money_in"),
          component: <MoneyIn />,
          to: "/money-in",
        },
        {
          name: t("void_receipt"),
          component: <VoidReceipt />,
          to: "/cancel-receipt",
        },
      ],
    },
  ];
  return listItem;
};
