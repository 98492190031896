import { Box, FormControlLabel, FormHelperText, Switch } from "@mui/material";
import React, { useEffect, useState } from "react";
import BreadcrumbsTitle from "../../../components/ui/breadcrumbsTitle";
import { t } from "i18next";
import { GridContainerSize } from "../../../components/utils/gridContainerSize";
import PaperWrapper from "../../../components/ui/paper/paperWrapper";
import TitleForm from "../../../components/ui/formComponent/titleForm";
import { useForm } from "react-hook-form";
import ConfirmButton from "../../../components/ui/button/confirmButton";
import { notifySuccess } from "../../../components/ui/popup/toastPopup";
// import TextfieldComponent from "../../../components/ui/textfield/textfieldComponent";
import StatusForm from "../../../components/ui/formComponent/statusForm";
import {
  addPaymentChannelToTemplate,
  getAllPaymentChannel,
  getPaymentChannel,
  updatePaymentChannel,
} from "../../../service/paymentChannelAPI";
import { getAllPaymentChannelTemplate } from "../../../service/paymentChannelAPI";
import SettingTypeBranch from "../../../components/ui/addTypeTemplate/settingTypeBranch";
import { BoxGapInput } from "../../../components/ui/box/boxGapInput";
import { useNavigate } from "react-router-dom";
import { getAllBranch } from "../../../service/branchAPI";
// import { useSchema } from "../../../components/hooks/use-schema";
// import { paymentChannel } from "../../../components/validation/paymentChannel";
import { TitleTextfield } from "../../../components/ui/textfield/textfieldCustom";
import DeliveryOptions from "./channelFormOptions/deliveryOptions";
import dataDelivery from "./channelFormOptions/delivery.json";
import eWallet from "./channelFormOptions/e-wallet.json";
import dataCard from "./channelFormOptions/card.json";
import { getPaymentType } from "../../../service/paymentTypeAPI";
import Loading from "../../../components/ui/loading";
import EWalletOptions from "./channelFormOptions/eWalletOptions";
import CardOptions from "./channelFormOptions/cardOptions";
import NetsOptions from "./channelFormOptions/netsOptions";
import CashCardOptions from "./channelFormOptions/cashCardOptions";
import dataNETS from "./channelFormOptions/nets.json";
import dataCashCard from "./channelFormOptions/cashCard.json";
import SelectComponent from "../../../components/ui/textfield/selectComponent";
import BankTransferOptions from "./channelFormOptions/bankTransferOptions";

function PaymentChannelDetail() {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    clearErrors,
    control,
    formState: { errors },
  } = useForm();
  const paramsId =
    window.location.pathname.split("/")[
      window.location.pathname.split("/").length - 3
    ];
  const paramsIdChannel =
    window.location.pathname.split("/")[
      window.location.pathname.split("/").length - 1
    ];
  const [freeTextStatus, setFreeTextStatus] = useState(false);
  const [allDataTemplate, setAllDataTemplate] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [statusButton, setStatusButton] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [data, setData] = useState(null);
  const [dataAll, setDataAll] = useState([]);
  const [dataChannel, setDataChannel] = useState(null);
  const [dataAllEditSelections, setDataAllEditSelections] = useState([]);
  const navigate = useNavigate();

  const fetchAllBranch = async (id) => {
    let dataAPI = await getAllBranch();
    dataAPI = dataAPI.filter((item) => item.branch_lang !== null);
    await fetchSettingSelectTemplate(dataAPI, id);
  };

  const fetchSettingSelectTemplate = async (dataBranch) => {
    let dataAPI = await getAllPaymentChannelTemplate(paramsIdChannel);
    dataAPI = dataAPI[0].template_payment_channels;
    let dataBox = [];
    dataBranch.forEach((item) => {
      let dataNews = dataAPI.filter(
        (itemTemplate) => itemTemplate.template_id === item.template_id
      );
      if (dataNews.length > 0) {
        dataBox.push({
          branch: item,
          data: dataNews[0],
          select: false,
        });
      } else {
        dataBox.push({
          branch: item,
          data: null,
          select: false,
        });
      }
    });
    await setAllDataTemplate(dataBox);
  };
  const onConfirmForm = async (register) => {
    let { selectDelivery } = register;
    let dataSelect = [];
    if (data.type === "delivery") {
      dataSelect = dataDelivery;
    } else if (data.type === "e_wallet") {
      dataSelect = eWallet;
    } else if (data.type === "card") {
      dataSelect = dataCard;
    } else if (data.type === "nets") {
      dataSelect = dataNETS;
    } else if (data.type === "cash_card") {
      dataSelect = dataCashCard;
    } else {
      dataSelect = [];
    }
    let dataFindName = "";
    if (data.type !== "bank_transfer") {
      dataFindName = dataSelect.find((item) => item.id === selectDelivery).name;
      dataFindName = {
        name: dataFindName,
        alt_name: dataFindName,
      };
    } else {
      dataFindName = {
        name: register.bankTransferName,
        alt_name: register.bankTransferNameAlt,
      };
    }
    setIsSubmitting(true);
    let dataAPI = {
      id: dataAll.id,
      payment_type_id: dataAll.payment_type_id,
      status: statusButton,
      freetext_status: freeTextStatus,
      payment_channel_lang: {
        name:
          selectDelivery === 99
            ? dataAll.payment_channel_lang.name
            : dataFindName.name,
        alt_name:
          selectDelivery === 99
            ? dataAll.payment_channel_lang.alt_name
            : dataFindName.alt_name,
        language_code: "ALL",
      },
    };
    let statusAPI = await updatePaymentChannel(dataAPI);
    if (statusAPI.message === "OK") {
      notifySuccess({
        title: t("toast_successfully_added_information"),
      });
      navigate("/payment-type/sub/detail/" + dataAll.payment_type_id);
    }
    await new Promise((resolve) => setTimeout(resolve, 2000));
  };

  const onAddPaymentChannelPOSToTemplate = async () => {
    let branchArray = allDataTemplate.filter((item) => item.select === true);
    let dataSelect = [];
    branchArray.forEach((item) => {
      dataSelect.push(item.branch.id);
    });
    let dataAPI = {
      payment_type_id: dataAll.payment_type_id,
      payment_channel_id: dataAll.id,
      branches: dataSelect,
      priority: 1,
    };
    let dataStatus = await addPaymentChannelToTemplate(dataAPI);
    if (dataStatus === "OK") {
      notifySuccess({
        title: t("notification_successfully_edited_information"),
      });
      navigate("/payment-type/sub/detail/" + dataAll.payment_type_id);
    }
  };
  const fetchData = async () => {
    let dataAPI = await getPaymentType(paramsId);
    let dataAPIChannel = await getAllPaymentChannel(paramsId);
    let dataChannel = await getPaymentChannel(paramsIdChannel);
    if (dataChannel.status) {
      await fetchAllBranch();
    }

    dataAPIChannel = dataAPIChannel.filter(
      (item) => item.payment_type_id === paramsId
    );

    let dataItemOption = [];
    if (dataAPI.type === "delivery") {
      dataItemOption = dataDelivery;
    } else if (dataAPI.type === "e_wallet") {
      dataItemOption = eWallet;
    } else if (dataAPI.type === "card") {
      dataItemOption = dataCard;
    } else if (dataAPI.type === "nets") {
      dataItemOption = dataNETS;
    } else if (dataAPI.type === "cash_card") {
      dataItemOption = dataCashCard;
    } else {
      dataItemOption = [];
    }
    if (dataAPI.type === "bank_transfer") {
      setValue("bankTransferName", dataChannel.payment_channel_lang.name);
      setValue(
        "bankTransferNameAlt",
        dataChannel.payment_channel_lang.alt_name
      );
    }
    const compareArraySelectNew = dataItemOption.filter(
      (item) =>
        !dataAPIChannel.some(
          (fItem) => fItem.payment_channel_lang.name === item.name
        )
    );

    let dataFind = dataItemOption.find(
      (item) => item.name === dataChannel.payment_channel_lang.name
    );
    if (dataFind) {
      compareArraySelectNew.push({
        id: dataFind.id,
        name: dataFind.name,
        url: dataFind.url,
      });
      setValue("selectDelivery", dataFind.id);
    } else {
      setValue("selectDelivery", 99);
    }
    setFreeTextStatus(dataAPIChannel.freetext_status);
    setDataAll(dataChannel);
    setData(dataAPI);
    setDataChannel(dataAPIChannel);
    setDataAllEditSelections(compareArraySelectNew);
    setStatusButton(dataChannel.status);
    setIsLoading(false);
  };
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Box>
      <BreadcrumbsTitle
        props={{
          title: t("payment_channel_add"),
          dataLink: [
            { text: t("payment_type"), link: "/payment-type" },
            {
              text: t("payment_type_channel"),
              link: "/payment-type/sub/detail/" + paramsId,
            },
            { text: t("payment_channel_add"), link: null },
          ],
        }}
      />

      {isLoading ? (
        <Loading />
      ) : (
        <>
          <GridContainerSize>
            <Box p={2}>
              <form onSubmit={handleSubmit(onConfirmForm)}>
                <Box className="boxWrapper-container">
                  <PaperWrapper props={{ growFull: 1 }}>
                    <TitleForm props={{ text: t("payment_channel_info") }} />
                    <BoxGapInput>
                      {data.type === "delivery" && (
                        <DeliveryOptions
                          props={{
                            register,
                            watch,
                            setValue,
                            errors,
                            clearErrors,
                            control,
                            setFreeTextStatus,
                            dataChannel,
                            dataAllEditSelections,
                          }}
                        />
                      )}
                      {data.type === "e_wallet" && (
                        <EWalletOptions
                          props={{
                            register,
                            watch,
                            setValue,
                            errors,
                            clearErrors,
                            control,
                            setFreeTextStatus,
                            dataChannel,
                            dataAllEditSelections,
                          }}
                        />
                      )}
                      {data.type === "card" && (
                        <CardOptions
                          props={{
                            register,
                            watch,
                            setValue,
                            errors,
                            clearErrors,
                            control,
                            setFreeTextStatus,
                            dataChannel,
                            dataAllEditSelections,
                          }}
                        />
                      )}
                      {data.type === "nets" && (
                        <NetsOptions
                          props={{
                            register,
                            watch,
                            setValue,
                            errors,
                            clearErrors,
                            control,
                            setFreeTextStatus,
                            dataChannel,
                            dataAllEditSelections,
                          }}
                        />
                      )}
                      {data.type === "cash_card" && (
                        <CashCardOptions
                          props={{
                            register,
                            watch,
                            setValue,
                            errors,
                            clearErrors,
                            control,
                            setFreeTextStatus,
                            dataChannel,
                            dataAllEditSelections,
                          }}
                        />
                      )}
                      {data.type === "cash" && (
                        <SelectComponent
                          watch={watch}
                          title={t("payment_channel")}
                          name="selectDelivery"
                          errors={errors}
                          setValue={setValue}
                          dataEmpty={t("payment_channel_select")}
                          optionsData={[{ id: 99, name: "Cash" }]}
                          onFunctionName={() => {}}
                          disabled={true}
                          clearErrors={clearErrors}
                          control={control}
                        />
                      )}
                      {data.type === "voucher" && (
                        <SelectComponent
                          watch={watch}
                          title={t("payment_channel")}
                          name="selectDelivery"
                          errors={errors}
                          setValue={setValue}
                          dataEmpty={t("payment_channel_select")}
                          optionsData={[{ id: 99, name: "Voucher" }]}
                          onFunctionName={() => {}}
                          disabled={true}
                          clearErrors={clearErrors}
                          control={control}
                        />
                      )}
                      {data.type === "bank_transfer" && (
                        <>
                          <BankTransferOptions
                            props={{
                              register,
                              watch,
                              setValue,
                              errors,
                              clearErrors,
                              control,
                              setFreeTextStatus,
                              dataChannel,
                            }}
                          />
                        </>
                        // <SelectComponent
                        //   watch={watch}
                        //   title={t("payment_channel")}
                        //   name="selectDelivery"
                        //   errors={errors}
                        //   setValue={setValue}
                        //   dataEmpty={t("payment_channel_select")}
                        //   optionsData={[{ id: 99, name: "Bank Transfer" }]}
                        //   onFunctionName={() => {}}
                        //   disabled={true}
                        //   clearErrors={clearErrors}
                        //   control={control}
                        // />
                      )}
                      <Box align="start">
                        <TitleTextfield text={t("global_free_text")} />
                        <FormHelperText>
                          {t("global_payment_channel_text_free_suggestion")}
                        </FormHelperText>
                        <FormControlLabel
                          onChange={(e) => setFreeTextStatus(e.target.checked)}
                          control={<Switch checked={freeTextStatus} />}
                          label={
                            freeTextStatus
                              ? t("global_active")
                              : t("global_in_active")
                          }
                        />
                      </Box>
                    </BoxGapInput>
                  </PaperWrapper>
                  <PaperWrapper props={{ growFull: 1 }}>
                    <SettingTypeBranch
                      title={t("payment_channel_branch")}
                      allDataTemplate={allDataTemplate}
                      setAllDataTemplate={setAllDataTemplate}
                      onAddTypeToTemplate={onAddPaymentChannelPOSToTemplate}
                      directToBranch={"paymentType"}
                    />
                  </PaperWrapper>
                  <StatusForm
                    statusButton={statusButton}
                    setStatusButton={setStatusButton}
                  />
                  <ConfirmButton disabled={isSubmitting} />
                </Box>
              </form>
            </Box>
          </GridContainerSize>
        </>
      )}
    </Box>
  );
}

export default PaymentChannelDetail;
