import { Box } from "@mui/material";
import React from "react";

function Loading() {
  return (
    <Box
      sx={{
        width: "100%",
        boxSizing: "border-box",
        display: "flex",
        justifyContent: "center",
        p: 4,
      }}
    >
      {/* <Box sx={{ position: "relative" }}>
        <Box sx={{ position: "absolute", transform: "translate(-50%,-50%)" }}>
          <div className="circleLoadingOne"></div>
        </Box>
        <Box sx={{ position: "absolute", transform: "translate(-50%,-50%)" }}>
          <div className="circleLoadingTwo"></div>
        </Box>
        <Box sx={{ position: "absolute", transform: "translate(-50%,-50%)" }}>
          <div className="circleLoadingThree"></div>
        </Box>
        <Box sx={{ position: "absolute", transform: "translate(-50%,-50%)" }}>
          <div className="circleLoadingFour"></div>
        </Box>
      </Box> */}
      <div class="loader"></div>
    </Box>
  );
}

export default Loading;
