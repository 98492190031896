import React, { useState, useEffect } from "react";
import { Box, Tab } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Loading from "../../../../components/ui/loading";
import { t } from "i18next";
import SelfPickupSaleProduct from "./selfPickupBranchSaleProduct/selfPickupBranchSaleProduct";
import SelfPickupOpenClosed from "./selfPickupBranchOpenClosed";
import SelfPickupPaymentBank from "./selfPickupBranchPaymentBank";
import BreadcrumbsTitle from "../../../../components/ui/breadcrumbsTitle";
import { getBranch } from "../../../../service/branchAPI";
function SelfPickupBranchDetail() {
  const [isLoading, setLoading] = useState(true);
  const [valueStep, setValueStep] = useState("1");
  const [dataBranch, setDataBranch] = useState(null);
  const paramsId =
    window.location.pathname.split("/")[
      window.location.pathname.split("/").length - 1
    ];

  const fetchData = async () => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
    let dataBranch = await getBranch(paramsId);
    setDataBranch(dataBranch.branch_lang.name);
  };
  const handleChange = (event, newValue) => {
    setValueStep(newValue);
  };
  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <>
      <BreadcrumbsTitle
        props={{
          title: t("branch_title", {
            data: dataBranch ? dataBranch : "",
          }),
          dataLink: [
            { text: t("branch"), link: "/self-pickup-branch" },
            { text: t("branch_info"), link: null },
          ],
        }}
      />
      <Box>
        {isLoading ? (
          <Loading />
        ) : (
          <>
            <Box>
              <TabContext value={valueStep}>
                <Box
                  sx={{
                    borderBottom: 1,
                    borderColor: "divider",
                  }}
                >
                  <TabList
                    onChange={handleChange}
                    aria-label="lab API tabs"
                    variant="scrollable"
                  >
                    {[
                      t("Sale product online"),
                      t("open/closed"),
                      t("payment/bank"),
                      // t("config"),
                    ].map((item, index) => (
                      <Tab label={item} value={String(index + 1)} />
                    ))}
                  </TabList>
                </Box>

                <TabPanel value="1" sx={{ p: 0 }}>
                  <SelfPickupSaleProduct props={{ paramsId }} />
                </TabPanel>
                <TabPanel value="2" sx={{ p: 0 }}>
                  <SelfPickupOpenClosed props={{ paramsId }} />
                </TabPanel>
                <TabPanel value="3" sx={{ p: 0 }}>
                  <SelfPickupPaymentBank props={{ paramsId }} />
                </TabPanel>
                {/* <TabPanel value="4" sx={{ p: 0 }}>
                  <SelfPickupConfig props={{ paramsId }} />
                </TabPanel> */}
              </TabContext>
            </Box>
          </>
        )}
      </Box>
    </>
  );
}

export default SelfPickupBranchDetail;
