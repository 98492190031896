import React, { useState, useEffect, useContext } from "react";
import { Box } from "@mui/material";
import DataMaterialTable from "../../components/ui/dataTable/dataMaterialTable";
import { useTranslation } from "react-i18next";

import { addPosUserToTemplate, getAllPosUser } from "../../service/posUserAPI";
import { ActionDetail } from "../../components/ui/dataTable/components/actionDetail";

import { BoxImageBlank } from "../../components/ui/boxImage/boxImageBlank";
import { MomentConvert } from "../../components/ui/moment/momentConvert";
import BreadcrumbsTitle from "../../components/ui/breadcrumbsTitle";
import StatusTable from "../../components/chip/statusTable";
import { TextTable } from "../../components/ui/textfield/textfieldCustom";
import { notifySuccess } from "../../components/ui/popup/toastPopup";
import { MyContext } from "../../context/MyContext";
import { BoxImageTable } from "../../components/ui/boxImage/boxImageTable";
import dayjs from "dayjs";
function StaffPOS() {
  const [rows, setRows] = useState([]);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [columns, setColumns] = useState([]);
  const { t } = useTranslation();
  const [exportColumnName, setExportColumnName] = useState(null);
  const { setProgress, staffAll, staffAllTable, fetchDataStaffImageTable } =
    useContext(MyContext);

  const fetchData = async () => {
    let dataColumns = [
      {
        accessorKey: "image",
        size: 100,
        enableSorting: false,
        header: t("global_image"),
        Cell: ({ row, cell }) => (
          <>
            {row.original.image ? (
              <BoxImageTable
                image={row.original.image}
                width={"50px"}
                height={"50px"}
                type="table"
                borderRadius={true}
              />
            ) : (
              <BoxImageBlank width={50} height={50} />
            )}
          </>
        ),
      },
      {
        accessorKey: "firstName",
        size: 100,
        header: t("global_first_name"),
        Cell: ({ cell }) => <TextTable props={{ text: cell.getValue() }} />,
      },
      {
        accessorKey: "lastName",
        size: 100,
        header: t("global_last_name"),
        Cell: ({ cell }) => <TextTable props={{ text: cell.getValue() }} />,
      },
      {
        accessorKey: "email",
        header: t("global_email"),
        Cell: ({ cell }) => <TextTable props={{ text: cell.getValue() }} />,
        size: 100,
      },
      {
        accessorKey: "tel",
        size: 100,
        header: t("global_tel"),
        Cell: ({ cell }) => <TextTable props={{ text: cell.getValue() }} />,
      },
      {
        accessorKey: "position",
        header: t("staff_pos_position"),
        Cell: ({ cell }) => <TextTable props={{ text: cell.getValue() }} />,
        size: 100,
      },
      {
        accessorKey: "update",
        header: t("global_updated_at"),
        size: 100,
        Cell: ({ cell }) => <TextTable props={{ text: cell.getValue() }} />,
      },

      {
        accessorKey: "status",
        enableSorting: false,
        header: t("global_status"),
        size: 100,
        Cell: ({ row }) => (
          <StatusTable props={{ status: row.original.status }} />
        ),
      },
      {
        accessorKey: "action",
        enableSorting: false,
        header: "Action",
        size: 100,
        sortable: false,
        Cell: ({ row }) => (
          <Box sx={{ display: "flex", gap: 2 }}>
            <ActionDetail params={row} detailPath={"/posUser/detail/"} />
          </Box>
        ),
      },
    ];

    let dataAPI = [];
    let dataBox = [];
    if (staffAll) {
      dataAPI = staffAll;
    } else {
      dataAPI = await getAllPosUser();
    }
    if (dataAPI) {
      if (staffAllTable.length === 0) {
        dataAPI
          .sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at))
          .map(async (item) => {
            dataBox.push({
              id: item.id,
              image: item.profile_img,
              firstName: item.pos_user_lang.firstname,
              lastName: item.pos_user_lang.lastname,
              email: item.email,
              tel: item.tel,
              position: item.position,
              update: MomentConvert(item.updated_at),
              status: item.status,
            });
          });
        setRows(dataBox);
        fetchDataStaffImageTable(dataBox, setRows);
      } else {
        dataBox = staffAllTable;
        setRows(dataBox);
      }
    }
    //===== Start set header name export csv
    let headers = [];
    dataColumns.forEach((item) => {
      if (item.header !== "Image" && item.header !== "Action") {
        headers.push(item.header);
      }
    });
    const csvStringArray = [
      headers,
      ...staffAllTable.map((item) => [
        item.image,
        item.firstName,
        item.lastName,
        item.email,
        item.tel,
        item.position,
        item.update,
        item.status,
      ]),
    ]
      .map((row) => row.join(","))
      .join("\n");

    setExportColumnName(csvStringArray);
    //===== End set header name export csv
    setColumns(dataColumns);
    setIsLoadingData(false);
  };
  const onInsertToBranches = async (rowsData, branchSelect) => {
    let countRound = 0;
    let timeBoost = 0;
    const promises = rowsData.map(async (row, index) => {
      await new Promise((resolve) => {
        let startTime = performance.now();
        setTimeout(async () => {
          let dataAPI = {
            pos_user_id: String(row.original.id),
            branches: branchSelect,
          };
          let dataStatus = await addPosUserToTemplate(dataAPI);
          if (dataStatus === "OK") {
            countRound++;
            setProgress((prevProgress) =>
              prevProgress >= 100 ? 0 : prevProgress + 100 / rowsData.length
            );
          } else {
            countRound++;
            setProgress((prevProgress) =>
              prevProgress >= 100 ? 0 : prevProgress + 100 / rowsData.length
            );
          }
          if (countRound === rowsData.length) {
            setProgress(0);
            notifySuccess({
              title: t("notification_successfully_insert_information"),
            });
            resolve(true);
          } else {
            resolve(false);
          }
          if (index === 0) {
            let endTime = performance.now();
            let processTime = endTime - startTime;
            timeBoost = processTime;
          }
        }, (timeBoost ? timeBoost + 200 : 1800) * index);
      });
    });
    await Promise.all(promises);
  };

  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box>
      <BreadcrumbsTitle
        props={{
          title: t("staff_pos"),
        }}
      />
      <DataMaterialTable
        statusViewMode={true}
        rows={rows}
        onInsertToBranch={onInsertToBranches}
        exportColumnName={exportColumnName}
        fileName={t("staff_pos") + ` ${dayjs().format("DD/MM/YYYY")}`}
        enableSelection={true}
        isLoadingData={isLoadingData}
        columns={columns}
        detailPath={"/posUser/detail/"}
        createButton={t("staff_pos")}
      />
    </Box>
  );
}

export default StaffPOS;
