import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { notifySuccess } from "../../../components/ui/popup/toastPopup";
import { getMoneyOut, updateMoneyOut } from "../../../service/moneyOutAPI";
import { useTranslation } from "react-i18next";
import BreadcrumbsTitle from "../../../components/ui/breadcrumbsTitle";
import { useSchema } from "../../../components/hooks/use-schema";
import { moneyOutSchema } from "../../../components/validation/moneyOutSchema";
import MoneyOutForm from "./moneyOutForm";
import { GridContainerSize } from "../../../components/utils/gridContainerSize";

function MoneyOutDetail() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm(useSchema(moneyOutSchema));

  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [statusButton, setStatusButton] = useState(true);
  const [dataAll, setDataAll] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const paramsId =
    window.location.pathname.split("/")[
      window.location.pathname.split("/").length - 1
    ];
  const onConfirmForm = async (register) => {
    setIsSubmitting(true);
    let dataAPI = {
      id: dataAll.id,
      status: statusButton,
      money_out_lang: {
        name: register.moneyOut,
        language_code: String(localStorage.getItem("language_form")),
      },
    };
    let statusAPI = await updateMoneyOut(dataAPI);
    if (statusAPI === "OK") {
      notifySuccess({
        title: t("notification_successfully_edited_information"),
      });
      navigate("/money-out");
    }
    await new Promise((resolve) => setTimeout(resolve, 2000));
    setIsSubmitting(false);
  };
  const fetchData = async () => {
    let dataAPI = await getMoneyOut(paramsId);
    let { money_out_lang, status } = dataAPI;
    setDataAll(dataAPI);
    setStatusButton(status);
    setValue("moneyOut", money_out_lang.name);
    setIsLoading(false);
  };
  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <Box>
      <BreadcrumbsTitle
        props={{
          title: t("money_out_info"),
          dataLink: [
            {
              text: t("money_out"),
              link: "/money-out",
            },
            {
              text: t("money_out_info"),
              link: null,
            },
          ],
        }}
      />
      {isLoading ? null : (
        <GridContainerSize>
          <Box p={2}>
            <MoneyOutForm
              props={{
                handleSubmit,
                onConfirmForm,
                register,
                errors,
                statusButton,
                setStatusButton,
                isSubmitting,
              }}
            />
          </Box>
        </GridContainerSize>
      )}
    </Box>
  );
}

export default MoneyOutDetail;
