import React, { useState, useEffect } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { MomentConvert } from "../../../components/ui/moment/momentConvert";
import DataMaterialTable from "../../../components/ui/dataTable/dataMaterialTable";
import { useTranslation } from "react-i18next";
import { ActionBtn } from "../../../components/ui/dataTable/components/actionBtn";

import { BoxImageBlank } from "../../../components/ui/boxImage/boxImageBlank";
import BreadcrumbsTitle from "../../../components/ui/breadcrumbsTitle";
import { BoxImage } from "../../../components/ui/boxImage/boxImage";
import { getAllSaleProduct } from "../../../service/selfPickUp/saleProduct/getAllSaleProduct";
import { getSaleProductAll } from "../../../service/selfPickUp/saleProduct/getSaleProductAll";
import { SelfPickupSaleProductDialog } from "./selfPickupSaleProductDialog";
import { updateSaleProductAll } from "../../../service/selfPickUp/saleProduct/updateSaleProductAll";
import { notifySuccess } from "../../../components/ui/popup/toastPopup";
import EditIcon from "@mui/icons-material/Edit";
import SelfPickupSaleProductUpdate from "./selfPickupSaleProductUpdate";
function SelfPickupSaleProduct() {
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [saleProductAll, setSaleProductAll] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [openDialogUpdate, setOpenDialogUpdate] = useState(false);
  const [dataUpdate, setDataUpdate] = useState(null);
  const { t } = useTranslation();

  const onAddSaleProduct = async () => {
    setDialogOpen(true);
  };
  const onConfirmAddSaleProduct = async () => {
    let dataAPI = [];
    saleProductAll.forEach((item) => {
      if (item.select) {
        dataAPI.push({
          sale_product_id: item.id,
          limit: item.limit,
          amount: item.amount,
          status: item.status,
        });
      }
    });
    let statusAPI = await updateSaleProductAll({ sale_products: dataAPI });
    if (statusAPI === "OK") {
      notifySuccess({
        title: t("notification_successfully_edited_information"),
      });
      setDialogOpen(false);
      fetchData();
    }
  };
  const onOpenDialogUpdate = async (item) => {
    setOpenDialogUpdate(true);
    setDataUpdate(item.original);
  };
  const onCloseOpenDialogUpdate = async (item) => {
    setOpenDialogUpdate(false);
  };
  const onUpdateData = async () => {
    setOpenDialogUpdate(false);
    let dataAPI = {
      sale_products: [
        {
          sale_product_id: dataUpdate.id,
          limit: dataUpdate.limit,
          amount: dataUpdate.amount,
          status: dataUpdate.status,
        },
      ],
    };
    let statusAPI = await updateSaleProductAll(dataAPI);
    if (statusAPI === "OK") {
      notifySuccess({
        title: t("notification_successfully_edited_information"),
      });
      setDialogOpen(false);
      fetchData();
    }
  };
  const fetchData = async () => {
    setIsLoadingData(true);

    let dataColumns = [
      {
        accessorKey: "action",
        enableSorting: false,
        size: 10,
        header: "Action",
        sortable: false,
        Cell: ({ row }) => (
          <Box sx={{ display: "flex", gap: 2 }}>
            <IconButton
              onClick={() => onOpenDialogUpdate(row)}
              sx={{ boxShadow: 1 }}
            >
              <EditIcon />
            </IconButton>
          </Box>
        ),
      },
      {
        accessorKey: "image",
        size: 50,
        enableSorting: false,
        header: t("global_image"),
        Cell: ({ row }) => (
          <Box sx={{ display: "flex", gap: 1 }}>
            <Box>
              {row.original.data.image ? (
                <BoxImage
                  image={row.original.data.image}
                  width={"50px"}
                  height={"50px"}
                  type="table"
                />
              ) : (
                <BoxImageBlank width={50} height={50} />
              )}
            </Box>
          </Box>
        ),
      },
      {
        accessorKey: "amount",
        size: 50,
        header: t("global_amount"),
      },
      {
        accessorKey: "limit",
        size: 50,
        header: t("global_limit"),
        Cell: ({ row }) => (
          <ActionBtn row={row} status={row.original.limit} disabled={true} />
        ),
      },
      {
        accessorKey: "updated_at",
        size: 100,
        header: t("global_updated_at"),
        Cell: ({ cell }) => (
          <Typography sx={{fontSize:"14px"}}>{MomentConvert(cell.getValue())}</Typography>
        ),
      },
      {
        accessorKey: "status",
        enableSorting: false,
        size: 50,
        header: t("global_status"),
        Cell: ({ row }) => (
          <ActionBtn row={row} status={row.original.status} disabled={true} />
        ),
      },
    ];
    let dataAPI = await getSaleProductAll();
    let dataSaleProductAll = await getAllSaleProduct();
    if (dataAPI) {
      dataAPI.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));
      dataAPI.forEach((product) => {
        let dataNews = dataSaleProductAll.find(
          (item) => item.id === product.sale_product_id
        );
        if (dataNews) {
          product.data = dataNews;
        }
      });
      setRows(dataAPI);
    }
    let dataNewBox = [];
    if (dataAPI.length > 0) {
      dataSaleProductAll.forEach((item) => {
        if (dataAPI.find((product) => product.id !== item.id)) {
          dataNewBox.push(item);
        }
      });
    } else {
      dataNewBox = dataSaleProductAll;
    }
    setColumns(dataColumns);
    dataNewBox.forEach((item) => {
      item.select = false;
      item.limit = true;
      item.amount = 0;
    });
    setSaleProductAll(dataNewBox);
    setIsLoadingData(false);
  };
  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <Box>
      <BreadcrumbsTitle
        props={{
          title: t("self_pickup_setup_sale_product"),
          suggestText: "",
        }}
      />

      <DataMaterialTable statusViewMode={true}
        rows={rows}
        isLoadingData={isLoadingData}
        columns={columns}
        createButton={t("self_pickup_setup_add_sale_product")}
        onCreateFunction={() => onAddSaleProduct()}
      />
      <SelfPickupSaleProductDialog
        props={{
          saleProductAll,
          setSaleProductAll,
          dialogOpen,
          setDialogOpen,
          onConfirmAddSaleProduct,
        }}
      />
      {openDialogUpdate ? (
        <SelfPickupSaleProductUpdate
          props={{
            openDialogUpdate,
            onCloseOpenDialogUpdate,
            onUpdateData,
            dataUpdate,
            setDataUpdate,
          }}
        />
      ) : null}
    </Box>
  );
}

export default SelfPickupSaleProduct;
