import React from "react";
import { Avatar, Box, Grid, Typography } from "@mui/material";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import "chartjs-plugin-datalabels";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { FormatNumber } from "../../formatNumber";
import WeeklyPerformanceIcon from "../../../../assets/svg/dashboard/weeklyPerformanceIcon.svg";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
function WeeklyPerformance({ props }) {
  let { dataWeekly, nameValue, symbol } = props;
  let colorBg = [
    "#115f9a",
    "#1984c5",
    "#22a7f0",
    "#48b5c4",
    "#76c68f",
    "#a6d75b",
    "#c9e52f",
    "#d0ee11",
    "#d0f400",
  ];
  let datasetsList = dataWeekly.N03_2.data.data.map((item) =>
    Number(item[nameValue])
  );
  const datasets = [
    {
      label: "Total Sales",
      data: datasetsList,
      backgroundColor: colorBg[4],
    },
  ];
  const options = {
    responsive: true,
    maintainAspectRatio: false,

    scales: {
      x: {
        title: {
          display: true,
          text: "Day of week",
        },
      },
      y: {
        title: { display: true, text: "Total Sales" },
        ticks: {
          display: window.innerWidth < 600 ? false : true,
          callback: (value) => {
            // Customize the text labels as needed
            return `${symbol ? "" : ""} ${FormatNumber(value)}`;
          },
        },
      },
    },
    plugins: {
      legend: {
        display: true,
      },
      title: {
        display: true,
        text: "Weekly: Total Sale",
        position: "top",
      },
      datalabels: {
        display: false,
        formatter: (value) => (value ? `${value.toFixed(2)}%` : null), // Format percentage to two decimal places
      },
    },
  };

  const labels = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ].map((item) => item.substring(0, 3));

  const data = {
    labels,
    datasets: datasets,
  };
  const onCalculateWeekly = (dataCome) => {
    // if (
    //   dataCome.total_sale_weekday === 0 ||
    //   dataCome.total_sale_weekend === 0
    // ) {
    //   return "No data to compare";
    // } else if (dataCome.total_sale_weekday > dataCome.total_sale_weekend) {
    return (
      <Box sx={{ display: "flex", gap: 0.5 }}>
        <Typography
          sx={(theme) => ({
            color: theme.palette.purple.main,
          })}
        >
          Weekday
        </Typography>
        <Typography
          sx={(theme) => ({
            color: theme.palette.success.main,
          })}
        >
          Total Sale
        </Typography>
        <Typography>are</Typography>
        <Typography
          sx={(theme) => ({
            color: theme.palette.success.main,
            fontWeight: "bold",
          })}
        >
          {dataCome.total_sale_weekday} higher
        </Typography>
        <Typography>than</Typography>
        <Typography
          sx={(theme) => ({
            color: theme.palette.info.main,
          })}
        >
          weekday
        </Typography>
        <Typography>on average.</Typography>
      </Box>
    );
    // } else if (dataCome.total_sale_weekday < dataCome.total_sale_weekend) {
    //   return `Weekend Total Sale are ${dataCome.total_sale_weekend}% higher than weekend on average.`;
    // } else {
    //   return `Weekend Total Sale ard same as weekend on average.`;
    // }
  };
  return (
    <>
      <Box sx={{ mb: 2, display: "flex", gap: 1, alignItems: "center" }}>
        <Avatar
          src={WeeklyPerformanceIcon}
          sx={{ borderRadius: 0, width: 24, height: 24 }}
        />
        <Typography
          variant="h3"
          sx={{
            fontWeight: "500",
          }}
        >
          Weekly Performance :
        </Typography>
        <Typography
          variant="h3"
          sx={{
            fontWeight: "500",
            color: (theme) => theme.palette.success.main,
          }}
        >
          Total Sale
        </Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Box
            className="grid-paper-white"
            sx={{
              p: 2,
              boxSizing: "border-box",
              height: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Typography sx={(theme) => ({ fontSize: 16 })}>
                Average. Daily
              </Typography>
              <Typography
                variant="h4"
                sx={(theme) => ({
                  color: theme.palette.secondaryText.main,
                  fontWeight: "bold",
                })}
              >
                {Number(
                  dataWeekly.N03_1.data.data[nameValue].avg_daily
                ).toLocaleString()}
              </Typography>
              <Typography
                sx={(theme) => ({
                  color:
                    Number(
                      dataWeekly.N03_1.data.data[
                        nameValue
                      ].pct_chg_avg_daily.replace("%", "")
                    ) === 0
                      ? theme.palette.warning.main
                      : Number(
                          dataWeekly.N03_1.data.data[
                            nameValue
                          ].pct_chg_avg_daily.replace("%", "")
                        ) > 0
                      ? theme.palette.success.main
                      : theme.palette.error.main,
                })}
              >
                {dataWeekly.N03_1.data.data[
                  nameValue
                ].pct_chg_avg_daily.replace("%", "")}
                %
              </Typography>
            </Box>
            <Box>
              <Typography sx={(theme) => ({ fontSize: 16 })}>
                Average. Weekday
              </Typography>
              <Typography
                variant="h4"
                sx={(theme) => ({
                  color: theme.palette.secondaryText.main,
                  fontWeight: "bold",
                })}
              >
                {Number(
                  dataWeekly.N03_1.data.data[nameValue].avg_weekday
                ).toLocaleString()}
              </Typography>
              <Typography
                sx={(theme) => ({
                  color:
                    Number(
                      dataWeekly.N03_1.data.data[
                        nameValue
                      ].pct_chg_avg_daily.replace("%", "")
                    ) === 0
                      ? theme.palette.warning.main
                      : Number(
                          dataWeekly.N03_1.data.data[
                            nameValue
                          ].pct_chg_avg_daily.replace("%", "")
                        ) > 0
                      ? theme.palette.success.main
                      : theme.palette.error.main,
                })}
              >
                {dataWeekly.N03_1.data.data[
                  nameValue
                ].pct_chg_avg_weekday.replace("%", "")}
                %
              </Typography>
            </Box>
            <Box>
              <Typography sx={(theme) => ({ fontSize: 16 })}>
                Average. Weekend
              </Typography>
              <Typography
                variant="h4"
                sx={(theme) => ({
                  color: theme.palette.secondaryText.main,
                  fontWeight: "bold",
                })}
              >
                {Number(
                  dataWeekly.N03_1.data.data[nameValue].avg_weekend
                ).toLocaleString()}
              </Typography>
              <Typography
                sx={(theme) => ({
                  color:
                    Number(
                      dataWeekly.N03_1.data.data[
                        nameValue
                      ].pct_chg_avg_weekend.replace("%", "")
                    ) === 0
                      ? theme.palette.warning.main
                      : Number(
                          dataWeekly.N03_1.data.data[
                            nameValue
                          ].pct_chg_avg_weekend.replace("%", "")
                        ) > 0
                      ? theme.palette.success.main
                      : theme.palette.error.main,
                })}
              >
                {dataWeekly.N03_1.data.data[
                  nameValue
                ].pct_chg_avg_weekend.replace("%", "")}
                %
              </Typography>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} md={12}>
          <Box
            className="grid-paper-white"
            sx={{ p: 2, boxSizing: "border-box", height: "100%" }}
          >
            <div style={{ height: "240px" }}>
              <Bar options={options} plugins={[ChartDataLabels]} data={data} />
            </div>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              gap: 1,
              alignItems: "center",
            }}
          >
            <Box sx={{ width: "20px" }}>
              <svg
                id="Layer_1"
                enable-background="new 0 0 468 468"
                viewBox="0 0 468 468"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="XMLID_21_">
                  <g id="XMLID_64_">
                    <path
                      d="m377 452h-286c-7.732 0-14-6.268-14-14v-373.681c0-7.732 6.268-14 14-14h286c7.732 0 14 6.268 14 14v373.681c0 7.732-6.268 14-14 14z"
                      fill="#fff"
                    />
                  </g>
                  <g id="XMLID_319_">
                    <path
                      d="m391 392c-139.728 0-253-113.272-253-253v-88.681h-47c-7.732 0-14 6.268-14 14v373.681c0 7.732 6.268 14 14 14h286c7.732 0 14-6.268 14-14z"
                      fill="#e6e7e8"
                    />
                  </g>
                  <g id="XMLID_129_">
                    <g id="XMLID_75_">
                      <g id="XMLID_73_">
                        <g id="XMLID_67_">
                          <path
                            id="XMLID_396_"
                            d="m148.752 240.415-32.952-32.702 22.541-22.713 20.494 20.338 39.563-48.013 24.695 20.35-50.723 61.558c-4.62 5.049-16.245 8.642-23.618 1.182z"
                            fill="#0795fe"
                          />
                        </g>
                        <g id="XMLID_70_">
                          <path
                            id="XMLID_393_"
                            d="m266.682 150.117h86.262v32h-86.262z"
                            fill="#3e4d6c"
                          />
                        </g>
                        <g id="XMLID_69_">
                          <path
                            id="XMLID_391_"
                            d="m266.682 220.069h86.262v32h-86.262z"
                            fill="#3e4d6c"
                          />
                        </g>
                      </g>
                    </g>
                    <g id="XMLID_28_">
                      <g id="XMLID_68_">
                        <g id="XMLID_74_">
                          <path
                            id="XMLID_389_"
                            d="m148.258 382.116-32.952-35.749 23.529-21.688 20.512 22.252 39.051-47.392 24.695 20.35-50.723 61.558c-5.37 5.553-16.62 8.178-24.112.669z"
                            fill="#0795fe"
                          />
                        </g>
                        <g id="XMLID_72_">
                          <path
                            id="XMLID_388_"
                            d="m266.682 292.332h86.262v32h-86.262z"
                            fill="#3e4d6c"
                          />
                        </g>
                        <g id="XMLID_71_">
                          <path
                            id="XMLID_387_"
                            d="m266.682 362.284h86.262v32h-86.262z"
                            fill="#3e4d6c"
                          />
                        </g>
                      </g>
                    </g>
                  </g>
                  <g id="XMLID_504_">
                    <path
                      id="XMLID_383_"
                      d="m377 468h-286c-16.542 0-30-13.458-30-30v-373.681c0-16.542 13.458-30 30-30h26.345v32h-24.345v369.681h282v-369.681h-24.345v-32h26.345c16.542 0 30 13.458 30 30v373.681c0 16.542-13.458 30-30 30z"
                      fill="#3e4d6c"
                    />
                  </g>
                  <g id="XMLID_65_">
                    <path
                      d="m306.656 100.436h-145.312c-7.732 0-14-6.268-14-14v-72.436c0-7.732 6.268-14 14-14h145.312c7.732 0 14 6.268 14 14v72.436c0 7.732-6.268 14-14 14z"
                      fill="#0795fe"
                    />
                  </g>
                </g>
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
              </svg>
            </Box>
            <Typography>{onCalculateWeekly(dataWeekly.N03_3)}</Typography>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default WeeklyPerformance;
