import React, { useContext, useState } from "react";
import { MaterialReactTable } from "material-react-table";
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { mkConfig } from "export-to-csv"; //or use your library of choice here
import moment from "moment";
import { Close, Info } from "@mui/icons-material";
import CardMaterialTable from "./cardMaterialTable";
import { useForm } from "react-hook-form";
import { MyContext } from "../../../context/MyContext";
import ConfirmButton from "../button/confirmButton";
import SelectCheckMarkComponent from "../select/selectCheckMarkComponent";

const DataMaterialTable = (props) => {
  let {
    rows,
    columns,
    createButton,
    isLoadingData,
    height,
    onCreateFunction,
    fileName,
    detailPath,
    imageStatus,
    statusViewMode,
    onInsertToBranch,
    enableSelection,
    exportColumnName,
    disabledCreate,
  } = props;

  const { branchAll } = useContext(MyContext);
  const [branchSelectName, setBranchSelectName] = useState([]);
  const [branchSelect, setBranchSelect] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {
    handleSubmit,
    setValue,
    formState: { errors },
    clearErrors,
    control,
    reset,
  } = useForm();
  const navigate = useNavigate();
  const [dialogBranchSelect, setDialogBranchSelect] = useState({
    status: false,
    data: null,
  });
  const { t } = useTranslation();
  const onCreate = () => {
    navigate("create");
  };

  const onDialogBranchSubmit = async () => {
    setDialogBranchSelect({
      status: false,
      data: null,
    });
    reset();
    setBranchSelect([]);
    setBranchSelectName([]);
    setIsSubmitting(false);
    await onInsertToBranch(dialogBranchSelect.data, branchSelect);
  };
  const onCloseDialog = () => {
    setDialogBranchSelect({
      status: false,
      data: null,
    });
    reset();
    setBranchSelect([]);
    setBranchSelectName([]);
  };

  // Add custom header names
  // const customHeaders = [
  //   { key: "uid", label: "Unique ID" }, // Customize this based on your actual column keys and desired header names
  // ];

  // const customHeaders = [];
  // columns.map((item) =>
  //   customHeaders.push({
  //     key: item.accessorKey,
  //     label: item.header,
  //   })
  // );
  // const csvConfig = mkConfig({
  //   fieldSeparator: ",",
  //   decimalSeparator: ".",
  //   useKeysAsHeaders: true,
  // });

  // Replace default headers with custom headers
  // customHeaders.forEach((header) => {
  //   const columnIndex = columns.findIndex(
  //     (col) => col.accessorKey === header.accessorKey
  //   );
  //   if (columnIndex !== -1) {
  //     csvConfig.headers[columnIndex].header = header.header;
  //   }
  // });
  // const handleExportData = () => {
  //   const csv = generateCsv(csvConfig)(rows);
  //   saveAs(
  //     new Blob([csv], { type: "text/csv;charset=utf-8" }),
  //     fileName ? fileName : moment().format("DD/MM/YYYY")
  //   );
  // };
  const handleExportData = (table) => {
    let csvConfig = mkConfig({
      useKeysAsHeaders: true,
      headers: [],
    });
    let customHeaders = [];
    columns.forEach((item) =>
      customHeaders.push({
        key: item.accessorKey,
        label: item.header,
      })
    );
    customHeaders.forEach((header) => {
      const columnIndex = columns.findIndex(
        (col) => col.accessorKey === header.key
      );
      if (columnIndex !== -1) {
        csvConfig.headers[columnIndex] = header.label;
      }
    });
    let rowsSelected = table.getPrePaginationRowModel().rows;
    const data = rowsSelected.map((row) => row.original);
    data.forEach((row) => {
      delete row.dataAll;
      delete row.data;
      delete row.time;
    });
    const blob = new Blob([exportColumnName], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = fileName ? fileName : moment().format("DD/MM/YYYY");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  // const onShowTable = (status) => {
  //   localStorage.setItem("showTable", status);
  //   window.location.reload();
  // };
  let tableShow = localStorage.getItem("showTable");
  return (
    <Box
      sx={(theme) => ({
        width: "100%",
        [theme.breakpoints.down("sm")]: {
          p: 0,
        },
        p: 2,
        boxSizing: "border-box",
      })}
    >
      {/* {statusViewMode ? (
        <Box
          sx={(theme) => ({
            display: "flex",
            width: "100%",
            justifyContent: "flex-end",
            [theme.breakpoints.down("sm")]: {
              px: 2,
              pt: 2,
            },
            mb: 2,
            boxSizing: "border-box",
          })}
        >
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "flex-end",
              gap: 1,
            }}
          >
            <IconButton
              sx={(theme) => ({
                border: 1,
                borderColor: "#00000020",
                borderRadius: 1,
                p: 0,
                backgroundColor:
                  tableShow === "table"
                    ? theme.palette.primary.main + "80"
                    : null,
                color: tableShow === "table" ? theme.palette.white.main : null,
              })}
              onClick={() => onShowTable("table")}
            >
              <List />
            </IconButton>

            <IconButton
              sx={(theme) => ({
                border: 1,
                borderColor: "#00000020",
                borderRadius: 1,
                p: 0,
                backgroundColor:
                  tableShow !== "table"
                    ? theme.palette.primary.main + "80"
                    : null,
                color: tableShow !== "table" ? theme.palette.white.main : null,
              })}
              onClick={() => onShowTable("grid")}
            >
              <GridViewIcon />
            </IconButton>
          </Box>
        </Box>
      ) : null} */}
      {tableShow === "table" || statusViewMode === false ? (
        <>
          {onInsertToBranch !== undefined ? (
            <Chip
              sx={{
                mb: 2,
                width: "100%",
                display: "flex",
                justifyContent: "flex-start",
              }}
              icon={<Info />}
              variant="outlined"
              label={t("global_selection_for_insert_items_to_branch")}
              color="info"
            />
          ) : null}
          <MaterialReactTable
            columns={columns}
            data={rows}
            enableRowSelection={enableSelection ? true : false}
            enableStickyHeader
            enableColumnResizing={true}
            state={{ isLoading: isLoadingData }}
            enableColumnActions={false}
            initialState={{
              showGlobalFilter: true,
              pagination: {
                pageSize: 25,
                pageIndex: 0,
              },
            }}
            muiTablePaperProps={{
              elevation: 1,
              sx: {
                // borderRadius: "25px",
                // fontColor: "red",
                // border: "1px dashed #e0e0e0",
              },
            }}
            muiSearchTextFieldProps={{
              variant: "outlined",
              size: "small",
              placeholder: "Search",
              sx: { borderRadius: 1, p: 2 },
              InputLabelProps: {
                shrink: true,
              },
            }}
            enableToolbarInternalActions={false}
            muiTableProps={{
              sx: {
                tableLayout: "fixed",
              },
            }}
            muiTableContainerProps={{
              sx: {
                maxHeight:
                  window.innerHeight * (height ? height : 0.86) -
                  88 -
                  56 -
                  32 +
                  "px",
              },
            }}
            muiTablePaginationProps={{
              rowsPerPageOptions: [25, 50, 100, 500],
              showFirstButton: true,
              showLastButton: true,
            }}
            renderTopToolbarCustomActions={({ table }) => (
              <Box
                sx={{
                  display: "flex",
                  gap: "1rem",
                  p: 2,
                  flexWrap: "wrap",
                }}
              >
                {createButton ? (
                  <>
                    <Button
                      color="primary"
                      onClick={() =>
                        onCreateFunction ? onCreateFunction() : onCreate()
                      }
                      sx={{ borderRadius: 2 }}
                      startIcon={<AddIcon />}
                      variant="contained"
                      disabled={disabledCreate}
                    >
                      {isMobile ? t("add_button") : createButton}
                    </Button>
                  </>
                ) : null}
                {!isMobile ? (
                  <>
                    {handleExportData ? (
                      <Button
                        //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                        onClick={() => handleExportData(table)}
                        startIcon={<FileDownloadIcon />}
                        variant="outlined"
                        sx={{ borderRadius: 2 }}
                      >
                        CSV
                      </Button>
                    ) : null}
                  </>
                ) : (
                  <></>
                )}
                {onInsertToBranch !== undefined ? (
                  <Button
                    onClick={() =>
                      setDialogBranchSelect({
                        status: true,
                        data: table.getSelectedRowModel().rows,
                      })
                    }
                    sx={{ borderRadius: 2 }}
                    variant="outlined"
                    disabled={table.getSelectedRowModel().rows.length === 0}
                  >
                    {t("global_table_insert_to_branch")}
                  </Button>
                ) : null}
              </Box>
            )}
          />
        </>
      ) : (
        <Box
          sx={(theme) => ({
            boxSizing: "border-box",
            [theme.breakpoints.down("sm")]: {
              px: 2,
            },
          })}
        >
          {createButton ? (
            <Box sx={{ position: "fixed", bottom: 20, right: 20, zIndex: 99 }}>
              <IconButton
                onClick={() =>
                  onCreateFunction ? onCreateFunction() : onCreate()
                }
                sx={(theme) => ({
                  boxShadow: 2,
                  px: 2,
                  backgroundColor: theme.palette.secondaryText.main,
                  color: theme.palette.white.main,
                })}
              >
                +
              </IconButton>
            </Box>
          ) : null}

          <CardMaterialTable
            props={{
              rows,
              columns,
              detailPath,
              imageStatus,
              createButton,
            }}
          />
        </Box>
      )}
      <Dialog open={dialogBranchSelect.status} maxWidth="sm" fullWidth>
        <DialogTitle>{t("branch_select")}</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => onCloseDialog()}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
        <DialogContent>
          <form onSubmit={handleSubmit(onDialogBranchSubmit)}>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              <SelectCheckMarkComponent
                dataEmpty={t("branch_select")}
                errors={errors}
                name="branchSelect"
                dataOptions={branchAll}
                clearErrors={clearErrors}
                control={control}
                setValue={setValue}
                valueData={branchSelectName}
                setValueData={setBranchSelectName}
                setValueDataId={setBranchSelect}
              />
              <ConfirmButton disabled={isSubmitting} />
            </Box>
          </form>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default DataMaterialTable;
