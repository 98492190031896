import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { getAllUnitCounter } from "../../../service/unitCounterAPI";
import { postPreDefine } from "../../../service/preDefineAPI";
import { getAllPreDefineCategory } from "../../../service/preDefineCategory";
import { useTranslation } from "react-i18next";
import { notifySuccess } from "../../../components/ui/popup/toastPopup";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import BreadcrumbsTitle from "../../../components/ui/breadcrumbsTitle";
import { useSchema } from "../../../components/hooks/use-schema";
import { productOptionSchema } from "../../../components/validation/productOptionSchema";
import Loading from "../../../components/ui/loading";
import { GridContainerSize } from "../../../components/utils/gridContainerSize";
import ProductOptionsForm from "./productOptionsForm";
function ProductOptionsCreate(props) {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
    clearErrors,
    control,
  } = useForm(useSchema(productOptionSchema));
  const { t } = useTranslation();
  const [statusButton, setStatusButton] = useState(true);
  const [categoryProductList, setCategoryProductList] = useState([]);
  const [unitCounterAll, setUnitCounterAll] = useState([]);
  const [statusPrice, setStatusPrice] = useState(false);
  const [amountCount, setAmountCount] = useState(false);
  const [vatStatus, setVatStatus] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const onVatStatus = (status) => {
    setVatStatus(status);
  };
  const fetchData = async () => {
    await fetchDataCategory();
    await fetchDataUnit();
    setValue("price", "0");
    setIsLoading(false);
  };
  const fetchDataCategory = async () => {
    let dataAPI = await getAllPreDefineCategory();
    dataAPI = dataAPI.filter((item) => item.status === true);
    setCategoryProductList(dataAPI);
  };
  const fetchDataUnit = async () => {
    let dataAPI = await getAllUnitCounter();
    dataAPI = dataAPI.filter((item) => item.status === true);
    setUnitCounterAll(dataAPI);
  };
  const onStatusPrice = (status) => {
    setStatusPrice(status);
    if (status === false) {
      setValue("price", "0");
    }
  };

  const onConfirmForm = async (register) => {
    setIsSubmitting(true);
    let dataAPI = {
      pre_define_category_id: register.productCategory,
      unit_counter_id: register.unit,
      price_status: statusPrice,
      stock_count: amountCount,
      // vat: register.vat ? Number(register.vat) : 0,
      vat_status: vatStatus,
      // vat_type: vatType === true ? "include" : "exclude",
      price: statusPrice ? Number(register.price) : 0,
      status: statusButton,
      pre_define_lang: {
        name: register.productOptionName,
        language_code: "ALL",
      },
    };
    let statusAPI = await postPreDefine(dataAPI);
    if (statusAPI === "OK") {
      notifySuccess({
        title: t("toast_successfully_added_information"),
      });
      navigate("/pre-define-product");
    }
    await new Promise((resolve) => setTimeout(resolve, 2000));
    setIsSubmitting(false);
  };
  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div align="left">
      <BreadcrumbsTitle
        props={{
          title: t("product_option_create"),
          dataLink: [
            {
              text: t("product_option"),
              link: "/pre-define-product",
            },
            { text: t("product_option_create"), link: null },
          ],
        }}
      />
      {isLoading ? (
        <Loading />
      ) : (
        <GridContainerSize>
          <Box p={2}>
            <form onSubmit={handleSubmit(onConfirmForm)}>
              <ProductOptionsForm
                props={{
                  register,
                  errors,
                  watch,
                  setValue,
                  control,
                  clearErrors,
                  categoryProductList,
                  unitCounterAll,
                  vatStatus,
                  onVatStatus,
                  statusPrice,
                  onStatusPrice,
                  amountCount,
                  setAmountCount,
                  statusButton,
                  setStatusButton,
                  isSubmitting,
                }}
              />

              {/* <Box className="boxWrapper-container">
                <PaperWrapper props={{ growFull: 1 }}>
                  <TitleForm props={{ text: t("product_option_info") }} />
                  <BoxGapInput>
                    <SelectComponent
                      title={t("product_option_category")}
                      watch={watch}
                      register={register}
                      setValue={setValue}
                      name="productCategory"
                      errors={errors}
                      dataEmpty={t("product_option_category_select")}
                      optionsData={categoryProductList}
                      disabled={categoryProductList.length === 0}
                      dataAlert={
                        <>
                          {categoryProductList.length === 0 ? (
                            <EmptyDataDirect
                              props={{
                                url: "/pre-define-category",
                                text: t("product_option_category"),
                              }}
                            />
                          ) : null}
                        </>
                      }
                      clearErrors={clearErrors}
                      control={control}
                    />

                    <TextfieldComponent
                      title={t("product_option_name")}
                      name="productOptionName"
                      register={register}
                      placeholder={t("product_option_name_example")}
                      errors={errors}
                    />
                    <SelectComponent
                      title={t("unit")}
                      watch={watch}
                      register={register}
                      setValue={setValue}
                      name="unit"
                      errors={errors}
                      dataEmpty={t("unit_select")}
                      optionsData={unitCounterAll}
                      disabled={unitCounterAll.length === 0}
                      dataAlert={
                        <>
                          {unitCounterAll.length === 0 ? (
                            <EmptyDataDirect
                              props={{ url: "/unit", text: t("unit") }}
                            />
                          ) : null}
                        </>
                      }
                      clearErrors={clearErrors}
                      control={control}
                    />
                  </BoxGapInput>
                </PaperWrapper>
                <PaperWrapper props={{ growFull: 1 }}>
                  <TitleForm
                    props={{ text: t("global_calculate_vat_title") }}
                  />
                  <Box p={2}>
                    <FormControlLabel
                      control={
                        <Radio
                          name="vatStatus"
                          checked={vatStatus}
                          onChange={(e) => onVatStatus(true)}
                        />
                      }
                      label={t("global_calculate_vat")}
                    />
                    <FormControlLabel
                      control={
                        <Radio
                          name="vatStatus"
                          checked={!vatStatus}
                          onChange={(e) => onVatStatus(false)}
                        />
                      }
                      label={t("global_not_calculate_vat")}
                    />
                  </Box>
                </PaperWrapper>
                <PaperWrapper props={{ growFull: 1 }}>
                  <TitleForm props={{ text: t("global_price") }} />
                  <BoxGapInput>
                    <TextfieldComponent
                      name="price"
                      register={register}
                      placeholder={t("global_price_example")}
                      errors={errors}
                      iconEnd={<MoneyIcon />}
                      disabled={statusPrice === false}
                      fullGrid={true}
                    />
                    <Box align="end">
                      <FormControlLabel
                        control={
                          <Switch
                            checked={statusPrice}
                            onChange={(e) => onStatusPrice(!statusPrice)}
                          />
                        }
                        label={t("global_price_status")}
                      />
                    </Box>
                  </BoxGapInput>
                </PaperWrapper>
                <PaperWrapper props={{ growFull: 1 }}>
                  <TitleForm props={{ text: t("product_option_amount") }} />
                  <Box p={2}>
                    <FormControlLabel
                      control={
                        <Radio
                          name="limit"
                          checked={amountCount === true}
                          onChange={(e) => setAmountCount(true)}
                        />
                      }
                      label={t("product_option_limit")}
                    />
                    <FormControlLabel
                      control={
                        <Radio
                          name="unlimited"
                          checked={amountCount === false}
                          onChange={(e) => setAmountCount(false)}
                        />
                      }
                      label={t("product_option_un_limit")}
                    />
                  </Box>
                </PaperWrapper>
                <StatusForm
                  statusButton={statusButton}
                  setStatusButton={setStatusButton}
                />
                <ConfirmButton disabled={isSubmitting} />
              </Box> */}
            </form>
          </Box>
        </GridContainerSize>
      )}
    </div>
  );
}

export default ProductOptionsCreate;
