import React, { useRef, useState } from "react";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  DoughnutController,
} from "chart.js";
import { Doughnut } from "react-chartjs-2";
import {
  Avatar,
  Box,
  FormHelperText,
  Typography,
  useTheme,
} from "@mui/material";
import "chartjs-plugin-datalabels";
// import ChartDataLabels from "chartjs-plugin-datalabels";
import { t } from "i18next";
import { Circle } from "@mui/icons-material";
import colorDashBoard from "../../colorDashboard.json";
import paymentMethodIcon from "../../../../../assets/svg/dashboard/paymentMethodIcon.svg";
import PaymentChannelChart from "./paymentChannelChart";
ChartJS.register(ArcElement, Tooltip, Legend, DoughnutController);

function PaymentMethod({ props }) {
  let { dataPaymentMethod } = props;
  const chartRef = useRef(null);
  const [selectPaymentTypeShow, setSelectPaymentTypeShow] = useState({
    status: false,
    data: null,
  });
  const onSelectPaymentType = (data) => {
    setSelectPaymentTypeShow({
      status: true,
      data: data,
    });
  };
  const onBackSelectPaymentType = () => {
    setSelectPaymentTypeShow({
      status: false,
      data: null,
    });
  };
  const theme = useTheme();
  const dataBox = dataPaymentMethod.data.map(
    (item) => item.payment_total_sales
  );
  const numberReceipt = dataPaymentMethod.data.reduce(
    (accSum, item) => accSum + item.payment_total_receipt_count,
    0
  );
  // const total = dataPaymentMethod.data.reduce(
  //   (accSum, item) =>
  //     accSum +
  //     item.data.reduce(
  //       (acc, itemPayment) => acc + Number(itemPayment.payment_total_sales),
  //       0
  //     ),
  //   0
  // );
  const data = {
    labels: dataPaymentMethod.data.map((item) => item.name),
    // labels: ["Bank Transfer", "Cash", "Credit Card"],
    datasets: [
      {
        data: dataBox,
        backgroundColor: colorDashBoard,
        borderColor: theme.palette.background.default,
      },
    ],
  };
  const colorSet = colorDashBoard;
  const options = {
    width: 100,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
        color: "white",
        formatter: (value) =>
          value ? `${((value / 100) * 100).toFixed(2)}%` : null,
      },
      tooltip: {
        callbacks: {
          title: function (context) {
            return t(context[0].label);
          },

          label: function (context) {
            let dataTotalSale = 0;
            let dataTotalSalePercent = 0;
            dataTotalSale =
              dataPaymentMethod.data[context.dataIndex].payment_total_sales;
            dataTotalSalePercent =
              dataPaymentMethod.data[context.dataIndex].payment_pct_total_sales;
            // dataPaymentMethod.data[context.dataIndex].data.forEach((item) => {
            //   dataTotalSale += item.payment_total_sales;
            //   dataTotalSalePercent += Number(
            //     item.payment_pct_total_sales.replace("%", "")
            //   );
            // });
            return `Total Sale: ${dataTotalSale.toLocaleString()} (${dataTotalSalePercent})%`;
          },
          afterLabel: function (context) {
            let dataReceiptCount = 0;
            let dataReceiptCountPercent = 0;
            dataReceiptCount =
              dataPaymentMethod.data[context.dataIndex]
                .payment_total_receipt_count;
            dataReceiptCountPercent =
              dataPaymentMethod.data[context.dataIndex]
                .payment_pct_total_receipt_count;

            // dataPaymentMethod.data[context.dataIndex].data.forEach((item) => {
            //   dataReceiptCount += item.payment_total_receipt_count;
            //   dataReceiptCountPercent += Number(
            //     item.payment_pct_total_receipt_count.replace("%", "")
            //   );
            // });

            return `Total Receipt: ${dataReceiptCount.toLocaleString()} (${dataReceiptCountPercent})%`;
          },
        },
      },
    },
    animation: {
      duration: 300, // Smooth animation effect
    },
    hover: {
      mode: "nearest",
      animationDuration: 300, // Animate scale effect smoothly
    },
    onHover: (event, elements) => {
      if (!event || !chartRef.current) return; // Ensure event and chartRef are defined

      const canvas = chartRef.current.canvas;

      // Check if we are hovering over any element
      if (elements.length > 0) {
        canvas.style.cursor = "pointer"; // Set cursor to pointer
      } else {
        canvas.style.cursor = "default"; // Reset cursor
      }
    },
    elements: {
      arc: {
        hoverOffset: 20, // Makes the segment pop out (scale effect)
      },
    },
    onClick: (event, elements) => {
      if (elements.length > 0) {
        const index = elements[0].index;
        if (dataPaymentMethod.data[index].payment_type !== "Unknown") {
          onSelectPaymentType(dataPaymentMethod.data[index]);
        }
      }
    },
  };
  const centerTextPlugin = {
    id: "centerText",
    beforeDraw: (chart) => {
      const { width, height } = chart;
      const ctx = chart.ctx;
      ctx.restore();
      const fontSize = (height / 300).toFixed(2);
      ctx.font = `${fontSize}em sans-serif`;
      ctx.textBaseline = "middle";
      ctx.textAlign = "center"; // Align text horizontally to the center

      // Prepare the text and break it into lines
      const line1 = `${t("Total Receipt")}`;
      const line2 = `${Number(numberReceipt).toLocaleString()}`;

      // Calculate vertical position based on the font size
      const line1Y = height / 2 - fontSize * 10;
      const line2Y = height / 2 + 20;

      // Draw first line with a different color
      ctx.fillStyle = "#000"; // Color for the first line
      ctx.fillText(line1, width / 2, line1Y);

      // Draw second line with a different color
      ctx.fillStyle = theme.palette.primary.main; // Color for the second line (black)
      ctx.font = `bold ${fontSize * 1.5}em sans-serif`; // Bold font
      ctx.fillText(line2, width / 2, line2Y);

      ctx.save();
    },
  };
  return (
    <Box>
      {selectPaymentTypeShow.status ? (
        <PaymentChannelChart
          props={{
            dataPaymentType: selectPaymentTypeShow.data,
            onBackSelectPaymentType,
          }}
        />
      ) : (
        <>
          {" "}
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              gap: 2,
              height: 40,
            }}
          >
            <Avatar
              src={paymentMethodIcon}
              sx={{ borderRadius: 0, width: 24, height: 24 }}
            />
            <Box>
              <Typography
                variant="h3"
                sx={{
                  fontWeight: "500",
                  lineHeight: 0.7,
                }}
              >
                Payment Method
              </Typography>
              <FormHelperText>
                Click piece of Doughnut Chart for view payment channel
              </FormHelperText>
            </Box>
          </Box>
          <Box
            sx={(theme) => ({
              position: "relative",
              alignItems: "center",
              [theme.breakpoints.down("sm")]: {
                display: "inline",
              },
              justifyContent: "center",
              width: "100%",
            })}
          >
            <div
              style={{
                height: "300px",
                marginTop: "24px",
                position: "relative",
              }}
            >
              <Doughnut
                ref={chartRef}
                data={data}
                plugins={[centerTextPlugin]}
                options={options}
              />
            </div>

            <Box
              sx={(theme) => ({
                m: 4,
                display: "flex",
                flexDirection: "column",
                gap: 2,
              })}
            >
              {dataPaymentMethod.data.map((item, index) => (
                <>
                  <Box
                    sx={(theme) => ({
                      display: "flex",
                      justifyContent: "space-between",
                    })}
                  >
                    <Box
                      sx={{
                        mt: 0.5,
                        display: "flex",
                        justifyContent: "space-between",
                        gap: 1,
                        alignItems: "center",
                      }}
                    >
                      <Circle sx={{ color: colorSet[index] }} />
                      <Typography sx={(theme) => ({ fontSize: 12 })}>
                        {t(`${item.payment_type}`)}
                      </Typography>
                    </Box>
                    <Typography sx={(theme) => ({ fontSize: 18 })}>
                      {item.payment_pct_total_sales.toLocaleString()}
                    </Typography>
                  </Box>
                </>
              ))}
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
}

export default PaymentMethod;
