import React, { useState, useEffect, useContext } from "react";
import { t } from "i18next";
import { Box, Typography } from "@mui/material";
import { ActionDetail } from "../../../components/ui/dataTable/components/actionDetail";
import { MomentConvert } from "../../../components/ui/moment/momentConvert";
import DataMaterialTable from "../../../components/ui/dataTable/dataMaterialTable";
import BreadcrumbsTitle from "../../../components/ui/breadcrumbsTitle";
import StatusTable from "../../../components/chip/statusTable";
import { TextTable } from "../../../components/ui/textfield/textfieldCustom";
import dayjs from "dayjs";
import { getAllPaymentChannel } from "../../../service/paymentChannelAPI";
// import paymentAll from "../../../components/utils/payment.json";
import Loading from "../../../components/ui/loading";
import { MyContext } from "../../../context/MyContext";
import { BoxFormTitleAction } from "../../../components/ui/box/boxFormTitleAction";
import StatusForm from "../../../components/ui/formComponent/statusForm";
import PaperWrapper from "../../../components/ui/paper/paperWrapper";
import { BoxWrapper } from "../../../components/ui/box/boxWrapper";

function PaymentChannel() {
  const { paymentTypeAll } = useContext(MyContext);
  const [rows, setRows] = useState([]);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState(null);
  const [exportColumnName, setExportColumnName] = useState(null);
  const paramsId =
    window.location.pathname.split("/")[
      window.location.pathname.split("/").length - 1
    ];

  const fetchData = async () => {
    let dataColumns = [
      {
        accessorKey: "name",
        flexGrow: 1,
        header: t("global_name"),
        Cell: ({ cell }) => <TextTable props={{ text: cell.getValue() }} />,
      },
      {
        accessorKey: "update",
        size: 100,
        header: t("global_updated_at"),
        Cell: ({ cell }) => <TextTable props={{ text: cell.getValue() }} />,
      },
      {
        accessorKey: "status",
        enableSorting: false,
        size: 20,
        header: t("global_status"),
        Cell: ({ row }) => (
          <StatusTable props={{ status: row.original.status }} />
        ),
      },
      {
        accessorKey: "action",
        enableSorting: false,
        size: 100,
        header: "Action",
        Cell: ({ row }) => (
          <Box sx={{ display: "flex", gap: 2 }}>
            <ActionDetail
              params={row}
              detailPath={`/payment-type/sub/detail/${paramsId}/payment-channel/`}
            />
          </Box>
        ),
      },
    ];
    let dataAPI = await getAllPaymentChannel(paramsId);
    dataAPI = dataAPI.filter((item) => item.payment_type_id === paramsId);
    setData(dataAPI);
    if (dataAPI) {
      let dataBox = dataAPI
        .sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at))
        .map((item) => ({
          id: item.id,
          name: item.payment_channel_lang.name,
          update: MomentConvert(item.payment_channel_lang.updated_at),
          status: item.status,
        }));
      //===== Start set header name export csv
      let headers = [];
      dataColumns.forEach((item) => {
        if (item.header !== "Image" && item.header !== "Action") {
          headers.push(item.header);
        }
      });
      const csvStringArray = [
        headers,
        ...dataBox.map((item) => [item.name, item.update, item.status]),
      ]
        .map((row) => row.join(","))
        .join("\n");

      setExportColumnName(csvStringArray);
      //===== End set header name export csv
      setRows(dataBox);
    }

    setColumns(dataColumns);
    setIsLoadingData(false);
  };
  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <Box>
      <BreadcrumbsTitle
        props={{
          dataLink: [
            { text: t("payment_type"), link: "/payment-type" },
            {
              text: t("payment_type_channel"),
              link: null,
            },
          ],
        }}
      />
      {isLoadingData ? (
        <Loading />
      ) : (
        <Box sx={{ p: 2, boxSizing: "border-box" }}>
          <BoxWrapper>
            <PaperWrapper props={{ growFull: 1 }}>
              {paramsId ? (
                <BoxFormTitleAction>
                  <Typography
                    variant="h5"
                    sx={(theme) => ({
                      color: theme.palette.primaryText.main,
                      p: 2,
                      boxSizing: "border-box",
                      fontWeight: 600,
                      width: "100%",
                    })}
                  >
                    {paymentTypeAll.length > 0
                      ? paymentTypeAll.find((item) => item.id === paramsId)
                          .payment_type_lang.name
                      : ""}
                  </Typography>
                  <StatusForm
                    statusButton={
                      paymentTypeAll.length > 0
                        ? paymentTypeAll.find((item) => item.id === paramsId)
                            .status
                        : false
                    }
                    disabled={true}
                  />
                </BoxFormTitleAction>
              ) : null}
              <DataMaterialTable
                statusViewMode={true}
                rows={rows}
                exportColumnName={exportColumnName}
                fileName={
                  t("payment_type_channel") + ` ${dayjs().format("DD/MM/YYYY")}`
                }
                isLoadingData={isLoadingData}
                columns={columns}
                detailPath={`payment-type/sub/detail/${paramsId}/payment-channel/`}
                createButton={t("payment_channel_add")}
                disabledCreate={data.find(
                  (item) =>
                    item.payment_channel_lang.name === "Cash" ||
                    item.payment_channel_lang.name === "Voucher"
                )}
              />
            </PaperWrapper>
          </BoxWrapper>
        </Box>
      )}
    </Box>
  );
}

export default PaymentChannel;
