import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { notifySuccess } from "../../components/ui/popup/toastPopup";
import Loading from "../../components/ui/loading";
import { getDevice, updateDevice } from "../../service/deviceAPI";
import { t } from "i18next";
import BreadcrumbsTitle from "../../components/ui/breadcrumbsTitle";
import SettingDeviceForm from "./settingDeviceForm";
import { deviceSchema } from "../../components/validation/deviceSchema";
import { useSchema } from "../../components/hooks/use-schema";
import { BoxWrapper } from "../../components/ui/box/boxWrapper";
import PaperWrapper from "../../components/ui/paper/paperWrapper";
import TitleForm from "../../components/ui/formComponent/titleForm";
import SelectComponent from "../../components/ui/textfield/selectComponent";
import { getAllBranch } from "../../service/branchAPI";
import { GridContainerSize } from "../../components/utils/gridContainerSize";
import { BoxFormTitleAction } from "../../components/ui/box/boxFormTitleAction";
import TitleHeaderForm from "../../components/ui/formComponent/titleHeaderForm";
import { BoxGapInput } from "../../components/ui/box/boxGapInput";

function SettingDeviceDetail() {
  const {
    register,
    handleSubmit,
    clearErrors,
    setValue,
    control,
    watch,
    formState: { errors },
  } = useForm(useSchema(deviceSchema));

  const [isLoading, setIsLoading] = useState(true);
  const [statusButton, setStatusButton] = useState(true);
  const [dataAll, setDataAll] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [branchAll, setBranchAll] = useState([]);
  const navigate = useNavigate();
  const paramsId =
    window.location.pathname.split("/")[
      window.location.pathname.split("/").length - 1
    ];
  const fetchData = async () => {
    let dataAPI = await getDevice(paramsId);
    setDataAll(dataAPI);
    setValue("branch", dataAPI.branch_id);
    setValue("serial_number", dataAPI.serial_number);
    setValue("device_model", dataAPI.device_model);
    setValue("version_security_patch", dataAPI.version_securitypatch);
    setValue("version_sdkInt", dataAPI.version_sdkInt);
    setValue("version_release", dataAPI.version_release);
    setValue("deviceName", dataAPI.device_lang.name);
    setStatusButton(dataAPI.status);
    setIsLoading(false);
  };
  const onConfirmForm = async (register) => {
    setIsSubmitting(true);
    let dataAPI = {
      id: dataAll.id,
      branch_id: register.branch,
      serial_number: register.serial_number,
      device_model: register.device_model,
      version_securitypatch: register.version_security_patch,
      version_sdkInt: register.version_sdkInt,
      version_release: register.version_release,
      status: statusButton,
      device_lang: {
        name: register.deviceName,
        language_code: String(localStorage.getItem("language_form")),
      },
    };
    let statusAPI = await updateDevice(dataAPI);
    if (statusAPI === "OK") {
      notifySuccess({
        title: t("notification_successfully_edited_information"),
      });
      navigate("/setting-device");
    }
    await new Promise((resolve) => setTimeout(resolve, 2000));
    setIsSubmitting(false);
  };
  const fetchDataBranchAll = async () => {
    let dataAPI = await getAllBranch();
    let dataBox = [];
    if (dataAPI.length > 0) {
      dataAPI.forEach((item) => {
        dataBox.push({ id: item.id, name: item.branch_lang.name });
      });
      setBranchAll(dataBox);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
    fetchDataBranchAll();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <Box>
      <BreadcrumbsTitle
        props={{
          title: t("device_pos_info"),
          dataLink: [
            { text: t("device_pos"), link: "/setting-device" },
            { text: t("device_pos_info"), link: null },
          ],
        }}
      />

      {isLoading ? (
        <>
          <Loading />
        </>
      ) : (
        <GridContainerSize>
          <Box sx={{ p: 2, boxSizing: "border-box" }}>
            <Box mb={2}>
              <BoxWrapper>
                <PaperWrapper props={{ growFull: 1 }}>
                  <BoxFormTitleAction>
                    <TitleHeaderForm props={{ text: t("branch") }} />
                  </BoxFormTitleAction>
                  <BoxGapInput>
                    <BoxWrapper>
                      <PaperWrapper props={{ growFull: 1 }}>
                        <TitleForm props={{ text: t("branch_info") }} />
                        <Box p={2}>
                          <SelectComponent
                            title={t("branch")}
                            watch={watch}
                            name="branch"
                            errors={errors}
                            setValue={setValue}
                            dataEmpty={t("branch_select")}
                            optionsData={branchAll}
                            clearErrors={clearErrors}
                            control={control}
                          />
                        </Box>
                      </PaperWrapper>
                    </BoxWrapper>
                  </BoxGapInput>
                </PaperWrapper>
              </BoxWrapper>
            </Box>
            <SettingDeviceForm
              props={{
                handleSubmit,
                onConfirmForm,
                watch,
                register,
                errors,
                statusButton,
                setStatusButton,
                isSubmitting,
                mode: "edit",
              }}
            />
          </Box>
        </GridContainerSize>
      )}
    </Box>
  );
}

export default SettingDeviceDetail;
