import React, { useState } from "react";
import { Box } from "@mui/material";
import TitleForm from "../formComponent/titleForm";
import { useTranslation } from "react-i18next";
import countries from "../../../components/utils/countries.json";
import amphures from "../../../components/utils/thai-address-data/amphures.json";
import tambons from "../../../components/utils/thai-address-data/tambons.json";
import zipCodes from "../../../components/utils/thai-address-data/zipCodes.json";
import provinces from "../../../components/utils/thai-address-data/provinces.json";
import AutocompleteComponent from "../textfield/autoCompleteComponent";
import { BoxWrapper } from "../box/boxWrapper";
import { LanguageForm } from "../../../service/getLocalStorage";
import TextfieldComponent from "../textfield/textfieldComponent";
import { BoxGapInput } from "../box/boxGapInput";

function AddressForm({ props }) {
  const {
    errors,
    address,
    setAddress,
    setValue,
    clearErrors,
    control,
    register,
  } = props;
  const { t } = useTranslation();
  const [dataAddress, setDataAddress] = useState({
    province: provinces,
    amphures: amphures,
    tambons: tambons,
    zip_code: zipCodes,
  });
  const onSelectValue = (name, newValue) => {
    setAddress({ ...address, [name]: newValue });
    let dataFilter = [];
    if (newValue) {
      if (name === "province") {
        let dataProvince = provinces.find((item) => item.name_th === newValue);
        dataProvince = dataProvince ? dataProvince : provinces;
        dataFilter = amphures.filter(
          (item) => item.province_id === dataProvince.id
        );
        setDataAddress({ ...dataAddress, amphures: dataFilter });
        setValue("district", "");
        setValue("subDistrict", "");
        setValue("zipCode", "");
        setAddress({
          district: "",
          subDistrict: "",
          province: newValue,
          zipCode: "",
        });
      } else if (name === "district") {
        let dataDistrict = amphures.find((item) => item.name_th === newValue);
        dataDistrict = dataDistrict ? dataDistrict : amphures;
        dataFilter = tambons.filter(
          (item) => item.amphure_id === dataDistrict.id
        );
        setValue("subDistrict", "");
        setValue("zipCode", "");
        setDataAddress({ ...dataAddress, tambons: dataFilter });
      } else if (name === "subDistrict") {
        let dataSubDistrict = tambons.find((item) => item.name_th === newValue);
        dataSubDistrict = dataSubDistrict ? dataSubDistrict : tambons;
        dataFilter = zipCodes.filter(
          (item) => item.name_th === dataSubDistrict.name_th
        );
        setValue("zipCode", "");
        dataFilter.forEach((item) => (item.zip_code = String(item.zip_code)));
        setDataAddress({ ...dataAddress, zip_code: dataFilter });
      }
    }
  };
  const onCountry = async (name, item) => {
    setValue(name, item);
    setValue("province", "");
    setValue("district", "");
    setValue("subDistrict", "");
    setValue("zipCode", "");
  };
  return (
    <Box>
      <TitleForm props={{ text: t("address") }} />
      <BoxGapInput>
        <AutocompleteComponent
          title={t("country")}
          name="country"
          control={control}
          setValue={setValue}
          errors={errors}
          clearErrors={clearErrors}
          placeholder={t("country_example")}
          optionsData={countries.map((option) => option.name)}
          fullGrid={true}
          onFunctionSelectName={onCountry}
        />
        {LanguageForm === "TH" ? (
          <>
            <BoxWrapper>
              <AutocompleteComponent
                title={t("province")}
                name="province"
                control={control}
                setValue={setValue}
                errors={errors}
                clearErrors={clearErrors}
                placeholder={t("province_example")}
                optionsData={dataAddress.province.map(
                  (option) => option.name_th
                )}
                onFunctionSelectName={onSelectValue}
              />
              <AutocompleteComponent
                title={t("district")}
                name="district"
                control={control}
                setValue={setValue}
                errors={errors}
                clearErrors={clearErrors}
                placeholder={t("district_example")}
                optionsData={dataAddress.amphures.map(
                  (option) => option.name_th
                )}
                onFunctionSelectName={onSelectValue}
                disabled={!address.province}
              />
            </BoxWrapper>
            <BoxWrapper>
              <AutocompleteComponent
                title={t("subdistrict")}
                name="subDistrict"
                control={control}
                setValue={setValue}
                errors={errors}
                clearErrors={clearErrors}
                placeholder={t("subdistrict_example")}
                optionsData={dataAddress.tambons.map(
                  (option) => option.name_th
                )}
                onFunctionSelectName={onSelectValue}
                disabled={!address.district}
              />
              <AutocompleteComponent
                title={t("postal_code")}
                name="zipCode"
                control={control}
                setValue={setValue}
                errors={errors}
                clearErrors={clearErrors}
                placeholder={t("postal_code_example")}
                optionsData={dataAddress.zip_code.map(
                  (option) => option.zip_code
                )}
                onFunctionSelectName={onSelectValue}
                disabled={!address.subDistrict}
              />
            </BoxWrapper>
          </>
        ) : (
          <>
            <BoxWrapper>
              <TextfieldComponent
                title={t("City")}
                name="province"
                register={register}
                placeholder={t("province_example")}
                errors={errors}
                fullGrid={true}
              />
              <TextfieldComponent
                title={t("State")}
                name="district"
                register={register}
                placeholder={t("district_example")}
                errors={errors}
                fullGrid={true}
              />
            </BoxWrapper>
            <BoxWrapper>
              <TextfieldComponent
                title={t("Sub State")}
                name="subDistrict"
                register={register}
                placeholder={t("subdistrict_example")}
                errors={errors}
                fullGrid={true}
              />
              <TextfieldComponent
                title={t("Postal Code")}
                name="zipCode"
                register={register}
                placeholder={t("postal_code_example")}
                errors={errors}
                fullGrid={true}
              />
            </BoxWrapper>
          </>
        )}
      </BoxGapInput>
    </Box>
  );
}

export default AddressForm;
