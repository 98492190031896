import React from "react";
import { BoxWrapper } from "../../components/ui/box/boxWrapper";
import { BoxGapInput } from "../../components/ui/box/boxGapInput";
import { BoxFormTitleAction } from "../../components/ui/box/boxFormTitleAction";
import TextfieldComponent from "../../components/ui/textfield/textfieldComponent";
import PaperWrapper from "../../components/ui/paper/paperWrapper";
import ConfirmButton from "../../components/ui/button/confirmButton";
import StatusForm from "../../components/ui/formComponent/statusForm";
import { t } from "i18next";
import TitleHeaderForm from "../../components/ui/formComponent/titleHeaderForm";

function UnitForm({ props }) {
  const { register, errors, isSubmitting, statusButton, setStatusButton } =
    props;
  return (
    <>
      <BoxWrapper>
        <PaperWrapper props={{ growFull: 1 }}>
          <BoxFormTitleAction>
            <TitleHeaderForm props={{ text: t("unit_info") }} />
            <StatusForm
              statusButton={statusButton}
              setStatusButton={setStatusButton}
            />
          </BoxFormTitleAction>
          <BoxGapInput>
            <TextfieldComponent
              title={t("unit_name")}
              name="unit"
              register={register}
              placeholder={t("unit_example")}
              errors={errors}
              fullGrid={true}
            />
          <ConfirmButton disabled={isSubmitting} />
          </BoxGapInput>
        </PaperWrapper>
      </BoxWrapper>
    </>
  );
}

export default UnitForm;
