import toast from "react-hot-toast";

export const notifySuccess = (props) =>
  toast.success(props.title, {
    duration: 5000,
    style: {
      fontFamily: "Kanit",
      backgroundColor: "#fff",
      display: "flex",
      width: "100%",
      color: "#1B7530",
      lineBreak: "anywhere",
    },
  });

// Example : notifySuccess({ title: "แก้ไขข้อมูลสำเร็จ" });

export const notifyDefault = (props) =>
  toast(props.title, {
    duration: 5000,
    style: {
      fontFamily: "Kanit",
      backgroundColor: "#fff",
      display: "flex",
      width: "100%",
      color: "#000000",
      lineBreak: "anywhere",
    },
  });
// Example : notifyDefault({ title: "ปิดการใช้งาน" });

export const notifyError = (props) =>
  toast.error(props.title, {
    duration: 5000,
    style: {
      fontFamily: "Kanit",
      backgroundColor: "#fff",
      display: "flex",
      width: "100%",
      color: "#dc3545",
      lineBreak: "anywhere",
    },
  });

// Example : notifyError({ title: "เกิดข้อผิดพลาด" });
