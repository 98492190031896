import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import "chartjs-plugin-datalabels";
import ChartDataLabels from "chartjs-plugin-datalabels";
import dayjs from "dayjs";
import { Box } from "@mui/material";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function BarOneDayDashBoard({ props }) {
  const [data, setData] = useState({
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: null,
        borderWidth: 1,
      },
    ],
  });
  let { dataValues, nameValue, symbol, title, isConfirmData } = props;
  let labelsData = [];
  if (dataValues !== undefined) {
    labelsData = dataValues.map((item) => {
      let dateNews = dayjs(item.hour, "H.0").format("HH");
      return dateNews;
    });
  }
  const fetchData = () => {
    const dataBox = dataValues.map((item) => Number(item.data[nameValue]));
    setData({
      labels: labelsData,
      datasets: [
        {
          data: dataBox,
          backgroundColor: "#ffb55a",
          borderWidth: 1,
        },
      ],
    });
  };
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConfirmData]);
  const options = {
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      x: {
        display: true,
        grid: {
          display: false,
        },
        title: {
          display: true, // Enable the axis title
          text: "Hour", // Set the title text
          font: {
            // Optional: Customize title font
            size: 16,
            weight: "bold",
          },
        },
      },
      y: {
        display: true,
        grid: {
          display: true,
        },
        min: nameValue === "avg_price_per_receipt" ? null : 0,
        max: nameValue === "avg_price_per_receipt" ? null : 100,
        ticks: {
          stepSize: nameValue === "avg_price_per_receipt" ? null : 10,
          callback: (value) => {
            return `${value}${symbol ? "%" : ""}`;
          },
        },
        title: {
          display: true, // Enable the axis title
          text: title ? title : `${symbol ? "%" : ""} Total Sales`, // Set the title text
          font: {
            // Optional: Customize title font
            size: 16,
            weight: "bold",
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false,
        position: "bottom",
      },
      title: {
        display: false,
        text: "% Total Sales",
        position: "left",
      },
      datalabels: {
        display: false,
        formatter: (value) => `${value.toFixed(2)}`,
      },

      tooltip: {
        callbacks: {
          title: function (context) {
            return `${dayjs(context[0].label, "HH").format("HH:mm")}`;
          },
          label: function (context) {
            let sumPercent = "";
            if (nameValue === "pct_total_sale") {
              sumPercent = `Total Sales`;
            } else if (nameValue === "pct_total_receipt") {
              sumPercent = `Total Receipt`;
            } else {
              sumPercent = `Average price per receipt`;
            }
            return sumPercent;
          },

          afterLabel: function (context) {
            let text = "";
            let amountText = "";
            text = context.raw;
            if (nameValue === "pct_total_sale") {
              amountText = dataValues[context.dataIndex].data.total_sale;
              return `Percent : ${text}% \nAmount : ${Number(
                amountText
              ).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}`; // Example of additional line
            } else if (nameValue === "pct_total_receipt") {
              amountText = dataValues[context.dataIndex].data.total_receipt;
              return `Percent : ${text}% \nAmount : ${Number(
                amountText
              ).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}`; // Example of additional line
            } else {
              amountText = dataValues[context.dataIndex].data[nameValue];
              return `Amount : ${Number(amountText).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}`; // Example of additional line
            }
          },
        },
      },
    },
  };

  return (
    <Box sx={{ height: "300px", width: "100%" }}>
      <Bar options={options} plugins={[ChartDataLabels]} data={data} />
    </Box>
  );
}

export default BarOneDayDashBoard;
