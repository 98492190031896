import React, { useState, useEffect } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { MomentConvert } from "../../../components/ui/moment/momentConvert";
import DataMaterialTable from "../../../components/ui/dataTable/dataMaterialTable";
import { useTranslation } from "react-i18next";
import { ActionBtn } from "../../../components/ui/dataTable/components/actionBtn";
import { getAllBranch } from "../../../service/branchAPI";
import BreadcrumbsTitle from "../../../components/ui/breadcrumbsTitle";
import { useNavigate } from "react-router-dom";
import SettingsIcon from '@mui/icons-material/Settings';
import { TextTable } from "../../../components/ui/textfield/textfieldCustom";

function SelfPickupBranch() {
  const [rows, setRows] = useState([]);
  const { t } = useTranslation();
  const [columns, setColumns] = useState([]);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const navigate = useNavigate();

  const fetchData = async () => {
    let dataColumns = [
      {
        accessorKey: "action",
        enableSorting: false,
        size: 100,
        header: "Action",
        sortable: false,
        Cell: ({ row }) => (
          <Box sx={{ display: "flex", gap: 2 }}>
            <IconButton
              aria-label="Example"
              onClick={() =>
                navigate("/self-pickup-admin/detail/" + row.original.id)
              }
              sx={{ boxShadow: 5 }}
            >
              <SettingsIcon />
            </IconButton>
          </Box>
        ),
      },
      {
        accessorKey: "branch_lang.name",
        size: 100,
        header: t("global_name"),
        Cell: ({ cell }) => (
          <TextTable props={{text:cell.getValue()}}/>
        ),
      },
      {
        accessorKey: "branch_lang.updated_at",
        size: 100,
        header: t("global_updated_at"),
        Cell: ({ cell }) => (
          <Typography sx={{fontSize:"14px"}}>{MomentConvert(cell.getValue())}</Typography>
        ),
      },
      {
        accessorKey: "status",
        enableSorting: false,
        header: "ONLINE",
        size: 100,
        Cell: ({ row, index }) => (
          <ActionBtn row={row} status={row.original.status} />
        ),
      },
    ];
    let dataAPI = await getAllBranch();
    if (dataAPI) {
      dataAPI.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));
      setRows(dataAPI);
    }
    setColumns(dataColumns);

    setIsLoadingData(false);
  };

  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <Box>
      <BreadcrumbsTitle
        props={{
          title: t("online branch"),
        }}
      />
      <DataMaterialTable statusViewMode={true}
        rows={rows}
        isLoadingData={isLoadingData}
        columns={columns}
        detailPath={"/self-pickup-admin/detail/"}
      />
    </Box>
  );
}

export default SelfPickupBranch;
