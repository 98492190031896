import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { getSelfPickupConfig } from "../../../../service/selfPickUp/config/getSelfPickupConfig";
import SelfPickupConfigForm from "./selfPickupConfigForm";
import { useForm } from "react-hook-form";
import { updateSelfPickupConfig } from "../../../../service/selfPickUp/config/updateSelfPickupConfig";
import verificationData from "../verificationData.json";
import StatusForm from "../../../../components/ui/formComponent/statusForm";
import ConfirmButton from "../../../../components/ui/button/confirmButton";
import { notifySuccess } from "../../../../components/ui/popup/toastPopup";
import { t } from "i18next";
import Loading from "../../../../components/ui/loading";

function SelfPickupConfig() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    control,
    clearErrors,
  } = useForm();
  const [confirmOrderAuto, setConfirmOrderAuto] = useState(false);
  const [mergeOrderReceipt, setMergeOrderReceipt] = useState(false);
  const [orderOverLimit, setOrderOverLimit] = useState(false);
  const [orderFitStock, setOrderFitStock] = useState(false);
  const [stockLimit, setStockLimit] = useState(false);
  const [statusButton, setStatusButton] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const fetchData = async () => {
    setIsLoading(true);
    let dataAPI = await getSelfPickupConfig();
    let {
      confirm_order_auto,
      merge_order_receipt,
      order_fit_stock,
      order_over_limit,
      status,
      stock_limit,
      verification_type,
    } = dataAPI;
    let verificationTypeNew = verificationData.find(
      (item) => item.name === verification_type
    );
    setValue("verificationType", Number(verificationTypeNew.id));

    setConfirmOrderAuto(confirm_order_auto);
    setMergeOrderReceipt(merge_order_receipt);
    setOrderFitStock(order_fit_stock);
    setOrderOverLimit(order_over_limit);
    setStockLimit(stock_limit);
    setStatusButton(status);

    setIsLoading(false);
  };
  const onConfirmForm = async (register) => {
    setIsSubmitting(true);
    setTimeout(() => {
      setIsSubmitting(false);
    }, 1000);
    let verificationTypeNew = verificationData.find(
      (item) => item.id === register.verificationType
    );
    let dataAPI = {
      verification_type: verificationTypeNew.name,
      confirm_order_auto: confirmOrderAuto,
      merge_order_receipt: mergeOrderReceipt,
      order_over_limit: orderOverLimit,
      order_fit_stock: orderFitStock,
      stock_limit: stockLimit,
      status: statusButton,
    };
    let statusAPI = await updateSelfPickupConfig(dataAPI);
    if (statusAPI === "OK") {
      notifySuccess({
        title: t("notification_successfully_edited_information"),
      });
      fetchData();
    }
  };
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Box p={1}>
      {isLoading ? (
        <Loading />
      ) : (
        <form onSubmit={handleSubmit(onConfirmForm)}>
          <SelfPickupConfigForm
            props={{
              register,
              errors,
              setValue,
              control,
              clearErrors,
              watch,
              confirmOrderAuto,
              setConfirmOrderAuto,
              mergeOrderReceipt,
              setMergeOrderReceipt,
              orderOverLimit,
              setOrderOverLimit,
              orderFitStock,
              setOrderFitStock,
              stockLimit,
              setStockLimit,
              stockLimitDisabled: true,
              verificationTypeDisabled: true,
            }}
          />
          <StatusForm
            statusButton={statusButton}
            setStatusButton={setStatusButton}
          />
          <ConfirmButton disabled={isSubmitting} />
        </form>
      )}
    </Box>
  );
}

export default SelfPickupConfig;
