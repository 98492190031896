import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
  OutlinedInput,
  ListItemText,
  Radio,
  FormHelperText,
  InputLabel,
  FormControl,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { TitleTextfield } from "../../../components/ui/textfield/textfieldCustom";
import ImageCrop from "../../../components/ui/upload/imageCrop";
import { useForm } from "react-hook-form";
import { uploadImage } from "../../../service/upload/uploadImage";
import { postProduct } from "../../../service/productAPI";
import { notifySuccess } from "../../../components/ui/popup/toastPopup";
import { getAllProductCategory } from "../../../service/productCategoryAPI";
import { getAllUnitCounter } from "../../../service/unitCounterAPI";
import { getAllPreDefineCategory } from "../../../service/preDefineCategory";
import BreadcrumbsTitle from "../../../components/ui/breadcrumbsTitle";
import TitleForm from "../../../components/ui/formComponent/titleForm";
import ConfirmButton from "../../../components/ui/button/confirmButton";
import StatusForm from "../../../components/ui/formComponent/statusForm";
import { useNavigate } from "react-router-dom";
import { WarningPopup } from "../../../components/ui/popup/successPopup";
import SelectComponent from "../../../components/ui/textfield/selectComponent";
import TextfieldComponent from "../../../components/ui/textfield/textfieldComponent";
import { useSchema } from "../../../components/hooks/use-schema";
import { productSchema } from "../../../components/validation/productSchema";
import Loading from "../../../components/ui/loading";
import EmptyDataDirect from "../../../components/ui/emptyDataDirect";
import PaperWrapper from "../../../components/ui/paper/paperWrapper";
import { BoxWrapper } from "../../../components/ui/box/boxWrapper";
import { GridContainerSize } from "../../../components/utils/gridContainerSize";
import { BoxGapInput } from "../../../components/ui/box/boxGapInput";
import { MyContext } from "../../../context/MyContext";
import { BoxFormTitleAction } from "../../../components/ui/box/boxFormTitleAction";
import TitleHeaderForm from "../../../components/ui/formComponent/titleHeaderForm";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
function ProductCreate() {
  const {
    register,
    handleSubmit,
    clearErrors,
    watch,
    setValue,
    control,
    formState: { errors },
  } = useForm(useSchema(productSchema));
  const { fetchProduct } = useContext(MyContext);
  const [image, setImage] = useState();
  const [categoryProductList, setCategoryProductList] = useState([]);
  const [unitCounterAll, setUnitCounterAll] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [preDefinedCategoryAll, setPreDefinedCategoryAll] = useState([]);
  const [selectPreDefinedCategoryAll, setSelectPreDefinedCategoryAll] =
    useState([]);
  const { t } = useTranslation();
  const [statusButton, setStatusButton] = useState(true);
  const [amountCount, setAmountCount] = useState(false);
  const [isLoadingPredefine, setIsLoadingPredefine] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const onAmountCount = (type) => {
    setAmountCount(type);
    setSelectPreDefinedCategoryAll([]);
    fetchPreDefineCategory(type);
  };
  const onConfirmForm = async (register) => {
    setIsSubmitting(true);
    if (localStorage.getItem("uploadImage")) {
      WarningPopup({ title: t("notification_please_upload_image") });
    } else {
      let preDefinedBox = [];
      selectPreDefinedCategoryAll.forEach((selectCate) => {
        preDefinedBox.push(selectCate.id);
      });
      let newImage = "";
      if (image) {
        newImage = await uploadImage(image);
      }
      let dataAPI = {
        product_category_id: register.productCategory,
        unit_counter_id: register.unit,
        image: newImage,
        stock_count: amountCount,
        status: statusButton,
        product_lang: {
          name: register.productName,
          description: register.description,
          language_code: "ALL",
        },
        pre_define_categories: preDefinedBox,
      };
      let statusAPI = await postProduct(dataAPI);
      if (statusAPI === "OK") {
        fetchProduct()
          .then(() => {
            notifySuccess({
              title: t("toast_successfully_added_information"),
            });
            navigate("/products");
          })
          .catch((error) => {
            console.error("Error fetching product:", error);
          });
      }
    }
    await new Promise((resolve) => setTimeout(resolve, 2000));
    setIsSubmitting(false);
  };

  const fetchData = async () => {
    localStorage.removeItem("uploadImage");
    fetchPreDefineCategory(false);
    fetchDataCategory();
    fetchDataUnit();
  };
  const fetchDataCategory = async () => {
    let dataAPI = await getAllProductCategory();
    dataAPI = dataAPI.filter((item) => item.status === true);
    setCategoryProductList(dataAPI);
  };
  const fetchDataUnit = async () => {
    let dataAPI = await getAllUnitCounter();
    dataAPI = dataAPI.filter((item) => item.status === true);
    setUnitCounterAll(dataAPI);
    setIsLoading(false);
  };
  const fetchPreDefineCategory = async (ItemAmountCount) => {
    setIsLoadingPredefine(true);
    let dataBox = [];
    let dataAPI = await getAllPreDefineCategory();
    dataAPI.forEach((item) => {
      let itemBox = item.pre_defines.filter(
        (itemPre) => itemPre.stock_count === ItemAmountCount
      );
      if (itemBox.length !== 0) {
        dataBox.push({ id: item.id, name: item.name });
      }
    });
    setPreDefinedCategoryAll(dataBox);
    setIsLoadingPredefine(false);
  };
  const onPreDefinedCategoryAll = (e) => {
    const {
      target: { value },
    } = e;
    setSelectPreDefinedCategoryAll(
      typeof value === "string" ? value.split(",") : value
    );
  };
  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box>
      <BreadcrumbsTitle
        props={{
          title: t("product_create"),
          dataLink: [
            { text: t("product"), link: "/products" },
            { text: t("product_create"), link: null },
          ],
        }}
      />
      {isLoading ? (
        <Loading />
      ) : (
        <GridContainerSize>
          <Box p={2}>
            <form onSubmit={handleSubmit(onConfirmForm)}>
              <BoxWrapper>
                <PaperWrapper props={{ growFull: 1 }}>
                  <BoxFormTitleAction>
                    <TitleHeaderForm props={{ text: t("product") }} />
                    <StatusForm
                      statusButton={statusButton}
                      setStatusButton={setStatusButton}
                    />
                  </BoxFormTitleAction>
                  <BoxGapInput>
                    <Box className="boxWrapper-container">
                      <BoxWrapper>
                        <PaperWrapper props={{ growFull: null }}>
                          <TitleForm props={{ text: t("product_image") }} />
                          <Box align="center">
                            <ImageCrop
                              props={{
                                setImage,
                                image,
                                heightCrop: 256,
                                widthCrop: 256,
                                label: "product_image",
                              }}
                            />
                          </Box>
                        </PaperWrapper>
                        <PaperWrapper props={{ growFull: 1 }}>
                          <TitleForm props={{ text: t("product_info") }} />
                          <BoxGapInput p={2} sx={{ boxSizing: "border-box" }}>
                            <SelectComponent
                              watch={watch}
                              title={t("product_category")}
                              name="productCategory"
                              errors={errors}
                              setValue={setValue}
                              dataEmpty={t("product_category_select")}
                              optionsData={categoryProductList}
                              disabled={categoryProductList.length === 0}
                              dataAlert={
                                <>
                                  {categoryProductList.length === 0 ? (
                                    <EmptyDataDirect
                                      props={{
                                        url: "/product-category",
                                        text: t("product_category"),
                                      }}
                                    />
                                  ) : null}
                                </>
                              }
                              clearErrors={clearErrors}
                              control={control}
                            />
                            <SelectComponent
                              watch={watch}
                              title={t("unit")}
                              name="unit"
                              setValue={setValue}
                              errors={errors}
                              dataEmpty={t("unit_select")}
                              optionsData={unitCounterAll}
                              disabled={unitCounterAll.length === 0}
                              dataAlert={
                                <>
                                  {unitCounterAll.length === 0 ? (
                                    <EmptyDataDirect
                                      props={{ url: "/unit", text: t("unit") }}
                                    />
                                  ) : null}
                                </>
                              }
                              clearErrors={clearErrors}
                              control={control}
                            />
                            <TextfieldComponent
                              title={t("product_name")}
                              name="productName"
                              register={register}
                              placeholder={t("product_example")}
                              errors={errors}
                              fullGrid={true}
                            />
                            <TextfieldComponent
                              title={t("product_description")}
                              name="description"
                              register={register}
                              placeholder={t("global_optional")}
                              errors={errors}
                              multiline={true}
                              rows={4}
                              fullGrid={true}
                            />
                            <TitleTextfield text={t("product_amount")} />
                            <Box>
                              <FormControlLabel
                                control={
                                  <Radio
                                    name="limit"
                                    checked={amountCount === true}
                                    onChange={(e) => onAmountCount(true)}
                                  />
                                }
                                label={t("product_amount_limit")}
                              />
                              <FormControlLabel
                                control={
                                  <Radio
                                    name="unlimited"
                                    checked={amountCount === false}
                                    onChange={(e) => onAmountCount(false)}
                                  />
                                }
                                label={t("product_amount_un_limit")}
                              />
                            </Box>
                            {/* <TitleTextfield text={t("product_option_category")} /> */}

                            <FormControl fullWidth sx={{ mt: 2 }}>
                              <InputLabel
                                id="demo-multiple-checkbox-label"
                                shrink={true}
                              >
                                {t("product_option_category")}
                              </InputLabel>
                              <Select
                                {...register("selectPreDefinedCategoryAll")}
                                labelId="demo-multiple-checkbox-label"
                                multiple
                                displayEmpty
                                notched={true}
                                label={t("product_option_category")}
                                error={errors.selectPreDefinedCategoryAll}
                                value={selectPreDefinedCategoryAll}
                                placeholder={
                                  t("product_option_category_select") +
                                  t("global_optional")
                                }
                                id="selectPreDefinedCategoryAll"
                                name="selectPreDefinedCategoryAll"
                                onChange={onPreDefinedCategoryAll}
                                input={
                                  <OutlinedInput
                                    label={t("product_option_category")}
                                  />
                                }
                                renderValue={(selected) => {
                                  let dataReturn = [];
                                  if (selected.length === 0) {
                                    // return [];
                                    return (
                                      <Typography
                                        sx={(theme) => ({
                                          color:
                                            theme.palette.secondaryText.main,
                                          fontStyle: "italic",
                                          boxSizing: "border-box",
                                        })}
                                      >
                                        {t("product_option_category_select") +
                                          t("global_optional")}
                                      </Typography>
                                    );
                                  } else {
                                    selected.forEach((item, index) => {
                                      if (index > 0) {
                                        dataReturn.push(",");
                                      }
                                      dataReturn.push(item.name);
                                    });
                                  }

                                  return dataReturn;
                                }}
                                MenuProps={MenuProps}
                                fullWidth
                                size="small"
                                disabled={isLoadingPredefine}
                              >
                                <MenuItem value={[]} disabled>
                                  <Typography
                                    sx={(theme) => ({
                                      color: theme.palette.secondaryText.main,
                                      fontStyle: "italic",
                                    })}
                                  >
                                    {t("product_option_category_select") +
                                      t("global_optional")}
                                  </Typography>
                                </MenuItem>
                                {preDefinedCategoryAll.map((item) => (
                                  <MenuItem key={item} value={item}>
                                    <Checkbox
                                      checked={
                                        selectPreDefinedCategoryAll.indexOf(
                                          item
                                        ) > -1
                                      }
                                    />
                                    <ListItemText primary={item.name} />
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                            <FormHelperText>
                              {t("product_category_helper")}
                            </FormHelperText>
                          </BoxGapInput>
                        </PaperWrapper>
                      </BoxWrapper>
                    </Box>
                    <ConfirmButton disabled={isSubmitting} />
                  </BoxGapInput>
                </PaperWrapper>
              </BoxWrapper>
            </form>
          </Box>
        </GridContainerSize>
      )}
    </Box>
  );
}

export default ProductCreate;
