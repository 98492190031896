import { Box, Button, Tab, Typography } from "@mui/material";
import React from "react";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import LineTotalSale from "./lineTotalSale";
import LineSeparateBranchTotalSale from "./lineSeparateBranchTotalSale";
import dayjs from "dayjs";
import { RadioButtonChecked, RadioButtonUnchecked } from "@mui/icons-material";

function TotalSale({ props }) {
  let {
    tabSelect,
    handleChange,
    setStatusLine,
    statusLine,
    startDate,
    endDate,
    dataTotalSale,
    dataTotalSaleLine,
  } = props;
  const dataTotalBranch =
    dataTotalSaleLine.N02_1.data.length > 0 ? dataTotalSaleLine.N02_1.data : [];
  const dataTotalSeparateBranch =
    dataTotalSaleLine.N02_2.data.length > 0 ? dataTotalSaleLine.N02_2.data : [];
  const dataFromPrevious = (name) => {
    return (
      <Box sx={(theme) => ({ display: "flex", gap: 0.5 })}>
        {dataTotalSale ? (
          <>
            {" "}
            {dataTotalSale.data.data[name].change_total !== "" ? (
              <Typography
                sx={(theme) => ({
                  color:
                    Number(
                      dataTotalSale.data.data[name].change_total.split("%")[0]
                    ) > 0
                      ? theme.palette.success.main
                      : theme.palette.error.main,
                  fontSize: 14,
                })}
              >
                {Number(
                  dataTotalSale.data.data[name].change_total.split("%")[0]
                ).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
                %
              </Typography>
            ) : (
              <Typography sx={{ fontSize: 14 }}>No data</Typography>
            )}
          </>
        ) : (
          <Typography sx={{ fontSize: 14 }}>No data</Typography>
        )}

        <Typography sx={{ fontSize: 14 }}>from previous</Typography>
        <Typography sx={{ fontSize: 14 }}>
          {Number(
            dayjs(endDate).format("DD") - dayjs(startDate).format("DD") + 1
          )}
        </Typography>
        <Typography sx={{ fontSize: 14 }}>days</Typography>
      </Box>
    );
  };
  return (
    <>
      {window.innerWidth < 1 ? (
        <Box>
          {/* <Select
            value={selectChart}
            onChange={(e) => setSelectChart(e.target.value)}
            fullWidth
            size="small"
            sx={{ mb: 1 }}
          >
            <MenuItem value="1">
              <Box sx={{ display: "flex", gap: 1 }}>
                <Typography>Total Sales</Typography>
                <Typography
                  sx={(theme) => ({
                    color: theme.palette.secondaryText.main,
                  })}
                >
                  ฿
                  {Number(summary.net_sales?.net_sales).toLocaleString(
                    undefined,
                    {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }
                  )}
                </Typography>
              </Box>
            </MenuItem>
            <MenuItem value="2">
              <Box sx={{ display: "flex", gap: 1 }}>
                <Typography> Number of transactions</Typography>
                <Typography
                  sx={(theme) => ({
                    color: theme.palette.secondaryText.main,
                  })}
                >
                  {Number(summary.amount?.amount).toLocaleString()}
                </Typography>
              </Box>
            </MenuItem>
            <MenuItem value="3">
              <Box sx={{ display: "flex", gap: 1 }}>
                <Typography> Average transaction amount</Typography>
                <Typography
                  sx={(theme) => ({
                    color: theme.palette.secondaryText.main,
                  })}
                >
                  ฿
                  {Number(summary.average?.avg).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </Typography>
              </Box>
            </MenuItem>
          </Select>
          {selectChart === "1" ? (
            <>
              {dayjs(startDate).isSame(dayjs(endDate)) ? (
                <DashboardTotalSaleOneDay
                  props={{
                    data,
                    nameValue: "percent_net_sales",
                    symbol: true,
                  }}
                />
              ) : (
                <DashboardTotalSale
                  props={{ data, nameValue: "net_sales", symbol: true }}
                />
              )}
            </>
          ) : null}
          {selectChart === "2" ? (
            <>
              {dayjs(startDate).isSame(dayjs(endDate)) ? (
                <DashboardTotalSaleOneDay
                  props={{
                    data,
                    nameValue: "percent_amount",
                    symbol: false,
                    title: "Total Transactions",
                  }}
                />
              ) : (
                <DashboardTotalSale
                  props={{
                    data,
                    nameValue: "amount",
                    symbol: false,
                    title: "Total Transactions",
                  }}
                />
              )}
            </>
          ) : null}
          {selectChart === "3" ? (
            <>
              {dayjs(startDate).isSame(dayjs(endDate)) ? (
                <DashboardTotalSaleOneDay
                  props={{
                    data,
                    nameValue: "avg",
                    symbol: false,
                    title: "Sales",
                  }}
                />
              ) : (
                <DashboardTotalSale
                  props={{
                    data,
                    nameValue: "avg",
                    symbol: true,
                    title: "Sales",
                  }}
                />
              )}
            </>
          ) : null} */}
        </Box>
      ) : (
        <TabContext value={tabSelect}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              onChange={handleChange}
              aria-label="lab API tabs"
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab
                label={
                  <Box align="left">
                    <Typography sx={{ opacity: 0.5 }}>Total Sales</Typography>
                    <Typography sx={{ fontWeight: 600, fontSize: 24 }}>
                      {dataTotalSale ? (
                        <>
                          {dataTotalSale.data?.data.total_sale ? (
                            <>
                              {Number(
                                dataTotalSale.data.data.total_sale.total
                              ).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </>
                          ) : (
                            0
                          )}
                        </>
                      ) : (
                        0
                      )}
                    </Typography>
                    {dataFromPrevious("total_sale")}
                  </Box>
                }
                sx={{ flexGrow: 1, textAlign: "left" }}
                value={"total_sale"}
              />
              <Tab
                label={
                  <Box align="left">
                    <Typography sx={{ opacity: 0.5 }}>
                      Number of transactions
                    </Typography>
                    <Typography sx={{ fontWeight: 600, fontSize: 24 }}>
                      {dataTotalSale ? (
                        <>
                          {" "}
                          {Number(
                            dataTotalSale.data.data.total_receipt.total
                          ).toLocaleString()}
                        </>
                      ) : (
                        <>0</>
                      )}
                    </Typography>
                    {dataFromPrevious("total_receipt")}
                  </Box>
                }
                sx={{ flexGrow: 1, textAlign: "left" }}
                value={"total_receipt"}
              />
              <Tab
                label={
                  <Box align="left">
                    <Typography sx={{ opacity: 0.5 }}>
                      Average transaction amount
                    </Typography>
                    <Typography sx={{ fontWeight: 600, fontSize: 24 }}>
                      {dataTotalSale ? (
                        <>
                          {Number(
                            dataTotalSale.data.data.avg_price_per_receipt.total
                          ).toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </>
                      ) : (
                        0
                      )}
                    </Typography>
                    {dataFromPrevious("avg_price_per_receipt")}
                  </Box>
                }
                sx={{ flexGrow: 1, textAlign: "left" }}
                value={"avg_price_per_receipt"}
              />
            </TabList>
          </Box>
          <Box
            sx={(theme) => ({
              display: "flex",
              justifyContent: "flex-end",
              gap: 2,
              p: 2,
            })}
          >
            <Button
              size="small"
              color={statusLine === "totalBranch" ? "success" : "primary"}
              variant="outlined"
              sx={{ borderRadius: 2 }}
              startIcon={
                statusLine === "totalBranch" ? (
                  <RadioButtonChecked />
                ) : (
                  <RadioButtonUnchecked />
                )
              }
              onClick={() => setStatusLine("totalBranch")}
            >
              Total Branch
            </Button>
            <Button
              size="small"
              variant="outlined"
              startIcon={
                statusLine !== "totalBranch" ? (
                  <RadioButtonChecked />
                ) : (
                  <RadioButtonUnchecked />
                )
              }
              color={statusLine !== "totalBranch" ? "success" : "primary"}
              sx={{ borderRadius: 2 }}
              onClick={() => setStatusLine("separateBranch")}
            >
              Separate Branch
            </Button>
          </Box>
          <TabPanel
            value="total_sale"
            sx={(theme) => ({
              px: 2,
            })}
          >
            {statusLine === "totalBranch" ? (
              <>
                <LineTotalSale
                  props={{
                    startDate,
                    endDate,
                    dataValues: dataTotalBranch,
                    nameValue: tabSelect,
                    symbol: true,
                    title: "Total Sales",
                  }}
                />
              </>
            ) : (
              <>
                <LineSeparateBranchTotalSale
                  props={{
                    startDate,
                    endDate,
                    dataValues: dataTotalSeparateBranch,
                    nameValue: tabSelect,
                    symbol: true,
                    title: "Total Sales",
                  }}
                />
              </>
            )}
          </TabPanel>
          <TabPanel
            value="total_receipt"
            sx={(theme) => ({
              px: 2,
            })}
          >
            {statusLine === "totalBranch" ? (
              <>
                <LineTotalSale
                  props={{
                    startDate,
                    endDate,
                    dataValues: dataTotalBranch,
                    nameValue: tabSelect,
                    symbol: true,
                    title: "Total Receipt",
                  }}
                />
              </>
            ) : (
              <>
                <LineSeparateBranchTotalSale
                  props={{
                    startDate,
                    endDate,
                    dataValues: dataTotalSeparateBranch,
                    nameValue: tabSelect,
                    symbol: true,
                    title: "Total Receipt",
                  }}
                />
              </>
            )}
          </TabPanel>
          <TabPanel
            value="avg_price_per_receipt"
            sx={(theme) => ({
              px: 2,
            })}
          >
            {statusLine === "totalBranch" ? (
              <>
                <LineTotalSale
                  props={{
                    startDate,
                    endDate,
                    dataValues: dataTotalBranch,
                    nameValue: tabSelect,
                    symbol: true,
                    title: "Total Average price per receipt",
                  }}
                />
              </>
            ) : (
              <>
                <LineSeparateBranchTotalSale
                  props={{
                    startDate,
                    endDate,
                    dataValues: dataTotalSeparateBranch,
                    nameValue: tabSelect,
                    symbol: true,
                    title: "Total Average price per receipt",
                  }}
                />
              </>
            )}
          </TabPanel>
        </TabContext>
      )}
    </>
  );
}

export default TotalSale;
