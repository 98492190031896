import * as yup from "yup";
import { validationSchemas } from "./data";
export const branchSchema = yup.object().shape({
  branchName: validationSchemas.name,
  branchCode: validationSchemas.name,
  branchRevenueCode: validationSchemas.number,
  country: validationSchemas.name,
  district: validationSchemas.name,
  subDistrict: validationSchemas.name,
  province: validationSchemas.name,
  zipCode: validationSchemas.name,
});
