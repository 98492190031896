import React, { useState, useEffect, useContext } from "react";
import { Box } from "@mui/material";
import TitleForm from "../../components/ui/formComponent/titleForm";
import { useTranslation } from "react-i18next";
import ConfirmButton from "../../components/ui/button/confirmButton";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { uploadImage } from "../../service/upload/uploadImage";
import { notifySuccess } from "../../components/ui/popup/toastPopup";
import {
  addPosUserToTemplate,
  getAllPosUserTemplate,
  getPosUser,
  updatePosUser,
} from "../../service/posUserAPI";
import { getAllBranch } from "../../service/branchAPI";
import SettingTypeBranch from "../../components/ui/addTypeTemplate/settingTypeBranch";
import Loading from "../../components/ui/loading";
import BreadcrumbsTitle from "../../components/ui/breadcrumbsTitle";
import ImageCrop from "../../components/ui/upload/imageCrop";
import TextfieldComponent from "../../components/ui/textfield/textfieldComponent";
import { useSchema } from "../../components/hooks/use-schema";
import { staffPosSchema } from "../../components/validation/staffPosSchema";
import { BoxWrapper } from "../../components/ui/box/boxWrapper";
import PaperWrapper from "../../components/ui/paper/paperWrapper";
import StaffPOSPermission from "./staffPOSPermission";
import { GridContainerSize } from "../../components/utils/gridContainerSize";
import StatusForm from "../../components/ui/formComponent/statusForm";
import { getImage } from "../../service/upload/getImage";
import { UseConvertImageToBase64 } from "../../components/hooks/use-convert-image-to-base-64";
import { BoxGapInput } from "../../components/ui/box/boxGapInput";
import { MyContext } from "../../context/MyContext";
import { BoxFormTitleAction } from "../../components/ui/box/boxFormTitleAction";
import TitleHeaderForm from "../../components/ui/formComponent/titleHeaderForm";
function StaffPOSDetail() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm(useSchema(staffPosSchema));
  const { t } = useTranslation();
  const [dataAll, setDataAll] = useState(null);
  const [allDataTemplate, setAllDataTemplate] = useState([]);
  const paramsId =
    window.location.pathname.split("/")[
      window.location.pathname.split("/").length - 1
    ];
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [statusButton, setStatusButton] = useState(true);
  const [permissionAll, setPermissionAll] = useState([]);
  const [image, setImage] = useState(null);
  const [checkPermissionAll, setCheckPermissionAll] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { fetchStaff } = useContext(MyContext);

  const fetchData = async () => {
    localStorage.removeItem("uploadImage");
    let dataAPI = await getPosUser(paramsId);
    let {
      profile_img,
      pos_user_lang,
      email,
      nat_id,
      pin,
      status,
      tel,
      position,
      pos_user_permissions,
    } = dataAPI;

    let imageUrl = await getImage(profile_img);
    profile_img = UseConvertImageToBase64(imageUrl);
    await Promise.all(imageUrl);

    setValue("imageRef", profile_img);
    setImage(profile_img);
    setValue("firstName", pos_user_lang ? pos_user_lang.firstname : null);
    setValue("lastName", pos_user_lang ? pos_user_lang.lastname : null);
    setValue("tel", tel);
    setValue("email", email);
    setValue("positionUser", position);
    setValue("idCardUser", nat_id);
    setValue("pin", pin);
    setStatusButton(status);
    setDataAll(dataAPI);
    // branch
    if (status) {
      await fetchAllBranch();
    }
    let boxPermission = [];
    pos_user_permissions.forEach((item) => {
      boxPermission.push({
        name: item.name,
        permission: item.permission,
        value: item.value,
      });
    });
    if (boxPermission.every((item) => item.value === true)) {
      setCheckPermissionAll(true);
    }
    setPermissionAll(boxPermission);
    await setIsLoading(false);
  };
  const onConfirm = async (register) => {
    setIsSubmitting(true);
    let newImage = "";
    if (image) {
      if (typeof image === "object") {
        newImage = await uploadImage(image);
      } else {
        newImage = dataAll.profile_img;
      }
    }
    let dataAPI = {
      id: dataAll.id,
      position: register.positionUser,
      pin: register.pin,
      profile_img: newImage,
      nat_id: register.idCardUser,
      tel: register.tel,
      email: register.email,
      status: statusButton,
      pos_user_lang: {
        firstname: register.firstName,
        lastname: register.lastName,
        language_code: String(localStorage.getItem("language_form")),
      },
      pos_user_permissions: permissionAll,
    };
    let statusAPI = await updatePosUser(dataAPI);
    if (statusAPI === "OK") {
      fetchStaff()
        .then(() => {
          notifySuccess({
            title: t("notification_successfully_edited_information"),
          });
          navigate("/posUser");
        })
        .catch((error) => {
          console.error("Error fetching product:", error);
        });
    }
    await new Promise((resolve) => setTimeout(resolve, 2000));
    setIsSubmitting(false);
  };
  const onChangePermission = (index) => (e) => {
    let checked = e.target.checked;
    let dataNews = [...permissionAll];
    dataNews[index].value = checked;
    setPermissionAll(dataNews);
    if (
      permissionAll.every((itemPermission) => itemPermission.value === true)
    ) {
      setCheckPermissionAll(true);
    } else {
      setCheckPermissionAll(false);
    }
  };
  const onCheckAllPermissions = (e) => {
    let checked = e.target.checked;
    permissionAll.forEach((itemPermission) => {
      itemPermission.value = checked;
    });
    setCheckPermissionAll(checked);
  };
  const fetchAllBranch = async () => {
    let dataAPI = await getAllBranch();
    dataAPI = dataAPI.filter((item) => item.branch_lang !== null);
    await fetchSettingSelectTemplate(dataAPI);
  };
  const fetchSettingSelectTemplate = async (dataBranch) => {
    let dataAPI = await getAllPosUserTemplate(paramsId);
    dataAPI = dataAPI[0].template_pos_users;
    let dataBox = [];
    dataBranch.forEach((item) => {
      let dataNews = dataAPI.filter(
        (itemTemplate) => itemTemplate.template_id === item.template_id
      );
      if (dataNews.length > 0) {
        dataBox.push({
          branch: item,
          data: dataNews[0],
          select: false,
        });
      } else {
        dataBox.push({
          branch: item,
          data: null,
          select: false,
        });
      }
    });
    await setAllDataTemplate(dataBox);
  };
  const onAddStaffPOSToTemplate = async () => {
    let branchArray = allDataTemplate.filter((item) => item.select === true);
    let dataSelect = [];
    branchArray.forEach((item) => {
      dataSelect.push(item.branch.id);
    });
    let dataAPI = {
      pos_user_id: dataAll.id,
      branches: dataSelect,
    };
    let dataStatus = await addPosUserToTemplate(dataAPI);
    if (dataStatus === "OK") {
      notifySuccess({
        title: t("notification_successfully_edited_information"),
      });
      fetchAllBranch();
    }
  };
  const convertNamePermission = (name) => {
    switch (name) {
      case "home_page":
        return t("staff_pos_home_page");
      case "manage_receipt_page":
        return t("staff_pos_manage_receipt_page");
      case "manage_money_in_drawer":
        return t("staff_pos_manage_money_in_drawer");
      case "daily_balance_summary_page":
        return t("staff_pos_daily_balance_summary_page");
      case "pin_close":
        return t("staff_pos_pin_close");
      case "void_bill":
        return t("staff_pos_receipt_cancel");
      case "setting":
        return t("staff_pos_setting");
      case "drawer_summary":
        return t("staff_pos_drawer_summary");
      default:
        t("global_data_not_found");
    }
  };
  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <Box>
      <BreadcrumbsTitle
        props={{
          title: t("staff_pos_info"),
          dataLink: [
            { text: t("staff_pos"), link: "/posUser" },
            { text: t("staff_pos_info"), link: null },
          ],
        }}
      />
      <GridContainerSize>
        {isLoading ? (
          <>
            <Loading />
          </>
        ) : (
          <Box p={2}>
            <form onSubmit={handleSubmit(onConfirm)}>
              <BoxWrapper>
                <PaperWrapper props={{ growFull: 1 }}>
                  <BoxFormTitleAction>
                    <TitleHeaderForm props={{ text: t("staff_pos") }} />
                    <StatusForm
                      statusButton={statusButton}
                      setStatusButton={setStatusButton}
                    />
                  </BoxFormTitleAction>
                  <BoxGapInput>
                    <Box className="boxWrapper-container">
                      <BoxWrapper>
                        <PaperWrapper props={{ growFull: null }}>
                          <TitleForm props={{ text: t("staff_pos_image") }} />
                          <Box align="center">
                            <ImageCrop
                              props={{
                                setImage,
                                image,
                                heightCrop: 256,
                                widthCrop: 256,
                                label: "staffPOS",
                              }}
                            />
                          </Box>
                        </PaperWrapper>
                        <PaperWrapper props={{ growFull: 1 }}>
                          <TitleForm props={{ text: t("staff_pos_info") }} />
                          <BoxGapInput p={2}>
                            <BoxWrapper>
                              <TextfieldComponent
                                title={t("global_first_name")}
                                name="firstName"
                                register={register}
                                placeholder={t("global_first_name_example")}
                                errors={errors}
                              />
                              <TextfieldComponent
                                title={t("global_last_name")}
                                name="lastName"
                                register={register}
                                placeholder={t("global_last_name_example")}
                                errors={errors}
                              />
                            </BoxWrapper>

                            <BoxWrapper>
                              <TextfieldComponent
                                title={t("tel")}
                                name="tel"
                                register={register}
                                placeholder={t("global_tel_example")}
                                errors={errors}
                              />
                              <TextfieldComponent
                                title={t("global_email")}
                                name="email"
                                register={register}
                                placeholder={t("global_email_example")}
                                errors={errors}
                              />
                            </BoxWrapper>
                            <BoxWrapper>
                              <TextfieldComponent
                                title={t("staff_pos_position")}
                                name="positionUser"
                                register={register}
                                placeholder={t("staff_pos_position_example")}
                                errors={errors}
                              />
                              <TextfieldComponent
                                title={t("global_national_id")}
                                name="idCardUser"
                                register={register}
                                placeholder={t("global_national_id_example")}
                                errors={errors}
                              />
                            </BoxWrapper>
                            <TextfieldComponent
                              title={t("global_pin")}
                              name="pin"
                              register={register}
                              placeholder={t("global_pin_example")}
                              errors={errors}
                            />
                          </BoxGapInput>
                        </PaperWrapper>
                      </BoxWrapper>
                      <BoxWrapper>
                        <StaffPOSPermission
                          props={{
                            checkPermissionAll,
                            onCheckAllPermissions,
                            permissionAll,
                            convertNamePermission,
                            onChangePermission,
                          }}
                        />
                        <PaperWrapper props={{ growFull: 1 }}>
                          <SettingTypeBranch
                            title={t("staff_pos_branch")}
                            allDataTemplate={allDataTemplate}
                            setAllDataTemplate={setAllDataTemplate}
                            onAddTypeToTemplate={onAddStaffPOSToTemplate}
                            directToBranch={"userPos"}
                          />
                        </PaperWrapper>
                      </BoxWrapper>
                      <ConfirmButton disabled={isSubmitting} />
                    </Box>
                  </BoxGapInput>
                </PaperWrapper>
              </BoxWrapper>
            </form>
          </Box>
        )}
      </GridContainerSize>
    </Box>
  );
}

export default StaffPOSDetail;
