import { Box } from "@mui/material";
import React from "react";
import PaperWrapper from "../../../components/ui/paper/paperWrapper";
import TitleForm from "../../../components/ui/formComponent/titleForm";
import { t } from "i18next";
import TextfieldComponent from "../../../components/ui/textfield/textfieldComponent";
import StatusForm from "../../../components/ui/formComponent/statusForm";
import ConfirmButton from "../../../components/ui/button/confirmButton";
import { BoxWrapper } from "../../../components/ui/box/boxWrapper";
import ImageCrop from "../../../components/ui/upload/imageCrop";

function SelfPickupAdminForm({ props }) {
  const {
    handleSubmit,
    onConfirmForm,
    register,
    errors,
    statusButton,
    setStatusButton,
    isSubmitting,
    image,
    setImage,
  } = props;
  return (
    <div>
      <Box p={2}>
        <form onSubmit={handleSubmit(onConfirmForm)}>
          <Box className="boxWrapper-container">
            <BoxWrapper>
              <PaperWrapper props={{ growFull: null }}>
                <TitleForm props={{ text: t("global_image") }} />
                <Box align="center">
                  <ImageCrop
                    props={{
                      setImage,
                      image,
                      heightCrop: 256,
                      widthCrop: 256,
                      label: "selfPickupAdmin",
                    }}
                  />
                </Box>
              </PaperWrapper>
              <PaperWrapper props={{ growFull: 1 }}>
                <TitleForm props={{ text: t("self_pickup_admin") }} />
                <Box p={2}>
                  <BoxWrapper>
                    <TextfieldComponent
                      title={t("global_first_name")}
                      name="firstName"
                      register={register}
                      placeholder={t("global_first_name_example")}
                      errors={errors}
                    />
                    <TextfieldComponent
                      title={t("global_last_name")}
                      name="lastName"
                      register={register}
                      placeholder={t("global_last_name_example")}
                      errors={errors}
                    />
                  </BoxWrapper>
                  <BoxWrapper>
                    <TextfieldComponent
                      title={t("tel")}
                      name="tel"
                      register={register}
                      placeholder={t("global_tel_example")}
                      errors={errors}
                    />
                    <TextfieldComponent
                      title={t("global_email")}
                      name="email"
                      register={register}
                      placeholder={t("global_email_example")}
                      errors={errors}
                    />
                  </BoxWrapper>
                  <BoxWrapper>
                    <TextfieldComponent
                      title={t("staff_pos_position")}
                      name="positionUser"
                      register={register}
                      placeholder={t("staff_pos_position_example")}
                      errors={errors}
                    />
                    <TextfieldComponent
                      title={t("global_national_id")}
                      name="idCardUser"
                      register={register}
                      placeholder={t("global_national_id_example")}
                      errors={errors}
                    />
                  </BoxWrapper>
                  <TextfieldComponent
                    title={t("global_pin")}
                    name="pin"
                    register={register}
                    placeholder={t("global_pin_example")}
                    errors={errors}
                  />
                </Box>
              </PaperWrapper>
            </BoxWrapper>

            <StatusForm
              statusButton={statusButton}
              setStatusButton={setStatusButton}
            />
            <ConfirmButton disabled={isSubmitting} />
          </Box>
        </form>
      </Box>
    </div>
  );
}

export default SelfPickupAdminForm;
