import axios from "axios";
import { expiredToken } from "./expiredToken";
import {
  AccessTokenAPI,
  BusinessId,
  CompanyId,
  LanguageForm,
  ServiceId,
} from "./getLocalStorage";

export const getAllMainCategory = () => {
  let config = {
    method: "get",
    url: `${process.env.REACT_APP_API_URL}/api/setting/getAllMainCategory?language_code=${LanguageForm}&company_uid=${CompanyId}&business_uid=${BusinessId}&service_code=${ServiceId}`,
    headers: {
      Authorization: `Bearer ${AccessTokenAPI}`,
    },
  };

  return axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      console.log(error);
      expiredToken(error);
    });
};
export const getMainCategory = (paramId) => {
  let config = {
    method: "get",
    url: `${process.env.REACT_APP_API_URL}/api/setting/getMainCategory?id=${paramId}&language_code=${LanguageForm}&company_uid=${CompanyId}&business_uid=${BusinessId}&service_code=${ServiceId}`,
    headers: {
      Authorization: `Bearer ${AccessTokenAPI}`,
    },
  };

  return axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      console.log(error);
      expiredToken(error);
    });
};
export const storeMainCategory = (dataAPI) => {
  let config = {
    method: "post",
    url: `${process.env.REACT_APP_API_URL}/api/setting/storeMainCategory?company_uid=${CompanyId}&business_uid=${BusinessId}&service_code=${ServiceId}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${AccessTokenAPI}`,
    },
    data: dataAPI,
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      expiredToken(error);
    });
};
export const updateMainCategory = (dataAPI) => {
  let config = {
    method: "put",
    url: `${process.env.REACT_APP_API_URL}/api/setting/updateMainCategory?company_uid=${CompanyId}&business_uid=${BusinessId}&service_code=${ServiceId}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${AccessTokenAPI}`,
    },
    data: dataAPI,
  };

  return axios(config)
    .then(function (response) {
      return response.data.message;
    })
    .catch(function (error) {
      console.log(error);
      expiredToken(error);
    });
};
export const addMainCategoryToTemplate = (dataAPI) => {
  let config = {
    method: "post",
    url: `${process.env.REACT_APP_API_URL}/api/setting/addMainCategoryToTemplate?company_uid=${CompanyId}&business_uid=${BusinessId}&service_code=${ServiceId}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${AccessTokenAPI}`,
    },
    data: dataAPI,
  };

  return axios(config)
    .then(function (response) {
      return response.data.message;
    })
    .catch(function (error) {
      console.log(error);
      expiredToken(error);
    });
};
export const getAllMainCategoryTemplate = (typeId) => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_API_URL}/api/setting/getAllMainCategoryTemplate?id=${typeId}&language_code=${LanguageForm}&company_uid=${CompanyId}&business_uid=${BusinessId}&service_code=${ServiceId}`,
    headers: {
      Authorization: `Bearer ${AccessTokenAPI}`,
    },
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      console.log(error);
      expiredToken(error);
    });
};
