import React from "react";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  DoughnutController,
} from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { Box, Typography, useTheme } from "@mui/material";
import "chartjs-plugin-datalabels";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { t } from "i18next";
import { Circle } from "@mui/icons-material";
import colorDashboard from "../../insight/colorDashboard.json";

ChartJS.register(ArcElement, Tooltip, Legend, DoughnutController);

function PieChartComponent({ props }) {
  let { dataBranch } = props;
  const theme = useTheme();
  const total = dataBranch.branchPie.reduce(
    (acc, item) => acc + item.grand_total,
    0
  );
  const labels = dataBranch.branchPie.map((item) => item.name);

  const data = {
    labels: labels,
    datasets: [
      {
        label: "pie",
        data: dataBranch.branchPie.map((item) => item.grand_total),
        backgroundColor: colorDashboard,

        borderColor: theme.palette.background.default,
      },
    ],
  };
  const colorSet = colorDashboard;
  const options = {
    maintainAspectRatio: false,
    width: 100,
    // elements: {
    //   center: {
    //     text: "Center Text",
    //     color: "#000", // Color of the text
    //     fontStyle: "Arial", // Font style of the text
    //     sidePadding: 20, // Padding around the text
    //   },
    // },
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
        color: "white",
        formatter: (value) =>
          value ? `${((value / total) * 100).toFixed(2)}%` : null,
      },
      tooltip: {
        callbacks: {
          title: function (context) {
            return t(context[0].label);
          },

          label: function (context) {
            let sum = Number(context.raw).toLocaleString();
            let sumPercent = `${((context.raw / total) * 100).toFixed(2)}%`;
            return sum + `(${sumPercent})`;
          },
        },
      },
    },
  };
  const centerTextPlugin = {
    id: "centerText",
    beforeDraw: (chart) => {
      const { width, height } = chart;
      const ctx = chart.ctx;
      ctx.restore();
      const fontSize = (height / 300).toFixed(2);
      ctx.font = `${fontSize}em sans-serif`;
      ctx.textBaseline = "middle";
      ctx.textAlign = "center"; // Align text horizontally to the center

      // Prepare the text and break it into lines
      const line1 = `${t("Branch")}`;
      const line2 = `${Number(dataBranch.branchPie.length).toLocaleString()}`;

      // Calculate vertical position based on the font size
      const line1Y = height / 2 - fontSize * 10;
      const line2Y = height / 2 + 20;

      // Draw first line with a different color
      ctx.fillStyle = "#000"; // Color for the first line
      ctx.fillText(line1, width / 2, line1Y);

      // Draw second line with a different color
      ctx.fillStyle = theme.palette.primary.main; // Color for the second line (black)
      ctx.font = `bold ${fontSize * 1.5}em sans-serif`; // Bold font
      ctx.fillText(line2, width / 2, line2Y);

      ctx.save();
    },
  };
  return (
    <Box
      sx={(theme) => ({
        position: "relative",
        alignItems: "center",
        // [theme.breakpoints.down("sm")]: {
        //   display: "inline",
        // },
        display: "flex",
        gap: {
          xs: 2,
          sm: 4,
          md: 8,
        },
        justifyContent: "center",
        width: "100%",
      })}
    >
      <div
        style={{
          maxWidth: "200px",
          height: "200px",
          margin: {
            xs: "auto",
            sm: 0,
            md: 0,
          },
          position: "relative",
        }}
      >
        <Doughnut
          data={data}
          plugins={[ChartDataLabels, centerTextPlugin]}
          options={options}
        />
        {/* <Typography
          sx={{
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
            textAlign: "center",
            fontSize: 16,
          }}
        >
          {t("branch")} <br /> {dataBranch.branchPie.length}
        </Typography> */}
      </div>

      <Box sx={(theme) => ({})}>
        {dataBranch.branchPie.map((item, index) => (
          <>
            <Box
              sx={(theme) => ({
                display: "flex",
                gap: 1,
                alignItems: "flex-start",
              })}
            >
              <Circle sx={{ color: colorSet[index] }} />
              <Box sx={{ mt: 0.5 }}>
                <Typography sx={(theme) => ({ fontSize: 12 })}>
                  {item.name}
                </Typography>
                <Typography sx={(theme) => ({ fontSize: 18 })}>
                  {item.grand_total
                    ? ` ${((item.grand_total / total) * 100).toFixed(2)}%`
                    : "0.00%"}
                </Typography>
              </Box>
            </Box>
          </>
        ))}
      </Box>
    </Box>
  );
}

export default PieChartComponent;
