import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import { t } from "i18next";
import CloseIcon from "@mui/icons-material/Close";
import React, { useState } from "react";
import { NumberDecimal } from "../../../components/utils/numberDecimal";
import dayjs from "dayjs";

function ReceiptSummaryDetail({ props }) {
  let { dialogSummary, onCloseDialogSummary } = props;
  let { dataAll } = dialogSummary.data;
  let {
    info,
    open_close,
    summary,
    payment_types,
    receipt_promotion,
    receipt_status,
    receipt_discount,
  } = dataAll.result_summary;
  const [statusButtonPrint, setStatusButtonPrint] = useState(true);
  const onPrintReceipt = async () => {
    setStatusButtonPrint(false);
    setTimeout(() => {
      window.print();
      setTimeout(() => {
        setStatusButtonPrint(true);
      }, 1000);
    }, 1000);
  };
  const LineBetween = ({ props }) => {
    return (
      <Grid container justifyContent={"space-between"} spacing={2}>
        <Grid item xs={props.middleText === "None" ? 9 : 7}>
          <Typography sx={(theme) => ({ fontSize: 12, textAlign: "left" })}>
            {props.text}
          </Typography>
        </Grid>
        {props.middleText !== "None" ? (
          <Grid item xs={2}>
            <Typography sx={(theme) => ({ fontSize: 12, textAlign: "left" })}>
              {props.middleText}
            </Typography>
          </Grid>
        ) : null}

        <Grid item xs={3}>
          <Typography sx={(theme) => ({ fontSize: 12, textAlign: "right" })}>
            <NumberDecimal number={props.value} decimal={props.decimal} />
          </Typography>
        </Grid>
      </Grid>
    );
  };
  const LineSplitWithStartEnd = ({ props }) => {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: props.position,
          gap: 0.5,
        }}
      >
        <Typography sx={(theme) => ({ fontSize: 12 })}>{props.text}</Typography>
        <Typography sx={(theme) => ({ fontSize: 12 })}>
          {props.textSplit}
        </Typography>
        <Typography sx={(theme) => ({ fontSize: 12 })}>
          {props.value}
        </Typography>
      </Box>
    );
  };
  const TitleDetail = ({ props }) => {
    return <Typography sx={{ fontSize: 14, mb: 1 }}>{props.text}</Typography>;
  };
  return (
    <>
      <Dialog open={dialogSummary.status} maxWidth="sm" fullWidth>
        {statusButtonPrint ? (
          <>
            <DialogTitle>{t("report_open_close_summary")}</DialogTitle>

            <IconButton
              aria-label="close"
              onClick={() => onCloseDialogSummary()}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </>
        ) : null}
        <DialogContent align="center">
          <Paper sx={{ maxWidth: "312px", py: 2, px: 2 }} align="center">
            <Typography sx={{ fontSize: 16, fontWeight: 600 }}>
              {dataAll.name}
            </Typography>
            <Typography sx={{ fontSize: 12 }}>End Drawer Report</Typography>
            <Typography sx={{ fontSize: 12 }}>{dataAll.branch_name}</Typography>
            <Typography sx={{ fontSize: 12 }}>
              Print Time : {dataAll.print_time}
            </Typography>
            <Divider sx={{ my: 1 }} variant="horizontal" />

            <LineSplitWithStartEnd
              props={{
                position: "flex-start",
                textSplit: ":",
                text: "Opening hours",
                value: dayjs(info.open_datetime).format("DD/MM/YYYY HH:mm"),
              }}
            />
            <LineSplitWithStartEnd
              props={{
                position: "flex-start",
                textSplit: ":",
                text: "Staff",
                value: info.opened_by,
              }}
            />
            <Box sx={{ my: 1 }} />
            <LineSplitWithStartEnd
              props={{
                position: "flex-start",
                textSplit: ":",
                text: "Closing hours",
                value: dayjs(info.close_datetime).format("DD/MM/YYYY HH:mm"),
              }}
            />
            <LineSplitWithStartEnd
              props={{
                position: "flex-start",
                textSplit: ":",
                text: "Staff",
                value: info.closed_by,
              }}
            />

            <Divider sx={{ my: 1 }} variant="horizontal" />
            <TitleDetail props={{ text: "Sales" }} />
            <LineBetween
              props={{
                text: "Total",
                middleText: summary.result_total_receipt,
                value: summary.result_total,
                decimal: 2,
              }}
            />
            <LineBetween
              props={{
                text: "Discount",
                value:
                  summary.result_total_discount !== 0
                    ? "-" + summary.result_total_discount
                    : summary.result_total_discount,
                decimal: 2,
              }}
            />
            <LineBetween
              props={{
                text: "Service change",
                value: summary.result_total_service_charge,
                decimal: 2,
              }}
            />
            <LineBetween
              props={{
                text: "Total before Value Added Tax (VAT)",
                value: summary.result_total_grand_total_before_vat,
                middleText: "None",
                decimal: 2,
              }}
            />
            <LineBetween
              props={{
                text: "Value Added Tax (VAT)",
                value: summary.result_total_vat,
                decimal: 2,
              }}
            />
            <LineBetween
              props={{
                text: "Round-off",
                value: summary.total_round_off,
                decimal: 2,
              }}
            />
            <LineBetween
              props={{
                text: "End-of-bill discount",
                value: summary.result_total_grand_total_discount,
                decimal: 2,
              }}
            />
            <LineBetween
              props={{
                text: "Grand Total",
                value: summary.result_total_grand_total,
                decimal: 2,
              }}
            />
            <LineBetween
              props={{
                text: "Amount of customers",
                value: payment_types.reduce(
                  (acc, customer) => acc + customer.count_type_name,
                  0
                ),
                decimal: 0,
              }}
            />
            <LineBetween
              props={{
                text: "Average sales per bill",
                value: summary.result_total_average_per_order,
                decimal: 2,
              }}
            />
            {receipt_promotion.length > 0 ? (
              <>
                <Divider sx={{ my: 1 }} variant="horizontal" />
                <TitleDetail props={{ text: "Discounts and promotions" }} />

                {receipt_promotion.map((promotion, i) => (
                  <LineBetween
                    props={{
                      text: promotion.type_name,
                      value: promotion.sum_received,
                      middleText: promotion.count_type_name,
                      decimal: 2,
                    }}
                  />
                ))}
                {receipt_discount.result_amount_total_amount_discount ? (
                  <LineBetween
                    props={{
                      text: "Sub TTL DC (Amt)",
                      middleText:
                        receipt_discount.result_amount_total_amount_discount,
                      value: receipt_discount.result_total_amount_discount,
                      decimal: 2,
                    }}
                  />
                ) : null}

                {receipt_discount.result_amount_total_percent_discount ? (
                  <LineBetween
                    props={{
                      text: "Sub TTL DC (%)",
                      middleText:
                        receipt_discount.result_amount_total_percent_discount,
                      value: receipt_discount.result_total_percent_discount,
                      decimal: 2,
                    }}
                  />
                ) : null}
              </>
            ) : null}
            <Divider sx={{ my: 1 }} variant="horizontal" />
            <TitleDetail props={{ text: "Sales by payment" }} />
            {payment_types.map((payment, index) => (
              <LineBetween
                props={{
                  text: payment.type_name,
                  middleText: payment.count_type_name,
                  value: payment.sum_received,
                  decimal: 2,
                }}
              />
            ))}
            <LineBetween
              props={{
                text: "Grand Total",
                value: payment_types.reduce(
                  (acc, customer) => acc + customer.sum_received,
                  0
                ),
                decimal: 2,
              }}
            />

            <Divider sx={{ my: 1 }} variant="horizontal" />
            <TitleDetail props={{ text: "Sales cycle" }} />

            <LineBetween
              props={{
                text: "Initial cash",
                value: open_close.total_money_open,
                decimal: 2,
              }}
            />
            <LineBetween
              props={{
                text: "Cash sales",
                value: open_close.total_grand_total_cash,
                decimal: 2,
              }}
            />
            <LineBetween
              props={{
                text: "Cash in",
                value: open_close.total_result_total_money_in,
                decimal: 2,
              }}
            />
            <LineBetween
              props={{
                text: "Cash out",
                value: open_close.total_result_total_money_out,
                decimal: 2,
              }}
            />
            <LineBetween
              props={{
                text: "Expected cash in drawer",
                value: open_close.total_expected_cash_in_drawer,
                decimal: 2,
              }}
            />
            <LineBetween
              props={{
                text: "Actual cash in drawer",
                value: open_close.total_actual_in_drawer,
                decimal: 2,
              }}
            />
            <LineBetween
              props={{
                text: "Difference",
                value: open_close.total_differrence,
                decimal: 2,
              }}
            />
            <LineBetween
              props={{
                text: "Total bills",
                value: open_close.result_total_receipt,
                decimal: 0,
              }}
            />
            <Divider sx={{ my: 1 }} variant="horizontal" />
            <TitleDetail props={{ text: "Void" }} />
            <LineBetween
              props={{
                text: "Voided bills",
                middleText: receipt_status
                  ? receipt_status.result_total_receipt_cancel
                  : 0,
                value: receipt_status
                  ? receipt_status.result_total_grand_total_receipt_cancel
                  : 0,
                decimal: 2,
              }}
            />
            <LineBetween
              props={{
                text: "Voided items",
                middleText: 0,
                value: 0,
                decimal: 2,
              }}
            />
            <LineBetween
              props={{
                text: "Voided paid bills",
                value: receipt_status
                  ? receipt_status.result_total_receipt_cancel
                  : 0,
                decimal: 0,
              }}
            />
            <Divider sx={{ my: 1 }} variant="horizontal" />
          </Paper>
        </DialogContent>
        <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
          {statusButtonPrint ? (
            <Button
              variant="outlined"
              onClick={() => onPrintReceipt()}
              sx={{ borderRadius: 2 }}
            >
              Print receipt again
            </Button>
          ) : null}
        </DialogActions>
      </Dialog>
      ;
    </>
  );
}

export default ReceiptSummaryDetail;
