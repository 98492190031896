import React from "react";
import { Box } from "@mui/material";
import TitleForm from "../formComponent/titleForm";
import { useTranslation } from "react-i18next";
import TextfieldComponent from "../textfield/textfieldComponent";
import { BoxWrapper } from "../box/boxWrapper";
import { BoxGapInput } from "../box/boxGapInput";

function AddressFormOptional(props) {
  const { register, errors } = props;
  const { t } = useTranslation();
  return (
    <Box>
      <TitleForm props={{ text: t("address_additional") }} />
      <BoxGapInput p={2}>
        <BoxWrapper>
          <TextfieldComponent
            title={t("room_no")}
            name="roomNo"
            register={register}
            placeholder={t("room_no_example")}
            errors={errors}
          />
          <TextfieldComponent
            title={t("floor")}
            name="floor"
            register={register}
            placeholder={t("floor_example")}
            errors={errors}
          />
        </BoxWrapper>
        <BoxWrapper>
          <TextfieldComponent
            title={t("house_no")}
            name="houseNo"
            register={register}
            placeholder={t("house_no_example")}
            errors={errors}
          />
          <TextfieldComponent
            title={t("building_name")}
            name="buildingName"
            register={register}
            placeholder={t("building_name_example")}
            errors={errors}
          />
        </BoxWrapper>
        <BoxWrapper>
          <TextfieldComponent
            title={t("village_name")}
            name="villageName"
            register={register}
            placeholder={t("village_name_example")}
            errors={errors}
          />
          <TextfieldComponent
            title={t("village_no")}
            name="villageNo"
            register={register}
            placeholder={t("village_no_example")}
            errors={errors}
          />
        </BoxWrapper>
        <BoxWrapper>
          <TextfieldComponent
            title={t("alley")}
            name="alley"
            register={register}
            placeholder={t("alley_example")}
            errors={errors}
          />
          <TextfieldComponent
            title={t("road")}
            name="road"
            register={register}
            placeholder={t("road_example")}
            errors={errors}
          />
        </BoxWrapper>
      </BoxGapInput>
    </Box>
  );
}

export default AddressFormOptional;
